import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { communityConfig } from "../../../../axiosConfig";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { Context } from "../../../contexts/Store";
import { useParams } from "react-router-dom";
import ToggleButton from "../ToggleButton";

function SchoolScientistEditModal({
	isEditModal,
	setEditModal,
	isViewModal,
	setViewModal,
	questionObject,
	setRender,
	render,
}) {
	useEffect(() => {}, [isEditModal]);
	const [edit, setEdit] = useState([]);
	const {
		state: {
			user_data: { access_token },
			student_data: {},
		},
		dispatch,
	} = useContext(Context);
	const [englishQuestion, setEnglishQuestion] = useState("");
	const [malayalamQuestion, setMalayalamQuestion] = useState("");
	const [optionsA, setOptionsA] = useState("");
	const [optionsB, setOptionsB] = useState("");
	const [optionsC, setOptionsC] = useState("");
	const [optionsD, setOptionsD] = useState("");
	const [correctOption, setCorrectOption] = useState("Select Right Answer");
	const [malayalamOptionA, setMalayalamOptionA] = useState("");
	const [malayalamOptionB, setMalayalamOptionB] = useState("");
	const [malayalamOptionC, setMalayalamOptionC] = useState("");
	const [malayalamOptionD, setMalayalamOptionD] = useState("");
	const [isMalayalam, setMalayalam] = useState(false);
	const [isLoading, setLoading] = useState(false)

	const editQuestion = () => {
		setLoading(true)
		communityConfig
			.post(
				`school-scientists/question/edit/${questionObject.id}/`,
				{
					malayalam_question: malayalamQuestion,
					english_question: englishQuestion,
					english_option1: optionsA,
					english_option2: optionsB,
					english_option3: optionsC,
					english_option4: optionsD,
					correct_option: correctOption,
					malayalam_option1: malayalamOptionA,
					malayalam_option2: malayalamOptionB,
					malayalam_option3: malayalamOptionC,
					malayalam_option4: malayalamOptionD,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				if (response.data.StatusCode === 6000) {
					setEditModal(false);
					setRender(!render)
					setMalayalamQuestion("");
					setEnglishQuestion("");
					setMalayalamOptionA("");
					setOptionsA("");
					setMalayalamOptionB("");
					setOptionsB("");
					setMalayalamOptionC("");
					setOptionsC("");
					setMalayalamOptionD("");
					setOptionsD("");
					setLoading(false)
					setCorrectOption("Select Right Answer")
					
				}else{
					setLoading(false)
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false)
			});
	};

	useEffect(() => {
		setEnglishQuestion(questionObject.english_question);
		setCorrectOption(questionObject.right_option);
		setOptionsA(questionObject.english_option1);
		setOptionsB(questionObject.english_option2);
		setOptionsC(questionObject.english_option3);
		setOptionsD(questionObject.english_option4);

		setMalayalamQuestion(questionObject.question);
		setMalayalamOptionA(questionObject.option1);
		setMalayalamOptionB(questionObject.option2);
		setMalayalamOptionC(questionObject.option3);
		setMalayalamOptionD(questionObject.option4);
	}, [questionObject]);

	return (
		<BackContainer>
			<Cover className={(isEditModal || isViewModal) ? "active" : ""}>
				<Overlay
					onClick={() => {
						setEditModal(false);
						setViewModal(false);
					}}
				></Overlay>
				<Modal>
					<Container>
						<Head>
							<Headtitle>{ isViewModal ? "View" : "Edit" } Question</Headtitle>
							<Closeicon
								onClick={() => {
									setEditModal(false);
									setViewModal(false);
								}}
							>
								<img
									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
									alt="Icon"
								/>
							</Closeicon>
						</Head>
						<ToggleButton
							isMalayalam={isMalayalam}
							setMalayalam={setMalayalam}
						/>
						<Servicesfields>
							<SelectCampusInput
								type="text"
								value={
									isMalayalam
										? malayalamQuestion
										: englishQuestion
								}
								onChange={(e) => {
									if(isEditModal){
										if (isMalayalam) {
											setMalayalamQuestion(e.target.value);
										} else {
											setEnglishQuestion(e.target.value);
										}
									}
								}}
							/>
						</Servicesfields>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Option A"
									type="text"
									value={
										isMalayalam
											? malayalamOptionA
											: optionsA
									}
									onChange={(e) => {
										if(isEditModal){
											if (isMalayalam) {
												setMalayalamOptionA(e.target.value);
											} else {
												setOptionsA(e.target.value);
											}

										}
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Option B"
									type="text"
									value={
										isMalayalam
											? malayalamOptionB
											: optionsB
									}
									onChange={(e) => {
										if(isEditModal){
											if (isMalayalam) {
												setMalayalamOptionB(e.target.value);
											} else {
												setOptionsB(e.target.value);
											}
										}
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Option C"
									type="text"
									value={
										isMalayalam
											? malayalamOptionC
											: optionsC
									}
									onChange={(e) => {
										if(isEditModal){
											if (isMalayalam) {
												setMalayalamOptionC(e.target.value);
											} else {
												setOptionsC(e.target.value);
											}
										}
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Option D"
									type="text"
									value={
										isMalayalam
											? malayalamOptionD
											: optionsD
									}
									onChange={(e) => {
										if(isEditModal){
											if (isMalayalam) {
												setMalayalamOptionD(e.target.value);
											} else {
												setOptionsD(e.target.value);
											}
										}	
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<select
								
									onChange={(e) =>
										{if(isEditModal){
											setCorrectOption(e.target.value)
										}}
										
									}
									value={correctOption}
								>
									<option placeholder="" disabled >
										Select Right Answer
									</option>
									<option value="option1" >A</option>
									<option value="option2" >B</option>
									<option value="option3" >C</option>
									<option value="option4">D</option>
								</select>
							</InputField>
						</AddExaminers>
						{
							isEditModal ? <CreateButtonBox>
								<CancelButton
									onClick={() => {
										setEditModal(false);
									}}
								>
									Cancel
								</CancelButton>
								<CreateButton
									onClick={() => {
										editQuestion();
									}}
								>
								{isLoading ? <ButtonLoader /> : "Submit"}
								</CreateButton>
							</CreateButtonBox> : ""
						}
						
					</Container>
				</Modal>
			</Cover>
		</BackContainer>
	);
}

export default SchoolScientistEditModal;
const BackContainer = styled.div``;
const Cover = styled.div`
	position: fixed;
	transition: 0.3s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	&.active {
		transform: scale(1, 1);
		backdrop-filter: blur(4px);
	}
`;
const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0px;
	width: 100%;
	/* z-index: 1000; */
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
`;
const Modal = styled.div`
	width: 90%;
	max-width: 640px;
	max-height: 100vh;
	position: absolute;
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s;
	z-index: 1000;
	border-radius: 5px;
	overflow-y: hidden;
	box-shadow: 0px 3px 56px #00000048;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}

	@media all and (max-width: 1280px) {
	}
	@media all and (max-width: 400px) {
		max-height: 70vh;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	width: 100%;
	height: 100%;
	background-color: #1b1c1f;
	border: 1px solid #26272a;
	@media all and (max-width: 480px) {
		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	@media all and (max-width: 360px) {
		padding: 10px;
	}
`;
const Head = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;
const Headtitle = styled.p`
	font-size: 18px;
	color: #fff;
	font-family: "gordita_medium";
`;
const Closeicon = styled.div`
	width: 18px;
	cursor: pointer;
	img {
		width: 100%;
		display: block;
	}
`;

const SelectCampustittle = styled.p`
	font-size: 14px;
	font-family: "gordita_regular";
	color: #fff;
	margin-bottom: 5px;
`;
const SelectCampusInput = styled.textarea`
	background-color: #161619 !important;
	border: 1px solid #26272a;
	border-radius: 5px !important;
	width: 100%;
	padding: 10px;
	color: #fff;
	height: 100px;
	resize: none;
	&.adress {
		height: 22vh;
	}
	@media all and (max-width: 480px) {
		padding: 15px 0px 15px 10px;
	}
`;
const CancelButton = styled.div`
	color: #fff;
	border-radius: 5px !important;
	width: 120px;
	height: 40px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;
	cursor: pointer;
	font-family: "gordita_medium";
	margin-right: 20px;
	background: #161619;
	border: 1px solid #fe673a;
`;
const Servicesfields = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 25px;
	:last-child {
		margin-left: 20px;
	}
	@media all and (max-width: 480px) {
		width: 100%;
		:last-child {
			margin-left: 0px;
			margin-top: 20px;
		}
	}
`;
const CreateButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	@media all and (max-width: 480px) {
		margin-top: 10px;
	}
`;
const CreateButton = styled.button`
	background: #fe673a;
	color: #fff;
	border-radius: 5px !important;
	width: 120px;
	height: 40px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;
	cursor: pointer;
	font-family: "gordita_medium";
`;
const AddExaminers = styled.div``;
const InputField = styled.div`
	margin-bottom: 20px !important;
	background: #161619;
	border-radius: 5px;
	margin-left: 0;
	width: 100%;
	border: 1px solid #26272a;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 15px;
	position: relative;
	select {
		width: 100%;
		padding: 15px 10px;
		color: #fff !important;
	}
	input {
		width: 100%;
		padding: 15px 10px;
		color: #fff !important;
	}
	transition: 3s ease;
	option {
		background: #161619;
		color: #fff;
		border: none;
	}
`;
