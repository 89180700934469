import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import {
    Link,
    useParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ShareDeleteModal from "../../includes/career-advisor/modal/ShareDeleteModal";
import RemoveQuestions from "../../includes/career-advisor/modal/RemoveQuestions";
import { Context } from "../../contexts/Store";
import RemoveCommentModal from "../../includes/career-advisor/modal/RemoveCommentModal";
import { learnConfig } from "../../../axiosConfig";
import Tiptap from "../../includes/doubt-hub/Editor/Tiptap";
import TiptapViewer from "../../includes/doubt-hub/Editor/TiptapViewer";
import parse from "html-react-parser";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import Avatar from "react-avatar";
import SectionLoader from "../../helpers/SectionLoader";
import RemoveAnswer from "../../includes/career-advisor/modal/RemoveAnswer";

function ViewPage() {
    const [comment, setComment] = useState("");
    const [answerComment, setAnswerComment] = useState("");
    const [isModal, setModal] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const [isAnswer, setAnswer] = useState(false);
    const [isShare, setShare] = useState(false);
    const [content, setContent] = useState();
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isLoadApi, setLoadApi] = useState(false);
    const [input, setInput] = useState();
    const [inputHeight, setInputHeight] = useState(38);
    const [modalId, setModalId] = useState("");
    const [sigleData, setSingleData] = useState([]);
    const [editorData, setEditorData] = useState("");
    const { pk } = useParams();
    const [desc, setDesc] = useState(null);
    const [answerData, setAnswerData] = useState("");
    const dataObject = JSON.parse(desc);
    const [isViewMore, setIsViewMore] = useState(false);
    const [isViewCmtMore, setIsCmtViewMore] = useState(false);
    const [aboutMeText, setAboutMeText] = useState("");
    const [isSelectedItem, setSelectedItem] = useState("");
    const [isCommentBtnLoad, setCommentBtnLoad] = useState(false);
    const [isAnsLoad, setAnsLoad] = useState();
    const [isPageLoading, setPageLoading] = useState(false);
    const [pagination, setPagination] = useState("");
    const [commetActive, setCommetActive] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        return filterParams;
    };

    const handleInputChange = (event) => {
        setContent(event.target.value);
        const textareaLineHeight = 20;
        const previousRows = event.target.rows;
        event.target.rows = 1;
        const currentRows = Math.ceil(
            event.target.scrollHeight / textareaLineHeight
        );
        event.target.rows = currentRows;
        if (currentRows === previousRows) {
            event.target.style.height = "auto";
        }
        const newHeight = event.target.scrollHeight + currentRows * 2;
        setInputHeight(newHeight);
    };
    const handleInput = (event) => {
        setInput(event.target.value);
        const textareaLineHeight = 20;
        const previousRows = event.target.rows;
        event.target.rows = 1;
        const currentRows = Math.ceil(
            event.target.scrollHeight / textareaLineHeight
        );
        event.target.rows = currentRows;
        if (currentRows === previousRows) {
            event.target.style.height = "auto";
        }
        const newHeight = event.target.scrollHeight + currentRows * 2;
        setInputHeight(newHeight);
    };

    const history = useNavigate();

    const textareaRef = React.useRef(null);
    const MIN_TEXTAREA_HEIGHT = 21;

    const [counter, setCounter] = useState(0);

    const incrementCounter = () => {
        setCounter(counter + 1);
    };

    const decrementCounter = () => {
        setCounter(counter - 1);
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    // handle

    const handleViewMore = () => {
        setIsViewMore(true);
    };
    const handleShowLess = () => {
        setIsViewMore(false);
    };

    const handleCmtMore = () => {
        setIsCmtViewMore(true);
    };
    const handleCmtLess = () => {
        setIsCmtViewMore(false);
    };

    const handleModal = () => {
        setShare(!isShare);
    };

    // scroll view

    const targetRef = useRef(null);

    useEffect(() => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } else {
            console.log("targetRef.current is null");
        }
    }, [targetRef]);

    // const handleDivClick = () => {
    //     setEditedContent("");
    // };
    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    useEffect(() => {
        setPageLoading(true);
        const params = getSearchParams();
        async function singleView() {
            try {
                const response = await learnConfig.get(
                    `/doubt-hub/questions-single-view-chief/${pk}`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: params,
                    }
                );
                const { status_code, data } = response.data;

                if (status_code === 6000) {
                    setSingleData(data);
                    setDesc(data?.description);
                    setAnswerData(data?.answers);
                    setCommentBtnLoad(false);
                    setContent("");
                    setAnsLoad(false);
                    setEditorData("");
                    setPageLoading(false);
                    setPagination(data?.answers?.pagination_data);
                    // setPageLoading(false);
                } else {
                    // setPageLoading(false);
                    setCommentBtnLoad(false);
                    setPageLoading(false);
                }
            } catch (error) {
                setPageLoading(false);
            }
        }

        singleView();
    }, [isLoadApi, currentPage, searchParams]);

    const postAnswer = () => {
        setAnsLoad(true);
        const formData = new FormData();
        formData.append("content", JSON.stringify(editorData));
        learnConfig
            .post(`/doubt-hub/answer-posting-chief/${pk}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { status_code } = response.data;
                if (status_code === 6000) {
                    setEditorData("");
                    setLoadApi(!isLoadApi);
                } else {
                }
            })
            .catch((error) => {});
    };

    // post comment  api

    const commentPost = () => {
        setCommentBtnLoad(true);
        const formData = new FormData();
        formData.append("description", content);
        formData.append("comment_instance", "question");
        learnConfig
            .post(`/doubt-hub/comment-posting-chief/${pk}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setLoadApi(!isLoadApi);
                } else {
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const AnswerCommentPost = (answer) => {
        setCommentBtnLoad(true);
        const formData = new FormData();
        formData.append("description", answerComment);
        formData.append("comment_instance", "answer");
        learnConfig
            .post(`/doubt-hub/comment-posting-chief/${answer}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setLoadApi(!isLoadApi);
                    setAnswerComment("");
                } else {
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // delete comment api

    const DeleteComment = () => {
        setBtnLoading(true);
        const formData = new FormData();
        formData.append("reason", aboutMeText);
        formData.append("page", "doubt");
        learnConfig
            .post(
                `/doubt-hub/delete-comment-chief/${isSelectedItem}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setLoadApi(!isLoadApi);
                    setAboutMeText("");
                    setSelectedItem("");
                    setIsComment(!isComment);
                    setBtnLoading(false);
                } else {
                    setBtnLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setBtnLoading(false);
            });
    };

    // delete question api

    const DeleteQuestion = () => {
        setBtnLoading(true);
        const formData = new FormData();
        formData.append("reason", aboutMeText);
        formData.append("page", "doubt");
        learnConfig
            .post(
                `/doubt-hub/delete-question-chief/${isSelectedItem}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setAboutMeText("");
                    setSelectedItem("");
                    history("/doubt-hub/doubts");
                    setModal(!isModal);
                    setBtnLoading(false);
                } else {
                    setBtnLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setBtnLoading(false);
            });
    };

    // Delete answer api

    const DeleteAnswer = () => {
        setBtnLoading(true);
        const formData = new FormData();
        formData.append("reason", aboutMeText);
        formData.append("page", "doubt");
        learnConfig
            .post(`/doubt-hub/delete-answer-chief/${modalId}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setLoadApi(!isLoadApi);
                    setAboutMeText("");
                    setModalId("");
                    setAnswer(false);
                    setBtnLoading(false);
                } else {
                    setBtnLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setBtnLoading(false);
            });
    };

    return (
        <>
            <Container>
                <>
                    <RemoveAnswer
                        isAnswer={isAnswer}
                        setAnswer={setAnswer}
                        DeleteAnswer={DeleteAnswer}
                        aboutMeText={aboutMeText}
                        setAboutMeText={setAboutMeText}
                        isBtnLoading={isBtnLoading}
                    />
                    <RemoveQuestions
                        isModal={isModal}
                        setModal={setModal}
                        DeleteQuestion={DeleteQuestion}
                        aboutMeText={aboutMeText}
                        setAboutMeText={setAboutMeText}
                        isBtnLoading={isBtnLoading}
                    />

                    <RemoveCommentModal
                        isComment={isComment}
                        setIsComment={setIsComment}
                        DeleteComment={DeleteComment}
                        aboutMeText={aboutMeText}
                        setAboutMeText={setAboutMeText}
                        isBtnLoading={isBtnLoading}
                    />

                    <DoubtNavigator>
                        <DoubtButton to="/doubt-hub/doubts/">
                            <ArrowIcon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2024/arrow-left.svg"
                                    alt=""
                                />
                            </ArrowIcon>
                            Back to Doubts
                        </DoubtButton>
                    </DoubtNavigator>

                    <MainContainer>
                        {isPageLoading ? (
                            <SectionLoader />
                        ) : (
                            <>
                                <Breadcrum>{/* <CardPathChip /> */}</Breadcrum>
                                <StudentContainer>
                                    <TopContainer>
                                        <Heading>
                                            {sigleData?.title ?? "--"}
                                        </Heading>
                                        <RightContainer>
                                            <AskButton
                                                onClick={(e) => {
                                                    setModal(!isModal);
                                                    setSelectedItem(
                                                        sigleData?.pk
                                                    );
                                                }}
                                            >
                                                Remove Question
                                            </AskButton>
                                        </RightContainer>
                                    </TopContainer>
                                    <MiddleContainer>
                                        <LeftContainer>
                                            {sigleData?.tags?.map(
                                                (tag, index) => (
                                                    <Tag key={index}>
                                                        {tag ?? "--"}
                                                    </Tag>
                                                )
                                            )}
                                        </LeftContainer>
                                    </MiddleContainer>
                                    <StudentProfile>
                                        {" "}
                                        <StudentPhoto>
                                            <ImageContainer>
                                                <img
                                                    src={
                                                        sigleData
                                                            ?.student_details
                                                            ?.student_photo
                                                    }
                                                    alt="student_photo"
                                                />
                                            </ImageContainer>
                                        </StudentPhoto>
                                        <StudentDetails>
                                            <StudentName>
                                                {sigleData?.student_details
                                                    ?.student_name ?? "--"}
                                            </StudentName>
                                            <StudentPosition>
                                                {sigleData?.student_details
                                                    ?.student_program ?? "--"}
                                            </StudentPosition>
                                        </StudentDetails>
                                    </StudentProfile>
                                    <BottomContainer>
                                        <Activity>
                                            <span>Asked</span>
                                            <small>
                                                {moment(
                                                    sigleData?.published_time ??
                                                        "--"
                                                )
                                                    .endOf("day")
                                                    .fromNow()}
                                            </small>
                                            <span>Modified</span>
                                            <small>
                                                {moment(
                                                    sigleData?.date_updated ??
                                                        "--"
                                                )
                                                    .endOf("day")
                                                    .fromNow()}
                                            </small>
                                            <span>Viewed</span>
                                            <small>
                                                {sigleData?.views} times
                                            </small>
                                        </Activity>
                                    </BottomContainer>
                                </StudentContainer>
                                <QuestionContainer>
                                    <Question>
                                        <CommentContainer>
                                            <TiptapViewer
                                                content={dataObject}
                                            />

                                            <CommentTitle>
                                                Add a comment
                                            </CommentTitle>

                                            <InputWrapper
                                                style={{
                                                    minHeight:
                                                        MIN_TEXTAREA_HEIGHT,
                                                    maxHeight: "120px",
                                                }}
                                            >
                                                <CommentImage>
                                                    <img
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-02-2024/comment-icon.svg"
                                                        alt="Comment"
                                                    />
                                                </CommentImage>
                                                <Input
                                                    onChange={(e) => {
                                                        setContent(
                                                            e.target.value
                                                        );
                                                    }}
                                                    ref={textareaRef}
                                                    id="res"
                                                    data-tab="10"
                                                    dir="ltr"
                                                    rows="1"
                                                    placeholder={
                                                        "Add your thoughts here"
                                                    }
                                                    value={content}
                                                    style={{
                                                        minHeight:
                                                            MIN_TEXTAREA_HEIGHT,
                                                        resize: "none",
                                                        maxHeight: "110px",
                                                    }}
                                                ></Input>
                                                {isCommentBtnLoad ? (
                                                    <ButtonLoader height={21} />
                                                ) : (
                                                    <CommentButton
                                                        onClick={() =>
                                                            commentPost()
                                                        }
                                                    >
                                                        {content && "Comment"}
                                                    </CommentButton>
                                                )}
                                            </InputWrapper>
                                            {sigleData?.comments
                                                ?.slice(
                                                    0,
                                                    isViewMore
                                                        ? sigleData?.comments
                                                              ?.length
                                                        : 3
                                                )
                                                ?.map((item, index) => (
                                                    <Comments>
                                                        <h6>
                                                            {item?.student
                                                                ? item?.student
                                                                : item?.chief
                                                                ? "Moderator"
                                                                : "--"}
                                                            {" :  "}
                                                        </h6>
                                                        <p>
                                                            {item?.description ??
                                                                "--"}
                                                        </p>
                                                        <small>
                                                            {" "}
                                                            {moment(
                                                                item?.date_added ??
                                                                    "--"
                                                            ).format("LLL")}
                                                        </small>
                                                        {/* <span>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-02-2024/edit-icon.svg"
                                                alt="Edit"
                                            />
                                        </span> */}

                                                        <span
                                                            onClick={(e) => {
                                                                setIsComment(
                                                                    true
                                                                );
                                                                setSelectedItem(
                                                                    item?.pk
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-02-2024/delete-icon.svg"
                                                                alt="Delete"
                                                            />
                                                        </span>
                                                    </Comments>
                                                ))}

                                            {sigleData?.comments?.length >
                                                3 && (
                                                <ViewCommentButton
                                                    onClick={
                                                        isViewMore
                                                            ? handleShowLess
                                                            : handleViewMore
                                                    }
                                                >
                                                    {isViewMore
                                                        ? "Show Less"
                                                        : "View More"}
                                                </ViewCommentButton>
                                            )}
                                        </CommentContainer>
                                    </Question>
                                </QuestionContainer>
                                <CommentTitle>
                                    {pagination?.total_items} Answers
                                </CommentTitle>

                                {/* -------pagination answer -------- */}

                                {pagination?.total_pages > 0 && (
                                    <PaginationContainer>
                                        {/* <PaginationText>
                                            {pagination?.last_item} of{" "}
                                            {pagination?.total_items}
                                        </PaginationText> */}
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            pageCount={pagination?.total_pages}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={
                                                "pagination__link"
                                            }
                                            nextLinkClassName={
                                                "pagination__link"
                                            }
                                            disabledClassName={
                                                "pagination__link--disabled"
                                            }
                                            activeClassName={
                                                "pagination__link--active"
                                            }
                                        />
                                    </PaginationContainer>
                                )}
                                <AnswerContainer ref={targetRef}>
                                    {answerData?.data?.map((answer) => (
                                        <Answer>
                                            {answer?.is_accepted && (
                                                <VotesContainer>
                                                    <TickImage>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-02-2024/verified.svg"
                                                            alt="VerifiedTick"
                                                        />
                                                    </TickImage>
                                                </VotesContainer>
                                            )}
                                            <AnswerList>
                                                <AnswerDetails>
                                                    <Student>
                                                        <StudentImage>
                                                            {answer?.studentimage ? (
                                                                <img
                                                                    src={
                                                                        answer?.studentimage
                                                                    }
                                                                    alt="student"
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    name={
                                                                        answer?.student
                                                                    }
                                                                    size="38"
                                                                    round
                                                                />
                                                            )}
                                                        </StudentImage>
                                                        <StudentsDetails>
                                                            <StudentsName>
                                                                {answer?.student
                                                                    ? answer?.student
                                                                    : answer?.chief
                                                                    ? "Moderator"
                                                                    : "--"}
                                                                {" :  "}
                                                            </StudentsName>
                                                            <StudentsPosition>
                                                                {answer?.student_program ??
                                                                    "--"}
                                                            </StudentsPosition>
                                                        </StudentsDetails>
                                                    </Student>
                                                    <DotContainer>
                                                        <DotIcon>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-02-2024/delete-icon.svg"
                                                                alt="Dot Icon"
                                                                style={{
                                                                    width: "25px",
                                                                    height: "25px",
                                                                }}
                                                                onClick={() => {
                                                                    // handleModal();
                                                                    setAnswer(
                                                                        !isAnswer
                                                                    );
                                                                    setModalId(
                                                                        answer?.pk
                                                                    );
                                                                }}
                                                            />
                                                        </DotIcon>
                                                        {/* {answer.pk ===
                                                            modalId &&
                                                            isShare && (
                                                                <RemoveQuestions
                                                                    isModal={
                                                                        isModal
                                                                    }
                                                                    setModal={
                                                                        setModal
                                                                    }
                                                                />
                                                            )} */}
                                                    </DotContainer>
                                                </AnswerDetails>

                                                <AnswerText>
                                                    {answer.answertext}
                                                </AnswerText>
                                                <TiptapViewer
                                                    content={JSON.parse(
                                                        answer?.content
                                                    )}
                                                />
                                                {commetActive === answer.pk && (
                                                    <CommentContainer>
                                                        <InputWrapper
                                                            style={{
                                                                minHeight:
                                                                    MIN_TEXTAREA_HEIGHT,
                                                                maxHeight:
                                                                    "120px",
                                                            }}
                                                        >
                                                            <CommentImage>
                                                                <img
                                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-02-2024/comment-icon.svg"
                                                                    alt="Comment"
                                                                />
                                                            </CommentImage>
                                                            <Input
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setAnswerComment(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                ref={
                                                                    textareaRef
                                                                }
                                                                id="res"
                                                                data-tab="10"
                                                                dir="ltr"
                                                                rows="1"
                                                                placeholder={
                                                                    "Add your thoughts here"
                                                                }
                                                                value={
                                                                    answerComment
                                                                }
                                                                style={{
                                                                    minHeight:
                                                                        MIN_TEXTAREA_HEIGHT,
                                                                    resize: "none",
                                                                    maxHeight:
                                                                        "110px",
                                                                }}
                                                            ></Input>
                                                            {isCommentBtnLoad ? (
                                                                <ButtonLoader
                                                                    height={21}
                                                                />
                                                            ) : (
                                                                <CommentButton
                                                                    onClick={() =>
                                                                        AnswerCommentPost(
                                                                            answer?.pk
                                                                        )
                                                                    }
                                                                >
                                                                    {answerComment &&
                                                                        "Comment"}
                                                                </CommentButton>
                                                            )}
                                                        </InputWrapper>
                                                        {answer?.comments
                                                            ?.slice(
                                                                0,
                                                                isViewCmtMore
                                                                    ? answer
                                                                          ?.comments
                                                                          ?.length
                                                                    : 3
                                                            )
                                                            ?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <Comments>
                                                                        <h6>
                                                                            {item?.student
                                                                                ? item?.student
                                                                                : item?.chief
                                                                                ? "Moderator"
                                                                                : "--"}
                                                                            {
                                                                                " :  "
                                                                            }
                                                                        </h6>
                                                                        <p>
                                                                            {item?.description ??
                                                                                "--"}
                                                                        </p>
                                                                        <small>
                                                                            {" "}
                                                                            {moment(
                                                                                item?.date_added ??
                                                                                    "--"
                                                                            ).format(
                                                                                "LLL"
                                                                            )}
                                                                        </small>
                                                                        {/* <span>
                                                     <img
                                                         src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-02-2024/edit-icon.svg"
                                                         alt="Edit"
                                                     />
                                                 </span> */}

                                                                        <span
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                setIsComment(
                                                                                    true
                                                                                );
                                                                                setSelectedItem(
                                                                                    item?.pk
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-02-2024/delete-icon.svg"
                                                                                alt="Delete"
                                                                            />
                                                                        </span>
                                                                    </Comments>
                                                                )
                                                            )}

                                                        {answer?.comments
                                                            ?.length > 3 && (
                                                            <ViewCommentButton
                                                                onClick={
                                                                    isViewCmtMore
                                                                        ? handleCmtLess
                                                                        : handleCmtMore
                                                                }
                                                            >
                                                                {isViewCmtMore
                                                                    ? "Show Less"
                                                                    : "View More"}
                                                            </ViewCommentButton>
                                                        )}
                                                    </CommentContainer>
                                                )}

                                                <TimeContainer>
                                                    <ButtonsContainer>
                                                        <CommentsButton
                                                            onClick={() =>
                                                                setCommetActive(
                                                                    answer.pk
                                                                )
                                                            }
                                                        >
                                                            Add a Comment
                                                        </CommentsButton>
                                                    </ButtonsContainer>
                                                    <PostedTime>
                                                        <EditedTime>
                                                            {answer.edittime}
                                                        </EditedTime>
                                                        <PostTime>
                                                            {"Posted "}
                                                            {moment(
                                                                answer?.date_added ??
                                                                    "--"
                                                            )
                                                                .startOf("hour")
                                                                .fromNow()}
                                                        </PostTime>
                                                    </PostedTime>
                                                </TimeContainer>
                                            </AnswerList>
                                        </Answer>
                                    ))}
                                </AnswerContainer>
                                <YourAnswer>
                                    <Title>Your Answer</Title>
                                    <EditorContainer>
                                        <Tiptap
                                            setDiscriptionData={setEditorData}
                                        />
                                    </EditorContainer>
                                    <PostButton>
                                        <Button
                                            onClick={() => {
                                                postAnswer();
                                            }}
                                        >
                                            {isAnsLoad ? (
                                                <ButtonLoader height={20} />
                                            ) : (
                                                "Post"
                                            )}
                                        </Button>
                                    </PostButton>
                                </YourAnswer>
                            </>
                        )}
                    </MainContainer>
                </>
            </Container>
        </>
    );
}

const CommentBox = styled.div``;
const CommentInput = styled.input`
    border: 1px solid #fff;
    width: 100%;
    padding: 20px;
    border-radius: 6px;
`;

const Container = styled.section``;
const DoubtButton = styled(Link)`
    color: #fe673a;
    font-size: 13px;
    display: flex;
    align-items: center;
`;
const ArrowIcon = styled.div`
    width: 20px;
    margin-right: 5px;
    img {
        width: 100%;
        display: block;
        /* filter: hue-rotate(90deg); */
        /* transform: rotate(180deg); */
    }
`;
const DoubtNavigator = styled.div``;
const MainContainer = styled.div`
    overflow-y: scroll;
    max-height: calc(93vh - 80px);
    min-height: calc(93vh - 80px);
    padding: 10px 226px;
    @media all and (max-width: 1100px) {
        /* justify-content: flex-end; */
        padding: 10px 126px;
    }
    @media all and (max-width: 768px) {
        /* justify-content: flex-end; */
        padding: 10px 98px;
    }
    @media all and (max-width: 640px) {
        padding: 10px 34px;
    }
    @media all and (max-width: 540px) {
        padding: 10px 30px;
    }
    @media all and (max-width: 480px) {
        padding: 10px 24px;
    }
`;
const Breadcrum = styled.div`
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const ImageContainer = styled.div`
    margin-right: 5px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;

    img {
        width: 100%;
        height: 100%;
        display: block;
    }
`;
const Section = styled.div`
    align-items: center;
    width: ${({ type }) =>
        type === "article"
            ? "30%"
            : type === "author"
            ? "21%"
            : type === "category"
            ? "15%"
            : type === "status"
            ? "8%"
            : type === "reads"
            ? "5%"
            : type === "actions" && "14%"};
    display: ${({ type }) =>
        type === "article" ||
        type === "actions" ||
        type === "author" ||
        type === "reads"
            ? "flex"
            : "block"};
    justify-content: ${({ type }) => (type === "actions" ? "flex-end" : "")};
    align-items: center;
    cursor: ${({ type }) => (type === "reads" ? "pointer" : "")};
`;
const ReworkText = styled.p`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;
const ReadImg = styled.img`
    display: block;
    width: 100%;
`;
const AuthorImg = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: #fff;

    /* img {
        width: 100%;
        display: block;
    } */
`;
const ReadImgContainer = styled.div`
    margin-right: 4px;
    width: 32%;
`;
const ActionsButton = styled.button`
    border-radius: 6px;
    border: 2px solid #fe673a;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    width: 65%;
    height: 44px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
    background: ${({ type }) => (type === "published" ? "#161619" : "#FE673A")};
`;
const StudentContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid #2c2c2c;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background: #e5e5e5;
        border-radius: 0.625rem;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #b7b7b7;
    }
    @media all and (max-width: 768px) {
        padding-right: 0;
    }
`;
const AskContainer = styled.div`
    display: none;
    @media all and (max-width: 480px) {
        display: flex;
        justify-content: flex-end;
    }
`;

const TopContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1160px) {
        flex-direction: column;
    }
`;
const Heading = styled.h4`
    font-size: 22px;
    color: #fff;
    font-family: "gordita_medium";
    margin-top: 15px;
    width: 75%;
    @media all and (max-width: 1400px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 640px) {
        font-size: 16px;
        margin-top: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
        margin-top: 20px;
    }
`;

const MiddleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;
const LeftContainer = styled.div`
    @media all and (max-width: 540px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Tag = styled.button`
    background: #161619;
    color: #fe673a;
    border: 1px solid #fe673a;
    margin-right: 8px;
    border-radius: 16px;
    padding: 6px 10px;
    cursor: pointer;
    font-family: "gordita_medium";
    text-transform: capitalize;
    :last-child {
        margin-right: 0;
    }
    @media all and (max-width: 640px) {
        padding: 6px 12px;
    }
    @media all and (max-width: 540px) {
        margin-bottom: 8px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 1160px) {
        justify-content: flex-end;
    }
    /* @media all and (max-width: 480px) {
        display: none;
    } */
`;
const ActionEditButton = styled.div`
    border-radius: 6px;
    border: 2px solid #2c2c2c;
    background: #2c2c2c;
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4%;

    img {
        display: block;
        width: 100%;
    }
`;
const AskButton = styled(Link)`
    background: #fe673a;
    color: #fff;
    padding: 10px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 18px;
    font-family: "gordita_medium";

    @media all and (max-width: 640px) {
        font-size: 12px;
    }
`;
const StudentProfile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
const StudentDetails = styled.div``;
const StudentName = styled.h4`
    font-family: "gordita_medium";
    font-size: 16px;
    color: #fff;
`;
const ReworkBox = styled.div`
    border-radius: 8px;
    border: 3px solid #3b3b3b;
    background: #1b1c1f;
    width: 246px;
    height: 88px;
    position: absolute;
    right: 23px;
    bottom: -100px;
    z-index: 100;
`;
const StudentPosition = styled.p`
    font-size: 12px;
    color: #fe673a;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const CommentButton = styled.button`
    color: #fe673a;
    font-size: 14px;
    cursor: pointer;
    font-family: "gordita_medium";
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const AuthorName = styled.h4`
    color: #fff;
    font-size: 16px;

    @media all and (max-width: 1380px) {
        font-size: 14px;
    }
`;
const AuthorContainer = styled.div`
    margin-left: 10px;
    width: 85%;
`;
const CopyButton = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
const StudentPhoto = styled.div`
    margin-right: 6px;
    img {
        width: 100%;
        display: block;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    margin-bottom: 5px;
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
    }
`;
const Activity = styled.div`
    margin-right: 24px;
    :last-child {
        margin-right: 0;
    }
    span {
        font-size: 14px;
        color: #70707b;
        margin-right: 10px;
        @media all and (max-width: 980px) {
            font-size: 13px;
        }
        @media all and (max-width: 540px) {
            font-size: 12px;
        }
    }
    small {
        font-size: 14px;
        color: #fff;
        margin-right: 10px;
        @media all and (max-width: 980px) {
            font-size: 13px;
        }
        @media all and (max-width: 540px) {
            font-size: 12px;
        }
    }
`;
const QuestionContainer = styled.div`
    border-bottom: 1px solid #2c2c2c;
    margin-top: 30px;
`;
const Question = styled.div`
    display: flex;
`;
const CommentContainer = styled.div`
    width: 100%;
    margin: 30px 0;
    p {
        font-size: 14px;
        margin-bottom: 15px;
        color: #fff;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    small {
        font-size: 14px;
        font-family: "gordita_regular";
        color: #fff;
        line-height: 18px;
        margin-bottom: 15px;
    }
`;
const MessageContainer = styled.div`
    width: 100%;
    display: flex;
    border: 1px solid #494949;
    /* background: #0d121c; */
    border-radius: 4px;
    padding: 10px 11px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const CommentTitle = styled.h6`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
    margin: 30px 0 15px 0;
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const CommentImage = styled.div`
    margin-right: 10px;
    width: 20px;
    position: absolute;
    top: 10px;
    img {
        width: 100%;
        display: block;
    }
`;

const Message = styled.div`
    width: 100%;
    display: flex;
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 10px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: flex-start; /* Changed from center to flex-start */
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const Error = styled.div``;
const MessageTwo = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 14px;
        color: #fff;
    }
`;
const SecondMessage = styled.div`
    width: 100%;
    display: flex;
    /* border: 1px solid #e4e7ec; */
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 10px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const MessageOne = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 14px;
        color: #fff;
    }
`;
const CommentSection = styled.div`
    display: flex;
    /* position: relative; */
    font-size: 14px;
    font-family: "gordita_medium";
    color: #828282;
    /* justify-content: space-between; */
    text-align: center;
    align-items: center;
    width: 90%;
`;
const Input = styled.textarea`
    font-size: 15px;
    word-wrap: break-word;
    white-space: pre-wrap;
    outline: none;
    /* min-height: 20px; */
    /* max-height: 100px; */
    overflow-x: hidden;
    overflow-y: auto;
    color: #ababab;
    width: 100%;
    resize: none;

    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        padding: 8px 8px;
        font-size: 14px;
    }
`;
const CommentIcon = styled.div`
    width: 20px;
    align-items: center;
    display: flex;
    margin-right: 4px;
    img {
        width: 100%;
        display: block;
        margin-right: 4px;
    }
`;
const Comments = styled.div`
    margin-bottom: 15px;
    h6 {
        font-size: 16px;
        /* color: #3f3f46; */
        color: #fff;
        font-family: "gordita_medium";
        display: inline-block;
        margin-right: 6px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    p {
        display: contents;
        font-size: 14px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    small {
        /* color: #d1d1d6; */
        font-family: "gordita_regular";
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
        @media all and (max-width: 480px) {
            font-size: 12px;
        }
    }
    span {
        width: 20px;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;

        :last-child {
            margin-right: 0;
        }
        img {
            display: block;
            width: 100%;
        }
    }
`;
const OtherMessage = styled.div`
    margin-bottom: 15px;
    h6 {
        font-size: 16px;
        color: #fff;
        font-family: "gordita_medium";
        display: inline-block;
        margin-right: 6px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    p {
        display: contents;
        font-size: 14px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    small {
        color: #d1d1d6;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
        @media all and (max-width: 480px) {
            font-size: 12px;
        }
    }
    span {
        width: 100%;
        display: inline;
        cursor: pointer;
    }
`;
const ViewCommentButton = styled.button`
    color: #fe673a;
    cursor: pointer;
    font-size: 14px;
`;

const VoteContainer = styled.div`
    width: 30px;
    margin-right: 20px;
`;
const UpVote = styled.div`
    width: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 24px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const VoteNumber = styled.h6`
    font-size: 16px;
    font-family: "gordita_medium";
    text-align: center;
    color: #000000;
    @media all and (max-width: 480px) {
        width: 24px;
    }
`;
const DownVote = styled.div`
    width: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 24px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const EditComments = styled.div`
    background-color: #fafafa;
    border: 1px solid #e4e4e7;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    h6 {
        font-size: 16px;
        color: #3f3f46;
        font-family: "gordita_medium";
        margin-bottom: 10px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    input {
        width: 100%;
        margin-bottom: 10px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const ChangeButton = styled.button`
    margin-right: 10px;
    color: #0fa76f;
    cursor: pointer;
`;
const CancelButton = styled.button`
    color: #70707b;
    cursor: pointer;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;

const AnswerContainer = styled.section`
    padding: 30px 0px;
`;
const CommentsButton = styled.div`
    color: #fe673a;
    font-size: 14px;
    cursor: pointer;
    font-family: "gordita_medium";
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const CommentsImage = styled.div`
    margin-right: 10px;
    width: 20px;
    position: absolute;
    top: 10px;
    img {
        width: 100%;
        display: block;
    }
`;
const Messages = styled.div`
    width: 100%;
    display: flex;
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    padding: 0 24px 0 24px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const CommentsSection = styled.div`
    display: flex;
    position: relative;
`;
const Comment = styled.div`
    margin-bottom: 15px;
    h6 {
        font-size: 16px;
        color: #3f3f46;
        font-family: "gordita_medium";
        display: inline-block;
        margin-right: 6px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    p {
        display: contents;
        font-size: 16px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    small {
        color: #d1d1d6;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 16px;
        @media all and (max-width: 480px) {
            font-size: 12px;
        }
    }
    span {
        width: 20px;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        :last-child {
            margin-right: 0;
        }
        img {
            display: block;
            width: 100%;
        }
    }
`;
const ReadsData = styled.h4`
    color: #fff;
    font-size: 14px;
    height: 16px;
    &.empty {
        text-align: center;
        width: 100%;
    }
    @media all and (max-width: 1380px) {
        font-size: 12px;
        height: 18px;
    }
`;
const OtherMessages = styled.div`
    margin-bottom: 15px;
    h6 {
        font-size: 16px;
        color: #3f3f46;
        font-family: "gordita_medium";
        display: inline-block;
        margin-right: 6px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    p {
        display: contents;
        font-size: 16px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    small {
        color: #d1d1d6;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 16px;
        @media all and (max-width: 480px) {
            font-size: 12px;
        }
    }
`;
const FlagImage = styled.div`
    width: 15px;
    display: inline-block;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SortConrtainer = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 8px 10px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 12px;
    span {
        font-family: "gordita_regular";
        font-size: 14px;
        color: #fff;
    }
`;
const SortIcon = styled.div`
    width: 25%;
    img {
        width: 100%;
        display: block;
        margin-left: 4px;
    }
`;
const HeadTitle = styled.h6`
    font-size: 20px;
    color: #fff;
    font-family: "gordita_medium";
    margin-bottom: 10px;
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const InputWrapper = styled.div`
    outline: none;
    border: 1px solid #2c2c2c;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    margin-bottom: 30px;
    @media (max-width: 360px) {
        width: 100%;
    }
    @media (max-width: 320px) {
        width: 100%;
    }
`;
const SelectInput = styled.input`
    @media (max-width: 480px) {
        width: 125px;
        /* margin-right: 13px; */
    }
    @media (max-width: 360px) {
        width: 110px;
    }
    @media (max-width: 320px) {
        width: 100px;
    }
`;
const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    p {
        font-size: 14px;
        margin-right: 5px;
        color: #697586;
    }
    @media (max-width: 768px) {
        margin-bottom: 20px;
        p {
            display: none;
        }
    }
`;
const SelectIcon = styled.div`
    cursor: pointer;
`;
const ArrowImage = styled.img`
    display: block;
    width: 100%;
`;

const Answer = styled.div`
    display: flex;
    border-bottom: 1px solid #2c2c2c;
    margin-top: 30px;
    margin-bottom: 30px;
    :last-child {
        margin-bottom: 0px;
    }
`;
const VotesContainer = styled.div`
    width: 30px;
    margin-right: 20px;
`;

const TickImage = styled.div`
    width: 30px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 24px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const AnswerList = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;
const Student = styled.div`
    display: flex;
`;
const StudentImage = styled.div`
    width: 40px;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;
const StudentsDetails = styled.div``;
const DotIcon = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        display: inline-block;
    }
`;
const StudentsName = styled.h4`
    font-family: "gordita_medium";
    font-size: 14px;
    color: #fff;
`;
const StudentsPosition = styled.p`
    font-size: 14px;
    color: #fe673a;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const Icons = styled.div`
    display: flex;
`;
const Save = styled.div`
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 24px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const Saved = styled.div`
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 24px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const Three = styled.div`
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 24px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const AnswerText = styled.p`
    font-size: 14px;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const AnswerDetails = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const DotContainer = styled.div`
    position: relative;
`;
const TimeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 480px) {
        display: block;
    }
`;
const ButtonsContainer = styled.div`
    @media all and (max-width: 480px) {
        margin-bottom: 15px;
    }
`;
const PostedTime = styled.div`
    display: flex;
    align-items: center;
`;
const EditedTime = styled.h6`
    margin-right: 10px;
    font-size: 14px;
    color: #364152;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const PostTime = styled.h6`
    font-size: 13px;
    color: #7b7b7b;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const YourAnswer = styled.div``;
const Title = styled.h6`
    font-size: 22px;
    color: #fff;
    font-family: "gordita_medium";
    margin-bottom: 10px;
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const EditorContainer = styled.div`
    margin-bottom: 14px;
`;
// const Editor = styled.div`
//     border-radius: 8px;
//     background: linear-gradient(0deg, #ffffff, #ffffff),
//         linear-gradient(0deg, #cdd5df, #cdd5df);
//     border: 1px solid #cdd5df;
//     box-shadow: 0px 1px 2px 0px #1018280d;
//     width: 100%;
// `;
const PostButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    button {
    }
`;
const Button = styled.button`
    padding: 8px 12px;
    min-width: 100px;
    background-color: #fe673a;
    font-family: "gordita_medium";
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const Tips = styled.div`
    background: #edfcf2;
    padding: 10px 25px;
    border-radius: 8px;
`;
const TipsTitle = styled.h4`
    font-size: 18px;
    color: #26272b;
    font-family: "gordita_medium";
    margin-bottom: 10px;
`;
const Content = styled.div`
    padding-left: 30px;
    ul {
        li {
            font-size: 16px;
            margin-bottom: 5px;
            list-style: disc;
            &:last-child {
                margin-bottom: 0;
            }
            @media all and (max-width: 480px) {
                font-size: 14px;
            }
        }
    }
`;
const EditedComments = styled.div`
    background-color: #fafafa;
    border: 1px solid #e4e4e7;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    h6 {
        font-size: 16px;
        color: #3f3f46;
        font-family: "gordita_medium";
        margin-bottom: 10px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    input {
        width: 100%;
        margin-bottom: 10px;
    }
`;
const ChangedButton = styled.button`
    margin-right: 10px;
    color: #fe673a;
    cursor: pointer;
`;
const CanceldButton = styled.button`
    color: #70707b;
    cursor: pointer;
`;
const ButtonChange = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const PosteTime = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default ViewPage;
