import React from "react";
import styled from "styled-components";

function AepResourceCard({ student, pagination, index }) {
    return (
        <StudentCard>
            <ItemList>
                {pagination?.first_item <= pagination?.last_item && (
                    <Item type="slno">
                        <p>{pagination?.first_item + index}</p>
                    </Item>
                )}
                <Item type="name">
                    <p>{student.name ? student.name : "--"}</p>
                    <p style={{ color: "#ef3d3d" }}>
                        {student.phone ? student.phone : "--"}
                    </p>
                </Item>
                <Item type="edu">
                    <p>
                        {student.class_or_course
                            ? student.class_or_course
                            : "--"}
                    </p>
                </Item>
                <Item type="category">
                    <p>{student.category ? student.category : "--"}</p>
                </Item>
                <Item type="applied">
                    <p>
                        {student.subscription_start_date
                            ? student.subscription_start_date
                            : "--"}
                    </p>
                </Item>
                <Item type="amount">
                    <p>{student.amount ? student.amount : "--"}</p>
                </Item>
            </ItemList>
        </StudentCard>
    );
}

export default AepResourceCard;
const StudentCard = styled.div``;
const ItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 20px;
    color: #fff;

    &.active {
        background: #121214;
    }
`;
const Item = styled.li`
    font-size: 14px;
    text-transform: capitalize;

    width: ${({ type }) =>
        type === "slno"
            ? "4%"
            : type === "name"
            ? "20%"
            : type === "edu"
            ? "10%"
            : type === "category"
            ? "12%"
            : type === "applied"
            ? "10%"
            : type === "amount"
            ? "10%"
            : "14%"};
    list-style: none;

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    p {
        font-size: 14px;
        color: #fff;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.pending {
        color: red;
    }
    &.completed {
        color: green;
    }
`;
