import React from "react";
import styled from "styled-components";

function TechUpdatePopupModal() {
    return (
        <>
            <ContentBox>
                <CloseButton>
                    <img
                        src={
                            require("../../../../assets/images/close.svg")
                                .default
                        }
                    />
                </CloseButton>
                <ContentDiv>
                    <ProfileDiv>
                        <img
                            src={
                                require("../../../../assets/images/person.svg")
                                    .default
                            }
                        />
                    </ProfileDiv>
                    <ProfileContent>
                        <NameText>@Sobir</NameText>
                        <ContentText>
                            Your article needs to be revised.
                        </ContentText>
                        <TimeText>2:04:14 AM</TimeText>
                    </ProfileContent>
                    <ViewButton>View details </ViewButton>
                </ContentDiv>
            </ContentBox>
        </>
    );
}

export default TechUpdatePopupModal;

const ContentBox = styled.div`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    width: 31%;
`;
const CloseButton = styled.div`
    position: absolute;
    left: 40%;
    top: 9%;
    img {
        display: block;
        width: 100%;
    }
`;
const ContentDiv = styled.div`
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
`;
const ProfileDiv = styled.div`
    padding-bottom: 25px;
    img {
        display: block;
        width: 100%;
    }
`;
const ProfileContent = styled.div``;
const NameText = styled.h1`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
`;
const ContentText = styled.p`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
`;
const TimeText = styled.span`
    color: #4f5360;
    font-family: "gordita_medium";
    font-size: 12px;
`;
const ViewButton = styled.button`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    padding: 6px 12px;
    align-items: center;
    color: #fe673a;
    font-family: "gordita_medium";
    font-size: 12px;
`;
