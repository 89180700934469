import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import SectionLoader from "../../../helpers/SectionLoader";
const NextGen = lazy(() => import("../../../screens/next-gen/NextGen"));

function NextGenRouter() {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Routes>
                <Route path="" element={<NextGen />} />
            </Routes>
        </Suspense>
    );
}

export default NextGenRouter;
