import { useState, useContext, useEffect } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { communityConfig, accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "./NoData";
import ReactPaginate from "react-paginate";
import SchoolDetails from "./SchoolDetails";
import axios from "axios";

function SchoolList() {
    const [schoolList, setSchoolList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [fileData, setFileData] = useState(null);

    const {
        state: {
            user_data: { access_token },
            student_data: {},
        },
        dispatch,
    } = useContext(Context);
    const page = searchParams.get("page") ? searchParams.get("page") : "";
    const q = searchParams.get("q") ? searchParams.get("q") : "";
    const [isPageLoading, setPageLoading] = useState(false);
    const [results, setResult] = useState([]);
    const [pagination, setPagination] = useState({});
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    useEffect(() => {
        setLoading(true);
        communityConfig
            .get(`school-scientists/campus-list/?page=${page}&q=${q}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setSchoolList(data);

                    setLoading(false);
                    setPagination(pagination_data);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [searchParams, currentPage]);

    const handleDownload = (pk) => {
        accountsConfig
            .get(`/api/v1/users/scs/download/school/exam-winners/${pk}/`, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })

            .then((response) => {
                setFileData(response.data);
            });
    };

    useEffect(() => {
        if (!fileData) {
            return;
        }

        const url = window.URL.createObjectURL(fileData);
        const link = document.createElement("a");
        link.href = url;
        link.download = "excel-file.xlsx";
        link.click();
    }, [fileData]);

    return (
        <>
            <Container>
                <SchoolDetails />
                {isLoading ? (
                    <SectionLoader />
                ) : schoolList?.length > 0 ? (
                    schoolList.map((school, index) => {
                        return (
                            <Cover to={`${school.id}?campus=${school.name}`}>
                                <Item type="slno">
                                    {pagination?.first_item <=
                                        pagination?.last_item && (
                                        <Item type="slno">
                                            {pagination?.first_item + index}
                                        </Item>
                                    )}
                                </Item>
                                <Item>{school.name}</Item>
                                <Item type="slno">
                                    {school?.campus_data?.up_students}
                                </Item>
                                <Item type="slno">
                                    {school?.campus_data?.hs_students}
                                </Item>
                                <Item type="slno">
                                    {school?.campus_data?.hss_students}
                                </Item>
                                <Item type="dist">
                                    {school?.campus_data?.total_students}
                                </Item>
                                <Item
                                    type="dist"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDownload(school.id);
                                    }}
                                >
                                    <Images
                                        src={
                                            require("../../../assets/images/enquirey/Down01.svg")
                                                .default
                                        }
                                    />
                                </Item>
                            </Cover>
                        );
                    })
                ) : (
                    <NoData />
                )}
                {!isLoading &&
                    pagination?.total_pages > 1 &&
                    schoolList?.length > 0 && (
                        <PaginationContainer>
                            <PaginationText>
                                Showing Admissions {pagination?.first_item} -{" "}
                                {pagination?.last_item} of{" "}
                                {pagination?.total_items}
                            </PaginationText>
                            <ReactPaginate
                                previousLabel={"< Prev"}
                                nextLabel={"Next >"}
                                pageCount={pagination?.total_pages}
                                onPageChange={handlePageClick}
                                forcePage={currentPage - 1}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />
                        </PaginationContainer>
                    )}
            </Container>
        </>
    );
}
const Container = styled.div`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    max-width: 100%;
    overflow: scroll;
    background-color: #1b1c1f;
`;
const Cover = styled(NavLink)`
    display: flex;
    border-bottom: 1px solid #26272a;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    min-width: 1180px;
`;
const Item = styled.li`
    list-style: none;
    width: ${({ type }) =>
        type === "slno" ? "8%" : type === "dist" ? "11%" : "15%"};
    /* :last-child{
			text-align: end;
		} */
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    margin-left: 20px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const Images = styled.img``;

export default SchoolList;
