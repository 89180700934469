// import "./styles.css";

import React, { useState, useEffect } from "react";

// Recursive function to render the document
function renderDocument(node) {
    switch (node.type) {
        case "doc":
            return <div>{node.content.map(renderDocument)}</div>;
        case "paragraph":
            return (
                <p
                    style={{
                        textAlign: node.attrs?.textAlign,
                        margin: 0,
                        marginBlock: 3,
                        padding: 0,
                    }}
                >
                    {node.content?.map(renderDocument)}
                </p>
            );
        case "heading":
            const Tag = `h${node.attrs.level}`; // Dynamic heading level h1, h2, etc.
            return (
                <Tag
                    style={{
                        textAlign: node.attrs?.textAlign,
                        margin: 0,
                        marginBlock: 5,
                        padding: 0,
                    }}
                >
                    {node.content?.map(renderDocument)}
                </Tag>
            );
        case "bulletList":
            return <ul>{node.content?.map(renderDocument)}</ul>;
        case "orderedList":
            return (
                <ol start={node.attrs.start}>
                    {node.content?.map(renderDocument)}
                </ol>
            );
        case "listItem":
            return <li>{node.content?.map(renderDocument)}</li>;

        case "codeBlock":
            // return <Pre>{node.content?.map(renderDocument)}</Pre>;
            return (
                <pre
                    style={{
                        // backgroundColor: "#0D121C",
                        padding: "12px",
                        color: "#B7B7B7",
                        fontSize: "14px",
                        margin: "19px 0",
                        maxWidth: "280px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <code>{node.content?.map(renderDocument)}</code>
                </pre>
            );

        case "image":
            return (
                <img
                    src={node.attrs.src}
                    alt={node.attrs.alt}
                    style={{ width: "100px", borderRadius: "6px" }}
                />
            );
        case "text":
            let styles = {};
            if (node.marks) {
                // Combine all mark styles
                if (node.marks[0].type === "link") {
                    return (
                        <a
                            href={
                                node.marks[0].attrs.href
                                    ? node.marks[0].attrs.href
                                    : "javascript:void(0)"
                            }
                            target={node.marks[0].attrs.href && "_blank"}
                            style={{
                                marginInline: 2,
                                fontSize: "14px",
                                color: "#fff",
                                maxWidth: "280px",
                                overflow: "scroll",
                            }}
                            title={node.text}
                        >
                            {node.text}
                        </a>
                    );
                }
                styles = node.marks.reduce((acc, mark) => {
                    switch (mark.type) {
                        case "bold":
                            acc.fontWeight = "bold";
                            break;
                        case "italic":
                            acc.fontStyle = "italic";
                            break;
                        case "underline":
                            acc.textDecoration = "underline";
                            break;
                        // case 'link':
                        //     return (
                        //         <a href='javascript:void(0)' target="_blank">{node.text}</a>
                        //     )
                        // Add other mark styles
                    }
                    return acc;
                }, {});
            }

            return <span style={styles}>{node.text}</span>;
        // Add cases for other node types like codeBlock, blockquote, image, etc.
        default:
            // console.log(node,"NODE")
            return null;
    }
}

function TiptapViewerTrunc({ content }) {
    const [document, setDocument] = useState(null);

    // const jsonResponse = {
    //     type: "doc",
    //     content: [],
    // };

    useEffect(() => {
        // If the content is actually a string containing JSON, parse it to get the document structure.
        // const content = jsonResponse.content[0];
        // const parsedDocument = JSON.parse(content);
        if (content != null) {
            setDocument(content);
        }
    }, [content]);

    return (
        <div>
            {document ? (
                renderDocument(JSON.parse(document))
            ) : (
                <p>Loading content...</p>
            )}
        </div>
    );
}

export default TiptapViewerTrunc;
