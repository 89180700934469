import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loader from "../../../assets/lotties/chat/chat-loader.json";

export default function ChatLoader() {
    const loaderOprions = {
        loop: false,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <Container>
            <Lottie options={loaderOprions} height={261} width="24%" />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;
