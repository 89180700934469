import React, { useState, useContext, useEffect } from "react";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import styled from "styled-components";
import { accountsConfig, learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import MemberManagement from "./MemberManagemen";
import { useParams } from "react-router-dom";
import $ from "jquery";

function AddStudent({
    studenModal,
    setShowstudentModal,
    successful,
    setSuccessful,
    setReload,
    isReload,
}) {
    const [members, setMembers] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const selectedUserIdsString = JSON.stringify(selectedUserIds);
    const [error, setError] = useState("");
    const { group_id } = useParams();
    const [isButtonLoading, setButtonLoading] = useState(false);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    //-----------for blocking background scroll when modal is open-------------
    useEffect(() => {
        if (studenModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [studenModal]);

    const fetchData = () => {
        setLoading(true);
        accountsConfig
            .get(`api/v1/users/events/list-profiles/?q=${searchInput}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, data, pagination_data } = response.data;

                if (status_code === 6000) {
                    setMembers(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })

            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [searchInput]);

    // api connection for form create

    const onSubmit = () => {
        setButtonLoading(true);
        const formData = new FormData();
        formData.append("students", selectedUserIdsString);

        learnConfig
            .post(`events/group/add-students/${group_id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, data, message } = response.data;
                if (status_code === 6000) {
                    setShowstudentModal(false);
                    setSuccessful(true);
                    setButtonLoading(false);
                } else {
                    setButtonLoading(false);
                    setError(message.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            {studenModal && (
                <Backdrop>
                    <Modal>
                        <Container>
                            <TopContainer>
                                <span>Add Student</span>
                                <img
                                    onClick={() => {
                                        setShowstudentModal(false);
                                    }}
                                    src={
                                        require("../../../../assets/images/close-icon.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            </TopContainer>
                            <MemberManagement
                                members={members}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                selectedUserIds={selectedUserIds}
                                setSelectedUserIds={setSelectedUserIds}
                                setError={setError}
                            />
                            <Error className="error">
                                {error === "students : This field is required."
                                    ? "Please select a member"
                                    : error}
                            </Error>
                            <CreateButtonBox>
                                <CancelButton
                                    onClick={() => {
                                        setShowstudentModal(false);
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                                <CreateButton onClick={onSubmit}>
                                    {isButtonLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Submit"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        </Container>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
}

export default AddStudent;

const Label = styled.label`
    color: rgba(123, 123, 123, 1);
    font-size: 14px;
    margin-right: 34px;
    &:last-child {
        margin-right: 0px;
    }
`;

const InputFiled = styled.input`
    width: 100%;
    color: #fff;
    padding: 12px 16px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    border: 1px solid #3b3b3b;
    margin-top: 4px;
    margin-bottom: 6px;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: "gordita_medium";
        font-weight: lighter;
        font-style: normal;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    width: 100%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 1000;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(59, 59, 59, 1);

    span {
        color: white;
    }
    img {
        cursor: pointer;
        width: 40px;
    }
`;

const FormContainer = styled.div`
    margin-top: 18px;
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;

const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
const StyledSelect = styled.select`
    cursor: pointer;
    padding: 12px 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #3b3b3b;
    color: #fff;
    width: 100%;
`;
const FileContainer = styled.div`
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    img {
        position: absolute;
        right: 3px;
        top: 8px;
    }
`;
const InputFileField = styled.label`
    position: relative;
    width: 100%;
    color: #fff;
    padding: 12px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    align-items: center;
    margin-top: 4px;

    input {
        display: none;
    }

    img {
        margin-right: 10px;
    }

    span {
        overflow: scroll;
        white-space: nowrap;
        margin-right: 40px;
        color: ${(props) =>
            props.fileSelected ? "#fff" : "rgba(79, 83, 96, 1)"};
        cursor: pointer;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Error = styled.p`
    font-size: 13px;
    color: #ff3333;
    margin-left: 2px;
    text-align: right;
    position: absolute;
    right: 22px;
    bottom: 10%;
`;
