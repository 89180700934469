import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

function GroupCard({ item, pagination, index, setSelectedCard }) {
    const handleCardClick = (item) => {
        setSelectedCard(item);
    };

    return (
        <>
            <ItemList
                to={`${item.id}`}
                onClick={() => {
                    handleCardClick(item.id);
                }}
            >
                <Item type="slno">
                    {pagination?.first_item <= pagination?.last_item && (
                        <Item type="slno">{pagination.first_item + index}</Item>
                    )}
                </Item>
                <Item type="photo">
                    <ImgDiv>
                        {item?.icon ? (
                            <img src={item?.icon} alt="icon" />
                        ) : (
                            <Avatar name={item?.name} size="40" round />
                        )}
                    </ImgDiv>
                </Item>
                <Item type="group">
                    <Name>{item?.name}</Name>
                </Item>
                <Item type="part">{item?.no_of_participants}</Item>
                <Item type="status">
                    <Status status={item?.status}>{item?.status}</Status>
                </Item>
                <Item className="flex">
                    <EditButton>
                        <img
                            src={
                                require("../../../assets/images/Edit.svg")
                                    .default
                            }
                            alt="Edit"
                        />
                    </EditButton>
                </Item>
            </ItemList>
        </>
    );
}

export default GroupCard;

const ItemList = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 18px 15px;
    color: #fff;

    &.active {
        background: #121214;
    }

    :hover {
        background: rgba(22, 22, 25, 1);
    }
`;
const Item = styled.li`
    font-size: 14px;
    /* text-align: ${({ type }) =>
        type === "seats" || type === "reg" ? "center" : "left"}; */

    h3 {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
    }
    h6 {
        font-size: 14px;
    }
    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    p {
        width: 60%;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    width: ${({ type }) =>
        type === "slno"
            ? "10%"
            : type === "photo"
            ? "15%"
            : type === "group"
            ? "20%"
            : type === "part"
            ? "15%"
            : type === "status"
            ? "10%"
            : "10%"};
    list-style: none;

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    &.flex {
        display: flex;
        align-items: center;
    }
`;
const EditButton = styled.div`
    border-radius: 8px;
    width: 40px;
    background-color: #2c2c2c;
    padding: 10px;
    margin-right: 10px;

    img {
        width: 100%;
        display: block;
    }
`;
const InfoDiv = styled.div`
    display: flex;
`;
const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
const Name = styled.h5`
    font-size: 18px;
    text-transform: capitalize;
`;
const Desig = styled.h6`
    color: #fe673a;
    font-size: 14px;
    text-transform: capitalize;
`;
const Status = styled.small`
    color: ${(props) => {
        switch (props.status) {
            case "Archived":
                return "#FF1D5C";
            case "Active":
                return "#0FA76F";
        }
    }};
    background-color: ${(props) => {
        switch (props.status) {
            case "Archived":
                return "rgba(54, 25, 32, 0.4)"; // Adjust the alpha value here
            case "Active":
                return "rgba(15, 167, 111, 0.2)"; // Adjust the alpha value here
        }
    }};
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 25px;
    text-transform: capitalize;
    display: inline-block;
`;

const InnerBox = styled.div`
    background: #2c2c2c;
    border: 1px solid #364152;
    padding: 8px;
    border-radius: 6px;
    display: inline-block;
`;
