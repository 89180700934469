import React from "react";
import styled from "styled-components";
import SectionLoader from "../../helpers/SectionLoader";

export default function LeftScoreboard({ studentDetail }) {
    const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    // function to convert sentence into sentence case
    const getSentenceCase = (str) => {
        const sentenceCase = str.charAt(0).toUpperCase() + str.slice(1);
        return sentenceCase;
    };
    return (
        <>
            <Container>
                <TitleDiv>
                    <LeftDiv>
                        <Title> Scoreboard</Title>
                        <SubTitle>
                            Dashboard / Students list /Scoreboard{" "}
                        </SubTitle>
                    </LeftDiv>
                    <RightDiv>
                        <QuestionNumber>
                            {studentDetail.length} Questions
                        </QuestionNumber>
                    </RightDiv>
                </TitleDiv>
                <ContentDiv>
                    {studentDetail.map((item, index) => (
                        <ContainerDiv key={item.id}>
                            <LeftContainer>
                                <IdDiv>{index + 1}.</IdDiv>
                            </LeftContainer>
                            <RightContainer>
                                <Sentence>{item.question}</Sentence>
                                <TotalScore>Total Score</TotalScore>
                                <ScoreContainer>
                                    {number.map((marks) => (
                                        <ScoreDiv
                                            className={
                                                +item.rating >= marks
                                                    ? "active"
                                                    : ""
                                            }
                                            type={+item.rating}
                                        >
                                            {marks}
                                        </ScoreDiv>
                                    ))}
                                </ScoreContainer>
                                {item?.description && (
                                    <>
                                        <Description>Description</Description>
                                        <Paragraph>
                                            {getSentenceCase(item.description)}
                                        </Paragraph>
                                    </>
                                )}
                            </RightContainer>
                        </ContainerDiv>
                    ))}
                </ContentDiv>
            </Container>
        </>
    );
}
const Container = styled.div`
    width: 72%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const ContentDiv = styled.div`
    max-height: calc(100vh - 95px);
`;
const TitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;
const LeftDiv = styled.div``;
const Title = styled.h3`
    font-size: 20px;
    color: #fff;
    font-family: "gordita_medium";
    margin-bottom: 2px;
    @media all and (max-width: 1400px) {
        font-size: 18px;
    }
`;
const SubTitle = styled.span`
    color: #737375;
    font-size: 16px;
`;
const RightDiv = styled.div``;
const QuestionNumber = styled.span`
    font-size: 16px;
    color: #fff;
`;
const ContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background: #1b1c20;
    padding: 15px;
    border-radius: 5px;
    margin-top: 14px;
`;
const LeftContainer = styled.div``;
const IdDiv = styled.div`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    display: inline-block;
`;
const RightContainer = styled.div`
    margin-left: 10px;
    width: 99%;
`;
const Sentence = styled.p`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_medium";
    border-bottom: 1px solid #26272a;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:after {
        content: "";

        background-color: #fff;
        position: absolute;
        right: 15px;
        top: 10px;
    }
`;
const TotalScore = styled.span`
    font-size: 14px;
    color: #737375;
    margin-bottom: 5px;
    display: inline-block;
`;
const ScoreContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const ScoreDiv = styled.div`
    margin-right: 10px;
    width: 40px;
    height: 40px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #26272a;
    background: #161619;
    &.active {
        background: ${({ type }) =>
            type <= 3 ? "red" : type >= 4 && type < 8 ? "#fe673a" : "green"};
    }
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    :last-child {
        margin-right: 0;
    }
`;
const Description = styled.span`
    color: #737375 !important;
    font-size: 14px;
    margin: 15px 0 5px 0;
    display: inline-block;
`;
const Paragraph = styled.p`
    font-size: 14px;
    color: #fff;
`;
