import React, { useState, useContext, useEffect } from "react";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import styled from "styled-components";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";

function EditGroup({
    showModal,
    setShowModal,
    setSuccessful,
    setReload,
    groupData,
    successful,
}) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const [file, setFile] = useState("");
    const [fileErr, setFileErr] = useState("");
    const [nameErr, setNameError] = useState("");
    const [name, setName] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [isButtonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        setName(groupData.name ?? "--");
    }, [groupData]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleTitleChange = (e) => {
        setName(e.target.value);
        setNameError("");
    };

    const resetStates = () => {
        setFileErr("");
        setNameError("");
    };

    // api connection for form Edit

    const handleEditGroup = () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("icon", file);

        setButtonLoading(true);
        learnConfig
            .post(`events/edit/group/${groupData.id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, data } = response.data;
                if (status_code === 6000) {
                    setShowModal(false);
                    resetStates();
                    setButtonLoading(false);
                    setSuccessful(true);
                } else {
                    setErrorMessage(data.message);
                    setButtonLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    return (
        <>
            {showModal && (
                <Backdrop>
                    <Modal>
                        <Container>
                            <TopContainer>
                                <span>Edit Group</span>
                                <img
                                    onClick={() => {
                                        setShowModal(false);
                                        resetStates();
                                    }}
                                    src={
                                        require("../../../../assets/images/close-icon.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            </TopContainer>
                            <FormContainer>
                                <FileContainer>
                                    <Label>Thumbnail image</Label>
                                    <InputFileField
                                        fileSelected={file !== null}
                                    >
                                        <input
                                            id="photo"
                                            className="file"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <img
                                            src={
                                                require("../../../../assets/images/FileUpload.svg")
                                                    .default
                                            }
                                            alt="FileUpload"
                                        />
                                        <span>
                                            {file
                                                ? file.name
                                                : "Upload thumbnail image"}
                                        </span>
                                    </InputFileField>
                                </FileContainer>
                                <Label>Group name</Label>
                                <InputFiled
                                    placeholder="Type here"
                                    onChange={handleTitleChange}
                                    value={name}
                                ></InputFiled>
                                <Error className="error">{errorMessage}</Error>
                            </FormContainer>
                            <CreateButtonBox>
                                <CancelButton
                                    onClick={() => {
                                        setShowModal(false);
                                        resetStates();
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                                <CreateButton onClick={handleEditGroup}>
                                    {isButtonLoading ? (
                                        <ButtonLoader width={25} />
                                    ) : (
                                        "Update"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        </Container>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
}

export default EditGroup;

const Label = styled.label`
    color: rgba(123, 123, 123, 1);
    font-size: 14px;
    margin-right: 34px;
    &:last-child {
        margin-right: 0px;
    }
`;

const InputFiled = styled.input`
    width: 100%;
    color: #fff;
    padding: 12px 16px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    border: 1px solid #3b3b3b;
    margin-top: 4px;
    margin-bottom: 6px;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: "gordita_medium";
        font-weight: lighter;
        font-style: normal;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    width: 100%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 1000;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(59, 59, 59, 1);
    span {
        color: white;
    }
    img {
        cursor: pointer;
        width: 40px;
    }
`;

const FormContainer = styled.div`
    margin-top: 18px;
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;

const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
const StyledSelect = styled.select`
    cursor: pointer;
    padding: 12px 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #3b3b3b;
    color: #fff;
    width: 100%;
`;
const FileContainer = styled.div`
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    img {
        position: absolute;
        right: 3px;
        top: 8px;
    }
`;
const InputFileField = styled.label`
    position: relative;
    width: 100%;
    color: #fff;
    padding: 12px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    align-items: center;
    margin-top: 4px;

    input {
        display: none;
    }

    img {
        margin-right: 10px;
    }

    span {
        overflow: scroll;
        white-space: nowrap;
        margin-right: 40px;
        color: ${(props) =>
            props.fileSelected ? "#fff" : "rgba(79, 83, 96, 1)"};
        cursor: pointer;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Error = styled.p`
    font-size: 12px;
    color: #ff3333;
    margin-left: 2px;
    text-align: right;
    position: absolute;
    right: 22px;
`;
