import React, { useContext, useEffect } from "react";
import { useState } from "react";
//packages
import styled from "styled-components";
import $ from "jquery";
//components
import RegistrationModal from "../modals/RegistrationModal";
import IdProofModal from "../modals/IdProofModal";
import PanCardModal from "../modals/PanCardModal";
import { useParams } from "react-router-dom";
import { accountsConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";

function BasicDetails({ aepDetails }) {
    const [isRegistrationModal, setRegistrationModal] = useState(false);
    const [isIdModal, setIdModal] = useState(false);
    const [isPanModal, setPanModal] = useState(false);

    useEffect(() => {
        if (isRegistrationModal || isIdModal || isPanModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isRegistrationModal || isIdModal || isPanModal]);

    return (
        <MainContainer>
            <RegistrationModal
                isRegistrationModal={isRegistrationModal}
                setRegistrationModal={setRegistrationModal}
            />
            <IdProofModal isIdModal={isIdModal} setIdModal={setIdModal} />
            <PanCardModal isPanModal={isPanModal} setPanModal={setPanModal} />
            <BasicDetailsDiv>
                <Container>
                    <LeftContainer>
                        <InputDiv>
                            <Titles>Agent Name:</Titles>
                            <InputData>
                                {aepDetails.basic_details?.name}
                            </InputData>
                        </InputDiv>
                        <InputDiv>
                            <Titles>Address:</Titles>
                            <InputDataAdress>
                                {aepDetails.basic_details?.location}
                            </InputDataAdress>
                        </InputDiv>
                    </LeftContainer>
                    <RightContainer>
                        <InputDiv>
                            <Titles>Company Name:</Titles>
                            <InputData>Talrop</InputData>
                        </InputDiv>
                        <InputDiv>
                            <Titles>Contact Number:</Titles>
                            <InputData>
                                {aepDetails.basic_details?.phone}
                            </InputData>
                        </InputDiv>
                        <InputDiv>
                            <Titles>Alternate Number:</Titles>
                            <InputData>
                                {aepDetails.basic_details?.alternate_phone}
                            </InputData>
                        </InputDiv>
                    </RightContainer>
                </Container>
                <BottomContainer>
                    <RegistrationDiv onClick={() => setRegistrationModal(true)}>
                        Registration
                    </RegistrationDiv>
                    <IdproofDiv onClick={() => setIdModal(true)}>
                        ID Proof
                    </IdproofDiv>
                    <PanCardDiv onClick={() => setPanModal(true)}>
                        PAN card
                    </PanCardDiv>
                </BottomContainer>
            </BasicDetailsDiv>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    margin-bottom: 30px;
`;
const BasicDetailsDiv = styled.div`
    background-color: rgb(27, 28, 31);
    border: 1px solid #26272a;
    border-radius: 5px;
    min-width: 1180px;
    padding: 20px;
    overflow-x: scroll;
    @media all and (max-width: 480px) {
        flex-direction: column;
        & :first-child {
            margin-top: 0;
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;
const LeftContainer = styled.div`
    width: 40%;
`;
const Titles = styled.p`
    font-size: 14px;
    margin-bottom: 5px;
`;
const InputDiv = styled.div`
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
`;
const InputData = styled.p`
    text-transform: capitalize;
    font-size: 14px;
    font-family: gordita_regular;
    color: rgb(255, 255, 255);
`;
const InputDataAdress = styled.p`
    text-transform: capitalize;
    font-size: 14px;
    font-family: gordita_regular;
    color: rgb(255, 255, 255);
    width: 50%;
`;
const RightContainer = styled.div`
    width: 40%;
`;
const BottomContainer = styled.div`
    width: 100%;
    display: flex;
`;
const RegistrationDiv = styled.div`
    cursor: pointer;
    font-size: 16px;
    font-family: gordita_medium;
    color: rgb(254, 103, 58);
    text-decoration: underline;
    margin-right: 40px;
`;
const IdproofDiv = styled.div`
    cursor: pointer;
    font-size: 16px;
    font-family: gordita_medium;
    color: rgb(254, 103, 58);
    text-decoration: underline;
    margin-right: 40px;
`;
const PanCardDiv = styled.div`
    cursor: pointer;
    font-size: 16px;
    font-family: gordita_medium;
    color: rgb(254, 103, 58);
    text-decoration: underline;
`;
export default BasicDetails;
