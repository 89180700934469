import { useContext } from "react";
import { learnConfig } from "../../../axiosConfig";
import VideoPlayer from "../../applications/video-player/src/VideoPlayer";
import { Context } from "../../contexts/Store";

export default class SteypPlayer {
    static get toolbox() {
        return {
            title: "Steyp Video Player",
            icon: '<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.963,0-9-4.038-9-9S7.037,3,12,3s9,4.038,9,9-4.037,9-9,9Zm-3-13.5l8,4.5-8,4.5V7.5Z"/></svg>',
        };
    }

    constructor({ data, config }) {
        this.data = data;
        this.config = config;
    }

    static get isReadOnlySupported() {
        return true;
    }
    render() {
        const container = document.createElement("div");
        container.style = "padding:30px;background:#f5f5f5;";
        const label = container.appendChild(document.createElement("label"));
        label.textContent = "Video Player Here";
        const input = container.appendChild(document.createElement("input"));
        input.style =
            "width:100%;border:1px solid #000; padding:5px;border-radius:10px;margin-top:10px;background:#fff;";
        input.value = this.data && this.data.url ? this.data.url : "";
        input.placeholder = "Enter Video m3u8 Link";
        const imageLabel = container.appendChild(document.createElement("h4"));
        imageLabel.textContent = "Upload Thumbnail";
        imageLabel.style = "margin:10px 0;";
        const image = container.appendChild(document.createElement("input"));
        image.type = "file";
        image.accept = "image/png, image/gif, image/jpeg";

        // image.value =
        //     this.data && this.data.thumbnail ? this.data.thumbnail : "";
        if (this.data && this.data.thumbnail) {
            // Create an <img> element to display the existing thumbnail
            const imgDisplay = container.appendChild(
                document.createElement("img")
            );
            imgDisplay.src = this.data.thumbnail;
            imgDisplay.alt = "Current Thumbnail";
            imgDisplay.style = "width:100%; display:block;margin-top:10px"; // Set max width for display
        }
        return container;
    }

    async save(blockContent) {
        const formData = new FormData();
        formData.append("video_url", blockContent.children[1].value);
        formData.append("thumbnail", blockContent.children[3].files[0]);

        let thumbnail = "";

        if (blockContent.children[3].files.length > 0) {
            const response = await learnConfig.post(
                `/tech-updates/upload-article-video-thumbnail/${this.config.article_pk}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${this.config.access_token}`,
                    },
                }
            );

            thumbnail = response.data.data.thumbnail;
            console.log(thumbnail, "Thumbnail");
            return {
                url: blockContent.children[1].value,
                thumbnail: thumbnail,
            };
        } else if (this.data && this.data.thumbnail) {
            return {
                url: blockContent.children[1].value,
                thumbnail: this.data.thumbnail,
            };
        } else {
            return {
                url: blockContent.children[1].value,
                thumbnail: "",
            };
        }
    }
}
