import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { accountsConfig, activityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import SteypHelmet from "../../helpers/SteypHelmet";
import DetailView from "../../includes/operations-head/DetailView";
import listData from "../../includes/operations-head/Listdata";
import RmCard from "../../includes/operations-head/RmCard";
import DatePicker from "react-date-picker";
import { getDDMMYY, getYYMMDD, getYYMMDDnew } from "../../helpers/functions";
import { Link } from "react-router-dom";

function SrhStatus() {
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	const [selectedRM, setSelectedRM] = useState("");
	const [rmDetails, setRMDetails] = useState([]);
	const [isRMLoading, setRMLoading] = useState(true);
	const [rmStatuses, setRMStatuses] = useState([]);
	const [active, setActive] = useState("Today");
	const [isShow, setShow] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [isStatusLoading, setStatusLoading] = useState(false);
	const [currentDate, setCurrentDate] = useState();
	const [isButtonClicked, setButtonClicked] = useState(false);
	const [selectedDate, setSelectedDate] = useState("");
	const today = new Date();

	useEffect(() => {
		setStartDate(today);
	}, []);

	const getBottomFilterParams = () => {
		let filterParams = {};
		startDate
			? (filterParams.date = getYYMMDDnew(startDate))
			: (filterParams.date = getYYMMDDnew(currentDate));
		return filterParams;
	};

	//-----------------------RM data fetching-------------------------

	const fetchRMsDetails = () => {
		setRMLoading(true);
		const filterParams = getBottomFilterParams();
		accountsConfig
			.get("/api/v1/users/srh/get-followup-executives/", {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				params: filterParams,
			})
			.then((response) => {
				let { StatusCode, data, pagination_data } = response.data;
				if (StatusCode === 6000) {
					setRMDetails(data);
					setRMLoading(false);
				} else {
					setRMLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setRMLoading(false);
			});
	};
	useEffect(() => {
		(startDate || currentDate) && fetchRMsDetails();
	}, [startDate, currentDate]);

	//------------------------------------------------------------------

	const getFilterParams = () => {
		let filterParams = {};
		selectedRM && (filterParams.executive_pk = selectedRM);
		startDate
			? (filterParams.date = getYYMMDDnew(startDate))
			: (filterParams.date = getYYMMDDnew(currentDate));
		return filterParams;
	};

	//---------------------RM student Fetching --------------------------
	useEffect(() => {
		const fetchRMsStatus = () => {
			const filterParams = getFilterParams();
			setStatusLoading(true);
			activityConfig
				.get("followups/srh/followup/", {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
					params: filterParams,
				})
				.then((response) => {
					let { StatusCode, data, pagination_data } = response.data;
					if (StatusCode === 6000) {
						setRMStatuses(data);
						setStatusLoading(false);
					} else {
						setStatusLoading(false);
						setRMStatuses([]);
					}
				})
				.catch((error) => {
					console.log(error);
					setStatusLoading(false);
				});
		};

		if (selectedRM) fetchRMsStatus();
	}, [selectedRM, startDate]);

	//------------------------------------------------------------------
	function useOutsideClick(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShow(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef);
	//--------------------------------------------------------------------------------------------

	const handleFilterDate = (d) => {
		const convertDates = getDDMMYY(d);
		setStartDate(d);
		setActive(convertDates);
	};

	//-----------------------------------------------------------------------------------------
	return (
		<>
			<SteypHelmet title="Follow Up" />
			<Cover>
				<Container id="main">
					<Bottom selected={selectedRM ? true : false}>
						<TopBar>
							<Left>Status</Left>

							<Right>
								<Filter
									onClick={() => setShow(!isShow)}
									ref={wrapperRef}
								>
									<FilterText>{active}</FilterText>
									<FIlterImage>
										<img
											src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
											alt="Image"
										/>
									</FIlterImage>
								</Filter>
								<Popup
									ref={wrapperRef}
									className={isShow ? "dropdown" : ""}
								>
									<DateHead>Select date</DateHead>
									<Dates
										// to={`?date=${getYYMMDD(startDate)}`}
										to=""
										onClick={() => {
											setActive("Selected Date");
											setButtonClicked(true);
										}}
									>
										<DatePicker
											format="dd-MM-yy"
											dayPlaceholder="DD"
											monthPlaceholder="MM"
											yearPlaceholder="YY"
											onChange={
												(value) =>
													setSelectedDate(value)
												// setFromDate(value)
											}
											value={selectedDate}
											clearIcon={null}
											calendarIcon={null}
											maxDate={today}
										/>
									</Dates>
									{active === "Selected Date" && (
										<Button
											onClick={() => {
												setShow(false);
												handleFilterDate(selectedDate);
												setActive(
													getDDMMYY(selectedDate)
												);
											}}
										>
											Filter
										</Button>
									)}
								</Popup>
							</Right>
						</TopBar>
						{isRMLoading ? (
							<SectionLoader />
						) : rmDetails.length > 0 ? (
							<Cards
								length={rmDetails.length}
								selected={selectedRM ? true : false}
							>
								{rmDetails.map((item) => (
									<div key={item.id}>
										<RmCard
											student={item}
											setSelectedRM={setSelectedRM}
											selectedRM={selectedRM}
										/>
									</div>
								))}
							</Cards>
						) : (
							<ImageBox>
								<CardImage
									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
									alt="Image"
								/>
								<p>No data found</p>
							</ImageBox>
						)}
					</Bottom>
					<RightSection selected={selectedRM ? true : false}>
						<Text selected={selectedRM ? true : false}>
							Detailed view
						</Text>
						<DetailView
							rmStatuses={rmStatuses}
							selectedRM={selectedRM}
							isStatusLoading={isStatusLoading}
							startDate={startDate}
						/>
					</RightSection>
				</Container>
			</Cover>
		</>
	);
}

export default SrhStatus;

const Container = styled.div`
	display: flex;
	jusify-content: space-between;
	min-width: 1200px;
`;
const Cover = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const TopBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	margin-bottom: 10px;
`;
const Left = styled.div`
	font-size: 20px;
	font-family: "gordita_medium";
	@media all and (max-width: 1400px) {
		font-size: 18px;
	}
`;
const Right = styled.div`
	position: relative;
`;
const Overlay = styled.div`
	position: absolute;
	height: 84vh;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const Cards = styled.div`
	display: grid;
	grid-gap: 15px;
	grid-template-columns: ${({ length, selected }) =>
		selected ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
	position: relative;
`;

const Bottom = styled.div`
	width: ${({ selected }) => (selected ? "70%" : "100%")};
	transition: 1s ease;
	margin-right: ${({ selected }) => (selected ? "20px" : "0")};
`;
const RightSection = styled.div`
	width: ${({ selected }) => (selected ? "30%" : "0%")};
	transition: 1s ease;
`;
const Text = styled.div`
	color: #fff;
	margin-bottom: 10px;
	margin-top: 6px;
	display: ${({ selected }) => (selected ? "block" : "none")};
	transition: 1s ease;
	font-size: 18px;
`;
const FilterText = styled.div`
	margin-right: 5px;
	color: #fff;
	font-size: 14px;
`;
const FIlterImage = styled.div`
	& img {
		width: 12px;
	}
`;
const Filter = styled.span`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	border-radius: 5px;
	background: #1b1c1f;
	padding: 0 15px;
	cursor: pointer;

	p {
		font-size: 14px;
		color: #fff;
	}
`;
const Popup = styled.div`
	font-size: 14px;
	width: 240px;
	padding: 10px;
	position: absolute;
	right: 0px;
	max-height: 0;
	overflow: hidden;
	transition: 0.5s ease;
	opacity: 0;
	top: 50px;
	&.dropdown {
		opacity: 1;
		overflow: visible;
		box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
		background: #1b1c20;
		max-height: 390px;
		transition: 0.5s ease;
		border-radius: 5px;
		z-index: 1;
	}
`;
const Dates = styled(Link)`
	text-align: center;
	background: #161618;
	border: 1px solid #28292d;
	border-radius: 4px;
	color: #fff;
	padding: 10px 0;
	cursor: pointer;
	.react-datepicker__day:hover {
		border: 1px solid #fff !important;
		color: #fff !important;
		background-color: red !important;
	}
`;
const Button = styled.div`
	width: 120px;
	height: 40px;
	background: #ff5722;
	text-align: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	margin: 0 auto;
	margin-top: 15px;
	color: #fff;
`;
const DateHead = styled.div`
	margin: 15px 0 10px 14px;
	color: #c8c8c8;
`;
const ImageBox = styled.div`
	margin: auto;
	width: 20%;
	margin-top: 170px;
	height: calc(100% - (-50px));
	margin-bottom: 170px;
	@media all and (max-width: 980px) {
		width: 200px;
	}
	p {
		color: #465663;
		font-size: 18px;
		font-family: "gordita_medium";
		text-align: center;
		margin-top: 15px;
	}
`;
const CardImage = styled.img`
	width: 100%;
	display: block;
`;
