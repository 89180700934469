import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import SectionLoader from "../../../helpers/SectionLoader";
import ProjectsEvaluationPage from "../../../screens/projects-section/ProjectsEvaluationPage";
import CommonRules from "../../../screens/projects-section/CommonRules";
import AddProjectScreen from "../../../screens/projects-section/AddProjectScreen";
import ProjectOverViewScreen from "../../../screens/projects-section/ProjectOverViewScreen";
const ProjectDashboard = lazy(() =>
    import("../../../screens/projects-section/ProjectDashboard")
);
const ProjectAddDashboard = lazy(() =>
    import("../../../screens/projects-section/ProjectAddDashboard")
);

function ProjectsRouter() {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Routes>
                <Route path="current/" element={<ProjectAddDashboard />} />
                <Route path="evaluation/" element={<ProjectDashboard />} />
                <Route
                    path="evaluation/:evaluation_id/"
                    element={<ProjectsEvaluationPage />}
                />
                <Route path="common-rules/" element={<CommonRules />} />
                <Route path="add-project/" element={<AddProjectScreen />} />
                <Route
                    path="project-single/:slug/"
                    element={<ProjectOverViewScreen />}
                />
            </Routes>
        </Suspense>
    );
}

export default ProjectsRouter;
