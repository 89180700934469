import React from 'react';
import styled from 'styled-components';


function TechUpdateNotificationModal() {
  return (
    <>
        <MessageBox>
            <CloseButton>
                <img
                    src={
                            require("../../../../assets/images/close.svg")
                            .default
                        }
                    />  
            </CloseButton>
            <MessageDiv>
                <ContentBox>
                    <ProfileDiv>
                            <img
                                src={
                                        require("../../../../assets/images/person.svg")
                                        .default
                                    }
                            />
                    </ProfileDiv>
                    <NameDiv>
                        <NameText>@sobir reviewed the article, here are a few changes and corrections that needs to be made.</NameText>
                    </NameDiv>
                </ContentBox>
                <ButtonDiv>
                    <ViewButton>View details </ViewButton>
                </ButtonDiv>
                    
            </MessageDiv>
        </MessageBox>
    </>
  )
}

export default TechUpdateNotificationModal;

const MessageBox = styled.div`
    width: 100%;
    border-radius: 8px;
    background: #2C2C2C;
    position: relative;
    
`;
const CloseButton = styled.div`
    position: absolute;
    right: -1%;
    top: -11%;
    img{
        display: block;
        width: 100%;
    }
`;
const MessageDiv = styled.div`
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
`;
const ProfileDiv = styled.div`
    width: 7%;
    margin-right: 10px;
    img{
        display: block;
        width: 100%;
    }
`;
const ContentBox = styled.div`
    display: flex;
    align-items: center;
`;
const NameDiv = styled.div``;
const NameText = styled.h1`
    color: #FFF;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
`;
const ButtonDiv = styled.div`
    padding: 10px;
`;
const ViewButton = styled.button`
    border-radius: 6px;
    border: 1px solid #FFF;
    background: #2C2C2C;
    padding: 6px 12px;
    color: #FE673A;
    font-family: "gordita_medium";
    font-size: 12px;
`;