import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TechEditor from "../content-manager/TechEditor";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function ProjectsAddingScreen({
    setEditorContent,
    setSelectedSkill,
    addNewProject,
    selectedSkill,
    setGithubLink,
    editorContent,
    setFigmaLink,
    setThumbnail,
    setVideoUrl,
    isDraftLoading,
    setDraftMessage,
    draftMessage,
    githubLink,
    setAssets,
    thumbnail,
    figmaLink,
    isLoading,
    setTitle,
    setShortDescription,
    videoUrl,
    skills,
    assets,
    title,
    short_description,
    tag,
    setTag,
    newTag,
    setNewTag,
    error,
    setError,
    orderId,
    setOrderID,
    dateToComplete,
    setDateToComplete,
    point,
    setPoint,
    projectStatus,
    getLatestOrderId,
    errorMessage,
}) {
    const [isDrop, setIsDrop] = useState(false);
    const assetsInputRef = useRef(null);
    const fileInputRef = useRef(null);

    //TagRemove
    const removeSelectedTags = (tagname) => {
        const newTags = tag.filter((item) => item.name !== tagname);
        setTag(newTags);
    };

    useEffect(() => {
        if (error || draftMessage) {
            const timer = setTimeout(() => {
                setError("");
                setDraftMessage("");
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, [error, draftMessage]);

    const onFileChange = (e, type) => {
        if (type === "thumbnail") {
            setThumbnail(e.target.files[0]);
        } else {
            setAssets(e.target.files[0]);
        }
    };
    const thumbnailClick = () => {
        fileInputRef.current.click();
    };
    const assetsClick = () => {
        assetsInputRef.current.click();
    };

    return (
        <Container>
            <MianDiv>
                <Left>
                    <HeadContainer>
                        <HeadText>Description & Requirements</HeadText>
                        <ButtonContainer>
                            <HeadButton
                                className="save"
                                onClick={() => addNewProject("draft")}
                            >
                                {isDraftLoading ? (
                                    <ButtonLoader width={25} height={25} />
                                ) : (
                                    " Save as draft"
                                )}
                            </HeadButton>
                            <HeadButton
                                onClick={() => addNewProject("publish")}
                            >
                                {isLoading ? (
                                    <ButtonLoader width={25} height={25} />
                                ) : (
                                    <>
                                        {projectStatus === "published"
                                            ? "Published"
                                            : "Publish"}
                                    </>
                                )}
                            </HeadButton>
                        </ButtonContainer>
                    </HeadContainer>
                    <p className="error">
                        {errorMessage ? errorMessage : error ? error : ""}
                    </p>
                    <p className="draft">{draftMessage ? draftMessage : ""}</p>
                    <EditorContainer>
                        <TechEditor
                            editorContent={editorContent}
                            setEditorContent={setEditorContent}
                        />
                    </EditorContainer>
                </Left>
                <Right>
                    <RightContainer>
                        <HeadSection>
                            <HeadText className="sub">
                                Create new project
                            </HeadText>
                            <SubHead>Add new project for the students.</SubHead>
                        </HeadSection>
                        <AddContainer>
                            <AddList>
                                <AddItem>
                                    <Label>Skill *</Label>
                                    <SkillContainer
                                        onClick={() => {
                                            setIsDrop(!isDrop);
                                        }}
                                    >
                                        {selectedSkill
                                            ? selectedSkill?.name
                                            : "Select Skill"}
                                    </SkillContainer>
                                    {isDrop && (
                                        <DoropDownContainer>
                                            <List>
                                                {skills?.map((item, index) => (
                                                    <Items
                                                        key={index}
                                                        onClick={() => {
                                                            setSelectedSkill(
                                                                item
                                                            );
                                                            getLatestOrderId(
                                                                item?.id
                                                            );
                                                            setIsDrop(!isDrop);
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Items>
                                                ))}
                                            </List>
                                        </DoropDownContainer>
                                    )}
                                </AddItem>
                                <AddItem>
                                    <Label>Title *</Label>
                                    <TitleInput
                                        type="text"
                                        placeholder="Enter title"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                </AddItem>
                                <AddItem>
                                    <Label>Short Description *</Label>
                                    <TitleInput
                                        type="text"
                                        placeholder="Enter short description"
                                        value={short_description}
                                        onChange={(e) =>
                                            setShortDescription(e.target.value)
                                        }
                                    />
                                </AddItem>
                                <AddItem className="addValue">
                                    <ItemDiv>
                                        <Label>Order ID</Label>
                                        <TitleInput
                                            type="number"
                                            placeholder="Enter order ID"
                                            value={orderId}
                                            onChange={(e) => {
                                                const inputValue =
                                                    e.target.value;
                                                if (inputValue.length <= 5) {
                                                    setOrderID(inputValue);
                                                }
                                            }}
                                        />
                                    </ItemDiv>
                                    <ItemDiv>
                                        <Label>Days To Complete</Label>
                                        <TitleInput
                                            type="number"
                                            placeholder="Enter the day"
                                            value={dateToComplete}
                                            onChange={(e) => {
                                                const inputValue =
                                                    e.target.value;
                                                if (inputValue.length <= 5) {
                                                    setDateToComplete(
                                                        inputValue
                                                    );
                                                }
                                            }}
                                        />
                                    </ItemDiv>
                                    <ItemDiv>
                                        <Label>Point</Label>
                                        <TitleInput
                                            type="number"
                                            placeholder="Enter the point"
                                            value={point}
                                            onChange={(e) => {
                                                const inputValue =
                                                    e.target.value;
                                                if (inputValue.length <= 5) {
                                                    setPoint(inputValue);
                                                }
                                            }}
                                        />
                                    </ItemDiv>
                                </AddItem>
                                <AddItem>
                                    <Label>Tags</Label>
                                    <TagMainContainer>
                                        <TagAddContainer>
                                            {tag.length > 0 &&
                                                tag.map((tagObj, index) => (
                                                    <TagSmall key={index}>
                                                        <TagText>
                                                            {tagObj.name}
                                                        </TagText>
                                                        <TagSpan
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeSelectedTags(
                                                                    tagObj.name
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    require("../../../assets/images/close-icon.svg")
                                                                        .default
                                                                }
                                                                alt="Category"
                                                            />
                                                        </TagSpan>
                                                    </TagSmall>
                                                ))}
                                        </TagAddContainer>
                                        <TagContainer
                                            type="text"
                                            placeholder="Add the required tags"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setNewTag(e.target.value);
                                            }}
                                            value={newTag}
                                            onKeyPress={(e) => {
                                                if (
                                                    e.key === "Enter" &&
                                                    newTag.trim() !== ""
                                                ) {
                                                    setNewTag("");
                                                    setTag([
                                                        ...tag,
                                                        {
                                                            name: newTag.trim(),
                                                        },
                                                    ]);
                                                }
                                            }}
                                        />
                                    </TagMainContainer>
                                </AddItem>
                                <AddItem>
                                    <Label>Introductory video</Label>
                                    <TitleInput
                                        type="text"
                                        placeholder="Add video url"
                                        value={videoUrl}
                                        onChange={(e) =>
                                            setVideoUrl(e.target.value)
                                        }
                                    />
                                </AddItem>
                                <AddItem>
                                    <Label>Thumbnail image *</Label>
                                    <AttachmentDiv onClick={thumbnailClick}>
                                        <Icon className="upload">
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-01-2024/upload-cloud.svg"
                                                alt="upload-icon"
                                            />
                                        </Icon>
                                        <AttachmentText>
                                            {thumbnail ? (
                                                <span>{thumbnail.name}</span>
                                            ) : (
                                                <>
                                                    <span>Click to upload</span>
                                                    or drag and drop PNG, JPG or
                                                    MP4(max. 5MB)
                                                </>
                                            )}
                                        </AttachmentText>
                                        <FileInput
                                            type="file"
                                            accept="image/*"
                                            ref={fileInputRef}
                                            onChange={(e) =>
                                                onFileChange(e, "thumbnail")
                                            }
                                        />
                                    </AttachmentDiv>
                                </AddItem>
                                <AddItem>
                                    <Label>Figma file link</Label>
                                    <TitleInput
                                        type="text"
                                        placeholder="Add Link"
                                        value={figmaLink}
                                        onChange={(e) =>
                                            setFigmaLink(e.target.value)
                                        }
                                    />
                                </AddItem>
                                <AddItem>
                                    <Label>Github repository link</Label>
                                    <TitleInput
                                        type="text"
                                        placeholder="Add github url"
                                        value={githubLink}
                                        onChange={(e) =>
                                            setGithubLink(e.target.value)
                                        }
                                    />
                                </AddItem>
                                <AddItem>
                                    <Label>Assets</Label>
                                    <AttachmentDiv onClick={assetsClick}>
                                        <Icon className="upload">
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-01-2024/upload-cloud.svg"
                                                alt="upload-icon"
                                            />
                                        </Icon>
                                        <AttachmentText>
                                            {assets ? (
                                                <span>
                                                    {assets.name ?? "No file"}
                                                </span>
                                            ) : (
                                                <>
                                                    <span>Click to upload</span>
                                                    or drag and drop ZIP file
                                                    (max. 50MB)
                                                </>
                                            )}
                                        </AttachmentText>
                                        <FileInput
                                            type="file"
                                            ref={assetsInputRef}
                                            accept=".zip"
                                            onChange={(e) =>
                                                onFileChange(e, "assets")
                                            }
                                        />
                                    </AttachmentDiv>
                                </AddItem>
                            </AddList>
                        </AddContainer>
                    </RightContainer>
                </Right>
            </MianDiv>
        </Container>
    );
}

export default ProjectsAddingScreen;

const Container = styled.div`
    height: fit-content;
`;
const MianDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HeadText = styled.h4`
    font-size: 14px;
    color: #94a3b8;
    font-family: "gordita_medium";
    &.sub {
        color: #fff;
        font-size: 16px;
        margin-bottom: 6px;
    }
`;
const HeadButton = styled.button`
    border-radius: 8px;
    background-color: #fe673a;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
    height: 40px;
    width: 48%;
    padding: 8px 4px;
    cursor: pointer;
    &.save {
        background-color: #1b1c1f;
        color: #fe673a;
        border: 1px solid #3b3b3b;
    }
`;

const EditorContainer = styled.div`
    margin-top: 20px;
    width: 100%;
    border-radius: 8px !important;
    overflow-x: hidden;
    overflow-y: scroll;
    height: fit-content;
    & .codex-editor__redactor {
        padding: 10px !important;
        min-height: 84vh !important;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;

const Left = styled.div`
    width: 58%;
    border: 1px solid #2c2c2c;
    border-radius: 8px;
    background: #1b1c1f;
    padding: 24px;
    p.error {
        /* position: absolute; */
        color: red;
        font-size: 12px;
        text-align: right;
        margin-top: 10px;
    }
    p.draft {
        color: #08de33;
        font-size: 12px;
        text-align: right;
        margin-top: 10px;
    }
`;
const Right = styled.div`
    width: 40%;
    border: 1px solid #2c2c2c;
    border-radius: 8px;
    background: #1b1c1f;
    padding: 24px;
    height: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 40%;
    justify-content: space-between;
`;

const RightContainer = styled.div``;

const HeadSection = styled.div`
    border-bottom: 1px solid #2c2c2c;
    margin-bottom: 20px;
`;
const SubHead = styled.p`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
    margin-bottom: 20px;
`;

const AddContainer = styled.div``;
const AddList = styled.ul``;
const AddItem = styled.li`
    position: relative;
    margin-bottom: 20px;
    :last-child {
        margin-bottom: 0;
    }
    &.addValue {
        display: flex;
        justify-content: space-between;
    }
`;

const Label = styled.label`
    color: #94a3b8;
    font-size: 13px;
    font-family: "gordita_medium";
`;

const TitleInput = styled.input`
    width: 100%;
    border: 1px solid #3d3d3d;
    padding: 14px 8px;
    margin-top: 10px;
    color: #fff;
    background-color: #161619 !important;
    font-size: 16px;
    font-family: "gordita_regular";
    border-radius: 8px;
`;
const AttachmentDiv = styled.div`
    border-radius: 12px;
    border: 1px solid #3d3d3d;
    background: #161619;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    .drag-active {
        &:hover {
            filter: blur(5px);
            transition: filter 0.3s ease;
        }
    }
`;
const AttachmentText = styled.div`
    width: 50%;
    margin-top: 12px;
    font-size: 12px;
    color: #94a3b8;
    font-family: "gordita_regular";
    line-height: 18px;
    text-align: center;
    /* max-height: 150px; */

    img {
        /* width: 100%; */
        height: 80%;
        display: block;
    }

    span {
        font-family: "gordita_medium";
        color: #fe673a;
        font-size: 14px;
        display: inline-block;
        margin-right: 4px;
    }
`;
const FileInput = styled.input`
    display: none;
`;
const Icon = styled.span`
    display: inline-block;
    width: 20px;
    &.upload {
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #3d3d3d;
        background: #161619;
    }
    &.file-icon {
        width: 40px;
    }
    img {
        width: 100%;
        display: block;
    }
`;

const SkillContainer = styled.div`
    width: 100%;
    border: 1px solid #3d3d3d;
    padding: 14px 8px;
    margin-top: 10px;
    color: #fff;
    background-color: #161619 !important;
    font-size: 16px;
    font-family: "gordita_regular";
    border-radius: 8px;
    cursor: pointer;
`;

const DoropDownContainer = styled.div`
    width: 100%;
    border: 1px solid #3d3d3d;
    position: absolute;
    margin-top: 10px;
    color: #fff;
    background-color: #161619 !important;
    font-size: 16px;
    font-family: "gordita_regular";
    border-radius: 8px;
    z-index: 999;
    top: 80px;
    max-height: 190px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const List = styled.ul``;
const Items = styled.li`
    border-bottom: 1px solid #3d3d3d;
    cursor: pointer;
    padding: 12px 8px;
    :last-child {
        margin-bottom: 0;
        border-bottom: none;
    }
    &:hover {
        background-color: #3d3d3d;
    }
`;
const TagMainContainer = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;

const TagContainer = styled.input`
    width: 100%;
    border: 1px solid #3d3d3d;
    padding: 14px 8px;
    margin-top: 10px;
    color: #fff;
    background-color: #161619 !important;
    font-size: 16px;
    font-family: "gordita_regular";
`;

const TagAddContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const TagSmall = styled.span`
    text-overflow: ellipsis;
    border-radius: 4px;
    background: #161619;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
`;

const TagText = styled.p`
    color: #fff;
    font-family: gordita_medium;
    font-size: 16px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media all and (max-width: 1280px) {
        max-width: 120px;
    }
`;

const TagSpan = styled.span`
    display: inline-block;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const ItemDiv = styled.div`
    width: 32%;
`;
