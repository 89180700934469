import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}

const DropDown = ({ editor }) => {
    const [isDrop, setIsDrop] = useState(false);
    const dropDownRef = useRef(null);

    useOutsideAlerter(dropDownRef, () => setIsDrop(false));

    return (
        <DropDownContainer>
            <SelectButton
                className={
                    editor.isActive("heading") ? "is-active-drop" : "icon-drop"
                }
                onClick={() => setIsDrop((prev) => !prev)}
            >
                <Icon
                    src={
                        "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/a.svg"
                    }
                    alt="Text"
                />
            </SelectButton>
            {/* {isDrop && ( */}
            <SelectContainer
                style={{
                    opacity: isDrop ? 1 : 0,
                    transform: isDrop ? "scaleY(1)" : "scaleY(0)",
                    visibility: isDrop ? "visible" : "hidden",
                }}
                ref={dropDownRef}
            >
                <SelectButton
                    onClick={() => {
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 1 })
                            .run();
                        setIsDrop((prev) => !prev);
                    }}
                    className={
                        editor.isActive("heading", { level: 1 })
                            ? "is-active"
                            : "icon"
                    }
                >
                    H1
                </SelectButton>
                <SelectButton
                    onClick={() => {
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 2 })
                            .run();
                        setIsDrop((prev) => !prev);
                    }}
                    className={
                        editor.isActive("heading", { level: 2 })
                            ? "is-active"
                            : "icon"
                    }
                >
                    H2
                </SelectButton>
                <SelectButton
                    onClick={() => {
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 3 })
                            .run();
                        setIsDrop((prev) => !prev);
                    }}
                    className={
                        editor.isActive("heading", { level: 3 })
                            ? "is-active"
                            : "icon"
                    }
                >
                    H3
                </SelectButton>
                <SelectButton
                    onClick={() => {
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 4 })
                            .run();
                        setIsDrop((prev) => !prev);
                    }}
                    className={
                        editor.isActive("heading", { level: 4 })
                            ? "is-active"
                            : "icon"
                    }
                >
                    H4
                </SelectButton>
                <SelectButton
                    onClick={() => {
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 5 })
                            .run();
                        setIsDrop((prev) => !prev);
                    }}
                    className={
                        editor.isActive("heading", { level: 5 })
                            ? "is-active"
                            : "icon"
                    }
                >
                    H5
                </SelectButton>
                <SelectButton
                    onClick={() => {
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 6 })
                            .run();
                        setIsDrop((prev) => !prev);
                    }}
                    className={
                        editor.isActive("heading", { level: 6 })
                            ? "is-active"
                            : "icon"
                    }
                >
                    H6
                </SelectButton>
            </SelectContainer>
            {/* )} */}
        </DropDownContainer>
    );
};
const DropDownContainer = styled.div`
    position: relative;
`;
const Icon = styled.img`
    display: block;
    width: 100%;
`;
const SelectButton = styled.button`
    background: none;

    padding: 10px;
    border: none;
    &.is-active {
        border: none;
        color: #000;
        background: #00000024;
        border-radius: 4px;
        padding: 8px 12px;
        width: 100%;
    }
    &.icon {
        background: transparent;
        padding: 8px 12px;
        border: none;
        width: 100%;
    }
    &.is-active-drop {
        border: none;
        color: #000;
        background: #00000024;
        border-radius: 4px;
        width: 100%;
        padding: 5px;
    }
    &.icon-drop {
        background: transparent;
        padding: 5px;
        border: none;
        width: 100%;
    }
`;
const SelectContainer = styled.div`
    background-color: #fff;
    box-shadow: 0px 1px 13px 0px #10182833;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 35px;
    overflow: hidden;
    z-index: 999;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
    transform-origin: top;
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
`;
export default DropDown;
