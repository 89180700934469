import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { Context } from "../../../contexts/Store";

function FeedbackModal({
    isFeedback,
    setFeedback,
    setNumber,
    overallReviewDescription,
    setOverallReviewDescription,
    setOverallReview,
    setDescription,
    submitFeedback,
    isSubmitFeedbackLoading,
    setContinue,
    isContinue,
}) {
    useEffect(() => {
        if (isFeedback) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isFeedback]);
    const {
        state: {
            student_data: { student_name, phone, student_photo },
        },
    } = useContext(Context);

    return (
        <MainContainer>
            {isFeedback ? (
                <Overlay
                    style={{ display: isFeedback ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isFeedback && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <Top>
                            <Head>
                                <MainTitle>Exam Review</MainTitle>
                            </Head>
                            {/* <Close>
                                <CloseIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Close Icon"
                                    onClick={() => {
                                        setContinue(!isContinue);
                                        setFeedback(false);
                                        setNumber(0);
                                        setOverallReview("");
                                        setOverallReviewDescription("");
                                    }}
                                />
                            </Close> */}
                        </Top>
                        <NameSection>
                            {student_photo && (
                                <ProPic>
                                    <Span>
                                        <img src={student_photo} />
                                    </Span>
                                </ProPic>
                            )}
                            <NameSec>
                                <Name>{student_name?.toLowerCase()}</Name>
                                <Phone>{phone}</Phone>
                            </NameSec>
                        </NameSection>
                        <Middle>
                            <>
                                <Details>
                                    <DetailsHead>
                                        Description about student
                                    </DetailsHead>
                                    <DetailsContent
                                        onChange={(e) => {
                                            setOverallReviewDescription(
                                                e.target.value
                                            );
                                            setDescription(e.target.value);
                                        }}
                                        value={overallReviewDescription}
                                    ></DetailsContent>
                                </Details>
                            </>
                        </Middle>
                        <Button>
                            <Next
                                type="cancel"
                                onClick={() => {
                                    setContinue(!isContinue);
                                    setFeedback(false);
                                    setNumber(0);
                                    setOverallReview("");
                                    setOverallReviewDescription("");
                                }}
                            >
                                Cancel
                            </Next>
                            {overallReviewDescription != "" ? (
                                <Next
                                    onClick={() => {
                                        submitFeedback();
                                    }}
                                >
                                    {isSubmitFeedbackLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Submit"
                                    )}
                                </Next>
                            ) : (
                                <Next style={{ cursor: "not-allowed" }}>
                                    Submit
                                </Next>
                            )}
                        </Button>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default FeedbackModal;

const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 650px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 35px 30px;
    width: 100%;
    height: 100%;
`;
const Top = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
`;
const NameSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 15px;
    border-bottom: 1px solid #2c2c2c;
`;
const ProPic = styled.div`
    margin-right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    font-family: "gordita_bold";
    border-radius: 50%;
    min-width: 50px;
`;
const Span = styled.span`
    width: 40px;
    height: 40px;

    img {
        width: 100%;
        display: block;
    }
`;
const NameSec = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;
const Name = styled.span`
    font-size: 18px;
    margin-bottom: 2px;
    text-transform: capitalize;
`;
const Phone = styled.span`
    font-size: 16px;
    color: #fe673a;
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
`;
const Close = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const Middle = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SubTitle = styled.h3`
    margin-bottom: 20px;
    color: #fff;
    font-size: 16px;
    font-family: gordita_medium;
`;
const StatusList = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
`;
const StatusDetail = styled.li`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    background: #161619;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    margin-bottom: 15px;
    // &:last-child {
    //     margin-bottom: 0;
    // }
    &.active {
        border: 1px solid #fe673a;
    }
`;
const Select = styled.div`
    &.hide {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #1b1c1f;
        border: 1px solid #3b3b3b;
    }
`;
const Orangetickcontainer = styled.div`
    width: 70%;
`;
const Orangetick = styled.img`
    width: 100%;
    display: block;
`;

const Status = styled.p`
    margin-left: 10px;
    font-size: 14px;
    font-family: gordita_regular;
    color: #fff;
    width: 90%;
`;
const Details = styled.div`
    margin-top: 15px;
    transition: 0.3s;
`;
const DetailsHead = styled.h3`
    margin-bottom: 10px;
    color: #fff;
    font-size: 16px;
    font-family: gordita_medium;
`;
const DetailsContent = styled.textarea`
    width: 100%;
    height: 150px;
    border: 1px solid #2c2c2c;
    background: #161619;
    border-radius: 5px;
    padding: 6px;
    color: #fff;
    font-size: 13px;
    outline: none;
    resize: none;
    font-family: gordita_regular;
`;
const Button = styled.div`
    display: flex;
    position: relative;
    color: #fff;
    justify-content: flex-end;
    margin-top: 30px;
`;
const Cancel = styled.div`
    border: 2px solid #fe673a;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-right: 25px;
    cursor: pointer;
    background-color: #1b1c1f;
    font-family: gordita_regular;
`;
const Next = styled.button`
    background: ${({ type }) => (type === "cancel" ? "#161619" : "#fe673a")};
    border: 1px solid #fe673a;
    color: #fff;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: gordita_regular;
    margin-right: ${({ type }) => (type === "cancel" ? "20px" : "0px")};
`;
