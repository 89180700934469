import React, { lazy, useContext } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../../contexts/Store";
//components
const StudentInnerRouter = lazy(() => import("./StudentInnerRouter"));
const Students = lazy(() => import("../../../screens/students/Students"));
const PrimeAboutStudents = lazy(() =>
	import("../../../screens/students/PrimeAboutStudents")
);

function StudentRouter() {
	const { state } = useContext(Context);
	const role = state.user_data.role[0];
	return (
		<>
			{role === "admission_manager" ? (
				<Container id="main">
					<Routes>
						<Route path="/students/" element={<Students />} />
						<Route
							path="students/prime-programs/:student_id/:user_pk"
							element={<PrimeAboutStudents />}
						/>
						<Route
							path="students/:student_id/*"
							element={<StudentInnerRouter />}
						/>
					</Routes>
				</Container>
			) : (
				<Container id="main">
					<Routes>
						<Route path="/" element={<Students />} />
						<Route
							path="prime-programs/:student_id/:user_pk"
							element={<PrimeAboutStudents />}
						/>
						<Route
							path="/:student_id/*"
							element={<StudentInnerRouter />}
						/>
					</Routes>
				</Container>
			)}
		</>
	);
}

export default StudentRouter;

const Container = styled.div``;
