import React, { useEffect, useState, useRef } from "react";
//packages
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getDDMMYY } from "../../helpers/functions";
//Components
import JobDeskDateModal from "../../includes/jobdesk/modals/JobDeskDateModal";

function JobDeskApplicantsFilter({
    activeClick,
    setActiveClick,
    today,
    isLoading,
    setLoading,
    startDate,
    setStartDate,
    selectedDate,
    setSelectedDate,
    searchWord,
    setSearchWord,
}) {
    const [isDropdown, setDropdown] = useState(false);
    const [isSearchClicked, setSearchClicked] = useState(false);
    const [isBox, setBox] = useState(false);
    const [isStatusBox, setStatusBox] = useState(false);
    const [isModal, setModal] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const handleModal = () => {
        setModal(!isModal);
    };
    const wrapperRef = useRef(null);

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDropdown(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);
    const handleFilterDate = (d) => {
        const convertDates = getDDMMYY(d);
        setActiveClick("Selected Date");
        setStartDate(convertDates);
    };

    return (
        <>
            <TopFilter>
                <CoverSet ref={wrapperRef}>
                    <Filter onClick={() => setDropdown(!isDropdown)}>
                        <FilterText>{activeClick}</FilterText>
                        <FIlterImage>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/filter.svg"
                                alt="Image"
                            />
                        </FIlterImage>
                    </Filter>
                    <Popup className={isDropdown ? "dropdown" : ""}>
                        <Date
                            onClick={() => {
                                setSearchClicked(true);
                                setActiveClick("Search");
                            }}
                            className="search"
                        >
                            <input
                                type="text"
                                placeholder="Search Here"
                                onChange={(e) => setSearchWord(e.target.value)}
                                value={searchWord}
                            ></input>
                            <SearchContainer
                                to={`?q=${searchWord}`}
                                onClick={() => {
                                    setDropdown(false);
                                }}
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-05-2022/search.svg"
                                    alt="Search"
                                />
                            </SearchContainer>
                        </Date>
                        <SelectOption
                            to={`?timestamp=today`}
                            className={activeClick === "Today" ? "active" : ""}
                            onClick={() => (
                                setActiveClick("Today"),
                                setDropdown(false),
                                setLoading(true)
                            )}
                        >
                            <Span>
                                {activeClick === "Today" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Today</Text>
                        </SelectOption>
                        <SelectOption
                            to={`?timestamp=this_week`}
                            className={
                                activeClick === "This Week" ? "active" : ""
                            }
                            onClick={() => (
                                setActiveClick("This Week"),
                                setDropdown(false),
                                setLoading(true)
                            )}
                        >
                            <Span>
                                {activeClick === "This Week" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>This Week</Text>
                        </SelectOption>
                        <SelectOption
                            to={`?timestamp=this_month`}
                            className={
                                activeClick === "This Month" ? "active" : ""
                            }
                            onClick={() => (
                                setActiveClick("This Month"),
                                setDropdown(false),
                                setLoading(true)
                            )}
                        >
                            <Span>
                                {activeClick === "This Month" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>This Month</Text>
                        </SelectOption>
                        <HrLine className="students"></HrLine>
                        {/* <InputField onClick={() => setBox(!isBox)}>
                            <div>Select Category</div>
                            <FIlterImage isBox={isBox}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                    alt="Image"
                                />
                            </FIlterImage>
                        </InputField>
                        {isBox && (
                            <DropDownDiv>
                                <SelectOption
                                    to={`?category=student`}
                                    className={
                                        activeClick === "student"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => (
                                        setActiveClick("category"),
                                        setDropdown(false),
                                        setBox(false),
                                        setLoading(true)
                                    )}
                                >
                                    <Span>
                                        {activeClick === "student" ? (
                                            <SelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                    alt="image"
                                                />
                                            </SelectedImg>
                                        ) : (
                                            <UnSelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                    alt="image"
                                                />
                                            </UnSelectedImg>
                                        )}
                                    </Span>
                                    <Text>Student</Text>
                                </SelectOption>
                                <SelectOption
                                    to={`?category=graduate`}
                                    className={
                                        activeClick === "Graduate"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => (
                                        setActiveClick("Category"),
                                        setDropdown(false),
                                        setBox(false),
                                        setLoading(true)
                                    )}
                                >
                                    <Span>
                                        {activeClick === "Graduate" ? (
                                            <SelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                    alt="image"
                                                />
                                            </SelectedImg>
                                        ) : (
                                            <UnSelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                    alt="image"
                                                />
                                            </UnSelectedImg>
                                        )}
                                    </Span>
                                    <Text>Graduate</Text>
                                </SelectOption>
                                <SelectOption
                                    to={`?category=drop_out`}
                                    className={
                                        activeClick === "Dropout"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => (
                                        setActiveClick("Category"),
                                        setDropdown(false),
                                        setBox(false),
                                        setLoading(true)
                                    )}
                                >
                                    <Span>
                                        {activeClick === "Dropout" ? (
                                            <SelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                    alt="image"
                                                />
                                            </SelectedImg>
                                        ) : (
                                            <UnSelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                    alt="image"
                                                />
                                            </UnSelectedImg>
                                        )}
                                    </Span>
                                    <Text>Dropout</Text>
                                </SelectOption>
                                <SelectOption
                                    to={`?category=employee`}
                                    className={
                                        activeClick === "employee"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => (
                                        setActiveClick("Category"),
                                        setDropdown(false),
                                        setBox(false),
                                        setLoading(true)
                                    )}
                                >
                                    <Span>
                                        {activeClick === "employee" ? (
                                            <SelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                    alt="image"
                                                />
                                            </SelectedImg>
                                        ) : (
                                            <UnSelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                    alt="image"
                                                />
                                            </UnSelectedImg>
                                        )}
                                    </Span>
                                    <Text>Employee</Text>
                                </SelectOption>
                            </DropDownDiv>
                        )} */}
                        <InputField onClick={() => setStatusBox(!isStatusBox)}>
                            <div>Interview Status</div>
                            <FIlterImageStatus isStatusBox={isStatusBox}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                    alt="Image"
                                />
                            </FIlterImageStatus>
                        </InputField>
                        {isStatusBox && (
                            <StatusDropDownDiv>
                                <SelectOption
                                    to={`?student_status=approved`}
                                    className={
                                        activeClick === "selected"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => (
                                        setActiveClick("Approved"),
                                        setDropdown(false),
                                        setBox(false),
                                        setStatusBox(false),
                                        setLoading(true)
                                    )}
                                >
                                    <Span>
                                        {activeClick === "selected" ? (
                                            <SelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                    alt="image"
                                                />
                                            </SelectedImg>
                                        ) : (
                                            <UnSelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                    alt="image"
                                                />
                                            </UnSelectedImg>
                                        )}
                                    </Span>
                                    <Text>Accepted</Text>
                                </SelectOption>
                                <SelectOption
                                    to={`?student_status=rejected`}
                                    className={
                                        activeClick === "Rejected"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => (
                                        setActiveClick("Rejected"),
                                        setDropdown(false),
                                        setBox(false),
                                        setStatusBox(false),
                                        setLoading(true)
                                    )}
                                >
                                    <Span>
                                        {activeClick === "Rejected" ? (
                                            <SelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                    alt="image"
                                                />
                                            </SelectedImg>
                                        ) : (
                                            <UnSelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                    alt="image"
                                                />
                                            </UnSelectedImg>
                                        )}
                                    </Span>
                                    <Text>Rejected</Text>
                                </SelectOption>
                                <SelectOption
                                    to={`?student_status=pending`}
                                    className={
                                        activeClick === "Pending"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => (
                                        setActiveClick("Pending"),
                                        setDropdown(false),
                                        setBox(true),
                                        setStatusBox(false),
                                        setLoading(true)
                                    )}
                                >
                                    <Span>
                                        {activeClick === "Pending" ? (
                                            <SelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                    alt="image"
                                                />
                                            </SelectedImg>
                                        ) : (
                                            <UnSelectedImg>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                    alt="image"
                                                />
                                            </UnSelectedImg>
                                        )}
                                    </Span>
                                    <Text>Pending</Text>
                                </SelectOption>
                            </StatusDropDownDiv>
                        )}

                        <HrLine className="student"></HrLine>
                        <SelectOption
                            to={``}
                            className={
                                activeClick === "Date Range" ? "active" : ""
                            }
                            onClick={() => (
                                setActiveClick("Date Range"),
                                setDropdown(false),
                                setModal(!isModal),
                                setLoading(true),
                                setFromDate(""),
                                setToDate("")
                            )}
                        >
                            <Span>
                                {activeClick === "Date Range" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Date Range</Text>
                        </SelectOption>
                        <DateHead>Select date</DateHead>
                        <Date
                            onClick={() => {
                                setButtonClicked(true);
                            }}
                        >
                            <DatePicker
                                format="dd-MM-yy"
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                yearPlaceholder="YY"
                                onChange={(value) => setSelectedDate(value)}
                                value={selectedDate}
                                clearIcon={null}
                                calendarIcon={null}
                                maxDate={today}
                            />
                        </Date>
                        {isButtonClicked && (
                            <SubmitButton
                                to={
                                    selectedDate &&
                                    `?applied_date=${getDDMMYY(selectedDate)}`
                                }
                                onClick={() => {
                                    setDropdown(false);
                                    handleFilterDate(selectedDate);
                                    setLoading(true);
                                }}
                            >
                                Filter
                            </SubmitButton>
                        )}
                    </Popup>
                </CoverSet>
            </TopFilter>
            <JobDeskDateModal
                isModal={isModal}
                handleModal={handleModal}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                startDate={startDate}
                setStartDate={setStartDate}
                isLoading={isLoading}
                setLoading={setLoading}
            />
        </>
    );
}

export default JobDeskApplicantsFilter;
const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const CoverSet = styled.div``;
const Filter = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #3b3b3b;
    padding: 6px 12px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
`;
const Icon = styled.img`
    margin-left: 10px;
    margin-top: -3px;
`;
const FilterText = styled.div`
    margin-right: 8px;
`;
const FIlterImage = styled.div`
    & img {
        width: 15px;
        transform: ${({ isBox }) =>
            isBox ? "rotate(180deg)" : "rotate(0deg)"};
        transition: 0.5s ease;
    }
`;
const FIlterImageStatus = styled.div`
    & img {
        width: 15px;
        transform: ${({ isStatusBox }) =>
            isStatusBox ? "rotate(180deg)" : "rotate(0deg)"};
        transition: 0.5s ease;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    width: 250px;
    padding: 10px;
    position: absolute;
    right: 17px;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 1;
    border: 1px solid #28292d;
    &.dropdown {
        opacity: 1;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        margin-top: 10px;
        overflow: visible;
        background: #1b1c20;
        max-height: 560px;
        transition: 0.5s ease;
        border-radius: 5px;
    }
`;
const SelectOption = styled(Link)`
    display: flex;
    cursor: pointer;
    padding: 10px;
    position: relative;
    align-items: center;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const InputField = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    height: 40px;
    padding: 10px;
    background: #161619;
    border-radius: 5px;
    border: 1px solid #2c2c2c;
    color: #fff;
    div {
        color: #fff;
        text-transform: capitalize;
        font-size: 14px;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Text = styled.div`
    font-size: 12px;
`;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
    &.students {
        margin-bottom: 10px;
    }
    &.student {
        margin: 10px 0;
    }
`;
const DropDownDiv = styled.div`
    padding: 10px;
    position: absolute;
    top: 240px;
    left: 10px;
    width: 92%;
    background: #1c1c21;
    border-radius: 5px;
    border: 1px solid #2c2c2c;
    z-index: 100;
`;
const StatusDropDownDiv = styled.div`
    padding: 10px;
    position: absolute;
    top: 228px;
    left: 10px;
    width: 92%;
    background: #1c1c21;
    border-radius: 5px;
    border: 1px solid #2c2c2c;
    z-index: 100;
`;
const CampusDropDownDiv = styled.div`
    position: absolute;
    top: 300px;
    left: 10px;
    width: 92%;
    max-height: 135px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    background: #1c1c21;
    border-radius: 5px;
    border: 1px solid #2c2c2c;
    z-index: 100;
    display: flex;
    flex-direction: column;
`;
const CampusCode = styled(Link)`
    padding: 10px;
    color: #fff;
    font-size: 14px;
    :nth-child(2n) {
        background: #161619;
    }
`;

const DateHead = styled.div`
    margin: 15px 0 10px 14px;
    color: #c8c8c8;
`;
const Date = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    text-align: center;
    background: #161618;
    border: 1px solid #28292d;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    .react-datepicker__day:hover {
        border: 1px solid #fff !important;
        color: #fff !important;
        background-color: red !important;
    }
    input {
        color: #fff;
        width: 90%;
        height: 40px;
    }
`;
const SearchContainer = styled(Link)`
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
const SubmitButton = styled(Link)`
    width: 120px;
    height: 40px;
    background: #ff5722;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 15px;
    color: #fff;
`;
