import axios from "axios";

// ### (LOCAL IP CONFIGS) ### //
// export const chatBaseUrl = "ws://192.168.0.109:8006/";
// export const learnChannelUrl = "ws://192.168.1.124:8001/";
// export const learnUrl = "http://192.168.1.124:8001";
// export const mediaUrl = "http://d3mbaugvr53zg5.cloudfront.net";
// export const learnConfig = axios.create({
//     baseURL: "http://192.168.1.124:8001/api/v1",
// });
// export const chatBaseUrl = "ws://192.168.29.58:8006/";
// export const accountsConfig = axios.create({
//     baseURL: "http://192.168.1.124:8000",
// });
// export const notificationsConfig = axios.create({
//     baseURL: "http://192.168.29.58:8003/api/v1",
// });
// export const coinsConfig = axios.create({
//     baseURL: "http://192.168.1.124:8002/api/v1",
// });
// export const webConfig = axios.create({
//     baseURL: "https://api-tce.talrop.com/api/v1/web",
// });
// export const communicationsConfig = axios.create({
//     baseURL: "http://192.168.1.124:8004/api/v1/",
// });
// export const communityConfig = axios.create({
//     baseURL: "https://community.talrop.com/api/v1/",
// });
// export const communicationConfig = axios.create({
//     baseURL: "http://192.168.1.124:8004/api/v1/",
// });

//#### DEVELOPER SERVER CONFIGS #### //
// export const chatBaseUrl = "wss://developers-communication.talrop.com/";
// export const accountsConfig = axios.create({
//     baseURL: "https://developers-accounts.talrop.com",
// });
// export const learnConfig = axios.create({
//     baseURL: "https://developers-learn.talrop.com/api/v1",
// });
// export const notificationsConfig = axios.create({
//     baseURL: "https://notifications.talrop.com/api/v1",
// });
// export const coinsConfig = axios.create({
//     baseURL: "https://developers-coins.talrop.com/api/v1",
// });
// export const webConfig = axios.create({
//     baseURL: "https://developers-api-tce.talrop.com/api/v1/web",
// });
// export const communicationsConfig = axios.create({
//     baseURL: "https://developers-communication.talrop.com/api/v1/",
// });
// export const communityConfig = axios.create({
//     baseURL: "https://developers-community.talrop.com/api/v1/",
// });
// export const challengeConfig = axios.create({
//     baseURL: "https://developers-challenges.steyp.com/api/v1/",
// });
// export const primeConfig = axios.create({
//     baseURL: "https://demo-prime-programs.talrop.works/api/v1/",
// });
// export const activityConfig = axios.create({
//     baseURL: "https://developers-activities.steyp.com/api/v1/",
// });
// export const managersConfig = axios.create({
//     baseURL: "https://developers-managers.talrop.com/api/v1/",
// });
// export const imageUploadConfig =
//     "https://developers-learn.talrop.com/api/v1/tech-updates/upload-image-by-file/";

// #### LIVE SERVER CONFIGS #### //
export const chatBaseUrl = "wss://communication.talrop.com/";
export const accountsConfig = axios.create({
    baseURL: "https://accounts.steyp.com",
});
export const learnConfig = axios.create({
    baseURL: "https://learn.steyp.com/api/v1",
});
export const notificationsConfig = axios.create({
    baseURL: "https://notifications.talrop.com/api/v1",
});
export const coinsConfig = axios.create({
    baseURL: "https://coins.steyp.com/api/v1",
});
export const webConfig = axios.create({
    baseURL: "https://api-tce.steyp.com/api/v1/web",
});
export const communicationsConfig = axios.create({
    baseURL: "https://communication.steyp.com/api/v1/",
});
export const communityConfig = axios.create({
    baseURL: "https://community.talrop.com/api/v1/",
});
export const challengeConfig = axios.create({
    baseURL: "https://challenges.steyp.com/api/v1/",
});
export const primeConfig = axios.create({
    baseURL: "https://prime-programs.steyp.com/api/v1/",
});
export const activityConfig = axios.create({
    baseURL: "https://activities.steyp.com/api/v1/",
});
export const managersConfig = axios.create({
    baseURL: "https://api-tce.talrop.com/api/v1/",
});
export const imageUploadConfig =
    "https://learn.steyp.com/api/v1/tech-updates/upload-image-by-file/";
