import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "@trendmicro/react-tooltip";
import "../../../assets/tooltip/react-tooltip.css";
import { activityConfig } from "../../../axiosConfig";
import { getDDMMYY, getYYMMDDnew } from "../../helpers/functions";
import { Context } from "../../contexts/Store";
import SrmStatusModal from "./modal/SrmStatusModal";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function CallStatusCard({ studentDetails, selectedRM, startDate }) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const [statusLoading, setStatusLoading] = useState(false);
    const [isLogModal, setLogModal] = useState(false);
    const [studentStatus, setStudentStatus] = useState([]);

    const fetchStudentStatus = () => {
        setStatusLoading(true);
        activityConfig
            .get(`followups/srh/followup-statuses/${studentDetails.id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    executive_pk: selectedRM,
                    date: getYYMMDDnew(startDate),
                },
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudentStatus(data);
                    setLogModal(true);
                    setStatusLoading(false);
                } else {
                    setStatusLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setStatusLoading(false);
            });
    };

    return (
        <>
            <Container>
                <Left>
                    <Name>{studentDetails.student_name.toLowerCase()}</Name>
                    <StudentId>{studentDetails.student_sat_id}</StudentId>
                </Left>
                <Right>
                    <Status type={studentDetails.followup_status}>
                        {studentDetails.followup_status
                            ? studentDetails.followup_status === "attended"
                                ? "Attended"
                                : studentDetails.followup_status ===
                                  "not-attended"
                                ? "Not Attended"
                                : studentDetails.followup_status ===
                                  "switched-off"
                                ? "Switched Off"
                                : studentDetails.followup_status === "busy"
                                ? "Busy"
                                : "Pending"
                            : "--"}
                    </Status>

                    <ImageContainer onClick={fetchStudentStatus}>
                        {statusLoading ? (
                            <ButtonLoader />
                        ) : (
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/tooltip.png"
                                alt="Image"
                            />
                        )}
                    </ImageContainer>

                    {studentStatus.length > 0 && (
                        <>
                            <SrmStatusModal
                                isLogModal={isLogModal}
                                studentStatus={studentStatus}
                                setLogModal={setLogModal}
                            />
                        </>
                    )}
                </Right>
            </Container>
        </>
    );
}

export default CallStatusCard;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: #1b1c1f;
    :nth-child(2n) {
        background: #161619;
    }
`;
const Left = styled.div``;

const Name = styled.div`
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
`;
const StudentId = styled.div`
    color: #fe673a;
    font-size: 12px;
`;
const Right = styled.div`
    display: flex;
    align-items: center;
`;
const Status = styled.div`
    color: ${({ type }) =>
        type === "attended"
            ? "#44C082"
            : type === "not-attended"
            ? "#ef3d3d"
            : type === "switched-off"
            ? "#ef3d3d"
            : "#fff "};
    margin-right: 10px;
    font-size: 14px;
`;
const ImageContainer = styled.div`
    cursor: pointer;
    width: 18px;
    img {
        width: 100%;
        display: block;
    }
`;

const ContainerTool = styled.div`
    background: #1b1c20;
    color: white;
    border: 2px solid #3b3b3b;
    border-radius: 8px;
    box-shadow: 14px 25px 75px -18px rgba(0, 0, 0, 0.75);
    padding: 16px 20px;
    width: 1061px;
`;

const Bottom = styled.div`
    width: 100%;
`;
const Log = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Time = styled.div`
    font-size: 14px;
`;
const Content = styled.p`
    margin-top: 10px;
    font-size: 14px;
    text-align: left;
`;
const StatusDiv = styled.div`
    position: absolute;
    top: 0;
    right: 10px;
    width: 220px;
    z-index: 2;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    color: #fff;

    div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 10px;
        background: #1b1c20;
        :nth-child(2n) {
            background: #161619;
        }
        span {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
`;
const Title = styled.span`
    color: ${({ type }) =>
        type === "attended"
            ? "#0FA76F"
            : type === "not-attended"
            ? "#C73838"
            : type === "switched-off"
            ? "#C73838"
            : type === "busy"
            ? "#FE673A"
            : ""};
`;
