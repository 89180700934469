import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { learnConfig } from "../../../../axiosConfig";
import Moment from "react-moment";
import { Context } from "../../../contexts/Store";

function TechUpdateHistoryModal({
    activeClick,
    isDropdown,
    setDropdown,
    wrapperRef,
    accessToken,
    articlePK,
}) {
    const {
        state: {
            user_data: { role },
        },
        dispatch,
    } = useContext(Context);

    const user_role = role[0];

    const [itemsToShow, setItemsToShow] = useState(5);
    const handleSeeMoreClick = () => {
        setItemsToShow((prev) => prev + 5);
    };

    const [historyDetails, setHistoryDetails] = useState([]);
    // to get last updated article
    useEffect(() => {
        learnConfig
            .get(`tech-updates/get-article-history/${articlePK}/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setHistoryDetails(data);
                } else {
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <TopHistory>
            <CoverSet ref={wrapperRef}>
                <History onClick={() => setDropdown(!isDropdown)}>
                    <HistoryText>{activeClick}</HistoryText>
                </History>
                <Popup className={isDropdown ? "dropdown" : ""}>
                    <HistoryDiv>
                        <HistoryDivText>History</HistoryDivText>
                        <CloseImg onClick={() => setDropdown(!isDropdown)}>
                            <img
                                src={
                                    require("../../../../assets/images/Historyclose.svg")
                                        .default
                                }
                            />
                        </CloseImg>
                    </HistoryDiv>
                    <HistoryProfileContainer>
                        {historyDetails &&
                            historyDetails.slice(0, itemsToShow).map((data) => (
                                <HistoryBox key={data.id}>
                                    <LeftLine>
                                        <Round></Round>
                                        <HrLine></HrLine>
                                    </LeftLine>
                                    <ProfileContentBox>
                                        <NameText>
                                            {data.history_action === "re_work"
                                                ? data.history_action.replace(
                                                      "_",
                                                      ""
                                                  )
                                                : data.history_action.replace(
                                                      /_/g,
                                                      " "
                                                  )}
                                        </NameText>
                                        <ProfileCotainer>
                                            <Profile
                                                style={{
                                                    backgroundImage: `url(${data.author.image})`,
                                                }}
                                            >
                                                {/* <img src={data.author.image} /> */}
                                            </Profile>
                                            <ProfileText>
                                                {data.author.name}
                                            </ProfileText>
                                        </ProfileCotainer>
                                        <TimeDateContainer>
                                            <DateText>
                                                <Moment
                                                    format="D MMM YYYY"
                                                    withTitle
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {data.occured_time}
                                                </Moment>
                                                <Moment format="LTS" withTitle>
                                                    {data.occured_time}
                                                </Moment>
                                            </DateText>
                                        </TimeDateContainer>
                                        {data.history_action === "re_work" ? (
                                            <ProfileButton
                                                to={
                                                    user_role ===
                                                    "content_manager"
                                                        ? `/content-manager/view-rework/${data.id}`
                                                        : `/content-editor/view-rework/${data.id}`
                                                }
                                            >
                                                View details
                                            </ProfileButton>
                                        ) : (
                                            <></>
                                        )}
                                    </ProfileContentBox>
                                </HistoryBox>
                            ))}
                    </HistoryProfileContainer>
                    {itemsToShow < historyDetails.length && (
                        <ProfileEndText onClick={handleSeeMoreClick}>
                            show older
                        </ProfileEndText>
                    )}
                </Popup>
            </CoverSet>
        </TopHistory>
    );
}

export default TechUpdateHistoryModal;

const TopHistory = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;
const History = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    background: #1b1c1f;
    cursor: pointer;
    border: 1px solid #3b3b3b;
    /* position: relative; */
`;
const HistoryText = styled.div`
    font-family: "gordita_medium";
    color: #fff;
    font-size: 14px;
`;

const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 384px;
    top: 0;
    right: -500px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: auto;
        transition: 0.5s ease;
        min-height: 100%;
        right: 0;
        overflow-y: scroll;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const HistoryDiv = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #3b3b3b;
    align-items: center;
`;
const HistoryDivText = styled.h1`
    font-size: 16px;
    padding: 20px 30px;
    color: #ffffff;
    font-family: "gordita_medium";
`;
const CloseImg = styled.div`
    padding: 19px 26px;
    padding: 10px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;
const HistoryProfileContainer = styled.div``;
const HistoryBox = styled.div`
    display: flex;
    position: relative;
`;
const NameText = styled.h2`
    color: #c9c3c2;
    font-size: 16px;
    font-family: "gordita_medium";
    /* text-transform: capitalize; */
`;
const LeftLine = styled.div`
    width: 20%;
    padding: 25px;
`;
const Round = styled.div`
    width: 22px;
    height: 22px;
    background: #2c2c2c;
    border-radius: 50%;
`;
const HrLine = styled.div`
    width: 1px;
    height: 144.018px;
    background: #2c2c2c;
    position: absolute;
    /* top: -79%; */
    left: 9.1%;
`;

const ProfileContentBox = styled.div`
    width: 60%;
    padding: 15px 0px;
`;
const ProfileCotainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;
const Profile = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: #fff;
`;
const ProfileText = styled.h2`
    color: #707070;
    font-size: 14px;
    margin-left: 8px;
    font-family: "gordita_medium";
`;
const TimeDateContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
`;
const ProfileEndText = styled.p`
    color: #079ef7;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    font-family: "gordita_medium";
    cursor: pointer;
    margin-left: 74px;
`;
const DateText = styled.p`
    color: #4f5360;
    font-size: 12px;
    margin-right: 3px;
    font-family: "gordita_medium";
`;
const ProfileButton = styled(Link)`
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    color: #fe673a;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    font-family: "gordita_medium";
    cursor: pointer;
`;
