import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../../axiosConfig";
import { Context } from "../../../../contexts/Store";
import CustomizableSelect from "../../../../includes/meet/TrainerSelecter";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import ButtonLoader from "../../../../genaral/Loader/ButtonLoader";
import $ from "jquery";

export default function EditMeetModal({
    showEditModal,
    setShowEditModal,
    cards,
    setSuccessful,
    setReload,
}) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const [file, setFile] = useState("");
    const [title, setTitle] = useState(cards.title ?? "--");

    useEffect(() => {
        if (cards && cards.cover_image) {
            const url = cards.cover_image;
            const fileName = url.substring(url.lastIndexOf("/") + 1);
            setFile(fileName);
        }
    }, [cards]);

    const todasy = new window.Date();
    const [fromDate, setFromDate] = useState(new window.Date(cards.day));

    const handleDateChange = (value) => {
        setFromDate(value);
    };

    const [link, setLink] = useState(cards.zoom_meeting_id ?? "--");
    const [available_seats, setAvailableSeats] = useState(
        cards.total_seats ?? "--"
    );
    const [led_by, setLedBy] = useState("");
    const [time, setTime] = useState(cards?.time ?? null);
    const [password, setPassword] = useState(
        cards.zoom_meeting_password ?? "--"
    );
    // const [description, setDescription] = useState(cards?.description ?? "--");
    const [errorMessage, setErrorMessage] = useState();
    const [isButtonLoading, setButtonLoading] = useState(false);

    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState("Programs");

    // validation

    const [titleError, setTitleError] = useState();
    const [dateError, setDateError] = useState();
    const [linkErr, setLinkErr] = useState();
    const [seatErr, setSeatErr] = useState();
    const [ledErr, setLedErr] = useState();
    const [timeErr, setTimeErr] = useState();
    const [discErr, setDiscErr] = useState();
    const [fileErr, setFileErr] = useState();
    const [pswrdErr, setPswrdErr] = useState();

    const selprog = cards?.programs;
    const selgroup = cards?.groups;

    // set previous submitted program value and group value

    useEffect(() => {
        const programIds = selprog?.map((card) => card.id);
        setSelectedPrograms(programIds);
    }, [cards]);

    useEffect(() => {
        const groupIds = selgroup?.map((card) => card.id);
        setSelectedGroups(groupIds);
    }, [cards]);

    //-----------for blocking background scroll when modal is open-------------

    useEffect(() => {
        if (showEditModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [showEditModal]);

    const handleRadioChange = (event) => {
        setSelectedEvent(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value;
        const isChecked = event.target.checked;

        if (selectedEvent === "Programs") {
            if (isChecked) {
                setSelectedPrograms([...selectedPrograms, checkboxValue]);
            } else {
                setSelectedPrograms(
                    selectedPrograms.filter((item) => item !== checkboxValue)
                );
            }
        } else if (selectedEvent === "Groups") {
            if (isChecked) {
                setSelectedGroups([...selectedGroups, checkboxValue]);
            } else {
                setSelectedGroups(
                    selectedGroups.filter((item) => item !== checkboxValue)
                );
            }
        }
    };

    // handless

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSelectChange = (selectedValue) => {
        setLedBy(selectedValue);
    };

    // date handler

    const formatDate = (fromDate) => {
        const dateObj = new window.Date(fromDate);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const day = String(dateObj.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const formattedDate = formatDate(fromDate);

    const today = new window.Date();

    // api connection for form editing

    const handleFormSubmit = () => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("date", formattedDate);
        formData.append("zoom_meeting_id", link);
        formData.append("total_seats", available_seats);
        // formData.append("host", led_by);
        formData.append("time", time);
        if (typeof file === "string") {
            console.log("File is a string. Not sending to formData.");
        } else if (file instanceof Blob || file instanceof File) {
            formData.append("cover_image", file);
        } else {
            console.log("Unsupported file type.");
        }
        formData.append("zoom_meeting_password", password);
        formData.append("programs", JSON.stringify(selectedPrograms));
        formData.append("groups", JSON.stringify(selectedGroups));

        // Validation

        if (!title) {
            setTitleError("Title is required");
        } else {
            setTitleError("");
        }
        if (!formattedDate || formattedDate === "NaN-NaN-NaN") {
            setDateError("Date is required");
        } else {
            setDateError("");
        }
        if (!link) {
            setLinkErr("Meeting is id required");
        } else {
            setLinkErr("");
        }
        if (!available_seats) {
            setSeatErr("Seats count is required");
        } else {
            setSeatErr("");
        }
        if (!led_by) {
            setLedErr("Lead selection is required");
        } else {
            setLedErr("");
        }
        if (!time) {
            setTimeErr("Time is required");
        } else {
            setTimeErr("");
        }
        if (!file) {
            setFileErr("Thumbnail is required");
        } else {
            setFileErr("");
        }
        if (!password) {
            setPswrdErr("Password is required");
        } else {
            setPswrdErr("");
        }

        setButtonLoading(true);
        learnConfig
            .post(`events/edit/event/${cards.id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { status_code, data, message } = response.data;
                if (status_code === 6000) {
                    setShowEditModal(false);
                    setSuccessful(true);
                    setButtonLoading(false);
                } else {
                    setButtonLoading(false);
                    setErrorMessage(message);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    // api connection for listing all programs

    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        const fetchPrograms = () => {
            learnConfig
                .get("learn/programs/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setPrograms(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchPrograms();
    }, [selectedEvent]);

    // api connection for listing all groups

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const fetchGroups = () => {
            learnConfig
                .get("events/groups-list/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    let { status_code, data } = response.data;
                    if (status_code === 6000) {
                        setGroups(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchGroups();
    }, [selectedEvent]);

    // editable function

    const handleTitleChange = (e) => {
        const inputValue = e.target.value;
        setTitle(inputValue);
        if (inputValue) {
            setTitleError("");
        }
    };
    const handlePasswordChange = (e) => {
        const inputValue = e.target.value;
        setPassword(inputValue);
        if (inputValue) {
            setPswrdErr("");
        }
    };

    const handleIdChange = (e) => {
        const inputValue = e.target.value;
        setLink(inputValue);
        if (inputValue) {
            setLinkErr("");
        }
    };

    // const handleDesc = (e) => {
    //     setDescription(e.target.value);
    // };

    const handleSeats = (e) => {
        const inputValue = e.target.value;
        setAvailableSeats(inputValue);
        if (inputValue) {
            setSeatErr("");
        }
    };

    return (
        <>
            {showEditModal && (
                <Backdrop>
                    <Modal>
                        <Container>
                            <TopContainer>
                                <span>Edit Meet</span>
                                <img
                                    onClick={() => {
                                        setShowEditModal(false);
                                    }}
                                    src={
                                        require("../../../../../assets/images/close-icon.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            </TopContainer>
                            <FormContainer>
                                <InputDiv>
                                    <FormLeft>
                                        <Label>Title *</Label>
                                        <InputFiled
                                            placeholder="Type here"
                                            onChange={handleTitleChange}
                                            value={title}
                                        ></InputFiled>
                                        <Error className="error">
                                            {titleError}
                                        </Error>
                                        <Label>Date *</Label>
                                        <Date>
                                            <DatePicker
                                                format={"dd-MM-yyyy"}
                                                dayPlaceholder="DD"
                                                monthPlaceholder="MM"
                                                yearPlaceholder="YY"
                                                onChange={handleDateChange}
                                                value={fromDate}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                // minDate={today}
                                            />
                                        </Date>

                                        <Label>Meeting id *</Label>
                                        <InputFiled
                                            type="url"
                                            placeholder="Paste here"
                                            onChange={handleIdChange}
                                            value={link}
                                        ></InputFiled>
                                        <Error className="error">
                                            {linkErr}
                                        </Error>
                                        <Label>Available seats *</Label>
                                        <InputFiled
                                            placeholder="Enter seat count"
                                            onChange={handleSeats}
                                            value={available_seats}
                                        ></InputFiled>
                                        <Error className="error">
                                            {seatErr}
                                        </Error>
                                    </FormLeft>
                                    <FormRight>
                                        {/* <Label>Led by</Label> */}
                                        {/* <CustomizableSelect
                                            onChange={handleSelectChange}
                                        /> */}

                                        <Label>Time *</Label>
                                        <Time>
                                            <TimePicker
                                                format={"hh:mm a"}
                                                clearIcon={null}
                                                disableClock={true}
                                                hourPlaceholder="Hr"
                                                minutePlaceholder="Min"
                                                onChange={setTime}
                                                value={time}
                                                amPmAriaLabel="AM/PM"
                                            />
                                        </Time>

                                        <Label>Password *</Label>
                                        <InputFiled
                                            placeholder="Paste here"
                                            onChange={handlePasswordChange}
                                            value={password}
                                        ></InputFiled>
                                        <Error className="error">
                                            {pswrdErr}
                                        </Error>

                                        {/* <Label>Note</Label>
                                        <InputFiled
                                            placeholder="Type here"
                                            onChange={handleDesc}
                                            value={description}
                                        ></InputFiled> */}
                                    </FormRight>
                                </InputDiv>
                                <Label>Event for</Label>
                                <EventRadioDiv onChange={handleRadioChange}>
                                    <RadioInput
                                        type="radio"
                                        name="event"
                                        value={"Programs"}
                                        checked={selectedEvent === "Programs"}
                                    />
                                    <Label htmlFor="Programs" id="Programs">
                                        Programs
                                    </Label>
                                    <RadioInput
                                        type="radio"
                                        name="event"
                                        value={"Groups"}
                                        checked={selectedEvent === "Groups"}
                                    />
                                    <Label htmlFor="Groups" id="Groups">
                                        Groups
                                    </Label>
                                </EventRadioDiv>
                                <Label>Meet for</Label>
                                {selectedEvent === "Programs" ? (
                                    <EventCheckDiv>
                                        {programs.map((program) => (
                                            <CheckContainer key={program.id}>
                                                <CheckInput
                                                    type="checkbox"
                                                    name="meet_for"
                                                    value={program.id}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                    checked={selectedPrograms.includes(
                                                        program.id
                                                    )}
                                                />
                                                <label
                                                    htmlFor={`meet_for_${program.id}`}
                                                    id={`meet_for_${program.id}`}
                                                >
                                                    {program.title}
                                                </label>
                                            </CheckContainer>
                                        ))}
                                    </EventCheckDiv>
                                ) : (
                                    <EventCheckDiv>
                                        {groups.map((group) => (
                                            <CheckContainer key={group.id}>
                                                <CheckInput
                                                    type="checkbox"
                                                    name="meet_for"
                                                    value={group.id}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                    checked={selectedGroups.includes(
                                                        String(group.id)
                                                    )}
                                                />
                                                <label
                                                    htmlFor={`meet_for_${group.id}`}
                                                    id={`meet_for_${group.id}`}
                                                >
                                                    {group.name}
                                                </label>
                                            </CheckContainer>
                                        ))}
                                    </EventCheckDiv>
                                )}
                                <FileContainer>
                                    <Label>Thumbnail image *</Label>
                                    <InputFileField
                                        fileSelected={file !== null}
                                    >
                                        <input
                                            id="photo"
                                            className="file"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <img
                                            src={
                                                require("../../../../../assets/images/FileUpload.svg")
                                                    .default
                                            }
                                            alt="FileUpload"
                                        />
                                        <span>
                                            {file
                                                ? file.name
                                                    ? file.name
                                                    : file
                                                : "Upload thumbnail image"}
                                        </span>
                                    </InputFileField>
                                </FileContainer>
                            </FormContainer>
                            <CreateButtonBox>
                                <CancelButton
                                    onClick={() => {
                                        setShowEditModal(false);
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                                <CreateButton
                                    onClick={() => {
                                        handleFormSubmit();
                                    }}
                                >
                                    {isButtonLoading ? (
                                        <ButtonLoader width={25} />
                                    ) : (
                                        "Submit"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        </Container>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
}
const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 1000;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    max-height: 650px;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(59, 59, 59, 1);
    span {
        color: white;
    }
    img {
        cursor: pointer;
        width: 40px;
    }
`;

const FormContainer = styled.div`
    margin-top: 10px;
`;

const Label = styled.label`
    color: rgba(123, 123, 123, 1);
    font-size: 14px;
    margin-right: 34px;
    &:last-child {
        margin-right: 0px;
    }
`;

const InputFiled = styled.input`
    width: 100%;
    color: #fff;
    padding: 18px 16px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    border: 1px solid #3b3b3b;
    margin: 10px 0px;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: "gordita_medium";
        font-weight: lighter;
        font-style: normal;
    }
`;

const InputFileField = styled.label`
    position: relative;
    width: 100%;
    color: #fff;
    padding: 12px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    align-items: center;
    margin-top: 12px;

    input {
        display: none;
    }

    img {
        margin-right: 10px;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 1;
        color: ${(props) =>
            props.fileSelected ? "#fff" : "rgba(79, 83, 96, 1)"};
        cursor: pointer;
    }
`;

const FileContainer = styled.div`
    position: relative;
    margin-top: 10px;
    img {
        position: absolute;
        right: 3px;
        top: 8px;
    }
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;

const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
const FormLeft = styled.div`
    margin-right: 16px;
    width: 50%;
`;
const FormRight = styled.div`
    width: 50%;
`;

const InputDiv = styled.div`
    display: flex;
`;
// Styled component for the wrapper div
const EventRadioDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
    background: #161619;
    border: 1px solid #3b3b3b;
    padding: 18px 16px;
    label {
        font-size: 14px;
        color: #fff;
    }
`;
const EventCheckDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 12px;
    background: #161619;
    border: 1px solid #3b3b3b;
    padding: 18px 16px;
    color: #fff;
`;

// Styled component for the radio input
const RadioInput = styled.input`
    cursor: pointer;
    appearance: none;
    border: 2px solid #fe673a;
    border-radius: 50% !important;
    width: 16px !important;
    height: 16px !important;
    margin-right: 8px;
    padding: 0px !important;
    margin-bottom: 0 !important;
    &:checked {
        border-color: #fe673a;
        background-color: #fe673a;
    }
    &:checked + label {
        color: #fe673a;
    }
`;

// Styled component for the label

const CheckInput = styled.input`
    margin: 16px 0px;
    margin-right: 12px;
    accent-color: #fe673a;
    border-radius: 12px !important;
    width: 20px;
    height: 20px;
    padding: 0px !important;
    margin-bottom: 0 !important;
    cursor: pointer;
    &:checked + label {
        color: #fe673a;
    }
`;
const CheckContainer = styled.div`
    width: 30%;
    margin-bottom: 12px;
    label {
        font-size: 14px;
    }
    @media all and (max-width: 1440px) {
        width: 50%;
    }
    @media all and (max-width: 900px) {
        width: 100%;
    }
`;
const Date = styled.div`
    width: 100%;
    color: #fff !important;
    font-size: 14px;
    padding: 6px 16px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    border: 1px solid #3b3b3b;
    margin: 10px 0px;
    .react-date-picker__wrapper {
        text-align: left !important;
    }
`;
const Time = styled.div`
    width: 100%;
    color: #fff !important;
    font-size: 14px;
    padding: 9px 16px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    border: 1px solid #3b3b3b;
    margin: 10px 0px;
`;
const Error = styled.p`
    font-size: 12px;
    color: #ff3333;
    margin-left: 2px;
    text-align: right;
`;
