import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components'

function SchoolScientistCategory({subject,category}) {
    
  return (
    <>
        <Container className='active'>
            <Cover>
                <Item to={`/school-scientist/questions/?subject=${subject}&category=up`} className={category === "up" && "active"} >UP</Item>   
                <Item to={`/school-scientist/questions/?subject=${subject}&category=hs`} className={category === "hs" && "active"} >HS</Item>
                <Item to={`/school-scientist/questions/?subject=${subject}&category=hss`} className={category === "hss" && "active"} >HSS</Item>    
            </Cover>
        </Container>
    </>
  )
}
const Container = styled.div`
    width: 100%;
    .active{
        border: 2px solid #f7684c;
        border-radius: 8px;
    }
`;
const Cover = styled.ul`
    display: flex;
    justify-content: space-between;
   flex-wrap: wrap;
`;
const Item  = styled(Link)`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 16px;
    background: rgb(27, 28, 31);
    width: 32%;
    padding: 30px;
    text-align: center;
    border: 1px solid rgb(38, 39, 42);
    border-radius: 5px;
    cursor: pointer;
    @media all and (max-width: 768px) {
		font-size: 15px;
	}
    @media all and (max-width: 480px) {
		width: 100%;
        margin-bottom: 20px;
	}
`;

export default SchoolScientistCategory