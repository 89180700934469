import "./assets/css/style.css";
import MainRouter from "./components/routing/routers/MainRouter";
import Store, { Context } from "./components/contexts/Store";
import { BrowserRouter as Router } from "react-router-dom";
import Notification from "./Notification";

function App() {
    return (
        <Router>
            <Store>
                <Notification />
                <MainRouter />
            </Store>
        </Router>
    );
}

export default App;
