import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Avatar from "react-avatar";

// import EditTrainerModal from "./modals/EditTrainerModal";

export default function MeetCard({ item, index, pagination, setSelectedCard }) {
    const [showModal, setShowModal] = useState(false);

    // handle click

    const handleCardClick = (item) => {
        setSelectedCard(item);
    };

    return (
        <>
            <ItemList
                to={`/meet/view/${item.id}`}
                onClick={() => {
                    handleCardClick(item.id);
                }}
            >
                {pagination?.first_item <= pagination?.last_item && (
                    <Item type="slno">{pagination?.first_item + index}</Item>
                )}
                <Item type="title">
                    <h3 title={item?.title}>
                        {item?.title ? item?.title : "--"}
                    </h3>
                </Item>
                <Item type="led">
                    <InfoDiv>
                        <ImgDiv>
                            {item?.host?.display_pic ? (
                                <img
                                    src={item.host.display_pic}
                                    alt={"avathar"}
                                />
                            ) : (
                                <Avatar
                                    name={item?.host?.name}
                                    size="40"
                                    round
                                />
                            )}
                        </ImgDiv>
                        <LeadCol>
                            <Name title={item?.host?.name}>
                                {item?.host?.name ? item?.host?.name : "--"}
                            </Name>
                            <Desig title={item?.host?.designation}>
                                {item.host.designation
                                    ? item.host.designation
                                    : "--"}
                            </Desig>
                        </LeadCol>
                    </InfoDiv>
                </Item>
                <Item type="date">
                    <div>
                        <h6>{item.day ? item.day : "--"}</h6>
                        <h6>{item.time ? item.time : "--"}</h6>
                    </div>
                </Item>
                <Item type="seats">
                    {item.total_seats ? item.total_seats : "--"}
                </Item>
                <Item type="reg">
                    {item.total_registrations ? item.total_registrations : "--"}
                </Item>

                <Item className="flex" type="for">
                    {item?.programs?.length > 0 ? (
                        <div className="flex">
                            <InnerBox>{item.programs[0]}</InnerBox>
                            {item.programs.length > 1 && (
                                <BoxCount>+{item.programs.length - 1}</BoxCount>
                            )}
                        </div>
                    ) : (
                        "--"
                    )}
                </Item>
                <Item type="status">
                    <Status status={item.status}>{item.status}</Status>
                </Item>
                <Item onClick={() => setShowModal(true)} className="flex">
                    <EditButton>
                        <img
                            src={
                                require("../../../../assets/images/Edit.svg")
                                    .default
                            }
                            alt="Edit"
                        />
                    </EditButton>
                </Item>
            </ItemList>
        </>
    );
}

const ItemList = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 18px 15px;
    color: #fff;

    &.active {
        background: #121214;
    }

    :hover {
        background: rgba(22, 22, 25, 1);
    }
`;
const Item = styled.li`
    font-size: 14px;
    /* text-align: ${({ type }) =>
        type === "seats" || type === "reg" ? "center" : "left"}; */

    h3 {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
    }
    h6 {
        font-size: 14px;
    }
    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    p {
        width: 60%;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "title"
            ? "19%"
            : type === "led"
            ? "15%"
            : type === "date"
            ? "10%"
            : type === "seats"
            ? "10%"
            : type === "reg"
            ? "10%"
            : type === "for"
            ? "15%"
            : type === "status"
            ? "10%"
            : "5%"};

    list-style: none;

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    &.flex {
        display: flex;
        align-items: center;
    }
`;
const EditButton = styled.div`
    border-radius: 8px;
    width: 40px;
    background-color: #2c2c2c;
    padding: 10px;
    margin-right: 10px;

    img {
        width: 100%;
        display: block;
    }
`;
const InfoDiv = styled.div`
    display: flex;
`;
const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
const Name = styled.h5`
    font-size: 18px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Desig = styled.h6`
    color: #fe673a;
    font-size: 14px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Status = styled.small`
    color: ${(props) => {
        switch (props.status) {
            case "registrations":
                return "#fd3e12";
            case "upcoming":
                return "#F59E0B";
            case "started":
                return "#0FA76F";
            case "completed":
                return "#175CD3";
            default:
                return "#ff0000";
        }
    }};
    border: 1px solid
        ${(props) => {
            switch (props.status) {
                case "registrations":
                    return "#fd3e12";
                case "upcoming":
                    return "#F59E0B";
                case "started":
                    return "#0FA76F";
                case "completed":
                    return "#175CD3";
                default:
                    return "#ff0000";
            }
        }};
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 25px;
    text-transform: capitalize;
    display: inline-block;
`;

const InnerBox = styled.div`
    background: #2c2c2c;
    border: 1px solid #364152;
    padding: 8px;
    border-radius: 6px;
    display: inline-block;
`;

const BoxCount = styled.span`
    margin-left: 12px;
    color: #fff !important;
    background: #475467;
    border: 1px solid #697586;
    text-align: center;
    border-radius: 12px;
    font-size: 12px;
    height: 23px;
    width: 41px;
    padding: 3px;
`;
const LeadCol = styled.div`
    width: 60%;
`;
