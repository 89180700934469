import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import TechUpdateCatogery from "../../includes/content-manager/TechUpdateCatogery";
import TechUpdateCatogeryTop from "../../includes/content-manager/TechUpdateCatogeryTop";
import CatogeryCreateModal from "../../includes/content-manager/modal/CatogeryCreateModal";
import CategoryDeleteModal from "../../includes/content-manager/modal/CategoryDeleteModal";
import { Context } from "../../contexts/Store";
import { error } from "jquery";
import { learnConfig } from "../../../axiosConfig";

function TechUpdateCategoryDashbord() {
    const [categoryModal, setCategoryModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [editLoder, setEditLoder] = useState(false);
    const [isReload, setReload] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isError, setError] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedInput, setSelectedInput] = useState("");

    // CATEGORY VALUES FROM API
    const [categoryImage, setCategoryImage] = useState(null);
    const [categoryName, setCategoryName] = useState("");
    const [isFeatured, setFeatured] = useState(false);
    const [selectedParent, setSelectedParent] = useState("");
    const [parentValue, setParentValue] = useState("");

    // Edit Category
    const [editCategoryPK, setEditCategoryPK] = useState("");

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const resetValues = () => {
        setCategoryImage(null);
        setCategoryName("");
        setFeatured(false);
        setSelectedParent("");
        fileInputRef.current.value = null;
        setSelectedInput("");
        setParentValue("");
        setEditCategoryPK("");
    };

    const createCategory = () => {
        setSaveLoader(true);
        setError(false);
        const formData = new FormData();
        formData.append("title", categoryName);
        formData.append("featured_image", categoryImage);
        formData.append("parent_category", selectedParent);
        formData.append("is_featured", isFeatured);
        learnConfig
            .post("/tech-updates/create-category/", formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setCategoryModal(false);
                    setSaveLoader(false);
                    setReload((prev) => !prev);
                    resetValues();
                    setError(false);
                } else {
                    console.error(error);
                    setSaveLoader(false);
                    setError(true);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const updateCategory = () => {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("title", categoryName);
        // if (categoryImage) {
        formData.append("featured_image", categoryImage);
        // }
        formData.append("parent_category", selectedParent);
        formData.append("is_featured", isFeatured);
        learnConfig
            .post(`/tech-updates/edit-category/${editCategoryPK}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setSaveLoader(false);
                    setCategoryModal(false);
                    setReload((prev) => !prev);
                    resetValues();
                } else {
                    console.error(error);
                    setSaveLoader(false);
                }
            })
            .catch((error) => {});
    };

    const getCategory = (pk) => {
        // setSaveLoader(true);
        setEditLoder(true);
        learnConfig
            .get(`/tech-updates/get-category/${pk}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCategoryModal((prev) => !prev);
                    setCategoryImage({ name: data.featured_image });
                    setCategoryName(data.title);
                    setFeatured(data.is_featured);
                    setEditLoder(false);
                    if (data.parent_category) {
                        setParentValue(data.parent_category.title);
                        setSelectedParent(data.parent_category.id);
                    }
                } else {
                    console.log(error);
                    setEditLoder(false);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmit = () => {
        if (modalType === "create") {
            createCategory();
        } else if (modalType === "edit") {
            updateCategory();
        }
    };

    return (
        <Container id="main">
            <Title>Category</Title>
            <TechUpdateCatogeryTop
                setCategoryModal={setCategoryModal}
                setModalType={setModalType}
            />
            <CatogeryCreateModal
                categoryModal={categoryModal}
                setCategoryModal={setCategoryModal}
                saveLoader={saveLoader}
                setSaveLoader={setSaveLoader}
                modalType={modalType}
                setCategoryImage={setCategoryImage}
                categoryImage={categoryImage}
                handleSubmit={handleSubmit}
                access_token={access_token}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                setFeatured={setFeatured}
                isFeatured={isFeatured}
                selectedParent={selectedParent}
                setSelectedParent={setSelectedParent}
                parentValue={parentValue}
                setParentValue={setParentValue}
                resetValues={resetValues}
                setError={setError}
                isError={isError}
                fileInputRef={fileInputRef}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
            />
            <CategoryDeleteModal
                resetValues={resetValues}
                setDeleteModal={setDeleteModal}
                deleteModal={deleteModal}
                modalType={modalType}
                saveLoader={saveLoader}
                setSaveLoader={setSaveLoader}
                selectedCategory={selectedCategory}
                access_token={access_token}
                setEditCategoryPK={setEditCategoryPK}
                editCategoryPK={editCategoryPK}
                parentValue={parentValue}
                setParentValue={setParentValue}
                setModalType={setModalType}
                setReload={setReload}
                setError={setError}
                isError={isError}
            />
            <Middle>
                <TechUpdateCatogery
                    setModalType={setModalType}
                    setCategoryModal={setCategoryModal}
                    setDeleteModal={setDeleteModal}
                    setSelectedCategory={setSelectedCategory}
                    isReload={isReload}
                    setReload={setReload}
                    setEditCategoryPK={setEditCategoryPK}
                    editCategoryPK={editCategoryPK}
                    setEditLoder={setEditLoder}
                    editLoder={editLoder}
                    getCategory={getCategory}
                />
            </Middle>
        </Container>
    );
}

export default TechUpdateCategoryDashbord;
const Container = styled.section``;

const Title = styled.h3`
    color: #fff;
    font-family: gordita_medium;
    font-size: 20px;
    margin-bottom: 20px;
`;

const Middle = styled.div``;
