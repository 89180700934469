import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import AddNote from "../trainer/modals/AddNote";
import EditMeetModal from "../trainer/modals/EditMeetModal";
import SuccessModal from "../trainer/modals/TrainerSuccess";
import MeetStatus from "../trainer/modals/MeetStatus";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import DeleteModal from "../../../includes/utils/DeleteModal";

function MeetInfo({ cards, setReload }) {
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [isModal, setModal] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [isbtnLoading, setIsbtnLoading] = useState(false);

    // api connection for edit create meet event

    return (
        <>
            <SuccessModal
                successful={successful}
                setSuccessful={setSuccessful}
                setReload={setReload}
            />
            <EditMeetModal
                showEditModal={showEditModal}
                setShowEditModal={setShowEditModal}
                cards={cards}
                setSuccessful={setSuccessful}
            />
            <AddNote
                cards={cards}
                setShowModal={setShowModal}
                showModal={showModal}
                setSuccessful={setSuccessful}
            />
            <MeetStatus
                cards={cards}
                setShowStatusModal={setShowStatusModal}
                showStatusModal={showStatusModal}
                setSuccessful={setSuccessful}
            />
            <DeleteModal
                setModal={setModal}
                isModal={isModal}
                setIsbtnLoading={setIsbtnLoading}
                isbtnLoading={isbtnLoading}
            />
            <MainContainer>
                <Container>
                    <HeadSection>
                        <HeadLeft>
                            <Heading>{cards.title ?? "--"}</Heading>
                        </HeadLeft>
                        <HeadRight>
                            <EditButton
                                onClick={() => {
                                    setShowEditModal(true);
                                }}
                            >
                                <span>
                                    <img
                                        src={
                                            require("../../../../assets/images/Edit.svg")
                                                .default
                                        }
                                        alt="Edit"
                                    />
                                </span>
                                <small>Edit info</small>
                            </EditButton>
                            <EditButton
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                <small>Add note</small>
                            </EditButton>
                            <EditButton
                                onClick={() => {
                                    setShowStatusModal(true);
                                }}
                            >
                                <small>Change status</small>
                            </EditButton>
                            <EditButton
                                onClick={() => {
                                    setModal(true);
                                }}
                            >
                                <small>Delete</small>
                            </EditButton>
                        </HeadRight>
                    </HeadSection>
                    <ContentSection>
                        <ThumbnailBox>
                            <InfoHeading>Thumbnail</InfoHeading>
                            <div>
                                {cards?.cover_image ? (
                                    <LazyLoadImage
                                        src={cards?.cover_image}
                                        alt="Thumbnail"
                                        effect="blur"
                                    />
                                ) : (
                                    <>
                                        <LazyLoadImage
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-02-2024/no-thumbnail.jpg"
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                        </ThumbnailBox>
                        <InfoBox>
                            <InfoHeading>Lead by</InfoHeading>
                            <InfoDiv>
                                <ImgDiv>
                                    {cards?.host?.display_pic ? (
                                        <img
                                            src={cards?.host?.display_pic}
                                            alt="avathar"
                                        />
                                    ) : (
                                        <Avatar
                                            name={cards?.host?.name}
                                            size="40"
                                            round
                                        />
                                    )}
                                </ImgDiv>
                                <div>
                                    <Name>{cards?.host?.name ?? "--"}</Name>
                                    <Desig>
                                        {cards?.host?.designation ?? "--"}
                                    </Desig>
                                </div>
                            </InfoDiv>
                            <MiddleDiv>
                                <InfoHeading>Available seats</InfoHeading>
                                <span>{cards?.total_seats ?? "--"}</span>
                            </MiddleDiv>
                            <MiddleDiv>
                                <InfoHeading>Meet id</InfoHeading>
                                <LinkText>
                                    {cards?.zoom_meeting_id ?? "--"}
                                </LinkText>
                            </MiddleDiv>
                            <MiddleDiv>
                                <InfoHeading>Meet for</InfoHeading>
                                {cards?.programs?.map((item, index) => (
                                    <InnerBox key={index}>
                                        {item?.title}
                                    </InnerBox>
                                ))}
                                {cards?.groups?.map((item, index) => (
                                    <InnerBox key={index}>
                                        {item?.name}
                                    </InnerBox>
                                ))}
                            </MiddleDiv>
                        </InfoBox>
                        <AddInfoBox>
                            <MiddleDiv>
                                <InfoHeading>Date & Time</InfoHeading>
                                <span>{cards?.time + " " + cards?.day}</span>
                            </MiddleDiv>
                            <MiddleDiv>
                                <InfoHeading>Registration</InfoHeading>
                                <span>{cards?.total_registrations}</span>
                            </MiddleDiv>
                            <MiddleDiv>
                                <InfoHeading>Password</InfoHeading>
                                <small>
                                    {cards?.zoom_meeting_password ?? "--"}
                                </small>
                            </MiddleDiv>
                        </AddInfoBox>
                        <StatusBox>
                            <MiddleDiv>
                                <InfoHeading>Status</InfoHeading>
                                <Status status={cards?.status}>
                                    {cards?.status ?? "--"}
                                </Status>
                            </MiddleDiv>
                            <MiddleDiv>
                                <InfoHeading>Participants </InfoHeading>
                                <span>{cards?.participance ?? "--"}</span>
                            </MiddleDiv>
                        </StatusBox>
                    </ContentSection>
                    <NoteSection>
                        <InfoHeading>Note</InfoHeading>
                        <NoteBox>
                            <NoteText>
                                {cards?.description
                                    ? cards?.description !== "null"
                                        ? cards?.description
                                        : "Add note"
                                    : "--"}
                            </NoteText>

                            <BtnDiv>
                                <NoteEdit
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                >
                                    <span>
                                        <img
                                            src={
                                                require("../../../../assets/images/Edit.svg")
                                                    .default
                                            }
                                            alt="Edit"
                                        />
                                    </span>
                                </NoteEdit>
                                {/* <NoteEdit>
                                <span>
                                    <img
                                        src={
                                            require("../../../../assets/images/delete-image.svg")
                                                .default
                                        }
                                        alt="Delete"
                                    />
                                </span>
                            </NoteEdit> */}
                            </BtnDiv>
                        </NoteBox>
                    </NoteSection>
                </Container>
            </MainContainer>
        </>
    );
}

export default MeetInfo;

const MainContainer = styled.div`
    width: 100%;
    color: white;
    background: #1b1c1f;
    padding: 32px;
    border-radius: 6px;
    margin-top: 18px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Container = styled.div`
    min-width: 1300px;
`;
const Heading = styled.h3`
    font-size: 20px;
    color: #fff;
`;
const HeadSection = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
    padding-bottom: 24px;
`;
const HeadLeft = styled.div`
    width: 60%;
`;
const HeadRight = styled.div`
    display: flex;
    height: 40px;
`;
const EditButton = styled.div`
    margin-right: 12px;
    border: 1px solid #fe673a;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    span {
        margin-right: 8px;
    }
    img {
        width: 100%;
        display: block;
    }
    small {
        font-size: 16px;
        color: #fe673a;
    }
    &:hover {
        opacity: 0.8;
    }
`;
const ContentSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    width: 90%;
`;
const ThumbnailBox = styled.div`
    img {
        width: 100%;
        display: block;
        border-radius: 8px;
    }
    div {
        width: 200px;
        border-radius: 8px;
    }
`;
const InfoBox = styled.div`
    width: 35%;
`;
const AddInfoBox = styled.div`
    width: 20%;
`;
const StatusBox = styled.div`
    width: 20%;
`;
const NoteSection = styled.div`
    width: 84%;
    margin-left: auto;
`;
const InfoHeading = styled.h3`
    font-size: 14px;
    color: #b7b7b7;
    margin-bottom: 10px;
`;
const InfoDiv = styled.div`
    display: flex;
    margin-bottom: 32px;
`;
const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
const Name = styled.h5`
    font-size: 18px;
`;
const Desig = styled.h6`
    color: #fe673a;
    font-size: 14px;
`;
const MiddleDiv = styled.div`
    margin-bottom: 32px;
    span {
        color: #fe673a;
        font-size: 16px;
    }
`;
const LinkText = styled.p`
    color: #d2caca;
    font-size: 18px;
`;
const InnerBox = styled.div`
    background: #2c2c2c;
    border: 1px solid #364152;
    display: inline-block;
    padding: 8px;
    border-radius: 6px;
    margin-right: 18px;
    margin-bottom: 18px;
`;
const NoteBox = styled.div`
    background: #161619;
    padding: 16px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
`;
const NoteText = styled.p`
    color: #d2caca;
    font-size: 16px;
`;
const NoteEdit = styled.button`
    cursor: pointer;
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;
const BtnDiv = styled.div`
    display: flex;
`;
const Status = styled.small`
    color: ${(props) => {
        switch (props.status) {
            case "registrations":
                return "#fd3e12";
            case "upcoming":
                return "#F59E0B";
            case "started":
                return "#0FA76F";
            case "completed":
                return "#175CD3";
            case "postponed":
                return "#fd3e12";
            case "cancelled":
                return "#fd3e12";
            default:
                return "#000000";
        }
    }};
    border: 1px solid
        ${(props) => {
            switch (props.status) {
                case "registrations":
                    return "#fd3e12";
                case "upcoming":
                    return "#F59E0B";
                case "started":
                    return "#0FA76F";
                case "completed":
                    return "#175CD3";
                case "postponed":
                    return "#fd3e12";
                case "cancelled":
                    return "#fd3e12";
                default:
                    return "#000000";
            }
        }};
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 25px;
    display: inline-block;
    text-transform: capitalize;
`;
