import React, { useState, useContext, useEffect } from "react";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import styled from "styled-components";
import ProjectStudentList from "../ProjectStudentList";
import $ from "jquery";
import MemberManagement from "../MemberManagement.jsx";
import { accountsConfig, learnConfig } from "../../../../axiosConfig.js";
import { Context } from "../../../contexts/Store.js";
import { useParams } from "react-router-dom";

function ProjectAssignedModal({
    studenModal,
    setShowstudentModal,
    setMoadl,
    slug,
    setIsReload,
    setSuccess,
}) {
    const [activeItem, setActiveItem] = useState(0);
    const [count, setCount] = useState(0);
    const [selectedNav, setSelectedNav] = useState("Add student");
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const selectedUserIdsString = JSON.stringify(selectedUserIds);
    const [searchInput, setSearchInput] = useState("");
    const [error, setError] = useState("");
    const [studentData, setStudentData] = useState([]);
    const [isButtonLoading, setButtonLoading] = useState(false);

    // const handleItemClick = (index) => {
    //     setActiveItem(index);
    // };

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const navList = [
        {
            id: 1,
            name: "Add student",
        },
        // {
        //     id: 2,
        //     name: "Add Group",
        // },
    ];

    //Assigned students api

    useEffect(() => {
        const assignedData = async () => {
            try {
                await accountsConfig
                    .get(`api/v1/users/projects/list-profiles-assign-modal/`, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: {
                            q: searchInput,
                        },
                    })
                    .then((res) => {
                        let { status_code, data } = res.data;
                        if (status_code === 6000) {
                            setStudentData(data);
                        } else {
                            console.log(data);
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };
        assignedData();
    }, [searchInput]);

    // api connection for form create

    const onSubmit = () => {
        setButtonLoading(true);
        const formData = new FormData();
        formData.append("student_id", selectedUserIdsString);

        learnConfig
            .post(`projects/chief-assign-project/${slug}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, data, message } = response.data;
                if (status_code === 6000) {
                    setShowstudentModal(false);
                    setSuccess((prev) => !prev);
                    setButtonLoading(false);
                } else {
                    setButtonLoading(false);
                    setError(message.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //-----------for blocking background scroll when modal is open-------------
    useEffect(() => {
        if (studenModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [studenModal]);
    return (
        <>
            {studenModal && (
                <Backdrop>
                    <Modal>
                        <Container>
                            <TopContainer>
                                <HeadDiv>
                                    <HeadText>Project Assigned to</HeadText>
                                    <Icon
                                        onClick={() => {
                                            setShowstudentModal(false);
                                            setSelectedNav("Add student");
                                            setActiveItem(0);
                                        }}
                                    >
                                        <img
                                            src={
                                                require("../../../../assets/images/close-icon.svg")
                                                    .default
                                            }
                                            alt="close-icon"
                                        />
                                    </Icon>
                                </HeadDiv>
                                <SubHeadText>
                                    View and Edit project assignation.
                                </SubHeadText>
                            </TopContainer>

                            <StudentCountDiv>
                                <StudentCount>
                                    <StudentText>
                                        Selected students{" "}
                                    </StudentText>
                                    <CountDiv>
                                        <small>{count}</small>
                                    </CountDiv>
                                </StudentCount>
                                <NavList>
                                    <ListNav>
                                        {navList?.map((item, index) => (
                                            <ItemNav
                                                key={index}
                                                // onClick={() => {
                                                //     handleItemClick(index);
                                                //     setSelectedNav(item.name);
                                                // }}
                                                active={activeItem === index}
                                            >
                                                {item.name}
                                            </ItemNav>
                                        ))}
                                    </ListNav>
                                </NavList>
                            </StudentCountDiv>
                            {selectedNav === "Add student" ? (
                                <>
                                    <MemberManagement
                                        members={studentData}
                                        searchInput={searchInput}
                                        setSearchInput={setSearchInput}
                                        selectedUserIds={selectedUserIds}
                                        setSelectedUserIds={setSelectedUserIds}
                                        setError={setError}
                                        setCount={setCount}
                                    />
                                </>
                            ) : (
                                ""
                            )}
                            <Error className="error"></Error>
                            <CreateButtonBox>
                                <CancelButton
                                    onClick={() => {
                                        setShowstudentModal(false);
                                        setSelectedNav("Add student");
                                        setActiveItem(0);
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                                <CreateButton onClick={onSubmit}>
                                    {isButtonLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Done"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        </Container>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
}

export default ProjectAssignedModal;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    width: 100%;
    max-width: 500px;
    max-height: 83vh;
    position: absolute;
    margin: 0px auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease 0s;
    z-index: 1000;
    border: 1px solid rgb(38, 39, 42);
    background: rgb(27, 28, 31);
    border-radius: 5px;
    overflow-y: hidden;
    scroll-behavior: smooth;
    box-shadow: rgba(0, 0, 0, 0.282) 0px 3px 56px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
`;

const TopContainer = styled.div`
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(59, 59, 59, 1);
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;

const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;

    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;

const Error = styled.p`
    font-size: 13px;
    color: #ff3333;
    margin-left: 2px;
    text-align: right;
    position: absolute;
    right: 22px;
    bottom: 10%;
`;

const HeadDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HeadText = styled.h4`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
`;
const SubHeadText = styled.p`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
`;
const Icon = styled.span`
    display: inline-block;
    width: 40px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const StudentCountDiv = styled.div`
    margin-top: 20px;
`;
const StudentCount = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;
const StudentText = styled.h6`
    color: #94a3b8;
    font-size: 16px;
    font-family: "gordita_medium";
    margin-right: 8px;
`;
const CountDiv = styled.div`
    border: 1px solid #e3e8ef;
    background-color: #f8fafc;
    padding: 4px 8px;
    border-radius: 16px;
    small {
        display: block;
        color: #fe673a;
        font-size: 12px;
        font-family: "gordita_medium";
        height: 15px;
    }
`;
const NavList = styled.div``;
const ListNav = styled.ul`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2e2e2e;
`;
const ItemNav = styled.li`
    margin-right: 20px;
    font-size: 16px;
    font-family: "gordita_medium";
    color: ${(props) => (props.active ? "#FE673A" : "#667085")};
    /* cursor: pointer;  */
    border-bottom: ${(props) =>
        props.active ? "2px solid #FE673A" : "2px solid transparent"};
    &:hover {
        color: #fe673a;
    }
`;

const Label = styled.label`
    color: #94a3b8;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 10px;
`;
