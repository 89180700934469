import React, { useEffect } from "react";
import styled from "styled-components";
import { getDDMMYY, getTimeFromDate } from "../../../helpers/functions";
import $ from "jquery";

function SrmStatusModal({ isLogModal, studentStatus, setLogModal }) {
    useEffect(() => {
        if (isLogModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isLogModal]);

    return (
        <BackContainer style={{ transform: isLogModal && "scale(1,1)" }}>
            <Overlay onClick={() => setLogModal(false)}></Overlay>
            <Close onClick={() => setLogModal(false)}>
                <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                    about="Icon"
                />
            </Close>
            <Modal>
                <ContainerTool>
                    {studentStatus.map((item) => (
                        <Card key={item.id}>
                            <Log>
                                <Status type={item.title}>
                                    {item.title === "attended"
                                        ? "Attended"
                                        : item.title === "not-attended"
                                        ? "Not Attended"
                                        : item.title === "switched-off"
                                        ? "Switched Off"
                                        : item.title === "busy"
                                        ? "Call Later / Busy"
                                        : "Pending"}
                                </Status>
                                <Time>
                                    {item.date_updated
                                        ? getTimeFromDate(item.date_updated)
                                        : ""}
                                </Time>
                            </Log>
                            <Content>{item.description}</Content>
                        </Card>
                    ))}
                </ContainerTool>
            </Modal>
        </BackContainer>
    );
}
export default SrmStatusModal;

const BackContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    transform: scale(0, 0);
    transition: 0.3s;
    backdrop-filter: blur(2px);
    z-index: 500;
`;
const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 70vh;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    background: #1b1c1f;
    border-radius: 5px;
    border: 1px solid #26272a;
    box-shadow: 0px 3px 56px #00000048;
    ::-webkit-scrollbar {
        display: none;
    }
    overflow-y: scroll;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;

const ContainerTool = styled.div`
    background: #1b1c20;
    color: white;
    border: 1px solid #3b3b3b;
    border-radius: 8px;
    box-shadow: 14px 25px 75px -18px rgba(0, 0, 0, 0.75);
    padding: 20px;
`;

const Log = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Time = styled.div`
    font-size: 14px;
`;
const Content = styled.p`
    margin-top: 10px;
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;
`;
const Status = styled.div`
    color: ${({ type }) =>
        type === "attended"
            ? "#44C082"
            : type === "not-attended"
            ? "#EE4B4B"
            : type === "switched-off"
            ? "#EF3D3D"
            : type === "busy"
            ? "#FE673A"
            : ""};
    margin-right: 10px;
    font-size: 14px;
`;
const Card = styled.div`
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    :last-child {
        margin-bottom: 0px;
    }
    :nth-child(2n) {
        background: #161619;
        padding: 10px;
    }
`;
const Close = styled.div`
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer;
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
