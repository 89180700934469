import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
//loader
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function AepExamStartModal({
    isStartModal,
    setStartModal,
    student,
    startStudentExam,
    isExamLoading,
    studentUpdate,
}) {
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setStartModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <MainContainer>
            {isStartModal ? (
                <Overlay
                    onClick={() => setStartModal(false)}
                    style={{ display: isStartModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isStartModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Head>
                                <NameSection>
                                    {student.student_photo && (
                                        <ProPic>
                                            <Span>
                                                <img
                                                    src={student.student_photo}
                                                />
                                            </Span>
                                        </ProPic>
                                    )}
                                    <NameSec>
                                        <Name>
                                            {student.name?.toLowerCase()}
                                        </Name>
                                        <Phone>
                                            {student.phone_code} {student.phone}
                                        </Phone>
                                    </NameSec>
                                </NameSection>
                            </Head>
                        </Top>
                        <Middle>
                            <SubTitle>
                                Are you sure do you want to start exam?
                            </SubTitle>
                        </Middle>
                        <Bottom>
                            <Button
                                type="cancel"
                                onClick={() => setStartModal(false)}
                            >
                                Not Now
                            </Button>

                            <Button
                                onClick={() => {
                                    startStudentExam();
                                    studentUpdate(student);
                                }}
                            >
                                {isExamLoading ? (
                                    <ButtonLoader />
                                ) : (
                                    "Start Exam"
                                )}
                            </Button>
                        </Bottom>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default AepExamStartModal;
const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 35px 30px;
    width: 100%;
    height: 100%;
`;
const Top = styled.div`
    margin-bottom: 20px;
    border-bottom: 1px solid #26272a;
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
`;

const SubTitle = styled.p`
    font-size: 18px;
    color: #fff;
`;
const Middle = styled.div``;
const Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 40px;
    background: ${({ type }) => (type === "cancel" ? "#161619" : "#fe673a")};
    margin-right: ${({ type }) => (type === "cancel" ? "20px" : "0px")};
    cursor: pointer;
    color: #fff;
    border: 1px solid #fe673afa;
    border-radius: 5px;
    font-size: 14px;
    font-family: "gordita_medium";
`;
const NameSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const ProPic = styled.div`
    margin-right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    font-family: "gordita_bold";
    border-radius: 50%;
    min-width: 50px;
`;
const Span = styled.span`
    width: 40px;
    height: 40px;

    img {
        width: 100%;
        display: block;
    }
`;
const NameSec = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;
const Name = styled.span`
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: capitalize;
`;
const Phone = styled.span`
    font-size: 16px;
    color: #fe673a;
`;
