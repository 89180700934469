import React from "react";
import styled from "styled-components";

const ToggleButton = ({ isMalayalam, setMalayalam }) => {
//   const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.checked) {

      setMalayalam(true);
    } else {
      setMalayalam(false);

    }
  };
  return (
    <Container>
      <CheckBoxWrapper>
        <SubTitle>English</SubTitle>
        <CheckBox id="checkbox" type="checkbox" onChange={(e)=>handleChange(e)} />
        <CheckBoxLabel htmlFor="checkbox" />
        <SecondTitle>Malayalam</SecondTitle>
      </CheckBoxWrapper>
    </Container>
  );
};

const Container = styled.div`

`;

const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  right: 80px;
  width: 46px;
  height: 21px;
  border-radius: 26px;
  background: #161619;
  border: 1px solid  #fe673a;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 17px;
    margin: 1px;
    background: #fe673a;
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 24px;
  border: 1px solid #4fbe79;

  &:checked + ${CheckBoxLabel} {
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 17px;
      margin-left: 25px;
      transition: 0.2s;
    }
  }
`;
const SubTitle = styled.h3`
    color: #fff;
    font-size: 14px;
    margin-right: 17px;
    @media all and (max-width: 360px) {
		    font-size: 13px;
        margin-right: 25px;
	  }
`;
const SecondTitle = styled.h3`
    color: #fff;
    font-size: 14px;
    @media all and (max-width: 360px) {
		    font-size: 13px;
	  }
`;
export default ToggleButton;
