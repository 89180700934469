import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import { accountsConfig, learnConfig } from "../../../axiosConfig";
import moment from "moment/moment";
import SectionLoader from "../../helpers/SectionLoader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import NoData from "../jobdesk/NoData";
import Avatar from "react-avatar";

function ProjectSubmissionsPage({ slug }) {
    const [projectData, setProjectData] = useState([]);
    const [submission, setSubmission] = useState([]);
    const [loader, setLoader] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [activeIndex, setActiveIndex] = useState();

    //pagination
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams("");
    const [searchWord, setSearchWord] = useState("");
    const [statusDrop, setStatusDrop] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const currentParams = Object.fromEntries([...searchParams]);

    const navigate = useNavigate();

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("status") &&
            (filterParams.status = searchParams.get("status"));

        return filterParams;
    };

    // const fetchProjects = () => {
    //     const params = getFilterParams();
    //     setLoader(true);
    //     learnConfig
    //         .get("/projects/dashboard/", {
    //             headers: {
    //                 Authorization: `Bearer ${access_token}`,
    //             },
    //             params: params,
    //         })
    //         .then((response) => {
    //             let { StatusCode, data, pagination_data, evaluation_counts } =
    //                 response.data;
    //             if (StatusCode === 6000) {
    //                 setProjectData(data);
    //                 setPagination(pagination_data);
    //                 setStatusList(evaluation_counts);
    //                 setLoader(false);
    //             } else {
    //                 setLoader(false);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setLoader(false);
    //         });
    // };

    const fetchSubmission = () => {
        const params = getFilterParams();
        setLoader(true);
        accountsConfig
            .get("api/v1/users/projects/list-profiles-submission", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    project: slug,
                },
            })
            .then((response) => {
                let { status_code, data, pagination_data, evaluation_counts } =
                    response.data;
                if (status_code === 6000) {
                    setSubmission(data);
                    setPagination(pagination_data);
                    setStatusList(evaluation_counts);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };

    const startProject = (project_pk) => {
        setButtonLoader(true);
        learnConfig
            .post(
                `/projects/start-evaluation/${project_pk}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    navigate(`/projects/evaluation/${project_pk}/`);
                    setButtonLoader(false);
                } else {
                    setButtonLoader(false);
                    navigate(`/projects/evaluation/${project_pk}/`);
                }
            })
            .catch((err) => {
                console.log(err);
                setButtonLoader(false);
                navigate(`/projects/evaluation/${project_pk}/`);
            });
    };

    useEffect(() => {
        fetchSubmission();
    }, [currentPage, searchWord, searchParams]);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    return (
        <Container>
            <FormHead>
                <SearchContainer>
                    <Label>Search</Label>
                    <InputConatainer>
                        <Icon>
                            <SearchIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
                                alt="SearchIcon"
                            />
                        </Icon>
                        <Input
                            type="text"
                            placeholder="Search for students..."
                            value={searchWord}
                            onChange={(e) => {
                                setSearchWord(e.target.value);
                                setSearchParams({
                                    q: e.target.value,
                                });
                            }}
                        />
                    </InputConatainer>
                </SearchContainer>
                {/* <DateContainer>
                    <Label>Select date range</Label>
                    <Date>
                        <Icon>
                            <DateIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/calendar.svg"
                                alt="Icon"
                            />
                        </Icon>
                        <DateRange>Jan 6, 2022 – Jan 13, 2022</DateRange>
                    </Date>
                </DateContainer> */}
            </FormHead>
            <FormBody>
                <Table>
                    <TableHead>
                        <TableHeadList>
                            <TableHeadItem eadItem type="number">
                                Sl No.{" "}
                                {/* <TableHeadIcon>
                                    <TableDownArrowIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg "
                                        alt="ArrowIcon"
                                    />
                                </TableHeadIcon> */}
                            </TableHeadItem>
                            {/* <TableHeadItem type="project">
                                Project{" "}
                                <TableHeadIcon>
                                    <TableDownArrowIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg "
                                        alt="ArrowIcon"
                                    />
                                </TableHeadIcon>
                            </TableHeadItem> */}
                            <TableHeadItem type="developer">
                                Developer{" "}
                                {/* <TableHeadIcon>
                                    <TableDownArrowIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg "
                                        alt="ArrowIcon"
                                    />
                                </TableHeadIcon> */}
                            </TableHeadItem>
                            <TableHeadItem type="link">Repo link</TableHeadItem>
                            <TableHeadItem
                                type="status"
                                onClick={() => {
                                    setStatusDrop(!statusDrop);
                                }}
                            >
                                Project status{" "}
                                <TableHeadIcon>
                                    <TableDownArrowIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg "
                                        alt="ArrowIcon"
                                    />
                                </TableHeadIcon>
                                <StatusFilter
                                    className={statusDrop && "active"}
                                >
                                    {statusList?.length === 0 ? (
                                        <StatusFilterItem>
                                            No status
                                        </StatusFilterItem>
                                    ) : (
                                        <>
                                            {statusList?.map((item, index) => (
                                                <StatusFilterItem
                                                    key={index}
                                                    onClick={() => {
                                                        setStatusDrop(false);
                                                        setSearchParams({
                                                            status: item.count,
                                                        });
                                                    }}
                                                >
                                                    {item?.name}
                                                </StatusFilterItem>
                                            ))}
                                        </>
                                    )}
                                </StatusFilter>
                            </TableHeadItem>
                            <TableHeadItem type="date">
                                Date & time{" "}
                                {/* <TableHeadIcon>
                                    <TableDownArrowIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg "
                                        alt="ArrowIcon"
                                    />
                                </TableHeadIcon> */}
                            </TableHeadItem>
                            <TableHeadItem type="action">
                                Actions{" "}
                                {/* <TableHeadIcon>
                                    <TableDownArrowIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg "
                                        alt="ArrowIcon"
                                    />
                                </TableHeadIcon> */}
                            </TableHeadItem>
                        </TableHeadList>
                    </TableHead>
                    <TableBody>
                        <TableData>
                            {loader ? (
                                <>
                                    <SectionLoader
                                        status={"followup_executive"}
                                    />
                                </>
                            ) : submission?.length > 0 ? (
                                submission?.map((item, index) => (
                                    <TalbleList key={index}>
                                        <TableItme type="number">
                                            <Number>
                                                {/* <CheckBox></CheckBox> */}
                                                <SlNumber>
                                                    {pagination.first_item +
                                                        index <
                                                    10
                                                        ? `0${
                                                              pagination.first_item +
                                                              index
                                                          }`
                                                        : pagination.first_item +
                                                          index}
                                                </SlNumber>
                                            </Number>
                                        </TableItme>
                                        <TableItme type="developer">
                                            <DevContainer>
                                                {item?.photo ? (
                                                    <DevImg>
                                                        <img
                                                            src={item?.photo}
                                                            alt="avatr"
                                                        />
                                                    </DevImg>
                                                ) : (
                                                    <DevImg>
                                                        <Avatar
                                                            name={item?.name}
                                                            size="38"
                                                            round
                                                        />
                                                    </DevImg>
                                                )}

                                                <DevNameContainer>
                                                    <DevName>
                                                        {item.name ?? "--"}
                                                    </DevName>
                                                    <DevId>
                                                        {item?.user_id
                                                            ? "ID " +
                                                              item?.user_id
                                                            : "--"}
                                                    </DevId>
                                                </DevNameContainer>
                                            </DevContainer>
                                        </TableItme>
                                        {/* <TableItme type="link">
                                            <RepoLink
                                                href={`${item?.repo_url}`}
                                                target="_blank"
                                            >
                                                View link
                                            </RepoLink>
                                        </TableItme> */}

                                        <TableItme type="link">
                                            <RepoLink
                                                href={item?.repo_url}
                                                target="_blank"
                                            >
                                                View link
                                            </RepoLink>
                                        </TableItme>

                                        <TableItme type="status">
                                            <StatusType>
                                                {item?.status
                                                    ? item?.status
                                                    : "--"}
                                            </StatusType>
                                        </TableItme>
                                        <TableItme type="date">
                                            <TimeContainer>
                                                <TimeContainerDate>
                                                    {item?.evaluation_date
                                                        ? item?.evaluation_date
                                                        : "--"}
                                                </TimeContainerDate>
                                                {/* <TimeContainerTime>
                                                    {item.date_updated
                                                        ? moment(
                                                              item.date_updated
                                                          ).format("LT")
                                                        : "--"}
                                                </TimeContainerTime> */}
                                            </TimeContainer>
                                        </TableItme>
                                        <TableItme type="action">
                                            <ActionButton
                                                onClick={() => {
                                                    startProject(
                                                        item.student_project_id
                                                    );
                                                    setActiveIndex(index);
                                                }}
                                                // to={`/projects/evaluation/${item.id}/`}
                                            >
                                                {buttonLoader &&
                                                index === activeIndex ? (
                                                    <ButtonLoader
                                                        width={25}
                                                        height={25}
                                                    />
                                                ) : (
                                                    "View Evaluation"
                                                )}
                                            </ActionButton>
                                        </TableItme>
                                    </TalbleList>
                                ))
                            ) : (
                                <TalbleList>
                                    <NoData />
                                </TalbleList>
                            )}
                        </TableData>
                    </TableBody>
                </Table>
            </FormBody>
            {pagination?.total_pages > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </Container>
    );
}

export default ProjectSubmissionsPage;

const Container = styled.div``;

const FormHead = styled.div`
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    margin-bottom: 24px;
    display: flex;
    gap: 32px;
`;

const FormBody = styled.div`
    border-radius: 4px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
`;

const SearchContainer = styled.div`
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;
const DateContainer = styled.div`
    display: flex;
    width: 250px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;
const Label = styled.label`
    color: #c9c9c9;
    font-size: 14px;
    font-family: "gordita_regular";
`;
const InputConatainer = styled.div`
    border-radius: 6px;
    border: 1px solid #3d3d3d;
    background: #161619;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Input = styled.input`
    color: #fff;
    font-size: 16px;
    width: 100%;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
    }
`;
const Icon = styled.span`
    display: inline-block;
    width: 20px;
    margin-right: 12px;
`;
const SearchIcon = styled.img`
    display: block;
    width: 100%;
`;

const Date = styled.div`
    border-radius: 6px;
    border: 1px solid #3d3d3d;
    background: #161619;
    display: flex;
    width: 100%;
    padding: 8px 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const DateIcon = styled.img`
    display: block;
    width: 100%;
`;

const DateRange = styled.div`
    font-size: 14px;
    color: #fff;
    font-family: "gordita_regular";
`;
const Table = styled.div``;

const TableHead = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #2c2c2c;
`;

const TableHeadList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TableHeadItem = styled.li`
    position: relative;
    font-size: 16px;
    font-family: "gordita_medium";
    color: #fff;
    display: flex;
    align-items: center;
    cursor: ${({ type }) => type === "status" && "pointer"};
    width: ${({ type }) =>
        type === "number"
            ? "8%"
            : type === "project"
            ? "15%"
            : type === "developer"
            ? "20%"
            : type === "link"
            ? "10%"
            : type === "status"
            ? "15%"
            : type === "date"
            ? "12%"
            : type === "action"
            ? "10%"
            : ""};
    &:last-child {
        justify-content: end;
    }
`;

const TableHeadIcon = styled.span`
    display: inline-block;
    width: 20px;
    margin-left: 8px;
    cursor: pointer;
`;
const StatusFilter = styled.div`
    position: absolute;
    top: 15px;
    width: 100%;
    min-height: auto;
    max-height: 140px;
    overflow-y: scroll;
    background-color: #161619;
    border: 1px solid #3d3d3d;
    border-radius: 8px;
    z-index: 999;
    border-radius: 5px;
    transition: all ease-in 150ms;
    opacity: 0;
    visibility: hidden;
    &.active {
        top: 25px;
        opacity: 1;
        visibility: visible;
    }
    &::-webkit-scrollbar {
        width: 3px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: #878787;
        border-radius: 20px;
    }
`;
const StatusFilterItem = styled.p`
    color: #fff;
    font-size: 15px;
    font-family: "gordita_medium";
    padding: 5px 15px;

    cursor: pointer;
    border-bottom: 1px solid #3d3d3d;
    &:last-child {
        border-bottom: none;
    }
    &:hover {
        background-color: #3d3d3d;
    }
`;
const TableDownArrowIcon = styled.img`
    display: block;
    width: 100%;
`;

const TableBody = styled.div``;

const TableData = styled.div``;

const TalbleList = styled.ul`
    background: #1b1c1f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #2c2c2c;
`;

const TableItme = styled.li`
    font-size: 16px;
    font-family: "gordita_medium";
    color: #d2caca;
    display: flex;
    align-items: center;
    width: ${({ type }) =>
        type === "number"
            ? "8%"
            : type === "project"
            ? "15%"
            : type === "developer"
            ? "20%"
            : type === "link"
            ? "10%"
            : type === "status"
            ? "15%"
            : type === "date"
            ? "12%"
            : type === "action"
            ? "10%"
            : ""};
    &:last-child {
        justify-content: end;
    }
`;

const Number = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;
const CheckBox = styled.div`
    border-radius: 6px;
    border: 1px solid #fff;
    background: #1b1c1f;
    width: 20px;
    height: 20px;
`;
const SlNumber = styled.h5`
    font-size: 16px;
    font-family: "gordita_regular";
    color: #d2caca;
`;
const ProjectName = styled(SlNumber)``;

const DevContainer = styled.div`
    display: flex;
`;
const DevImg = styled.div`
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;
const DevNameContainer = styled.div``;
const DevName = styled.h5`
    text-transform: capitalize !important;
    font-size: 14px;
    color: #fff;
    font-family: "gordita_medium";
`;
const DevId = styled.h5`
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_regular";
`;
const RepoLink = styled.a`
    color: #fe673a;
    font-size: 16px;
    font-family: "gordita_regular";
    text-decoration: underline;
`;

const StatusType = styled.div`
    color: #d0d5dd;
    display: flex;
    height: 26px;
    padding: 2px 8px;
    align-items: center;
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
`;

const TimeContainer = styled.div``;
const TimeContainerDate = styled.h5`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
`;
const TimeContainerTime = styled.h5`
    color: #d2caca;
    font-size: 14px;
    font-family: "gordita_regular";
`;

const ActionButton = styled.button`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    display: flex;
    width: 132px;
    height: 44px;
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fe673a;
    font-size: 12px;
    font-family: "gordita_medium";
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
