import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../../contexts/Store";
import { learnConfig } from "../../../../axiosConfig";
import { error } from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { useRef } from "react";

function CatogeryCreateModal({
    categoryModal,
    setCategoryModal,
    saveLoader,
    modalType,
    categoryImage,
    setCategoryImage,
    handleSubmit,
    access_token,
    categoryName,
    setCategoryName,
    setFeatured,
    isFeatured,
    setSelectedParent,
    parentValue,
    setParentValue,
    resetValues,
    isError,
    fileInputRef,
    selectedInput,
    setSelectedInput,
}) {
    const [isArrowRotated, setIsArrowRotated] = useState(false);
    const categoryRef = useRef(null);
    const textInputRef = useRef(null);
    const [parentCategory, setParentCategory] = useState([]);

    // console.log(parentCategory, "parentCategory");

    const imageUpload = (e) => {
        setCategoryImage(e.target.files[0]);
        fileInputRef.current.value = null;
    };

    const fetchParentCategory = () => {
        learnConfig
            .get("/tech-updates/list-parent-categories/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    q: parentValue,
                },
            })

            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setParentCategory(data);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        fetchParentCategory();
    }, [parentValue]);

    const clearSavedValues = () => {
        resetValues();
        setSelectedInput("");
        fileInputRef.current.value = null;
    };

    return (
        <MainContainer>
            {categoryModal ? (
                <Overlay
                    onClick={() => {
                        setCategoryModal(false);
                        clearSavedValues();
                    }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: categoryModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <Header>
                            <HeadText>
                                {modalType === "create"
                                    ? "Create category"
                                    : "Edit category"}
                            </HeadText>
                            <HeaderClose
                                onClick={() => {
                                    setCategoryModal(false);
                                    clearSavedValues();
                                }}
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Close Icon"
                                />
                            </HeaderClose>
                        </Header>
                        <CategoriesContainer>
                            <ParentCategory>
                                Parent category (Optional)
                            </ParentCategory>
                            <ParentCategoryDropDown>
                                <ParentCategoryInput
                                    style={{
                                        borderColor:
                                            selectedInput === "parent" &&
                                            "#fe673a",
                                    }}
                                    onClick={() => {
                                        setSelectedInput("parent");
                                        categoryRef.current.focus();
                                    }}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Select category"
                                        value={parentValue}
                                        onChange={(e) => {
                                            setParentValue(e.target.value);
                                            setIsArrowRotated(true);
                                        }}
                                        ref={categoryRef}
                                    />
                                    <Icon
                                        className={
                                            parentValue && isArrowRotated
                                                ? "rotated"
                                                : ""
                                        }
                                    >
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                            alt="Image"
                                        />
                                    </Icon>
                                </ParentCategoryInput>
                                {parentValue && isArrowRotated && (
                                    <ParentCategorySelcect>
                                        {parentCategory.length > 0 ? (
                                            <ul>
                                                {parentCategory.map(
                                                    (data, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => {
                                                                setSelectedParent(
                                                                    data.id
                                                                );
                                                                setParentValue(
                                                                    data.title
                                                                );
                                                                setIsArrowRotated(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {data.title}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        ) : (
                                            <ul>
                                                <li>No Category Found!</li>
                                            </ul>
                                        )}
                                    </ParentCategorySelcect>
                                )}
                            </ParentCategoryDropDown>
                            <CategoryTitleContainer>
                                <ParentCategory>Category title</ParentCategory>
                                <CategoryTitle
                                    style={{
                                        borderColor:
                                            selectedInput === "title" &&
                                            "#fe673a",
                                    }}
                                    onClick={() => {
                                        setSelectedInput("title");
                                        textInputRef.current.focus();
                                    }}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Type category title"
                                        value={categoryName}
                                        onChange={(e) =>
                                            setCategoryName(e.target.value)
                                        }
                                        ref={textInputRef}
                                    />
                                </CategoryTitle>
                                {isError && !categoryName && (
                                    <ErrorMessage>
                                        This Field is Required*
                                    </ErrorMessage>
                                )}
                            </CategoryTitleContainer>
                            <CategoryTitleContainer>
                                <ParentCategory>Category image</ParentCategory>
                                <Categoryimage
                                    onClick={() => {
                                        fileInputRef.current.click();
                                    }}
                                >
                                    <inputCatogeryCreateModal
                                        type="file"
                                        placeholder="Upload Category image"
                                        ref={fileInputRef}
                                        onChange={imageUpload}
                                    />
                                    <FileName
                                        show={categoryImage ? true : false}
                                    >
                                        {categoryImage
                                            ? categoryImage.name
                                            : "Upload Category image"}
                                    </FileName>
                                    <Icon>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-08-2022/uploaded.svg"
                                            alt="ClipIcon"
                                        />
                                    </Icon>
                                </Categoryimage>
                                {isError && !categoryImage && (
                                    <ErrorMessage>
                                        This Field is Required*
                                    </ErrorMessage>
                                )}
                            </CategoryTitleContainer>
                            <ToggleCover className={isFeatured ? "active" : ""}>
                                <ToggleContainer
                                    onClick={() => {
                                        setFeatured(!isFeatured);
                                        // setShowCalender(false);
                                    }}
                                >
                                    <ToggleBackground
                                        className={isFeatured ? "activate" : ""}
                                    >
                                        <Circle
                                            className={
                                                isFeatured ? "activate" : ""
                                            }
                                        ></Circle>
                                    </ToggleBackground>
                                </ToggleContainer>
                                <ConatinerToggle>
                                    <ToggleContent>Is featured</ToggleContent>
                                    <ToggleContentSpan>
                                        Enable this to show it on trending
                                    </ToggleContentSpan>
                                </ConatinerToggle>
                            </ToggleCover>
                        </CategoriesContainer>
                        <ButtonConatiner>
                            <Cancel
                                onClick={() => {
                                    setCategoryModal(false);
                                    clearSavedValues();
                                }}
                            >
                                Cancel
                            </Cancel>
                            <Submit
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                {saveLoader ? <ButtonLoader /> : "Submit"}
                            </Submit>
                        </ButtonConatiner>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default CatogeryCreateModal;

const MainContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Modal = styled.div`
    width: 90%;
    max-width: 494px;
    /* height: 50vh; */
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #3b3b3b;
    background: #1b1c20;
    border-radius: 5px;
    /* overflow-y: hidden; */
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-height: 200px;
`;

const ButtonConatiner = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;
    padding: 20px 20px 20px 20px;
    border-top: 1px solid #3b3b3b;
`;

const Cancel = styled.button`
    padding: 0px 14px;
    background-color: #1b1c1f;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    border: 1px solid #fe673a;
    width: 100px;
    height: 40px;
    flex-shrink: 0;
`;

const Submit = styled(Cancel)`
    background-color: #fe673a;
    margin-left: 18px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-bottom: 1px solid #3b3b3b;
`;
const HeadText = styled.h4`
    color: #fff;
    font-size: 22 px;
    font-family: "gordita_medium";
`;
const HeaderClose = styled.span`
    width: 20px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const CategoriesContainer = styled.div`
    padding: 20px 20px;
`;

const ParentCategory = styled.p`
    color: #7b7b7b;
    font-size: 14px;
    font-family: "gordita_regular";
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
`;

const ParentCategoryInput = styled.div`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 14px;
    cursor: pointer;
`;

const Input = styled.input`
    width: 100%;
    font-size: 16px;
    color: #fff;
    &::placeholder {
        color: #4f5360;
    }
`;

const Icon = styled.span`
    width: 18px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
        transition: transform 0.3s ease;
        transform: rotate(0deg);
    }

    &.rotated img {
        transform: rotate(-180deg);
    }
`;

const CategoryTitle = styled(ParentCategoryInput)``;
const CategoryTitleContainer = styled.div`
    position: relative;
`;
const ErrorMessage = styled.p`
    position: absolute;
    color: red;
    font-size: 12px;
    top: 100px;
    right: 0;
`;
const Categoryimage = styled(ParentCategoryInput)`
    justify-content: space-between;
    input {
        display: none;
    }
`;
const FileName = styled.p`
    color: ${({ show }) => (show ? "#fff" : "#4F5360")};
    font-size: 16px;
`;
const ToggleCover = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 12px;
    &.activate {
        margin-top: 20px;
    }
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
`;
const ToggleBackground = styled.div`
    position: relative;
    align-items: center;
    display: flex;
    width: 44px;
    height: 24px;
    padding: 2px;
    background-color: #161619;
    border-radius: 15px;
    border: 1px solid #fe673a;
    &.activate {
        background-color: #fe673a;
    }
`;
const Circle = styled.span`
    position: absolute;
    /* left: 4px;
    top: 1.5px; */
    width: 20px;
    height: 20px;
    background-color: #fff;
    transition: 0.4s;
    border-radius: 50%;
    &.activate {
        left: 20px;
        background-color: #fff;
    }
`;
const ConatinerToggle = styled.div`
    margin-left: 10px;
`;
const ToggleContent = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #fff;
`;

const ToggleContentSpan = styled.small`
    display: inline-block;
    color: #7b7b7b;
    font-size: 12px;
    font-family: "gordita_regular";
`;

const ParentCategorySelcect = styled.div`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    align-items: center;
    z-index: 999;
    position: absolute;
    top: 62px;
    left: 0;
    right: 0;
    width: 100%;

    margin-bottom: 14px;
    cursor: pointer;
    ul {
        width: 100%;
        min-height: 53px;
        max-height: 209px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        li {
            padding: 16px 20px;
            font-size: 14px;
            color: #fff;
            :hover {
                background: #1b1c1f;
            }
        }
    }
`;

const ParentCategoryDropDown = styled.div`
    position: relative;
`;
