import { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import $ from "jquery";
import ToggleButton from "../ToggleButton";
import { Context } from "../../../contexts/Store";
import { communityConfig } from "../../../../axiosConfig";

function SchoolScientistAddModal({
	isSchoolScientistAddModal,
	setSchoolScientistAddModal,
	setRender,
	render,
}) {
	const {
		state: {
			user_data: { access_token },
			student_data: {},
		},
		dispatch,
	} = useContext(Context);

	const [englishQuestion, setEnglishQuestion] = useState("");
	const [malayalamQuestion, setMalayalamQuestion] = useState("");
	const [optionsA, setOptionsA] = useState("");
	const [optionsB, setOptionsB] = useState("");
	const [optionsC, setOptionsC] = useState("");
	const [optionsD, setOptionsD] = useState("");
	const [correctOption, setCorrectOption] = useState("");
	const [questionCategory, setQuestionCategory] = useState("");
	const [studentCategory, setStudentCategory] = useState("");
	const [isMalayalam, setMalayalam] = useState(false);
	const [malayalamOptionA, setMalayalamOptionA] = useState("");
	const [malayalamOptionB, setMalayalamOptionB] = useState("");
	const [malayalamOptionC, setMalayalamOptionC] = useState("");
	const [malayalamOptionD, setMalayalamOptionD] = useState("");
	const [isLoading, setLoading] = useState(false)

	const addQuestion = () => {
		setLoading(true)
		communityConfig
			.post(
				`school-scientists/question/add/`,
				{
					malayalam_question: malayalamQuestion,
					english_question: englishQuestion,
					english_option1: optionsA,
					english_option2: optionsB,
					english_option3: optionsC,
					english_option4: optionsD,
					correct_option: correctOption,
					question_category: questionCategory,
					student_category: studentCategory,
					malayalam_option1: malayalamOptionA,
					malayalam_option2: malayalamOptionB,
					malayalam_option3: malayalamOptionC,
					malayalam_option4: malayalamOptionD,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				let { StatusCode } = response.data;
				if (StatusCode === 6000) {
					setSchoolScientistAddModal(false);
					setRender(!render);
					setMalayalamQuestion("");
					setEnglishQuestion("");
					setMalayalamOptionA("");
					setOptionsA("");
					setMalayalamOptionB("");
					setOptionsB("");
					setMalayalamOptionC("");
					setOptionsC("");
					setMalayalamOptionD("");
					setOptionsD("");
					setLoading(false)
				}else{
					setLoading(false)
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false)
			});
	};

	return (
		<BackContainer>
			<Cover className={isSchoolScientistAddModal ? "active" : ""}>
				<Overlay
					onClick={() => {
						setSchoolScientistAddModal(false);
					}}
				></Overlay>
				<Modal>
					<Container>
						<Head
							onClick={() => {
								setSchoolScientistAddModal(false);
							}}
						>
							<Headtitle>Add Question</Headtitle>

							<CloseIcon>
								<img
									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
									alt="Icon"
								/>
							</CloseIcon>
						</Head>
						<ToggleButton
							isMalayalam={isMalayalam}
							setMalayalam={setMalayalam}
						/>
						<AddExaminers>
							<InputField>
								<select
									onChange={(e) =>
										setStudentCategory(e.target.value)
									}
								>
									<option
										placeholder=""
										disabled
										selected
										value={
											isMalayalam
												? malayalamQuestion
												: englishQuestion
										}
									>
										select school category
									</option>
									<option value="up">UP</option>
									<option value="hs">HS</option>
									<option value="hss">HSS</option>
								</select>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<select
									onChange={(e) =>
										setQuestionCategory(e.target.value)
									}
								>
									<option
										placeholder=""
										disabled
										selected
										value={
											isMalayalam
												? malayalamQuestion
												: englishQuestion
										}
									>
										select Question category
									</option>
									<option value="mathematics">
										Mathematics
									</option>
									<option value="science">science</option>
									<option value="technology">
										Technology & Engineering
									</option>
									<option value="physics">Physics</option>
									<option value="chemistry">Chemistry</option>
								</select>
							</InputField>
						</AddExaminers>
						<Servicesfields>
							<SelectCampusInput
								type="text-area"
								placeholder="Enter your Question"
								value={
									isMalayalam
										? malayalamQuestion
										: englishQuestion
								}
								onChange={(e) => {
									if (isMalayalam) {
										setMalayalamQuestion(e.target.value);
									} else {
										setEnglishQuestion(e.target.value);
									}
								}}
							/>
						</Servicesfields>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Enter option A"
									type="text"
									value={
										isMalayalam
											? malayalamOptionA
											: optionsA
									}
									onChange={(e) => {
										if (isMalayalam) {
											setMalayalamOptionA(e.target.value);
										} else {
											setOptionsA(e.target.value);
										}
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Enter option B"
									type="text"
									value={
										isMalayalam
											? malayalamOptionB
											: optionsB
									}
									onChange={(e) => {
										if (isMalayalam) {
											setMalayalamOptionB(e.target.value);
										} else {
											setOptionsB(e.target.value);
										}
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Enter option C"
									type="text"
									value={
										isMalayalam
											? malayalamOptionC
											: optionsC
									}
									onChange={(e) => {
										if (isMalayalam) {
											setMalayalamOptionC(e.target.value);
										} else {
											setOptionsC(e.target.value);
										}
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<input
									placeholder="Enter option D"
									type="text"
									value={
										isMalayalam
											? malayalamOptionD
											: optionsD
									}
									onChange={(e) => {
										if (isMalayalam) {
											setMalayalamOptionD(e.target.value);
										} else {
											setOptionsD(e.target.value);
										}
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<InputField>
								<select
									onChange={(e) =>
										setCorrectOption(e.target.value)
									}
								>
									<option placeholder="" disabled selected>
										Select Right Answer
									</option>
									<option value="option1">A</option>
									<option value="option2">B</option>
									<option value="option3">C</option>
									<option value="option4">D</option>
								</select>
							</InputField>
						</AddExaminers>
						<CreateButtonBox>
							<CancelButton
								onClick={() => {
									setSchoolScientistAddModal(false);
								}}
							>
								Cancel
							</CancelButton>
							<CreateButton
								onClick={() => {
									addQuestion();
								}}
							>
								{/* {setSchoolScientistAddModal ? (
									"Submit"
								) : (
									<ButtonLoader />
								)} */}
								{isLoading ? <ButtonLoader/> : "Submit"}
							</CreateButton>
						</CreateButtonBox>
					</Container>
				</Modal>
			</Cover>
		</BackContainer>
	);
}

export default SchoolScientistAddModal;

const Cover = styled.div`
	position: fixed;
	transition: 0.3s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	&.active {
		transform: scale(1, 1);
		backdrop-filter: blur(4px);
	}
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	position: fixed;
	z-index: 101;
	left: 0;
	top: 0px;
	width: 100%;
	z-index: 1000;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
`;
const Modal = styled.div`
	width: 90%;
	max-width: 640px;
	max-height: 100vh;
	position: absolute;
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s;
	z-index: 1000;
	border: 1px solid #26272a;
	background: #1b1c1f;
	border-radius: 5px;
	overflow-y: hidden;
	box-shadow: 0px 3px 56px #00000048;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}

	@media all and (max-width: 1280px) {
        max-height: 94vh;
	}
	@media all and (max-width: 400px) {
		max-height: 70vh;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
    @media all and (max-width: 400px) {
		max-height: 90vh;
	}
	
`;
const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 40px 30px;
	width: 100%;
	height: 100%;
	@media all and (max-width: 768px) {
		padding: 30px;
	}
	@media all and (max-width: 480px) {
		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	@media all and (max-width: 360px) {
		padding: 10px;
	}
`;

const Head = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;
const Headtitle = styled.p`
	font-size: 18px;
	color: #fff;
	font-family: "gordita_medium";
    @media all and (max-width: 360px) {
		font-size: 15px;
	}
`;
const CloseIcon = styled.div`
	width: 22px;
	cursor: pointer;
	img {
		width: 100%;
		display: block;
	}
`;

const AddExaminers = styled.div``;
const InputField = styled.div`
	margin-bottom: 20px !important;
	background: #161619;
	border-radius: 5px;
	margin-left: 0;
	width: 100%;
	border: 1px solid #26272a;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 15px;
	position: relative;
	select {
		width: 100%;
		padding: 15px 10px;
		color: #fff !important;
	}
	input {
		width: 100%;
		padding: 15px 10px;
		color: #fff !important;
	}
	/* img {
        transition: 0.5s ease;
        cursor: pointer;
        &.active {
            transform: rotate(-180deg);
            transition: 0.5s ease;
        }
    } */
	transition: 3s ease;
	option {
		background: #161619;
		color: #fff;
	}
`;
const ImageContainer = styled.div`
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 100%;
		display: block;
	}
`;
const DropDown = styled.div`
	z-index: 100;
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid #26272a;
	border-radius: 5px;
	bottom: 50px;
	background: #1b1c1f;
	left: 0;
	top: 100%;
	color: #fff;
	transition: 3s ease;
	overflow: scroll;
	min-height: 130px;
	::-webkit-scrollbar {
		display: none;
	}
	&.question {
		min-height: 210px;
	}
	span {
		cursor: pointer;
		text-align: left;
		width: 100%;
		padding: 10px;
		font-size: 14px;
		color: #fff;
		background: #161619;
		text-transform: capitalize;
		:nth-child(2n) {
			background: #1b1c1f;
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			font-size: 14px;
			color: #fff;
		}
	}
`;
const TickImage = styled.img`
	display: none;
	&.selected {
		display: block;
		width: 18px;
	}
`;

const SelectCampusInput = styled.textarea`
	background-color: #161619;
	border: 1px solid #26272a;
	border-radius: 5px !important;
	width: 100%;
	padding: 10px;
	color: #fff;
	height: 100px;
	resize: none;
	&.adress {
		height: 22vh;
	}
	@media all and (max-width: 480px) {
		padding: 15px 0px 15px 10px;
	}
`;
const CancelButton = styled.div`
	color: #fff;
	border-radius: 5px !important;
	width: 120px;
	height: 40px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;
	background: #161619;
	cursor: pointer;
	font-family: "gordita_medium";
	margin-right: 20px;
	border: 1px solid #fe673a;
`;
const Servicesfields = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 25px;
	:last-child {
		margin-left: 20px;
	}
	@media all and (max-width: 480px) {
		width: 100%;
		:last-child {
			margin-left: 0px;
			margin-top: 20px;
		}
	}
`;
const CreateButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	@media all and (max-width: 480px) {
		margin-top: 10px;
	}
`;
const CreateButton = styled.div`
	background: #fe673a;
	color: #fff;
	border-radius: 5px !important;
	width: 120px;
	height: 40px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;
	cursor: pointer;
	font-family: "gordita_medium";
`;
