import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
// import TrainerCard from "./TrainerCard";
import SectionLoader from "../../../helpers/SectionLoader";
import MeetCard from "./MeetCard";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import NoData from "../../../includes/jobdesk/NoData";
import SuccessModal from "../../../includes/subscription_manager/modal/SuccessModal";

export default function MeetDetails({
    userData,
    isReload,
    pagination,
    handlePageClick,
    handleCardButtonClick,
    closePopup,
    selectedCard,
    setSelectedCard,
    loading,
}) {
    return (
        <>
            <Container>
                <Cover>
                    <Category>
                        <Item type="slno">Sl No.</Item>
                        <Item type="title">Title</Item>
                        <Item type="led">Lead by</Item>
                        <Item type="date">Date</Item>
                        <Item type="seats">Seats</Item>
                        <Item type="reg">Registrations</Item>
                        <Item type="for">Meets for</Item>
                        <Item type="status">Status</Item>
                        <Item>Action</Item>
                    </Category>
                    {userData?.length > 0 ? (
                        <>
                            {userData.map((item, index) => (
                                <MeetCard
                                    item={item}
                                    key={index}
                                    index={index}
                                    pagination={pagination}
                                    handleCardButtonClick={
                                        handleCardButtonClick
                                    }
                                    closePopup={closePopup}
                                    selectedCard={selectedCard}
                                    setSelectedCard={setSelectedCard}
                                />
                            ))}
                        </>
                    ) : loading ? (
                        <SectionLoader />
                    ) : (
                        <NoData />
                    )}
                </Cover>
            </Container>
            {pagination?.total_pages > 1 && userData?.length > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        Showing students {pagination?.first_item} -{" "}
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </>
    );
}

const Container = styled.div`
    @media all and (max-width: 1350px) {
        overflow-x: scroll;
    }

    ::-webkit-scrollbar {
        display: none;
    }
`;
const Cover = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1b1c20;
    color: #fff;
    min-width: 1300px;
    border: 1px solid rgba(44, 44, 44, 1);
    border-radius: 4px;
    .card-container {
        border-bottom: 1px solid #26272a;
        :last-child {
            border-bottom: 0px solid #000;
        }
    }
`;

const Category = styled.div`
    border-bottom: 1px solid #26272a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
`;

const Item = styled.li`
    font-size: 14px;
    text-align: ${({ type }) => (type === "count" ? "center" : "left")};

    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    p {
        width: 60%;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "title"
            ? "19%"
            : type === "led"
            ? "15%"
            : type === "date"
            ? "10%"
            : type === "seats"
            ? "10%"
            : type === "reg"
            ? "10%"
            : type === "for"
            ? "15%"
            : type === "status"
            ? "10%"
            : "5%"};

    list-style: none;
`;

const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    margin-top: 170px;
    height: calc(100% - (-50px));
    margin-bottom: 170px;
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;
const CardImage = styled.img`
    width: 100%;
    display: block;
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
