import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import NoData from "../../includes/jobdesk/NoData";
import SectionLoader from "../../helpers/SectionLoader";
import ReactPaginate from "react-paginate";
import { Link, useSearchParams } from "react-router-dom";
import CreateCampusModal from "./modals/CreateCampusModal";
import CreateSubscriptionModal from "./modals/CreateSubscriptionModal";
import SuccessModal from "../../includes/subscription_manager/modal/SuccessModal";
import PasswordShowModal from "./modals/PasswordShowModal";
import PaymentModal from "./modals/PaymentModal";

function CampusList() {
    const [campusInput, setCampusInput] = useState("");
    const [CampusList, setCampusLists] = useState("");
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;
    const [loading, setLoading] = useState(false);
    const [isReload, setReload] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [isModal, setModal] = useState(false);
    const [isEvent, setEvent] = useState(false);
    const [subModal, setSubModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [campusPk, setCampusPk] = useState(null);
    const [campusUserPk, setCampusUserPk] = useState(null);
    const [userPassword, setUserPassword] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isPassLoading, setPassLoading] = useState(false);
    //search params
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const [successful, setSuccessful] = useState(false);
    const [isPayment, setPayment] = useState(false);
    const [selectedUsername, setSelectedUsername] = useState(false);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        console.log(currentPage, "currentPagecampus");
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
        console.log(searchParams, "searchParamscampus");
    };
    // api for search campus

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await accountsConfig.get(
                    `api/v1/campuses/all-campuses/?q=${campusInput}`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: {
                            page: currentPage,
                        },
                    }
                );

                const { StatusCode, data, pagination_data } = response.data;

                if (StatusCode === 6000) {
                    setCampusLists(data);
                    setPagination(pagination_data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [campusInput, currentPage, searchParams, isReload]);

    const handleSearchInputChange = (e) => {
        setCampusInput(e.target.value);
    };

    const fetchPassword = (username) => {
        setPassLoading(true);
        accountsConfig
            .get(
                "api/v1/users/chief/get-details/?chief_username=" + username,
                {
                    chief_username: username,
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setUserPassword(response.data.password);
                    setIsShowPassword(true);
                    setPassLoading(false);
                } else {
                    setPassLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setPassLoading(false);
            });
    };

    return (
        <>
            <Container>
                <CreateCampusModal
                    isModal={isModal}
                    setModal={setModal}
                    setEvent={setEvent}
                    editModal={editModal}
                    setEditModal={setEditModal}
                    campusPk={campusPk}
                    setSuccessful={setSuccessful}
                    setReload={setReload}
                />
                <CreateSubscriptionModal
                    campusPk={campusPk}
                    isModal={subModal}
                    setModal={setSubModal}
                    campusUserPk={campusUserPk}
                    setSuccessful={setSuccessful}
                    setReload={setReload}
                />
                <SuccessModal
                    isModal={successful}
                    setReload={setReload}
                    setModal={setSuccessful}
                />
                <PaymentModal
                    isModal={isPayment}
                    setModal={setPayment}
                    campusPk={campusPk}
                    setCampusPk={setCampusPk}
                />
                <TopContainer>
                    <Heading>Campus Lists</Heading>
                    <TopDiv>
                        <CreateButton
                            onClick={() => {
                                setModal(true);
                                setEditModal(false);
                            }}
                        >
                            <Plusicon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/plus.svg"
                                    alt="Icon"
                                />
                            </Plusicon>
                            <CreateTitle>Create</CreateTitle>
                        </CreateButton>

                        <SearchContainer>
                            <SearchBar>
                                <InputField
                                    placeholder="Search Here"
                                    value={campusInput}
                                    onChange={handleSearchInputChange}
                                />
                            </SearchBar>
                            <SearchImageContainer>
                                <SearchImg
                                    src={
                                        require("../../../assets/images/request/search-icon.svg")
                                            .default
                                    }
                                    alt="Search-icon"
                                />
                            </SearchImageContainer>
                        </SearchContainer>
                    </TopDiv>
                </TopContainer>
                <BottomContainer>
                    <MainHead>
                        <MainSub>
                            <Contents type="slno">Sl No</Contents>
                            <Contents type="campus">
                                School / college name
                            </Contents>
                            <Contents type="token">Domain</Contents>
                            <Contents type="local">Local Body</Contents>
                            <Contents type="district">Credential</Contents>
                            <Contents type="subscription">
                                Subscription
                            </Contents>
                        </MainSub>
                    </MainHead>
                    {loading ? (
                        <SectionLoader />
                    ) : (
                        <>
                            {CampusList?.length > 0 ? (
                                CampusList.map((campus, index) => (
                                    <UlList key={campus.id}>
                                        {pagination?.first_item <=
                                            pagination?.last_item && (
                                            <Li type="slno">
                                                {pagination?.first_item + index}
                                            </Li>
                                        )}
                                        <Li type="campus" title={campus?.name}>
                                            <h2>{campus?.name}</h2>
                                        </Li>
                                        <Li type="token">
                                            <LinkTo
                                                href={
                                                    campus?.domain
                                                        ? `https://${campus.domain}`
                                                        : ""
                                                }
                                                target="_blank"
                                            >
                                                {campus?.domain
                                                    ? campus?.domain
                                                    : "--"}
                                            </LinkTo>
                                        </Li>
                                        <Li type="local">
                                            {campus?.local_body
                                                ? campus?.local_body
                                                : "--"}
                                        </Li>
                                        <Li type="district">
                                            <p
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    fetchPassword(
                                                        campus.username
                                                    );
                                                    setSelectedUsername(
                                                        selectedUsername ===
                                                            index
                                                            ? null
                                                            : index
                                                    );
                                                }}
                                            >
                                                {campus.username === null
                                                    ? "--"
                                                    : campus.username}
                                            </p>

                                            <PasswordShowModal
                                                showModal={
                                                    selectedUsername === index
                                                        ? true
                                                        : false
                                                }
                                                userPassword={userPassword}
                                                setIsShowPassword={
                                                    setIsShowPassword
                                                }
                                                isShowPassword={isShowPassword}
                                                setSelectedUsername={
                                                    setSelectedUsername
                                                }
                                                isPassLoading={isPassLoading}
                                            />
                                        </Li>

                                        <Li type="subscription">
                                            {campus?.has_subscription ===
                                            true ? (
                                                <SubscribeButtion
                                                    className="payment"
                                                    onClick={() => {
                                                        setPayment(true);
                                                        // setEditModal(true);
                                                        setCampusPk(campus?.id);
                                                    }}
                                                >
                                                    Payments
                                                </SubscribeButtion>
                                            ) : (
                                                <>
                                                    {campus.has_subscription ===
                                                    true ? (
                                                        <SubscribeButtion className="sub">
                                                            Subscribed
                                                        </SubscribeButtion>
                                                    ) : (
                                                        <SubscribeButtion
                                                            onClick={() => {
                                                                setSubModal(
                                                                    true
                                                                );
                                                                setCampusPk(
                                                                    campus?.id
                                                                );
                                                                setCampusUserPk(
                                                                    campus.user_pk
                                                                );
                                                            }}
                                                        >
                                                            Subscribe
                                                        </SubscribeButtion>
                                                    )}
                                                </>
                                            )}

                                            <EditButtion
                                                onClick={() => {
                                                    setModal(true);
                                                    setEditModal(true);
                                                    setCampusPk(campus.id);
                                                }}
                                            >
                                                Edit
                                            </EditButtion>
                                        </Li>
                                    </UlList>
                                ))
                            ) : (
                                <NoData />
                            )}
                        </>
                    )}
                </BottomContainer>
            </Container>
            {pagination?.total_pages > 1 && CampusList?.length > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        Showing students {pagination?.first_item} -{" "}
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </>
    );
}

export default CampusList;

const Container = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;

const BottomContainer = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 10px;
    // @media all and (max-width: 1080px) {
    //     grid-template-columns: 1fr 1fr;
    // }
    // @media all and (max-width: 640px) {
    //     grid-template-columns: 1fr;
    // }
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    display: flex;
    font-family: "gordita_medium";
    font-size: 16px;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "campus"
            ? "20%"
            : type === "token"
            ? "25%"
            : type === "local"
            ? "20%"
            : type === "district"
            ? "15%"
            : type === "subscription"
            ? "20%"
            : ""};
    justify-content: ${({ type }) =>
        type === "subscription" ? "center" : "flex-start"};
`;
const UlList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    align-items: center;
    border-bottom: 1px solid #26272a;
    :last-child {
        border-bottom: 0px solid #26272a;
    }
`;
const Li = styled.li`
    position: relative;
    display: flex;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "campus"
            ? "20%"
            : type === "token"
            ? "25%"
            : type === "local"
            ? "20%"
            : type === "district"
            ? "15%"
            : type === "subscription"
            ? "20%"
            : ""};
    justify-content: ${({ type }) =>
        type === "subscription" ? "center" : "flex-start"};
    cursor: ${({ type }) => (type === "district" ? "pointer" : "")};
    p {
        color: #fff;
    }
    h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff;
        font-size: 16px;
        width: 78%;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10px;
    border: 2px solid #1b1c20;
    border-radius: 3px;
    margin-left: 20px;
`;
const CreateButton = styled.div`
    display: flex;
    align-items: center;
    background-color: #fe673a;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
`;
const Plusicon = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const CreateTitle = styled.span`
    font-size: 14px;
    color: #fff;
    font-family: "gordita_medium";
    margin-left: 5px;
`;

const SearchBar = styled.div`
    // padding: 10px 24px;
    background: #161619;
    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background: #161619;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 10px 0;
    cursor: pointer;
`;
const SearchImg = styled.img`
    width: 100%;
    display: block;
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;

const SubscribeButtion = styled.div`
    border: 1px solid rgb(254, 103, 58);
    background-color: rgb(254, 103, 58);
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: 15px;
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    font-family: "gordita_medium";
    margin-right: 6px;
    &.sub {
        border: 1px solid rgb(254, 103, 58);
        color: rgb(255, 255, 255);
        background-color: #000;
        font-size: 14px;
        cursor: not-allowed;
    }
    &.payment {
        background-color: green;
        border: none;
    }
`;
const EditButtion = styled(SubscribeButtion)`
    border: 1px solid rgb(254, 103, 58);
    color: rgb(255, 255, 255);
    background-color: #000;
    font-size: 14px;
`;
const LinkTo = styled.a`
    color: #fff;
    overflow: scroll;
    width: 80%;
    ::-webkit-scrollbar {
        display: none;
    }
`;
