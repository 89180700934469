import React, { useContext, useEffect, useState } from "react";
import SteypHelmet from "../../helpers/SteypHelmet";
import styled from "styled-components";
import EvaluvationHeader from "../../includes/projects-section/EvaluvationHeader";
import EvaluvationStudentDetails from "../../includes/projects-section/EvaluvationStudentDetails";
import EvaluvationTable from "../../includes/projects-section/EvaluvationTable";
import ProjectAddModal from "../../includes/projects-section/modal/ProjectAddModal";
import $ from "jquery";
import ProjectPauseModal from "../../includes/projects-section/modal/ProjectPauseModal";
import ProjectViewDetialsModal from "../../includes/projects-section/modal/ProjectViewDetialsModal";
import { learnConfig } from "../../../axiosConfig";
import { useParams, useSearchParams } from "react-router-dom";
import { Context } from "../../contexts/Store";
import ProjectActivityModal from "../../includes/projects-section/modal/ProjectActivityModal";
import ProjectCommitModal from "../../includes/projects-section/modal/ProjectCommitModal";
import ProjectCompleteModal from "../../includes/projects-section/modal/ProjectCompleteModal";

function ProjectsEvaluationPage() {
    const [studentData, setStudentData] = useState({});
    const [EvaluationID, setEvaluationID] = useState("");
    const [studentPk, setStudentPk] = useState("");
    const [projectName, setProjectName] = useState("");
    const [isPause, setPause] = useState(false);
    const [modal, setModal] = useState(false);
    const [commitModal, setCommitModal] = useState(false);
    const [isView, setView] = useState(false);
    const params = useParams();
    const [reLoad, setReLoad] = useState(false);
    const [activityModal, setActivityModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [bugsList, setBugsList] = useState([]);
    const [btnStatus, setBtnStatus] = useState("");
    const [pkvlaue, setPkValue] = useState("");
    const [sectionLoader, setSectionLoader] = useState(false);
    const [addBugData, setAddBugData] = useState({});
    const [activitiData, setActivitiData] = useState([]);
    const [commitData, setCommitData] = useState([]);
    const [issues, setIssues] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [isComplete, setComplete] = useState(false);
    const [selectedScore, setSelectedScore] = useState("");
    const [isProjectComplete, setProjectComplete] = useState(false);
    const [status, setStatus] = useState({
        id: 1,
        name: "Open",
        title: "open",
        color: "#54cff5",
    });
    const [totalpoint, setTotalPoint] = useState("");
    //pagination

    const [pagination, setPagination] = useState({});
    const [readPagination, setReadPagination] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams("");
    const [searchWord, setSearchWord] = useState("");
    const currentParams = Object.fromEntries([...searchParams]);
    const [description, setDescription] = useState("");
    const [isPaused, setPaused] = useState(false);
    const [apiError, setApiError] = useState("");
    const [bugEditData, setBugEditData] = useState({});
    const [editingIssue, setEditingIssue] = useState(false);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    // Remove overflow scroll on modal
    useEffect(() => {
        if (modal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [modal]);

    // single page list api
    useEffect(() => {
        const fetchSingleUserData = async () => {
            try {
                await learnConfig
                    .get(`/projects/chief/${params?.evaluation_id}/`, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: searchWord,
                    })

                    .then((res) => {
                        let { StatusCode, data, pagination_data } = res.data;
                        if (StatusCode === 6000) {
                            setStudentData(data);
                            setTotalPoint(data?.student_project?.project_score);
                            // setPagination(pagination_data);
                        } else {
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };

        fetchSingleUserData();
    }, [reLoad]);

    // bug list api call
    useEffect(() => {
        const fetchBugUserData = async () => {
            setSectionLoader(true);
            try {
                if (pkvlaue) {
                    await learnConfig
                        .get(`/projects/chief-issues/${pkvlaue}/`, {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                            params: {
                                q: searchWord,
                                page: currentPage + 1,
                            },
                        })

                        .then((res) => {
                            let {
                                StatusCode,
                                data,
                                pagination_data,
                                evaluation_status,
                            } = res.data;
                            if (StatusCode === 6000) {
                                setBugsList(data);
                                setSectionLoader(false);
                                setPagination(pagination_data);
                                setBtnStatus(evaluation_status);
                                return data, pagination_data;
                            } else {
                                setSectionLoader(false);
                            }
                        });
                }
            } catch (error) {
                setSectionLoader(false);
            }
        };
        fetchBugUserData();
    }, [reLoad, pkvlaue, searchWord, currentPage]);
    //bug create post api
    const bugCreate = (evaluation_id, status, parent_issue) => {
        setButtonLoader(true);
        let formData = new FormData();
        formData.append("status", status);
        formData.append("parent_issue", parent_issue);
        learnConfig
            .post(
                `/projects/create-new-bug/${studentData?.student_project?.id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((res) => {
                let { StatusCode, data } = res.data;
                if (StatusCode === 6000) {
                    setAddBugData(data);
                    setButtonLoader(false);
                    setPkValue(data?.evaluation_pk);
                    setEditingIssue(false);
                    if (status === "fixed") {
                        setView(false);
                        setButtonLoader(false);
                        setReLoad(!reLoad);
                    } else {
                        setView(false);
                        setModal(true);
                        setButtonLoader(false);
                    }
                    if (status === "re-open") {
                        setStatus({
                            id: 2,
                            name: "Reopen",
                            title: "re_open",
                            color: "#F79009",
                        });
                    }
                    if (status === "open") {
                        setStatus({
                            id: 1,
                            name: "Open",
                            title: "open",
                            color: "#2E90FA",
                        });
                    }
                    // setReLoad(!reLoad);
                }
            })
            .catch((error) => {
                setButtonLoader(false);
            });
    };

    useEffect(() => {
        const activitiesView = async () => {
            try {
                await learnConfig
                    .get(
                        `/projects/show-activities/${params?.evaluation_id}/`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                            params: searchWord,
                        }
                    )

                    .then((res) => {
                        let { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setActivitiData(data);
                        } else {
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };

        activitiesView();
    }, [reLoad]);

    useEffect(() => {
        const commitHistory = async () => {
            try {
                await learnConfig
                    .get(
                        `/projects/commit-histories/${params?.evaluation_id}/`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                            params: {
                                page: pageNumber,
                            },
                        }
                    )

                    .then((res) => {
                        let { StatusCode, data, pagination_data } = res.data;
                        if (StatusCode === 6000) {
                            setCommitData(data);
                            setReadPagination(pagination_data);
                        } else {
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };

        commitHistory();
    }, [reLoad, pageNumber]);

    const viewIsuueDetails = async (issue_pk) => {
        try {
            await learnConfig
                .get(`/projects/view-issue-details/${issue_pk}/`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })

                .then((res) => {
                    let { StatusCode, data } = res.data;
                    if (StatusCode === 6000) {
                        setIssues(data);
                    } else {
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const pauseProject = (status) => {
        setPaused(true);
        let formData = new FormData();
        formData.append("comment", description);
        formData.append("status", status);
        formData.append(
            "student_id ",
            studentData?.student_project?.student?.student_pk
        );
        formData.append("project ", studentData?.student_project?.id);

        learnConfig
            .post(`projects/chief-qa-activity/${EvaluationID}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((res) => {
                let { StatusCode, data, message } = res.data;
                if (StatusCode === 6000) {
                    setModal(false);
                    setModalType("");
                    setDescription("");
                    setPaused(false);
                    setPause(false);
                    setReLoad(!reLoad);
                    setApiError("");
                } else {
                    setPaused(false);
                    setApiError(message?.message);
                }
            })
            .catch((err) => {
                setPaused(false);
                setApiError(
                    err?.response?.data?.message || "An error occurred"
                );
            });
    };

    //Project Complete
    const projectComplete = () => {
        setProjectComplete(true);
        let formData = new FormData();
        formData.append("score", selectedScore);

        learnConfig
            .post(
                `projects/complete-student-project/${params?.evaluation_id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((res) => {
                let { StatusCode, data, message } = res.data;
                if (StatusCode === 6000) {
                    setComplete(false);
                    setProjectComplete(false);
                    setPause(false);
                    setReLoad(!reLoad);
                    setApiError("");
                    setSelectedScore("");
                } else {
                    setProjectComplete(false);
                    setApiError(message?.message);
                }
            })
            .catch((err) => {
                setProjectComplete(false);
                setApiError(
                    err?.response?.data?.message || "An error occurred"
                );
            });
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    return (
        <Container id="main">
            <MainContainer>
                <ContainerDiv>
                    <SteypHelmet title="Evaluvation" />
                    <ProjectAddModal
                        modal={modal}
                        setModal={setModal}
                        EvaluationID={EvaluationID}
                        setReLoad={setReLoad}
                        reLoad={reLoad}
                        addBugData={addBugData}
                        pkvlaue={pkvlaue}
                        setStatus={setStatus}
                        status={status}
                        bugEditData={bugEditData}
                        setBugEditData={setBugEditData}
                        editingIssue={editingIssue}
                    />
                    {modalType && (
                        <ProjectPauseModal
                            modal={isPause}
                            setModal={setPause}
                            description={description}
                            setDescription={setDescription}
                            modalType={modalType}
                            pauseProject={pauseProject}
                            buttonLoader={isPaused}
                            setButtonLoader={setPaused}
                            apiError={apiError}
                            setApiError={setApiError}
                        />
                    )}
                    <ProjectCompleteModal
                        modal={isComplete}
                        setModal={setComplete}
                        projectComplete={projectComplete}
                        apiError={apiError}
                        setApiError={setApiError}
                        totalpoint={totalpoint}
                        setSelectedScore={setSelectedScore}
                        selectedScore={selectedScore}
                        buttonLoader={isProjectComplete}
                        setButtonLoader={setProjectComplete}
                    />
                    <ProjectViewDetialsModal
                        modal={isView}
                        setModal={setView}
                        issues={issues}
                        bugCreate={bugCreate}
                        EvaluationID={EvaluationID}
                        projectId={studentData?.student_project?.id}
                    />
                    <ProjectCommitModal
                        modal={commitModal}
                        setModal={setCommitModal}
                        activitiData={commitData}
                        pagination={readPagination}
                        setPageNumber={setPageNumber}
                    />
                    <ProjectActivityModal
                        modal={activityModal}
                        setModal={setActivityModal}
                        activitiData={activitiData}
                    />
                    <EvaluvationHeader
                        setComplete={setComplete}
                        studentData={studentData}
                        btnStatus={btnStatus}
                        projectComplete={projectComplete}
                    />
                    <EvaluvationStudentDetails
                        studentData={studentData}
                        setActivityModal={setActivityModal}
                        setCommitModal={setCommitModal}
                    />
                    <HrLine></HrLine>
                    <EvaluvationTable
                        studentData={studentData}
                        setModal={setModal}
                        setPause={setPause}
                        setView={setView}
                        setBugOpen={setEvaluationID}
                        bugOpen={EvaluationID}
                        setStudentPk={setStudentPk}
                        setProjectName={setProjectName}
                        pagination={pagination}
                        setSearchParams={setSearchParams}
                        setCurrentPage={setCurrentPage}
                        currentParams={currentParams}
                        searchWord={searchWord}
                        setSearchWord={setSearchWord}
                        currentPage={currentPage}
                        handlePageClick={handlePageClick}
                        setModalType={setModalType}
                        bugsList={bugsList}
                        btnStatus={btnStatus}
                        setPkValue={setPkValue}
                        sectionLoader={sectionLoader}
                        bugCreate={bugCreate}
                        viewIsuueDetails={viewIsuueDetails}
                        activitiData={activitiData}
                        buttonLoader={buttonLoader}
                        pauseProject={pauseProject}
                        setBugEditData={setBugEditData}
                        editingIssue={editingIssue}
                        setEditingIssue={setEditingIssue}
                    />
                </ContainerDiv>
            </MainContainer>
        </Container>
    );
}

export default ProjectsEvaluationPage;
const MainContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ContainerDiv = styled.div`
    /* min-width: 1620px; */
    min-width: 1100px;
`;
const Container = styled.div``;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
    margin: 24px 0;
`;
