import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

//components
import AEPformpage from "../../../includes/aep/AEPformpage";
const AepLeadsTechSchooling = lazy(() =>
    import("../../../screens/aep/AepLeadsTechSchooling")
);
const AepLeadsTechDegree = lazy(() =>
    import("../../../screens/aep/AepLeadsTechDegree")
);
const AepLeadsTechGrad = lazy(() =>
    import("../../../screens/aep/AepLeadsTechGrad")
);
const AepLeadsTechnologySchool = lazy(() =>
    import("../../../screens/aep/AepLeadsTechnologySchool")
);
const SteypHelmet = lazy(() => import("../../../helpers/SteypHelmet"));

function AepLeadInnerRouter() {
    return (
        <>
            <SteypHelmet title="AEP Leads" />
            <Routes>
                <Route
                    path="tech-schooling"
                    element={<AepLeadsTechSchooling />}
                />
                <Route path="tech-degree" element={<AepLeadsTechDegree />} />
                <Route path="tech-grad" element={<AepLeadsTechGrad />} />
                <Route
                    path="technology-school"
                    element={<AepLeadsTechnologySchool />}
                />
            </Routes>
        </>
    );
}

export default AepLeadInnerRouter;
