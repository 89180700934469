import React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import DoubtHub from "../../../../screens/doubt-hub/DoubtHub";
import Reports from "../../../../screens/doubt-hub/Reports";
import RemovedLogs from "../../../../screens/doubt-hub/RemovedTags";
import Tags from "../../../../screens/doubt-hub/Tags";
import Comments from "../../../../screens/doubt-hub/Comments";
import Answers from "../../../../screens/doubt-hub/Answers";
import ViewPage from "../../../../screens/doubt-hub/ViewPage";
import ReportAnswers from "../../../../screens/doubt-hub/ReportAnswers";
import ReportQuestions from "../../../../screens/doubt-hub/ReportQuestions";
import ReportComments from "../../../../screens/doubt-hub/ReportComments";
import ReportViewPage from "../../../../screens/doubt-hub/ReportViewPage";
import QuestionsReport from "../../../../screens/doubt-hub/QuestionsReport";
import CommentReport from "../../../../screens/doubt-hub/CommentReport";
import AnswerReport from "../../../../screens/doubt-hub/AnswerReport";
import MyAnswersViewPage from "../../../../screens/doubt-hub/MyAnswersViewPage";
import MyCommentsViewPage from "../../../../screens/doubt-hub/MyCommentsViewPage";
import RemovedLogsAnswers from "../../../../screens/doubt-hub/RemovedLogsAnswers";
import RemovedLogsComments from "../../../../screens/doubt-hub/RemovedLogsComments";
import ReportAnswerView from "../../../../screens/doubt-hub/ReportAnswerView";
import ReportCommentsView from "../../../../screens/doubt-hub/ReportCommentsView";

function DoubtHubRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route exact path="/" element={<DoubtHub />} />
                <Route exact path="/doubts/" element={<DoubtHub />} />
                <Route exact path="/removed-logs/" element={<RemovedLogs />} />
                <Route exact path="tags/" element={<Tags />} />
                <Route
                    exact
                    path="/removed-logs/answers/"
                    element={<RemovedLogsAnswers />}
                />
                <Route
                    exact
                    path="/removed-logs/comments/"
                    element={<RemovedLogsComments />}
                />

                <Route
                    exact
                    path="/doubts/my-comments/"
                    element={<Comments />}
                />
                <Route exact path="/doubts/my-answers/" element={<Answers />} />
                <Route
                    exact
                    path="/doubts/my-answers/view-page/:pk/"
                    element={<MyAnswersViewPage />}
                />
                <Route
                    exact
                    path="/doubts/my-comments/view-page/:pk/"
                    element={<MyCommentsViewPage />}
                />

                <Route exact path="/doubts/:pk/" element={<ViewPage />} />

                <Route exact path="/reports/questions/" element={<Reports />} />
                <Route
                    exact
                    path="/reports/questions/:pk/"
                    element={<ReportViewPage />}
                />
                <Route
                    exact
                    path="/reports/answers/"
                    element={<ReportAnswers />}
                />
                <Route
                    exact
                    path="/reports/answers/:pk/"
                    element={<ReportAnswerView />}
                />
                <Route
                    exact
                    path="/reports/comment-report/"
                    element={<CommentReport />}
                />
                <Route
                    exact
                    path="/reports/comments/"
                    element={<ReportComments />}
                />
                <Route
                    exact
                    path="/reports/comments/:pk/"
                    element={<ReportCommentsView />}
                />
                <Route
                    exact
                    path="/reports/answer-report/"
                    element={<AnswerReport />}
                />
                <Route
                    exact
                    path="/reports/questions-report/"
                    element={<QuestionsReport />}
                />
            </Routes>
        </Container>
    );
}

export default DoubtHubRouter;

const Container = styled.div``;
