import React from "react";

export const Play = () => (
    <svg viewBox="0 0 34 34" width="34" height="34" class="">
        <path
            fill="#adbac1"
            d="M8.5 8.7c0-1.7 1.2-2.4 2.6-1.5l14.4 8.3c1.4.8 1.4 2.2 0 3l-14.4 8.3c-1.4.8-2.6.2-2.6-1.5V8.7z"
        ></path>
    </svg>
);

export const Pause = () => (
    <svg viewBox="0 0 34 34" width="34" height="34" class="">
        <path
            fill="#adbac1"
            d="M9.2 25c0 .5.4 1 .9 1h3.6c.5 0 .9-.4.9-1V9c0-.5-.4-.9-.9-.9h-3.6c-.4-.1-.9.3-.9.9v16zm11-17c-.5 0-1 .4-1 .9V25c0 .5.4 1 1 1h3.6c.5 0 1-.4 1-1V9c0-.5-.4-.9-1-.9 0-.1-3.6-.1-3.6-.1z"
        ></path>
    </svg>
);

export const MenuArrow = () => (
    <svg viewBox="0 0 18 18" width="18" height="18" class="">
        <path
            fill="#adbac1"
            d="M3.3 4.6 9 10.3l5.7-5.7 1.6 1.6L9 13.4 1.7 6.2l1.6-1.6z"
        ></path>
    </svg>
);

export const Delete = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 22">
        <path
            d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
            fill="#adbac1"
        ></path>
    </svg>
);
