import React, { useState } from "react";
import styled from "styled-components";

// Main component
const CustomizableSelect = ({ localInput, onChange, width }) => {
    const [selectedOption, setSelectedOption] = useState("");

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
        if (onChange) {
            onChange(selectedValue);
        }
    };

    return (
        <CustomSelectContainer>
            <CustomSelect
                value={selectedOption}
                onChange={handleSelectChange}
                width={width}
            >
                <option value="">Select Trainer</option>
                {localInput?.map((option) => (
                    <>
                        <option key={option?.auto_id} value={option?.id}>
                            {option?.name}
                        </option>
                    </>
                ))}
            </CustomSelect>
        </CustomSelectContainer>
    );
};

export default CustomizableSelect;

const CustomSelectContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    color: #fff;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    border: 1px solid #3b3b3b;
    margin: 10px 0px;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: "gordita_medium";
        font-weight: lighter;
        font-style: normal;
    }
`;

const CustomSelect = styled.select`
    appearance: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 20px 16px;
    width: 100%;
    color: #fff;
    background: #161619;
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg");
    background-repeat: no-repeat;
    background-position: 95% center;
`;

const CustomArrowIcon = styled.span`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;
