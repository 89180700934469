import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

function AepStudentsCard({ student, index, pagination }) {
    const payment_on = moment(student.date_added)
        .utc()
        .format("DD / MM / YYYY");

    return (
        <StudentCards>
            <ItemList>
                {pagination?.first_item <= pagination?.last_item && (
                    <Item type="slno">{pagination?.first_item + index}</Item>
                )}
                <Item type="name">
                    {student.student_details.name
                        ? student.student_details.name
                        : "--"}
                </Item>
                <Item type="phone">
                    {student.student_details.phone
                        ? student.student_details.phone
                        : "--"}
                </Item>
                <Item type="category">
                    {student.student_details.category
                        ? student.student_details.category.replace("_", " ")
                        : "--"}
                </Item>
                <Item type="payment">
                    {student.date_added ? payment_on : "--"}
                </Item>
                <Item type="amount">
                    {student.bonus_amount ? student.bonus_amount : "--"}
                </Item>
            </ItemList>
        </StudentCards>
    );
}

export default AepStudentsCard;

const ItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 20px;
    color: #fff;

    &.active {
        background: #121214;
    }
`;
const StudentCards = styled.div``;

const Item = styled.li`
    font-size: 14px;
    /* text-align: ${({ type }) => (type === "count" ? "center" : "left")}; */
    font-family: "gordita_medium";
    text-transform: capitalize;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "name"
            ? "20%"
            : type === "phone"
            ? "19%"
            : type === "category"
            ? "20%"
            : type === "payment"
            ? "17%"
            : type === "amount"
            ? "15%"
            : "15%"};
    list-style: none;
    &:last-child {
        text-align: center;
    }

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
`;
