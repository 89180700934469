import React from "react";
import styled from "styled-components";
import SchoolScientistLeftScoreBoard from "../../screens/schoolScientist/SchoolScientistLeftScoreBoard";
import SchoolScientistRightScoreBoard from "../../screens/schoolScientist/SchoolScientistRightScoreBoard";

export default function SchoolScientistScoreBoard() {
  return (
    <>
      <TotalContainer id="main">
        <Container>
          <SchoolScientistLeftScoreBoard/>
          <SchoolScientistRightScoreBoard />
        </Container>
      </TotalContainer>
    </>
  );
}
const TotalContainer = styled.div`
  overflow-x: scroll;
  /* overflow-x: hidden; */
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 1080px) {
        flex-direction: column-reverse;
    }
  
`;
