import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function BdmSidebar({ setSubMenu, isExpand, setExpand, wrapperRef }) {
    const [isTrue, setTrue] = useState(false);
    return (
        <Container
            id="menu"
            ref={wrapperRef}
            className={isExpand && "expand"}
            onMouseEnter={() => setExpand(true)}
            onMouseLeave={() => setExpand(false)}
        >
            <Button
                to={`bdm/dashboard?district=Kannur`}
                onClick={() => {
                    setSubMenu(false);
                    setTrue(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/dashboard.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/dashboard-orange.svg
                            "
                        alt="Icon"
                    />
                    <Title>Dashboard</Title>
                </Menu>
            </Button>
            <Button
                to={`bdm/bda-manager/`}
                onClick={() => {
                    setSubMenu(false);
                    setTrue(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={
                            require("../../../assets/images/bdm/user-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={
                            require("../../../assets/images/bdm/group-orange.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <Title>BDA</Title>
                </Menu>
            </Button>
            <Button
                to={`bdm/aep-manager/`}
                onClick={() => {
                    setSubMenu(false);
                    setTrue(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={
                            require("../../../assets/images/bdm/group-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={
                            require("../../../assets/images/bdm/group-orange.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <Title>AEP</Title>
                </Menu>
            </Button>
            {/* <Button
                to={`bdm/aep-target/`}
                onClick={() => {
                    setSubMenu(false);
                    setTrue(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={
                            require("../../../assets/images/bdm/traget-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={
                            require("../../../assets/images/bdm/traget-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <Title>AEP Traget</Title>
                </Menu>
            </Button> */}
            <MainButton
                to="/bdm/target/"
                onClick={() => {
                    setTrue(!isTrue);
                    setSubMenu(false);
                }}
                className="menu-item"
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={
                            require("../../../assets/images/bdm/traget-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={
                            require("../../../assets/images/bdm/traget-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <TrainingTitle className={isTrue ? "active" : ""}>
                        Aep Target
                    </TrainingTitle>
                    <ArrowContiner>
                        <ArrowImageContainer
                            className={isTrue ? "active" : ""}
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                            alt="Icon"
                        />
                        <ArrowImageContainer1
                            className={isTrue ? "active" : ""}
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow-active.svg"
                            alt="Icon"
                        />
                    </ArrowContiner>
                </Menu>
            </MainButton>
            {isTrue && (
                <Trainingdiv>
                    <Button to="/bdm/target/" className="menu-item">
                        <Menu1 className={isTrue ? "active" : ""}>
                            <MenuItemIcon
                                className={isTrue ? "active" : ""}
                                src={
                                    require("../../../assets/images/bdm/dot-white.svg")
                                        .default
                                }
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                className={isTrue ? "active" : ""}
                                src={
                                    require("../../../assets/images/bdm/dot-orange.svg")
                                        .default
                                }
                                alt="Icon"
                            />
                            <Title>Acheived</Title>
                        </Menu1>
                    </Button>
                    <Button to="/bdm/pending/" className="menu-item">
                        <Menu1 className={isTrue ? "active" : ""}>
                            <MenuItemIcon
                                className={isTrue ? "active" : ""}
                                src={
                                    require("../../../assets/images/bdm/dot-white.svg")
                                        .default
                                }
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                className={isTrue ? "active" : ""}
                                src={
                                    require("../../../assets/images/bdm/dot-orange.svg")
                                        .default
                                }
                                alt="Icon"
                            />
                            <Title>Pending</Title>
                        </Menu1>
                    </Button>
                </Trainingdiv>
            )}

            <Button
                to={`bdm/school-students/`}
                onClick={() => {
                    setSubMenu(false);
                    setTrue(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={
                            require("../../../assets/images/bdm/leed-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={
                            require("../../../assets/images/bdm/leed-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <Title>Leads</Title>
                </Menu>
            </Button>
            <Button
                to={`bdm/report/`}
                onClick={() => {
                    setSubMenu(false);
                    setTrue(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={
                            require("../../../assets/images/bdm/report-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={
                            require("../../../assets/images/bdm/report-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <Title>Report</Title>
                </Menu>
            </Button>
            <Button
                to={`bdm/settlement/`}
                onClick={() => {
                    setSubMenu(false);
                    setTrue(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={
                            require("../../../assets/images/bdm/settlement-white.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={
                            require("../../../assets/images/bdm/settlement-orange.svg")
                                .default
                        }
                        alt="Icon"
                    />
                    <Title>Settlement</Title>
                </Menu>
            </Button>
        </Container>
    );
}

export default BdmSidebar;

const Title = styled.span`
    font-family: "gordita_regular";
    color: inherit;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #f7684c;
    }
`;
const SubTitle = styled.p`
    font-family: "gordita_medium";
    font-size: 14px;
    white-space: nowrap;
    color: #fff;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #1ec569;
    }
`;
const Menu = styled.div`
    padding: 10px 21px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
`;
const Menu1 = styled.div`
    padding: 10px 21px;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
    .active {
        margin-left: 28px;
    }
`;

const Dot = styled.span`
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transform: translate(0px, -1px);
    margin-right: 12px;
    border-radius: 12px;
`;
const MenuItemIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    &.active {
        width: 9px;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const MenuItemIcon1 = styled.img`
    display: none;
    width: 16px;
    margin-right: 1.125rem;
    &.active {
        width: 9px;
    }
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const ArrowImageContainer = styled.img`
    display: block;
    width: 16px;
    transform: rotate(-90deg);
    transition: all 0.4s ease;
    &.active {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const ArrowImageContainer1 = styled.img`
    display: none;
    width: 16px;
    /* margin-right: 1.125rem; */
    transform: rotate(0deg);
    &.active {
        display: block;
    }
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const DownIcon = styled.img``;
const DownIcon1 = styled.img``;
const Trainingdiv = styled.div``;

const Button = styled(NavLink)`
    width: -webkit-fill-available;
    height: 46px;
    display: flex;
    color: #fff;
    transition: all 0.4s ease;
    &.active {
        border-left: 5px solid #fe673a;
        color: #1ec569 !important;
        background: #161616;
        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }

        ${Title} {
            color: #f7684c;
        }
        ${ArrowImageContainer} {
            display: none;
        }
        ${ArrowImageContainer1} {
            display: block;
        }
    }
`;

const Container = styled.aside`
    &.expand {
        width: 218px;
        transition: all 0.4s ease;
        ${Title} {
            display: block;
        }
        ${SubTitle} {
            display: block;
        }
        ${Menu} {
        }
        ${MenuItemIcon} {
            margin-right: 1.125rem;
        }
        ${MenuItemIcon1} {
            margin-right: 1.125rem;
        }
        ${Button} {
        }
    }
`;
const MainButton = styled(NavLink)`
    color: #fff;
    cursor: pointer;
    &.active {
        color: #f7684c;
    }
`;
const TrainingTitle = styled.span`
    color: #fff;
    cursor: pointer;
    &.active {
        color: #f7684c;
    }
`;
const ArrowContiner = styled.div`
    margin-left: 30px;
`;
