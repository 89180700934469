import React, { useState, useRef, useEffect, useContext, lazy } from "react";
import styled from "styled-components";
import RmStudentCard from "../../includes/operations-head/RmStudentCard";
import { activityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useSearchParams } from "react-router-dom";
import SectionLoader from "../../helpers/SectionLoader";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

export default function SrhDashboard() {
    const [isDetailsLoading, setDetailsLoading] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const [isActivityLoading, setActivityLoading] = useState(false);
    const [activityList, setActivityList] = useState([]);
    const [statusUpdated, setStatusUpdated] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [goalSettingData, SetGoalSettingData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const currentParams = Object.fromEntries([...searchParams]);
    const [errorMessege, setErrorMessege] = useState("");
    const [PDFdata, setPDFdata] = useState([]);
    const [isDownloadLoader, setDownloadLoader] = useState(false);
    const [reloadApi, setReloadApi] = useState(false);

    // rmStudentCard state
    const [rmStudents, setRmStudenrs] = useState([]);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const { student_sat_id } = useParams();

    useEffect(() => {
        const setInitialSearch = () => {
            const page = searchParams.get("page");
            setCurrentPage(page ? page : 1);
        };
        setInitialSearch();
    }, [searchParams]);

    function handlePageClick({ selected: selectedPage }) {
        setSearchParams({ page: selectedPage + 1 });
    }

    //---------------student details----------------------------------------------
    useEffect(() => {
        activityConfig
            .get("followups/srm/followups/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {},
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setPDFdata(data);
                    setDownloadLoader(false);
                } else {
                    setDownloadLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setDownloadLoader(false);
            });
    }, []);
    useEffect(() => {
        const fetchStudentDetails = () => {
            setDetailsLoading(true);
            activityConfig
                .get("followups/srm/followups/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        page: currentPage,
                    },
                })
                .then((response) => {
                    let { StatusCode, data, pagination_data } = response.data;
                    if (StatusCode === 6000) {
                        setStudentDetails(data);
                        setPagination(pagination_data);
                        setDetailsLoading(false);
                    } else {
                        setDetailsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setDetailsLoading(false);
                });
        };
        currentPage && fetchStudentDetails();
    }, [statusUpdated, currentPage, reloadApi]);

    //----------------------------------------------------------------------------
    //-----------------------activity List-----------------------------

    const fetchCallStatus = () => {
        setActivityLoading(true);
        activityConfig
            .get("followups/srm/activity-actions/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setActivityList(data);
                    setActivityLoading(false);
                } else {
                    setActivityLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setActivityLoading(false);
            });
    };

    //--------------------------------------------------Download PDF ------------------
    // const handleDownload = () => {
    // 	const input = document.getElementById("pdf-content");
    // 	html2canvas(input).then((canvas) => {
    // 		const imgData = canvas.toDataURL("image/png");
    // 		const pdf = new jsPDF();
    // 		pdf.addImage(imgData, "PNG", 0, 0);
    // 		pdf.save("download.pdf");
    // 	});
    // };
    const generatePDF = () => {
        setDownloadLoader(true);

        const columnWidths = [30, 50, 40, 10, 40, 40, 60, 60, 60];
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);
        const title = "Follow Up Report";

        const headers = [
            [
                "No",
                "Name",
                "Category",
                "",
                "Phone",
                "Free Trial",
                "Last Activity Date",
                "Call Status",
                "Reason",
            ],
        ];
        const body = PDFdata.map((datum, index) => [
            pagination.first_item + index,
            datum.student_name.toLowerCase(),
            datum.user_data.category,
            datum.user_data.country_code,
            datum.student_phone,
            datum.user_data.free_trail,
            datum.student_activity,
            datum.followup_status,
            datum.followup_reason,
        ]);
        const content = {
            startY: 50,
            head: headers,
            body: body,
            columnWidths: columnWidths,
        };
        if (!isDownloadLoader) {
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("report.pdf");
        }
        setDownloadLoader(false);
    };

    return (
        <MainContainer id="main">
            <Heading>Dashboard</Heading>
            <Top>
                <TopLeft></TopLeft>
                <TopRight>
                    <DownloadButton
                        className={isDownloadLoader && "active"}
                        onClick={generatePDF}
                    >
                        {isDownloadLoader ? <ButtonLoader /> : "Download"}
                    </DownloadButton>
                </TopRight>
            </Top>
            <TableCover>
                <TableHeading>
                    <Items type="first">No</Items>
                    <Items type="name">Name</Items>
                    <Items type="category">Category</Items>
                    <Items type="phone">Phone</Items>
                    <Items type="freetrial">Free Trial</Items>
                    <Items type="activity">Last Activity Date</Items>
                    <Items type="status">Call Status</Items>
                    <Items type="reason">Reason</Items>
                    <Items type="action">Action</Items>
                </TableHeading>
                {isDetailsLoading ? (
                    <SectionLoader />
                ) : (
                    studentDetails.map((student, index) => (
                        <RmStudentCard
                            key={index}
                            student={student}
                            activityList={activityList}
                            setStatusUpdated={setStatusUpdated}
                            index={index}
                            pagination={pagination}
                            errorMessege={errorMessege}
                            fetchCallStatus={fetchCallStatus}
                            isActivityLoading={isActivityLoading}
                            setReload={setReloadApi}
                        />
                    ))
                )}
            </TableCover>
            {pagination &&
                pagination?.total_pages > 1 &&
                studentDetails.length > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            Showing students {pagination?.first_item} -{" "}
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"< Prev"}
                            nextLabel={"Next >"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
        </MainContainer>
    );
}

const MainContainer = styled.div`
    color: #fff;
`;
const TableCover = styled.div`
    padding: 20px 20px 0 20px;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Heading = styled.h3`
    font-family: "gordita_medium";
    font-size: 22px;
    margin-bottom: 10px;
`;
const Top = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const TopLeft = styled.div``;
const TopRight = styled.div``;
const DownloadButton = styled.div`
    text-transform: capitalize;
    padding: 10px 30px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: #fe673a;
    color: #ffff;
    border-radius: 8px;
    margin-right: 20px;
    height: 40px;
    width: 130px;
    &.status {
        width: 15%;
    }
    &.active {
        min-width: 130px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: not-allowed;
    }
`;
const TableHeading = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    min-width: 1100px;
`;
const Items = styled.li`
    font-size: 14px;

    width: ${({ type }) =>
        type === "first"
            ? "5%"
            : type === "phone"
                ? "12%"
                : type === "freetrial"
                    ? "8%"
                    : type === "activity"
                        ? "12%"
                        : type === "status"
                            ? "10%"
                            : type === "category"
                                ? "10%"
                                : type === "name"
                                    ? "19%"
                                    : type === "reason"
                                        ? "13%"
                                        : type === "action"
                                            ? "10%"
                                            : ""};
    :last-child {
        text-align: center;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
