import React from 'react'
import styled from 'styled-components'

function AttendedDetails() {
  return (
    <>
        <Container>
            <Cover>
                <Item type="slno" >Sl No</Item>
                <Item >Name</Item>
                <Item type="slno" >Class</Item>
                <Item type="slno" >Laptop Availability</Item>
                <Item type="dist" >Admission Code</Item>
                <Item type="dist" >Current level</Item>
                <Item type="slno">Time</Item>
                <Item type="slno" >Exam Score</Item>
                {/* <Item type="dist" >Eligibility</Item> */}
            </Cover>
        </Container>
    </>
  )
}
const Container = styled.div`
    color: rgb(255,255,255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    min-width: 1180px;
`;
const Cover = styled.ul`
    display: flex;
    border-bottom: 1px solid #26272a;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    font-size: 16px;
    text-align: left;
    color: rgb(255,255,255);
`;
const Item = styled.li`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 14px;
    width: ${({ type }) =>
        type === "slno" ? "8%" : type === "dist" ? "11%" : "15%"};
    @media all and (max-width: 1280px) {
		font-size: 13px;
	}
`;

export default AttendedDetails