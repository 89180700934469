import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const IntroducerTechSchoolingStudents = lazy(() =>
    import("../../../screens/introducer/IntroducerTechSchoolingStudents")
);

export default function IntroducerRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/techschooling/" />} />
            <Route
                path="/techschooling/"
                element={<IntroducerTechSchoolingStudents />}
            />
        </Routes>
    );
}
