import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { learnConfig } from "../../../axiosConfig";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "../jobdesk/NoData";
import Moment from "react-moment";
import { Context } from "../../contexts/Store";
import BlogPublishModal from "./modal/BlogPublishModal";
import SteypHelmet from "../../helpers/SteypHelmet";
import ReadDetailsModal from "./modal/ReadDetailsModal";
import TechUpdateEditingScreen from "../../screens/content-manager/TechUpdateEditingScreen";

function TechUpdates() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);
    const currentParams = Object.fromEntries([...searchParams]);
    const [articles, setArticles] = useState([]);
    const [pagination, setPagination] = useState({});
    const [readPagination, setReadPagination] = useState({});
    const [selectedArticle, setSelectedArticle] = useState({});
    const [isPageLoading, setPageLoading] = useState(false);
    const [isModal, setModal] = useState(false);
    const [isRead, setRead] = useState(false);
    const [isWork, setWork] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [selectedData, setSelectData] = useState("");
    const [readData, setReadData] = useState([]);
    const [reactionCount, setReactionCount] = useState({});
    const [readLoader, setReadLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [articlePK, setArticlePK] = useState("");

    const wrapperRef = useRef(null);
    const {
        isView,
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("blog_status") &&
            (filterParams.blog_status = searchParams.get("blog_status"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        searchParams.get("author") &&
            (filterParams.author = searchParams.get("author"));
        searchParams.get("published_date") &&
            (filterParams.published_date = searchParams.get("published_date"));
        searchParams.get("from_date") &&
            (filterParams.from_date = searchParams.get("from_date"));
        searchParams.get("to_date") &&
            (filterParams.to_date = searchParams.get("to_date"));
        searchParams.get("category") &&
            (filterParams.category = searchParams.get("category"));
        return filterParams;
    };

    const articleFetch = () => {
        setPageLoading(true);
        const params = getSearchParams();
        learnConfig
            .get("/tech-updates/all-articles/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                const { data, StatusCode, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setArticles(data);
                    setPageLoading(false);
                    setPagination(pagination_data);
                } else {
                    setArticles([]);
                    setPageLoading(false);
                }
            })
            .catch((err) => {
                setPageLoading(false);
            });
    };
    const readListApi = () => {
        setReadLoader(true);
        learnConfig
            .get(`/tech-updates/get-read-details/${articlePK}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    page: pageNumber,
                },
            })
            .then((response) => {
                const { data, StatusCode, pagination_data, reaction_count } =
                    response.data;
                if (StatusCode === 6000) {
                    setReadData(data);
                    setReactionCount(reaction_count);
                    setReadPagination(pagination_data);
                    setReadLoader(false);
                } else {
                    setReadLoader(false);
                }
            })
            .catch((err) => {
                setReadLoader(false);
            });
    };

    const deleteUpadates = (id) => {
        learnConfig
            .post(
                `/tech-updates/delete-article/${id}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    articleFetch();
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // articleFetch();
    useEffect(() => {
        articleFetch();
    }, [searchParams]);

    useEffect(() => {
        if (articlePK) {
            readListApi();
        }
    }, [pageNumber, articlePK]);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    function truncateString(str = "", maxLength) {
        if (str?.length <= maxLength) {
            return str;
        }

        return str?.slice(0, maxLength) + "...";
    }

    return (
        <>
            <BlogPublishModal
                isModal={isModal}
                setModal={setModal}
                selectedArticle={selectedArticle}
                articleFetch={articleFetch}
            />

            <ReadDetailsModal
                isRead={isRead}
                setRead={setRead}
                readData={readData}
                readLoader={readLoader}
                setReadData={setReadData}
                reactionCount={reactionCount}
                setReactionCount={setReactionCount}
                pagination={readPagination}
                setPageNumber={setPageNumber}
                readListApi={readListApi}
            />
            <Container>
                <SteypHelmet title="Content-Manager" />
                <ContentContaioner>
                    <MainContainer>
                        <Top>
                            <Text type="article">Article</Text>
                            <Text type="author">Author</Text>
                            <Text type="category">Category</Text>
                            <Text type="status">Status</Text>
                            <Text type="reads">Reads</Text>
                            <Text type="actions">Actions</Text>
                        </Top>
                        <DataContainer>
                            {isPageLoading ? (
                                <SectionLoader />
                            ) : articles?.length > 0 ? (
                                <>
                                    {articles.map((data) => (
                                        <Bottom key={data.id}>
                                            <ArticleSection
                                                onClick={() => {
                                                    if (
                                                        data.blog_status ===
                                                        "published"
                                                    ) {
                                                        dispatch({
                                                            type: "UPDATE_EDITOR_VIEW",
                                                            isView: true,
                                                        });
                                                    } else {
                                                        dispatch({
                                                            type: "UPDATE_EDITOR_VIEW",
                                                            isView: false,
                                                        });
                                                    }
                                                }}
                                                to={`/content-manager/update/${data.id}/`}
                                            >
                                                <ArticleImg>
                                                    <img
                                                        src={
                                                            data.featured_image
                                                        }
                                                    />
                                                </ArticleImg>
                                                <ArtText>
                                                    {truncateString(
                                                        data.title
                                                            ? data.title
                                                            : "",
                                                        78
                                                    )}
                                                </ArtText>
                                            </ArticleSection>
                                            <Section type="author">
                                                <AuthorImg
                                                    style={{
                                                        backgroundImage: `url(${data.author.image})`,
                                                    }}
                                                >
                                                </AuthorImg>
                                                <AuthorContainer>
                                                    <AuthorName>
                                                        {data.author.name}
                                                    </AuthorName>
                                                    <AuthorDiscription>
                                                        {data?.author?.profile_type.replace(
                                                            "_",
                                                            " "
                                                        )}
                                                    </AuthorDiscription>
                                                </AuthorContainer>
                                            </Section>
                                            <Section type="category">
                                                {data.category ? (
                                                    <CategoryName>
                                                        {data.category}
                                                    </CategoryName>
                                                ) : (
                                                    <p>--</p>
                                                )}
                                            </Section>
                                            {data.blog_status ? (
                                                <Section type="status">
                                                    <StatusUpdate
                                                        status={
                                                            data.blog_status
                                                        }
                                                    >
                                                        {data.blog_status ===
                                                        "published"
                                                            ? "Published"
                                                            : data.blog_status.replace(
                                                                  "_",
                                                                  ""
                                                              )}
                                                    </StatusUpdate>
                                                    <StatusDate>
                                                        <Moment
                                                            format="D MMM YYYY"
                                                            withTitle
                                                        >
                                                            {data.blog_status ===
                                                            "published"
                                                                ? data.date_published
                                                                : data.date_added}
                                                        </Moment>
                                                    </StatusDate>
                                                </Section>
                                            ) : (
                                                <Section type="status">
                                                    <Draft>Draft</Draft>
                                                </Section>
                                            )}
                                            <Section
                                                type="reads"
                                                onClick={() => {
                                                    setRead(true);
                                                    // readListApi(data.id);
                                                    setArticlePK(data.id);
                                                }}
                                            >
                                                <ReadImgContainer>
                                                    <ReadImg
                                                        src={
                                                            require("../../../assets/images/eye.svg")
                                                                .default
                                                        }
                                                        alt="eye"
                                                    />
                                                </ReadImgContainer>
                                                <ReadsData>
                                                    {data.reads}
                                                </ReadsData>
                                            </Section>

                                            <Section type="actions">
                                                <ActionsButton
                                                    onClick={() => {
                                                        setSelectedArticle(
                                                            data
                                                        );
                                                        setModal(true);
                                                    }}
                                                    type={
                                                        data.blog_status ===
                                                        "published"
                                                            ? "published"
                                                            : data.blog_status ===
                                                              "in_review"
                                                            ? "in_review"
                                                            : data.blog_status ===
                                                              "re_work"
                                                            ? "re_work"
                                                            : "draft"
                                                    }
                                                >
                                                    {data.blog_status ===
                                                    "published"
                                                        ? "Unpublish"
                                                        : data.blog_status ===
                                                          "draft"
                                                        ? "In Review"
                                                        : data.blog_status ===
                                                          "in_review"
                                                        ? "Publish"
                                                        : data.blog_status ===
                                                          "re_work"
                                                        ? "In Review"
                                                        : ""}
                                                </ActionsButton>

                                                <ActionEditButton
                                                    onClick={() => {
                                                        setEdit(false);
                                                        setWork(!isWork);
                                                        setSelectData(data.id);
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            require("../../../assets/images/threeDots.svg")
                                                                .default
                                                        }
                                                    />
                                                </ActionEditButton>
                                                <ReworkBox
                                                    style={{
                                                        display:
                                                            isWork &&
                                                            selectedData ==
                                                                data.id
                                                                ? "block"
                                                                : "none",
                                                    }}
                                                    isWork={isWork}
                                                    setWork={setWork}
                                                    wrapperRef={wrapperRef}
                                                >
                                                    <ReworkBoxDiv
                                                        to={`/content-manager/update/${data.id}/`}
                                                        onClick={() => {
                                                            if (
                                                                data.blog_status ===
                                                                "published"
                                                            ) {
                                                                dispatch({
                                                                    type: "UPDATE_EDITOR_VIEW",
                                                                    isView: true,
                                                                });
                                                            } else {
                                                                dispatch({
                                                                    type: "UPDATE_EDITOR_VIEW",
                                                                    isView: false,
                                                                });
                                                            }
                                                        }}
                                                    >

                                                        <ReworkText>
                                                            Edit
                                                        </ReworkText>

                                                        <ReworkIcon>
                                                            <img
                                                                src={
                                                                    require("../../../assets/images/Edit.svg")
                                                                        .default
                                                                }
                                                            />
                                                        </ReworkIcon>
                                                    </ReworkBoxDiv>
                                                    <ReworkBoxDiv
                                                        to={`/content-manager/rework/${data.id}/`}
                                                    >
                                                        <ReworkText>
                                                            Rework
                                                        </ReworkText>
                                                        <ReworkIcon>
                                                            <img
                                                                src={
                                                                    require("../../../assets/images/Setting.svg")
                                                                        .default
                                                                }
                                                            />
                                                        </ReworkIcon>
                                                    </ReworkBoxDiv>
                                                    <ReworkBoxDiv
                                                        // to={`/content-manager/rework/${data.id}/`}
                                                        onClick={() => {
                                                            deleteUpadates(
                                                                data.id
                                                            );
                                                        }}
                                                    >
                                                        <ReworkText>
                                                            Delete
                                                        </ReworkText>
                                                        <ReworkIcon>
                                                            <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-04-2024/iconly.svg" />
                                                        </ReworkIcon>
                                                    </ReworkBoxDiv>
                                                </ReworkBox>
                                            </Section>
                                        </Bottom>
                                    ))}
                                </>
                            ) : (
                                <NoData />
                            )}
                        </DataContainer>
                    </MainContainer>
                </ContentContaioner>
                {pagination?.total_pages > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
            </Container>
        </>
    );
}

export default TechUpdates;
const DataContainer = styled.div`
    min-height: 61vh;
`;
const Container = styled.section`
    padding-top: 22px;
`;
const MainContainer = styled.div`
    min-width: 1280px;
`;

const ContentContaioner = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    border-radius: 8px 8px 0 0;
    background-color: #1b1c1f;
`;

const Text = styled.h3`
    color: #fff;
    font-size: 16px;

    width: ${({ type }) =>
        type === "article"
            ? "30%"
            : type === "author"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "status"
            ? "8%"
            : type === "reads"
            ? "5%"
            : type === "actions" && "14%"};
    text-align: ${({ type }) => (type === "actions" ? "right" : "")};
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    background-color: #1b1c1f;
    &:hover {
        background-color: #161619;
    }
    position: relative;
`;
const ArticleSection = styled(Link)`
    width: 30%;
    display: flex;
`;
const Section = styled.div`
    align-items: center;
    width: ${({ type }) =>
        type === "article"
            ? "30%"
            : type === "author"
            ? "21%"
            : type === "category"
            ? "15%"
            : type === "status"
            ? "8%"
            : type === "reads"
            ? "5%"
            : type === "actions" && "14%"};
    display: ${({ type }) =>
        type === "article" ||
        type === "actions" ||
        type === "author" ||
        type === "reads"
            ? "flex"
            : "block"};
    justify-content: ${({ type }) => (type === "actions" ? "flex-end" : "")};
    align-items: center;
    cursor: ${({ type }) => (type === "reads" ? "pointer" : "")};
`;

const ArticleImg = styled.div`
    width: 30%;
    img {
        display: block;
        width: 100%;
    }
`;

const ArtText = styled.p`
    color: #d2caca;
    font-size: 16px;
    margin-left: 10px;
    width: 82%;
    @media all and (max-width: 1380px) {
        font-size: 14px;
    }
`;

const AuthorImg = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: #fff;

    /* img {
        width: 100%;
        display: block;
    } */
`;

const AuthorContainer = styled.div`
    margin-left: 10px;
    width: 85%;
`;

const AuthorName = styled.h4`
    color: #fff;
    font-size: 16px;

    @media all and (max-width: 1380px) {
        font-size: 14px;
    }
`;

const AuthorDiscription = styled.h4`
    text-transform: capitalize;
    color: #fe673a;
    font-size: 14px;

    @media all and (max-width: 1380px) {
        font-size: 12px;
    }
`;

const CategoryName = styled.div`
    border-radius: 8px;
    background: #2c2c2c;
    padding: 12px 12px;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    @media all and (max-width: 1380px) {
        font-size: 13px;
        padding: 16px 9px;
    }
`;

const StatusUpdate = styled.h4`
    text-transform: capitalize;
    color: ${({ status }) =>
        status == "draft"
            ? "#0c85f6"
            : status == "published"
            ? "#0FA76F"
            : status == "rejected" || status == "re_work"
            ? "#FF3636"
            : "#fff"};
    font-size: 14px;
    margin-bottom: 4px;
`;

const StatusDate = styled.small`
    color: #525252;
    font-size: 14px;

    @media all and (max-width: 1380px) {
        font-size: 12px;
    }
`;

const ReadsData = styled.h4`
    color: #fff;
    font-size: 14px;
    height: 16px;
    &.empty {
        text-align: center;
        width: 100%;
    }
    @media all and (max-width: 1380px) {
        font-size: 12px;
        height: 18px;
    }
`;

const ReadImgContainer = styled.div`
    margin-right: 4px;
    width: 32%;
`;

const ReadImg = styled.img`
    display: block;
    width: 100%;
`;

const ActionsButton = styled.button`
    border-radius: 6px;
    border: 2px solid #fe673a;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    width: 65%;
    height: 44px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
    background: ${({ type }) => (type === "published" ? "#161619" : "#FE673A")};
`;
const ActionEditButton = styled.div`
    border-radius: 6px;
    border: 2px solid #2c2c2c;
    background: #2c2c2c;
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4%;

    img {
        display: block;
        width: 100%;
    }
`;
const ReworkBox = styled.div`
    border-radius: 8px;
    border: 3px solid #3b3b3b;
    background: #1b1c1f;
    /* box-shadow: 0px 44px 56px 0px rgba(0, 0, 0, 0.25); */
    width: 246px;
    // height: 88px;
    position: absolute;
    right: 23px;
    bottom: -115px;
    z-index: 100;
`;
const ReworkBoxDiv = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50%;
    padding: 10px;
    gap: 8px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 6px;
    background: #18191c;
    &:hover {
        background-color: #161619;
    }
`;
const ReworkText = styled.p`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;
const ReworkIcon = styled.div`
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;

const Draft = styled.p`
    color: #0c85f6;
    font-size: 14px;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
