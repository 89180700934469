import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import SectionLoader from "../../../helpers/SectionLoader";
import TrainerDeatils from "../../../screens/meet/trainer/TrainerDeatils";
import MeetMain from "../../../screens/meet/meets/MeetMain";
import MeetInner from "../../../screens/meet/meets/MeetInner";

const MeetRouter = () => {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Conatiner id="main">
                <Routes>
                    <Route path="list/" element={<MeetMain />} />
                    <Route path="trainer/" element={<TrainerDeatils />} />
                    <Route path="view/:meet_id/*" element={<MeetInner />} />
                </Routes>
            </Conatiner>
        </Suspense>
    );
};

const Conatiner = styled.div``;
export default MeetRouter;
