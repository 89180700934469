import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import DaybydayRouter from "./DaybydayRouter";
import SyllabusMainTopics from "../../../screens/syllabus/SyllabusMainTopic";
//components
const SyllabusTopics = lazy(() =>
    import("../../../screens/syllabus/SyllabusTopics")
);
const SyllabusSkills = lazy(() =>
    import("../../../screens/syllabus/SyllabusSkills")
);
const SyllabusCompletedStudents = lazy(() =>
    import("../../../screens/syllabus/SyllabusCompletedStudents")
);
const Syllabus = lazy(() => import("../../../screens/syllabus/Syllabus"));
const SyllabusClass = lazy(() =>
    import("../../../screens/syllabus/SyllabusClass")
);
const SyllabusMainTopic = lazy(() =>
    import("../../../screens/syllabus/SyllabusMainTopic")
);





function SyllabusRouter() {
    return (
        <Routes>
            <Route path="/" element={<Syllabus />} />
            <Route path=":prof_id/skills/" element={<SyllabusSkills />} />
            <Route path=":skill_id/topics/" element={<SyllabusTopics />} />
            <Route path="day-by-day/*" element={<DaybydayRouter />} />
            <Route path=":topics_id/classes/" element={<SyllabusClass />} />
            <Route path="form_id/topic/" element={<SyllabusMainTopics />} />
            <Route
                path="profession/:prof_name/:prof_id/"
                element={<SyllabusCompletedStudents />}
            />
            <Route
                path="skill/:prof_name/:skill_id/"
                element={<SyllabusCompletedStudents />}
            />
            <Route
                path="lesson/:prof_name/:lesson_id/"
                element={<SyllabusCompletedStudents />}
            />
            <Route
                path="form/:prof_name/:form_id/"
                element={<SyllabusCompletedStudents />}
            />
            <Route
                path="topic/:prof_name/:_id/"
                element={<SyllabusCompletedStudents />}
            />

        </Routes>
    );
}

export default SyllabusRouter;
