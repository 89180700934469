import { useState } from 'react'
import styled from 'styled-components'
import { Link,NavLink, useSearchParams } from "react-router-dom";

function SchoolCategory() {
    const[searchWord,setSearchWord] = useState("")
    const [isSearchBar,setSearchBar] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const ExamStatus = searchParams.get("exam_status") ? searchParams.get("exam_status") : ""
  return (
    <>
        <Container>
            <TotalContainer className="active">
                <FullContainer>
                    <Cover>
                        <Item to="/school-scientist/students/"> All</Item>
                        <Item to="/school-scientist/students/up">UP</Item>
                        <Item to="/school-scientist/students/hs/">HS</Item>
                        <Item to="/school-scientist/students/hss/">HSS</Item>
                    </Cover>
                    <RightContainer className={isSearchBar ? "search-active" : ""}>
                        <input
                            type="text"
                            placeholder="Search Here"
                            onChange={(e) => setSearchWord(e.target.value)}
                        ></input>
                        <ImageContainer
                            to={`?q=${searchWord}&exam_status=${ExamStatus}`}
                            onClick={() =>{
                                setSearchBar(false)
                            }}
                            >
                            <img
                                src={
                                    require("../../../assets/images/school-scientist/search.svg")
                                        .default
                                }
                                alt="Search"
                            />
                        </ImageContainer>
                    </RightContainer>
                    <SearchContainer
                        onClick={() => {
                            setSearchBar(true)
                        }}
                    >
                        <img 
                            src={
                                require("../../../assets/images/school-scientist/search.svg")
                                    .default
                            }
                            alt="Search"
                        />
                    </SearchContainer>
                </FullContainer>
            </TotalContainer>
        </Container>
    </>
  )
}
const Container = styled.div`
   
`;
const TotalContainer = styled.div`
    .active{
        border-bottom: 3px solid #f7684c;
        border-radius: 2px;
    }
`;
const FullContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const Cover = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 19%;
    
`;
const Item = styled(NavLink)`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 18px;
    cursor: pointer;
    @media all and (max-width: 1280px) {
		font-size: 16px;
	}
    @media all and (max-width: 980px) {
		font-size: 15px;
	}
    @media all and (max-width: 768px) {
		margin-right: 20px;
        &:last-child{
            margin-right: 0;
        }
	}
    @media all and (max-width: 360px) {
		font-size: 14px;
	}
`;
const RightContainer = styled.div`
    background: rgb(27, 28, 32);
    display: flex;
    color: rgb(255, 255, 255);
    align-items: center;
    padding: 5px;
    cursor: pointer;
    input {
        color: #fff;
        width: 90%;
        height: 40px;
        padding: 10px;
        background: rgb(22, 22, 25);
        border-radius: 5px;
        width: 200px;
        margin-right: 10px;
        @media all and (max-width: 768px) {
		    height: 33px;
	    }
    }
    @media all and (max-width: 480px) {
        display: none;
        position: absolute;
        right: 10px;
        &.search-active{
            display: flex;
        }
	}
   
`;
const ImageContainer = styled(Link)`
    background: rgb(22, 22, 25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    padding: 10px 0px;
    cursor: pointer;
    img{
        display: block;
        width: 20px;
    }
    @media all and (max-width: 768px) {
		padding: 6px 0px;
	}
   
`;
const SearchContainer = styled.div`
    display: none;
    img{
        display: block;
        width: 20px;
    }
     @media all and (max-width: 480px) {
        display: block;
        
	}
`;

export default SchoolCategory