import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Varif from "../../../../assets/images/check.svg";
import $ from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function RejectedModal({
    isModal,
    setModal,
    setApprovemodal,
    setReason,
    setRejectSelect,
    setSelected,
    updateStatus,
    reason,
    RejectedLoading,
}) {
    const [dropdown, setDropdown] = useState(false);
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);
    return (
        <BackContainer>
            {isModal ? (
                <Overlay
                    onClick={() => setModal(false)}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <RejectSeclectOption
                            onClick={() => {
                                setApprovemodal(false);
                                setReason("Inappropriate picture");
                                setSelected("");
                                setRejectSelect(false);
                            }}
                        >
                            <Top>
                                <Head>
                                    <Main>
                                        <VarifyImage>
                                            <Varify
                                                onClick={() =>
                                                    setRejectSelect(false)
                                                }
                                                src={Varif}
                                                alt="varify"
                                            />
                                        </VarifyImage>
                                        <MainTitle className="gm">
                                            Reason
                                        </MainTitle>
                                    </Main>
                                    <Close>
                                        <CloseIcon
                                            onClick={() =>
                                                setRejectSelect(false)
                                            }
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                            alt="Close Icon"
                                        />
                                    </Close>
                                </Head>
                            </Top>
                        </RejectSeclectOption>

                        {/* <SectionButton>
							<ButtonTag
								onClick={() => {
									if (reason) {
										updateStatus();
										setReason("Inappropriate picture");
									}
								}}
							>
								{RejectedLoading ? (
									<ButtonLoader />
								) : (
									"Continue"
								)}
							</ButtonTag>
						</SectionButton> */}

                        <SelectCampustittle>Select a Reason</SelectCampustittle>
                        <Block>
                            <Dropdownlist
                                onClick={() => {
                                    setDropdown(!dropdown);
                                }}
                            >
                                <p>{reason}</p>
                            </Dropdownlist>

                            <Localbodydiv className={dropdown ? "show" : ""}>
                                <div>
                                    <li
                                        className={
                                            reason === "Inappropriate picture"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            setDropdown(false);
                                            setReason("Inappropriate picture");
                                        }}
                                    >
                                        Inappropriate picture
                                    </li>
                                    <li
                                        className={
                                            reason ===
                                            "This image doesn't show your face"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            setDropdown(false);
                                            setReason(
                                                "This image doesn't show your face"
                                            );
                                        }}
                                    >
                                        This image doesn't show your face
                                    </li>
                                    <li
                                        className={
                                            reason === "Low resolution image"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            setDropdown(false);
                                            setReason("Low resolution image");
                                        }}
                                    >
                                        Low resolution image
                                    </li>{" "}
                                </div>
                            </Localbodydiv>
                        </Block>
                        <MainButton>
                            <SubButton>
                                <RejectButton
                                    onClick={() => {
                                        setApprovemodal(false);
                                        setReason("Inappropriate picture");
                                        setSelected("");
                                        setRejectSelect(false);
                                    }}
                                >
                                    Cancel
                                </RejectButton>
                                <AcceptButton
                                    onClick={() => {
                                        updateStatus();
                                    }}
                                >
                                    {RejectedLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Accept"
                                    )}
                                </AcceptButton>
                            </SubButton>
                        </MainButton>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default RejectedModal;

const Varify = styled.img`
    margin-right: 10px;
`;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    max-height: 100vh;
    min-height: 270px;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 105;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    box-shadow: 0px 3px 56px #00000048;
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 600px;
    }
    @media all and (max-width: 700px) {
        width: 560px;
    }
    @media all and (max-width: 640px) {
        width: 430px;
    }
    @media all and (max-width: 600px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;

const BackContainer = styled.div``;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Top = styled.div`
    margin-bottom: 15px;
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #26272a;
    align-items: center;
    padding-bottom: 15px;
    @media all and (max-width: 480px) {
        padding-bottom: 10px;
    }
`;
const Main = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const VarifyImage = styled.div`
    img {
    }
`;
const Block = styled.div`
    position: relative;
`;
const MainButton = styled.div``;
const SubButton = styled.div`
    display: flex;
    padding: 24px 0px 0px 32px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 12px;
`;
const RejectButton = styled.button`
    width: 140px;
    height: 50px;
    cursor: pointer;
    color: #ff3030;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
`;
const AcceptButton = styled.button`
    width: 140px;
    height: 50px;
    background: #fe673a;
    color: #fff;
    cursor: pointer;

    border-radius: 5px;
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Close = styled.span`
    cursor: pointer;
    width: 18px;
    @media all and (max-width: 640px) {
        width: 15px;
    }
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const RejectSeclectOption = styled.div``;
const MainDiv = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const MainDivBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;
const SubDiv = styled.h2`
    margin-bottom: 10px;
    color: #ffff;
    font-size: 18px;
    line-height: 28px;
    font-family: "gordita_medium";
`;
const CancelContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const RejectSelect = styled.select`
    height: 40px;
    border: 1px solid #64748b;
    border-radius: 6px;
    cursor: pointer;
    padding: 8px, 16px, 8px, 16px;
    gap: 10px;
    color: white;
    font-family: "gordita_medium";
    font-size: 12px;
`;
const Option = styled.option`
    font-size: 12px;
`;
const SectionButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const ButtonTag = styled.button`
    width: 103px;
    height: 40px;
    background-color: #ce582f;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-left: 10px;
    padding: 8px, 16px, 8px, 16px;
    gap: 10px;
    cursor: pointer;
    font-family: "gordita_medium";
`;
const SelectCampustittle = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #ababab;

    margin-bottom: 5px;
`;

const Dropdownlist = styled.div`
    background-color: #161619 !important;
    border-radius: 5px !important;
    width: 100%;
    color: #fff;
    padding: 5px 5px 5px 10px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
        font-size: 14px;
        color: #fff;
    }
`;
const Arrow = styled.img`
    width: 12px;
    margin-right: 10px;
    position: absolute;
    right: 0;
`;
const Localbodydiv = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    transition: all 1s ease-in;
    /* top: 9vh; */
    &.show {
        transition: all 1s ease-out;
    }
    div {
        background-color: #1b1c1f;
        border: 1px solid #26272a;
        color: #fff;
        font-size: 14px;
        width: 100%;
        padding: 10px;
        & :first-child {
            margin-top: 0;
        }
    }
    li {
        font-size: 14px;
        color: #7f7f7f;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        background-color: #161619;
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 8px;
        &.active {
            color: #fff;
            border: 1px solid #fff;
        }
    }
`;
