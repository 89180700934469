import React, { useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Context } from "../../../contexts/Store";

const ChiefRoute = ({ children }) => {
    const { state } = useContext(Context);
    const role = state.user_data.role;

    const [searchParams] = useSearchParams();
    const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";
    return role.includes("students_relations_officer") ? (
        children
    ) : role.includes("followup_executive") ? (
        <Navigate to="/executive/dashboard/" />
    ) : role.includes("followup_manager") ? (
        <Navigate to="/manager/dashboard/" />
    ) : role.includes("registration_manager") ? (
        <Navigate to="/registration-manager/techschooling/" />
    ) : role.includes("business_development_associate") ? (
        <Navigate to="/bda-manager/dashboard/" />
    ) : role.includes("school_scientist") ? (
        <Navigate to="/school-scientist/dashboard/" />
    ) : role.includes("sat_head") ? (
        <Navigate to="/sat-examiner-head/dashboard/" />
    ) : role.includes("sat_examiner") ? (
        <Navigate to="/examiner/dashboard/" />
    ) : role.includes("career_advisor") ? (
        <Navigate to="/career-advisor/dashboard/" />
    ) : role.includes("sales_executive") ? (
        <Navigate to="/sales-executive/tech-schooling/students/" />
    ) : role.includes("introducer") ? (
        <Navigate to="/introducer/techschooling/" />
    ) : role.includes("sat_agent") ? (
        <Navigate to="/sat-agent/dashboard/" />
    ) : role.includes("sat_authorized_education_partner") ? (
        <Navigate to="/aep/dashboard" />
    ) : role.includes("business_development_manager") ? (
        <Navigate to="/bdm/dashboard/" />
    ) : role.includes("subscription_manager") ? (
        <Navigate to="/subscription-manager/dashboard/" />
    ) : role.includes("support_engineer") ? (
        <Navigate to="/support" />
    ) : role.includes("content_manager") ? (
        <Navigate to="/content-manager/dashboard/" />
    ) : role.includes("content_editor") ? (
        <Navigate to="/content-editor/dashboard/" />
    ) : role.includes("admission_manager") ? (
        <Navigate to="/admission-manager/students/" />
    ) : role.includes("verification_manager") ? (
        <Navigate to="/verifications/" />
    ) : role.includes("project_manager") ? (
        <Navigate to="/projects/current/" />
    ) : (
        <h1>404</h1>
    );
};

export default ChiefRoute;
