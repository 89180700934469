import React from "react";

//packages
import styled from "styled-components";

function ExaminationDetails({ aepDetails }) {
    return (
        <>
            <Heading>Examination</Heading>
            <MainContainer>
                <Container>
                    <Cover>
                        <Card>
                            <Title>Applicants</Title>
                            <Count>
                                {aepDetails.exam_details?.total_appicants}
                            </Count>
                        </Card>
                        <Card>
                            <Title>Exam Conducted</Title>
                            <Count>
                                {aepDetails.exam_details?.total_exam_taken}
                            </Count>
                        </Card>
                        <Card>
                            <Title>Pending</Title>
                            <Count>
                                {aepDetails.exam_details?.pending_students}
                            </Count>
                        </Card>
                        <Card>
                            <Title>Eligible students</Title>
                            <Count>
                                {aepDetails.exam_details?.eligible_students}
                            </Count>
                        </Card>
                    </Cover>
                </Container>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.div`
    overflow-x: scroll;
    margin-bottom: 30px;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const Container = styled.div`
    min-width: 1180px;
`;
const Heading = styled.p`
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: gordita_medium;
    margin-bottom: 15px;
    text-transform: capitalize;
`;
const Cover = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
`;
const Card = styled.li`
    border-radius: 5px;
    color: rgb(255, 255, 255);
    background-color: rgb(27, 28, 31);
    border: 1px solid #26272a;
    font-family: gordita_regular;
    font-size: 14px;
    padding: 20px;
    text-align: center;
`;
const Title = styled.p`
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 14px;
    font-family: gordita_regular;
    margin-bottom: 5px;
`;
const Count = styled.p`
    font-size: 24px;
    color: rgb(254, 103, 58);
    font-family: "gordita_medium";
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
export default ExaminationDetails;
