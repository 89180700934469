import React, { useState, useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

export default function DownloadFilter({
    examStatus,
    isDownloadDropdown,
    setDownloadDropdown,
    setExamStatus,
    examCode,
    wrapperRef,
    setDropdown,
    isDropdown,
}) {
    const [isButtonLoading, setButtonDownloading] = useState(false);

    /////////////////////////////////download applicant students sheet/////////////
    ////////////////////////////////////////////////

    const download = function () {
        setButtonDownloading(false);
        let url = `https://activities.steyp.com/api/v1/followups/download-applicant-student-sheet/?exam_status=${examStatus}&campus_code=${examCode}`;
        let link = document.createElement("a");
        link.download = "excel sheet download";
        link.href = url;
        link.click();
        setDownloadDropdown(false);
    };

    return (
        <>
            <TopFilter>
                <CoverSet ref={wrapperRef}>
                    <Filter
                        onClick={() => (
                            setDownloadDropdown(!isDownloadDropdown),
                            setDropdown(false)
                        )}
                    >
                        <FIlterImage>
                            <img
                                src={
                                    require("../../../assets/images/downld.svg")
                                        .default
                                }
                                alt="Image"
                            />
                        </FIlterImage>
                    </Filter>

                    <Popup className={isDownloadDropdown ? "dropdown" : ""}>
                        <Small>Exam Status</Small>
                        <HrLine className="Exam Status"></HrLine>

                        <SelectOption
                            className={
                                examStatus === "eligible" ? "active" : ""
                            }
                            onClick={() => setExamStatus("eligible")}
                        >
                            <Span>
                                {examStatus === "eligible" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Eligible</Text>
                        </SelectOption>

                        <SelectOption
                            className={
                                examStatus === "not_eligible" ? "active" : ""
                            }
                            onClick={() => setExamStatus("not_eligible")}
                        >
                            <Span>
                                {examStatus === "not_eligible" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Not Eligible</Text>
                        </SelectOption>

                        <SelectOption
                            className={
                                examStatus === "not-attended" ? "active" : ""
                            }
                            onClick={() => setExamStatus("not-attended")}
                        >
                            <Span>
                                {examStatus === "not-attended" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Not Attended</Text>
                        </SelectOption>

                        <DownloadButton
                            onClick={() => (
                                setButtonDownloading(true), download()
                            )}
                        >
                            {isButtonLoading ? <ButtonLoader /> : "Download"}
                        </DownloadButton>
                    </Popup>
                </CoverSet>
            </TopFilter>
        </>
    );
}

const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
`;
const CoverSet = styled.div``;
const Filter = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 6px 12px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
`;

const FIlterImage = styled.div`
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    width: 250px;
    padding: 15px 10px;
    position: absolute;
    right: 17px;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 1;
    border: 1px solid #28292d;
    &.dropdown {
        opacity: 1;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: 500px;
        transition: 0.5s ease;
        border-radius: 5px;
    }
`;

const SelectOption = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    position: relative;
    align-items: center;
    // margin-bottom: 10px;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Text = styled.div`
    font-size: 12px;
`;
const Small = styled.span`
    color: #fff;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    &.students {
        margin-bottom: 10px;
    }
`;

const DownloadButton = styled.div`
    margin-right: 15px;
    color: #fff;
    background: #fe673a;
    border: 1px solid #fe673a;
    height: 40px;
    width: 150px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
