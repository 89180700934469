import React from "react";
import styled from "styled-components";
import DoughnutChart from "./DoughnutChart";
import { Link } from "react-router-dom";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import SrmLineChart from "../../includes/operations-head/SrmLineChart";

function ChartsContainer({
    isStudentLoading,
    studentCount,
    isGraphDataLoading,
    graphData,
    attendArray,
    pendingArray,
    notAttendedArray,
    switchedOffArray,
    busyArray,
}) {
    const items = [
        {
            id: 1,
            count: studentCount.attended_followups,
            title: "Attended",
            name: "Attended",
            campus_link: ``,
        },
        {
            id: 2,
            count: studentCount.pending_followups,
            title: "Pending",
            name: "Pending",
            campus_link: ``,
        },
        {
            id: 3,
            count: studentCount.other_followups,
            title: "Others",
            name: "Others",
            campus_link: ``,
        },
    ];

    return (
        <MainContainer>
            <Cover>
                <LineChartContainer>
                    <LineCard>
                        <SrmLineChart
                            isGraphDataLoading={isGraphDataLoading}
                            graphData={graphData}
                            attendArray={attendArray}
                            pendingArray={pendingArray}
                            notAttendedArray={notAttendedArray}
                            switchedOffArray={switchedOffArray}
                            busyArray={busyArray}
                        />
                    </LineCard>
                </LineChartContainer>
                <BarChartContainer>
                    <Card>
                        <TopSection>
                            <Title>Assigned Students</Title>
                            {isStudentLoading ? (
                                <ButtonLoader />
                            ) : (
                                <TotalCount to="">
                                    {studentCount.total_followups?studentCount.total_followups:"0"}
                                </TotalCount>
                            )}
                        </TopSection>
                        <BottomSection>
                            <LeftSection>
                                {items.map((item, index) => (
                                    <Section key={item.id}>
                                        <Circle type={item.name}></Circle>
                                        <CountSection>
                                            {isStudentLoading ? (
                                                <ButtonLoader />
                                            ) : (
                                                <Count>{item.count?item.count:"0"}</Count>
                                            )}
                                            <Para>{item.title?item.title:'--'}</Para>
                                        </CountSection>
                                    </Section>
                                ))}
                            </LeftSection>
                            <RightSection>
                                <DoughnutChart studentCount={studentCount} />
                            </RightSection>
                        </BottomSection>
                    </Card>
                </BarChartContainer>
                <Container></Container>
            </Cover>
        </MainContainer>
    );
}

export default ChartsContainer;

const MainContainer = styled.div`
    overflow-x: scroll;
    margin-top: 30px;
    /* min-width: 1200px; */
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Cover = styled.div`
    min-width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media all and (max-width: 480px) {
        flex-direction: column;
        min-width: 400px;
    }
`;
const LineChartContainer = styled.div`
    width: 55%;
    @media all and (max-width: 480px) {
        width: 100%;
        margin-bottom: 30px;
    }
`;
const BarChartContainer = styled.div`
    width: 30%;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Title = styled.p`
    color: #fff;
    font-size: 16px;
`;
const TotalCount = styled(Link)`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const BottomSection = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const LeftSection = styled.div`
    width: 50%;
`;
const Heading = styled.h6`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 18px;
`;
const LineCard = styled.div`
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    background-color: #1b1c1f;
    /* padding: 10px 15px 10px 10px; */
`;
const Card = styled.div`
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    background-color: #1b1c1f;
    // margin-top: 15px;
    padding: 20px 20px 20px 25px;
    @media all and (max-width: 480px) {
    }
`;
const TopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #4e5053;
`;
const Section = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    :last-child {
        margin-bottom: 0;
    }
`;
const Circle = styled.span`
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    background: ${(props) =>
        props.type === "Attended"
            ? "#6a7ce7"
            : props.type === "Pending"
            ? "#ef8637"
            : "#404255"};
`;
const CountSection = styled.div``;
const Count = styled.p`
    color: #fff;
    margin-bottom: 10px;
    font-family: "gordita_medium";
    font-size: 14px;
`;
const Para = styled.p`
    color: #4e5053;
    font-size: 12px;
`;
const RightSection = styled.div`
    width: 50%;
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
    @media all and (max-width: 480px) {
        margin-bottom: 15px;
    }
`;
const DateCard = styled.div`
    color: #fff;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    background-color: #1b1c1f;
    padding: 5px 10px;
    display: flex;
    align-items: baseline;
`;
const Date = styled.p`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 12px;
`;
const Icon = styled.img`
    margin-left: 10px;
`;
const Container = styled.div`
    width: 12%;
`;
