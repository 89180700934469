import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import AttendeesList from './AttendeesList';


function Attendees() {
    const [searchWord, setSearchWord ] = useState("")
    const [isSearchBar,setSearchBar] = useState(false)
  return (
        <Container id='main'>
            <MainContainer >
                <TopContainer>
                    <Heading>Schools</Heading>
                </TopContainer>
                <RightContainer className={isSearchBar ? "search-active" : ""}>
                    <input
                        type="text"
                        placeholder="Search Here"
                        onChange={(e) => setSearchWord(e.target.value)}
                    ></input>
                    <ImageContainer
                        to={`?q=${searchWord}`}
                        onClick={() =>{
                            setSearchBar(false)
                        }}
                        >
                        <img
                            src={
                                require("../../../assets/images/school-scientist/search.svg")
                                    .default
                            }
                            alt="Search"
                        />
                    </ImageContainer>
                </RightContainer>
                <SearchContainer
                        onClick={() => {
                            setSearchBar(true)
                        }}
                    >
                        <img 
                            src={
                                require("../../../assets/images/school-scientist/search.svg")
                                    .default
                            }
                            alt="Search"
                        />
                    </SearchContainer>
            </MainContainer>  
            <AttendeesList/>
        </Container>
    )
}
const Container = styled.div`
   
`;
const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const TopContainer = styled.div`
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
    @media all and (max-width: 1280px) {
		font-size: 16px;
	}
`;
const RightContainer = styled.div`
    background: rgb(27, 28, 32);
    display: flex;
    color: rgb(255, 255, 255);
    align-items: center;
    padding: 5px;
    cursor: pointer;
    input {
        color: #fff;
        width: 90%;
        height: 40px;
        padding: 10px;
        background: rgb(22, 22, 25);
        border-radius: 5px;
        width: 200px;
        margin-right: 10px;
        @media all and (max-width: 768px) {
		    height: 33px;
	    }    
    }
    @media all and (max-width: 480px) {
        display: none;
        position: absolute;
        right: 10px;
        &.search-active{
            display: flex;
        }
	}

`;
const ImageContainer = styled(Link)`
   background: rgb(22, 22, 25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    padding: 10px 0px;
    cursor: pointer;
    img{
        display: block;
        width: 20px;
    }
    @media all and (max-width: 768px) {
		padding: 6px 0px;
	}
`;
const SearchContainer = styled.div`
    display: none;
    img{
        display: block;
        width: 20px;
    }
     @media all and (max-width: 480px) {
        display: block;
        
	}
`;

export default Attendees