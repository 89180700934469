import React, { useContext, useEffect, useRef, useState } from "react";
import { imageUploadConfig } from "../../../axiosConfig";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import Code from "@editorjs/code";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Delimiter from "@editorjs/delimiter";
import Quote from "@editorjs/quote";
import InlineCode from "@editorjs/inline-code";
import Marker from "@editorjs/marker";
import { Context } from "../../contexts/Store";
import styled from "styled-components";
import SteypPlayer from "../../custom-plugins/SteypPlayer/SteypPlayer";

function TechEditor({
    article_pk,
    editorContent,
    setEditorContent,
    setDraft,
    isDraft,
}) {
    const ejInstance = useRef();
    const editorInstanceRef = useRef(null);
    const {
        state: {
            isView,
            user_data: { access_token },
        },
    } = useContext(Context);
    const storedIsView = JSON.parse(localStorage.getItem("isView"));

    const initEditor = () => {
        const editor = new EditorJS({
            holder: "editorjs",
            data: editorContent,
            readOnly: storedIsView !== null ? storedIsView : isView,
            onReady: () => {
                ejInstance.current = editor;
            },
            autofocus: true,
            onChange: async () => {
                let content = await editor.save(); // Change 'editor.saver.save()' to 'editor.save()'
                setEditorContent(content);
                if (isDraft === false) {
                    setDraft(true);
                }
            },
            tools: {
                header: Header,
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: `${imageUploadConfig}${article_pk}/`,
                            byUrl: "api/v1/tech-updates/fetch-image",
                        },
                        additionalRequestHeaders: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    },
                },
                code: Code,
                table: Table,
                quote: Quote,
                embed: Embed,
                list: {
                    class: List,
                    inlineToolbar: true, // or ['bold', 'link']
                },
                delimiter: Delimiter,
                inlineCode: InlineCode,
                marker: Marker,
                videoPlayer: {
                    class: SteypPlayer,
                    config: {
                        article_pk: article_pk,
                        access_token: access_token,
                    },
                },
            },
        });
        editorInstanceRef.current = editor;
    };

    useEffect(() => {
        if (!editorInstanceRef.current && editorContent) {
            initEditor();
        }
    }, [editorContent, editorInstanceRef.current]);

    return (
        <>
            <Container id="editorjs"></Container>
        </>
    );
}

export default TechEditor;

const Container = styled.div`
    padding: 0px 80px;

    background-color: #fff;
    @media all and (max-width: 1280px) {
        padding: 0px 40px;
    }
`;
