import React, { useContext, useState } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function ProjectPauseModal({
    modal,
    setModal,
    modalType,
    pauseProject,
    description,
    setDescription,
    buttonLoader,
    apiError,
    setApiError,
}) {
    const handleModal = () => {
        setModal(false);
        setDescription("");
        setApiError("");
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        setDescription(newValue);
    };

    const maxCharacters = 300;
    const charactersLeft = maxCharacters - description.length;
    const isScreenOverLimit = charactersLeft <= 0;

    return (
        <MainContainer>
            {modal ? (
                <Overlay
                    onClick={() => setModal(false)}
                    style={{ display: modal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <ContainerDiv style={{ transform: modal && "scale(1,1)" }}>
                <Modal>
                    {modalType === "pause" ? (
                        <Container>
                            <Top>
                                <Head>
                                    <Headtitle>
                                        Pause evaluation 1 and add last update
                                        <SmallText>
                                            Write where you stopped your
                                            testing.{" "}
                                        </SmallText>
                                    </Headtitle>
                                    <Closeicon onClick={handleModal}>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                            alt="Icon"
                                        />
                                    </Closeicon>
                                </Head>
                            </Top>
                            <Body>
                                <ScreenContainer>
                                    <Label>Description</Label>
                                    <ScreenInput
                                        maxLength={maxCharacters}
                                        value={description}
                                        onChange={(e) =>
                                            handleChange(e, "screen")
                                        }
                                    />
                                    <BottomText isOverLimit={isScreenOverLimit}>
                                        Max 300 characters.{" "}
                                        <Small isOverLimit={isScreenOverLimit}>
                                            {maxCharacters - charactersLeft}/
                                        </Small>
                                        {maxCharacters} used.
                                    </BottomText>
                                </ScreenContainer>
                                {apiError && (
                                    <ErrorSpan>{apiError?.message}</ErrorSpan>
                                )}
                            </Body>

                            <ButtonDiv>
                                <Button
                                    className="close"
                                    onClick={() => {
                                        handleModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button onClick={() => pauseProject("paused")}>
                                    {buttonLoader ? <ButtonLoader /> : "Done"}
                                </Button>
                            </ButtonDiv>
                        </Container>
                    ) : modalType === "success" ? (
                        <Container>
                            <Top className="top">
                                <Head>
                                    <Headtitle>
                                        Are you sure this evaluation is
                                        completed?
                                        <br />
                                        <SmallText>
                                            This action cannot be undone.{" "}
                                        </SmallText>
                                    </Headtitle>
                                </Head>
                                {apiError && (
                                    <ErrorSpan>{apiError.message}</ErrorSpan>
                                )}
                            </Top>
                            <ButtonDiv className="save">
                                <Button
                                    className="close save_btn"
                                    onClick={() => {
                                        handleModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => pauseProject("completed")}
                                    className="save_btn"
                                >
                                    {buttonLoader ? <ButtonLoader /> : " Yes"}
                                </Button>
                            </ButtonDiv>
                        </Container>
                    ) : null}
                </Modal>
            </ContainerDiv>
        </MainContainer>
    );
}

export default ProjectPauseModal;
const MainContainer = styled.div``;
const ContainerDiv = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 640px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    border-bottom: 2px solid #26272a;
    margin-bottom: 15px;
    &.top {
        border-bottom: none;
        margin-bottom: 25px;
    }
`;
const Head = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Headtitle = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
`;
const SmallText = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-family: "gordita_regular";
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;

const ScreenContainer = styled.div``;
const Label = styled.div`
    color: #94a3b8;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 6px;
`;
const Body = styled.div`
    border-bottom: 2px solid #26272a;
    padding-bottom: 15px;
    margin-bottom: 15px;
`;

const ScreenInput = styled.textarea`
    border-radius: 8px;
    border: 1px solid #3d3d3d;
    background: #161619;
    min-height: 120px;
    padding: 8px;
    width: 100%;
    color: #fff;
    resize: none;
    font-size: 16px;
    font-family: "gordita_regular";
    &::-webkit-scrollbar {
        display: none;
    }
`;

const BottomText = styled.small`
    display: inline-block;
    color: ${({ isOverLimit }) => (isOverLimit ? "red" : "#fff")};
    font-size: 12px;
    font-family: "gordita_regular";
`;

const Small = styled.small`
    display: inline-block;
    font-size: 12px;
    font-family: "gordita_regular";

    color: ${({ isOverLimit }) => (isOverLimit ? "red" : "#1ea027")};
`;

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    &.save {
        justify-content: flex-end;
    }
`;
const Button = styled.button`
    border-radius: 6px;
    background: #fe673a;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 18px;
    display: flex;
    height: 58px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    font-family: "gordita_medium";
    cursor: pointer;

    &.close {
        border-radius: 6px;
        border: 1px solid #e2e8f0;
        background: #fff;
        color: #0f172a;
    }
    &.save {
        width: 40px;
    }
    &.save_btn {
        width: 20%;
        height: 48px;
        padding: 8px 12px;
        font-size: 14px;
    }
`;
const ErrorSpan = styled.span`
    color: #f44336;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
`;
