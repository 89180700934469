import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const TechSchoolManager = lazy(() =>
    import("../../../screens/registration-manager/TechSchoolManager")
);
const TechDegreeManager = lazy(() =>
    import("../../../screens/registration-manager/TechDegreeManager")
);

const TechGradManager = lazy(() =>
    import("../../../screens/registration-manager/TechGradManager")
);
const TechSchoolStatusmanager = lazy(() =>
    import("../../../screens/registration-manager/TechSchoolStatusmanager")
);
const TechDegreeStatusManager = lazy(() =>
    import("../../../screens/registration-manager/TechDegreeStatusManager")
);
const TechGradStatusManager = lazy(() =>
    import("../../../screens/registration-manager/TechGradStatusManager")
);
function MyStatusRouter() {
    return (
        <Routes>
            <Route
                path="techschooling/"
                element={<TechSchoolStatusmanager />}
            />
            <Route path="techdegree/" element={<TechDegreeStatusManager />} />
            <Route path="techgrad/" element={<TechGradStatusManager />} />
        </Routes>
    );
}

export default MyStatusRouter;
