import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../fiebase";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function SupportEngineerDetails() {
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);

    const [engineerName, setEngineerName] = useState("");
    const [engineerUserName, setEngineerUserName] = useState("");
    const [engineerImage, setEngineerImage] = useState("");
    const [height, setHeight] = useState("auto");
    const [isLoading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState({
        name: false,
        username: false,
        image: false,
    });

    useEffect(() => {
        setEngineerName(user_data?.chatname);
        setEngineerUserName(user_data?.username);
        setEngineerImage(user_data?.image);
    }, []);

    const setInputValue = (e, setState) => {
        setState(e.target.value);
    };

    const renderTextTrim = (text) => {
        if (text.length > 30) {
            return text.substring(0, 30 - 3) + "...";
        }
        return text;
    };

    const autoResize = () => {
        const textarea = document.getElementById("textAreaInput");
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    };

    const renderHeight = (e) => {
        setHeight(`${e.target.scrollHeight}px`);
    };

    const updateEngineerDetails = async () => {
        setLoading(true);
        try {
            await updateDoc(doc(db, "users", user_data?.uid), {
                name: engineerName,
                username: engineerUserName,
                image: engineerImage,
            });
            setLoading(false);
            getEngineerDetails();
        } catch (error) {
            console.error("Error updating document: ", error);
            setLoading(false);
        }
    };

    const getEngineerDetails = async () => {
        try {
            const docRef = doc(db, "users", user_data?.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                dispatch({
                    type: "UPDATE_USER_DATA",
                    user_data: {
                        chatname: docSnap.data().name,
                        username: docSnap.data().username,
                        image: docSnap.data().image,
                    },
                });
            }
        } catch (error) {
            console.error("Error retrieving chat rooms:", error);
        }
    };

    return (
        <MainContainer>
            <SubContainer>
                <Top>
                    <ProfileContainer>
                        <ArrowIcon
                            onClick={() => {
                                dispatch({
                                    type: "UPDATE_SUPPORT_EDIT_PROFILE",
                                    isEditProfile: false,
                                });
                            }}
                        >
                            <img
                                src={
                                    require("../../../assets/images/subscription_manager/left_arrow.svg")
                                        .default
                                }
                                alt="Close"
                            />
                        </ArrowIcon>
                        <Profile>Profile</Profile>
                    </ProfileContainer>
                </Top>
                <Middle>
                    <ProfileImg>
                        {user_data?.image ? (
                            <img src={user_data?.image} alt="ProfileImage" />
                        ) : (
                            <img
                                src={
                                    require("../../../assets/images/chat/user.svg")
                                        .default
                                }
                                alt="ProfileImage"
                            />
                        )}
                    </ProfileImg>
                </Middle>
                <Bottom>
                    <InputContainer>
                        <Label>Your name</Label>
                        <SubImg>
                            {isEdit?.name ? (
                                <Input
                                    rows="1"
                                    placeholder="Enter your name"
                                    value={engineerName}
                                    onChange={(e) =>
                                        setInputValue(e, setEngineerName)
                                    }
                                />
                            ) : user_data?.chatname ? (
                                <span>
                                    {renderTextTrim(user_data?.chatname)}
                                </span>
                            ) : (
                                <span style={{ color: "red" }}>
                                    no name found
                                </span>
                            )}
                            <IconContainer
                                onClick={() => {
                                    setIsEdit({
                                        ...isEdit,
                                        name: !isEdit?.name,
                                    });
                                }}
                            >
                                <img
                                    src={
                                        isEdit?.name
                                            ? require("../../../assets/images/subscription_manager/blacktick.svg")
                                                  .default
                                            : require("../../../assets/images/subscription_manager/blackpen.svg")
                                                  .default
                                    }
                                    alt="Edit"
                                />
                            </IconContainer>
                        </SubImg>
                    </InputContainer>
                    <InputContainer>
                        <Label>User name</Label>
                        <SubImg>
                            {isEdit?.username ? (
                                <Input
                                    rows="1"
                                    placeholder="Enter your username"
                                    value={engineerUserName}
                                    onChange={(e) =>
                                        setInputValue(e, setEngineerUserName)
                                    }
                                />
                            ) : user_data?.username ? (
                                <span>
                                    {renderTextTrim(user_data?.username)}
                                </span>
                            ) : (
                                <span style={{ color: "red" }}>
                                    no username found
                                </span>
                            )}
                            <IconContainer
                                onClick={() => {
                                    setIsEdit({
                                        ...isEdit,
                                        username: !isEdit?.username,
                                    });
                                }}
                            >
                                <img
                                    src={
                                        isEdit?.username
                                            ? require("../../../assets/images/subscription_manager/blacktick.svg")
                                                  .default
                                            : require("../../../assets/images/subscription_manager/blackpen.svg")
                                                  .default
                                    }
                                    alt="Edit"
                                />
                            </IconContainer>
                        </SubImg>
                    </InputContainer>
                    <InputContainer>
                        <Label>Edit image</Label>
                        <SubImg>
                            {isEdit?.image ? (
                                <Input
                                    rows="1"
                                    style={{ height }}
                                    onInput={autoResize}
                                    placeholder="Enter the image URL"
                                    value={engineerImage}
                                    onFocus={renderHeight}
                                    onChange={(e) =>
                                        setInputValue(e, setEngineerImage)
                                    }
                                />
                            ) : user_data?.image ? (
                                <span>{renderTextTrim(user_data?.image)}</span>
                            ) : (
                                <span style={{ color: "red" }}>
                                    no image found
                                </span>
                            )}
                            <IconContainer
                                onClick={() => {
                                    setIsEdit({
                                        ...isEdit,
                                        image: !isEdit?.image,
                                    });
                                }}
                            >
                                <img
                                    src={
                                        isEdit?.image
                                            ? require("../../../assets/images/subscription_manager/blacktick.svg")
                                                  .default
                                            : require("../../../assets/images/subscription_manager/blackpen.svg")
                                                  .default
                                    }
                                    alt="Edit"
                                />
                            </IconContainer>
                        </SubImg>
                    </InputContainer>
                </Bottom>
            </SubContainer>
            <Button onClick={updateEngineerDetails}>
                {isLoading ? <ButtonLoader /> : "Save Changes"}
            </Button>
        </MainContainer>
    );
}

export default SupportEngineerDetails;

const MainContainer = styled.div`
    background-color: #1b1c1f;
    width: 100%;
    padding: 32px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const SubContainer = styled.div``;
const Top = styled.div``;
const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
`;
const ArrowIcon = styled.div`
    width: 22px;
    margin: 0 5px 2px 0;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;
const Profile = styled.span`
    color: var(--colors-badge-bg-01, #fff4f3);
    text-align: center;
    font-size: 16px;
    font-family: gordita_regular;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const Middle = styled.div`
    display: flex;
    justify-content: center;
    padding: 50px 0px;
`;
const ProfileImg = styled.div`
    border-radius: 50%;
    width: 155px;
    height: 155px;
    overflow: hidden;
    display: flex;
    img {
        width: 100%;
        display: block;
        object-fit: cover;
    }
`;
const Bottom = styled.div``;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

const Label = styled.label`
    margin-bottom: 0.2rem;
    color: var(--steyp-landingpage-paragraph, #707070);
    font-size: 12px;
`;

const Input = styled.textarea`
    color: #fff !important;
    font-size: 14px;
    padding: 4px 0;
    font-family: gordita_regular;
    width: 100%;
    resize: none;
`;

const Button = styled.div`
    border-radius: 6px;
    border: 1px solid #fe673a;
    background: #fe673a;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 8px 14px;
    cursor: pointer;

    & div {
        height: 20px !important;
    }
`;
const IconContainer = styled.div`
    width: 25px;
    cursor: pointer;
    /* position: absolute;
    right: 0; */
    img {
        display: block;
        width: 100%;
    }
`;
const SubImg = styled.div`
    display: flex;
    align-items: center;

    & span {
        color: #fff;
        font-size: 14px;
        padding: 4px 0;
        font-family: gordita_regular;
        width: 100%;
        cursor: default;
    }
`;
