import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { Context } from "../../../contexts/Store";

function ExamStartModal({ examModal, setExamModal }) {
    const [active, setActive] = useState("");
    // function useOutsideClick(ref) {
    //     useEffect(() => {
    //         function handleClickOutside(event) {
    //             if (ref.current && !ref.current.contains(event.target)) {
    //                 setStartModal(false);
    //             }
    //         }
    //         document.addEventListener("mousedown", handleClickOutside);
    //         return () => {
    //             document.removeEventListener("mousedown", handleClickOutside);
    //         };
    //     }, [ref]);
    // }

    // useOutsideClick(wrapperRef);

    const wrapperRef = useRef(null);
    const colorRef = useRef(null);
    const useOutSideClick = (ref) => {
        useEffect(() => {
            document.addEventListener("mousedown", handleOutSideClick);
            return () => {
                document.removeEventListener("mousedown", handleOutSideClick);
            };
        }, [ref]);
        const handleOutSideClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setExamModal(false);
            }
        };
    };
    useOutSideClick(wrapperRef);
    const addActive = (e) => {
        Array.from(colorRef.current.children).map((list) => {
            if (e.target === list) {
                list.classList.add("active");
            }
            if (e.target !== list) {
                list.classList.remove("active");
            }
        });
    };

    let Mark = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
        <MainContainer>
            {examModal ? (
                <Overlay
                    style={{ display: examModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: examModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        {/* <Top>
                            <Head>
                                <NameSection>
                                    {student.student_photo && (
                                        <ProPic>
                                            <Span>
                                                <img
                                                    src={student.student_photo}
                                                />
                                            </Span>
                                        </ProPic>
                                    )}
                                    <NameSec>
                                        <Name>
                                            {student.student_name?.toLowerCase()}
                                        </Name>
                                        <Phone>
                                            {student.student_phone_code}{" "}
                                            {student.student_phone}
                                        </Phone>
                                    </NameSec>
                                </NameSection>
                            </Head>
                        </Top>
                        <Middle>
                            <SubTitle>
                                Are you sure do you want to start exam?
                            </SubTitle>
                        </Middle>
                        <Bottom>
                            <Button
                                type="cancel"
                                onClick={() => setStartModal(false)}
                            >
                                Not Now
                            </Button>

                            <Button
                                onClick={() => {
                                    startStudentExam();
                                    studentUpdate(student);
                                }}
                            >
                                {isExamLoading ? (
                                    <ButtonLoader />
                                ) : (
                                    "Start Exam"
                                )}
                            </Button>
                        </Bottom> */}
                        <Top>
                            <LeftContainer>
                                <img
                                    src={
                                        require("../../../../assets/images/calendar.svg")
                                            .default
                                    }
                                    alt="DP"
                                />
                                <div>
                                    <h5>Daniel cooper</h5>
                                    <span>+91 8943545647</span>
                                </div>
                            </LeftContainer>
                            <Close onClick={() => setExamModal(false)}>
                                <img
                                    src={require("../../../../assets/images/close.png")}
                                    alt="Close"
                                />
                            </Close>
                        </Top>
                        <Middle>
                            <Feilds>
                                <label htmlFor="strength">strength</label>
                                <textarea
                                    placeholder="Type here...."
                                    name="strength"
                                ></textarea>
                            </Feilds>
                            <Feilds>
                                <label htmlFor="improvement">
                                    Areas of improvement
                                </label>
                                <textarea
                                    placeholder="Type here...."
                                    name="improvement"
                                ></textarea>
                            </Feilds>
                            <Feilds>
                                <label htmlFor="remarks">Remarks</label>
                                <textarea
                                    placeholder="Type here...."
                                    name="remarks"
                                ></textarea>
                            </Feilds>
                        </Middle>
                        <Bottom>
                            <h6>Score</h6>
                            <Items ref={colorRef}>
                                {Mark.map((item, i) => (
                                    <Item onClick={addActive} key={i}>
                                        {item}
                                    </Item>
                                ))}
                            </Items>
                            <UpdateButton>Update</UpdateButton>
                        </Bottom>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default ExamStartModal;
const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 754px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 35px 30px;
    width: 100%;
    height: 100%;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: 3px solid #3b3b3b;
    padding-bottom: 40px;
`;
const LeftContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    h5 {
        color: #cfcfcf;
        font-size: 20px;
    }
    span {
        color: #fe673a;
        font-size: 18px;
    }
    img {
        width: 56px;
        height: 56px;
        object-fit: contain;
        border-radius: 50px;
    }
`;
const Close = styled.div`
    cursor: pointer;
`;

const Middle = styled.div``;
const Feilds = styled.div`
    label {
        color: #cfcfcf;
        margin-bottom: 15px;
        font-size: 16px;
        display: inline-block;
    }
    textarea {
        height: 100px;
        width: 100%;
        color: #fff;
        resize: none;
        ::-webkit-scrollbar {
            display: none;
        }
        border: 1px solid #333333;
        padding: 14px;
        font-size: 18px;
        background: #161619;
    }
    margin-bottom: 23px;
`;
const Bottom = styled.div`
    position: relative;
    h6 {
        font-size: 16px;
        color: #fff;
        margin-bottom: 15px;
    }
`;
const Items = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 100px;
`;
const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    color: #fff;
    border: 1px solid #333333;
    background: #161619;
    border-radius: 5px;
    &.active {
        border: 1px solid #fe673a;
    }
`;
const UpdateButton = styled.div`
    color: #fff;
    background: #fe673a;
    display: inline-block;
    width: 258px;
    height: 64px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
`;

// const Top = styled.div`
//     margin-bottom: 20px;
//     border-bottom: 1px solid #26272a;
// `;
// const Head = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     margin-bottom: 20px;
// `;

// const SubTitle = styled.p`
//     font-size: 18px;
//     color: #fff;
// `;
// const Middle = styled.div``;
// const Bottom = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     margin-top: 25px;
// `;
// const Button = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 120px;
//     height: 40px;
//     background: ${({ type }) => (type === "cancel" ? "#161619" : "#fe673a")};
//     margin-right: ${({ type }) => (type === "cancel" ? "20px" : "0px")};
//     cursor: pointer;
//     color: #fff;
//     border: 1px solid #fe673afa;
//     border-radius: 5px;
//     font-size: 14px;
//     font-family: "gordita_medium";
// `;
// const NameSection = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
// `;
// const ProPic = styled.div`
//     margin-right: 20px;
//     width: 50px;
//     height: 50px;
//     display: flex;
//     justify-content: center !important;
//     align-items: center;
//     font-family: "gordita_bold";
//     border-radius: 50%;
//     min-width: 50px;
// `;
// const Span = styled.span`
//     width: 40px;
//     height: 40px;

//     img {
//         width: 100%;
//         display: block;
//     }
// `;
// const NameSec = styled.div`
//     display: flex;
//     align-items: flex-start;
//     justify-content: center;
//     flex-direction: column;
// `;
// const Name = styled.span`
//     font-size: 18px;
//     margin-bottom: 5px;
//     text-transform: capitalize;
// `;
// const Phone = styled.span`
//     font-size: 16px;
//     color: #fe673a;
// `;
