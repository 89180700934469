import React, { useContext, useEffect, useState } from "react";
//packages
import styled from "styled-components";
//Components
import BankDetails from "../../includes/community/agentsinglepage/BankDetails";
import BasicDetails from "../../includes/community/agentsinglepage/BasicDetails";
import TransactionDetails from "../../includes/community/agentsinglepage/TransactionDetails";
import ExaminationDetails from "../../includes/community/agentsinglepage/ExaminationDetails";
import PartnerDetails from "../../includes/community/agentsinglepage/PartnerDetails";
import { accountsConfig } from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import { Context } from "../../contexts/Store";

function AgentSinglePage() {
    const [aepDetails, setAepDetails] = useState({});
    const [commisionDetails, setCommisionDetails] = useState({});
    const { agent_id } = useParams();
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    useEffect(() => {
        accountsConfig
            .get(
                `api/v1/users/authorized-education-partner-details/${agent_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then(function (response) {
                setAepDetails(response.data);
            });
        accountsConfig
            .get(
                `api/v1/users/aep-admission-and-commision-details/${agent_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then(function (res) {
                setCommisionDetails(res.data);
            });
    }, []);
    return (
        <>
            <Heading>Basic Details</Heading>
            <BasicDetails aepDetails={aepDetails} />
            <BankDetails aepDetails={aepDetails} />
            <TransactionDetails commisionDetails={commisionDetails} />
            <ExaminationDetails aepDetails={aepDetails} />
            {/* <PartnerDetails aepDetails={aepDetails} /> */}
        </>
    );
}
const Heading = styled.p`
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: "gordita_medium";
    margin-bottom: 15px;
    text-transform: capitalize;
`;
export default AgentSinglePage;
