import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function VerificationManagerSideBar({
    setSubMenu,
    setSyllabusMenu,
    setSelected,
    isExpand,
    setExpand,
    wrapperRef,
    setSubCommunity,
    setSubJobDeskMenu,
    isRequest,
    setSubRequestMenu,
}) {
    return (
        <Container
            id="menu"
            ref={wrapperRef}
            className={isExpand && "expand"}
            onMouseEnter={() => setExpand(true)}
            onMouseLeave={() => setExpand(false)}
        >
            {/* Request profile pic */}
            <Button
                to={"/verifications/profile-pictures/"}
                className={
                    window.location.pathname.includes("verifications") &&
                    "selected active"
                }
                activeClassName="active"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(!isRequest);
                    <ArrowImageContainer
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                        alt="Icon"
                        style={{
                            transition: "all 0.4s ease",
                        }}
                    />;
                }}
            >
                <Menu
                    className="menu-item"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "60%",
                            }}
                        >
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/verification.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/verification-active.svg"
                                alt="Icon"
                            />
                            <Title>Verifications</Title>
                        </div>
                        <div
                            style={{
                                display: isExpand ? "block" : "none",
                                transition: "all .1s ease",
                            }}
                        >
                            <ArrowImageContainer
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                                alt="Icon"
                                style={{
                                    transition: "all 0.4s ease",
                                }}
                            />
                            <ArrowImageContainer1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow-active.svg"
                                alt="Icon"
                            />
                        </div>
                    </div>
                </Menu>
            </Button>
            {isRequest && (
                <>
                    <Sub>
                        <SubButton
                            to="/verifications/profile-pictures/"
                            // className={
                            //     window.location.pathname.includes(
                            //         "verifications"
                            //     ) && "selected active"
                            // }
                            className={
                                window.location.pathname.includes(
                                    "verifications"
                                ) || "selected active"
                            }
                            activeClassName="active"
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/profile-icon.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/profile-icon-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Profile Pictures</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/verifications/campus"
                            // className={
                            //     window.location.pathname.includes(
                            //         "verifications"
                            //     ) && "selected active"
                            // }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Campus</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/verifications/campus-list"
                            // className={
                            //     window.location.pathname.includes(
                            //         "verifications"
                            //     ) && "selected active"
                            // }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Campus List</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                </>
            )}
            {/* End Request profile pic */}
        </Container>
    );
}

export default VerificationManagerSideBar;

const Sub = styled.div`
    display: flex;
    flex-direction: column;
    background: #161616;
`;

const Title = styled.span`
    font-family: "gordita_regular";
    color: inherit;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #f7684c;
    }
`;
const SubTitle = styled.p`
    font-family: "gordita_medium";
    font-size: 14px;
    white-space: nowrap;
    color: #fff;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #1ec569;
    }
`;
const Menu = styled.div`
    padding: 10px 21px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
`;
const SubMenu = styled.div`
    padding: 3px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
`;
const Dot = styled.span`
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transform: translate(0px, -1px);
    margin-right: 12px;
    border-radius: 12px;
`;
const MenuItemIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const MenuItemIcon1 = styled.img`
    display: none;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const MenuItemIcon2 = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 1550px) {
        margin-right: 1.125rem;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const ArrowImageContainer = styled.img`
    display: block;
    width: 16px;
    transform: rotate(-90deg);
    transition: all 0.4s ease;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const ArrowImageContainer1 = styled.img`
    display: none;
    width: 16px;
    /* margin-right: 1.125rem; */
    transform: rotate(0deg);
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const DownIcon = styled.img``;
const DownIcon1 = styled.img``;

const Button = styled(NavLink)`
    width: -webkit-fill-available;
    height: 46px;
    display: flex;
    color: #fff;
    transition: all 0.4s ease;
    &.active {
        border-left: 5px solid #fe673a;
        color: #1ec569 !important;
        background: #161616;
        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }

        ${Title} {
            color: #f7684c;
        }
        ${ArrowImageContainer} {
            display: none;
        }
        ${ArrowImageContainer1} {
            display: block;
        }
    }
`;

const SubButton = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 35px;
    width: -webkit-fill-available;
    height: 46px;
    transition: all 0.4s ease;
    &.active {
        color: red;

        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }
        ${SubTitle} {
            color: #f7684c;
        }
        ${Dot} {
            background-color: #f7684c;
        }
        transition: all 0.4s ease;
    }
`;

const Container = styled.aside`
    &.expand {
        width: 218px;
        transition: all 0.4s ease;
        ${Title} {
            display: block;
        }
        ${SubTitle} {
            display: block;
        }
        ${Menu} {
        }
        ${MenuItemIcon} {
            margin-right: 1.125rem;
        }
        ${MenuItemIcon1} {
            margin-right: 1.125rem;
        }
        ${Button} {
        }
    }
`;
