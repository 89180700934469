import React from "react";
import styled from "styled-components";
import moment from "moment/moment";
import Avatar from "../../../includes/utils/UserProfile";
import UserProfile from "../../../includes/utils/UserProfile";

export default function ParticipantsCard({ studentData, pagination }) {
    return (
        <>
            {studentData?.map((item, index) => (
                <ItemList>
                    {pagination?.first_item <= pagination?.last_item && (
                        <Item type="slno">{pagination.first_item + index}</Item>
                    )}
                    <Item type="students">
                        <InfoDiv>
                            {/* <ImgDiv>
                                <img
                                    src={item?.student.photo}
                                    alt={"Profile"}
                                />
                            </ImgDiv> */}
                            <>
                                <UserProfile
                                    image={item?.student.photo}
                                    name={item?.student.name}
                                />
                            </>

                            <div>
                                <Name>{item?.student.name ?? "--"}</Name>
                                <Desig>
                                    {item.student.phone
                                        ? item.student.phone
                                        : "--"}
                                </Desig>
                            </div>
                        </InfoDiv>
                    </Item>
                    <Item type="time">
                        <small>
                            {moment(
                                item?.registration_time
                                    ? item?.registration_time
                                    : ""
                            ).format("DD MMM YY | LT")}
                        </small>
                    </Item>
                    <Item type="waiting">{"--"}</Item>
                    <Item type="status">
                        <Status status={item?.is_participated}>
                            {item?.is_participated === false
                                ? "Not attended"
                                : "Attended"}
                        </Status>
                    </Item>
                    <Item type="joined">
                        <small>
                            {item?.joined_time
                                ? moment(item?.joined_time).format(
                                      "DD MMM YY | LT"
                                  )
                                : "--"}
                        </small>
                    </Item>
                </ItemList>
            ))}
        </>
    );
}

const ItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 18px 15px;
    color: #fff;

    &.active {
        background: #121214;
    }

    :hover {
        background: rgba(22, 22, 25, 1);
    }
`;
const Item = styled.li`
    font-size: 14px;
    /* text-align: ${({ type }) =>
        type === "seats" || type === "reg" ? "center" : "left"}; */

    h3 {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
    }
    h6 {
        font-size: 14px;
    }
    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    p {
        width: 60%;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    small {
        font-size: 13px;
    }
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "students"
            ? "19%"
            : type === "time"
            ? "15%"
            : type === "waiting"
            ? "10%"
            : type === "part"
            ? "10%"
            : type === "joined"
            ? "10%"
            : "10%"};

    list-style: none;

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    &.flex {
        display: flex;
        align-items: center;
    }
`;
const EditButton = styled.div`
    border-radius: 8px;
    width: 40px;
    background-color: #2c2c2c;
    padding: 10px;
    margin-right: 10px;

    img {
        width: 100%;
        display: block;
    }
`;
const InfoDiv = styled.div`
    display: flex;
`;
const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
const Name = styled.h5`
    font-size: 18px;
    text-transform: capitalize;
`;
const Desig = styled.h6`
    color: #fe673a;
    font-size: 14px;
    text-transform: capitalize;
`;
const Status = styled.small`
    color: ${(props) => {
        switch (props.status) {
            case false:
                return "#fd3e12";
            case true:
                return "#0FA76F";
            default:
                return "#000000";
        }
    }};
    border: 1px solid
        ${(props) => {
            switch (props.status) {
                case false:
                    return "#fd3e12";
                case true:
                    return "#0FA76F";
                default:
                    return "#000000";
            }
        }};
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 25px;
    text-transform: capitalize;
    display: inline-block;
`;

const InnerBox = styled.div`
    background: #2c2c2c;
    border: 1px solid #364152;
    padding: 8px;
    border-radius: 6px;
    display: inline-block;
`;
const BoxCount = styled.div`
    padding: 6px 8px;
    margin-left: 12px;
    color: #fff !important;
    background: #475467;
    border: 1px solid #697586;
    border-radius: 25px;
    font-size: 12px;
    position: relative;
`;
