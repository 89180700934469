import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import EditTrainerModal from "./modals/EditTrainerModal";
import Avatar from "react-avatar";

export default function TrainerCard({
    item,
    index,
    pagination,
    successful,
    setSuccessful,
}) {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");

    const handleEditClick = (item) => {
        setSelectedItem(item); // Set the selected item
        setShowModal(true);
    };

    return (
        <>
            <EditTrainerModal
                setShowModal={setShowModal}
                showModal={showModal}
                selectedItem={selectedItem}
                successful={successful}
                setSuccessful={setSuccessful}
            />
            <ItemList>
                {pagination?.first_item <= pagination?.last_item && (
                    <Item type="slno">{pagination.first_item + index}</Item>
                )}
                <Item type="photo">
                    <ImgDiv>
                        {item?.display_pic ? (
                            <img src={item?.display_pic} alt="avathar" />
                        ) : (
                            <Avatar name={item?.name} size="40" round />
                        )}
                    </ImgDiv>
                </Item>
                <Item type="name">
                    <h2>{item.name ? item.name : "--"}</h2>
                </Item>
                <Item type="desg">
                    <h3>{item.designation ? item.designation : "--"}</h3>
                </Item>
                <Item
                    type="action"
                    onClick={() => handleEditClick(item)}
                    className="flex"
                >
                    <EditButton>
                        <img
                            src={
                                require("../../../../assets/images/Edit.svg")
                                    .default
                            }
                            alt="Edit"
                        />
                    </EditButton>
                </Item>
            </ItemList>
        </>
    );
}

const ItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 7px 15px;
    color: #fff;

    &.active {
        background: #121214;
    }
    :last-child {
        border-bottom: 0px solid #000;
    }
    :hover {
        background: rgba(22, 22, 25, 1);
    }
`;
const Item = styled.li`
    font-size: 16px;
    text-align: left;
    text-transform: capitalize;

    width: ${({ type }) =>
        type === "slno"
            ? "10%"
            : type === "photo"
            ? "25%"
            : type === "name"
            ? "35%"
            : type === "desg"
            ? "30%"
            : type === "action"
            ? "10%"
            : "10%"};

    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    h2 {
        font-size: 16px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    h3 {
        font-size: 16px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    list-style: none;
`;
const EditButton = styled.div`
    border-radius: 8px;
    width: 40px;
    background-color: #2c2c2c;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
