import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import SectionLoader from "../../../helpers/SectionLoader";
import Group from "../../../screens/groups/Group";
import styled from "styled-components";
import GroupInner from "../../../screens/groups/GroupInner";

const GroupRouter = () => {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Conatiner id="main">
                <Routes>
                    <Route path="/" element={<Group />} />
                    <Route path=":group_id" element={<GroupInner />} />
                </Routes>
            </Conatiner>
        </Suspense>
    );
};

const Conatiner = styled.div``;
export default GroupRouter;
