import React from 'react'
import styled from 'styled-components'
import profile from "../../../../src/assets/images/school-scientist/profile.svg"

function HSSStudents({count}) {
  return (
    <>
        <Container>
            <TotalContainer>
                <TopContainer>
                    <LeftContent>
                        <ProfileContainer>
                            <Profile 
                                src={profile}
                                alt="ProfileImage"
                            />
                        </ProfileContainer>
                        <Text>HSS Students</Text>
                    </LeftContent>
                    <RightContent>
                        <Count>{count.attended_hss_students}</Count>
                    </RightContent>
                </TopContainer>
                <BottomContainer>
                    <Cover>
                        <Item>
                            <LeftText>
                                Level 1
                            </LeftText>
                            <RightText>
                                {count.attended_hss_students}
                            </RightText>
                        </Item>
                        <Item>
                            <LeftText>
                                Level 2
                            </LeftText>
                            <RightText>
                                0
                            </RightText>
                        </Item>
                        <Item>
                            <LeftText>
                                Level 3
                            </LeftText>
                            <RightText>
                                0
                            </RightText>
                        </Item>
                    </Cover>
                </BottomContainer>
            </TotalContainer>
        </Container>
    </>
  )
}
const Container = styled.div`
   
`;
const TotalContainer = styled.div`
    background: #1b1c20;
    padding: 30px;
    border-radius: 16px;
    height: 329px;
    border: 1px solid #242528;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
`;
const LeftContent = styled.div`
    display: flex;
    align-items: center;

`;
const ProfileContainer = styled.div`
    margin-right: 5px;
`;
const Profile = styled.img`
    width: 100%;
    display: block;
`;
const Text = styled.h3`
    color: #fff;
    font-size: 16px;
`;
const RightContent = styled.div`

`;
const Count = styled.h4`
    color: #f7684c;
    font-family: "gordita_medium";
`;
const BottomContainer = styled.div`

`;
const Cover = styled.ul`
    
`;
const Item = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: #161616;
    margin-bottom: 20px;
    padding: 20px;
    height: 54px;
    border-radius: 8px;
    border: 1px solid #242528;
`;
const LeftText = styled.h4`
    font-size: 14px;
`;
const RightText = styled.h4`
    color: #f7684c;
    font-family: "gordita_medium";
`;


export default HSSStudents