import React from "react";
import Avatar from "react-avatar";
import styled from "styled-components";
import moment from "moment";

function ViewReasonModal({ modal, setModal, selectedData, setSelectedData }) {
    return (
        <MainContainer>
            {modal && (
                <Overlay
                    onClick={() => {
                        setModal(false);
                        setSelectedData("");
                    }}
                ></Overlay>
            )}
            <ContainerDiv className={modal ? "active" : ""}>
                <ContainerHead>
                    <div>
                        <HeadText>Reason Details</HeadText>
                        <TimeDetails>
                            {" "}
                            {moment(selectedData?.removed_date ?? "--").format(
                                "MMMM Do YYYY, h:mm a"
                            )}
                        </TimeDetails>
                    </div>
                    <HeadIcon
                        onClick={() => {
                            setModal(false);
                            setSelectedData("");
                        }}
                    >
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-01-2024/x-close.svg "
                            alt="close-icon"
                        />
                    </HeadIcon>
                </ContainerHead>
                <ActivityCards>
                    <ContentHead>
                        <ContentTitle>Content Type</ContentTitle>
                        <ContentCategory>Question</ContentCategory>
                    </ContentHead>
                    <ContentBody>
                        <ContentTitle>Asked By</ContentTitle>
                        <PersonDiv>
                            {selectedData?.posted_by?.student_photo ? (
                                <ImageContainer>
                                    <img
                                        src={
                                            selectedData?.posted_by
                                                ?.student_photo
                                        }
                                        alt="student"
                                    />
                                </ImageContainer>
                            ) : (
                                <DpDiv>
                                    <Avatar
                                        name={
                                            selectedData?.posted_by
                                                ?.student_name
                                        }
                                        size="38"
                                        round
                                    />
                                </DpDiv>
                            )}
                            <Student>
                                <h3
                                    style={{
                                        fontFamily: "gordita_medium",
                                        fontSize: "14px",
                                        color: " #fff",
                                    }}
                                >
                                    {selectedData?.posted_by?.student_name ??
                                        "--"}
                                </h3>
                                <p
                                    style={{
                                        fontFamily: "gordita_regular",
                                        fontSize: "14px",
                                        color: "#fe673a",
                                    }}
                                >
                                    {selectedData?.posted_by?.student_program ??
                                        "--"}
                                </p>
                            </Student>
                        </PersonDiv>
                    </ContentBody>
                    <ContentBody>
                        <ContentTitle>Removed By</ContentTitle>
                        <PersonDiv>
                            {selectedData?.removed_by?.student_photo ? (
                                <ImageContainer>
                                    <img
                                        src={
                                            selectedData?.removed_by
                                                ?.profile_photo
                                        }
                                        alt="student"
                                    />
                                </ImageContainer>
                            ) : (
                                <DpDiv>
                                    <Avatar
                                        name={
                                            selectedData?.removed_by?.user_name
                                        }
                                        size="38"
                                        round
                                    />
                                </DpDiv>
                            )}
                            <Student>
                                <h3
                                    style={{
                                        fontFamily: "gordita_medium",
                                        fontSize: "14px",
                                        color: " #fff",
                                    }}
                                >
                                    {selectedData?.removed_by?.user_name ??
                                        "--"}
                                </h3>
                                <p
                                    style={{
                                        fontFamily: "gordita_regular",
                                        fontSize: "14px",
                                        color: "#fe673a",
                                    }}
                                >
                                    {selectedData?.removed_by?.profile_type ===
                                    "students_relations_officer"
                                        ? "Moderator"
                                        : selectedData?.removed_by
                                              ?.profile_type}
                                </p>
                            </Student>
                        </PersonDiv>
                    </ContentBody>
                    <ContentBody>
                        <ContentTitle>Reason</ContentTitle>
                        <ContentText>
                            {selectedData?.reason ?? "--"}
                        </ContentText>
                    </ContentBody>
                    <ContentFooter>
                        <ContentTitle>Removed on</ContentTitle>
                        <RemovedDate>
                            {moment(selectedData?.removed_date ?? "--").format(
                                "MMMM Do YYYY, h:mm a"
                            )}
                        </RemovedDate>
                    </ContentFooter>
                </ActivityCards>
                <BottomDiv>
                    <CloseBtn
                        onClick={() => {
                            setModal(false);
                            setSelectedData("");
                        }}
                    >
                        Close
                    </CloseBtn>
                </BottomDiv>
            </ContainerDiv>
        </MainContainer>
    );
}

export default ViewReasonModal;

const PersonDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ContentHead = styled.div`
    margin-bottom: 32px;
`;
const ContentBody = styled.div`
    margin-bottom: 32px;
`;
const ContentFooter = styled.div`
    border-top: 1px solid #2c2c2c;
    border-bottom: 1px solid #2c2c2c;
    padding: 30px 0;
`;
const RemovedDate = styled.h4`
    font-size: 14px;
    color: #fff;
`;
const ContentTitle = styled.h4`
    color: #94a3b8;
    font-family: "gordita_medium";
    font-size: 14px;
    margin-bottom: 8px;
`;
const ContentCategory = styled.h4`
    color: #ffffff;
    font-family: "gordita_medium";
    font-size: 14px;
`;
const ContentText = styled.p`
    font-size: 16px;
    color: #ffffff;
    font-family: "gordita_regular";
`;
const MainContainer = styled.div`
    /* padding: 24px; */
`;
const BottomDiv = styled.div`
    border-top: 1px solid #2c2c2c;
    padding-top: 12px;
    display: flex;
    justify-content: end;
    align-items: baseline;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const ContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    z-index: 1001;
    right: -555px;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 555px;
    max-width: 555px;
    transition: all ease 200ms;
    padding: 24px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        right: 0;
    }
    border-left: 1px solid #2c2c2c;
    background: #1b1c1f;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
`;
const ContainerHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid #2c2c2c;
`;

const HeadText = styled.h2`
    color: #fff;
    font-size: 20px;
    font-family: "gordita_medium";
    margin-bottom: 6px;
`;
const HeadIcon = styled.span`
    display: inline-block;
    cursor: pointer;
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;

const ActivityCards = styled.ul`
    margin-top: 20px;
`;
const TimeDetails = styled.h3`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #fff;
`;
const CloseBtn = styled.div`
    padding: 10px 16px 10px 16px;
    background: #ffffff;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
`;
const ImageContainer = styled.div`
    margin-right: 5px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;

    img {
        width: 100%;
        height: 100%;
        display: block;
    }

    img {
        width: 100%;
        display: block;
    }
`;
const Student = styled.div`
    h2 {
        font-family: "gordita_medium";
        font-size: 16px;
    }
    p {
        font-family: "gordita_medium";
        font-size: 16px;
    }
`;
const DpDiv = styled.div`
    margin-right: 5px;
`;
