import React, { lazy } from "react";
import { Switch, Route, Routes, Navigate } from "react-router-dom";
const ChallengeJoiners = lazy(() =>
    import("../../../screens/challenges/ChallengeJoiners")
);
const ChallengeResults = lazy(() =>
    import("../../../screens/challenges/ChallengeResults")
);

function ChallengesListRouter() {
    return (
        <Routes>
            {/* <Route exact path="/challenges/:challenge_id/">
                <Redirect
                    to={{
                        pathname: "/challenges/:challenge_id/joiners/",
                    }}
                />
            </Route> */}

            <Route path="/" element={<Navigate to="joiners" />} />

            <Route path="joiners" element={<ChallengeJoiners />} />
            <Route path="results" element={<ChallengeResults />} />
        </Routes>
    );
}

export default ChallengesListRouter;
