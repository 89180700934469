import item1 from "../../../../assets/images/Dropdown.svg";
import item2 from "../../../../assets/images/vector(2).svg";
import item3 from "../../../../assets/images/component.svg";


export const data = [
    {
        id: 1,
        name: "Total Students",
        src: item1,
        subname: "17299"
    },
    {
        id: 2,
        name: "Ongoing",
        src: item1,
        subname: "16721"
    },
    {
        id: 3,
        name: "Completed",
        src: item1,
        subname: "580"
    },
    {
        id: 4,
        name: "Not enrolled",
        src: item1,
        subname: "0"
    },
    {
        id: 5,
        icons: item2,
        title: "1 Topic",
        icon: item3,
        subtitle: "24 hrs 10 mins 35 secs"
    }

]