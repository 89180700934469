import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import DashbordIcon from "../../../assets/images/subscription_manager/contentmanagericon.svg";
import CategoryIcon from "../../../assets/images/tech-update/catogery.svg";

function ContentManagerSidebar({ isExpand, setExpand, wrapperRef }) {
    const location = useLocation();

    return (
        <Container
            id="menu"
            ref={wrapperRef}
            className={isExpand && "expand"}
            onMouseEnter={() => setExpand(true)}
            onMouseLeave={() => setExpand(false)}
        >
            <Button
                to="/content-manager/dashboard/"
                // className={
                //     location.pathname.includes("content-manager") && "active"
                // }
            >
                <Menu className="menu-item">
                    <MenuItemIcon src={DashbordIcon} alt="Icon" />
                    <MenuItemIcon1 src={DashbordIcon} alt="Icon" />
                    <Title>Tech updates</Title>
                </Menu>
            </Button>
            <Button to="/content-manager/categories/">
                <Menu className="menu-item">
                    <MenuItemIcon src={CategoryIcon} alt="Icon" />
                    <MenuItemIcon1 src={CategoryIcon} alt="Icon" />
                    <Title>Categories</Title>
                </Menu>
            </Button>
        </Container>
    );
}

export default ContentManagerSidebar;

const Title = styled.span`
    font-family: "gordita_regular";
    color: inherit;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #f7684c;
    }
`;
const SubTitle = styled.p`
    font-family: "gordita_medium";
    font-size: 14px;
    white-space: nowrap;
    color: #fff;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #1ec569;
    }
`;
const Menu = styled.div`
    padding: 10px 21px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
`;
const MenuItemIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const MenuItemIcon1 = styled.img`
    display: none;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const ArrowImageContainer = styled.img`
    display: block;
    width: 16px;
    transform: rotate(-90deg);
    transition: all 0.4s ease;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const ArrowImageContainer1 = styled.img`
    display: none;
    width: 16px;
    /* margin-right: 1.125rem; */
    transform: rotate(0deg);
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const DownIcon = styled.img``;
const DownIcon1 = styled.img``;

const Button = styled(NavLink)`
    width: -webkit-fill-available;
    height: 46px;
    display: flex;
    color: #fff;
    transition: all 0.4s ease;
    &.active {
        border-left: 5px solid #fe673a;
        color: #1ec569 !important;
        background: #161616;
        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }

        ${Title} {
            color: #f7684c;
        }
        ${ArrowImageContainer} {
            display: none;
        }
        ${ArrowImageContainer1} {
            display: block;
        }
    }
`;

const Container = styled.aside`
    &.expand {
        width: 218px;
        transition: all 0.4s ease;
        ${Title} {
            display: block;
        }
        ${SubTitle} {
            display: block;
        }
        ${Menu} {
        }
        ${MenuItemIcon} {
            margin-right: 1.125rem;
        }
        ${MenuItemIcon1} {
            margin-right: 1.125rem;
        }
        ${Button} {
        }
    }
`;
