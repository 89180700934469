import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function AepPosterTypes() {
    return (
        <MainSection id="main">
            <Container>
                <Switchers>
                    <PosterTypes to="/aep/posters/create-poster/techschooling">
                        Tech Schooling
                    </PosterTypes>
                    <PosterTypes to="/aep/posters/create-poster/techdegree">
                        Tech Degree
                    </PosterTypes>
                    <PosterTypes to="/aep/posters/create-poster/techgrad">
                        Tech Grad
                    </PosterTypes>
                </Switchers>
            </Container>
        </MainSection>
    );
}

export default AepPosterTypes;

const MainSection = styled.div`
    color: #fff;
`;
const Container = styled.div`
    min-height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Switchers = styled.div`
    width: 300px;
    /* height: 40px; */
`;
const PosterNames = styled.div`
    color: #fff;
    cursor: pointer;
    font-size: 22px;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    background-color: #3a3a3a;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    :last-child {
        margin-bottom: 0;
    }
`;
const PosterTypes = styled(Link)`
    color: #fff;
    cursor: pointer;
    font-size: 22px;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    background-color: #3a3a3a;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    :last-child {
        margin-bottom: 0;
    }
`;
