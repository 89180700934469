import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const SrhDashboard = lazy(() =>
    import("../../../screens/operations-head/Dashboard")
);
const SrmStatus = lazy(() =>
    import("../../../screens/operations-head/SrmStatus")
);
const ParentApp = lazy(() =>
    import("../../../screens/operations-head/ParentApp")
);

function ExecutiveRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard/" />} />
            <Route path="/dashboard/" element={<SrhDashboard />} />
            <Route path="/status" element={<SrmStatus />} />
            <Route path="/parent-app" element={<ParentApp />} />
        </Routes>
    );
}

export default ExecutiveRouter;
