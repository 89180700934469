import React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import SrhDashboard from "../../../screens/operations-head/Dashboard";
import SrmStatus from "../../../screens/operations-head/SrmStatus";
import SrhStatus from "../../../screens/operations-head/SrhStatus";
import CallStatus from "../../../screens/follow-up/CallStatus";

function FollowupRouter() {
	return (
		<Routes>
			<Route exact path="/" element={<SrhDashboard />} />
			{/* <Route
                    exact
                    path="/dashboard/"
                    element={<SrhDashboard />}
                /> */}
			{/* <Route exact path="/status/" element={<SrhStatus />} /> */}

			<Route exact path="/status/" element={<CallStatus />} />
		</Routes>
	);
}

export default FollowupRouter;

const Container = styled.div``;
