import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import styled from "styled-components";
import SectionLoader from "../../helpers/SectionLoader";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function SrmLineChart({
    isGraphDataLoading,
    graphData,
    attendArray,
    pendingArray,
    notAttendedArray,
    switchedOffArray,
    busyArray,
}) {
    Chart.register(...registerables);

    const data = {
        labels: graphData,

        datasets: [
            {
                label: "Attended",
                data: attendArray,
                fill: true,
                backgroundColor: "green",
                borderColor: "#9156a4",
                barThickness: 20,
                tension: 0.5,
                borderWidth: 0,
            },
            {
                label: "Pending",
                data: pendingArray,
                fill: true,
                backgroundColor: "yellow",
                borderColor: "#9156a4",
                barThickness: 20,
                tension: 0.5,
                borderWidth: 0,
            },
            {
                label: "Not Attended",
                data: notAttendedArray,
                fill: true,
                backgroundColor: "red",
                borderColor: "#9156a4",
                barThickness: 20,
                tension: 0.5,
                borderWidth: 0,
            },
            {
                label: "Switched Off",
                data: switchedOffArray,
                fill: true,
                backgroundColor: "orange",
                borderColor: "#9156a4",
                barThickness: 20,
                tension: 0.5,
                borderWidth: 0,
            },
            {
                label: "Busy/Call Later",
                data: busyArray,
                fill: true,
                backgroundColor: "blue",
                borderColor: "#9156a4",
                barThickness: 20,
                tension: 0.5,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        plugins: {
            drawBorder: false,
            title: {
                display: false,
                text: "",
            },
        },

        layout: {
            padding: 10,
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <Container>
            <Bar data={data} options={options} />
        </Container>
    );
}

export default SrmLineChart;

const Container = styled.div`
    // height: 400px;
    canvas {
        // height: 225px !important;
        width: 100% !important;
        margin: 0 auto;
    }
`;
