import React from 'react'
import styled from 'styled-components';

function SchoolScientistStudents() {
  return (
    <>
         <MainContainer>
            <TopContainer>
                <Heading>Students</Heading>
            </TopContainer>
        </MainContainer>
    </>
  )
}
const MainContainer = styled.div``;
const TopContainer = styled.div`
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
    margin-bottom: 20px;
    @media all and (max-width: 1280px) {
      font-size: 16px;
	 }
`;

export default SchoolScientistStudents