import React from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import styled from "styled-components";

function CommentCard({ item, pagination, index }) {
    return (
        <>
            <ItemList>
                {pagination?.first_item <= pagination?.last_item && (
                    <Item type="slno">{pagination?.first_item + index}</Item>
                )}
                <Item type="post">
                    <Post>
                        <h3>
                            {item?.reported_instance?.content
                                ? item?.reported_instance?.content
                                : "--"}
                        </h3>
                    </Post>
                </Item>
                <Item type="led">
                    <InfoDiv>
                        <ImgDiv>
                            {item?.posted_by?.photo ? (
                                <img
                                    src={item?.posted_by?.photo}
                                    alt={"avathar"}
                                />
                            ) : (
                                <Avatar
                                    name={item?.posted_by?.name}
                                    size="40"
                                    round
                                />
                            )}
                        </ImgDiv>
                        <LeadCol>
                            <Name>
                                {item?.posted_by?.name
                                    ? item?.posted_by?.name
                                    : "--"}
                            </Name>
                            <Desig>
                                {item?.posted_by?.program?.name
                                    ? item?.posted_by?.program?.name
                                    : "--"}
                            </Desig>
                        </LeadCol>
                    </InfoDiv>
                </Item>
                <Item type="reports">
                    <div>
                        <h6>
                            {item?.total_report_count
                                ? item?.total_report_count
                                : "--"}
                        </h6>
                    </div>
                </Item>
                <Item type="status">
                    <h6>
                        Current: {""}
                        <StatusSpan current={item?.current_report_action}>
                            {item?.current_report_action === null
                                ? "Pending"
                                : item?.current_report_action == "ignore"
                                ? "Wrong Report"
                                : item?.current_report_action}
                        </StatusSpan>
                    </h6>
                    <h6>
                        Last: {""}
                        {item?.last_report_action === null ? (
                            "--"
                        ) : (
                            <StatusSpan last={item?.last_report_action}>
                                {item?.last_report_action == "ignore"
                                    ? "Worng Report"
                                    : item?.last_report_action}
                            </StatusSpan>
                        )}
                    </h6>
                </Item>
                <Item>
                    <ViewButton
                        to={`/community/reports/${item?.report_type}/${item?.id}/`}
                    >
                        View Reports
                    </ViewButton>
                </Item>
            </ItemList>
        </>
    );
}

export default CommentCard;

const ItemList = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 18px 15px;
    color: #fff;

    &.active {
        background: #121214;
    }

    :hover {
        background: rgba(22, 22, 25, 1);
    }
`;
const Item = styled.li`
    font-size: 14px;

    h3 {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
        color: #fff;
    }
    h6 {
        text-align: ${({ type }) => (type === "reports" ? "center" : "left")};
        font-size: 14px;
    }
    /* span {
        color: ${({ current, last }) =>
        current === "Post Removed" || last === "Post Removed"
            ? "#0FA76F"
            : current === "Wrong Report" || last === "Wrong Report"
            ? "#D92D20"
            : "#FFFFFF"};
        margin-top: 5px;
        padding: 4px 8px 3px;
        border: 1px solid
            ${({ current, last }) =>
        current === "Post Removed" || last === "Post Removed"
            ? "#0FA76F"
            : current === "Wrong Report" || last === "Wrong Report"
            ? "#D92D20"
            : "#FFFFFF"};
        border-radius: 4px;
        display: inline-block;
        font-size: 12px;
    } */
    p {
        width: 60%;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "post"
            ? "35%"
            : type === "led"
            ? "15%"
            : type === "reports"
            ? "10%"
            : type === "status"
            ? "25%"
            : "10%"};

    list-style: none;

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    &.flex {
        display: flex;
        align-items: center;
    }
`;
const StatusSpan = styled.span`
    color: ${({ current, last }) =>
        current === "remove" || last === "remove"
            ? "#0FA76F"
            : current === "ignore" || last === "ignore"
            ? "#D92D20"
            : "#FFFFFF"};
    margin-top: 5px;
    padding: 4px 8px 3px;
    border: 1px solid
        ${({ current, last }) =>
            current === "remove" || last === "remove"
                ? "#0FA76F"
                : current === "ignore" || last === "ignore"
                ? "#D92D20"
                : "#FFFFFF"};
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
`;
const InfoDiv = styled.div`
    display: flex;
`;
const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
const Name = styled.h5`
    font-size: 18px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Desig = styled.h6`
    color: #fe673a;
    font-size: 14px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Status = styled.small`
    color: ${(props) => {
        switch (props.Status) {
            case "Pending":
                return "#fd3e12";
            case "upcoming":
                return "#F59E0B";
            case "started":
                return "#0FA76F";
            case "completed":
                return "#175CD3";
            default:
                return "#ff0000";
        }
    }};
    border: 1px solid
        ${(props) => {
            switch (props.status) {
                case "registrations":
                    return "#fd3e12";
                case "upcoming":
                    return "#F59E0B";
                case "started":
                    return "#0FA76F";
                case "completed":
                    return "#175CD3";
                default:
                    return "#ff0000";
            }
        }};
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 25px;
    text-transform: capitalize;
    display: inline-block;
`;

const LeadCol = styled.div`
    width: 60%;
`;
const ViewButton = styled(Link)`
    border: 1px solid #3b3b3b;
    padding: 11px 16px;
    color: #fe673a;
    border-radius: 6px;
    font-size: 12px;
`;
const ImageDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 60px;
    img {
        width: 60px;
        height: 40px;
        display: block;
    }
`;
const Post = styled.div`
    display: flex;
    align-items: center;
`;
