import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

// Function to decrypt and get user data from the cookie
export function getUserData() {
  const encryptedUserData = Cookies.get("user_data");
  if (encryptedUserData) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedUserData, "#q8a2!u*&4");
      const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedUserData;
    } catch (error) {
      // Handle decryption error
      // console.error("Error decrypting user data:", error);
      return null;
    }
  } else {
    return null;
  }
}
