import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment/moment";
import Avatar from "react-avatar";
import NoData from "../jobdesk/NoData";
import SectionLoader from "../../helpers/SectionLoader";

function ProjectAssignedPage({
    setSearchWord,
    searchWord,
    setSearchParams,
    setModal,
    assigned,
    isSuccess,
    setSuccess,
    loader,
}) {
    return (
        <Container>
            <FormHead>
                <SearchContainer>
                    <Label>Search</Label>
                    <InputConatainer>
                        <Icon>
                            <SearchIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
                                alt="SearchIcon"
                            />
                        </Icon>
                        <Input
                            type="text"
                            placeholder="Search for students ..."
                            value={searchWord}
                            onChange={(e) => {
                                setSearchWord(e.target.value);
                                setSearchParams({
                                    q: e.target.value,
                                });
                            }}
                        />
                    </InputConatainer>
                </SearchContainer>
                <AssignBtn
                    onClick={() => {
                        setModal(true);
                    }}
                >
                    <Icon className="plus">
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/icon.svg"
                            alt="plusicon"
                        />
                    </Icon>
                    <ButtonDiv>Assigned to </ButtonDiv>
                </AssignBtn>
            </FormHead>
            <FormBody>
                <TableHead>
                    <TableHeadList>
                        <TableHeadItem type="number">
                            Sl No.{" "}
                            <TableHeadIcon>
                                <TableDownArrowIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/chevron-down.svg"
                                    alt="ArrowIcon"
                                />
                            </TableHeadIcon>
                        </TableHeadItem>
                        <TableHeadItem type="reportedby">
                            Developer{" "}
                            <TableHeadIcon>
                                <TableDownArrowIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/chevron-down.svg"
                                    alt="ArrowIcon"
                                />
                            </TableHeadIcon>
                        </TableHeadItem>

                        <TableHeadItem type="action">
                            Status
                            <TableHeadIcon>
                                <TableDownArrowIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/chevron-down.svg"
                                    alt="ArrowIcon"
                                />
                            </TableHeadIcon>
                        </TableHeadItem>
                    </TableHeadList>
                </TableHead>
                <>
                    {loader ? (
                        <SectionLoader status={"followup_executive"} />
                    ) : (
                        <>
                            <>
                                <TableBody>
                                    <TableData>
                                        {assigned?.length > 0 ? (
                                            assigned.map((issue, index) => (
                                                <TalbleList key={index}>
                                                    <TableItem
                                                        className="issueId"
                                                        type="number"
                                                    >
                                                        {index + 1}
                                                    </TableItem>

                                                    <TableItem type="reportedby">
                                                        <IconImage>
                                                            {issue?.photo ? (
                                                                <img
                                                                    src={
                                                                        issue?.photo
                                                                    }
                                                                    alt="avathar icon"
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    name={
                                                                        issue?.name
                                                                    }
                                                                    size="40"
                                                                    round
                                                                />
                                                            )}
                                                        </IconImage>

                                                        <ReportContainer>
                                                            <DevName>
                                                                {issue?.name ??
                                                                    "--"}
                                                            </DevName>
                                                            <DevPosition>
                                                                {issue?.user_id ??
                                                                    "--"}
                                                            </DevPosition>
                                                        </ReportContainer>
                                                    </TableItem>

                                                    <TableItem type="action">
                                                        <ServityBox
                                                            securityColor={
                                                                issue?.status
                                                            }
                                                        >
                                                            {issue?.status ??
                                                                "--"}
                                                        </ServityBox>
                                                    </TableItem>
                                                </TalbleList>
                                            ))
                                        ) : (
                                            <TalbleList>
                                                <NoData />
                                            </TalbleList>
                                        )}
                                    </TableData>
                                </TableBody>
                            </>
                        </>
                    )}
                </>
            </FormBody>
        </Container>
    );
}

export default ProjectAssignedPage;

const Container = styled.div``;

const FormHead = styled.div`
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
`;
const SearchContainer = styled.div`
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;
const AssignBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 6px;
    border-radius: 8px;
    width: 160px;
    height: 45px;
    background-color: #fe673a;
    font-family: "gordita_medium";
    cursor: pointer;
`;
const Label = styled.label`
    color: #c9c9c9;
    font-size: 14px;
    font-family: "gordita_regular";
`;
const InputConatainer = styled.div`
    border-radius: 6px;
    border: 1px solid #3d3d3d;
    background: #161619;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Input = styled.input`
    color: #fff;
    font-size: 16px;
    width: 100%;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
    }
`;
const Icon = styled.span`
    display: inline-block;
    width: 20px;
    margin-right: 12px;
    &.plus {
        width: 15px;
        margin-right: 10px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const SearchIcon = styled.img`
    display: block;
    width: 100%;
`;

const ButtonDiv = styled.p`
    color: #fff;
    font-size: 15px;
    font-family: "gordita_medium";
`;

const FormBody = styled.div`
    /* min-height: 30vh; */
    background: #1b1c1f;
    border-radius: 8px;
`;

const TableHead = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #2c2c2c;
    background: #1b1c1f;
`;

const TableHeadList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TableHeadItem = styled.li`
    font-size: 16px;
    font-family: "gordita_medium";
    color: #fff;
    display: flex;
    align-items: center;
    width: ${({ type }) =>
        type === "number"
            ? "8%"
            : type === "reportedby"
            ? "70%"
            : type === "action"
            ? "20%"
            : ""};
    &:last-child {
        justify-content: end;
    }
`;

const TableHeadIcon = styled.span`
    display: inline-block;
    width: 14px;
    margin-left: 8px;
`;

const TableDownArrowIcon = styled.img`
    display: block;
    width: 100%;
`;

const TableBody = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #2c2c2c;
    background: #1b1c1f;
    border-radius: 8px;
    &:last-child {
        border-bottom: none;
    }
`;

const TalbleList = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #2c2c2c;
`;
const TableItem = styled.li`
    font-size: 14px;
    font-family: "gordita_medium";
    color: #d2caca;
    display: flex;
    align-items: center;
    width: ${({ type }) =>
        type === "number"
            ? "8%"
            : type === "reportedby"
            ? "70%"
            : type === "action"
            ? "20%"
            : ""};
    &.issueId {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &.screen {
        flex-direction: column;
        align-items: flex-start;
    }
    &:last-child {
        justify-content: end;
    }
`;

const SpanNumber = styled.span`
    color: #fff;
    font-size: 10px;
    font-family: "gordita_regular";
`;

const ScreenName = styled.h4`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 8px;
`;
const ScreenText = styled.h5`
    font-family: "gordita_regular";
    color: #d2caca;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 180px;
    word-break: break-word;
`;

const BugContainer = styled.div`
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    background: #1b1c1f;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 4px 6px;
    align-items: center;
`;
// const BugIcon = styled.span`
//     width: 8px;
//     height: 8px;
//     border-radius: 50%;
//     display: inline-block;
//     background: ${({ securityColor }) =>
//         securityColor === "open"
//             ? "#2E90FA"
//             : securityColor === "done"
//             ? "#16B364"
//             : securityColor === "re_open"
//             ? "#F79009"
//             : "#2E90FA"};
// `;
const BugText = styled.h5`
    color: #d0d5dd;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-left: 8px;
    text-transform: capitalize;
`;

const BugButton = styled.button`
    font-size: 16px;
    font-family: "gordita_regular";
    color: #fff;
    display: flex;
    padding: 8px 18px;
    height: 44px;
    width: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #fe673a;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
`;

const DevStatus = styled.div`
    color: #d0d5dd;
    display: flex;
    text-transform: capitalize;
    padding: 8px 12px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 32px;
`;

const Attachment = styled.button`
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_regular";
    border: none;
    background: none;
    cursor: pointer;
`;

const Date = styled.h5`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 3px;
`;
const Time = styled(Date)`
    color: #d2caca;
    font-family: "gordita_regular";
`;
const IconImage = styled.div`
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;

const ReportContainer = styled.div``;

const DevName = styled.h5`
    font-size: 14px;
    color: #fff;
    font-family: "gordita_medium";
`;
const DevPosition = styled.h5`
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_regular";
`;

const ServityBox = styled.button`
    color: ${({ securityColor }) =>
        securityColor === "Not Enrolled"
            ? "#087443"
            : securityColor === "medium"
            ? "#B54708"
            : securityColor === "high"
            ? "#B42318"
            : "#fff"};
    font-size: 14px;
    font-family: "gordita_medium";
    padding: 2px 10px;
    border-radius: 16px;
    text-transform: capitalize;
    border: 1.5px solid
        ${({ securityColor }) =>
            securityColor === "Not Enrolled"
                ? "#087443"
                : securityColor === "medium"
                ? "#B54708"
                : securityColor === "high"
                ? "#B42318"
                : "#fff"};
    /* cursor: pointer; */
`;
const ActionButton = styled.button`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    padding: 11px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fe673a;
    font-size: 12px;
    font-family: "gordita_medium";
    cursor: pointer;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 10px;
    small {
        color: #cbd5e1;
    }
`;

const EvaluvationParagraph = styled.p`
    color: #fff;
    font-size: 1rem;
    font-family: "gordita_regular";
    width: 20%;
    text-align: center;
`;
const Link = styled.a`
    cursor: pointer;
    color: #fe673a;
    font-size: 1rem;
    font-family: "gordita_regular";
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const TableData = styled.div``;
