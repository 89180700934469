import React from "react";
//packages
import styled from "styled-components";

function BankDetails({ aepDetails }) {
    return (
        <>
            <Heading>Bank Details</Heading>
            <MainContainer>
                <BankDetailsDiv>
                    <Container>
                        <LeftContainer>
                            <InputDiv>
                                <Titles>Bank Name:</Titles>
                                <InputData>
                                    {aepDetails.bank_details?.bank_name}
                                </InputData>
                            </InputDiv>
                            <InputDiv>
                                <Titles>Account Holder:</Titles>
                                <InputData>
                                    {
                                        aepDetails.bank_details
                                            ?.account_holder_name
                                    }
                                </InputData>
                            </InputDiv>
                            <InputDiv>
                                <AccountNumberTitle>
                                    Account Number:
                                </AccountNumberTitle>
                                <AccountNumber>
                                    {aepDetails.bank_details?.account_number}
                                </AccountNumber>
                            </InputDiv>
                        </LeftContainer>
                        <RightContainer>
                            <InputDiv>
                                <Titles>Branch Name:</Titles>
                                <InputData>
                                    {aepDetails.bank_details?.branch_name}
                                </InputData>
                            </InputDiv>
                            <InputDiv>
                                <Titles>IFSC Code:</Titles>
                                <InputData>
                                    {aepDetails.bank_details?.ifsc_code}
                                </InputData>
                            </InputDiv>
                        </RightContainer>
                    </Container>
                </BankDetailsDiv>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.div`
    overflow-x: scroll;
    margin-bottom: 30px;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const Heading = styled.p`
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: "gordita_medium";
    margin-bottom: 15px;
    text-transform: capitalize;
`;
const BankDetailsDiv = styled.div`
    background-color: rgb(27, 28, 31);
    border: 1px solid #26272a;
    border-radius: 5px;
    min-width: 1180px;
    padding: 20px;
    @media all and (max-width: 480px) {
        flex-direction: column;
        & :first-child {
            margin-top: 0;
        }
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;
const LeftContainer = styled.div`
    width: 40%;
`;
const Titles = styled.p`
    font-size: 14px;
    font-family: gordita_regular;
    color: rgb(134, 134, 134);
    margin-bottom: 5px;
`;
const InputDiv = styled.div`
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const InputData = styled.p`
    text-transform: capitalize;
    font-size: 14px;
    font-family: gordita_regular;
    color: rgb(255, 255, 255);
`;
const RightContainer = styled.div`
    width: 40%;
`;
const AccountNumberTitle = styled.p`
    font-size: 14px;
    font-family: gordita_regular;
    color: rgb(134, 134, 134);
    margin-bottom: 5px;
`;
const AccountNumber = styled.p`
    text-transform: capitalize;
    font-size: 14px;
    font-family: gordita_regular;
    color: rgb(255, 255, 255);
`;
export default BankDetails;
