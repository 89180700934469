import { useEffect } from "react";
import SchoolScientistCategory from "./SchoolScientistCategory";
import MathematicalList from "../schoolScientist/MathematicalList";
import SchoolScientistEditModal from "./Modal/SchoolScientistEditModal";
import QuestionCategoryUP from "./QuestionCategoryUP";
import SchoolScientistQuestions from "./SchoolScientistQustions";
import SchoolScientistsAddModal from "./Modal/SchoolScientistAddModal";
import { useState } from "react";
import SchoolScientistDeleteModal from "./Modal/SchoolScientistDeleteModal";
import { useSearchParams } from "react-router-dom";
import { communityConfig } from "../../../axiosConfig";
import SectionLoader from "../../helpers/SectionLoader";

function MathematicalSkill() {
	const [isEditModal, setEditModal] = useState(false);
	const [isViewModal, setViewModal] = useState(false);
	const [isDeleteModal, setDeleteModal] = useState(false);
	const [isSchoolScientistAddModal, setSchoolScientistAddModal] =
		useState(false);

	const [searchParams] = useSearchParams();

	const subject = searchParams.get("subject");
	const category = searchParams.get("category");
	const [questionObject, setQuestionObject] = useState({});
	const [sectionLoading, setSectionLoading] = useState(false);

	// =====render=======
	const [render, setRender] = useState(false);

	return (
		<section id="main">
			<SchoolScientistCategory subject={subject} category={category} />
			<SchoolScientistQuestions
				isSchoolScientistAddModal={isSchoolScientistAddModal}
				setSchoolScientistAddModal={setSchoolScientistAddModal}
			/>
			<QuestionCategoryUP subject={subject} category={category} />
			<MathematicalList
				setQuestionObject={setQuestionObject}
				subject={subject}
				category={category}
				isEditModal={isEditModal}
				setEditModal={setEditModal}
				isDeleteModal={isDeleteModal}
				setDeleteModal={setDeleteModal}
				setSectionLoading={setSectionLoading}
				sectionLoading={sectionLoading}
				render={render}
				isViewModal={isViewModal}
				setViewModal={setViewModal}
			/>
			<SchoolScientistsAddModal
				isSchoolScientistAddModal={isSchoolScientistAddModal}
				setSchoolScientistAddModal={setSchoolScientistAddModal}
				setRender={setRender}
				render={render}
			/>
			<SchoolScientistEditModal
				isEditModal={isEditModal}
				isViewModal={isViewModal}
				setViewModal={setViewModal}
				setEditModal={setEditModal}
				questionObject={questionObject}
				setSectionLoading={setSectionLoading}
				setRender={setRender}
				render={render}
			/>
			<SchoolScientistDeleteModal
				isDeleteModal={isDeleteModal}
				setDeleteModal={setDeleteModal}
				questionObject={questionObject}
				setSectionLoading={setSectionLoading}
				setRender={setRender}
				render={render}
			/>
		</section>
	);
}

export default MathematicalSkill;
