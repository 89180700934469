import {NavLink } from "react-router-dom";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from '../../contexts/Store';
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
//components

function AttendedCount({count, isLoading}) {
    return (
        <Container>  
            <Heading>Attended</Heading>
            <MainCards>
                <CountCard className="club" to="/school-scientist/students/">
                    <TextProgram >Total Attended</TextProgram>
                    <ProgramCount>
                        {isLoading ? 
                            <ButtonLoader />
                        :  
                            count.total_attended
                        }

                    </ProgramCount>
                </CountCard>
                <CountCard>
                    <TextProgram >UP</TextProgram>
                    <ProgramCount>
                        {isLoading ?
                            <ButtonLoader />
                        :
                            count.attended_up_students
                        }
                    </ProgramCount>
                </CountCard>
                <CountCard>
                    <TextProgram >HS</TextProgram>
                    <ProgramCount>
                        {isLoading ? 
                            <ButtonLoader />
                        :
                            count.attended_hs_students
                        }  
                    </ProgramCount>
                </CountCard>
                <CountCard>
                    <TextProgram >HSS</TextProgram>
                    <ProgramCount>
                        {isLoading ? 
                            <ButtonLoader />
                        :
                            count.attended_hss_students
                        }
                    </ProgramCount>
                </CountCard>
            </MainCards>
        </Container>
    );
}

export default AttendedCount;

const Container = styled.div`
 
`;

const MainCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    width: 100%;
    border-radius: 8px;
    @media (max-width: 980px) {
        grid-template-columns:  1fr 1fr;
        width: 100%;
    }
    @media (max-width: 360px) {
        grid-template-columns:  1fr ;
        width: 100%;
    }
    
`;
const Heading = styled.h1`
    font-size: 18px;
    color: rgb(255,255,255);
    font-family: "gordita_medium";
    margin-bottom: 20px;
    margin-top: 20px;
`;

const CountCard = styled.div`
    background: #1b1c20;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 104px;
    justify-content: center;
    border-radius: 8px;
    padding: 0 20px;
`;
const TextProgram = styled.h4`
    color: rgb(255, 255, 255);
    font-size: 14px;
    text-align: center;
`;
const ProgramCount = styled.h2`
   color: rgb(255, 255, 255);
    margin-top: 5px;
    font-family: "gordita_medium";
    font-size: 20px;
`;
