import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TechUpdateTop from "../../includes/content-manager/TechUpdateTop";
import TechUpdatesEditor from "../../includes/content-manager/TechUpdatesEditor";
import TechUpdateNotification from "../../includes/content-manager/modal/TechUpdateNotification";
import { learnConfig, notificationsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function ContentEditor() {
    const {
        state: {
            notification_modal_data: { isModal, new_notification },
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);
    const [notificationData, setNotificationData] = useState([]);
    const [fetchNotification, setFetchNotification] = useState(false);

    const [articlePK, setArticlePK] = useState(null);

    const updateNotificationCount = (data) => {
        let count = 0;
        data.map((item) => {
            if (!item.is_read) {
                count += 1;
            }
        });
        dispatch({
            type: "UPDATE_TECH_NOTIFICATION",
            notification_modal_data: {
                count: count,
            },
        });
    };

    const fetchNotifications = () => {
        learnConfig
            .get("/tech-updates/get-notifications/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setNotificationData(data);
                    updateNotificationCount(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const markAllAsRead = () => {
        notificationsConfig
            .get("/main/mark-all-read/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setFetchNotification((prev) => !prev);
                } else {
                }
            })
            .catch((error) => {});
    };

    const markAsRead = (dataPk) => {
        notificationsConfig
            .get(`/main/mark-as-read/${dataPk}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setFetchNotification((prev) => !prev);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotification, new_notification]);

    return (
        <Container id="main">
            <Title>Tech Updates</Title>
            <TechUpdateTop />
            <TechUpdateNotification
                isFilter={isModal}
                notificationData={notificationData}
                readAll={markAllAsRead}
                markAsRead={markAsRead}
                setArticlePK={setArticlePK}
                articlePK={articlePK}
            />
            <Middle>
                <TechUpdatesEditor />
            </Middle>
        </Container>
    );
}

export default ContentEditor;

const Container = styled.section``;

const Title = styled.h3`
    color: #fff;
    font-size: 20px;
    font-family: gordita_medium;
    margin-bottom: 20px;
`;

const Middle = styled.div``;
