import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import SectionLoader from "../../../helpers/SectionLoader";
import NoData from "../../jobdesk/NoData";
import ReactPaginate from "react-paginate";

function ReadDetailsModal({
    isRead,
    setRead,
    readData,
    setReadData,
    readLoader,
    reactionCount,
    setReactionCount,
    pagination,
    setPageNumber,
}) {
    const wrapperRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(0);
    // const [searchParams, setSearchParams] = useSearchParams();
    // const [currentPage, setCurrentPage] = useState(0);
    // const currentParams = Object.fromEntries([...searchParams]);

    useEffect(() => {
        if (isRead) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isRead]);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setPageNumber(selectedPage + 1);
    };

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setRead(false);
                    setReadData("");
                    setReactionCount({});
                }
            }

            if (isRead) {
                document.addEventListener("mousedown", handleClickOutside);
            }

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, isRead, setRead]);
    }

    useOutsideClick(wrapperRef);
    return (
        <TopFilter>
            <CoverSet ref={wrapperRef}>
                <Popup className={isRead ? "dropdown" : ""}>
                    <div>
                        <Head>
                            <HeadText>
                                Read User
                                <SubText>
                                    <span>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-10-2023/react-three.svg"
                                            alt=""
                                        />
                                    </span>
                                    <small>
                                        {reactionCount.good
                                            ? reactionCount.good
                                            : 0}
                                    </small>
                                    <span>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-10-2023/react-two.svg"
                                            alt=""
                                        />
                                    </span>
                                    <small>
                                        {reactionCount.neutral
                                            ? reactionCount.neutral
                                            : 0}
                                    </small>
                                    <span>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-10-2023/react-one.svg"
                                            alt=""
                                        />
                                    </span>
                                    <small>
                                        {reactionCount.bad
                                            ? reactionCount.bad
                                            : 0}
                                    </small>
                                </SubText>
                            </HeadText>

                            <CloseIcon
                                onClick={() => {
                                    setRead(false);
                                    setReadData("");
                                    setReactionCount({});
                                }}
                            >
                                <img
                                    src={
                                        require("../../../../assets/images/Historyclose.svg")
                                            .default
                                    }
                                />
                            </CloseIcon>
                        </Head>
                        <SectionContainer>
                            {readLoader ? (
                                <SectionLoader />
                            ) : (
                                <>
                                    {readData.length > 0 ? (
                                        <ContentContainer>
                                            {readData.map((data, index) => (
                                                <Container>
                                                    <SubContainer key={index}>
                                                        <Small>
                                                            {pagination?.first_item +
                                                                index}
                                                        </Small>
                                                        <TextContainer>
                                                            <Name>
                                                                {data.name}
                                                            </Name>
                                                            <Message>
                                                                {data.program}
                                                            </Message>
                                                        </TextContainer>
                                                    </SubContainer>

                                                    <ReactionIconSpan>
                                                        {data.reaction ===
                                                        "good" ? (
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-10-2023/react-three.svg"
                                                                alt=""
                                                            />
                                                        ) : data.reaction ===
                                                          "bad" ? (
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-10-2023/react-one.svg"
                                                                alt=""
                                                            />
                                                        ) : data.reaction ===
                                                          "neutral" ? (
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-10-2023/react-two.svg"
                                                                alt=""
                                                            />
                                                        ) : null}
                                                    </ReactionIconSpan>
                                                </Container>
                                            ))}
                                        </ContentContainer>
                                    ) : (
                                        <ContentContainer>
                                            <NoData />
                                        </ContentContainer>
                                    )}
                                </>
                            )}
                        </SectionContainer>
                    </div>
                    {/* <ContentContainer>
                        {readData.map((data, index) => (
                            <Container>
                                <SubContainer key={index}>
                                    <Small>{index + 1}</Small>
                                    <TextContainer>
                                        <Name>{data.name}</Name>
                                        <Message>{data.program}</Message>
                                    </TextContainer>
                                </SubContainer>
                            </Container>
                        ))}
                    </ContentContainer> */}
                    {pagination?.total_pages > 0 && (
                        <PaginationContainer>
                            <PaginationText>
                                {pagination?.last_item} of{" "}
                                {pagination?.total_items}
                            </PaginationText>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={pagination?.total_pages}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />
                        </PaginationContainer>
                    )}
                </Popup>
            </CoverSet>
        </TopFilter>
    );
}

export default ReadDetailsModal;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    & ul.pagination a {
        width: 34px;
        height: 34px;
    }
    & ul.pagination li.previous a.pagination__link {
        width: 34px;
    }

    @media all and (max-width: 890px) {
        flex-direction: column;
        padding: 0 10px;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;

const SectionContainer = styled.div`
    max-height: 76vh;
    min-height: 76vh;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1380px) {
        max-height: 76vh;
        min-height: 76vh;
    }
    @media all and (max-width: 980px) {
        min-height: 74vh;
        max-height: 74vh;
    }
`;

const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;
const Popup = styled.div`
    min-height: 100vh;
    font-size: 14px;
    color: #fff;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 480px;
    top: 59px;
    right: -900px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        overflow: visible;
        background: #161619;
        border-radius: 6px;
        border: 1px solid #2c2c2c;
        /* max-height: auto; */
        transition: 0.5s ease;
        /* min-height: 100%; */
        right: 0;
        top: 59px;
        /* overflow-y: scroll;
        height: 100%; */
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const Small = styled.small`
    margin-right: 14px;
    display: inline-block;
    font-size: 15px;
    font-family: gordita_medium;
`;
const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #3b3b3b;
`;

const CloseIcon = styled.div`
    width: 16px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const HeadText = styled.div`
    font-size: 18px;
    font-family: "gordita_medium";
`;

const SubText = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;

    gap: 10px;
    span {
        display: inline-block;
        width: 20px;
        img {
            display: block;
            width: 100%;
        }
    }
    small {
        font-family: "gordita_medium";
        font-size: 12px;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    border-bottom: 1px solid #2e2e2e;
`;
const SubContainer = styled.div`
    display: flex;
    /* align-items: center; */
`;
const Dot = styled.div`
    margin-right: 15px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ show }) => (show ? "#fe673a" : "transparent")};
`;
const ProfileIcon = styled.div`
    margin-right: 15px;
`;
const ImgSpan = styled.span`
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
`;
const TextContainer = styled.div``;

const Name = styled.h4`
    font-size: 16px;
    font-family: "gordita_medium";
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 4px;
`;

const Message = styled.p`
    font-size: 14px;
    line-height: normal;
    font-family: "gordita_medium";
    text-transform: capitalize;
    color: #fe673a;
`;

const Time = styled.small`
    font-size: 12px;
    font-family: "gordita_regular";
    color: #707070;
`;

const Button = styled(Link)`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    display: flex;
    padding: 8px 8px;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 19%;
    color: #fe673a;
    font-size: 12px;
    font-family: "gordita_regular";
    cursor: pointer;
`;

const ThumbnailImageSpan = styled.span`
    display: inline-block;
    width: 18%;
`;

const ThumbnailImage = styled.img`
    display: block;
    width: 100%;
`;
const ContentContainer = styled.div`
    overflow-y: scroll;
    max-height: 90vh;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const ReactionIconSpan = styled.span`
    display: inline-block;
    width: 28px;
    img {
        display: block;
        width: 100%;
    }
`;
