import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//components
const Dashboard = lazy(() => import("../../../screens/sat-agent/Dashboard"));
const Settlement = lazy(() => import("../../../screens/sat-agent/Settlement"));
const FinanceReport = lazy(() =>
    import("../../../screens/sat-agent/FinanceReport")
);
const AEPRouter = lazy(() => import("../aep/AEPRouter"));

function SatAgentRouter() {
    return (
        <div id="main">
            <Routes>
                <Route path="/" element={<Navigate to="/dashboard/" />} />
                <Route path="dashboard/" element={<Dashboard />} />
                <Route path="aep/*" element={<AEPRouter />} />
                <Route path="finance-report" element={<FinanceReport />} />
                <Route path="settlement" element={<Settlement />} />
            </Routes>
        </div>
    );
}

export default SatAgentRouter;
