import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TechDegreeStudentList from "../../../includes/examiner/TechDegreeStudentList";
import TechGradStudentCard from "../../../includes/examiner/TechGradStudentCard";
import TechGradStudentList from "../../../includes/examiner/TechGradStudentList";
import TechSchoolingStudentList from "../../../includes/examiner/TechSchoolingStudentList";

const ScoreBoard = lazy(() => import("../../../screens/examiner/ScoreBoard"));
const ExaminerDashboard = lazy(() =>
    import("../../../screens/examiner/ExaminerDashboard")
);
const ExaminerStudents = lazy(() =>
    import("../../../screens/examiner/ExaminerStudents")
);

function ExaminerRouter() {
    return (
        <Routes>
            <Route path="/" element={<TechSchoolingStudentList />} />
            <Route path="tech-degree/" element={<TechDegreeStudentList />} />
            <Route path="tech-grade/" element={<TechGradStudentList />} />

            <Route
                path=":examcenter_id/students/"
                element={<ExaminerStudents />}
            />
            <Route
                path=":student_sat_id/scoreboard/"
                element={<ScoreBoard />}
            />
        </Routes>
    );
}

export default ExaminerRouter;
