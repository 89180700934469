import React, { useState, useRef, useEffect, useContext, lazy } from "react";
import styled from "styled-components";
// import ParentAppCard from "../../includes/operations-head/ParentAppCard";
import { activityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useSearchParams } from "react-router-dom";
import SectionLoader from "../../helpers/SectionLoader";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

import jsPDF from "jspdf";
import "jspdf-autotable";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import StatusCard from "../../includes/follow-up/StatusCard";
import SteypHelmet from "../../helpers/SteypHelmet";

export default function CallStatus() {
    const [isDetailsLoading, setDetailsLoading] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const [isActivityLoading, setActivityLoading] = useState(false);
    const [activityList, setActivityList] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);

    const [currentPage, setCurrentPage] = useState(1);

    const [pagination, setPagination] = useState(null);

    const [PDFdata, setPDFdata] = useState([]);
    const [isDownloadLoader, setDownloadLoader] = useState(false);
    const [isAssigned, setAssigned] = useState(false);

    const [isSearchBar, setSearchBar] = useState(false);
    const [searchWord, setSearchWord] = useState("");

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    // useEffect(() => {
    //     const setInitialSearch = () => {
    //         const page = searchParams.get("page");

    //         setCurrentPage(page ? page : 1);
    //         if (!searchWord.length > 0) {
    //             setSearchParams({});
    //         }
    //     };
    //     setInitialSearch();
    // }, [searchParams, searchWord]);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    // const getSearchParams = () => {
    //     const filterParams = {};
    //     searchParams.get("page") &&
    //         (filterParams.page = searchParams.get("page"));
    //     searchParams.get("q") && (filterParams.q = searchParams.get("q"));
    //     return filterParams;
    // };
    useEffect(() => {
        const fetchStudentDetails = () => {
            setDetailsLoading(true);
            // let params = getSearchParams();
            activityConfig
                .get("followups/parent-call-requests/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        page: currentPage,
                    },
                })
                .then((response) => {
                    let { StatusCode, data, pagination_data } = response.data;
                    if (StatusCode === 6000) {
                        setStudentDetails(data);
                        setPDFdata(data);
                        setPagination(pagination_data);
                        setDetailsLoading(false);
                    } else {
                        setDetailsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setDetailsLoading(false);
                });
        };
        fetchStudentDetails();
    }, [currentPage, isAssigned, searchParams]);

    const generatePDF = () => {
        setDownloadLoader(true);

        const columnWidths = [30, 50, 40, 10, 40, 40, 60, 60, 60];
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);
        const title = "Follow Up Report";

        const headers = [
            [
                "No",
                "Name",
                "Phone",
                "Category",
                "Reason",
                "Date & Time",
                "Feedback",
                "Call status",
            ],
        ];
        const body = PDFdata.map((student, index) => [
            pagination.first_item + index,
            student?.student_details?.name
                ? student?.student_details?.name.toLowerCase()
                : "--",
            student?.student_details?.contact_number
                ? student?.student_details?.contact_number
                : "--",
            student?.student_details?.category
                ? student?.student_details?.category
                : "--",
            student?.reason,
            student?.call_requested_date + student?.call_requested_time,
            student?.feedback ? student?.feedback : "--",
            student?.status ? student?.status : "No Status",

            student.reason ? student.reason : "--",
        ]);
        const content = {
            startY: 50,
            head: headers,
            body: body,
            columnWidths: columnWidths,
        };
        if (!isDownloadLoader) {
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("call-request-reports.pdf");
        }
        setDownloadLoader(false);
    };

    return (
        <MainContainer id="main">
            <SteypHelmet title="Follow up " />
            <Heading>Follow up</Heading>
            <Top>
                <TopLeft></TopLeft>
                <TopRight>
                    {/* <RightContainer
                        className={isSearchBar ? "search-active" : ""}
                    >
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchWord}
                            onChange={(e) => setSearchWord(e.target.value)}
                        ></input>
                        <ImageContainer
                            to={`?q=${searchWord}`}
                            onClick={() => {
                                setSearchBar(false);
                            }}
                        >
                            <img
                                src={
                                    require("../../../assets/images/school-scientist/search.svg")
                                        .default
                                }
                                alt="Search"
                            />
                        </ImageContainer>
                    </RightContainer> */}
                    <DownloadButton
                        className={isDownloadLoader && "active"}
                        onClick={generatePDF}
                    >
                        {isDownloadLoader ? <ButtonLoader /> : "Download"}
                    </DownloadButton>
                </TopRight>
            </Top>
            <TableCover>
                <TableHeading>
                    <Items type="odd">No</Items>
                    <Items>Name</Items>

                    <Items type="even">Category</Items>
                    <Items type="even">Reason</Items>
                    <Items type="">Date & Time</Items>
                    <Items type="even">Feedback</Items>

                    <Items type="even">Call status</Items>
                    <Items type="even">Action</Items>
                </TableHeading>
                {isDetailsLoading ? (
                    <SectionLoader />
                ) : studentDetails.length > 0 ? (
                    studentDetails.map((student, index) => (
                        <StatusCard
                            key={index}
                            student={student}
                            activityList={activityList}
                            index={index}
                            pagination={pagination}
                            isActivityLoading={isActivityLoading}
                            setAssigned={setAssigned}
                            isAssigned={isAssigned}
                        />
                    ))
                ) : (
                    <ImageBox>
                        <CardImage
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                            alt="Image"
                        />
                        <p>No Data Found</p>
                    </ImageBox>
                )}
            </TableCover>
            {pagination &&
                pagination?.total_pages > 1 &&
                studentDetails.length > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            Showing students {pagination?.first_item} -{" "}
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"< Prev"}
                            nextLabel={"Next >"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
        </MainContainer>
    );
}

const MainContainer = styled.div`
    color: #fff;
`;
const TableCover = styled.div`
    padding: 20px 20px 0 20px;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Heading = styled.h3`
    font-family: "gordita_medium";
    font-size: 22px;
    margin-bottom: 10px;
`;
const Top = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
    display: flex;
    align-items: center;
`;
const DownloadButton = styled.div`
    text-transform: capitalize;
    padding: 10px 30px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: #fe673a;
    color: #ffff;
    border-radius: 8px;
    margin-right: 20px;
    height: 40px;
    width: 130px;
    &.status {
        width: 15%;
    }
    &.active {
        min-width: 130px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: not-allowed;
    }
`;
const TableHeading = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    min-width: 1100px;
`;
const Items = styled.li`
    font-size: 14px;

    width: ${({ type }) =>
        type === "odd" ? "5%" : type === "even" ? "10%" : "15%"};
    :last-child {
        text-align: center;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    margin-top: 170px;
    height: calc(100% - (-50px));
    margin-bottom: 170px;
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;
const CardImage = styled.img`
    width: 100%;
    display: block;
`;

const RightContainer = styled.div`
    background: rgb(27, 28, 32);
    display: flex;
    color: rgb(255, 255, 255);
    align-items: center;
    margin-right: 10px;
    padding: 5px;
    cursor: pointer;
    input {
        color: #fff;
        width: 90%;
        height: 40px;
        padding: 10px;
        background: rgb(22, 22, 25);
        border-radius: 5px;
        width: 200px;
        margin-right: 10px;
        @media all and (max-width: 768px) {
            height: 33px;
        }
    }
    @media all and (max-width: 480px) {
        display: none;
        position: absolute;
        right: 10px;
        &.search-active {
            display: flex;
        }
    }
`;
const ImageContainer = styled(Link)`
    background: rgb(22, 22, 25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    padding: 10px 0px;
    cursor: pointer;
    img {
        display: block;
        width: 20px;
    }
    @media all and (max-width: 768px) {
        padding: 6px 0px;
    }
`;
