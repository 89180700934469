import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { Context } from "../../contexts/Store";

function AdmissionMabagerSideBar({
	setSubMenu,
	isExpand,
	setExpand,
	wrapperRef,
	setSyllabusMenu,
	setSubCommunity,
	setSubJobDeskMenu,
}) {
	const { state } = useContext(Context);
	let active = true;

	return (
		<Container
			id="menu"
			ref={wrapperRef}
			className={isExpand && "expand"}
			onMouseEnter={() => setExpand(true)}
			onMouseLeave={() => setExpand(false)}
		>
			<Button
				to="/admission-manager/students/"
				className="sub-menu"
				onClick={() => {
					setSubMenu(false);
					setSyllabusMenu(false);
					setSubCommunity(false);
					setSubJobDeskMenu(false);
				}}
			>
				<Menu className="menu-item">
					<MenuItemIcon
						src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/tefunicon.svg"
						alt="Icon"
					/>
					<MenuItemIcon1
						src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/tefun.svg"
						alt="Icon"
					/>
					<Title>Students</Title>
				</Menu>
			</Button>
		</Container>
	);
}

export default AdmissionMabagerSideBar;

const Sub = styled.div`
	display: flex;
	flex-direction: column;
	background: #161616;
`;

const Title = styled.span`
	font-family: "gordita_regular";
	color: inherit;
	font-size: 16px;
	color: #fff;
	font-weight: 500;
	white-space: nowrap;
	@media only screen and (max-width: 1550px) {
		display: none;
	}
	@media only screen and (max-width: 768px) {
		display: block;
	}
	&.selected {
		color: #f7684c;
	}
`;
const SubTitle = styled.p`
	font-family: "gordita_medium";
	font-size: 14px;
	white-space: nowrap;
	color: #fff;
	@media only screen and (max-width: 1550px) {
		display: none;
	}
	@media only screen and (max-width: 768px) {
		display: block;
	}
	&.selected {
		color: #1ec569;
	}
`;
const Menu = styled.div`
	padding: 10px 21px;
	display: flex;
	align-items: baseline;
	width: 100%;
	position: relative;
	transition: all 0.4s ease;
`;
const SubMenu = styled.div`
	padding: 3px;
	display: flex;
	align-items: baseline;
	width: 100%;
	position: relative;
`;
const Dot = styled.span`
	display: block;
	width: 12px;
	height: 12px;
	background-color: #fff;
	transform: translate(0px, -1px);
	margin-right: 12px;
	border-radius: 12px;
`;
const MenuItemIcon = styled.img`
	display: block;
	width: 16px;
	margin-right: 1.125rem;
	@media only screen and (max-width: 768px) {
		margin-right: 1.125rem;
	}
`;

const MenuItemIcon1 = styled.img`
	display: none;
	width: 16px;
	margin-right: 1.125rem;
	@media only screen and (max-width: 1550px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 768px) {
		margin-right: 1.125rem;
	}
`;
const ArrowImageContainer = styled.img`
	display: block;
	width: 16px;
	transform: rotate(-90deg);
	transition: all 0.4s ease;
	@media only screen and (max-width: 768px) {
		margin-right: 1.125rem;
	}
`;

const ArrowImageContainer1 = styled.img`
	display: none;
	width: 16px;
	/* margin-right: 1.125rem; */
	transform: rotate(0deg);
	@media only screen and (max-width: 1550px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 768px) {
		margin-right: 1.125rem;
	}
`;
const DownIcon = styled.img``;
const DownIcon1 = styled.img``;

const Button = styled(NavLink)`
	width: -webkit-fill-available;
	height: 46px;
	display: flex;
	color: #fff;
	transition: all 0.4s ease;
	border-left: 5px solid #fe673a;
	color: #1ec569 !important;
	background: #161616;
	${MenuItemIcon} {
		display: none;
	}
	${MenuItemIcon1} {
		display: block;
	}
	${DownIcon} {
		display: none;
	}
	${DownIcon1} {
		display: block;
	}

	${Title} {
		color: #f7684c;
	}
	${ArrowImageContainer} {
		display: none;
	}
	${ArrowImageContainer1} {
		display: block;
	}
	/* &.active {
		border-left: 5px solid #fe673a;
		color: #1ec569 !important;
		background: #161616;
		${MenuItemIcon} {
			display: none;
		}
		${MenuItemIcon1} {
			display: block;
		}
		${DownIcon} {
			display: none;
		}
		${DownIcon1} {
			display: block;
		}

		${Title} {
			color: #f7684c;
		}
		${ArrowImageContainer} {
			display: none;
		}
		${ArrowImageContainer1} {
			display: block;
		}
	} */
`;

const SubButton = styled(NavLink)`
	display: flex;
	align-items: center;
	padding: 10px 20px 10px 35px;
	width: -webkit-fill-available;
	height: 46px;
	transition: all 0.4s ease;
	&.active {
		color: red;

		${MenuItemIcon} {
			display: none;
		}
		${MenuItemIcon1} {
			display: block;
		}
		${DownIcon} {
			display: none;
		}
		${DownIcon1} {
			display: block;
		}
		${SubTitle} {
			color: #f7684c;
		}
		${Dot} {
			background-color: #f7684c;
		}
		transition: all 0.4s ease;
	}
`;

const Container = styled.aside`
	&.expand {
		width: 218px;
		transition: all 0.4s ease;
		${Title} {
			display: block;
		}
		${SubTitle} {
			display: block;
		}
		${Menu} {
		}
		${MenuItemIcon} {
			margin-right: 1.125rem;
		}
		${MenuItemIcon1} {
			margin-right: 1.125rem;
		}
		${Button} {
		}
	}
`;
