import React, { useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Context } from "../../../contexts/Store";

const EmployRoute = ({ children }) => {
	const { state } = useContext(Context);
	const role = state.user_data.role;

	const [searchParams] = useSearchParams();
	const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";

	return role.includes("verification_manager") ? children : <h1>404</h1>;
};

export default EmployRoute;
