import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import { useParams } from "react-router-dom";
import GroupInfo from "../../includes/groups/GroupInfo";
import { accountsConfig, learnConfig } from "../../../axiosConfig";
import SectionLoader from "../../helpers/SectionLoader";
import SuccessModal from "../meet/trainer/modals/TrainerSuccess";
import MembersInfo from "../../includes/groups/MembersInfo";

function GroupInner() {
    const [showModal, setShowModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [studenModal, setShowstudentModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [groupData, setGroupData] = useState("");
    const [isReload, setReload] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const { group_id } = useParams();

    useEffect(() => {
        fetchData();
        // fetchMembers();
        setReload(false);
    }, [isReload]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await learnConfig.get(`events/group/${group_id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            const { status_code, data } = response.data;

            if (status_code === 6000) {
                setGroupData(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <SuccessModal
                setReload={setReload}
                isReload={isReload}
                successful={successful}
                setSuccessful={setSuccessful}
            />
            <MainContainer>
                <LeftContainer>Groups info</LeftContainer>
                <GroupInfo
                    setShowModal={setShowModal}
                    showStatusModal={showStatusModal}
                    setShowStatusModal={setShowStatusModal}
                    showModal={showModal}
                    groupData={groupData}
                    loading={loading}
                    setReload={setReload}
                    isReload={isReload}
                    successful={successful}
                    setSuccessful={setSuccessful}
                    studenModal={studenModal}
                    setShowstudentModal={setShowstudentModal}
                />
                <LeftContainer>Group Members</LeftContainer>
                <MembersInfo isReload={isReload} setReload={setReload} />
            </MainContainer>
        </>
    );
}

export default GroupInner;

const MainContainer = styled.div`
    width: 100%;
    color: white;
`;
const LeftContainer = styled.div`
    font-size: 18px;
    font-family: "gordita_medium";
`;
