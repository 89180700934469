import React from "react";
import styled from "styled-components";

function SchoolScientistQuestions({
	isSchoolScientistAddModal,
	setSchoolScientistAddModal,
}) {
	return (
		<>
			<ButtonContainer>
				<Text>Questions</Text>
				<Button
					onClick={() => {
						setSchoolScientistAddModal(true);
					}}
				>
					<ImageDiv>
						<img
							src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/plus.svg"
							alt="AddButton"
						/>
					</ImageDiv>
					<Span>Add</Span>
				</Button>
			</ButtonContainer>
		</>
	);
}
// c
const ButtonContainer = styled.div`
	margin: 30px 0px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Text = styled.h5`
	font-size: 18px;
	color: #ffffff;
	font-family: "gordita_medium";
    @media all and (max-width: 1280px) {
		font-size: 17px;
	}
    @media all and (max-width: 768px) {
		font-size: 16px;
	}
`;
const Button = styled.div`
	border: 1px solid #fe673a;
	color: #fff;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fe673a;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	justify-content: center;
	&:hover {
	}
`;
const ImageDiv = styled.div`
	width: 20px;
	margin-right: 5px;
	img {
		width: 100%;
		display: block;
		color: #fe673a;
	}
`;
const Span = styled.h5`
	font-size: 14px;
	color: #ffffff;
	font-family: "gordita_medium";
    @media all and (max-width: 768px) {
		font-size: 13px;
	}
`;

export default SchoolScientistQuestions;
