import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const CareerAdvisorDashboard = lazy(() =>
    import("../../../screens/career-advisor/CareerAdvisorDashboard")
);
const CareerAdvisorFollowups = lazy(() =>
    import("../../../screens/career-advisor/CareerAdvisorFollowups")
);
const CareerAdvisorStatusAll = lazy(() =>
    import("../../../screens/career-advisor/CareerAdvisorStatusAll")
);
const CareerAdvisorStatusRejected = lazy(() =>
    import("../../../screens/career-advisor/CareerAdvisorStatusRejected")
);

function CareerAdvisorRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard/" />} />
            <Route path="/dashboard/" element={<CareerAdvisorDashboard />} />
            <Route path="/status/all/" element={<CareerAdvisorStatusAll />} />
            <Route
                path="/status/rejected/"
                element={<CareerAdvisorStatusRejected />}
            />
            <Route path="/followups/" element={<CareerAdvisorFollowups />} />
        </Routes>
    );
}

export default CareerAdvisorRouter;
