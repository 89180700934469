import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import { primeConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useParams } from "react-router-dom";
import { getYYMMDD } from "../../helpers/functions";

function Filter({
    show,
    setShow,
    setLoading,
    setClearFilter,
    appliedDate,
    setAppliedDate,
    selectedStatus,
    setSelectedStatus,
    selectedCoupon,
    setSelectedCoupon,
    fetchPrimeData,
}) {
    const { state } = useContext(Context);

    const [isCoupon, setCoupon] = useState(false);
    const [couponStatus, setCouponStatus] = useState([]);
    const { primeprogramme_id } = useParams();
    const [studentStatus, setStudentStatus] = useState([
        { id: 1, status: "Completed" },
        { id: 1, status: "Ongoing" },
    ]);

    const handleEnrolledDate = (d) => {
        const date = getYYMMDD(d);
        setAppliedDate(date);
        // history.push({
        //     pathname: `/prime-programs/${primeprogramme_id}/`,
        //     search: `?enrolled_time=${date}`,
        // });
    };

    useEffect(() => {
        const fetchData = () => {
            const { user_data } = state;
            const { access_token } = user_data;
            primeConfig
                .get("coupons/sro-all-coupons/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setCouponStatus(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        };
        fetchData();
    }, []);

    return !show ? null : (
        <FilterModal>
            <FilterDataContainer>
                <FilterModalHead>Purchased On</FilterModalHead>
                <DateLabel
                    style={{
                        fontSize: 14,
                        cursor: "pointer",
                    }}
                >
                    <DatePicker
                        onChange={(value) => handleEnrolledDate(value)}
                        value={appliedDate ? new Date(appliedDate) : ""} //when day is clicked
                    />
                </DateLabel>
            </FilterDataContainer>
            <FilterDataContainer>
                <FilterModalHead>Status</FilterModalHead>
                <Status>
                    {studentStatus.map((data) => (
                        <DateCard
                            onClick={() => {
                                setSelectedStatus(data.status);
                            }}
                        >
                            <Circle>
                                <Dot
                                    className={
                                        selectedStatus === data.status
                                            ? "selected"
                                            : "null"
                                    }
                                ></Dot>
                            </Circle>
                            <DateTitle>{data.status}</DateTitle>
                        </DateCard>
                    ))}
                </Status>
            </FilterDataContainer>
            <FilterDataContainer>
                <FilterModalHead>Coupon</FilterModalHead>
                <CouponSection
                    onClick={() => {
                        setCoupon(!isCoupon);
                    }}
                    className={isCoupon ? "up" : "null"}
                >
                    <FilterModalSelect
                        className={
                            selectedCoupon === "Select" ? "select" : "null"
                        }
                    >
                        {selectedCoupon}
                    </FilterModalSelect>
                    <Arrow src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/right-arrow.svg" alt="" width={6} />
                </CouponSection>
                {isCoupon && (
                    <Status>
                        {couponStatus.map((data) => (
                            <CouponCard
                                onClick={() => {
                                    setSelectedCoupon(data.code);
                                    setCoupon(!isCoupon);
                                }}
                                className={
                                    selectedCoupon === data.code
                                        ? "selected"
                                        : "null"
                                }
                            >
                                <DateTitle>{data.code}</DateTitle>
                            </CouponCard>
                        ))}
                    </Status>
                )}
            </FilterDataContainer>
            <FilterButton
                onClick={() => {
                    setShow(false);
                    setLoading(true);
                    setClearFilter(true);
                    fetchPrimeData();
                }}
            >
                Filter
            </FilterButton>
        </FilterModal>
    );
}

export default Filter;
const FilterModal = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    width: 250px;
    background: #161619;
    border: 1px solid #474747;
    border-radius: 5px;
    padding: 15px;
    box-shadow: rgba(37, 37, 37, 0.2) 0px 8px 24px;
`;
const FilterDataContainer = styled.div`
    margin-bottom: 10px;

    div {
        width: 100%;
        .react-date-picker__wrapper {
            border-radius: 5px !important;
            padding: 5px 10px !important;
            background-color: #1b1c1f !important;
            height: 40px !important;
            border: none !important;
            font-family: "baloo_paaji_2medium" !important;
            font-size: 16px !important;
            .react-date-picker__inputGroup {
                input {
                    color: #b9b9b9 !important;
                }
            }
            .react-date-picker__clear-button {
                display: none !important;
            }
            .react-date-picker__calendar-button {
                display: none !important;
            }
        }
        .react-calendar__navigation {
            button {
                color: #fff !important;
                &:hover {
                    color: #1f2934 !important;
                }
            }
        }
        .react-date-picker__calendar {
            top: 50px !important;
            right: 65px !important;
            left: unset !important;
        }
        .react-calendar {
            background: #1f2934 !important;
            max-width: 280px !important;
            width: 280px !important;
            border-radius: 5px !important;
        }
        .react-calendar_month-view_weekdays {
            padding: 10px 10px 5px 10px !important;
        }
        .react-calendar_month-viewweekdays_weekday {
            padding: 5px !important;
        }
        .react-calendar_month-view_days {
            padding: 5px 10px 10px 10px !important;
            button {
                border-radius: 5px !important;
            }
        }
        .react-calendar_month-viewdays_day {
            padding: 5px 0 !important;
            color: #fff !important;
            &:hover {
                color: #1f2934 !important;
            }
        }
        .react-calendar__tile--now {
            background: #ffff76 !important;
            color: #1f2934 !important;

            &:hover {
                color: #1f2934 !important;
            }
        }
        .react-calendar_month-viewdays_day--weekend {
            color: #d10000 !important;
            &:hover {
                color: #d10000 !important;
            }
        }
        .react-calendar__tile--active {
            background: #15bf81 !important;
        }
        .react-calendar__tile--hasActive {
            background: unset;
        }
        .react-calendar_month-viewdays_day--neighboringMonth {
            color: #757575 !important;
        }
    }
`;
const FilterModalHead = styled.h5`
    font-family: "baloo_paaji_2medium";
    font-size: 16px;
    margin-bottom: 3px;
`;
const FilterModalSelect = styled.h5`
    font-size: 14px;
    &.select {
        color: #f5f5f5;
        font-size: 12px;
    }
`;

const FilterButton = styled.div`
    font-family: "baloo_paaji_2medium";
    font-size: 16px;
    background: #15bf81 !important;
    color: #fff;
    border-radius: 5px;
    margin-top: 15px;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
`;
const DateLabel = styled.span`
    font-size: 16px;
    width: 100%;
    /* font-family: "baloo_paaji_2medium"; */
    @media all and (max-width: 480px) {
        font-size: 13px !important;
        margin-left: 5px;
    }
    @media all and (max-width: 320px) {
        font-size: 12px !important;
    }
`;
const Arrow = styled.img`
    margin-left: 10px;
    cursor: pointer;
    transform: rotate(90deg);
    @media all and (max-width: 365px) {
        width: 5px !important;
    }
    &.up {
        transform: rotate(-90deg);
    }
`;
const CouponSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1b1c1f;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
`;
const Status = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
`;

const Circle = styled.span`
    width: 13px;
    height: 13px;
    display: flex;
    justify-content: center;
    background-color: #43231d;
    align-items: center;
    border-radius: 50%;
    border: 1px solid#3d3e42;
`;
const Dot = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &.selected {
        background-color: #fe673a;
    }
`;
const DateCard = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 3px;
    background-color: #1b1c1f;
`;
const CouponCard = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 3px;
    background-color: #1b1c1f;
    &.selected,
    &:hover {
        background-color: #fe673a;
    }
`;
const DateTitle = styled.p`
    margin-left: 5px;
    width: 80%;
    font-size: 14px;
`;
