import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import DeleteImage from "../../../assets/images/school-scientist/delete.svg";
import Edit from "../../../assets/images/school-scientist/edit.svg";
import SchoolScientistEditModal from "./Modal/SchoolScientistEditModal";
import { communityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "./NoData";
import eye from "../../../assets/images/school-scientist/eye.svg"

function MathematicalList({
	isEditModal,
	setEditModal,
	isDeleteModal,
	setDeleteModal,
	category,
	subject,
	setQuestionObject,
	sectionLoading,
	setSectionLoading,
	render,
	isViewModal,
	setViewModal,
}) {
	const {
		state: {
			user_data: { access_token },
			student_data: {},
		},
		dispatch,
	} = useContext(Context);
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		setSectionLoading(true);
		communityConfig
			.get(
				`/school-scientists/questions/?category=${category}&question_category=${subject}`,
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setQuestions(data);
					setSectionLoading(false);
				} else {
					setSectionLoading(false);
					setQuestions([]);
				}
			})
			.catch((error) => {
				console.log(error);
				setSectionLoading(false);
			});
	}, [category, subject, render]);

	return (
		<Container>
			<MainHead>
				<MainSub>
					<Contents type="sl.no">Sl.No</Contents>
					<Contents type="Question">Question</Contents>
					<Contents type="Action">Action</Contents>
				</MainSub>
			</MainHead>
			<MainContentDiv>
				{sectionLoading ? (
					<SectionLoader />
				) : questions?.length > 0 ? (
					questions.map((question, index) => {
						return (
							<SubContent>
								<MainContent>
									<Content type="sl.no">{index + 1}</Content>
									<Content type="Question">{question.english_question}</Content>
									<Content className="image" type="Action">
										<ViewDiv
											onClick={() => {
												setViewModal(true);
												setQuestionObject(question);

											}}
										>
											<img src={eye} alt="ViewImage" />	
										</ViewDiv>
										<EditDiv
											onClick={() => {
												setEditModal(true);
												setQuestionObject(question);
											}}
										>
											<img src={Edit} alt="Edit" />
										</EditDiv>
										<DeletedDiv
											onClick={() => {
												setDeleteModal(true);
												setQuestionObject(question);
											}}
										>
											<img src={DeleteImage} alt="DeleteImage" />
										</DeletedDiv>
									</Content>
								</MainContent>
							</SubContent>
						);
					})
				) : (
					<NoData />
				)}
			</MainContentDiv>
		</Container>
	);
}
const Container = styled.div`
	color: rgb(255, 255, 255);
	border-radius: 5px;
	width: 100%;
	background-color: #1b1c1f;
	max-width: 100%;
	overflow: scroll;
`;
const MainHead = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 18px;
	color: rgb(255, 255, 255);
	border-bottom: 1px solid #26272a;
	min-width: 1180px;
`;
const MainSub = styled.div`
	display: flex;
	-webkit-box-align: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
	font-size: 16px;
	text-align: left;
	color: rgb(255, 255, 255);
`;
const Contents = styled.div`
	text-transform: capitalize;
	font-family: "gordita_medium";
	font-size: 14px;
	text-align: left;
	width: ${({ type }) =>
		type === "sl.no"
			? "10%"
			: type === "Question"
			? "60%"
			: type === "Action"
			? "20%"
			: ""};
	:nth-child(3) {
		text-align: right;
	}
`;
const MainContentDiv = styled.div`
	color: rgb(255, 255, 255);
	background-color: #1b1c1f;
	border-radius: 5px;
	width: 100%;
	min-width: 1180px;
`;
const SubContent = styled.div`
	display: flex;
	flex-direction: column;
	color: rgb(255, 255, 255);
`;
const MainContent = styled.div`
	display: flex;
	-webkit-box-align: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
	font-size: 14px;
	text-align: left;
	color: rgb(255, 255, 255);
	border-bottom: 1px solid #26272a;
	font-family: "gordita_regular";
`;
const Content = styled.div`
	font-family: "gordita_regular";
	font-size: 14px;
	text-align: left;
	width: ${({ type }) =>
		type === "sl.no"
			? "10%"
			: type === "Question"
			? "60%"
			: type === "Action"
			? "20%"
			: ""};

	:nth-child(3) {
		text-align: right;
	}
	&.image {
		display: flex;
		justify-content: flex-end;
	}
	@media all and (max-width: 768px) {
		font-size: 13px;
	}
`;
const EditDiv = styled.div`
	width: 23px;
	display: flex;
	justify-content: center;
	margin-right: 15px;
	cursor: pointer;
	img {
		display: block;
		width: 100%;
	}
`;
const DeletedDiv = styled.div`
	width: 23px;
	display: flex;
	justify-content: center;
	cursor: pointer;
	img {
		display: block;
		width: 100%;
	}
`;
const ViewDiv = styled.div`
	width: 23px;
	display: flex;
	justify-content: center;
	margin-right: 15px;
	cursor: pointer;
	img {
		display: block;
		width: 100%;
	}
`;


export default MathematicalList;
