import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

function DoubtHubNavBar({ pagination }) {
    const navBars = [
        {
            id: 1,
            title: "Questions",
            button: "18",
            url: "/doubt-hub/doubts/",
        },
        {
            id: 2,
            title: "My Answers",
            url: "/doubt-hub/doubts/my-answers/",
        },
        {
            id: 3,
            title: "My Comments",
            url: "/doubt-hub/doubts/my-comments/",
        },
    ];

    return (
        <Container>
            {navBars.map((item) => (
                <NavItem key={item.id} to={item.url} activeClassName="active">
                    {item.title}{" "}
                </NavItem>
            ))}
        </Container>
    );
}

const Container = styled.nav`
    display: flex;
    width: 100%;
    padding-right: 25px;
    overflow-x: scroll;
    margin-top: 11px;
    margin-bottom: 22px;
    border-bottom: 1px solid #2e2e2e;

    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background: #e5e5e5;
        border-radius: 0.625rem;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #b7b7b7;
    }
`;
const CountButton = styled.span`
    background-color: #fe673a;
    color: #fff;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px;
`;

const NavItem = styled(NavLink)`
    cursor: pointer;
    font-family: "gordita_medium";
    border-bottom: 3px solid transparent;
    min-width: fit-content;
    display: inline-block;
    color: #717171;
    font-size: 16px;
    font-weight: 500;
    margin-right: 40px;
    &:last-child {
        margin-right: 0;
    }
    &:focus {
        outline: none;
    }
    &.active {
        color: #fe673a;
        border-bottom: 3px solid #fe673a;
    }
    &:not(.active):hover {
        color: #fe673a;
    }

    @media all and (max-width: 1380px) {
        margin-right: 34px;
    }
    @media all and (max-width: 1280px) {
        font-size: 14px;
        margin-right: 25px;
    }
    @media all and (max-width: 1080px) {
        font-size: 13px;
        margin-right: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
        margin-right: 20px;
    }
    @media (max-width: 480px) {
        font-size: 14px;
        margin-right: 30px;
    }
    @media (max-width: 360px) {
        font-size: 13px;
        margin-right: 15px;
    }
`;
export default DoubtHubNavBar;
