import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ShareDeleteModal from "../../includes/career-advisor/modal/ShareDeleteModal";
import RemoveQuestions from "../../includes/career-advisor/modal/RemoveQuestions";
import RemovedTags from "./RemovedTags";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "../../includes/jobdesk/NoData";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function CommentReport(
    editLoder,
    setModalType,
    editCategoryPK,
    setEditCategoryPK,
    setDeleteModal
) {
    const [comment, setComment] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [isDrop, setIsDrop] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [isModal, setModal] = useState(false);
    const [isShare, setShare] = useState(false);
    const [content, setContent] = useState();
    const [isPageLoading, setPageLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [activeClick, setActiveClick] = useState("Filter");

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const [counter, setCounter] = useState(0);

    const handleModal = () => {
        // setSelectedId("");
        setShare(!isShare);
        // setDescription("");
    };

    const [editedContent, setEditedContent] = useState("Type your comment");

    const handleDivClick = () => {
        setEditedContent("");
    };

    return (
        <>
            <RemoveQuestions isModal={isModal} setModal={setModal} />
            {isShare && (
                <ShareDeleteModal isShare={isShare} setShare={setShare} />
            )}

            <DoubtNavigator>
                <DoubtButton to="/doubt-hub/reports/">
                    <ArrowIcon>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2024/arrow-left.svg"
                            alt=""
                        />
                    </ArrowIcon>
                    Back to Reports
                </DoubtButton>
            </DoubtNavigator>

            <MainContainer>
                <Breadcrum>{/* <CardPathChip /> */}</Breadcrum>
                <StudentContainer>
                    <TopContainer>
                        <Heads>
                            <Heading>Comment Reports</Heading>
                            <p>Evaluate and manage the reports here</p>
                        </Heads>
                    </TopContainer>
                    <Crumb>
                        <MiddleContainer>
                            <Views>
                                How to make a great R reproducible example
                            </Views>
                            <RightContainer>
                                <AskButton
                                    onClick={(e) => {
                                        // setStudentPk(student?.pk);
                                        setModal(true);
                                    }}
                                >
                                    View Doubt
                                </AskButton>
                            </RightContainer>
                        </MiddleContainer>
                        <BottomContainers>
                            <MainHead>
                                <MainSub>
                                    <Contents type="question">
                                        Posted by
                                    </Contents>
                                    <Contents type="date">Vote Count</Contents>
                                    <Contents type="campus">Answers</Contents>
                                    <Contents type="campus">Comments</Contents>
                                    <Contents type="subscription">
                                        Tags
                                    </Contents>
                                    <Contents type="subscription">
                                        Create Date
                                    </Contents>
                                </MainSub>
                            </MainHead>
                            <UlList>
                                <Li type="ask">
                                    <StudentProfile>
                                        {" "}
                                        <StudentPhoto>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/31-01-2024/Avatar.svg"
                                                alt=""
                                            />
                                        </StudentPhoto>
                                        <StudentDetails>
                                            <StudentName>Noufal</StudentName>
                                            <StudentPosition>
                                                Tech Grad
                                            </StudentPosition>
                                        </StudentDetails>
                                    </StudentProfile>
                                </Li>
                                <Li type="question">
                                    {" "}
                                    <p>3</p>
                                </Li>

                                <Li type="date">
                                    <p>320</p>
                                </Li>
                                <li type="tags">
                                    <p>20</p>
                                </li>

                                <Li type="action">
                                    <small
                                        style={{
                                            border: "1px solid #22c55e",
                                            color: "#22c55e",
                                            padding: "6px 12px 6px 12px",
                                            borderRadius: "8px",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Tags
                                    </small>
                                </Li>
                                <Li type="action">
                                    <p>07 Feb, 24 7:00 PM</p>
                                </Li>
                            </UlList>
                        </BottomContainers>
                    </Crumb>
                </StudentContainer>
                <BorderContainer>
                    <BorderTop>
                        <Heading>Questions:</Heading>
                        <Buttons>
                            <Remove>RemoveQuestion</Remove>
                            <Evaluate>Mark as Evaluated</Evaluate>
                        </Buttons>
                    </BorderTop>
                    <QuestionContainer>
                        <Question>
                            <CommentContainer>
                                <p>
                                    I am a newbie in xamarin. Trying to build a
                                    login page but when accessing a connection
                                    to sqlite error is occurred. Object variable
                                    not set to an instance of an object.
                                </p>
                                <small>
                                    This is the line which shows error:
                                </small>
                                <Message>
                                    <CommentSection>
                                        <Input
                                            placeholder="Type your comment"
                                            // onChange={handleInputChange}
                                            value={content}
                                        ></Input>
                                    </CommentSection>
                                    <CopyButton>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-02-2024/copy-btn.svg"
                                            alt="copy"
                                        />
                                    </CopyButton>
                                </Message>
                                <Error>
                                    <MessageOne>
                                        <p>This is the line shows the error</p>
                                    </MessageOne>
                                    <MessageTwo>
                                        <p>I made SQL lite </p>
                                    </MessageTwo>
                                </Error>
                                <SecondMessage>
                                    <CommentSection>
                                        <div
                                            className="message-text"
                                            contentEditable="true"
                                            onClick={handleDivClick}
                                        >
                                            {editedContent}
                                        </div>
                                    </CommentSection>
                                    <CopyButton>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-02-2024/copy-btn.svg"
                                            alt="copy"
                                        />
                                    </CopyButton>
                                </SecondMessage>
                            </CommentContainer>
                        </Question>
                    </QuestionContainer>
                    <Container>
                        <ContentContainer>
                            <TopContainers>
                                <Search>
                                    <p>Search </p>
                                    <SearchContainer>
                                        <SearchBar>
                                            <InputField placeholder="Search for keywork student etc..." />
                                        </SearchBar>
                                        <SearchImageContainer>
                                            <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg" />
                                        </SearchImageContainer>
                                    </SearchContainer>
                                </Search>

                                <TopDiv>
                                    <NavContainer>
                                        <SortConrtainer>
                                            <span>Sort by</span>
                                            <SortIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-02-2024/up-down.svg"
                                                    alt=""
                                                />
                                            </SortIcon>
                                        </SortConrtainer>

                                        <TopFilter>
                                            <CoverSet>
                                                <Filters
                                                // onClick={() =>
                                                //     setDropdown(!isDropdown)
                                                // }
                                                >
                                                    <FilterText>
                                                        {activeClick}
                                                    </FilterText>
                                                    <FIlterImage>
                                                        <img
                                                            src={
                                                                require("../../../assets/images/bda/Filter.svg")
                                                                    .default
                                                            }
                                                            alt="Image"
                                                        />
                                                    </FIlterImage>
                                                </Filters>
                                            </CoverSet>
                                        </TopFilter>
                                    </NavContainer>
                                </TopDiv>
                            </TopContainers>

                            <MainContainers>
                                <Top>
                                    <Text type="sl_no">Sl No</Text>
                                    <Text type="title">Questions</Text>
                                    <Text type="category">Posted by </Text>
                                    <Text type="category_image">
                                        Removed by
                                    </Text>
                                    {/* <Text type="parent_category">Date and Time </Text> */}
                                    <Text type="actions">Actions</Text>
                                </Top>
                                {/*  */}
                                <DataContainer>
                                    {isPageLoading ? (
                                        <SectionLoader />
                                    ) : categories.length > 0 ? (
                                        <>
                                            {categories.map((data, index) => (
                                                <Bottom key={index}>
                                                    <Section type="sl_no">
                                                        <small>
                                                            {index < 9 &&
                                                            pagination.current_page ===
                                                                1
                                                                ? `0${
                                                                      pagination.first_item +
                                                                      index
                                                                  }`
                                                                : pagination.first_item +
                                                                  index}
                                                        </small>
                                                    </Section>
                                                    <Section type="title">
                                                        <p>
                                                            {data.title
                                                                ? data.title
                                                                : "--"}
                                                        </p>
                                                    </Section>
                                                    <Section type="category">
                                                        <p>
                                                            {data.category_type
                                                                ? data.category_type
                                                                : "--"}
                                                        </p>
                                                    </Section>
                                                    <Section type="category_image">
                                                        <span>
                                                            <img
                                                                src={
                                                                    data.featured_image
                                                                }
                                                                alt=""
                                                            />
                                                        </span>
                                                    </Section>
                                                    <Section type="parent_category">
                                                        <p>
                                                            {data.parent_category
                                                                ? data.parent_category
                                                                : "--"}
                                                        </p>
                                                    </Section>
                                                    <Section type="actions">
                                                        <BottomContainer>
                                                            <Edit
                                                            // onClick={() => {
                                                            //     setModalType("edit");
                                                            //     getCategory(data.id);
                                                            //     setEditCategoryPK(
                                                            //         data.id
                                                            //     );
                                                            // }}
                                                            >
                                                                <RestoreButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        // setStudentPk(student?.pk);
                                                                        setModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    Restore
                                                                </RestoreButton>
                                                                {editLoder &&
                                                                data.id ==
                                                                    editCategoryPK ? (
                                                                    <ButtonLoader
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <ImageBox></ImageBox>
                                                                )}
                                                            </Edit>
                                                            <Delete
                                                                onClick={() => {
                                                                    setModalType(
                                                                        "delete_category"
                                                                    );
                                                                    setDeleteModal(
                                                                        (
                                                                            prev
                                                                        ) =>
                                                                            !prev
                                                                    );
                                                                    setSelectedCategory(
                                                                        data.title
                                                                    );
                                                                    setEditCategoryPK(
                                                                        data.id
                                                                    );
                                                                }}
                                                            >
                                                                Delete
                                                            </Delete>
                                                        </BottomContainer>
                                                    </Section>
                                                </Bottom>
                                            ))}
                                        </>
                                    ) : (
                                        <NoData />
                                    )}
                                </DataContainer>
                            </MainContainers>
                        </ContentContainer>
                        {/* {pagination?.total_pages > 0 && (
                            <PaginationContainer>
                                <PaginationText>
                                    {pagination?.last_item} of{" "}
                                    {pagination?.total_items}
                                </PaginationText>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pagination?.total_pages}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={
                                        "pagination__link--disabled"
                                    }
                                    activeClassName={"pagination__link--active"}
                                />
                            </PaginationContainer>
                        )} */}
                    </Container>
                </BorderContainer>
            </MainContainer>
        </>
    );
}
const DoubtButton = styled(Link)`
    color: #fe673a;
    font-size: 13px;
    display: flex;
    align-items: center;
`;
const ArrowIcon = styled.div`
    width: 20px;
    margin-right: 5px;
    img {
        width: 100%;
        display: block;
        /* filter: hue-rotate(90deg); */
        /* transform: rotate(180deg); */
    }
`;
const DoubtNavigator = styled.div`
    padding: 0px 160px;
`;
const MainContainer = styled.div`
    overflow-y: scroll;
    max-height: calc(93vh - 80px);
    min-height: calc(93vh - 80px);
    padding: 10px 160px;
`;
const Breadcrum = styled.div`
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const StudentContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid #2c2c2c;
    overflow-x: scroll;
    /* margin-top: 12px; */

    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background: #e5e5e5;
        border-radius: 0.625rem;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #b7b7b7;
    }
    @media all and (max-width: 768px) {
        padding-right: 0;
    }
`;

const TopContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2c2c2c;
`;
const Heads = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 13px;
        color: #fff;
        margin-bottom: 12px;
    }
`;
const Heading = styled.h4`
    font-size: 22px;
    color: #fff;
    font-family: "gordita_medium";
    margin-top: 15px;
    @media all and (max-width: 1400px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
        margin-top: 20px;
    }
`;
const Crumb = styled.div`
    background: #1b1c1f;
    border-radius: 6px;
    padding: 14px 34px;
`;

const Section = styled.div`
    color: #d2caca;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "category_image"
            ? "10%"
            : // : type === "parent_category"
              // ? "25%"
              type === "actions" && "15%"};
    justify-content: ${({ type }) =>
        type === "actions" ? "flex-end" : "center"};

    display: flex;
    align-items: center;

    p {
        color: #d2caca;
    }
    span {
        height: 30px;
        img {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
`;
const MiddleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;
const BottomContainer = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const UlList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    align-items: center;
    border-bottom: 1px solid #252627;
`;
const Li = styled.li`
    display: flex;
    flex-wrap: wrap;
    width: ${({ type }) =>
        type === "question"
            ? "7%"
            : type === "date"
            ? "8%"
            : type === "tags"
            ? "20%"
            : type === "ask"
            ? "40"
            : type === "action"
            ? "5%"
            : ""};
    justify-content: ${({ type }) =>
        type === "subscription" ? "center" : "flex-start"};
    p {
        font-family: "gordita_regular";
        font-size: 12px;
    }
`;

const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    display: flex;
    font-family: "gordita_regular";
    font-size: 13px;
    color: #b7b7b7;
    width: ${({ type }) =>
        type === "question"
            ? "10%"
            : type === "date"
            ? "8%"
            : type === "tags"
            ? "15%"
            : type === "ask"
            ? "8%"
            : type === "action"
            ? "15%"
            : ""};
    justify-content: ${({ type }) =>
        type === "viewbutton" ? "center" : "flex-start"};
`;

const Views = styled.div`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
    margin-top: 15px;
    @media all and (max-width: 1400px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
        margin-top: 20px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const AskButton = styled(Link)`
    background: #fe673a;
    color: #fff;
    padding: 10px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const StudentProfile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
const StudentDetails = styled.div``;
const StudentName = styled.h4`
    font-family: "gordita_medium";
    font-size: 16px;
    color: #fff;
`;
const StudentPosition = styled.p`
    font-size: 12px;
    color: #fe673a;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const CopyButton = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
const StudentPhoto = styled.div`
    margin-right: 6px;
    img {
        width: 100%;
        display: block;
    }
`;

const QuestionContainer = styled.div`
    margin-top: 30px;
`;
const Question = styled.div`
    display: flex;
`;
const CommentContainer = styled.div`
    width: 100%;
    margin-bottom: 30px;
    p {
        font-size: 16px;
        margin-bottom: 15px;
        color: #fff;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    small {
        font-size: 14px;
        font-family: "gordita_medium";
        color: #fff;
        line-height: 18px;
        margin-bottom: 15px;
    }
`;

const Message = styled.div`
    width: 100%;
    display: flex;
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 24px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const Error = styled.div``;
const MessageTwo = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 14px;
        color: #fff;
    }
`;
const SecondMessage = styled.div`
    width: 100%;
    display: flex;
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 24px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const MessageOne = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 14px;
        color: #fff;
    }
`;
const CommentSection = styled.div`
    display: flex;
    position: relative;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #828282;
    justify-content: space-between;
    text-align: center;
`;
const Input = styled.textarea`
    font-size: 15px;
    word-wrap: break-word;
    white-space: pre-wrap;
    outline: none;
    /* min-height: 20px; */
    /* max-height: 100px; */
    overflow-x: hidden;
    overflow-y: auto;
    color: #414141;
    width: 100%;
    resize: none;

    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        padding: 8px 8px;
        font-size: 14px;
    }
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;

const BorderContainer = styled.div`
    border: 1px solid #334155;
    border-radius: 6px;
    margin-top: 24px;
    padding: 0px 24px;
`;
const BorderTop = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 26%;
    align-items: center;
`;
const Remove = styled(Link)`
    background: #fff;
    font-family: "gordita_regular";
    font-size: 13px;
    padding: 4px 8px;
    border-radius: 6px;
    color: #b42318;
`;
const Evaluate = styled(Link)`
    background: #fe673a;
    font-family: "gordita_regular";
    color: #fff;
    font-size: 13px;
    padding: 4px 8px;
    border-radius: 6px;
`;

const Container = styled.section`
    padding-top: 22px;
`;
const ContentContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
`;
const ClearDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 154px;
    height: 40px;
    background: #1b1c20;
    border-radius: 6px;
    border: 1px solid #fe673a;
    margin-right: 15px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
`;

const TopContainers = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Search = styled.div`
    p {
        font-family: "gordita_regular";
        color: #fff;
        font-size: 14px;
    }
`;
const Headings = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px 16px 8px 16px;
    border: 2px solid #1b1c20;
    border-radius: 3px;
    margin-right: 12px;
`;
const SortConrtainer = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 8px 10px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 12px;
    span {
        font-family: "gordita_regular";
        color: #fff;
    }
`;
const SortIcon = styled.div`
    width: 25%;
    img {
        width: 100%;
        display: block;
        margin-left: 4px;
    }
`;
const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;
const Filters = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const FilterText = styled.div`
    margin-right: 10px;
    font-family: gordita_medium;
    color: #fff;
    font-size: 14px;
`;
const FIlterImage = styled.div`
    & img {
        width: 12px;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    padding: 20px;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 384px;
    top: 0;
    right: -500px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: auto;
        transition: 0.5s ease;
        min-height: 100%;
        right: 0;
        overflow-y: scroll;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const ApplyDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 11px;
    width: 154px;
    height: 40px;
    background: #fe673a;
    border-radius: 6px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
    &.disabled {
        cursor: not-allowed;
    }
`;
const FilterDiv = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
    justify-content: space-between;
    align-items: center;
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    margin: 10px 0;
`;
const ArrowDiv = styled.div`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 20px;

    transform: rotate(90deg);

    img {
        width: 100%;
        display: block;
    }
`;

const AccountFilter = styled.div`
    display: flex;
    align-items: center;
`;
const DatePickDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    background: #161618;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #272729;
    font-size: 14px;
    width: 146px;
    height: 50px;
    cursor: pointer;
    z-index: unset !important;
    @media all and (max-width: 480px) {
        padding: 13px 5px;
    }
    /* position: relative; */

    & .react-date-picker__calendar {
        inset: inherit !important;
        top: -175px !important;
        left: -38px !important;
        z-index: 201;
        width: 375px !important;
    }

    &:last-child .react-date-picker__calendar {
        left: -218px !important;
    }

    &:last-child {
        margin: 0 0 0 10px;
    }

    &.active {
        border: 1px solid #fe673a;
    }
`;
const ArticleDivModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    width: 100%;
    max-height: 219px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-bottom: 10px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SearchDivModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    width: 100%;
    max-height: 219px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    top: 290px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SelectDiv = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
`;
const TagContainer = styled.div`
    padding: 8px 10px 8px 10px;
    background: #1d1c1c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 56%;
    border-radius: 6px;
`;
const Tag = styled.div`
    font-family: "gordita_regular";
`;
const TagIcon = styled.div`
    width: 12px;
    img {
        width: 100%;
        display: block;
    }
`;
const ContainerDiv = styled.div``;
const Small = styled.span`
    color: #838488;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 15px;
`;
const SelectOption = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Select = styled.div`
    display: inline-flex;
    padding: 6px 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: #3b3b3b;
`;
const MainImg = styled.small`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    margin-right: 8px;
`;
const MianText = styled.h5`
    color: #fff;
    font-size: 14px;
    height: 16px;
`;
const CloseImg = styled.span`
    display: inline-block;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
    }
`;

const SearchContent = styled.div`
    font-size: 16px;
    border-bottom: 1px solid #777;
    font-family: gordita_medium;
    padding: 14px 20px;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    }
`;
const SearchBar = styled.div`
    // padding: 10px 24px;
    background: #161619;
    border-radius: 5px;
    width: 310px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background: #161619;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 10px 10px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const SearchImg = styled.img`
    width: 100%;
    display: block;
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;
const MainContainers = styled.div`
    min-width: 1280px;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    border-radius: 8px 8px 0 0;
    background-color: #1b1c1f;
`;

const Text = styled.h3`
    color: #d2caca;
    font-size: 14px;
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "category_image"
            ? "10%"
            : type === "parent_category"
            ? "25%"
            : type === "actions" && "15%"};
    display: flex;
    align-items: center;
    justify-content: ${({ type }) =>
        type === "actions" ? "flex-end" : "center"};
`;

const DataContainer = styled.div`
    min-height: 61vh;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    background-color: #1b1c1f;
    &:hover {
        background-color: #161619;
    }
`;
const Sections = styled.div`
    color: #d2caca;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "category_image"
            ? "10%"
            : type === "parent_category"
            ? "25%"
            : type === "actions" && "15%"};
    justify-content: ${({ type }) =>
        type === "actions" ? "flex-end" : "center"};

    display: flex;
    align-items: center;

    p {
        color: #d2caca;
    }
    span {
        height: 30px;
        /* width: 30px; */
        img {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
`;

const BottomContainers = styled.div``;

const Edit = styled.button`
    border-radius: 6px;
    border: 2px solid #2c2c2c;
    background: #fff;
    padding: 10px 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    p {
        font-family: "gordita_regular";
        font-size: 12px;
        color: #000;
    }
`;
const RestoreButton = styled.div``;
const ImageBox = styled.span`
    width: 20px;
    display: inline-block;
    img {
        display: block;
        width: 100%;
    }
`;
const Delete = styled.button`
    margin-left: 10px;
    font-family: "gordita_regular";
    color: #ff3030;
    font-size: 14px;
    padding: 10px 28px;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const SearchDiv = styled.div`
    display: flex;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    padding: 12px 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
`;
const SearchTxt = styled.input`
    color: #fff;
    font-size: 18px;
    width: 100%;

    &::placeholder {
        color: #fff;
        font-size: 18px;
    }
`;
const ArticleIcon = styled.small`
    display: inline-block;
    width: 18px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

// const PaginationContainer = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 40px 0 15px;
//     @media all and (max-width: 890px) {
//         flex-direction: column;
//     }
// `;
// const PaginationText = styled.p`
//     color: #898989;
//     font-size: 14px;
//     @media all and (max-width: 890px) {
//         margin-bottom: 10px;
//     }
// `;

export default CommentReport;
