import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { Context } from "../../../contexts/Store";
import { learnConfig } from "../../../../axiosConfig";
import { useState } from "react";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { useParams } from "react-router-dom";

function MembersDelete({
    isModal,
    setModal,
    isContinue,
    setContinue,
    userId,
    setSuccessful,
}) {
    const [isButton, setButton] = useState(false);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const { group_id } = useParams();

    //to avoid outside scroll
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    //for outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const deleteMember = () => {
        setButton(true);
        learnConfig
            .post(
                `events/group/remove-student/${group_id}/${userId}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { status_code, data } = response.data;
                if (status_code === 6000) {
                    setModal(false);
                    setSuccessful(true);
                    setContinue(!isContinue);
                    setButton(false);
                } else {
                    setButton(false);
                    setModal(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setButton(false);
            });
    };

    return (
        <BackContainer>
            {isModal ? (
                <Overlay
                    onClick={() => setModal(false)}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <HeadingContainer>
                            <Close
                                onClick={() => {
                                    setModal(false);
                                }}
                            >
                                <CloseIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Close Icon"
                                />
                            </Close>
                            <Text>
                                Are you sure do you want to remove this member ?
                            </Text>
                        </HeadingContainer>
                        <ButtonContainer>
                            <Button
                                className="reject"
                                onClick={() => {
                                    setModal(false);
                                }}
                            >
                                cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    deleteMember();
                                }}
                            >
                                {isButton ? <ButtonLoader /> : "confirm"}
                            </Button>
                        </ButtonContainer>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default MembersDelete;
const BackContainer = styled.div``;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
// const Overlay = styled.div`
//     position: absolute;
//     left: 0;
//     top: 0px;
//     width: 100%;
//     min-height: 100vh;
//     max-height: 100vh;
// `;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 460px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 1280px) {
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 35px;
    width: 100%;
    height: 100%;
    background-color: #1b1c1f;
    border: 1px solid #26272a;
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;
const Text = styled.p`
    font-size: 14px;
    color: #fff;
    margin-bottom: 25px;
    font-family: "gordita_medium";
    width: 100%;
    text-align: left;
`;
const Close = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
const CloseIcon = styled.img`
    width: 18px;
    display: block;
    margin-bottom: 15px;
`;
const Button = styled.div`
    font-size: 14px;
    font-family: "gordita_medium";
    background-color: #fe673a;
    height: 35px;
    width: 100px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-family: "gordita_medium";
    &:nth-child(1) {
        margin-right: 20px;
    }

    &.reject {
        background: #161619;
        border: 1px solid #fe673a;
        color: #fe673a;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
`;
