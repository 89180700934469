import React, { useContext, useEffect, useState } from "react";
import SteypHelmet from "../../helpers/SteypHelmet";
import styled from "styled-components";
import ProjectAddHeader from "../../includes/projects-section/ProjectAddHeader";
import ProjectRuleScreen from "../../includes/projects-section/ProjectRuleScreen";

function CommonRules() {
    return (
        <Container id="main">
            <MainContainer>
                <ContainerDiv>
                    <SteypHelmet title="Common Rules" />
                    <ProjectAddHeader />
                    <ProjectRuleScreen />
                </ContainerDiv>
            </MainContainer>
        </Container>
    );
}

export default CommonRules;
const MainContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ContainerDiv = styled.div`
    /* min-width: 1620px; */
    min-width: 1100px;
`;
const Container = styled.div``;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
    margin: 24px 0;
`;
