import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function PasswordShowModal({
    showModal,
    isShowPassword,
    setIsShowPassword,
    userPassword,
    setSelectedUsername,
    isPassLoading,
}) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
            setIsShowPassword(false);
            setSelectedUsername("");
        }, 500);
    }, [copied]);

    return (
        <>
            {showModal && isShowPassword && (
                <Container>
                    {isPassLoading ? (
                        <ButtonLoader />
                    ) : (
                        <>
                            <Div>
                                <Pass>{userPassword}</Pass>
                            </Div>

                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(userPassword);
                                    setCopied(true);
                                }}
                            >
                                <small>
                                    <img
                                        src={
                                            copied === true
                                                ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-02-2024/check-tick.svg"
                                                : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-02-2024/code.svg"
                                        }
                                        alt="copy-icon"
                                    />
                                </small>
                            </span>
                        </>
                    )}
                </Container>
            )}
        </>
    );
}

export default PasswordShowModal;

const Container = styled.div`
    border: 1px solid rgb(254, 103, 58);
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    font-size: 14px;
    width: auto;
    padding: 8px;
    height: 36px;
    min-width: 140px;
    border-radius: 12px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: 27px;
    left: -5px;
    z-index: 1;
    span {
        color: #e2e8f0;
        font-size: 14px;
        font-family: "gordita_medium";
        display: flex;
        align-items: center;

        small {
            display: flex;
            align-items: center;
            width: 18px;
            margin-left: 8px;
            img {
                display: block;
                width: 100%;
            }
        }
    }
`;
const Div = styled.div`
    /* margin-right: 10px; */
`;
const Pass = styled.p`
    font-family: "gordita_medium";
    font-size: 14px;
    color: #fff;
`;
const Closeicon = styled.div`
    width: 12px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
