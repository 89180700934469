import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import { NavLink } from "react-router-dom";

function SchoolsSinglePage() {
	const {
		state: {
			user_data: { access_token },
			student_data: {},
		},
		dispatch,
	} = useContext(Context);

	// ============params================
	const { id } = useParams();
	const [searchparams, setSearchparams] = useSearchParams();

	// ============loading===============
	const [loading, setLoading] = useState(false);

	// ============data==================
	const [countData, setCountData] = useState([]);
	const count = 4;
	useEffect(() => {
		setLoading(true);
		accountsConfig
			.get(`/api/v1/users/scs/get-student-count/${id}`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setCountData(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	}, []);

	const campus = searchparams.get("campus") ? searchparams.get("campus") : "";

	return (
		<Container id="main">
			<Heading>{campus}</Heading>
			<Cover>
				<Item>
					<SubHeading>Total</SubHeading>
					<Count>
						{loading ? (
							<ButtonLoader />
						) : countData?.total_students ? (
							countData.total_students
						) : (
							"--"
						)}
					</Count>
				</Item>

				{countData?.students_count?.map((item, i) => {
					return (
						<Item>
							<Card
								to={`/school-scientist/students/?student_class=${
									item.class === "+1"
										? "plus_one"
										: item.class === "+2"
										? "plus_two"
										: item.class
								}&campus=${id}`}
							>
								<SubHeading>{item.class}</SubHeading>
								<Count>
									{loading ? (
										<ButtonLoader />
									) : (
										item.total_count
									)}
								</Count>
							</Card>
						</Item>
					);
				})}
			</Cover>
		</Container>
	);
}
const Container = styled.div``;
const Heading = styled.h2`
	color: #fff;
	font-size: 18px;
	font-family: "gordita_medium";
	margin-bottom: 30px;
	@media all and (max-width: 768px) {
		font-size: 17px;
	}
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;
const Cover = styled.ul`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;
const Item = styled.li`
	width: 30%;
	margin-bottom: 20px;
	@media all and (max-width: 480px) {
		width: 48%;
	}
	@media all and (max-width: 360px) {
		width: 100%;
	}
`;
const SubHeading = styled.h3`
	color: #fff;
	font-size: 16px;
	font-family: "gordita_medium";
	margin-bottom: 10px;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
`;
const Count = styled.span`
	color: #fff;
	display: block;
	background: #1b1c20;
	padding: 30px;
	border-radius: 8px;
	border: 1px solid #242528;
	text-align: center;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
`;
const Card = styled(NavLink)`
	width: 30%;
	margin-bottom: 20px;
`;

export default SchoolsSinglePage;
