import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import UsersList from "../../../screens/chat/UsersList";
import ChatRoom from "../../../screens/chat/ChatRoom";
import ChooseStudent from "../../../screens/chat/ChooseStudent";

export default function SeRoute() {
    const [isStudentSelected, setStudentSelected] = useState(false);

    return (
        <Container>
            <Routes>
                <Route
                    path="/"
                    element={
                        <UsersList
                            isStudentSelected={isStudentSelected}
                            setStudentSelected={setStudentSelected}
                        />
                    }
                >
                    <Route index element={<ChooseStudent />} />
                    <Route
                        path="room"
                        element={
                            <ChatRoom isStudentSelected={isStudentSelected} />
                        }
                    />
                </Route>
            </Routes>
        </Container>
    );
}

const Container = styled.div`
    padding: 70px 16px 11px 16px;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    position: relative;
`;
