import React from 'react'
import SchoolCategory from './SchoolCategory'
import styled from "styled-components";
import SchoolScientistStudents from './SchoolScientistStudents';
import UpStudentsList from './UpStudentsList'

function StudentsUP() {
  return (
    <Container id="main">
        <SchoolScientistStudents />
        <SchoolCategory /> 
        <UpStudentsList />
    </Container>
  )
}
const Container = styled.div`
    
`;

export default StudentsUP