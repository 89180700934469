import React, { lazy } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
//components
const StudentInnerRouter = lazy(() => import("./StudentInnerRouter"));
const Students = lazy(() => import("../../../screens/students/Students"));
const PrimeAboutStudents = lazy(() =>
    import("../../../screens/students/PrimeAboutStudents")
);
const SubscriptionManagerStudents = lazy(() =>
    import("../../../screens/subscription-manager/SubscriptionManagerStudents")
);

function SubscriptionManagerStudentsRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route path="/" element={<SubscriptionManagerStudents />} />
                <Route
                    path="prime-programs/:student_id/:user_pk"
                    element={<PrimeAboutStudents />}
                />
                <Route path=":student_id/*" element={<StudentInnerRouter />} />
            </Routes>
        </Container>
    );
}

export default SubscriptionManagerStudentsRouter;

const Container = styled.div``;
