import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import dp from "../../../assets/images/chat/Avatar.jpg";
import Avatar from "react-avatar";
import { formatBytes, middleTruncate } from "../../helpers/functions";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import fileDownload from "js-file-download";
import axios from "axios";
import Waveform from "../../includes/chat/Wave";
import Lightbox from "react-modal-image";
import ReactMarkdown from "react-markdown";
import ChatLoader from "../../includes/chat/ChatLoader";
import Linkify from "react-linkify";
import { useSearchParams } from "react-router-dom";

export default function ChatList({
    chat_user,
    percentage,
    myId,
    msgs,
    uploadedSize,
    setPercentage,
    setUploadedSize,
    msgFetching,
    fetchMoreData,
    scrollableDiv,
    isShowLoadButton,
    setStudentDetailsMenu,
    isStudentDetailsMenu,
}) {
    const [messages, setMessages] = useState([]);
    const [searchParams] = useSearchParams();

    const emojiCheck = (text) => {
        const emojiRegex = /\p{Emoji}/u;
        if (
            text &&
            typeof text === "string" &&
            text.length === 2 &&
            emojiRegex.test(text)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const scrollRef = useRef();
    useEffect(() => {
        if (!isShowLoadButton)
            scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [msgs]);

    function groupedDays(messages) {
        return messages.reduce((acc, el, i) => {
            const messageDay = moment(el.createdAt?.toDate()).format(
                "YYYY-MM-DD"
            );
            if (acc[messageDay]) {
                return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
            }
            return { ...acc, [messageDay]: [el] };
        }, {});
    }

    function generateItems(messages) {
        const days = groupedDays(messages);
        const sortedDays = Object.keys(days).sort(
            (x, y) =>
                moment(y, "YYYY-MM-DD").unix() - moment(x, "YYYY-MM-DD").unix()
        );
        const items = sortedDays.reduce((acc, date) => {
            const sortedMessages = days[date].sort(
                (x, y) => new Date(y.createdAt) - new Date(x.createdAt)
            );
            return acc.concat([
                ...sortedMessages,
                { type: "day", date, id: date },
            ]);
        }, []);
        setMessages(items.reverse());
    }

    const handleDownload = (msg) => {
        msg.isUploading = true;
        axios
            .get(msg.fileUrl, {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                    let downloadingPercentage = Math.floor(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setPercentage(downloadingPercentage);
                    setUploadedSize(progressEvent.loaded);
                },
            })
            .then((res) => {
                msg.isUploading = false;
                fileDownload(res.data, msg.fileName);
            })
            .catch((err) => {
                console.log("error");
            });
    };

    useEffect(() => {
        generateItems(msgs);
    }, [msgs]);

    const ConvertDate = (value) => {
        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];

        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0, 0);

        let date_new = new Date(value);
        date_new.setHours(0);
        date_new.setMinutes(0);
        date_new.setSeconds(0, 0);

        if (today.getTime() === date_new.getTime()) {
            return "Today";
        } else if (today.getTime() - 86400000 === date_new.getTime()) {
            return "Yesterday";
        } else if (today.getTime() - 86400000 * 7 < date_new.getTime()) {
            return days[date_new.getDay()];
        } else {
            return getDateStr(value);
        }
    };

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (!isShowLoadButton)
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    const getDateStr = (value) => {
        let date = new Date(value);
        let month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ][date.getMonth()];
        let date_str = month + " " + date.getDate() + ", " + date.getFullYear();
        return date_str;
    };

    const isValidUrl = (urlString) => {
        if (
            new RegExp(
                "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
            ).test(urlString)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const id = searchParams.get("id") ? searchParams.get("id") : "";

    return (
        <MainContainer ref={scrollableDiv}>
            <LoadButton
                className={isShowLoadButton ? "active" : ""}
                onClick={fetchMoreData}
            >
                Load Previous
            </LoadButton>
            {chat_user.uid === id && (
                <StudentDetailHamburger
                    onClick={() => setStudentDetailsMenu(!isStudentDetailsMenu)}
                >
                    <HamburgerDot></HamburgerDot>
                    <HamburgerDot></HamburgerDot>
                    <HamburgerDot></HamburgerDot>
                </StudentDetailHamburger>
            )}
            <Messages>
                {messages.length && !msgFetching ? (
                    messages.map((msg, index) =>
                        msg.type === "typing" &&
                        msg.isTyping &&
                        msg.fromId !== myId ? (
                            <Message ref={scrollRef} key={index}>
                                <DpContainer>
                                    {msg.fromId === chat_user.uid ? (
                                        <Avatar
                                            name={chat_user.name}
                                            size="33"
                                        />
                                    ) : (
                                        <Dp src={msg.fromImage} alt="Icon" />
                                    )}
                                </DpContainer>
                                <Right>
                                    <Loading>
                                        <span className="dot"></span>
                                        <span className="dot"></span>
                                        <span className="dot"></span>
                                    </Loading>
                                </Right>
                            </Message>
                        ) : msg.type === "recording" &&
                          msg.isRecording &&
                          msg.fromId !== myId ? (
                            <Message ref={scrollRef} key={index}>
                                <DpContainer>
                                    {chat_user.uid === msg.fromId ? (
                                        <Avatar
                                            name={chat_user.name}
                                            size="33"
                                        />
                                    ) : (
                                        <Dp
                                            src={
                                                msg.fromImage
                                                    ? msg.fromImage
                                                    : dp
                                            }
                                            alt="Icon"
                                        />
                                    )}
                                </DpContainer>
                                <Right>
                                    <Top>
                                        <p>{msg.fromName}</p>{" "}
                                    </Top>
                                    <RecordingContainer
                                        className={
                                            chat_user.uid === msg.fromId
                                                ? "student"
                                                : ""
                                        }
                                    >
                                        <img
                                            className="file"
                                            src={require("../../../assets/images/recording.gif")}
                                            alt="Icon"
                                        />
                                        <Recording>Recording...</Recording>
                                    </RecordingContainer>
                                </Right>
                            </Message>
                        ) : msg.type === "day" ? (
                            <DateLine ref={scrollRef} key={index}>
                                <span>{ConvertDate(msg.date)}</span>
                            </DateLine>
                        ) : msg.type === "join" ? (
                            <NewMember ref={scrollRef} key={index}>
                                {msg.fromId === myId ? "You" : msg.fromName}{" "}
                                joined the conversation at{" "}
                                {msg.createdAt
                                    ?.toDate()
                                    .toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                            </NewMember>
                        ) : msg.type === "leave" ? (
                            <NewMember ref={scrollRef} key={index}>
                                {msg.fromId === myId ? "You" : msg.fromName}{" "}
                                left the conversation at{" "}
                                {msg.createdAt
                                    ?.toDate()
                                    .toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                            </NewMember>
                        ) : msg.type === "file" ? (
                            chat_user.uid === msg.fromId ? (
                                <Message ref={scrollRef} key={index}>
                                    <DpContainer>
                                        {chat_user.uid === msg.fromId ? (
                                            <Avatar
                                                name={chat_user.name}
                                                size="33"
                                            />
                                        ) : (
                                            <Dp
                                                src={
                                                    msg.fromImage
                                                        ? msg.fromImage
                                                        : dp
                                                }
                                                alt="Icon"
                                            />
                                        )}
                                    </DpContainer>
                                    <Right>
                                        <Top>
                                            <p>
                                                {msg.fromId === myId
                                                    ? ""
                                                    : msg.fromId ===
                                                      chat_user.uid
                                                    ? "Student"
                                                    : msg.fromName}
                                            </p>{" "}
                                            <small>
                                                {msg.createdAt
                                                    ?.toDate()
                                                    .toLocaleTimeString(
                                                        "en-US",
                                                        {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }
                                                    )}
                                            </small>
                                        </Top>
                                        <File
                                            className={
                                                msg.fromId === myId
                                                    ? "chief-side"
                                                    : "student"
                                            }
                                        >
                                            <FileLeft>
                                                <FileImage
                                                    className={
                                                        msg.fromId === myId
                                                            ? "chief-side"
                                                            : "student"
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            require("../../../assets/images/chat/file.svg")
                                                                .default
                                                        }
                                                        alt="Image"
                                                    />
                                                </FileImage>
                                                <div className="filename">
                                                    <h5>
                                                        {middleTruncate(
                                                            msg.fileName
                                                        )}
                                                    </h5>
                                                    {msg.isUploading ? (
                                                        <p>
                                                            {formatBytes(
                                                                uploadedSize
                                                            )}
                                                            /
                                                            {formatBytes(
                                                                msg.fileSize
                                                            )}
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            {formatBytes(
                                                                msg.fileSize
                                                            )}
                                                        </p>
                                                    )}
                                                </div>
                                            </FileLeft>
                                            {msg.isUploading ? (
                                                <Progress>
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                        strokeWidth={5}
                                                    />
                                                </Progress>
                                            ) : (
                                                <DownloadButton
                                                    onClick={() => {
                                                        handleDownload(msg);
                                                    }}
                                                    title="Download"
                                                >
                                                    <img
                                                        className="file"
                                                        src={
                                                            require("../../../assets/images/chat/download.svg")
                                                                .default
                                                        }
                                                        alt="Icon"
                                                    />
                                                </DownloadButton>
                                            )}
                                        </File>
                                    </Right>
                                </Message>
                            ) : (
                                <Message
                                    className={
                                        msg.fromId === myId ? "chief-side" : ""
                                    }
                                    key={index}
                                >
                                    {msg.fromId !== myId && (
                                        <DpContainer>
                                            {chat_user.uid === msg.fromId ? (
                                                <Avatar
                                                    name={chat_user.name}
                                                    size="33"
                                                />
                                            ) : (
                                                <Dp
                                                    src={
                                                        msg.fromImage
                                                            ? msg.fromImage
                                                            : dp
                                                    }
                                                    alt="Icon"
                                                />
                                            )}
                                        </DpContainer>
                                    )}
                                    <Right>
                                        <Top>
                                            <p>
                                                {msg.fromId === myId
                                                    ? ""
                                                    : msg.fromId ===
                                                      chat_user.uid
                                                    ? "Student"
                                                    : msg.fromName}
                                            </p>{" "}
                                            <small>
                                                {msg.createdAt
                                                    ?.toDate()
                                                    .toLocaleTimeString(
                                                        "en-US",
                                                        {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }
                                                    )}
                                            </small>
                                        </Top>
                                        <File>
                                            <FileLeft>
                                                <FileImage>
                                                    <img
                                                        src={
                                                            require("../../../assets/images/chat/file.svg")
                                                                .default
                                                        }
                                                        alt="Image"
                                                    />
                                                </FileImage>
                                                <div className="filename">
                                                    <h5>
                                                        {middleTruncate(
                                                            msg.fileName
                                                        )}
                                                    </h5>
                                                    {msg.isUploading ? (
                                                        <p>
                                                            {formatBytes(
                                                                uploadedSize
                                                            )}
                                                            /
                                                            {formatBytes(
                                                                msg.fileSize
                                                            )}
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            {formatBytes(
                                                                msg.fileSize
                                                            )}
                                                        </p>
                                                    )}
                                                </div>
                                            </FileLeft>
                                            {msg.isUploading ? (
                                                <Progress>
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                        strokeWidth={5}
                                                    />
                                                </Progress>
                                            ) : (
                                                <DownloadButton
                                                    onClick={() => {
                                                        handleDownload(msg);
                                                    }}
                                                    title="Download"
                                                >
                                                    <img
                                                        className="file"
                                                        src={
                                                            require("../../../assets/images/chat/download.svg")
                                                                .default
                                                        }
                                                        alt="Icon"
                                                    />
                                                </DownloadButton>
                                            )}
                                        </File>
                                    </Right>
                                </Message>
                            )
                        ) : msg.type === "voice" ? (
                            msg.isUploading ? (
                                msg.fromId === myId ? (
                                    <Message className="chief-side" key={index}>
                                        <Right>
                                            <Top>
                                                <small>
                                                    {msg.createdAt
                                                        ?.toDate()
                                                        .toLocaleTimeString(
                                                            "en-US",
                                                            {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }
                                                        )}
                                                </small>
                                            </Top>
                                            <WaveContainer>
                                                <Waveform
                                                    id={msg.id}
                                                    loading={true}
                                                    voiceState={msg.media}
                                                />
                                            </WaveContainer>
                                        </Right>
                                    </Message>
                                ) : null
                            ) : msg.fromId !== myId ? (
                                <Message ref={scrollRef} key={index}>
                                    {msg.fromId !== myId && (
                                        <DpContainer>
                                            {chat_user.uid === msg.fromId ? (
                                                <Avatar
                                                    name={chat_user.name}
                                                    size="33"
                                                />
                                            ) : (
                                                <Dp
                                                    src={
                                                        msg.fromImage
                                                            ? msg.fromImage
                                                            : dp
                                                    }
                                                    alt="Icon"
                                                />
                                            )}
                                        </DpContainer>
                                    )}
                                    <Right>
                                        <Top>
                                            <p>
                                                {msg.fromId === myId
                                                    ? ""
                                                    : msg.fromId ===
                                                      chat_user.uid
                                                    ? "Student"
                                                    : msg.fromName}
                                            </p>{" "}
                                            <small>
                                                {msg.createdAt
                                                    ?.toDate()
                                                    .toLocaleTimeString(
                                                        "en-US",
                                                        {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }
                                                    )}
                                            </small>
                                        </Top>
                                        <WaveContainer>
                                            <Waveform
                                                id={msg.id}
                                                loading={msg.isUploading}
                                                voiceState={msg.media}
                                                className={
                                                    msg.fromId === myId
                                                        ? "chief-side"
                                                        : chat_user.uid ===
                                                          msg.fromId
                                                        ? "student"
                                                        : "chief-side-engineer"
                                                }
                                            />
                                        </WaveContainer>
                                    </Right>
                                </Message>
                            ) : (
                                <Message className="chief-side" key={index}>
                                    {msg.fromId !== myId && (
                                        <DpContainer>
                                            {chat_user.uid === msg.fromId ? (
                                                <Avatar
                                                    name={chat_user.name}
                                                    size="33"
                                                />
                                            ) : (
                                                <Dp
                                                    src={
                                                        msg.fromImage
                                                            ? msg.fromImage
                                                            : dp
                                                    }
                                                    alt="Icon"
                                                />
                                            )}
                                        </DpContainer>
                                    )}
                                    <Right>
                                        <Top>
                                            <p>
                                                {msg.fromId === myId
                                                    ? ""
                                                    : msg.fromId ===
                                                      chat_user.uid
                                                    ? "Student"
                                                    : msg.fromName}
                                            </p>{" "}
                                            <small>
                                                {msg.createdAt
                                                    ?.toDate()
                                                    .toLocaleTimeString(
                                                        "en-US",
                                                        {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }
                                                    )}
                                            </small>
                                        </Top>
                                        <WaveContainer>
                                            <Waveform
                                                id={msg.id}
                                                loading={msg.isUploading}
                                                voiceState={msg.media}
                                                className={
                                                    msg.fromId === myId
                                                        ? "chief-side"
                                                        : chat_user.uid ===
                                                          msg.fromId
                                                        ? "student"
                                                        : "chief-side-engineer"
                                                }
                                            />
                                        </WaveContainer>
                                    </Right>
                                </Message>
                            )
                        ) : msg.type !== "typing" && !msg.isRecording ? (
                            msg.fromId === myId ? (
                                <Message className="chief-side" key={index}>
                                    <Right>
                                        <Top>
                                            <small>
                                                {msg.createdAt
                                                    ?.toDate()
                                                    .toLocaleTimeString(
                                                        "en-US",
                                                        {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }
                                                    )}
                                            </small>
                                        </Top>
                                        {msg.media ? (
                                            <MediaCard
                                                className={
                                                    chat_user.uid === msg.fromId
                                                        ? "student"
                                                        : "chief-side"
                                                }
                                            >
                                                {msg.isUploading && (
                                                    <Overlay>
                                                        <span>Sending...</span>
                                                    </Overlay>
                                                )}
                                                <PlainImage>
                                                    <Img
                                                        className={
                                                            msg.isUploading
                                                                ? "uploading"
                                                                : ""
                                                        }
                                                        small={msg.media}
                                                        medium={msg.media}
                                                        large={msg.media}
                                                    />
                                                </PlainImage>

                                                {msg.text && (
                                                    <MediaText
                                                        className={
                                                            chat_user.uid ===
                                                            msg.fromId
                                                                ? "student"
                                                                : "chief-side"
                                                        }
                                                    >
                                                        {msg.text}
                                                    </MediaText>
                                                )}
                                            </MediaCard>
                                        ) : (
                                            <Text
                                                className={`${
                                                    msg.fromId === myId
                                                        ? "chief-side"
                                                        : chat_user.uid ===
                                                          msg.fromId
                                                        ? "student"
                                                        : "chief-side-engineer"
                                                } ${
                                                    emojiCheck(msg.text)
                                                        ? "emoji"
                                                        : ""
                                                }`}
                                            >
                                                {typeof msg.text === "string" &&
                                                msg.text?.startsWith("```") ? (
                                                    <ReactMarkdown>
                                                        {msg.text}
                                                    </ReactMarkdown>
                                                ) : (
                                                    <Linkify
                                                        children={msg.text}
                                                        componentDecorator={(
                                                            decoratedHref,
                                                            decoratedText,
                                                            key
                                                        ) => (
                                                            <a
                                                                target="blank"
                                                                href={
                                                                    decoratedHref
                                                                }
                                                                key={key}
                                                            >
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    ></Linkify>
                                                )}
                                            </Text>
                                        )}
                                    </Right>
                                </Message>
                            ) : !msg.isUploading ? (
                                <Message ref={scrollRef} key={index}>
                                    <DpContainer>
                                        <DpContainer>
                                            {chat_user.uid === msg.fromId ? (
                                                <Avatar
                                                    name={chat_user.name}
                                                    size="33"
                                                />
                                            ) : (
                                                <Dp
                                                    src={
                                                        msg.fromImage
                                                            ? msg.fromImage
                                                            : dp
                                                    }
                                                    alt="Icon"
                                                />
                                            )}
                                        </DpContainer>
                                    </DpContainer>
                                    <Right>
                                        <Top>
                                            <p>
                                                {msg.fromId === myId
                                                    ? ""
                                                    : msg.fromId ===
                                                      chat_user.uid
                                                    ? "Student"
                                                    : msg.fromName}
                                            </p>
                                            <small>
                                                {msg.createdAt
                                                    ?.toDate()
                                                    .toLocaleTimeString(
                                                        "en-US",
                                                        {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }
                                                    )}
                                            </small>
                                        </Top>
                                        {msg.media ? (
                                            <StudentMedia
                                                className={
                                                    chat_user.uid === msg.fromId
                                                        ? "student"
                                                        : ""
                                                }
                                            >
                                                <PlainImage>
                                                    <Img
                                                        className={
                                                            msg.isUploading
                                                                ? "uploading"
                                                                : ""
                                                        }
                                                        small={msg.media}
                                                        medium={msg.media}
                                                        large={msg.media}
                                                    />
                                                </PlainImage>

                                                {msg.text && (
                                                    <MediaText
                                                        className={
                                                            chat_user.uid ===
                                                            msg.fromId
                                                                ? "student"
                                                                : ""
                                                        }
                                                    >
                                                        {msg.text}
                                                    </MediaText>
                                                )}
                                            </StudentMedia>
                                        ) : (
                                            <Text
                                                className={
                                                    emojiCheck(msg.text)
                                                        ? chat_user.uid ===
                                                          msg.fromId
                                                            ? "student emoji"
                                                            : myId ===
                                                              msg.fromId
                                                            ? "chief-side emoji"
                                                            : "emoji chief-side-engineer"
                                                        : chat_user.uid ===
                                                          msg.fromId
                                                        ? "student"
                                                        : myId === msg.fromId
                                                        ? "chief-side"
                                                        : "chief-side-engineer"
                                                }
                                            >
                                                {typeof msg.text === "string" &&
                                                msg.text?.startsWith("```") ? (
                                                    <ReactMarkdown>
                                                        {msg.text}
                                                    </ReactMarkdown>
                                                ) : (
                                                    <Linkify
                                                        children={msg.text}
                                                        componentDecorator={(
                                                            decoratedHref,
                                                            decoratedText,
                                                            key
                                                        ) => (
                                                            <a
                                                                target="blank"
                                                                href={
                                                                    decoratedHref
                                                                }
                                                                key={key}
                                                            >
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    ></Linkify>
                                                )}
                                            </Text>
                                        )}
                                    </Right>
                                </Message>
                            ) : null
                        ) : null
                    )
                ) : (
                    <ChatLoader />
                )}
                <div ref={messagesEndRef} />
            </Messages>
        </MainContainer>
    );
}

const LoadButton = styled.div`
    cursor: pointer;
    position: fixed;
    left: 50%;
    top: 75px;
    transform: translateX(-50%);
    z-index: 3;
    font-size: 0.69rem;
    padding: 5px 11px;
    border-radius: 13px;
    color: #fff;
    background: #ff8558;
    transition: 0.3s;
    opacity: 0;
    &.active {
        opacity: 1;
    }

    @media all and (max-width: 1280px) {
        position: absolute;
        top: 10px;
    }
`;
const RecordingContainer = styled.div`
    background: #d7d7d7;
    border: 1.0824px solid #d3ebce;
    border-radius: 12.3108px 12.3108px 12.3108px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 13px;
    font-size: 0.72rem;
    &.student {
        background: #482b24;
        border-color: #482b24;
        color: #fff !important;
    }
    &.student p {
        color: #fff !important;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: block;
        width: 20px;
        margin-right: 7px;
    }
`;
const Recording = styled.span`
    font-size: 0.63rem;
`;
const DateLine = styled.div`
    text-align: center;
    padding: 10px 20px;
    &::before {
        content: "";
        display: block;
        border-top: 1px solid #343434;
        position: relative;
        top: 10px;
    }
    span {
        background-color: #1b1c1f;
        padding: 0px 30px;
        position: relative;
        color: #707072;
        font-size: 0.63rem;
    }
`;
const NewMember = styled.span`
    color: #8c8c8c;
    display: block;
    font-size: 0.69rem;
    cursor: default;
    font-style: italic !important;
    padding: 8px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media all and (max-width: 480px) {
        font-size: 10px;
    }
`;
const MainContainer = styled.div`
    position: relative;
    background: #1b1c1f;
    width: 100%;
    overflow-y: scroll;
    flex: 1;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Messages = styled.div`
    background: #1b1c1f;
    padding: 35px 20px 20px 20px;
`;
const Message = styled.div`
    margin-bottom: 25px;
    display: flex;
    align-items: flex-end;
    position: relative;

    &.message {
        justify-content: start;
    }

    &.chief-side {
        justify-content: end;
        flex-direction: inherit;
    }
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3px;
    p {
        font-size: 0.63rem;
        color: #fff;
        margin-right: 7px;
        @media all and (max-width: 1280px) {
            font-size: 10px;
        }
    }
    small {
        color: #fff;
        opacity: 0.4;
        font-size: 0.63rem;
        @media all and (max-width: 1280px) {
            font-size: 10px;
        }
    }
`;
const Text = styled.div`
    width: fit-content;
    max-width: 600px;
    background: #d7d7d7;
    border: 1.0824px solid #d3ebce;
    border-radius: 13px 13px 13px 0px;
    padding: 7px 13px;
    font-size: 0.72rem;
    white-space: pre-line !important;

    &.emoji {
        font-size: 33px;
    }
    &.student {
        & a {
            color: #000;
            font-family: "gordita_medium";
        }
    }
    &.chief-side {
        background: #482b24;
        border-color: #482b24;
        color: #fff;
        border-radius: 13px 13px 0;
        margin: 0 0px 0 auto;

        & a {
            color: #fff;
            font-family: "gordita_medium";
        }
    }
    &.chief-side-engineer {
        background: #482b24;
        border-color: #482b24;
        color: #fff;
        border-radius: 13px 13px 13px 0px;

        & a {
            color: #fff;
            font-family: "gordita_medium";
        }
    }

    & pre {
        overflow-x: scroll;
    }

    & p {
        color: #000;
    }

    &.student p {
        color: #fff !important;
    }

    & ::-webkit-scrollbar {
        display: none;
    }
`;
const DpContainer = styled.div`
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 14px;
    overflow: hidden;
`;
const Dp = styled.img`
    width: 100%;
    display: block;
`;
const MediaCard = styled.div`
    position: relative;
    overflow: hidden;
    background: #d7d7d7;
    padding: 10px 10px 0 10px;
    border-radius: 4px;
    &.text {
        padding: 0;
    }

    &.chief-side {
        background-color: #482b24;
        /* margin-right: 10px; */
        border-radius: 13px 13px 0;
    }
`;
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: #fff;
        font-family: "gordita_regular";
        font-size: 16px;
    }
`;
const MediaText = styled.span`
    padding: 11px 8px 0 8px;
    display: block;
    font-size: 0.72rem;

    &.chief-side {
        color: #fff;
    }

    &.student {
        color: #000;
    }
`;
const PlainImage = styled.div`
    overflow: hidden;
    width: 210px;
    display: flex;
`;
const Img = styled(Lightbox)`
    width: 100%;
    display: block;
    border-radius: 7px;
    &.uploading {
        filter: blur(2px);
    }
`;

const Loading = styled.div`
    background: #161619;
    border-radius: 12px 12px 12px 0px;
    padding: 16px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    .dot {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 3px;
        background: #fe673a;
        animation: wave 1s linear infinite;

        &:nth-child(2) {
            animation-delay: -0.8s;
        }

        &:nth-child(3) {
            animation-delay: -0.6s;
        }
    }

    @keyframes wave {
        0%,
        60%,
        100% {
            transform: initial;
        }

        30% {
            transform: translateY(-10px);
        }
    }
`;

const Right = styled.div`
    &.voice {
    }
`;
const StudentMedia = styled.div`
    background-color: #d7d7d7;
    position: relative;
    overflow: hidden;
    padding: 10px 10px 10px 10px;
    border-radius: 13px 13px 13px 0;
`;
const WaveContainer = styled.div`
    position: relative;
`;
const File = styled.div`
    overflow: hidden;
    position: relative;
    background: #482b24;
    border-radius: 13px 13px 0px 13px;
    min-width: 250px;
    min-height: 67px;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    h5 {
        color: #fff;
        font-size: 0.72rem;
        font-weight: 600;
    }
    p {
        color: #fff;
        font-size: 0.6rem;
    }
    &.filename {
    }

    &.student {
        background-color: #d7d7d7;
        border-radius: 13px 13px 13px 0px;
    }

    &.student h5,
    &.student p {
        color: #000;
    }
    &.student .file {
        filter: invert(1);
    }
`;
const FileLeft = styled.div`
    display: flex;
    align-items: center;
`;
const FileImage = styled.div`
    min-width: 32px;
    width: 34px;
    margin-right: 15px;
    img {
        width: 100%;
        display: block;
    }

    &.student {
        filter: invert(1);
    }
`;
const DownloadButton = styled.div`
    cursor: pointer;
    display: flex;
    width: 32px;
    img {
        display: block;
        width: 100%;
    }
`;

const Progress = styled.div`
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: relative;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 15px;
    }
`;

const StudentDetailHamburger = styled.div`
    position: fixed;
    top: 85px;
    right: 40px;
    display: flex;
    cursor: pointer;
    margin-bottom: auto;
    display: none;
    z-index: 100;
    background: #161619;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 1280px) {
        display: flex;
    }
    @media all and (max-width: 1280px) {
        top: 75px;
        right: 25px;
    }
`;
const HamburgerDot = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 2px;

    &:last-child {
        margin-right: 0;
        background-color: #fe673a;
    }
`;
