import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useParams, useSearchParams } from "react-router-dom";
import VideoPlayer from "../../applications/video-player/src/VideoPlayer";
import Overview from "./Overview";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { data as overViewData } from "./Data/data";
import SectionLoader from "../../helpers/SectionLoader";
import SuccessModal from "../../includes/syllabus/modal/SuccessModal";
import CreateLanguageModal from "../../includes/syllabus/modal/CreateLanguageModal";
import EditModal from "../../includes/syllabus/modal/EditModal";
import $ from "jquery";

function SyllabusMainTopics() {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [isPageLoading, setPageLoading] = useState(false);
    const [pagination, setPagination] = useState({});
    const [toggle, setToggle] = useState(false);
    const [professionData, setProfessionData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [professionOverview, setProfessionOverview] = useState([]);
    const [topicLangId, setTopicLangId] = useState(null);
    const [selectedLanguageId, setSelectedLanguageId] = useState("");
    const [syllabusLanguages, setSyllabusLanguages] = useState([]);
    const [successful, setSuccessful] = useState(false);
    const [reload, setReload] = useState(false);
    const [modalUse, setModalUse] = useState("");
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [isUploadLoading, setUploadLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [videoId, setVideoId] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState();
    const [selectedVideoName, setSelectedVideoName] = useState("");
    const [selectedOption, setSelectedOption] = useState(" ");
    const [errorMassage, setErrorMessage] = useState("");

    useEffect(() => {
        const updatedData = overViewData?.map((item) => {
            let subname = "Overview";
            if (item.id === 1) {
                subname = professionData?.topic_data?.total_students;
            } else if (item.id === 2) {
                subname = professionData?.topic_data?.ongoing;
            } else if (item.id === 3) {
                subname = professionData?.topic_data?.completed;
            } else if (item.id === 4) {
                subname = professionData?.topic_data?.not_enrolled;
            } else if (item.id === 5) {
                return {
                    ...item,
                    // title: `${professionData?.topics} Topics`,
                    subtitle: `${professionData?.duration}`,
                };
            }
            if (item.id !== 5) {
                return {
                    ...item,
                    subname: subname,
                };
            }
            return item;
        });
        setProfessionOverview(updatedData);
    }, [professionData]);

    // new-language-popup
    const [selectedForm, setSelectedForm] = useState("");

    //modal
    const [isLanguageModalOpen, setLanguageIsModalOpen] = useState(false);
    const [isEditModalOpen, setEditIsModalOpen] = useState(false);
    const openEditModal = () => setEditIsModalOpen(true);
    const openLanguageModal = () => setLanguageIsModalOpen(true);
    const closeEditModal = () => {
        setEditIsModalOpen(false);
        setSelectedForm("");
    };
    const closeLanguageModal = () => {
        setLanguageIsModalOpen(false);
        setSelectedForm("");
    };

    const { topics_id } = useParams();
    const { id } = useParams();

    const videoJsOptions = {
        autoplay: false,
        controls: false,
        sources: [
            {
                src: selectedLanguage?.video_url,
                type: "video/m3u8",
            },
        ],
    };
    //filteration
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [activeClick, setActiveClick] = useState("Filter");
    const [toggleActive, setToggleActive] = useState("");

    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("active") === "active" &&
            (filterParams.active = "active");
        searchParams.get("program") === "tech-schooling" &&
            (filterParams.program = "tech-schooling");
        searchParams.get("program") === "tech-degree" &&
            (filterParams.program = "tech-degree");
        searchParams.get("program") === "tech-grad" &&
            (filterParams.program = "tech-grad");
        return filterParams;
    };
    useEffect(() => {
        if (
            searchParams.get("program") === "tech-schooling" &&
            searchParams.get("active") === "active"
        ) {
            setActiveClick("tech-schooling");
            setToggleActive("active");
        } else if (
            searchParams.get("program") === "tech-degree" &&
            searchParams.get("active") === "active"
        ) {
            setActiveClick("tech-degree");
            setToggleActive("active");
        } else if (
            searchParams.get("program") === "tech-grad" &&
            searchParams.get("active") === "active"
        ) {
            setActiveClick("tech-grad");
            setToggleActive("active");
        } else if (searchParams.get("program") === "tech-schooling") {
            setActiveClick("tech-schooling");
        } else if (searchParams.get("program") === "tech-degree") {
            setActiveClick("tech-degree");
        } else if (searchParams.get("program") === "tech-grad") {
            setActiveClick("tech-grad");
        } else if (searchParams.get("active") === "active") {
            setToggleActive("active");
        } else {
            setActiveClick("Filter");
        }
    }, [searchParams]);

    const ThumbnailName = selectedLanguage?.image;
    /**
     * Extracts the file name from a given URL.
     * */
    const getFileName = (url) => {
        if (!url) {
            return "";
        }
        const parts = url.split("/");
        return parts[parts.length - 1];
    };
    const thumbnail = getFileName(ThumbnailName);

    const [userdata, setUserdata] = useState({
        title: selectedLanguage?.name ?? " ",
        description: selectedLanguage?.description ?? " ",
        videoName: selectedLanguage?.video_id ?? " ",
        attachment: professionData?.attachment ?? " ",
        thumbnail: thumbnail,
        Language: selectedLanguage?.language ?? " ",
    });

    useEffect(() => {
        setUserdata((prevUserdata) => ({
            ...prevUserdata,
            title: selectedLanguage?.name ?? " ",
            description: selectedLanguage?.description ?? " ",
            videoName: selectedLanguage?.video_id ?? " ",
            attachment: selectedLanguage?.attachment ?? " ",
            thumbnail: thumbnail,
            Language: selectedLanguage?.language ?? " ",
        }));
    }, [selectedLanguage]);

    //checkbox
    const [isChecked, setIsChecked] = useState(false);
    const handleToggle = () => {
        setIsChecked((prevState) => !prevState);
        HandilEdiPrimarySelect();
    };

    const fetchsyllabusTopicDetails = () => {
        setPageLoading(true);

        const params = getFilterParams();
        learnConfig
            .get(`learn/sro/syllabus/topic/${id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    // setProfessionData(data);
                    setPagination(pagination_data);
                    setLoading(false);
                    setPageLoading(false);
                } else {
                    setLoading(false);
                    setPageLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setPageLoading(false);
            });
    };
    useEffect(() => {
        fetchsyllabusTopicDetails();
    }, [searchParams]);

    /**
     * Fetches syllabus topic details.
     */
    const fetchLanguage = () => {
        learnConfig
            .get(`learn/sro/syllabus/topic/${id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { status_code, data } = response.data;
                if (status_code === 6000) {
                    setProfessionData(data);
                    if (data?.language_data?.length > 0) {
                        setSelectedLanguage(data.language_data[0]);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchLanguage();
    }, []); // Empty dependency array ensures this runs only once on mount

    useEffect(() => {
        const fetchsyllabusLanguage = () => {
            learnConfig
                .get("/learn/sro/syllabus/languages/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    let { status_code, data } = response.data;
                    if (status_code === 6000) {
                        setSyllabusLanguages(data);
                        if (data.length > 0) {
                            setSelectedLanguageId(data[0].pk);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchsyllabusLanguage();
    }, []);

    /**
     * Handles the form submission for creating a new topic details.
     */
    const handleTopicDetailsSubmit = async () => {
        setButtonLoading(true);
        const formData = new FormData();
        formData.append("language", selectedLanguageId);
        formData.append("topic", id);
        formData.append("name", title);
        formData.append("description", description);
        formData.append("image", selectedImage);
        formData.append("video_id", videoId);
        formData.append("is_primary", isChecked);
        // formData.append("attachment", selectedAttachment);

        learnConfig
            .post(`learn/sro/syllabus/create-topic-language/${id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, message } = response.data;
                if (status_code === 6000) {
                    setButtonLoading(false);
                    fetchLanguage();
                    setSuccessful(true);
                    setModalUse("New Topic Language Created Successfully ");
                    closeLanguageModal();
                } else {
                    setButtonLoading(false);
                    if (message?.message) {
                        setErrorMessage(message.message);
                    } else {
                        console.log("Some error occured");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    /**
     * Handles the form submission for editing a topic language.
     */
    const handleEditTopicLanguageSubmit = async () => {
        setButtonLoading(true);

        const formData = new FormData();
        formData.append("language", selectedLanguageId);
        if (userdata.title !== title) {
            formData.append("name", title);
        }
        if (userdata.thumbnail !== selectedImage) {
            formData.append("image", selectedImage);
        }
        if (userdata.description !== description) {
            formData.append("description", description);
        }
        if (userdata.videoName !== videoId) {
            if (videoId !== null) {
                formData.append("video_id", videoId);
            }
        }
        formData.append("topic", id);
        formData.append("is_primary", isChecked);

        learnConfig
            .post(
                `learn/sro/syllabus/update-topic-language/${topicLangId}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { status_code, message } = response.data;
                if (status_code === 6000) {
                    setButtonLoading(false);
                    fetchLanguage();
                    setSuccessful(true);
                    setModalUse("Topic Edited Successfully");
                    closeEditModal();
                } else {
                    setButtonLoading(false);
                    if (message?.message) {
                        setErrorMessage(message.message);
                    } else {
                        console.log("Some error occured");
                    }
                }
            })
            .catch((error) => {
                console.log(
                    "Error:",
                    error.response ? error.response.data : error.message
                );
                setButtonLoading(false);
            });
    };

    /**
     * Handles toggling the primary designation for a language.
     */
    const HandilEdiPrimarySelect = async () => {
        const formData = new FormData();
        formData.append("type", "topic");

        learnConfig
            .post(
                `learn/sro/syllabus/toggle-primary/${selectedLanguage?.id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { status_code, message } = response.data;
                if (status_code === 6000) {
                    console.log(message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    /**
     * Handles toggling the published status for a language.
     */
    const HandilPublish = async () => {
        const formData = new FormData();

        formData.append("type", "topic");

        learnConfig
            .post(
                `learn/sro/syllabus/toggle-published/${selectedLanguage?.id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { status_code, message } = response.data;
                if (status_code === 6000) {
                    console.log(message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleLanguageClick = (language) => {
        setSelectedLanguage(language);
        // fetchDataForLanguage(language.id);
    };

    useEffect(() => {
        if (selectedLanguage?.is_primary) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [selectedLanguage]);
    useEffect(() => {
        if (selectedLanguage?.is_published) {
            setToggle(true);
        } else {
            setToggle(false);
        }
    }, [selectedLanguage]);

    useEffect(() => {
        if (selectedForm === "edit-topic-language-form") {
            setTitle(userdata?.title);
            setDescription(userdata?.description);
            setSelectedImage(userdata?.thumbnail);
            setSelectedVideoName(userdata?.videoName);
            setSelectedAttachment(userdata?.attachment);
            setSelectedOption(userdata?.Language);
        } else {
            setTitle("");
            setDescription("");
            setSelectedImage("");
            setSelectedVideoName("");
            setSelectedAttachment("");
            setSelectedOption("");
        }
    }, [userdata, selectedForm]);

    useEffect(() => {
        if (isLanguageModalOpen || isEditModalOpen) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isLanguageModalOpen, isEditModalOpen]);

    const handleDownload = (url) => {
        if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.download = url.split("/").pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <>
            <MainContainer id="main" style={{ color: "#fff" }}>
                {isLoading ? (
                    <SectionLoader />
                ) : isPageLoading ? (
                    <SectionLoader />
                ) : (
                    <MainTop>
                        <MainHeading>{professionData.name}</MainHeading>
                        <SubHeading>
                            Professions / UI Engineering /
                            {selectedLanguage?.name}
                        </SubHeading>
                        <Overview data={professionOverview} />
                        <Language>
                            <Div length={professionData.language_data?.length}>
                                {professionData.language_data?.length > 0 && (
                                    <>
                                        {professionData.language_data?.map(
                                            (item) => (
                                                <Small
                                                    isActive={
                                                        item.language ===
                                                        selectedLanguage?.language
                                                    }
                                                    key={item.id}
                                                    className={
                                                        item.language ===
                                                        selectedLanguage?.language
                                                            ? "isActive"
                                                            : ""
                                                    }
                                                    onClick={() =>
                                                        handleLanguageClick(
                                                            item
                                                        )
                                                    }
                                                >
                                                    {item.language}
                                                </Small>
                                            )
                                        )}
                                    </>
                                )}
                            </Div>
                            <Button
                                onClick={() => {
                                    setSelectedForm("create-topic-detail-form");
                                    setIsChecked(false);
                                    openLanguageModal();
                                }}
                            >
                                <ImagePlus>
                                    <Img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-04-2024/plus-circle.svg"
                                        alt="image"
                                    />
                                </ImagePlus>
                                <Apply>Add new language</Apply>
                            </Button>
                        </Language>
                        <Container>
                            <EditInfo>
                                <Professions>
                                    <SubHead>{selectedLanguage.name}</SubHead>
                                    <ButtonProfession>Topic</ButtonProfession>
                                </Professions>
                                <Toggle>
                                    {!toggle && (
                                        <Time
                                            className={!toggle ? "active" : ""}
                                            to=""
                                        >
                                            Unpublish
                                        </Time>
                                    )}
                                    {toggle && (
                                        <Time
                                            onClick={() => {
                                                setToggle(!toggle);
                                                HandilPublish();
                                            }}
                                            className={!toggle ? "active" : ""}
                                        >
                                            Unpublish
                                        </Time>
                                    )}
                                    <ToggleContainer>
                                        <ToggleBackground
                                            className={toggle ? "activate" : ""}
                                            onClick={() => {
                                                toggle && setToggle(!toggle);
                                                HandilPublish();
                                            }}
                                            to={toggle ? "" : `?active=active`}
                                        >
                                            <Circle
                                                onClick={() => {
                                                    setToggle(!toggle);
                                                }}
                                                className={
                                                    toggle ? "activate" : ""
                                                }
                                                to={
                                                    toggle
                                                        ? ""
                                                        : `?active=active`
                                                }
                                            ></Circle>
                                        </ToggleBackground>
                                    </ToggleContainer>
                                    {toggle && (
                                        <Para
                                            className={toggle ? "active" : ""}
                                            to="?active=active"
                                        >
                                            Publish
                                        </Para>
                                    )}
                                    {!toggle && (
                                        <Para
                                            onClick={() => {
                                                setToggle(!toggle);
                                                HandilPublish();
                                            }}
                                            className={toggle ? "active" : ""}
                                            to={toggle ? "" : `?active=active`}
                                        >
                                            Publish
                                        </Para>
                                    )}
                                    <EditButton
                                        onClick={() => {
                                            setSelectedForm(
                                                "edit-topic-language-form"
                                            );
                                            openEditModal();
                                            setTopicLangId(
                                                selectedLanguage?.id
                                            );
                                        }}
                                    >
                                        <ImageEdit>
                                            <Img
                                                src={
                                                    require("../../../assets/images/edit-skill.svg")
                                                        .default
                                                }
                                                alt="image"
                                            />
                                        </ImageEdit>
                                        <Info>Edit info</Info>
                                    </EditButton>
                                </Toggle>
                            </EditInfo>
                            <Hr />
                        </Container>
                    </MainTop>
                )}

                {isLoading ? (
                    <SectionLoader />
                ) : isPageLoading ? (
                    <SectionLoader />
                ) : (
                    <VideoSections>
                        <SubVideoSections>
                            <FirstSection>
                                <TopicVideo>Topic Video</TopicVideo>
                                <VideoPlayer
                                    cover={selectedLanguage?.image}
                                    source={
                                        selectedLanguage?.video_url
                                            ? selectedLanguage?.video_url
                                            : null
                                    }
                                    {...videoJsOptions}
                                    autoPlay={false}
                                />
                            </FirstSection>
                            <FirstSection>
                                <TopicVideo>Thumbnail image</TopicVideo>
                                <Imgforms>
                                    <Img
                                        src={selectedLanguage?.image}
                                        alt="image"
                                    />
                                </Imgforms>
                            </FirstSection>
                            <FirstSection>
                                <TopicVideo>Description</TopicVideo>
                                <Paragraphs>
                                    {selectedLanguage?.description}
                                </Paragraphs>
                            </FirstSection>
                            {selectedLanguage?.attachment === null ? null : (
                                <Attachments>
                                    <TopicVideo>Attachments</TopicVideo>
                                    <SubAttachments
                                        onClick={() =>
                                            handleDownload(
                                                professionData?.attachment
                                            )
                                        }
                                    >
                                        <Mb>
                                            <ImgAttachments as={Link}>
                                                <Img
                                                    src={
                                                        require("../../../../src/assets/images/Attachment-empty-green.svg")
                                                            .default
                                                    }
                                                    alt="image"
                                                />
                                            </ImgAttachments>
                                            <Mp3>
                                                <Spans>
                                                    {
                                                        selectedLanguage?.attachment
                                                    }
                                                </Spans>
                                            </Mp3>
                                        </Mb>
                                        <Views>Download</Views>
                                    </SubAttachments>
                                </Attachments>
                            )}

                            <CheckContainer>
                                <CheckBoxContainer
                                    isChecked={isChecked}
                                    onClick={handleToggle}
                                >
                                    <CheckBox>
                                        {isChecked ? (
                                            <Img
                                                className="checked"
                                                src={
                                                    require("../../../assets/images/checkmark.svg")
                                                        .default
                                                }
                                                alt="Checked Image"
                                            />
                                        ) : null}
                                    </CheckBox>
                                </CheckBoxContainer>
                                <Label
                                    isChecked={isChecked}
                                    onClick={() => {
                                        handleToggle();
                                    }}
                                >
                                    Set as primary language
                                </Label>
                            </CheckContainer>
                        </SubVideoSections>
                    </VideoSections>
                )}
                <CreateLanguageModal
                    id={id}
                    isOpen={isLanguageModalOpen}
                    onClose={closeLanguageModal}
                    setTitle={setTitle}
                    title={title}
                    setDescription={setDescription}
                    description={description}
                    setSelectedImage={setSelectedImage}
                    selectedImage={selectedImage}
                    setSelectedLanguageId={setSelectedLanguageId}
                    selectedLanguageId={selectedLanguageId}
                    syllabusLanguages={syllabusLanguages}
                    handSubmit={handleTopicDetailsSubmit}
                    isButtonLoading={isButtonLoading}
                    selectedForm={selectedForm}
                    setVideoId={setVideoId}
                    selectedVideoName={selectedVideoName}
                    setErrorMessage={setErrorMessage}
                    errorMassage={errorMassage}
                />
                <EditModal
                    id={id}
                    isOpen={isEditModalOpen}
                    onClose={closeEditModal}
                    setTitle={setTitle}
                    title={title}
                    setSelectedImage={setSelectedImage}
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    setDescription={setDescription}
                    description={description}
                    selectedImage={selectedImage}
                    setSelectedLanguageId={setSelectedLanguageId}
                    selectedLanguageId={selectedLanguageId}
                    syllabusLanguages={syllabusLanguages}
                    setSelectedAttachment={setSelectedAttachment}
                    selectedAttachment={selectedAttachment}
                    handSubmit={handleEditTopicLanguageSubmit}
                    isButtonLoading={isButtonLoading}
                    isUploadLoading={isUploadLoading}
                    selectedForm={selectedForm}
                    setVideoId={setVideoId}
                    selectedVideoName={selectedVideoName}
                    selectedOption={selectedOption}
                    setErrorMessage={setErrorMessage}
                    errorMassage={errorMassage}
                />
                <SuccessModal
                    modalUse={modalUse}
                    setSuccessful={setSuccessful}
                    successful={successful}
                    setReload={setReload}
                />
            </MainContainer>
        </>
    );
}
export default SyllabusMainTopics;

const MainContainer = styled.div``;
const MainTop = styled.section``;
const MainHeading = styled.h1`
    font-size: 30px;
    color: #fff;
    font-family: gordita_medium;
    margin-bottom: 10px;
    @media all and (max-width: 1280px) {
        font-size: 25px;
    }
`;
const SubHeading = styled.h3`
    font-size: 14px;
    font-family: gordita_regular;
    margin-bottom: 20px;
    color: #b7b7b7;
`;

const Img = styled.img`
    display: block;
    width: 100%;
`;
const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
`;

const Small = styled.small`
    background-color: ${({ isActive }) =>
        isActive ? "rgb(31 31 31)" : "transparent"};
    font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
    color: ${({ isActive }) => (isActive ? "rgb(254, 103, 58)" : "#667085")};
    padding: 10px;

    &:hover {
        color: rgb(254, 103, 58);
        cursor: pointer;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
        &:hover {
            padding: 8px;
        }
    }
    @media all and (max-width: 480px) {
        &:hover {
            padding: 5px;
        }
    }
`;
const Language = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    margin-right: 15px;
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 9px;
    border: 1px solid #3b3b3b;
    cursor: pointer;
`;
const Apply = styled.a`
    color: #fff;
    font-family: "gordita_medium";
    width: 100%;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const ImagePlus = styled.div`
    width: 14%;
    margin-right: 10px;
    @media all and (max-width: 1080px) {
        width: 12%;
    }
    @media all and (max-width: 360px) {
        width: 36%;
    }
`;
const Container = styled.div`
    background-color: #1b1c1f;
    padding: 20px;
    width: 100%;
    border-radius: 5px;
    @media (max-width: 480px) {
        padding: 15px;
    }
`;
const EditInfo = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;
const Hr = styled.hr`
    border-bottom: 1px solid #3b3b3b;
    margin: 10px;
`;
const Toggle = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 640px) {
        width: 100%;
        justify-content: end;
    }
`;
const Time = styled(Link)`
    margin-right: 20px;
    color: #9c9c9c;
    font-size: 14px;
    font-family: gordita_regular;
    @media (max-width: 480px) {
        font-size: 12px;
    }
    cursor: pointer;
    &.active {
        color: #fff;
    }
    @media (max-width: 768px) {
        margin-right: 10px;
    }
`;
const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    @media (max-width: 768px) {
        margin-right: 10px;
    }
`;
const ToggleBackground = styled(Link)`
    position: relative;
    height: 19px;
    width: 40px;
    background-color: #401a17;
    border-radius: 15px;
    &.activate {
        background-color: #fe673a;
    }
    @media (max-width: 480px) {
        height: 18px;
        width: 32px;
    }
`;
const Circle = styled(Link)`
    position: absolute;
    left: 4px;
    top: 1.5px;
    height: 16px;
    width: 16px;
    background-color: #fe673a;
    transition: 0.4s;
    border-radius: 50%;
    &.activate {
        left: 20px;
        background-color: #fff;
    }
    @media (max-width: 480px) {
        height: 14px;
        width: 14px;
        &.activate {
            left: 14px;
        }
    }
`;

const Para = styled(Link)`
    color: #9c9c9c;
    font-size: 14px;
    margin-right: 30px;
    @media (max-width: 360px) {
        font-size: 12px;
    }
    cursor: pointer;
    &.active {
        color: #fff;
    }
    @media (max-width: 768px) {
        margin-right: 10px;
    }
    @media (max-width: 480px) {
        font-size: 12px;
    }
`;
const Professions = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 640px) {
        margin-bottom: 10px;
    }
`;
const SubHead = styled.h3`
    font-size: 24px;
    margin-right: 15px;
    font-family: gordita_medium;
    @media all and (max-width: 1080px) {
        font-size: 20px;
    }
    @media all and (max-width: 768px) {
        font-size: 17px;
    }
`;
const ButtonProfession = styled.button`
    padding: 2px 10px;
    border: 1px solid #fff;
    border-radius: 25px;
    background-color: #fff;
    font-size: 15px;
    color: #364152;
    font-family: gordita_medium;
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;
const ImageEdit = styled.div`
    width: 20%;
`;
const EditButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border-radius: 9px;
    border: 1px solid #fe673a;
    cursor: pointer;
    gap: 10px;
    @media all and (max-width: 640px) {
        padding: 5px 5px;
    }
    @media all and (max-width: 480px) {
        gap: 1px;
    }
`;
const Info = styled.span`
    font-size: 14px;
    color: #fe673a;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const VideoSections = styled.section`
    padding: 10px;
    background-color: rgb(27, 28, 31);
`;
const SubVideoSections = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const FirstSection = styled.div`
    margin-bottom: 20px;
    width: 60%;
`;
const TopicVideo = styled.h3`
    font-size: 15px;
    color: #cdd5df;
    margin-bottom: 5px;
`;
const Imgs = styled.div`
    /* width: 45%; */
    width: 100%;
`;
const Paragraphs = styled.p`
    width: 90%;
    color: #fff;
    font-size: 16px;
`;
const Imgforms = styled.div`
    width: 40%;
`;
const Attachments = styled.div`
    width: 61%;
`;
const SubAttachments = styled.div`
    border: 1px solid #3d3d3d;
    padding: 10px 10px;
    width: 100%;
    border-radius: 9px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #161619;
    cursor: pointer;
`;
const Mb = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const ImgAttachments = styled.div`
    width: 12%;
`;
const Mp3 = styled.div``;
const Spans = styled.h2`
    font-size: 14px;
    color: #fff;
`;
const Smalls = styled.span`
    font-size: 14px;
`;
const Views = styled.a`
    font-size: 14px;
    color: #fe673a;
`;

const CheckContainer = styled.div`
    width: 35%;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    @media all and (max-width: 1080px) {
        width: 39%;
    }
    @media all and (max-width: 980px) {
        width: 65%;
    }
    @media all and (max-width: 480px) {
        width: 67%;
    }
`;
const CheckBoxContainer = styled.div`
    border: 1px solid #fb663a;
    padding: ${({ isChecked }) => (isChecked ? "1px" : "10px")};
    background-color: #fff;
    border-radius: 4px !important;
    cursor: pointer;
    @media all and (max-width: 980px) {
        padding: ${({ isChecked }) => (isChecked ? "0px" : "9px")};
    }
`;
const CheckBox = styled.div`
    width: 100%;
    @media all and (max-width: 1080px) {
        width: 95%;
    }
`;
const Label = styled.label`
    font-size: 16px;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        font-size: 14px;
    }
`;
// import { Title } from "chart.js";
// import React from "react";
// import styled from "styled-components";

// function SyllabusMainTopic() {
//     return (
//         <Heading>
//             <h1>Syllabus Topic</h1>
//         </Heading>
//     );
// }

// export default SyllabusMainTopic;
// const Heading = styled.h1`
//     color: #fff;
//     padding: 10px;
// `;
