import React from 'react'
import styled from 'styled-components';

function SchoolScientistDashboard() {
  return (
    <>
         <MainContainer >
            <TopContainer>
                <Heading>Level Attended Status</Heading>
            </TopContainer>
        </MainContainer>
    </>
  )
}
const MainContainer = styled.div``;
const TopContainer = styled.div`
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
    margin-bottom: 20px;
    margin-top: 30px;
`;

export default SchoolScientistDashboard