import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../../axiosConfig";
import { Context } from "../../../../contexts/Store";
import ButtonLoader from "../../../../genaral/Loader/ButtonLoader";

export default function CreateTrainerModal({
    showModal,
    setShowModal,
    successful,
    setSuccessful,
    setReload,
}) {
    const initialDescription = "";

    const [file, setFile] = useState(null);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [nameError, setNameError] = useState("");
    const [prefError, setPrefError] = useState("");
    const [fileError, setFileError] = useState("");
    const [hasChanges, setHasChanges] = useState(false);

    const checkForChanges = useCallback(() => {
        setHasChanges(
            designation !== initialDescription && name !== initialDescription
        );
    }, [designation, name, initialDescription]);

    useEffect(() => {
        checkForChanges();
    }, [checkForChanges]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const ResetState = () => {
        setNameError("");
        setFileError("");
        setPrefError("");
        setName("");
    };

    // api connection for create trainer

    const handleCreateTrainer = () => {
        // Validation

        if (!name) {
            setNameError("Name is required");
        } else {
            setNameError("");
        }
        if (!designation) {
            setPrefError("Profession is required");
        } else {
            setPrefError("");
        }
        if (!file) {
            setFileError("Photo is required");
        } else {
            setFileError("");
        }

        if (!hasChanges) {
            return;
        }
        const formData = new FormData();
        formData.append("display_pic", file);
        formData.append("name", name);
        formData.append("designation", designation);

        setButtonLoading(true);
        learnConfig
            .post("events/host/create/", formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, data } = response.data;
                if (status_code === 6000) {
                    setShowModal(false);
                    setButtonLoading(false);
                    ResetState();
                    setSuccessful(true);
                    setReload(true);
                } else {
                    setButtonLoading(false);
                    setErrorMessage(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    return (
        <>
            {showModal && (
                <Backdrop>
                    <Modal>
                        <Container>
                            <TopContainer>
                                <span>Create Trainer</span>
                                <img
                                    onClick={() => {
                                        setShowModal(false);
                                        ResetState();
                                    }}
                                    src={
                                        require("../../../../../assets/images/close-icon.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            </TopContainer>
                            <FormContainer>
                                <Label>Name</Label>
                                <InputFiled
                                    maxLength={35}
                                    placeholder="Type here"
                                    onChange={(e) => {
                                        const inputText = e.target.value;
                                        setName(inputText);
                                    }}
                                    onKeyDown={(e) => {
                                        const keyCode = e.keyCode || e.which;

                                        if (
                                            (keyCode >= 65 && keyCode <= 90) ||
                                            (keyCode >= 97 && keyCode <= 122) ||
                                            keyCode === 32 ||
                                            keyCode === 8 ||
                                            keyCode === 9 ||
                                            keyCode === 13 ||
                                            keyCode === 37 ||
                                            keyCode === 39 ||
                                            keyCode === 46
                                        ) {
                                            return true;
                                        } else {
                                            e.preventDefault();
                                            return false;
                                        }
                                    }}
                                />
                                <Error className="error">{nameError}</Error>
                                <Label>Profession</Label>
                                <InputFiled
                                    placeholder="Type profession"
                                    onChange={(e) =>
                                        setDesignation(e.target.value)
                                    }
                                    maxLength={40}
                                    onKeyDown={(e) => {
                                        const keyCode = e.keyCode || e.which;

                                        if (
                                            (keyCode >= 65 && keyCode <= 90) ||
                                            (keyCode >= 97 && keyCode <= 122) ||
                                            keyCode === 32 ||
                                            keyCode === 188 ||
                                            keyCode === 172 ||
                                            keyCode === 8 ||
                                            keyCode === 9 ||
                                            keyCode === 13 ||
                                            keyCode === 37 ||
                                            keyCode === 39 ||
                                            keyCode === 46
                                        ) {
                                            return true;
                                        } else {
                                            e.preventDefault();
                                            return false;
                                        }
                                    }}
                                />
                                <Error className="error">{prefError}</Error>
                                <Label>Photo</Label>
                                <FileContainer>
                                    <InputFileField
                                        fileSelected={file !== null}
                                    >
                                        <input
                                            id="photo"
                                            className="file"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <img
                                            src={
                                                require("../../../../../assets/images/FileUpload.svg")
                                                    .default
                                            }
                                            alt="FileUpload"
                                        />
                                        <span>
                                            {file ? file.name : "Upload photo"}
                                        </span>
                                    </InputFileField>
                                </FileContainer>
                                <Error className="error">{fileError}</Error>
                            </FormContainer>
                            <CreateButtonBox>
                                <CancelButton
                                    onClick={() => {
                                        setShowModal(false);
                                        ResetState();
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                                <CreateButton
                                    style={{
                                        cursor:
                                            name && designation && file
                                                ? "pointer"
                                                : "not-allowed",
                                    }}
                                    onClick={handleCreateTrainer}
                                >
                                    {" "}
                                    {isButtonLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Create"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        </Container>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
}

const Error = styled.p`
    font-size: 12px;
    color: #ff3333;
    margin-left: 2px;
    text-align: right;
`;
const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    width: 100%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 1000;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(59, 59, 59, 1);
    span {
        color: white;
    }
    img {
        cursor: pointer;
        width: 40px;
    }
`;

const FormContainer = styled.div`
    margin-top: 10px;
`;

const Label = styled.label`
    color: rgba(123, 123, 123, 1);
`;

const InputFiled = styled.input`
    width: 100%;
    color: #fff;
    padding: 10px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    margin: 10px 0px;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: "gordita_medium";
        font-weight: lighter;
        font-style: normal;
    }
`;

const InputFileField = styled.label`
    position: relative;
    width: 100%;
    color: #fff;
    padding: 10px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    align-items: center;

    input {
        display: none;
    }

    img {
        margin-right: 10px;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 1;
        color: ${(props) =>
            props.fileSelected ? "#fff" : "rgba(79, 83, 96, 1)"};
        cursor: pointer;
    }
`;

const FileContainer = styled.div`
    position: relative;
    margin: 10px 0;
    img {
        position: absolute;
        right: 3px;
        top: 8px;
    }
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;

const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
