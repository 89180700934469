import React from 'react'
import SchoolCategory from './SchoolCategory'
import styled  from 'styled-components'
import SchoolScientistStudents from './SchoolScientistStudents';
import HSstudentsList from './HSstudetsList'   

function StudentsHS() {
  return (
    <Container id="main">
        <SchoolScientistStudents />
        <SchoolCategory /> 
        <HSstudentsList />
    </Container>
  )
}
const Container = styled.div`
  
`;

export default StudentsHS