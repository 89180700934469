import React, { lazy } from "react";
import styled from "styled-components";
import { Switch, Route, Routes } from "react-router-dom";
import ChallengesListRouter from "./ChallengesListRouter";
const Challenges = lazy(() => import("../../../screens/challenges/Challenges"));

function ChallengesRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route exact path="/" element={<Challenges />} />
                <Route
                    path="/:challenge_id/*"
                    element={<ChallengesListRouter />}
                />
            </Routes>
        </Container>
    );
}

export default ChallengesRouter;

const Container = styled.div``;
