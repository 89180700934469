import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../../axiosConfig";
import { Context } from "../../../../contexts/Store";
import ButtonLoader from "../../../../genaral/Loader/ButtonLoader";

export default function AddNote({
    cards,
    showModal,
    setShowModal,
    setSuccessful,
    setReload,
}) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const initialDescription = cards?.description ?? "--";

    const [description, setDescription] = useState(initialDescription);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setHasChanges(description !== initialDescription);
    }, [description]);

    // handle

    const handleDesc = (e) => {
        setDescription(e.target.value);
    };

    // api connection for add note

    const handleFormSubmit = () => {
        if (!hasChanges) {
            return;
        }
        const formData = new FormData();
        formData.append("description", description);
        setButtonLoading(true);
        learnConfig
            .post(`events/edit/event/${cards.id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { status_code, data, message } = response.data;
                if (status_code === 6000) {
                    setSuccessful(true);
                    setShowModal(false);
                    // setReload(true);
                    setButtonLoading(false);
                } else {
                    setButtonLoading(false);
                    setErrorMessage(message);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    return (
        <>
            {showModal && (
                <Backdrop>
                    <Modal>
                        <Container>
                            <TopContainer>
                                <span>Add Note</span>
                                <img
                                    onClick={() => setShowModal(false)}
                                    src={
                                        require("../../../../../assets/images/close-icon.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            </TopContainer>
                            <FormContainer>
                                <Label>Note</Label>
                                <InputFiled
                                    placeholder="Type here"
                                    onChange={handleDesc}
                                    value={description}
                                />
                            </FormContainer>
                            <CreateButtonBox>
                                <CancelButton
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </CancelButton>
                                <CreateButton
                                    onClick={handleFormSubmit}
                                    disabled={!hasChanges || isButtonLoading}
                                    className={
                                        !hasChanges || isButtonLoading
                                            ? "disabled-button"
                                            : ""
                                    }
                                >
                                    {isButtonLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Submit"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        </Container>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
}

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    width: 100%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 1000;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(59, 59, 59, 1);
    span {
        color: white;
    }
    img {
        cursor: pointer;
        width: 40px;
    }
`;

const FormContainer = styled.div`
    margin-top: 10px;
`;

const Label = styled.label`
    color: rgba(123, 123, 123, 1);
`;

const InputFiled = styled.textarea`
    width: 100%;
    color: #fff;
    height: 200px;
    padding: 10px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    margin: 10px 0px;
    scroll-behavior: smooth;
    resize: none;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
        font-family: "gordita_medium";
        font-weight: lighter;
        font-style: normal;
    }
`;

const InputFileField = styled.label`
    position: relative;
    width: 100%;
    color: #fff;
    padding: 10px;
    border: 1px solid #26272a;
    background-color: #161619 !important;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    align-items: center;

    input {
        display: none;
    }

    img {
        margin-right: 10px;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 1;
        color: ${(props) =>
            props.fileSelected ? "#fff" : "rgba(79, 83, 96, 1)"};
        cursor: pointer;
    }
`;

const FileContainer = styled.div`
    position: relative;
    margin-top: 10px;
    img {
        position: absolute;
        right: 3px;
        top: 8px;
    }
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    &.disabled-button {
        cursor: not-allowed;
        background: #525252;
    }
`;

const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
