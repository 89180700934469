import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import TechUpdateSortModal from "./modal/TechUpdateSortModal";

import { Context } from "../../contexts/Store";
import ContentManagerNewFilter from "./ContentManagerNewFilter";
import { learnConfig } from "../../../axiosConfig";

function TechUpdateTop() {
    const [activeTab, setActiveTab] = useState("all");
    const [isSort, setSort] = useState(false);
    const [isFilter, setFilter] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);

    //filter
    const [activeClick, setActiveClick] = useState("Filter");
    const [isDropdown, setDropdown] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [accountStatus, setAccountStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [subscriptionDay, setSubscriptionDay] = useState("");
    const [eligibility, setEligibility] = useState("");
    const [dateType, setDateType] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const wrapperRef = useRef(null);

    const {
        state: {
            user_data: { role, access_token },
        },
        dispatch,
    } = useContext(Context);

    const navigate = useNavigate();

    // add and delete searchword "q"
    useEffect(() => {
        if (searchWord) {
            setSearchParams({
                ...currentParams,
                q: searchWord,
            });
        } else {
            const newObj = { ...currentParams };
            delete newObj["q"];
            setSearchParams(newObj);
        }
    }, [searchWord]);

    // setting params for switching tabs
    const handleTabSwitch = (tab) => {
        setSearchParams({
            ...currentParams,
            blog_status: tab,
        });
    };
    const sortingArticles = (value) => {
        setSearchParams({
            ...currentParams,
            sort: value,
        });
    };

    // outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDropdown(false);
                    setSort(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    const handleCreateArticle = () => {
        learnConfig
            .post(
                "tech-updates/create-article/",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    navigate(
                        `/${role[0].replace("_", "-")}/create/${
                            data.article_pk
                        }`
                    );
                }
            })
            .catch((err) => console.log("err", err));
    };

    return (
        <Top>
            <SubContainer>
                <MenuConainer>
                    <MenuText
                        onClick={() => {
                            setActiveTab("all");
                            handleTabSwitch("all");
                        }}
                        className={activeTab === "all" && "active"}
                    >
                        All
                    </MenuText>
                    <MenuText
                        onClick={() => {
                            setActiveTab("publish");
                            handleTabSwitch("published");
                        }}
                        className={activeTab === "publish" && "active"}
                    >
                        Published
                    </MenuText>
                    <MenuText
                        onClick={() => {
                            setActiveTab("in_draft");
                            handleTabSwitch("draft");
                        }}
                        className={activeTab === "in_draft" && "active"}
                    >
                        Drafts
                    </MenuText>
                </MenuConainer>
                <InputContainer>
                    <SearchContainer>
                        <SearchIcon>
                            <img
                                src={
                                    require("../../../assets/images/search.svg")
                                        .default
                                }
                                alt="Search"
                            />
                        </SearchIcon>
                        <InputFiled
                            type="search"
                            value={searchWord}
                            onChange={(e) => setSearchWord(e.target.value)}
                            placeholder="Search…"
                        />
                    </SearchContainer>

                    <Sort>
                        <SortDiv
                            onClick={() => {
                                setFilter(false);
                                setSort(!isSort);
                            }}
                        >
                            <Small className="white">Sort by</Small>
                            <Span>
                                <img
                                    src={
                                        require("../../../assets/images/sort.svg")
                                            .default
                                    }
                                    alt="Sort"
                                />
                            </Span>
                        </SortDiv>
                    </Sort>
                    <TechUpdateSortModal
                        sortingArticles={sortingArticles}
                        isSort={isSort}
                        setSort={setSort}
                        wrapperRef={wrapperRef}
                    />
                    <FilterContainer>
                        <RightContainer>
                            <ContentManagerNewFilter
                                activeClick={activeClick}
                                isDropdown={isDropdown}
                                setDropdown={setDropdown}
                                setActiveClick={setActiveClick}
                                // setButton={setButton}
                                // isButton={isButton}
                                searchWord={searchWord}
                                setSearchWord={setSearchWord}
                                wrapperRef={wrapperRef}
                                // isContinue={isContinue}
                                accountStatus={accountStatus}
                                setAccountStatus={setAccountStatus}
                                paymentStatus={paymentStatus}
                                setPaymentStatus={setPaymentStatus}
                                subscriptionDay={subscriptionDay}
                                setSubscriptionDay={setSubscriptionDay}
                                eligibility={eligibility}
                                setEligibility={setEligibility}
                                dateType={dateType}
                                setDateType={setDateType}
                                fromDate={fromDate}
                                setFromDate={setFromDate}
                                toDate={toDate}
                                setToDate={setToDate}
                            />
                        </RightContainer>
                    </FilterContainer>
                    <CreateContainer
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCreateArticle();
                            dispatch({
                                type: "UPDATE_EDITOR_VIEW",
                                isView: false,
                            });
                        }}
                    >
                        <Span>
                            <img
                                src={
                                    require("../../../assets/images/plus.svg")
                                        .default
                                }
                                alt="plus"
                            />
                        </Span>
                        <Small className="whites">Create article</Small>
                    </CreateContainer>
                </InputContainer>
            </SubContainer>
        </Top>
    );
}

export default TechUpdateTop;

const Top = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const SubContainer = styled.div`
    border-bottom: 1px solid #2e2e2e;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-width: 1280px;
    padding-bottom: 15px;
`;

const MenuConainer = styled.div`
    display: flex;
    width: 20%;
`;

const MenuText = styled.h5`
    color: #7b7b7b;
    font-size: 16px;
    font-family: gordita_regular;
    margin-right: 14px;
    height: 16px;
    cursor: pointer;
    position: relative;
    &.active {
        color: #fe673a;
        &::before {
            content: "";
            width: 100%;
            height: 4px;
            background-color: #fe673a;
            border: 4px;
            position: absolute;
            bottom: -18px;
            border-radius: 5px;
        }
        /* border-radius: 3px;
        border-bottom: 2px solid #fe673a; */
        /* padding-bottom: 10%; */
    }
`;

const InputContainer = styled.div`
    width: 55%;
    display: flex;
    justify-content: space-between;
    /* padding-bottom: 10px; */
    @media all and (max-width: 1380px) {
        width: 64%;
    }
`;
const SearchContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    display: flex;
    width: 45%;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
`;

const SearchIcon = styled.div`
    img {
        display: block;
        width: 100%;
    }
`;

const InputFiled = styled.input`
    width: 100%;
    color: #4f5360;
    font-size: 18px;
    line-height: normal;
`;

const Sort = styled.button`
    position: relative;
    img {
        display: block;
        width: 100%;
    }
`;
const SortDiv = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const Span = styled.span`
    width: 24px;
`;
const Small = styled.small`
    color: #7b7b7b;
    font-size: 0.875rem;
    font-family: gordita_medium;

    cursor: pointer;
    &.white {
        color: #fff;

        margin-right: 8px;
    }
    &.whites {
        color: #f2f2f2;

        line-height: normal;
        margin-right: 0;
        margin-left: 4px;
    }
`;

const Filter = styled.button`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;

    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const CreateContainer = styled.div`
    display: flex;
    height: 44px;
    padding: 8px 16px;
    align-items: center;
    border-radius: 8px;
    background: #fe673a;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 460px) {
        align-items: flex-end;
    }
`;

const TextArea = styled.div`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 460px) {
        margin-bottom: 15px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
`;
