import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import StatusModal from "./modal/StatusModal";
import { getDDMMYY } from "../../helpers/functions";
import { accountsConfig, activityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import StatusLogModal from "./modal/StatusLogModal";
import SectionLoader from "../../helpers/SectionLoader";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import PhoneNumberModal from "./modal/PhoneNumberModal";

function RmStudentCard({
    student,
    activityList,
    setStatusUpdated,
    pagination,
    index,
    errorMessege,
    fetchCallStatus,
    isActivityLoading,
    setReload,
}) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const [isModal, setModal] = useState(false);
    const [isPhoneModal, setPhoneModal] = useState(false);
    const [isLogModal, setLogModal] = useState(false);
    const [statusSelect, setStatusSelect] = useState("");
    const [textarea, setTextarea] = useState(false);
    const [textareaValue, setTextareaValue] = useState("");
    const [modalType, setModalType] = useState("status");
    const [isSelected, setIsSelected] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [selectedStatusID, setSelectedStatusID] = useState("");
    const [studentID, setStudentID] = useState("");
    const [phoneData, setPhoneData] = useState([]);

    const handleModal = () => (
        setModal((prev) => !prev),
        setStatusSelect(""),
        setTextarea(false),
        setTextareaValue(""),
        setModalType(""),
        setIsSelected(false),
        setSelectedStatusID("")
    );

    const handleLogModal = () => setLogModal((prev) => !prev);

    const handlePhoneModal = () => setPhoneModal((prev) => !prev);
    //------------fetch status---------------------------------------

    const fetchStatus = () => {
        setLoading(true);
        activityConfig
            .get(`followups/srm/student-statuses/${student.id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                console.log(data);
                if (StatusCode === 6000) {
                    setStatuses(data);
                    setLoading(false);
                    handleLogModal();
                } else {
                    setLoading(false);
                    handleLogModal();
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const fetchStudentPhone = () => {
        accountsConfig
            .get(`api/v1/users/srm/student-guardian/${student.user_pk}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPhoneData(data);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // useEffect(() => {}, []);

    return (
        <>
            <StatusModal
                isModal={isModal}
                handleModal={handleModal}
                statusSelect={statusSelect}
                setStatusSelect={setStatusSelect}
                textarea={textarea}
                setTextarea={setTextarea}
                textareaValue={textareaValue}
                setTextareaValue={setTextareaValue}
                modalType={modalType}
                setModalType={setModalType}
                isSelected={isSelected}
                student={student}
                setIsSelected={setIsSelected}
                activityList={activityList}
                setSelectedStatusID={setSelectedStatusID}
                selectedStatusID={selectedStatusID}
                setStatusUpdated={setStatusUpdated}
                errorMessege={errorMessege}
                isActivityLoading={isActivityLoading}
                setStudentID={setStudentID}
                setReload={setReload}
            />
            <PhoneNumberModal
                isModal={isPhoneModal}
                handlePhoneModal={handlePhoneModal}
                phoneData={phoneData}
                setStudentID={setStudentID}
            />
            <MainContainer>
                {pagination.first_item <= pagination.last_item && (
                    <Item type="first">{pagination.first_item + index}</Item>
                )}
                <Item type="name">
                    <Left>
                        <div>
                            <span>{student.student_name.charAt(0)}</span>
                        </div>
                    </Left>
                    <Right>
                        <span className="name">
                            {student.student_name.toLowerCase()}
                        </span>
                        <span className="id">#{student.user_pk}</span>
                    </Right>
                </Item>
                <Item type="category">{student.user_data.category}</Item>
                <Item type="phone" className="phone">
                    <div>
                        {student.user_data.country_code} {student.student_phone}
                    </div>
                    <small
                        onClick={(e) => {
                            handlePhoneModal();
                            setStudentID(student.id);
                            fetchStudentPhone();
                            e.preventDefault();
                        }}
                    >
                        View More
                    </small>
                </Item>

                <Item type="freetrial">
                    {JSON.stringify(student.user_data.free_trail)}
                </Item>
                <Item type="activity">
                    {getDDMMYY(student.student_activity)}
                </Item>
                <Item type="status" status={student.followup_status}>
                    <span>
                        {student.followup_status === "switched-off"
                            ? "Switched Off"
                            : student.followup_status === "not-attended"
                            ? "Not Attended"
                            : student.followup_status === "attended"
                            ? "Attended"
                            : student.followup_status === "busy"
                            ? "Call Later / Busy"
                            : "--"}
                    </span>
                    {student.followup_status === "pending" ||
                    student.followup_status === "Pending" ? (
                        <></>
                    ) : isLoading ? (
                        <ImageContainer>
                            <ButtonLoader />
                        </ImageContainer>
                    ) : (
                        <ImageContainer
                            onClick={() => {
                                fetchStatus();
                            }}
                        >
                            <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/tooltip.png" />
                        </ImageContainer>
                    )}
                    {/* {statuses.length > 0 && (
                        <> */}
                    <StatusLogModal
                        statuses={statuses}
                        handleLogModal={handleLogModal}
                        isLogModal={isLogModal}
                        isLoading={isLoading}
                    />
                    {/* </>
                    )} */}
                </Item>
                <Item type="reason">{student.followup_reason}</Item>
                <Item type="action">
                    <ActionDiv
                        type={student.followup_status}
                        onClick={(e) => {
                            student.followup_status !== "attended"
                                ? handleModal() ||
                                  fetchCallStatus() ||
                                  setStudentID(student.id)
                                : e.preventDefault();
                        }}
                    >
                        {isActivityLoading && student.id === studentID ? (
                            <ButtonLoader />
                        ) : student.followup_status === "attended" ? (
                            "Attended"
                        ) : (
                            "Update"
                        )}
                    </ActionDiv>
                </Item>
            </MainContainer>
        </>
    );
}
export default RmStudentCard;

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    font-size: 13px;
    border-bottom: 2px solid #161618;
    :last-child {
        border-bottom: 0px solid #000;
    }
    min-width: 1100px;
`;
const Item = styled.div`
    text-transform: capitalize;
    width: ${({ type }) =>
        type === "first"
            ? "5%"
            : type === "phone"
            ? "12%"
            : type === "freetrial"
            ? "8%"
            : type === "activity"
            ? "12%"
            : type === "status"
            ? "10%"
            : type === "category"
            ? "10%"
            : type === "name"
            ? "19%"
            : type === "reason"
            ? "13%"
            : type === "action"
            ? "10%"
            : ""};

    display: ${({ type }) =>
        type === "name" || type === "action" ? "flex" : "flex"};
    align-items: center;
    justify-content: ${({ type }) => (type === "action" ? "center" : "")};
    font-size: 13px;
    color: ${({ status }) =>
        status === "attended"
            ? "#0FA76F"
            : status === "not-attended"
            ? "#C73838"
            : status === "switched-off"
            ? "#C73838"
            : status === "busy"
            ? "#FE673A"
            : ""};
    &.status {
        width: 15%;
    }
    &.phone {
        flex-direction: column;
        align-items: flex-start;
        & div {
            margin-bottom: 4px;
        }
        & small {
            font-size: 12px;
            color: #da5b36;
            font-family: "gordita_medium";
            cursor: pointer;
        }
    }
`;
const Right = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 13px;
    width: 80%;
    .id {
        font-size: 12px;
        color: #da5b36;
    }
    .name {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
    }
    span {
        text-transform: capitalize;
    }
`;
const Left = styled.div`
    margin-right: 10px;
    div {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid #da5b36;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        color: #da5b36;
        font-size: 13px;
        font-family: "gordita_medium";
    }
`;
const ActionDiv = styled.div`
    width: 120px;
    height: 40px;
    font-size: 13px;
    border: 1px solid #da5b36;
    text-transform: capitalize;
    border-radius: 5px;
    background: ${({ type }) => (type === "attended" ? "#da5b36" : "")};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ type }) => (type === "attended" ? "not-allowed" : "pointer")};
`;

const ImageContainer = styled.div`
    margin-left: 6px;
    cursor: pointer;
    width: 16px;
    img {
        width: 100%;
        display: block;
    }
`;

const ContainerTool = styled.div`
    background: #1b1c20;
    color: white;
    border: 1px solid #3b3b3b;
    border-radius: 8px;
    box-shadow: 14px 25px 75px -18px rgba(0, 0, 0, 0.75);
    // padding: 16px 20px;
    width: 1061px;
`;

const Bottom = styled.div`
    width: 100%;
    padding: 15px 10px;
    :nth-child(2n) {
        background: #161619;
    }
`;
const Log = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Time = styled.div`
    font-size: 13px;
`;
const Content = styled.p`
    margin-top: 10px;
    font-size: 13px;
    text-align: left;
`;
const Status = styled.div`
    color: ${({ type }) =>
        type === "attended"
            ? "#44C082"
            : type === "not-attended"
            ? "#EE4B4B"
            : type === "switched-off"
            ? "#EF3D3D"
            : type === "busy"
            ? "#FE673A"
            : ""};
    margin-right: 10px;
    font-size: 13px;
`;
