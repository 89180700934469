import React from "react";
import Avatar from "react-avatar";
import styled from "styled-components";
import moment from "moment/moment";

const formatTimeAgo = (dateAdded) => {
    const now = moment();
    const added = moment(dateAdded);
    const diffMinutes = now.diff(added, "minutes");

    if (diffMinutes < 1) {
        return "Just now";
    } else if (diffMinutes < 20) {
        return `${diffMinutes} mins ago`;
    } else {
        return added.format("MMM DD");
    }
};

function ProjectActivityModal({ modal, setModal, activitiData }) {
    return (
        <MainContainer>
            {modal && <Overlay onClick={() => setModal(false)}></Overlay>}
            <ContainerDiv className={modal ? "active" : ""}>
                <ContainerHead>
                    <HeadText>Activity</HeadText>
                    <HeadIcon onClick={() => setModal(false)}>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-01-2024/x-close.svg "
                            alt="close-icon"
                        />
                    </HeadIcon>
                </ContainerHead>
                <ActivityCards>
                    {activitiData?.map((item, index) => (
                        <Card key={index}>
                            {index !== activitiData?.length - 1 && <HrLine />}
                            <Left>
                                {item.activity_by.photo ? (
                                    <Dot
                                        src={item.activity_by.photo}
                                        alt="avathar"
                                    />
                                ) : (
                                    <Avatar
                                        name={
                                            item.activity_by.name
                                                ? item.activity_by.name
                                                : "--"
                                        }
                                        size="40"
                                        round
                                    />
                                )}
                            </Left>
                            <Right>
                                <Details>
                                    <NameDiv>
                                        <Name>
                                            {item.activity_by.name
                                                ? item.activity_by.name
                                                : "--"}
                                        </Name>
                                        <Time>
                                            {item?.date_added
                                                ? formatTimeAgo(item.date_added)
                                                : "--"}
                                        </Time>
                                    </NameDiv>
                                    <Status>
                                        {item?.title
                                            ? item?.title
                                            : item?.activity
                                            ? item?.activity
                                            : "--"}
                                    </Status>
                                </Details>
                                {item.comment && (
                                    <Content>
                                        <ContentDiv>
                                            <ContentText>
                                                {item.comment}
                                            </ContentText>
                                        </ContentDiv>
                                    </Content>
                                )}
                            </Right>
                        </Card>
                    ))}
                </ActivityCards>
            </ContainerDiv>
        </MainContainer>
    );
}

export default ProjectActivityModal;
const MainContainer = styled.div`
    /* padding: 24px; */
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const ContainerDiv = styled.div`
    position: fixed;
    z-index: 1001;
    right: -555px;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 555px;
    max-width: 555px;
    transition: all ease 200ms;
    padding: 24px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        right: 0;
    }
    border-left: 1px solid #2c2c2c;
    background: #1b1c1f;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
`;
const ContainerHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid #2c2c2c;
`;

const HeadText = styled.h4`
    color: #fff;
    font-size: 20px;
    font-family: "gordita_medium";
`;
const HeadIcon = styled.span`
    display: inline-block;
    cursor: pointer;
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;

const ActivityCards = styled.ul`
    margin-top: 20px;
`;
const Card = styled.li`
    position: relative;
    width: 100%;
    padding: 14px 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
`;
const Left = styled.div`
    position: absolute;
    left: 0px;
    top: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
const Right = styled.div`
    margin-left: 24px;
`;
const Dot = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    border-radius: 50%;
`;
const HrLine = styled.span`
    position: absolute;
    width: 0.125rem;
    height: 100%;
    border-radius: 0.125rem;
    background: #475569;
    left: 18px;
    top: 28px;
    z-index: -1;
    display: block;
`;

const Details = styled.div``;
const Content = styled.div`
    margin-top: 8px;
`;

const NameDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
const Name = styled.h4`
    color: #cbd5e1;
    font-size: 14px;
    font-family: "gordita_medium";
`;
const Time = styled.h5`
    color: #94a3b8;
    font-size: 12px;
    font-family: "gordita_regular";
`;
const Status = styled.div`
    color: #e2e8f0;
    font-size: 14px;
    font-family: "gordita_regular";
`;
const ContentDiv = styled.div``;
const ContentText = styled.div`
    border-radius: 0.5rem;
    border: 1px solid #475569;
    background: #161619;
    padding: 8px;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
`;
