import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//components
import EventsRouter from "../events/EventsRouter";
const SATEnquiries = lazy(() => import("../../../screens/SAT/SATEnquiries"));
const ScoreBoard = lazy(() => import("../../../screens/examiner/ScoreBoard"));
const SATEvents = lazy(() => import("../../../screens/SAT/SATEvents"));
const CareerAdvisorList = lazy(() =>
    import("../../../screens/examiner-head/CareerAdvisorList")
);
const AddExaminer = lazy(() =>
    import("../../../screens/examiner-head/AddExaminer")
);
const Dashboard = lazy(() =>
    import("../../../screens/examiner-head/Dashboard")
);
const TotalAdmissions = lazy(() =>
    import("../../../screens/examiner-head/TotalAdmissions")
);
const ApplicantsList = lazy(() =>
    import("../../../screens/examiner-head/ApplicantsList")
);
const SATQuestions = lazy(() => import("../../../screens/SAT/SATQuestions"));

function ExaminerHeadRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard/" />} />
            <Route path="/dashboard/" element={<Dashboard />} />
            <Route path="/events/*" element={<EventsRouter />} />
            <Route path="/examiners/" element={<AddExaminer />} />
            <Route
                path="/:student_sat_id/scoreboard/"
                element={<ScoreBoard />}
            />
            <Route path="/enquiries/" element={<SATEnquiries />} />
            <Route path="/applicants/" element={<SATEvents />} />
            <Route path="/questions/" element={<SATQuestions />} />
            <Route path="/:subject_slug" element={<CareerAdvisorList />} />
            <Route
                path="/students/:subject_slug"
                element={<ApplicantsList />}
            />
            <Route path="/total-admissions" element={<TotalAdmissions />} />
        </Routes>
    );
}

export default ExaminerHeadRouter;
