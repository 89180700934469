import { useState } from "react";
import styled from "styled-components";
import ReadMoreModal from "./modal/ReadMoreModal";
import { getDDMMYY } from "../../helpers/functions";

export default function RightScoreboard({ studentProfile }) {
    const [isModal, setIsmodal] = useState(false);
    //function to convert sentence into sentence case
    const getSentenceCase = (str) => {
        const sentenceCase = str.charAt(0).toUpperCase() + str.slice(1);
        return sentenceCase;
    };

    return (
        <>
            <ReadMoreModal
                isModal={isModal}
                setIsmodal={setIsmodal}
                student_summary={studentProfile.student_summary}
            />
            <Profile>
                <TopContainer>
                    <ImageContainer>
                        <Image src={studentProfile.student_photo} />
                    </ImageContainer>
                    <Name>{studentProfile.student_name?.toLowerCase()}</Name>
                    <Id>#{studentProfile.student_sat_id}</Id>
                </TopContainer>
                <MiddleContainer>
                    <TopDiv>
                        <LeftDiv type={studentProfile.exam_status}>
                            {studentProfile.exam_status
                                ? studentProfile.exam_status.replace("_", " ")
                                : "-"}
                        </LeftDiv>
                        <RightDiv>
                            <Score>
                                {studentProfile.exam_score}/
                                {studentProfile.total_score}
                            </Score>
                            <Exam>Exam Score</Exam>
                        </RightDiv>
                    </TopDiv>
                    <BottomDiv>
                        <LeftContainer>
                            <Code>Exam Code</Code>
                            <District>District</District>
                        </LeftContainer>
                        <RightContainer>
                            <School>{studentProfile.exam_centre}</School>
                            <Place>{studentProfile.district}</Place>
                        </RightContainer>
                    </BottomDiv>
                </MiddleContainer>
                <SummaryContainer>
                    {studentProfile.student_summary && (
                        <>
                            <Summary>Student Summary</Summary>
                            <Text>
                                <p>
                                    {getSentenceCase(
                                        studentProfile.student_summary
                                    )}
                                </p>
                                <Span
                                    onClick={() => {
                                        setIsmodal(true);
                                    }}
                                >
                                    Read More
                                </Span>
                            </Text>
                        </>
                    )}
                    <Interview>Interviewed by</Interview>
                    <PictureDiv>
                        {studentProfile.assignee_photo && (
                            <PictureContainer>
                                <Picture src={studentProfile.assignee_photo} />
                            </PictureContainer>
                        )}
                        <NameDiv>
                            <Student>{studentProfile.assignee_name}</Student>
                            {studentProfile.assignee_id && (
                                <Id>#{studentProfile.assignee_id}</Id>
                            )}
                        </NameDiv>
                    </PictureDiv>
                </SummaryContainer>
                <BottomContainer>
                    <TotalIcon>
                        <IconContainer>
                            <Icon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/phone.svg"
                                alt="images"
                            />
                        </IconContainer>
                        <DetailsContainer>
                            <Details>Phone Number</Details>
                            <Small>
                                {studentProfile.student_phone_code &&
                                    studentProfile.student_phone_code}{" "}
                                {studentProfile.student_phone
                                    ? studentProfile.student_phone
                                    : "--"}
                            </Small>
                        </DetailsContainer>
                    </TotalIcon>
                    <TotalIcon>
                        <IconContainer>
                            <Icon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/email.svg"
                                alt="images"
                            />
                        </IconContainer>
                        <DetailsContainer>
                            <Details>Email ID</Details>
                            <Small>
                                {studentProfile.student_email
                                    ? studentProfile.student_email
                                    : "--"}
                            </Small>
                        </DetailsContainer>
                    </TotalIcon>
                    <TotalIcon>
                        <IconContainer>
                            <Icon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/calendar.svg"
                                alt="images"
                            />
                        </IconContainer>
                        <DetailsContainer>
                            <Details>Joined Date</Details>
                            <Small>
                                {studentProfile.applied_date
                                    ? getDDMMYY(studentProfile.applied_date)
                                    : "--"}
                            </Small>
                        </DetailsContainer>
                    </TotalIcon>
                    <TotalIcon>
                        <IconContainer>
                            <Icon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/mortarboard.svg"
                                alt="images"
                            />
                        </IconContainer>
                        <DetailsContainer>
                            <Details>Category</Details>
                            <Small>
                                {studentProfile.category}
                                {studentProfile.student_class && ","}{" "}
                                {studentProfile.student_class &&
                                    studentProfile.student_class}
                            </Small>
                        </DetailsContainer>
                    </TotalIcon>
                </BottomContainer>
            </Profile>
        </>
    );
}

const Profile = styled.div`
    width: 26%;
    background: #1b1c1f;
    padding: 30px 15px;
    border-radius: 5px;
    max-height: calc(100vh - 90px);
    min-height: calc(100vh - 90px);
    overflow-y: scroll;
`;
const TotalIcon = styled.div`
    display: flex;
    margin-bottom: 15px;
    :last-child {
        margin-bottom: 0px;
    }
`;
const SummaryContainer = styled.div``;
const Summary = styled.span`
    color: #737375;
    font-size: 14px;
    margin-bottom: 5px;
    display: inline-block;
`;
const Text = styled.p`
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    p {
        color: #fff;
        width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    span {
        margin-top: 5px;
        display: block;
    }
`;
const Span = styled.span`
    color: #fe673a;
    font-size: 14px;
    cursor: pointer;
`;
const Interview = styled.span`
    color: #737375;
    margin-bottom: 5px;
    display: inline-block;
    font-size: 16px;
`;
const PictureDiv = styled.div`
    display: flex;
    border-bottom: 1px solid #26272a;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
`;
const PictureContainer = styled.div`
    width: 50px;
    margin-right: 10px;
`;
const NameDiv = styled.div``;
const Picture = styled.img`
    width: 100%;
    display: block;
`;
const Student = styled.span`
    color: #fff;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 2px;
`;
const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;
const ImageContainer = styled.div`
    width: 100px;
    margin-bottom: 15px;
`;
const Image = styled.img`
    width: 100%;
    display: block;
    border-radius: 50%;
`;
const Name = styled.span`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
    text-transform: capitalize;
`;
const Id = styled.span`
    font-size: 14px;
    color: #fe673a;
    display: flex;
`;
const MiddleContainer = styled.div`
    background: #161619;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
`;
const TopDiv = styled.div`
    display: flex;
    justify-content: space-between;
    background: #0000;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #26272a;
`;
const LeftDiv = styled.div`
    color: ${({ type }) => (type === "eligible" ? "#12a457" : "red")};
    font-size: 14px;
    font-family: "gordita_medium";
    text-transform: capitalize;
`;
const RightDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    font-size: 18px;
`;
const Score = styled.span`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
`;
const Exam = styled.span`
    color: #737375;
    font-size: 14px;
    display: flex;
`;
const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;
const LeftContainer = styled.div``;
const Code = styled.span`
    display: flex;
    font-size: 14px;

    color: #737375;
`;
const District = styled.span`
    display: flex;
    font-size: 14px;
    color: #737375;
    margin-top: 10px;
`;
const IconContainer = styled.div``;
const Icon = styled.img``;
const DetailsContainer = styled.div`
    margin-left: 10px;
`;
const Details = styled.span`
    font-size: 14px;
    color: #737375;
    margin-bottom: 5px;
    display: inline-block;
`;
const Small = styled.span`
    display: flex;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
`;
const BottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const School = styled.span`
    display: flex;
    font-size: 14px;
    color: #fff;
`;
const Place = styled.span`
    display: flex;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    text-transform: capitalize;
`;
const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`;
