import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ContentEditor from "../../../screens/content-manager/ContentEditor";
import TechUpdateEditingScreen from "../../../screens/content-manager/TechUpdateEditingScreen";
import TechUpdateViewRework from "../../../screens/content-manager/TechUpdateViewRework";

function ContentEditorRouter() {
    return (
        <Routes>
            <Route path="dashboard/" element={<ContentEditor />} />
            <Route
                path="create/:article_pk/"
                element={<TechUpdateEditingScreen />}
            />
            <Route
                path="update/:article_pk/"
                element={<TechUpdateEditingScreen />}
            />
            <Route
                path="view/:article_pk/"
                element={<TechUpdateEditingScreen />}
            />
            <Route
                path="view-rework/:history_pk/"
                element={<TechUpdateViewRework />}
            />
        </Routes>
    );
}

export default ContentEditorRouter;
