import React from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from "chart.js";

export default function ChartGraph({
    graphData,
    degreeArray,
    hubArray,
    clubArray,
    primeArray,
}) {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

    // ChartJS.defaults.global.elements.line.tension = 10;
    const labels = graphData;
    const options = {
        plugins: {
            drawBorder: false,
            title: {
                display: true,
                text: "",
            },
        },

        layout: {
            padding: 20,
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: "Prime Program",
                data: primeArray,
                borderRadius: 5,
                barThickness: 20,
                backgroundColor: "#F0883E ",
                borderWidth: 1,
            },
            {
                label: "Tech Grad",
                data: degreeArray,
                borderRadius: 5,
                barThickness: 20,
                backgroundColor: "#FF7B72",

                borderWidth: 1,
            },
            {
                label: "Tech Degree",
                data: hubArray,
                borderRadius: 5,
                barThickness: 20,
                backgroundColor: "#F778BA",
                borderWidth: 1,
            },
            {
                label: "Tech Schooling",
                data: clubArray,
                borderRadius: 5,
                barThickness: 20,
                backgroundColor: "#BC8CFF",
                borderWidth: 1,
            },
        ],
    };
    return (
        <GraphContainer>
            <Bar data={data} options={options} />
        </GraphContainer>
    );
}

const GraphContainer = styled.div`
    canvas {
        height: 400px !important;
        /* width: 157px !important; */
        margin: 0 auto;
    }
`;
