import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function BdmStudentMenuTab() {
    return (
        <MainContainer>
            <Items>
                <Item
                    className={({ isActive }) => (isActive ? "active" : "none")}
                    to={`/bdm/school-students/`}
                >
                    Tech Schooling
                </Item>
                <Item
                    className={({ isActive }) => (isActive ? "active" : "none")}
                    to={`/bdm/college-students/`}
                >
                    Tech Degree
                </Item>
                <Item
                    className={({ isActive }) => (isActive ? "active" : "none")}
                    to={`/bdm/gratuates-students/`}
                >
                    Tech Grad
                </Item>
            </Items>
            <hr />
        </MainContainer>
    );
}

export default BdmStudentMenuTab;
const MainContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Items = styled.ul`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0;
    min-width: 1100px;
`;
const Item = styled(NavLink)`
    padding: 8px 20px;
    font-size: 14px;
    color: #fff;
    font-family: "gordita_medium";
    transition: 1s ease;
    margin-right: 20px;
    :last-child {
        margin-right: 0;
    }
    &.active {
        border-bottom: 3px solid #f7684c;
        border-radius: 2px;
        color: #f7684c;
    }
`;
