import {useContext,useEffect,useState} from "react";
import styled from 'styled-components';
import SchoolScientistDashboardTop from "../schoolScientist/SchoolStudentDashboardTop"
import LevelStatus from "../schoolScientist/LevelStatus"
import Total from './Total';
import AttendedCount from './AttendedCount';
import CompletionStatus from './CompletionStatus';
import { Context } from "../../contexts/Store";
import { accountsConfig } from "../../../axiosConfig";

function SchoolScientistDashboard() {
  const {
    state: {
        user_data: { access_token },
        student_data: {},
    },
    dispatch,
} = useContext(Context);
const [count, setCount] = useState([])
const [isLoading, setLoading] = useState(true);

useEffect(() => {
    accountsConfig
        .get(`api/v1/users/applicants-count/`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        })
        .then((response) => {
            let { StatusCode, data } = response.data;
            if (StatusCode === 6000) {
                setCount(data);
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
        .catch((error) => {
            console.log(error);
            setLoading(false)
        });
   }, []);
  return (
    <>
         <MainContainer id="main">
            <TopContainer>
                <Heading>Dashboard</Heading>
            </TopContainer>
            <SchoolScientistDashboardTop count={count} isLoading={isLoading}/>
            <AttendedCount count={count} isLoading={isLoading}/>
            <CompletionStatus count={count} isLoading={isLoading}/>
            <LevelStatus />
            <Total count={count} isLoading={isLoading}/>
        </MainContainer>
    </>
  )
}
const MainContainer = styled.div``;
const TopContainer = styled.div`
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
    margin-bottom: 20px;
`;

export default SchoolScientistDashboard