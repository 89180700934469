import React from "react";
import { Route, Routes } from "react-router-dom";
import AepBussinessCardForm from "../../../includes/aep/AepBussinessCardForm";
import AepPoster from "../../../includes/aep/AepPoster";
import AepTechDegreePosterForm from "../../../includes/aep/AepTechDegreePosterForm";
import AepTechGradPosterForm from "../../../includes/aep/AepTechGradPosterForm";
import AepTechSchoolingPosterForm from "../../../includes/aep/AepTechSchoolingPosterForm";
import PosterCardRouter from "./PosterCardRouter";

function PosterInnerRouter() {
    return (
        <>
            <Routes>
                <Route path="/*" element={<PosterCardRouter />} />
                <Route
                    path="/create-poster/techschooling"
                    element={<AepTechSchoolingPosterForm />}
                />
                <Route
                    path="/create-poster/techschooling/preview"
                    element={<AepPoster />}
                />
                <Route
                    path="/create-poster/techdegree"
                    element={<AepTechDegreePosterForm />}
                />
                <Route
                    path="/create-poster/techgrad"
                    element={<AepTechGradPosterForm />}
                />
                <Route path="/create-card" element={<AepBussinessCardForm />} />
            </Routes>
        </>
    );
}

export default PosterInnerRouter;
