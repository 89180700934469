import React from "react";
import styled from "styled-components";
import ChatBox from "./ChatBox";

export default function ChooseStudent() {
    return (
        <>
            <MainContainer>
                <Middle>
                    <ChatBox />
                </Middle>
            </MainContainer>
        </>
    );
}

const Middle = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px 11px;
`;

const MainContainer = styled.div`
    background-color: #161619;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex: 1;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
