import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getDDMMYY } from "../../../helpers/functions";

function JobDeskDateModal({
    isModal,
    handleModal,
    setActiveClick,
    setToDate,
    toDate,
    setFromDate,
    fromDate,
    isLoding,
    setLoading,
}) {
    const today = new window.Date();
    return (
        <>
            <MainContainer>
                {isModal ? (
                    <Overlay onClick={() => handleModal()}></Overlay>
                ) : (
                    ""
                )}
                <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                    <Modal>
                        <Conatiner>
                            <HeadingContainer>
                                <Heading>Date Range</Heading>
                                <Close onClick={() => handleModal()}>
                                    <ButtonClose>
                                        <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/close.svg" />
                                    </ButtonClose>
                                </Close>
                            </HeadingContainer>
                            <MiddleContainer>
                                <From>
                                    <SubHead>From</SubHead>
                                    <Date>
                                        <DatePicker
                                            format={"dd-MM-yy"}
                                            dayPlaceholder="DD"
                                            monthPlaceholder="MM"
                                            yearPlaceholder="YY"
                                            onChange={(value) =>
                                                setFromDate(value)
                                            }
                                            value={fromDate}
                                            clearIcon={null}
                                            calendarIcon={null}
                                            maxDate={today}
                                        />
                                    </Date>
                                </From>
                                <ToContainer>
                                    <SubHead>To</SubHead>
                                    <Date>
                                        <DatePicker
                                            format={"dd-MM-yy"}
                                            dayPlaceholder="DD"
                                            monthPlaceholder="MM"
                                            yearPlaceholder="YY"
                                            onChange={(value) =>
                                                setToDate(value)
                                            }
                                            value={toDate}
                                            clearIcon={null}
                                            calendarIcon={null}
                                            maxDate={today}
                                        />
                                    </Date>
                                </ToContainer>
                            </MiddleContainer>
                            <BottomContainer>
                                {fromDate && toDate && fromDate < toDate ? (
                                    <Button
                                        className="accept"
                                        onClick={() => {
                                            // setActiveClick("Date Range");
                                            handleModal();
                                            setLoading(true);
                                        }}
                                        to={
                                            fromDate &&
                                            toDate &&
                                            `?from_date=${getDDMMYY(
                                                fromDate
                                            )}&to_date=${getDDMMYY(toDate)}`
                                        }
                                    >
                                        Submit
                                    </Button>
                                ) : (
                                    <Button className="not-accept" to="">
                                        Submit
                                    </Button>
                                )}
                            </BottomContainer>
                        </Conatiner>
                    </Modal>
                </BackContainer>
            </MainContainer>
        </>
    );
}
export default JobDeskDateModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 494px;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #3b3b3b;
    background: #1b1c20;
    border-radius: 5px;
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Conatiner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;

    @media all and (max-width: 640px) {
        padding: 28px;
    }
`;
const HeadingContainer = styled.div`
    border-bottom: 1px solid #3b3b3b;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Heading = styled.h3`
    font-family: "gordita_medium";
    font-size: 18px;
    color: #fff;
`;
const Close = styled.div`
    cursor: pointer;
`;
const ButtonClose = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const MiddleContainer = styled.div`
    padding: 20px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
        color: #fff;
        font-family: "gordita_regular";
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;

const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Button = styled(Link)`
    width: 48%;
    height: 52px;
    border: 1px solid #fe673a;
    border-radius: 5px;
    display: inline-block;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: "gordita_regular";
    color: #fff;
    :hover {
        opacity: 0.85;
    }
    &.accept {
        background: #fe673a;
        color: #fff;
        margin-left: 20px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        margin-left: 0px !important;
    }
    &.not-accept {
        cursor: not-allowed;
        background: #fe673a;
        color: #fff;
        margin-left: 20px;
    }
`;
const From = styled.div`
    width: 48%;

    @media all and (max-width: 480px) {
        width: 100%;
        margin-bottom: 15px;
    }
    /* margin-right: 20px; */
`;
const ToContainer = styled.div`
    width: 48%;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const SubHead = styled.div`
    color: #fff;
`;
const MainContainer = styled.div``;
const Date = styled.div`
    text-align: center;
    background: #161618;
    border: 1px solid #6f7070;
    border-radius: 4px;
    margin-top: 10px;
`;
