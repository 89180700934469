import React, { lazy } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import PrimeProgrammesSingle from "../../../screens/primeprogrammes/PrimeProgrammesSingle";
// import PrimeProgrammesList from "../../../screens/primeprogrammes/PrimeProgrammesList";
const PrimeProgrammesList = lazy(() =>
    import("../../../screens/primeprogrammes/PrimeProgrammesList")
);

function PrimeProgrammesRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route exact path="/" element={<PrimeProgrammesList />} />
                <Route
                    path="/:primeprogramme_id/"
                    element={<PrimeProgrammesSingle />}
                />
            </Routes>
        </Container>
    );
}

export default PrimeProgrammesRouter;

const Container = styled.div``;
