import React, { useState } from "react";
import styled from "styled-components";
import SteypHelmet from "../../helpers/SteypHelmet";
//components
import ApplicantList from "./ApplicantList";

function JobApplicantsList({ data, isUpdated, setUpdated }) {
    return (
        <MainSection>
            <SteypHelmet title="Job Desk" />
            <MainContainer>
                <Cover>
                    <Category>
                        <Item type="slno">Sl No.</Item>
                        <Item>Name</Item>
                        <Item type="dist">Email</Item>
                        <Item type="slno">Gender</Item>
                        <Item>College</Item>
                        <Item type="dist">Local body</Item>
                        <Item type="dist">Applied on</Item>
                        <Item type="dist">Interview on</Item>
                        <Item type="slno">Resume</Item>
                        <Item type="dist">Action</Item>
                    </Category>
                    <>
                        {data?.length > 0 ? (
                            data.map((item, index) => (
                                <ApplicantList
                                    data={item}
                                    index={index}
                                    key={item.id}
                                    isUpdated={isUpdated}
                                    setUpdated={setUpdated}
                                />
                            ))
                        ) : (
                            <ImageBox>
                                <CardImage
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                                    alt="Image"
                                />
                                <p>No data found</p>
                            </ImageBox>
                        )}
                    </>
                </Cover>
            </MainContainer>
        </MainSection>
    );
}

export default JobApplicantsList;

const MainSection = styled.div`
    min-width: 1450px;
`;

const MainContainer = styled.div`
    border: 1px solid #26272a;
    border-radius: 5px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border-radius: 5px;
    border: 1px solid #26272a;
`;
const Cover = styled.div`
    padding: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1b1c20;
    color: #fff;
    min-width: 980px;
    .card-container {
        border-bottom: 1px solid #000;
        :last-child {
            border-bottom: 0px solid #000;
        }
    }
`;
const Category = styled.div`
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
`;
const Item = styled.li`
    font-size: 14px;
    text-align: ${({ type }) => (type === "count" ? "center" : "left")};
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "slno" ? "4%" : type === "dist" ? "10%" : "14%"};
    list-style: none;
    :last-child {
        text-align: center;
    }
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
`;
const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    margin-top: 170px;
    height: calc(100% - (-50px));
    margin-bottom: 170px;
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;

const CardImage = styled.img`
    width: 100%;
    display: block;
`;
