import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import StudentStatusRouter from "../bda-studentstatus/StudentStatusRouter";

const BdaDashboard = lazy(() => import("../../../screens/bda/BdaDashboard"));
const AepDetails = lazy(() => import("../../../screens/bda/AepDetails"));
const BdaReport = lazy(() => import("../../../screens/bda/BdaReport"));
const BdaSettlement = lazy(() => import("../../../screens/bda/BdaSettlement"));
const BdaTarget = lazy(() => import("../../../screens/bda/BdaTarget"));
const BdaPending = lazy(() => import("../../../screens/bda/BdaPending"));
const Admissions = lazy(() => import("../../../screens/bda/Admissions"));
const BdaInvoice = lazy(() => import("../../../screens/bda/BdaInvoice"));

function BdaRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="dashboard/" />} />
            <Route path="dashboard/" element={<BdaDashboard />} />
            <Route path="aep/" element={<AepDetails />} />
            <Route path="target/" element={<BdaTarget />} />
            <Route path="pending/" element={<BdaPending />} />
            <Route path="leads/*" element={<StudentStatusRouter />} />
            <Route path="report/" element={<BdaReport />} />
            <Route path="settlement/" element={<BdaSettlement />} />
            <Route path="admissions/:id" element={<Admissions />} />
            <Route
                path="school-students/invoice/:student_id"
                element={<BdaInvoice />}
            />
        </Routes>
    );
}

export default BdaRouter;
