import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import TechUserModal from "../../includes/request-dp/modal/TechUserModal";
import { Context } from "../../contexts/Store";
import {
    getDateStr,
    getTimeStrFromDate,
    secondsTohms,
} from "../../helpers/functions";
import moment from "moment/moment";
import NoData from "../../includes/jobdesk/NoData";
import SectionLoader from "../../helpers/SectionLoader";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import CreateCampusModal from "./modals/CreateCampusModal";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import { useSearchParams } from "react-router-dom";

function Campus() {
    const [isEvent, setEvent] = useState(false);
    const [isModal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;
    const [approvemodal, setApprovemodal] = useState(false);
    const [userId, setUserId] = useState("");
    const [varifyLoading, setVarifyLoading] = useState(false);
    const [campusInput, setCampusInput] = useState("");
    const [CampusList, setCampusLists] = useState("");
    const [selectedCampus, setSelectedCampus] = useState("");
    const [pagination, setPagination] = useState(null);
    const [reason, setReason] = useState("");
    const [rejectedLoading, setRejectedLoading] = useState(false);

    //search params
    const [searchQuery, setSearchQuery] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    useEffect(() => {
        if (approvemodal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [approvemodal]);

    // api for listing student details

    const fetchStudentDetails = () => {
        setLoading(true);
        accountsConfig
            .get(`api/v1/users/campus-verifications/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setLoading(false);
                    setPagination(pagination_data);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const updateStatus = (status) => {
        const formData = new FormData();
        formData.append("status", status);
        formData.append("campus", selectedCampus);
        formData.append("reason", reason);

        if (status === "rejected") {
            setRejectedLoading(true);
            setVarifyLoading(false);
        } else if (status === "approved") {
            setRejectedLoading(false);
            setVarifyLoading(true);
        } else {
            setRejectedLoading(false);
            setVarifyLoading(false);
        }

        accountsConfig
            .post(`api/v1/users/verify/student-campus/${userId}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setUserId("");
                    setCampusInput("");
                    setReason("");
                    setApprovemodal(false);
                    fetchStudentDetails("");
                    setSelectedCampus("");
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setRejectedLoading(false);
                setVarifyLoading(false);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchStudentDetails();
    }, [currentPage, searchParams]);

    // api for search campus

    useEffect(() => {
        accountsConfig
            .get(`/api/v1/campuses/schools/?q=${campusInput}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCampusLists(data);
                } else if (StatusCode === 6001) {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [campusInput]);

    //modal ouside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const filteredStudents = studentDetails?.filter((student) => {
        const studentName = student.student.toLowerCase(); // Convert student name to lowercase for case-insensitive search
        return studentName.includes(searchQuery.toLowerCase()); // Check if the student name contains the search query
    });

    return (
        <>
            <Container>
                <CreateCampusModal
                    isModal={isModal}
                    setModal={setModal}
                    setEvent={setEvent}
                />
                <TopContainer>
                    <Heading>Campus</Heading>
                    <HeadRight>
                        <CreateButton onClick={() => setModal(true)}>
                            <Plusicon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/plus.svg"
                                    alt="Icon"
                                />
                            </Plusicon>
                            <CreateTitle>Add campus</CreateTitle>
                        </CreateButton>
                        <SearchContainer>
                            <SearchBar>
                                <InputField
                                    placeholder="Search Here"
                                    value={searchQuery}
                                    onChange={(e) =>
                                        setSearchQuery(e.target.value)
                                    }
                                />
                            </SearchBar>
                            <SearchImageContainer>
                                <SearchImg
                                    src={
                                        require("../../../assets/images/request/search-icon.svg")
                                            .default
                                    }
                                    alt="Search-icon"
                                />
                            </SearchImageContainer>
                        </SearchContainer>
                    </HeadRight>
                </TopContainer>

                <BottomContainer>
                    <MainHead>
                        <MainSub>
                            <Contents type="slno">Sl No</Contents>
                            <Contents type="student">Student</Contents>
                            <Contents type="campus">
                                School / college name
                            </Contents>
                            <Contents type="date">Date & time</Contents>
                            <Contents type="action">Action</Contents>
                        </MainSub>
                    </MainHead>
                    {filteredStudents?.length > 0 ? (
                        filteredStudents?.map((student, index) => (
                            <>
                                <UlList key={student.id}>
                                    {pagination?.first_item <=
                                        pagination?.last_item && (
                                        <Li type="slno">
                                            {pagination?.first_item + index}
                                        </Li>
                                    )}
                                    <Li type="student">
                                        <StudentInfo>
                                            <span>{student.student}</span>
                                            <Phone>{student.phone}</Phone>
                                        </StudentInfo>
                                    </Li>
                                    <Li type="campus">
                                        <CampusName>
                                            {student.campus_name}
                                        </CampusName>
                                    </Li>
                                    <Li type="date">
                                        <StudentInfo>
                                            {student.date_added ? (
                                                <>
                                                    <span>
                                                        {getDateStr(
                                                            student.date_added
                                                        )}
                                                    </span>
                                                    <TimeLabel>
                                                        {getTimeStrFromDate(
                                                            student.date_added
                                                        )}
                                                    </TimeLabel>
                                                </>
                                            ) : (
                                                "--"
                                            )}
                                        </StudentInfo>
                                    </Li>

                                    <Li type="action">
                                        {student.campus_verification_status ===
                                        "approved" ? (
                                            <Updated>Verified</Updated>
                                        ) : student.campus_verification_status ===
                                          "rejected" ? (
                                            <NotApprov>Rejected</NotApprov>
                                        ) : (
                                            <Update
                                                onClick={() => {
                                                    setApprovemodal(true);
                                                    setUserId(student.id);
                                                }}
                                            >
                                                Verify
                                            </Update>
                                        )}
                                    </Li>
                                </UlList>
                                <TechUserModal
                                // isModal={isModal}
                                // setModal={setModal}
                                // clickedAvatar={clickedAvatar}
                                />
                            </>
                        ))
                    ) : loading ? (
                        <SectionLoader />
                    ) : (
                        <NoData />
                    )}
                </BottomContainer>
                <BackContainer>
                    {approvemodal ? (
                        <Overlay
                            onClick={() => {
                                setApprovemodal(false);
                            }}
                            style={{ display: approvemodal ? "block" : "none" }}
                        ></Overlay>
                    ) : (
                        ""
                    )}
                    <Cover style={{ transform: approvemodal && "scale(1,1)" }}>
                        <Modal>
                            <ContainerTool>
                                <ModalHeading>
                                    <VerifyIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/verification-active.svg"
                                        alt="Icon"
                                    />
                                    <HeadingText>
                                        Campus Verification
                                    </HeadingText>
                                    <Closeicon
                                        onClick={() => {
                                            setApprovemodal(false);
                                        }}
                                    >
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                            alt="Icon"
                                        />
                                    </Closeicon>
                                </ModalHeading>
                                <hr />
                                <ShortDesc>
                                    Upon verification, the campus you
                                    entered will appear on student profile.
                                </ShortDesc>
                                <SelectCampusDiv>
                                    <Top>
                                        <CampusHeading>
                                            Search campus
                                        </CampusHeading>
                                        <InputContainer
                                            type="text"
                                            value={campusInput}
                                            onChange={(e) => {
                                                setCampusInput(e.target.value);
                                            }}
                                        />
                                        <SearchIcon>
                                            <img
                                                src={
                                                    require("../../../assets/images/search.svg")
                                                        .default
                                                }
                                                alt="Search"
                                            />
                                        </SearchIcon>
                                    </Top>
                                    <Bottom>
                                        {CampusList.length > 0 ? (
                                            CampusList.map((campus, index) => (
                                                <CampusNames
                                                    key={index}
                                                    className={
                                                        selectedCampus ===
                                                        campus.id
                                                            ? "active"
                                                            : ""
                                                    }
                                                    onClick={() => {
                                                        setCampusInput(
                                                            campus.name
                                                        );
                                                        setSelectedCampus(
                                                            campus.id
                                                        );
                                                    }}
                                                >
                                                    <p>{campus.name}</p>
                                                </CampusNames>
                                            ))
                                        ) : (
                                            <p className="empty">
                                                No Campus Found!!
                                            </p>
                                        )}
                                    </Bottom>
                                </SelectCampusDiv>
                                <CreateButtonBox>
                                    <CancelButton
                                        onClick={() => {
                                            updateStatus("rejected");
                                        }}
                                    >
                                        {rejectedLoading ? (
                                            <ButtonLoader />
                                        ) : (
                                            "Reject"
                                        )}
                                    </CancelButton>

                                    <VerifyButton
                                        style={{
                                            cursor: selectedCampus
                                                ? "pointer"
                                                : "not-allowed",
                                        }}
                                        onClick={() => {
                                            if (selectedCampus !== "") {
                                                updateStatus("approved");
                                            }
                                        }}
                                    >
                                        {varifyLoading ? (
                                            <ButtonLoader />
                                        ) : (
                                            "Verify"
                                        )}
                                    </VerifyButton>
                                </CreateButtonBox>
                            </ContainerTool>
                        </Modal>
                    </Cover>
                </BackContainer>
            </Container>
            {pagination?.total_pages > 1 && studentDetails?.length > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        Showing students {pagination?.first_item} -{" "}
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </>
    );
}

export default Campus;

const Closeicon = styled.div`
    position: absolute;
    right: 0;
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const Modal = styled.div`
    width: 90%;
    max-width: 685px;
    max-height: 100vh;
    min-height: 400px;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 105;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    box-shadow: 0px 3px 56px #00000048;
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 600px;
    }
    @media all and (max-width: 700px) {
        width: 560px;
    }
    @media all and (max-width: 640px) {
        width: 430px;
    }
    @media all and (max-width: 600px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const ContainerTool = styled.div`
    overflow: hidden;
    position: relative;
    background: #1b1c20;
    color: white;
    border: 1px solid #26272a;
    border-radius: 5px;
    box-shadow: 14px 25px 75px -18px rgba(0, 0, 0, 0.75);
    padding: 25px;
    min-height: 400px;
    hr {
        height: 2px;
        background-color: #26272a;
        margin: 20px 0;
    }
    @media all and (max-width: 980px) {
        padding: 40px;
    }
    @media all and (max-width: 768px) {
        padding: 30px 20px;
    }
    @media all and (max-width: 640px) {
        padding: 30px 25px;
    }
`;
const Bottom = styled.div`
    border: 1px solid #333;
    border-radius: 6px;
    max-height: 180px;
    min-height: fit-content;
    overflow-y: scroll;
    p.empty {
        display: flex;
        width: 100%;
        height: 180px;
        justify-content: center;
        align-items: center;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;
const CampusHeading = styled.p`
    margin-bottom: 5px;
    color: #7b7b7b;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
`;
const InputContainer = styled.input`
    font-size: 14px;
    background-color: #161619;
    padding: 10px 20px 10px 46px;
    border: 1px solid #676767;
    border-radius: 5px;
    color: #fff;
    width: 100%;
    margin-bottom: 15px;
    display: inline-block;
`;
const SearchIcon = styled.span`
    position: absolute;
    left: 10px;
    top: 46%;
    width: 20px;
    display: inline-block;
    img {
        width: 100%;
        display: block;
    }
`;
const Top = styled.div`
    position: relative;
`;

const SelectCampusDiv = styled.div`
    margin-bottom: 15px;
`;

const Container = styled.div`
    @media all and (max-width: 1350px) {
    }
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;

const BottomContainer = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 10px;
    // @media all and (max-width: 1080px) {
    //     grid-template-columns: 1fr 1fr;
    // }
    // @media all and (max-width: 640px) {
    //     grid-template-columns: 1fr;
    // }
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    display: flex;
    font-family: "gordita_medium";
    font-size: 16px;
    text-align: left;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "student"
            ? "23%"
            : type === "campus"
            ? "37%"
            : type === "date"
            ? "15%"
            : type === "action"
            ? "20%"
            : ""};
    :nth-child(5) {
        /* text-align: center; */
    }
    :nth-child(2) {
        /* text-align: center; */
    }
    :nth-child(3) {
        /* text-align: center; */
    }
    :last-child {
        justify-content: end;
    }
`;
const UlList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    align-items: center;
`;
const Li = styled.li`
    display: flex;

    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "student"
            ? "23%"
            : type === "campus"
            ? "37%"
            : type === "date"
            ? "15%"
            : type === "action"
            ? "20%"
            : ""};
    :nth-child(5) {
        /* text-align: center; */
    }
    :nth-child(2) {
        /* text-align: center; */
    }

    :nth-child(3) {
        /* text-align: center; */
    }
    :last-child {
        justify-content: end;
    }
`;
const StudentInfo = styled.div`
    font-size: 16px;
    color: #d2caca;
    text-transform: capitalize;
    span {
        margin-bottom: 6px;
        display: inline-block;
    }
`;
const ImageContainer = styled.div`
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`;
const Phone = styled.p`
    text-align: left;
    font-size: 12px;
    color: #fe673a;
`;
const TimeLabel = styled.p`
    text-align: left;
    font-size: 14px;
    color: #fe673a;
`;
const AImg = styled.a`
    display: inline-block;
    width: 30%;
`;
const Program = styled.p`
    color: #fff;
`;
const CampusName = styled.p`
    color: #fff;
    font-size: 16px;
`;
const DownIcon = styled.img`
    display: block;
    width: 100%;
`;
const Update = styled.button`
    display: flex;
    width: 132px;
    height: 44px;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #fe673a;
    color: #fff;
    cursor: pointer;
`;
const Updated = styled.button`
    display: flex;
    width: 132px;
    height: 44px;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #0fa76f;
    color: #fff;
    cursor: not-allowed;
`;
const NotApprov = styled.button`
    display: flex;
    width: 132px;
    height: 44px;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #ff3030;
    color: #fff;
    cursor: not-allowed;
`;
const Remarks = styled.div``;
const VerifyOverlayContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1b1c20;
    height: 160px;
    border-radius: 8px;
    padding: 20px;
    z-index: 1001;
    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: 10px;
        color: #ffff;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
    }

    p {
        margin-bottom: 20px;
        color: #64748b;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    div {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        @media (max-width: 360px) {
            justify-content: center;
        }
    }
    @media (max-width: 640px) {
        height: 175px;
    }
    @media (max-width: 480px) {
        width: 290px;
        height: 190px;
    }
    @media (max-width: 360px) {
        width: 290px;
        height: 190px;
    }
`;
const CancelButton = styled.button`
    cursor: pointer;
    color: #ff3030;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    font-family: "gordita_medium";
    width: 120px;
    height: 40px;
`;
const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const VerifyButton = styled.button`
    width: 120px;
    height: 40px;
    background: #fe673a;
    color: #fff;
    border-radius: 5px;
    margin-left: 10px;
    font-family: "gordita_medium";
`;
const CreateButton = styled.div`
    display: flex;
    align-items: center;
    background-color: #fe673a;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10px;
    border: 2px solid #1b1c20;
    border-radius: 3px;
`;
const SearchBar = styled.div`
    // padding: 10px 24px;
    background: #161619;
    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;
const SearchImageContainer = styled.div`
    background: #161619;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 10px 0;
    cursor: pointer;
`;
const SearchImg = styled.img`
    width: 100%;
    display: block;
`;

const Plusicon = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const CreateTitle = styled.span`
    font-size: 14px;
    color: #fff;
    font-family: "gordita_medium";
    margin-left: 5px;
`;
const HeadRight = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`;
const CampusNames = styled.div`
    border-radius: 5px;
    padding: 10px 20px;
    transition: 0.3s ease all;
    border: 1px solid transparent;
    cursor: pointer;
    p {
        color: #fff;
    }
    &.active {
        background-color: #161619;
        border: 1px solid #6b6b6b;
    }
    &:hover {
        background-color: #333;
    }
`;
const ShortDesc = styled.p`
    color: #fff;
    font-size: 18px;
    margin-bottom: 12px;
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
`;
const ModalHeading = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;
const HeadingText = styled.h3`
    color: #fff;
    font-size: 22px;
    @media all and (max-width: 768px) {
        font-size: 18px;
    }
`;
const VerifyIcon = styled.img`
    width: 34px;
    margin-right: 12px;
    right: 0;
    @media all and (max-width: 768px) {
        width: 28px;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
