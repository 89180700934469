import React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import JobApplicants from "../../../screens/jobdesk/JobApplicants"
import JobApplicantsList from "../../../includes/jobdesk/JobApplicantsList";
import Graduate from "../../../screens/jobdesk/Graduate";

function JobDeskRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route exact path="/" element={<JobApplicants />} />
                <Route exact path="/jobdesk/" element={<JobApplicantsList />} />
                <Route exact path="/graduate/" element={<Graduate />} />
            </Routes>
        </Container>
    );
}

export default JobDeskRouter;

const Container = styled.div``;
