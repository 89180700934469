import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//router components
import SubscriptionManagerStudentsRouter from "../students/SubscriptionManagerStudentsRouter";
//components
const Dashboard = lazy(() => import("../../../screens/Dashboard"));
const AdmissionDashboard = lazy(() =>
    import("../../../screens/AdmissionDashboard")
);
const RenewalDashboard = lazy(() =>
    import("../../../screens/RenewalDashboard")
);
const PaymentsDashboard = lazy(() =>
    import("../../../screens/students/PaymentsDashboard")
);
const LeadPaymentsDashboard = lazy(() =>
    import("../../../screens/subscription-manager/LeadPaymentsDashboard")
);

export default function SubscriptionManagerRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard/" />} />
            <Route path="/dashboard/" element={<Dashboard />} />
            <Route
                path="/students/*"
                element={<SubscriptionManagerStudentsRouter />}
            />
            <Route path="/renewal/" element={<RenewalDashboard />} />
            <Route path="/admission/" element={<AdmissionDashboard />} />
            <Route path="/payments/" element={<PaymentsDashboard />} />
            <Route path="/lead-payments/" element={<LeadPaymentsDashboard />} />
        </Routes>
    );
}
