import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//router components
import ChallengesRouter from "../challenges/ChallengesRouter";
import PrimeProgrammesRouter from "../primeprogrammes/PrimeProgrammesRouter";
import StudentRouter from "../students/StudentRouter";
import SatRouter from "../sat/SatRouter";
import SyllabusRouter from "../syllabus/SyllabusRouter";
import ChiefExaminerRouter from "../../routers/examiners/ChiefExaminerRouter";
import ChiefAgentRouter from "../community/ChiefAgentRouter";
import JobDeskRouter from "../jobdesk/JobDeskRouter";
import VerificationRouter from "../verifications/VerificationRouter";
import FollowupRouter from "../oprations-head/FollowupRouter";
import DoubtHubbRouter from "./../roles/doubt-hub/DoubtHubbRouter";
import CommunityPostRouter from "../community-post/CommunityPostRouter";
import EmployRouter from "./EmployRouter";
import NextGenRouter from "./NextGenRouter";
import ProjectsRouter from "./ProjectsRouter";
import MeetRouter from "./MeetRouter";
import GroupRouter from "./GroupRouter";
//components
const StudentActivity = lazy(() =>
    import("../../../screens/students/StudentActivity")
);
const Dashboard = lazy(() => import("../../../screens/Dashboard"));
const AdmissionDashboard = lazy(() =>
    import("../../../screens/AdmissionDashboard")
);
const PaymentsDashboard = lazy(() =>
    import("../../../screens/students/PaymentsDashboard")
);
const RenewalDashboard = lazy(() =>
    import("../../../screens/RenewalDashboard")
);

export default function ChiefRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard/" />} />
            <Route path="/dashboard/" element={<Dashboard />} />
            <Route path="/students/*" element={<StudentRouter />} />
            <Route path="/renewal/" element={<RenewalDashboard />} />
            <Route path="/admission/" element={<AdmissionDashboard />} />
            <Route
                path="/prime-programs/*"
                element={<PrimeProgrammesRouter />}
            />
            <Route path="/projects/*" element={<ProjectsRouter />} />
            <Route path="/community/*" element={<CommunityPostRouter />} />
            <Route path="/doubt-hub/*" element={<DoubtHubbRouter />} />
            <Route path="/challenges/*" element={<ChallengesRouter />} />
            <Route path="/payments/*" element={<PaymentsDashboard />} />
            <Route path="/activity/*" element={<StudentActivity />} />
            <Route path="/sat/*" element={<SatRouter />} />
            <Route path="/syllabus/*" element={<SyllabusRouter />} />
            <Route path="/chief-examiner/*" element={<ChiefExaminerRouter />} />
            <Route path="/agent/*" element={<ChiefAgentRouter />} />
            <Route path="/jobdesk/*" element={<JobDeskRouter />} />
            <Route path="/verifications/*" element={<VerificationRouter />} />
            <Route path="/followup/*" element={<FollowupRouter />} />
            <Route path="/employees/*" element={<EmployRouter />} />
            <Route path="/meet/*" element={<MeetRouter />} />
            <Route path="/groups/*" element={<GroupRouter />} />
            <Route path="/next-gen/*" element={<NextGenRouter />} />
        </Routes>
    );
}
