import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import { useSearchParams } from "react-router-dom";

function QuestionReportFilterModal(
    wrapperRef,
    toDate,
    fromDate,
    setToDate,
    setFromDate,
) {
    const [isDropdown, setDropdown] = useState(false);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [isCategory, setCategory] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [toDay, setToday] = useState("");
    const today = new window.Date();
    const [isAuthor, setAuthor] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isSelected, setSelected] = useState("");
    const [dateAlert, setDateAlert] = useState(false);
    const [activeClick, setActiveClick] = useState("Filter");
    const [categorys, setCategorys] = useState([]);
    const currentParams = Object.fromEntries([...searchParams]);
    const [toLink, setToLink] = useState("?");
    const [selectedCategory, setSelectedCategory] = useState(null);

    const clearAll = () => {
        setSearchWord("");
        setToday("");
        setFromDate("");
        setToDate("");
    };

    const handleRemoveCategory = () => {
        setSelectedCategory(null);
    };
    const handleDateFilter = (date) => {
        if (date !== "") {
            setSearchParams({
                ...currentParams,
                published_date: date,
            });
        } else {
            const updatedParams = { ...currentParams };
            delete updatedParams.published_date;
            setSearchParams(updatedParams);
        }
    };

    const handleDateRange = (date, range) => {
        let date_range = `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}`;
        if (range === "from") {
            setSearchParams({
                ...currentParams,
                from_date: date_range,
            });
        } else {
            setSearchParams({
                ...currentParams,
                to_date: date_range,
            });
        }
    };
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setCategory(false);
    };
    const handleCloseClick = () => {
        setSelectedAuthor(null);
        const updatedParams = { ...currentParams };
        delete updatedParams.author;
        setSearchParams(updatedParams);
    };
    const updateFilters = () => {
        if (currentParams.blog_status) {
            setSelected(currentParams.blog_status);
        }
        if (currentParams.published_date) {
            setToday(currentParams.published_date);
        }
    };

    const handleAuthorClick = (author) => {
        setSelectedAuthor(author);
        setAuthor(false);
        setSearchParams({
            ...currentParams,
            author: author.id,
        });
    };

    useEffect(() => {
        updateFilters();
    }, [searchParams]);
    return (
        <>
            <TopFilter>
                <CoverSet ref={wrapperRef}>
                    <Filters onClick={() => setDropdown(!isDropdown)}>
                        <FilterText>{activeClick}</FilterText>
                        <FIlterImage>
                            <img
                                src={
                                    require("../../../../assets/images/bda/Filter.svg")
                                        .default
                                }
                                alt="Image"
                            />
                        </FIlterImage>
                    </Filters>
                    <Popup className={isDropdown ? "dropdown" : ""}>
                        <FilterDiv>
                            Filter
                            <ArrowDiv onClick={() => setDropdown(!isDropdown)}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Arrow"
                                />
                            </ArrowDiv>
                        </FilterDiv>
                        <HrLine className="Exam Status"></HrLine>
                        {selectedAuthor && (
                            <SelectDiv>
                                <Select>
                                    <MainImg
                                        style={{
                                            backgroundImage: `url(${selectedAuthor.image})`,
                                        }}
                                    ></MainImg>
                                    <MianText>{selectedAuthor.name}</MianText>
                                    <CloseImg onClick={handleCloseClick}>
                                        <img
                                            src={
                                                require("../../../../assets/images/closered.svg")
                                                    .default
                                            }
                                            alt="close"
                                        />
                                    </CloseImg>
                                </Select>
                            </SelectDiv>
                        )}
                        <SearchDiv
                            onClick={() => {
                                setCategory(!isCategory);
                                setAuthor(false);
                            }}
                        >
                            <SearchTxt
                                type="text"
                                value={searchWord}
                                onChange={(e) => setSearchWord(e.target.value)}
                                placeholder="Search category"
                            />
                            <ArticleIcon>
                                <img
                                    src={
                                        require("../../../../assets/images/search.svg")
                                            .default
                                    }
                                    alt="searchimage"
                                />
                            </ArticleIcon>
                        </SearchDiv>
                        {isCategory && (
                            <SearchDivModal>
                                {categorys.map((data) => (
                                    <SearchContent
                                        key={data.id}
                                        onClick={() => {
                                            handleCategoryClick(data);
                                            setSearchWord("");
                                            setSearchParams({
                                                ...currentParams,
                                                category: data.slug,
                                            });
                                        }}
                                    >
                                        <p>{data.title}</p>
                                    </SearchContent>
                                ))}
                            </SearchDivModal>
                        )}

                        {selectedCategory && (
                            <SelectDiv>
                                <Select>
                                    <MianText>
                                        {selectedCategory.title}
                                    </MianText>
                                    <CloseImg onClick={handleRemoveCategory}>
                                        <img
                                            src={
                                                require("../../../../assets/images/closered.svg")
                                                    .default
                                            }
                                            alt="close"
                                        />
                                    </CloseImg>
                                </Select>
                            </SelectDiv>
                        )}
                        <TagContainer>
                            <Tag>Artificial Intellegence</Tag>
                            <TagIcon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="close"
                                />
                            </TagIcon>
                        </TagContainer>
                        <ContainerDiv>
                            <Small>Date Range</Small>
                            <SelectOption
                                onClick={() => {
                                    setToday(toDay == "today" ? "" : "today");
                                    handleDateFilter(
                                        toDay == "today" ? "" : "today"
                                    );
                                }}
                            >
                                <Span>
                                    {toDay === "today" ? (
                                        <SelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                alt="image"
                                            />
                                        </SelectedImg>
                                    ) : (
                                        <UnSelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                alt="image"
                                            />
                                        </UnSelectedImg>
                                    )}
                                </Span>
                                <Text>Today</Text>
                            </SelectOption>
                            <SelectOption
                                onClick={() =>
                                    setToday(
                                        toDay == "yesterday" ? "" : "yesterday"
                                    )
                                }
                            >
                                <Span>
                                    {toDay === "yesterday" ? (
                                        <SelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                alt="image"
                                            />
                                        </SelectedImg>
                                    ) : (
                                        <UnSelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                alt="image"
                                            />
                                        </UnSelectedImg>
                                    )}
                                </Span>
                                <Text>Yesterday</Text>
                            </SelectOption>

                            <SelectOption
                                onClick={() => {
                                    setToday(
                                        toDay == "this_week" ? "" : "this_week"
                                    );
                                    handleDateFilter(
                                        toDay == "this_week" ? "" : "this_week"
                                    );
                                }}
                            >
                                <Span>
                                    {toDay === "this_week" ? (
                                        <SelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                alt="image"
                                            />
                                        </SelectedImg>
                                    ) : (
                                        <UnSelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                alt="image"
                                            />
                                        </UnSelectedImg>
                                    )}
                                </Span>
                                <Text>This week</Text>
                            </SelectOption>

                            <SelectOption
                                onClick={() =>
                                    setToday(
                                        toDay == "this_month"
                                            ? ""
                                            : "this_month"
                                    )
                                }
                            >
                                <Span>
                                    {toDay === "this_month" ? (
                                        <SelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                alt="image"
                                            />
                                        </SelectedImg>
                                    ) : (
                                        <UnSelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                alt="image"
                                            />
                                        </UnSelectedImg>
                                    )}
                                </Span>
                                <Text>This month</Text>
                            </SelectOption>
                        </ContainerDiv>
                        <HrLine className="Exam Status"></HrLine>
                        <ContainerDiv>
                            <Small>Pick Date</Small>
                            <AccountFilter>
                                <DatePickDiv>
                                    <DatePicker
                                        format={"dd-MM-yy"}
                                        dayPlaceholder="DD"
                                        monthPlaceholder="MM"
                                        yearPlaceholder="YY"
                                        onChange={(value) => {
                                            setFromDate(value);
                                            handleDateRange(value, "from");
                                        }}
                                        value={fromDate}
                                        clearIcon={null}
                                        calendarIcon={null}
                                        maxDate={today}
                                    />
                                </DatePickDiv>
                                to
                                <DatePickDiv>
                                    <DatePicker
                                        format={"dd-MM-yy"}
                                        dayPlaceholder="DD"
                                        monthPlaceholder="MM"
                                        yearPlaceholder="YY"
                                        onChange={(value) => {
                                            setToDate(value);
                                            handleDateRange(value, "to");
                                        }}
                                        value={toDate}
                                        clearIcon={null}
                                        calendarIcon={null}
                                        maxDate={today}
                                    />
                                </DatePickDiv>
                            </AccountFilter>
                        </ContainerDiv>
                        <ButtonDiv>
                            <ClearDiv onClick={clearAll} to="">
                                Clear
                            </ClearDiv>
                            <ApplyDiv
                                className={dateAlert && "disabled"}
                                onClick={(e) => {
                                    if (dateAlert) {
                                        e.preventDefault();
                                    } else {
                                        setDropdown(false);
                                    }
                                }}
                                to={toLink.slice(0, -1)}
                            >
                                Apply
                            </ApplyDiv>
                        </ButtonDiv>
                    </Popup>
                </CoverSet>
            </TopFilter>
        </>
    );
}

export default QuestionReportFilterModal;

const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    @media all and (max-width: 480px) {
        display: flex;
        justify-content: flex-start;
    }
`;
const CoverSet = styled.div``;
const Filters = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const FilterText = styled.div`
    margin-right: 5px;
    font-family: gordita_medium;
    color: #fff;
    font-size: 14px;
`;
const FIlterImage = styled.div`
    & img {
        width: 18px;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    padding: 20px;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 384px;
    top: 0;
    right: -500px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: auto;
        transition: 0.5s ease;
        min-height: 100%;
        right: 0;
        overflow-y: scroll;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const AccountFilter = styled.div`
    display: flex;
    align-items: center;
`;

const SearchDivModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    width: 100%;
    max-height: 219px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    top: 290px;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const SearchContent = styled.div`
    font-size: 16px;
    border-bottom: 1px solid #777;
    font-family: gordita_medium;
    padding: 14px 20px;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    }
`;

const DatePickDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    background: #161618;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #272729;
    font-size: 14px;
    width: 146px;
    height: 50px;
    cursor: pointer;
    z-index: unset !important;
    @media all and (max-width: 480px) {
        padding: 13px 5px;
    }

    & .react-date-picker__calendar {
        inset: inherit !important;
        top: -175px !important;
        left: -38px !important;
        z-index: 201;
        width: 375px !important;
    }

    &:last-child .react-date-picker__calendar {
        left: -218px !important;
    }

    &:last-child {
        margin: 0 0 0 10px;
    }

    &.active {
        border: 1px solid #fe673a;
    }
`;

const FilterDiv = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
    justify-content: space-between;
    align-items: center;
`;
const ArrowDiv = styled.div`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 20px;

    transform: rotate(90deg);

    img {
        width: 100%;
        display: block;
    }
`;
const TagContainer = styled.div`
    padding: 8px 10px 8px 10px;
    background: #1d1c1c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 56%;
    border-radius: 6px;
`;
const Tag = styled.div`
    font-family: "gordita_regular";
`;
const TagIcon = styled.div`
    width: 12px;
    img {
        width: 100%;
        display: block;
    }
`;
const ContainerDiv = styled.div``;

const SelectOption = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Text = styled.div`
    font-size: 14px;
`;
const Small = styled.span`
    color: #838488;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 15px;
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    margin: 10px 0;
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
`;
const ClearDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 154px;
    height: 40px;
    background: #1b1c20;
    border-radius: 6px;
    border: 1px solid #fe673a;
    margin-right: 15px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
`;
const ApplyDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 11px;
    width: 154px;
    height: 40px;
    background: #fe673a;
    border-radius: 6px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
    &.disabled {
        cursor: not-allowed;
    }
`;

const ArticleIcon = styled.small`
    display: inline-block;
    width: 18px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const SelectDiv = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
`;

const Select = styled.div`
    display: inline-flex;
    padding: 6px 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: #3b3b3b;
`;

const MainImg = styled.small`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    margin-right: 8px;
`;

const MianText = styled.h5`
    color: #fff;
    font-size: 14px;
    height: 16px;
`;

const CloseImg = styled.span`
    display: inline-block;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
    }
`;

const SearchDiv = styled.div`
    display: flex;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    padding: 12px 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
`;

const SearchTxt = styled.input`
    color: #fff;
    font-size: 18px;
    width: 100%;

    &::placeholder {
        color: #fff;
        font-size: 18px;
    }
`;
