import React, { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import styled from "styled-components";
import { activityConfig, communityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import ExamCompletedModal from "../examiner/modal/ExamCompletedModal";
import ReviewDescriptionDetails from "../examiner/modal/ReviewDescriptionDetails";
import CreateAccount from "../examiner/modal/CreateAccount";
import EnablePayment from "../examiner/modal/EnablePayment";
import BdaExamStartModal from "../../includes/bdm/BdmExamStartModal";
import BdaQuestionsModal from "../../includes/bdm/BdmQuestionModal";

export default function BdmSchoolStudentCard({
    pagination,
    index,
    studentId,
    student,
    isContinue,
    setContinue,
    setStudentSatId,
    setCategory,
    setEligibilityId,
    setPhoneNumber,
    setUserName,
    setPassword,
    phoneNumber,
    userName,
    password,
    createAccount,
    setCountryId,
    fetchEligibility,
    activateAccount,
    setClickTrue,
    isCreateLoding,
    isEnableAccounctLoading,
    isAccountActivatedLoading,
    checkMessage,
    clickedId,
    setClickedId,
}) {
    const [isExamModal, setExamModal] = useState(false);
    const [questions, setQuestions] = useState({});
    const [questionsLoading, setQuestionsLoading] = useState(false);
    const [mark, setMark] = useState("");
    const [totalQuestions, setTotalQuestions] = useState();
    const [isFeedback, setFeedback] = useState(false);
    const [number, setNumber] = useState(0);
    const [description, setDescription] = useState("");
    const [examPk, setExamPk] = useState("");
    const [isExamLoading, setExamLoading] = useState(false);
    const [isCreated, setCreated] = useState(false);
    const [isEnabled, setEnabled] = useState(false);
    const [isReviewed, setReviewed] = useState(false);
    const [showSummary, setShowSummary] = useState("");
    const [isStartModal, setStartModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");

    const [isSuccess, setSuccess] = useState(false);

    const {
        state: {
            user_data: { access_token },
            student_data: {},
        },
        dispatch,
    } = useContext(Context);
    const handleLogModal = () => {
        setExamModal(!isExamModal);
    };

    const studentUpdate = (item) => {
        dispatch({
            type: "UPDATE_STUDENT_DETAILS",
            student_data: {
                student_name: item.name,
                phone: item.phone,
                student_photo: student.student_photo,
            },
        });
    };

    //----------------------for starting-----------------------------------
    const startStudentExam = () => {
        setExamLoading(true);
        communityConfig
            .post(
                `enquiry-exams/start-sat-exam/`,
                { student_sat_id: student.student_sat_id },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setExamPk(data.sat_exam_pk);
                    setExamLoading(false);
                    setExamModal(true);
                    setStartModal(false);
                } else {
                    setExamLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setExamLoading(false);
            });
    };

    //----------------send current questions-------------

    const fetchExamQuestions = () => {
        setQuestionsLoading(true);
        communityConfig
            .get(
                `enquiry-exams/send-current-question/?student_sat_id=${studentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: { student_sat_id: student.student_sat_id },
                }
            )
            .then((response) => {
                let { StatusCode, data, total_questions, is_summary_pending } =
                    response.data;
                if (StatusCode === 6000) {
                    setQuestions(data);
                    setQuestionsLoading(false);
                    setTotalQuestions(total_questions);
                    setMark("");
                    setSelectedId("");
                    setDescription("");
                    setExamModal(true);
                } else {
                    is_summary_pending &&
                        setFeedback(true) &&
                        setExamModal(true);
                    setQuestionsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setQuestionsLoading(false);
            });
    };

    return (
        <>
            <BdaExamStartModal
                isStartModal={isStartModal}
                setStartModal={setStartModal}
                student={student}
                startStudentExam={startStudentExam}
                isExamLoading={isExamLoading}
                studentUpdate={studentUpdate}
            />
            <BdaQuestionsModal
                isExamModal={isExamModal}
                handleLogModal={handleLogModal}
                setExamModal={setExamModal}
                setSuccess={setSuccess}
                number={number}
                setNumber={setNumber}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                description={description}
                setDescription={setDescription}
                student={student}
                examPk={examPk}
                fetchExamQuestions={fetchExamQuestions}
                questions={questions}
                mark={mark}
                totalQuestions={totalQuestions}
                setMark={setMark}
                questionsLoading={questionsLoading}
                setQuestionsLoading={setQuestionsLoading}
                setFeedback={setFeedback}
                isFeedback={isFeedback}
                setContinue={setContinue}
                isContinue={isContinue}
            />
            <ExamCompletedModal
                isSuccess={isSuccess}
                setSuccess={setSuccess}
                number={number}
                setNumber={setNumber}
                student={student}
                setContinue={setContinue}
                isContinue={isContinue}
            />
            <ReviewDescriptionDetails
                setReviewed={setReviewed}
                isReviewed={isReviewed}
                showSummary={showSummary}
            />
            <CreateAccount
                isCreated={isCreated}
                setCreated={setCreated}
                setPhoneNumber={setPhoneNumber}
                setUserName={setUserName}
                setPassword={setPassword}
                phoneNumber={phoneNumber}
                userName={userName}
                password={password}
                createAccount={createAccount}
                setCountryId={setCountryId}
                student={student}
                setClickTrue={setClickTrue}
                checkMessage={checkMessage}
                isCreateLoding={isCreateLoding}
            />
            <EnablePayment
                isEnabled={isEnabled}
                setEnabled={setEnabled}
                setStudentSatId={setStudentSatId}
                setCategory={setCategory}
                student={student}
                fetchEligibility={fetchEligibility}
                setEligibilityId={setEligibilityId}
                isEnableAccounctLoading={isEnableAccounctLoading}
            />
            <ItemList>
                {pagination?.first_item <= pagination?.last_item && (
                    <Item type="slno">{pagination?.first_item + index}</Item>
                )}
                <Item>
                    <p>
                        {student?.name
                            ? student?.name.toLocaleLowerCase()
                            : "--"}
                    </p>
                </Item>
                <Item type="dist">
                    <p>{student?.phone ? student?.phone : "--"}</p>
                </Item>
                <Item>
                    <p>
                        {student?.name_of_college
                            ? student?.name_of_college
                            : "--"}
                    </p>
                </Item>
                <Item type="dist">
                    {student?.student_class ? student?.student_class : "--"}
                </Item>
                {student?.account_details ? (
                    <Item completed={student?.is_exam_completed} type="dist">
                        <ExamDiv
                            className={
                                (student?.account_details.has_account &&
                                    student?.account_details.approval_status ===
                                        "pending") ||
                                (student?.account_details.has_account &&
                                    student?.account_details.approval_status ===
                                        "pending" &&
                                    student?.account_details.is_subscribed)
                                    ? "enable"
                                    : student?.account_details.has_account &&
                                      !student?.account_details.is_subscribed &&
                                      student?.account_details
                                          .approval_status === "approved"
                                    ? "activate"
                                    : student?.account_details.has_account ===
                                      false
                                    ? "create"
                                    : student?.account_details.has_account &&
                                      student?.account_details
                                          .approval_status === "approved" &&
                                      student?.account_details.is_subscribed
                                    ? "receipt"
                                    : ""
                            }
                            onClick={() => {
                                if (!student.account_details.has_account) {
                                    setUserName(student.name);
                                    setPhoneNumber(student.phone);
                                    setCreated(true);
                                } else if (
                                    (student?.account_details.has_account &&
                                        student?.account_details
                                            .approval_status === "pending") ||
                                    (student?.account_details.has_account &&
                                        student?.account_details
                                            .approval_status === "pending" &&
                                        student?.account_details.is_subscribed)
                                ) {
                                    setEnabled(true);
                                } else if (
                                    student?.account_details.has_account &&
                                    !student?.account_details.is_subscribed &&
                                    student?.account_details.approval_status ===
                                        "approved"
                                ) {
                                    activateAccount(
                                        student.account_details.student_id
                                    );
                                    setClickedId(student.id);
                                } else if (
                                    student?.account_details.has_account &&
                                    student?.account_details.approval_status ===
                                        "approved" &&
                                    student?.account_details.is_subscribed
                                ) {
                                }
                            }}
                        >
                            {!student?.account_details.has_account ? (
                                "Create Account"
                            ) : (student?.account_details.has_account &&
                                  student?.account_details.approval_status ===
                                      "pending") ||
                              (student?.account_details.has_account &&
                                  student?.account_details.approval_status ===
                                      "pending" &&
                                  student?.account_details.is_subscribed) ? (
                                "Enable Payment"
                            ) : student?.account_details.has_account &&
                              !student?.account_details.is_subscribed &&
                              student?.account_details.approval_status ===
                                  "approved" &&
                              clickedId === student.id &&
                              isAccountActivatedLoading === true ? (
                                <ButtonLoader />
                            ) : student?.account_details.has_account &&
                              !student?.account_details.is_subscribed &&
                              student?.account_details.approval_status ===
                                  "approved" &&
                              (clickedId === "" || clickedId !== student.id) ? (
                                "Activate Account"
                            ) : student?.account_details.has_account &&
                              student?.account_details.approval_status ===
                                  "approved" &&
                              student?.account_details.is_subscribed ? (
                                <Recipt
                                    to={`/bdm/school-students/invoice/${student.account_details.user_pk}`}
                                >
                                    <span className="recept">Receipt</span>
                                    <div className="none">
                                        <img
                                            src={
                                                require("../../../assets/images/downld.svg")
                                                    .default
                                            }
                                            alt="Download"
                                        />
                                    </div>
                                </Recipt>
                            ) : (
                                ""
                            )}
                        </ExamDiv>
                    </Item>
                ) : (
                    ""
                )}
            </ItemList>
        </>
    );
}
const ItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 20px;
    color: #fff;

    // cursor: pointer;
    &.active {
        background: #121214;
    }
    :last-child {
        border-bottom: 0px solid #000;
    }
`;

const Item = styled.li`
    font-size: 14px;
    text-transform: capitalize;
    :nth-child(5) {
        text-align: center;
    }
    p {
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
    }
    width: ${({ type }) =>
        type === "slno" ? "5%" : type === "dist" ? "10%" : "15%"};
    list-style: none;

    &:last-child {
        text-align: right;
        color: ${({ completed }) => (completed ? "#44c082" : "#ef3d3d")};
    }
    :nth-child(5) {
        text-transform: capitalize;
    }
    &:nth-child(6),
    &:last-child {
        text-align: right;
    }
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    &.review {
        text-decoration: underline;
        cursor: pointer;
    }
    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    &.button {
        display: block;
        padding: 12px 0;
        font-family: "gordita_medium";
        background-color: #fe673a;
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
    }
`;
const ItemDiv = styled(Link)`
    text-transform: capitalize;
    width: 10%;
    color: #fff;
    cursor: ${({ completed }) => (completed ? "pointer" : "not-allowed")};
`;
const StatusDiv = styled.div`
    color: ${({ type }) =>
        type === "not_eligible"
            ? "#ef3d3d"
            : type === "eligible"
            ? "#44c082"
            : "#fff"};
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
`;

const ImageContainer = styled.div`
    width: 25px;
    margin-left: 10px;

    cursor: pointer;
    &img {
        width: 100%;
        display: block;
    }
`;
const Bottom = styled.div`
    font-size: 14px;
    /* text-align: center; */
    margin: 0 auto;
`;
const ExamDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    height: 42px;
    width: 140px;
    font-family: gordita_medium;
    div {
        margin-left: 10px;
    }
    &.create {
        text-decoration: underline;
        color: #fe673a;
    }
    &.enable {
        border: 1px solid #fe673a;
        color: #fe673a;
    }
    &.activate {
        background: #fe673a;
    }
`;
const Recipt = styled(Link)`
    display: flex;
    align-items: center;
    span {
        font-family: gordita_medium;
        color: #fff;
        display: inline-block;
        font-size: 14px;
    }
`;
