import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import profile from "../../../assets/images/school-scientist/profile.svg";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useParams, useSearchParams } from "react-router-dom";

export default function SchoolScientistRightScoreBoardUp() {
	const {
		state: {
			user_data: { access_token },
			student_data: {},
		},
		dispatch,
	} = useContext(Context);

	const [studentProfile, setStudentProfile] = useState([]);
	const [params] = useSearchParams();
	const phone = params.get("phone");

	useEffect(() => {
		accountsConfig
			.get(`/api/v1/users/view/profile/?phone=${phone}`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				// params: { phone: phone },
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setStudentProfile(data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	return (
		<>
			<Profiles>
				<TotalContainer>
					<TopContainer>
						<ImageContainer>
							<Image src={profile} />
						</ImageContainer>
						<Name>{studentProfile.name}</Name>
						<AdmissionCode>
							{studentProfile.admission_number}
						</AdmissionCode>
					</TopContainer>
					<MiddleContainer>
						<TopDiv>
							<LeftDiv></LeftDiv>
							<RightDiv>
								<Score>Exam Score</Score>
								<Exam>{studentProfile?.details?.score}</Exam>
							</RightDiv>
						</TopDiv>
						<Cover>
							<Item>Class : {studentProfile.student_class}</Item>
							<Item>
								Time :{" "}
								{studentProfile?.details?.time_taken !== null &&
								studentProfile?.details?.time_taken
									? Math.round(
											studentProfile?.details
												?.time_taken / 60
									  )
									: "--"}
							</Item>
							<Item>
								School&nbsp; &nbsp;
								<address>
									: {studentProfile?.details?.campus_name}
								</address>
							</Item>
							<Item>
								Phone No : {studentProfile.phone_number}
							</Item>
							<Item className="text">
								Category :&nbsp;
								<span>{studentProfile.category}</span>
							</Item>
						</Cover>
					</MiddleContainer>
				</TotalContainer>
			</Profiles>
		</>
	);
}

const Profiles = styled.div`
	width: 26%;
	background: #1b1c1f;
	padding: 30px 15px;
	min-height: calc(100vh - 80px);
	border-radius: 5px;
	@media all and (max-width: 1280px) {
		width: 29%;
	}
	@media all and (max-width: 1080px) {
		width: 100%;
		margin-bottom: 15px;
	}
`;
const TotalContainer = styled.div``;

const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
`;
const ImageContainer = styled.div`
	width: 100px;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		width: 80px;
	}
`;
const Image = styled.img`
	width: 100%;
	display: block;
	border-radius: 50%;
`;
const Name = styled.span`
	color: #fff;
	font-size: 18px;
	font-family: "gordita_medium";
	margin-bottom: 5px;
	text-transform: capitalize;
	@media all and (max-width: 480px) {
		font-size: 16px;
	}
`;
const AdmissionCode = styled.span`
	font-size: 14px;
	color: #fe673a;
	display: flex;
	@media all and (max-width: 480px) {
		font-size: 13px;
	}
`;
const MiddleContainer = styled.div`
	background: #161619;
	padding: 15px;
	border-radius: 5px;
`;
const TopDiv = styled.div`
	background: #0000;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #26272a;
`;
const LeftDiv = styled.div`
	color: ${({ type }) => (type === "eligible" ? "#12a457" : "red")};
	font-size: 14px;
	font-family: "gordita_medium";
	text-transform: capitalize;
`;
const RightDiv = styled.div`
	font-size: 18px;
	display: flex;
	justify-content: space-between;
`;
const Score = styled.span`
	color: #fff;
	font-size: 16px;
	font-family: "gordita_medium";
	margin-bottom: 5px;
`;
const Exam = styled.span`
	color: #fff;
	font-size: 14px;
	display: flex;
`;
const Cover = styled.div``;
const Item = styled.span`
	font-size: 17px;
	color: #fff;
	display: flex;
	margin-bottom: 20px;
	font-size: 15px;

	address {
		display: block;
		font-size: 15px;
	}
	span {
		text-transform: uppercase;
	}
`;
