import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventsRouter from "../events/EventsRouter";
//components
const SATEvents = lazy(() => import("../../../screens/SAT/SATEvents"));
const SATEnquiries = lazy(() => import("../../../screens/SAT/SATEnquiries"));
const ScoreBoard = lazy(() => import("../../../screens/examiner/ScoreBoard"));

function SatRouter() {
    return (
        <Routes>
            <Route path="enquiries/" element={<SATEnquiries />} />
            <Route path="applicants/" element={<SATEvents />} />
            <Route path="exam-centers/*" element={<EventsRouter />} />
            <Route
                path=":student_sat_id/scoreboard/"
                element={<ScoreBoard />}
            />
        </Routes>
    );
}

export default SatRouter;
