import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import MeetInfo from "./MeetInfo";
import MeetPartcipants from "./MeetPartcipants";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import { useParams } from "react-router-dom";
import SectionLoader from "../../../helpers/SectionLoader";

function MeetInner({}) {
    const [activeTab, setActiveTab] = useState(1);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [isReload, setReload] = useState(false);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const { meet_id } = useParams();

    // Fetching card data from API

    const fetchCards = async () => {
        learnConfig
            .get(`events/event/${meet_id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, data } = response.data;
                if (status_code === 6000) {
                    setCards(data);
                    setLoading(false);
                } else {
                    setCards([]);
                }
            });
    };

    useEffect(() => {
        setLoading(true);
        fetchCards();
    }, [isReload]);

    const TabContent1 = () => <MeetInfo setReload={setReload} cards={cards} />;

    const TabContent2 = () => <MeetPartcipants />;

    return (
        <>
            <MainContainer>
                <Heading>Meets</Heading>
                <TableContainer>
                    <TabHeading
                        active={activeTab === 1}
                        onClick={() => setActiveTab(1)}
                    >
                        Meet info
                    </TabHeading>
                    <TabHeading
                        active={activeTab === 2}
                        onClick={() => setActiveTab(2)}
                    >
                        Participants
                    </TabHeading>
                </TableContainer>
                {loading ? (
                    <SectionLoader />
                ) : activeTab === 1 ? (
                    <TabContent1 />
                ) : (
                    <TabContent2 />
                )}
            </MainContainer>
        </>
    );
}

export default MeetInner;

const MainContainer = styled.div`
    width: 100%;
    color: white;
`;
const Heading = styled.h3`
    font-size: 24px;
    font-family: "gordita_medium";
    color: #fff;
    margin-bottom: 24px;
`;
const TabHeading = styled.h3`
    font-size: 18px;
    margin-right: 18px;
    margin-bottom: 16px;
    font-family: "gordita_medium";

    color: ${(props) => (props.active ? "#fe673a" : "#667085")};
    cursor: pointer;
`;
const TableContainer = styled.div`
    display: flex;
    border-bottom: 1px solid #26272a;
`;
