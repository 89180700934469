import React from "react";

//packages
import styled from "styled-components";
import InterviewStatusModal from "./modals/InterviewStatusModal";
import { useState } from "react";
import { getDDMMYY } from "../../helpers/functions";

function ApplicantList({ data, index, isUpdated, setUpdated }) {
    const [Ismodal, setModal] = useState(false);
    return (
        <>
            <InterviewStatusModal
                isFormModal={Ismodal}
                setFormModal={setModal}
                data={data}
                isUpdated={isUpdated}
                setUpdated={setUpdated}
            />
            <DatasDiv key={data.id}>
                <DataDiv type="slno">{index + 1}</DataDiv>
                <DataDiv type="name">
                    {data.name}
                    <div><span>{data.phone}</span></div>
                </DataDiv>
                <DataDiv type="dist">
                    <p>{data.email}</p>
                </DataDiv>
                <DataDiv type="slno">{data.gender}</DataDiv>
                <DataDiv>
                    <div>{data.name_of_college}</div>
                    <div>{data.educational_qualification}</div>
                    <div>{data.year_of_graduation}</div>
                    <div>{data.year_of_study}</div>
                </DataDiv>
                <DataDiv type="dist">
                    <div><p>{data.local_body && data.local_body.name}</p></div>
                    <div>{data.local_body && data.local_body.type}</div>
                    <div><p>{data.local_body && data.local_body.district}</p></div>  
                </DataDiv>
                {/* <DataDiv type="dist">{data.employment_status}</DataDiv> */}
                <DataDiv type="dist">{getDDMMYY(data.applied_date)}</DataDiv>
                <DataDiv type="dist">
                    {data.interviewed_date ? (
                        <>{getDDMMYY(data.interviewed_date)}</>
                    ) : (
                        "--"
                    )}
                </DataDiv>
                <DataDiv type="slno">
                    <DowmloadImge>
                        {data.resume ? (
                            <a href={data.resume} download target={"_blank"}>
                                <img
                                    src={
                                        require("../../../assets/images/jobdesk/downld.svg")
                                            .default
                                    }
                                    alt="Download"
                                />
                            </a>
                        ) : (
                            "--"
                        )}
                    </DowmloadImge>
                </DataDiv>
                <ButtonDiv type="dist">
                    {data.status === "pending" ? (
                        <button
                            onClick={() => {
                                setModal(true);
                            }}
                        >
                            Update
                        </button>
                    ) : data.status === "rejected" ? (
                        <SelectedButton type="rejected">
                            Rejected
                        </SelectedButton>
                    ) : data.status === "approved" ? (
                        <SelectedButton type="selected">
                            Accepted
                        </SelectedButton>
                    ) : (
                        ""
                    )}
                </ButtonDiv>
            </DatasDiv>
        </>
    );
}
const DatasDiv = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    padding: 20px;
    font-size: 14px;
    text-align: left;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(0, 0, 0);
    font-family: "gordita_regular";
    background: rgb(27, 28, 31);
    
`;

const DataDiv = styled.div`
    width: ${({ type }) =>
        type === "slno" ? "4%" : type === "dist" ? "10%" : "14%"};
    p {
        width: 90%;
        margin-right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(255, 255, 255);
        text-align: left;
    }
    div {
        width: 100%;
    }
    span{
        color: #f7684c;
    }
`;
const ButtonDiv = styled.div`
    width: 10%;
    button {
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        color: rgb(255, 255, 255);
        background: rgb(254, 103, 58);
        border: 1px solid rgb(254, 103, 58);
        height: 35px;
        width: 85px;
        margin-left: auto;
        font-family: "gordita_medium";
    }
    text-align: center;
`;

const DowmloadImge = styled.div`
    width: 20px;
    cursor: pointer;
    text-align: center;
    img {
        width: 25px;
        display: block;
        margin: 0 auto;
    }
    a {
        color: #fff;
    }
`;
const SelectedButton = styled.p`
    color: ${({ type }) =>
        type === "rejected" ? "red" : type === "selected" ? "green" : "#fff"};
`;
    

export default ApplicantList;
