import React, { useState, useContext, useEffect } from "react";
import SteypHelmet from "../../helpers/SteypHelmet";
import GroupHeader from "./GroupHeader";
import GroupDetails from "./GroupDetails";
import { Context } from "../../contexts/Store";
import { learnConfig } from "../../../axiosConfig";
import { useSearchParams } from "react-router-dom";

function Group() {
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [groupData, setGroupData] = useState("");
    const [pagination, setPagination] = useState(null);
    const [successful, setSuccessful] = useState(false);
    const [isReload, setReload] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);

    // search params

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    // access token

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    // fetching group list from api

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await learnConfig.get(
                `events/groups-list/?q=${searchInput}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        page: currentPage,
                    },
                }
            );
            const { status_code, data, pagination_data } = response.data;

            if (status_code === 6000) {
                setGroupData(data);
                setLoading(false);
                setPagination(pagination_data);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
        setReload(false);
    }, [searchInput, currentPage, isReload]);

    const handleCardButtonClick = (card) => {
        setSelectedCard(card);
    };

    const closePopup = () => {
        setSelectedCard(null);
    };

    return (
        <>
            <SteypHelmet title="Groups" />
            <GroupHeader
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                setSuccessful={setSuccessful}
                successful={successful}
                setReload={setReload}
                isReload={isReload}
            />
            <GroupDetails
                setReload={setReload}
                isReload={isReload}
                groupData={groupData}
                loading={loading}
                pagination={pagination}
                handlePageClick={handlePageClick}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                handleCardButtonClick={handleCardButtonClick}
            />
        </>
    );
}

export default Group;
