import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import BdmCollegeStudents from "../../../includes/bdm/BdmCollegeStudents";
import BdmGratuateStudents from "../../../includes/bdm/BdmGratuateStudents";
import BdmSchoolStudents from "../../../includes/bdm/BdmSchoolStudents";

//componets
const BdmDashboard = lazy(() => import("../../../screens/bdm/BdmDashboard"));
const BdmLeads = lazy(() => import("../../../screens/bdm/BdmLeads"));

const BdaDetails = lazy(() => import("../../../screens/bdm/BdaDetails"));
const AepDetails = lazy(() => import("../../../screens/bdm/AepDetails"));
const AepTraget = lazy(() => import("../../../screens/bdm/AepTarget"));
const AepPending = lazy(() => import("../../../screens/bdm/AepPending"));
const BdmReport = lazy(() => import("../../../screens/bdm/BdmReport"));
const BdmSettlement = lazy(() => import("../../../screens/bdm/BdmSettlement"));
const BdmInvoice = lazy(() => import("../../../screens/bdm/BdmInvoice"));

function BdmRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="dashboard/" />} />
            <Route path="dashboard/" element={<BdmDashboard />} />
            <Route path="school-students/" element={<BdmSchoolStudents />} />
            <Route path="college-students/" element={<BdmCollegeStudents />} />
            <Route
                path="gratuates-students/"
                element={<BdmGratuateStudents />}
            />
            <Route path="bda-manager/" element={<BdaDetails />} />
            <Route path="aep-manager/" element={<AepDetails />} />
            <Route path="target/" element={<AepTraget />} />
            <Route path="pending/" element={<AepPending />} />
            <Route path="report/" element={<BdmReport />} />
            <Route path="settlement/" element={<BdmSettlement />} />
            <Route
                path="school-students/invoice/:student_id"
                element={<BdmInvoice />}
            />
        </Routes>
    );
}

export default BdmRouter;
