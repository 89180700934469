import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { communityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useSearchParams } from "react-router-dom";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "./NoData";

export default function SchoolScientistLeftScoreBoard() {
	const [isLoading, setLoading] = useState(false);
	const [attendedQuestions, setAttendedQuestions] = useState([]);
	const {
		state: {
			user_data: { access_token },
			student_data: {},
		},
		dispatch,
	} = useContext(Context);
	const [searchParams, setSearchParams] = useSearchParams();

	const id = searchParams.get("id") ? searchParams.get("id") : "";

	useEffect(() => {
		setLoading(true);
		communityConfig
			.get(`school-scientists/attented-questions/${id}`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setAttendedQuestions(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	}, []);

	const setAnswerStatus =(option, rightOption, selectedOption)=> {
		console.log(option, rightOption, selectedOption, "------------------")
		if(option === rightOption && option === selectedOption) {
			return "right-answer"
		}
		else if(option === rightOption && option !== selectedOption) {
			return "active-answer"
		}
		else if(option === selectedOption && selectedOption !== rightOption ) {
			return "wrong-answer"
		}
	}
	
	

	return (
		<>
			<Container>
				<TitleDiv>
					<LeftDiv>
						<Title> Scoreboard</Title>
						<SubTitle>Dashboard / Students list /Scoreboard </SubTitle>
					</LeftDiv>
				</TitleDiv>
				<ContentDiv>
					{isLoading ? (
						<SectionLoader />
					) : attendedQuestions?.length > 0 ? (
						attendedQuestions.map((question, index) => {
							return (
								<TotalContainer>
									<LeftContent>{index + 1}</LeftContent>
									<RightContent>
										<Paragraph>
											{question?.question_details?.question}
										</Paragraph>
										<Cover>
											{/* {renderQuestionOptions} */}
											<Item
												className={setAnswerStatus("option1", question?.question_details?.right_option, question.selected_option)}
											>
												<Left>A</Left>
												<Right>{question?.question_details?.option1}</Right>
											</Item>
											<Item
												className={setAnswerStatus('option2', question?.question_details?.right_option,question.selected_option)}
											>
												<Left>B</Left>
												<Right>{question?.question_details?.option2}</Right>
											</Item>
											<Item
												className={
													setAnswerStatus('option3', question?.question_details?.right_option,question.selected_option)}
											>
												<Left>C</Left>
												<Right>{question?.question_details?.option3}</Right>
											</Item>
											<Item
												className={
													setAnswerStatus('option4', question?.question_details?.right_option,question.selected_option)}
											>
												<Left>D</Left>
												<Right>{question?.question_details?.option4}</Right>
											</Item>
										</Cover>
									</RightContent>
								</TotalContainer>
							);
						})
					) : (
						<NoData />
					)}
				</ContentDiv>
			</Container>
		</>
	);
}
const Container = styled.div`
	width: 72%;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	@media all and (max-width: 1280px) {
        width: 69%;
    }
	@media all and (max-width: 1080px) {
        width: 100%;
    }
`;
const ContentDiv = styled.div`
	max-height: calc(100vh - 95px);
	margin-top: 30px;
`;
const TitleDiv = styled.div`
	margin-bottom: 5px;
`;
const LeftDiv = styled.div``;
const Title = styled.h3`
	font-size: 18px;
	color: #fff;
	font-family: "gordita_medium";
	margin-bottom: 2px;
	@media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const SubTitle = styled.span`
	color: #737375;
	font-size: 16px;
	@media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
const RightDiv = styled.div``;
const QuestionNumber = styled.span`
	font-size: 16px;
	color: #fff;
`;
const TotalContainer = styled.div`
	display: flex;
	background: #1b1c1f;
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 8px;
`;
const LeftContent = styled.div`
	margin-right: 10px;
	color: #fff;
	font-size: 17px;
	@media all and (max-width: 640px) {
        font-size: 15px;
    }
`;
const RightContent = styled.div`
	width: 99%;
`;
const Paragraph = styled.p`
	margin-bottom: 30px;
	color: #fff;
	font-size: 17px;
	font-family: "gordita_regular";
	width: 89%;
	@media all and (max-width: 640px) {
        font-size: 15px;
    }
	@media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Cover = styled.ul``;

const Item = styled.li`
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	:last-child {
		margin-bottom: 0;
	}

	&.active-answer {
		background-color: green;
		border-radius: 5px;
		padding: 5px;
	}
	&.wrong-answer {
		background-color: red;
		border-radius: 5px;
		padding: 5px;
	}
	&.right-answer {
		background-color: #092f05;
		border-radius: 5px;
		padding: 5px;
	}
`;
const Left = styled.p`
	margin-right: 10px;
	min-width: 35px;
    min-height: 35px;
	font-size: 16px;
	border-radius: 5px;
	border: 1px solid #26272a;
	background: #161619;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
`;
const Right = styled.p`
	color: #fff;
	font-size: 17px;
	@media all and (max-width: 640px) {
        font-size: 15px;
    }
`;
