import React from "react";
import styled from "styled-components";
import { Switch, Route, Routes } from "react-router-dom";
import AgentSinglePage from "../../../screens/community/AgentSinglePage";
import ChiefAgentList from "../../../screens/community/ChiefAgentList";
import AEPform from "../../../includes/aep/AEPform";

function ChiefAgentRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route exact path="/" element={<ChiefAgentList />} />
                <Route exact path="/agent-register" element={<AEPform />} />
                <Route path=":agent_id" element={<AgentSinglePage />} />
            </Routes>
        </Container>
    );
}

export default ChiefAgentRouter;

const Container = styled.div``;
