import React from "react";

const Close = () => {
    return (
        <span data-testid="x" data-icon="x" class="">
            <svg viewBox="0 0 24 24" width="24" height="24" class="">
                <path
                    fill="#adbac1"
                    d="m19.1 17.2-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
                ></path>
            </svg>
        </span>
    );
};

export default Close;
