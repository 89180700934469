import React, { useState } from "react";
import styled from "styled-components";
import CreateGroup from "./CreateGroup";
import SuccessModal from "../meet/trainer/modals/TrainerSuccess";

function GroupHeader({
    searchInput,
    setSearchInput,
    setSuccessful,
    successful,
    setReload,
    isReload,
}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <SuccessModal
                setSuccessful={setSuccessful}
                successful={successful}
                setReload={setReload}
                isReload={isReload}
            />
            <CreateGroup
                showModal={showModal}
                setShowModal={setShowModal}
                setSuccessful={setSuccessful}
                successful={successful}
                setReload={setReload}
                isReload={isReload}
            />
            <MainContainer>
                <LeftContainer>Groups</LeftContainer>
                <HeadRight>
                    <SearchContainer>
                        <SearchImageContainer>
                            <SearchImg
                                src={
                                    require("../../../assets/images/search.svg")
                                        .default
                                }
                                alt="Search-icon"
                            />
                        </SearchImageContainer>
                        <SearchBar>
                            <InputField
                                placeholder="Search Here"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </SearchBar>
                    </SearchContainer>

                    <CreateButton
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        <Plusicon>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/plus.svg"
                                alt="Icon"
                            />
                        </Plusicon>
                        <CreateTitle>Create Group</CreateTitle>
                    </CreateButton>
                </HeadRight>
            </MainContainer>
        </>
    );
}

export default GroupHeader;

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
`;

const LeftContainer = styled.div`
    font-size: 18px;
    font-family: "gordita_medium";
`;

const HeadRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    width: 50%;
`;
const Plusicon = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const CreateTitle = styled.div`
    font-size: 14px;
    color: #fff;
    margin-left: 5px;
`;
const CreateButton = styled.div`
    display: flex;
    align-items: center;
    background-color: #fe673a;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
`;

const SearchContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid #2c2c2c;
    border-radius: 8px;
    background-color: #1b1c1f;
    width: 60%;
`;

const SearchBar = styled.div`
    padding-left: 40px;
    margin-left: 10px;
    border-radius: 5px;
    width: 100%;
    margin-right: 10px;
`;

const SearchImageContainer = styled.div`
    position: absolute;
    left: 4%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    cursor: pointer;
    width: 20px;
`;

const SearchImg = styled.img`
    width: 100%;
    display: block;
`;

const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    width: 100%;
    min-height: 40px;
`;
