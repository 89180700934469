import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function ProjectAddHeader() {
    const goBack = () => {
        window.history.back(); // Go back one step in the browser history
    };

    return (
        <>
            <BackContainer onClick={goBack}>
                <CloseIcon>
                    <Icon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/arrow-left.svg"
                        alt="Arrow-Icon"
                    />
                </CloseIcon>
                <CloseText>Back to all projects</CloseText>
            </BackContainer>
        </>
    );
}

export default ProjectAddHeader;
const BackContainer = styled(Link)`
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    cursor: pointer;
    margin-bottom: 24px;
`;
const CloseIcon = styled.span`
    display: inline-block;
    width: 20px;
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;
const CloseText = styled.h4`
    font-size: 14px;
    font-family: "gordita_medium";
    color: #fe673a;
`;
