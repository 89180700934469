import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
//components
const ExaminerAssesmentRouter = lazy(() =>
    import("../examiners/ExaminerAssesmentRouter")
);
const ExaminerPracticeRouter = lazy(() =>
    import("../examiners/ExaminerPracticeRouter")
);
const Examiners = lazy(() =>
    import("../../../screens/chief-examiner/Examiners")
);
const ExaminerDetailedView = lazy(() =>
    import("../../../screens/chief-examiner/ExaminerDetailedView")
);

function ChiefExaminerRouter() {
    return (
        <Routes>
            <Route path="/" element={<Examiners />} />

            <Route
                path=":examiner_id/details"
                element={<ExaminerDetailedView />}
            />

            <Route
                path=":examiner_id/details/practices/*"
                element={<ExaminerPracticeRouter />}
            />

            <Route
                path=":examiner_id/details/assessments/*"
                element={<ExaminerAssesmentRouter />}
            />
        </Routes>
    );
}

export default ChiefExaminerRouter;
