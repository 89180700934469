import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
//components
const EditDayByDayDetails = lazy(() =>
    import("../../../screens/syllabus/EditDayByDayDetails")
);
const DayByDayDetails = lazy(() =>
    import("../../../screens/syllabus/DayByDayDetails")
);
const DayByDayDashboard = lazy(() =>
    import("../../../screens/syllabus/DayByDayDashboard")
);

function DaybydayRouter() {
    return (
        <Routes>
            <Route path="/" element={<DayByDayDetails />} />
            <Route path=":programs_slug/" element={<DayByDayDashboard />} />
            <Route
                path="tech-schooling/edit/:day_id"
                element={<EditDayByDayDetails />}
            />
        </Routes>
    );
}

export default DaybydayRouter;
