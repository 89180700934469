import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TechEditor from "../content-manager/TechEditor";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function ProjectRuleScreen() {
    const [editorContent, setEditorContent] = useState();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    //add common rules

    const addCommonRules = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("rule", JSON.stringify(editorContent));
        try {
            await learnConfig
                .post("projects/update-common-rule/", formData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })

                .then((res) => {
                    let { StatusCode, data } = res.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        navigate("/projects/current/");
                    } else {
                        console.log(data);
                        setLoading(false);
                    }
                });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    //fetch common rules
    useEffect(() => {
        const fetchCommonRules = async () => {
            try {
                await learnConfig
                    .get("projects/update-common-rule/", {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })

                    .then((res) => {
                        let { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setEditorContent(
                                data === null ? {} : JSON.parse(data)
                            );
                        } else {
                            console.log(data);
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };

        fetchCommonRules();
    }, []);

    return (
        <Container>
            <MianDiv>
                <HeadContainer>
                    <HeadText>Common Rules</HeadText>
                    <HeadButton onClick={() => addCommonRules()}>
                        {isLoading ? (
                            <ButtonLoader width={25} height={25} />
                        ) : (
                            "Save"
                        )}
                    </HeadButton>
                </HeadContainer>
                <EditorContainer>
                    <TechEditor
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                    />
                </EditorContainer>
            </MianDiv>
        </Container>
    );
}

export default ProjectRuleScreen;

const Container = styled.div`
    border: 1px solid #2c2c2c;
    width: 100%;
    height: 85vh;
    border-radius: 8px;
    background: #1b1c1f;
    padding: 24px;
`;
const MianDiv = styled.div``;

const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HeadText = styled.h4`
    font-size: 20px;
    color: #fff;
    font-family: "gordita_medium";
`;
const HeadButton = styled.button`
    border-radius: 8px;
    background-color: #fe673a;
    color: #fff;
    font-size: 16px;
    font-family: "gordita_regular";
    padding: 8px 16px;
    width: 120px;
    cursor: pointer;
`;

const EditorContainer = styled.div`
    margin-top: 20px;
    width: 100%;
    border-radius: 8px !important;
    overflow-x: hidden;
    overflow-y: scroll;
    height: fit-content;
    & .codex-editor__redactor {
        padding: 10px !important;
        min-height: 73vh !important;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;
