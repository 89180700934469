import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { activityConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function RemoveQuestions({
    DeleteQuestion,
    setModal,
    isModal,
    aboutMeText,
    setAboutMeText,
    isBtnLoading,
}) {
    console.log(isModal, "isModal");
    const [selectedId, setSelectedId] = useState("");

    const MAX_CHARACTER_COUNT = 300;

    const characterCount = aboutMeText?.length;
    const isSaveDisabled = characterCount > MAX_CHARACTER_COUNT;

    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleModal();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    // const wrapperRef = useRef(null);
    // useOutsideClick(wrapperRef);

    const handleModal = () => {
        setSelectedId("");
        setModal(!isModal);
    };

    const handleAboutMeTextChange = (e) => {
        const newText = e.target.value;
        if (newText.length <= MAX_CHARACTER_COUNT) {
            setAboutMeText(newText);
        }
    };

    return (
        <>
            <MainContainer>
                {isModal ? (
                    <Overlay
                        style={{ display: isModal ? "block" : "none" }}
                    ></Overlay>
                ) : (
                    ""
                )}
                <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                    <Modal>
                        <Container>
                            <Top>
                                <Head>
                                    <MainTitle>Mention a Reason</MainTitle>
                                    <Close>
                                        <CloseIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                            alt="Close Icon"
                                            onClick={(e) => {
                                                handleModal();
                                            }}
                                        />
                                    </Close>
                                </Head>
                            </Top>
                            <Discription>
                                <p>
                                    Write why you decided to remove the question
                                </p>
                            </Discription>
                            <MiddleBox>
                                <DescriptionHeading>Reason</DescriptionHeading>
                                <DescriptionBox
                                    placeholder="Type here...."
                                    value={aboutMeText}
                                    onChange={handleAboutMeTextChange}
                                    maxLength={MAX_CHARACTER_COUNT}
                                ></DescriptionBox>
                                <DescriptionLabel>
                                    Max {MAX_CHARACTER_COUNT} characters.{" "}
                                    <span>{characterCount} </span>/{" "}
                                    {MAX_CHARACTER_COUNT} used.
                                </DescriptionLabel>
                            </MiddleBox>

                            <Button>
                                <Cancel
                                    onClick={(e) => {
                                        handleModal();
                                    }}
                                >
                                    Cancel
                                </Cancel>
                                {isBtnLoading ? (
                                    <Next>
                                        <ButtonLoader />
                                    </Next>
                                ) : (
                                    <Next
                                        onClick={() => {
                                            DeleteQuestion();
                                        }}
                                    >
                                        Remove Question
                                    </Next>
                                )}
                            </Button>
                        </Container>
                    </Modal>
                </BackContainer>
            </MainContainer>
        </>
    );
}

export default RemoveQuestions;
const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 10px;
    overflow-y: hidden;
    @media all and (max-width: 640px) {
        /* padding: 0px 40px; */
        width: 70%;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 100%;
    height: 100%;
`;
const Top = styled.div``;
const Discription = styled.div`
    border-bottom: 1px solid #26272a;

    p {
        font-family: "gordita_regular";
        margin-bottom: 10px;
        color: #ffffff;
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        /* padding: 0px 40px; */
        font-size: 16px;
    }
`;
const CommentSection = styled.div`
    display: flex;
    position: relative;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #828282;
    justify-content: space-between;
    text-align: center;
`;
const Input = styled.textarea`
    font-size: 15px;
    word-wrap: break-word;
    white-space: pre-wrap;
    outline: none;
    /* min-height: 20px; */
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    color: #ffffff;
    width: 100%;
    resize: none;

    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        padding: 8px 8px;
        font-size: 12px;
    }
`;
const Content = styled.div`
    p {
        font-family: "gordita_medium";
        font-size: 13px;
        margin-top: 20px;
        color: #ffffff;
    }
    @media all and (max-width: 640px) {
        /* padding: 0px 40px; */
        font-size: 16px;
    }
`;

const Message = styled.div`
    width: 100%;
    display: flex;
    /* border: 1px solid #e4e7ec; */
    background: #161619;
    border-radius: 4px;
    padding: 8px 56px 8px 12px;
    margin-top: 4px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid #3d3d3d;
    p {
        font-family: "gordita_regular";
    }
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
    @media all and (max-width: 640px) {
        /* padding: 0px 40px; */
        font-size: 16px;
    }
`;
const Reason = styled.div``;
const Close = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const Middle = styled.div``;
const Profile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;
const ProfileIconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fe673a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fe673a;
    position: relative;
    span {
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
const ProfileDetails = styled.div`
    margin-left: 10px;
    font-family: gordita_regular;
`;
const UserName = styled.h3`
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
`;
const PhoneNumber = styled.h3`
    font-size: 14px;
    color: #fe673a;
`;
const SubTitle = styled.h3`
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    font-family: gordita_medium;
`;
const StatusList = styled.ul``;
const StatusDetail = styled.li`
    display: flex;
    padding: 10px;
    align-items: center;
    background: #161619;
    cursor: pointer;
    border: 1px solid #26272a;
    border-radius: 4px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    &.active {
        border: 1px solid #fe673a;
    }
`;
const Select = styled.div`
    &.show {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #1b1c1f;
        border: 1px solid #26272a;
    }
`;
const Orangetickcontainer = styled.div`
    width: 70%;
`;
const Orangetick = styled.img`
    width: 100%;
    display: block;
`;

const Status = styled.p`
    margin-left: 10px;
    font-size: 14px;
    font-family: gordita_regular;
    color: #fff;
`;
const Details = styled.div`
    margin-top: 16px;
    transition: 0.3s;
`;
const DetailsHead = styled.h3`
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
    font-family: gordita_medium;
`;
const DetailsContent = styled.textarea`
    width: 100%;
    height: 80px;
    border: 1px solid #26272a;
    background: #161619;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    /* opacity:0.5; */
    font-size: 14px;
    outline: none;
    resize: none;
    font-family: gordita_regular;
`;
const Button = styled.div`
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;
const Cancel = styled.div`
    border-radius: 5px;
    width: 100%;
    height: 50px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-right: 25px;
    background: #fff;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        /* padding: 0px 40px; */
        font-size: 12px;
    }
`;
const Next = styled.button`
    cursor: pointer;
    background: #fe673a;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        /* padding: 0px 40px; */
        font-size: 12px;
    }
`;
const MiddleBox = styled.div`
    margin-top: 12px;
`;
const DescriptionHeading = styled.h5`
    color: var(--white, #fff);
    font-size: 14px;
    font-weight: 600;
`;

const DescriptionBox = styled.textarea`
    border-radius: 8px;
    border: 1px solid var(--gray-300, #3d3d3d);
    background: #161619;
    color: #fff;
    font-family: "gordita_regular";
    resize: none;
    min-height: 190px;
    font-size: 16px;
    min-width: 114.285714286%;
    transform: scale(0.875);
    padding: 10px 15px;
    transform-origin: left;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const DescriptionLabel = styled.h6`
    position: relative;
    top: -15px;
    font-size: 12px;
    color: var(--white, #fff);
    font-family: "gordita_regular";
    span {
        color: #1ea027;
    }
`;
