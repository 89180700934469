import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import SteypHelmet from "../../helpers/SteypHelmet";
import DownloadFilter from "./DownloadFilter";
import ExamFilter from "./ExamFilter";
import ExamStartModal from "./modal/ExamStartModal";
import TechSchoolingStudentCard from "./TechSchoolingStudentCard";

const TechSchoolingStudentList = () => {
    const student = {
        student_photo: "",
        student_name: "Junaid",
        student_sat_id: 1,
        student_phone_code: "+91",
        student_phone: "8943536377",
        category: "school",
        student_class: "10",
        district: "thrisur",
        student_stream: "",
        exam_progress: { is_started: false, is_compleated: false },
        exam_score: "10",
        exam_status: "eligible",
        assignedby: "anjana",
        sheduled_on: "10/02/22",
    };

    return (
        <Container id="main">
            <MainSection>
                <SteypHelmet title="Students" />
                <FilterContainer>
                    <TextArea>Students list</TextArea>
                    <RightContainer>
                        <DownloadFilter />
                        <ExamFilter />
                    </RightContainer>
                </FilterContainer>
                <MainContainer>
                    <Cover>
                        <Category>
                            <Item type="slno">Sl No.</Item>
                            <Item>Name</Item>
                            <Item type="dist">Phone Number</Item>
                            <Item type="dist">Class</Item>
                            <Item type="dist">Localbody</Item>
                            <Item type="dist">Scheduled On</Item>
                            <Item type="dist">Assigned By</Item>
                            <Item type="dist">Exam status</Item>
                            <Item type="slno"> Action</Item>
                        </Category>

                        <>
                            <TechSchoolingStudentCard student={student} />
                            {/* <ImageBox>
                                <CardImage
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                                    alt="Image"
                                />
                                <p>No Data Found</p>
                            </ImageBox> */}
                        </>
                    </Cover>
                </MainContainer>
            </MainSection>
        </Container>
    );
};

export default TechSchoolingStudentList;

const Container = styled.section``;
const MainSection = styled.div`
    min-width: 1200px;
`;
const MainContainer = styled.div`
    border: 1px solid #26272a;
    border-radius: 5px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border-radius: 5px;
    border: 1px solid #26272a;
`;
const Cover = styled.div`
    padding: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1b1c20;
    color: #fff;
    min-width: 1300px;
    .card-container {
        border-bottom: 1px solid #26272a;
        :last-child {
            border-bottom: 0px solid #000;
        }
    }
`;
const Category = styled.div`
    border-bottom: 1px solid #26272a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
`;
const Item = styled.li`
    font-size: 14px;
    text-align: ${({ type }) => (type === "count" ? "center" : "left")};
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "slno" ? "5%" : type === "dist" ? "10%" : "15%"};
    list-style: none;
    &:last-child {
        text-align: right;
    }

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
`;
const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    margin-top: 170px;
    height: calc(100% - (-50px));
    margin-bottom: 170px;
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;
const CardImage = styled.img`
    width: 100%;
    display: block;
`;

const FilterContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 460px) {
        align-items: flex-end;
    }
`;
const RightContainer = styled.div`
    display: flex;
    align-items: center;
`;
const TextArea = styled.div`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 460px) {
        margin-bottom: 15px;
    }
`;

const Statusdetail = styled.p`
    margin-left: 5px;
    font-size: 13px;
    font-family: "gordita_regular";
    color: #fff;
`;
const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
`;
const ToggleBackground = styled.div`
    position: relative;
    height: 20px;
    width: 40px;
    background-color: #401a17;
    border-radius: 15px;
    &.activate {
        background-color: #fff;
    }
`;
const Circle = styled.span`
    position: absolute;
    left: 4px;
    top: 1.5px;
    height: 16px;
    width: 16px;
    background-color: #fe673a;
    transition: 0.4s;
    border-radius: 50%;
    &.activate {
        left: 20px;
        background-color: #fe673a;
    }
`;
const NotFollowup = styled.div`
    display: flex;
    align-items: center;
    padding-left: 12px;
    margin-right: 15px;
`;
