import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import TrainerHeader from "../../../includes/meet/TrainerHeader";
import TrainersList from "./TrainersList";
import Vector from "../../../../assets/images/Vector.png";
import { Context } from "../../../contexts/Store";
import { learnConfig } from "../../../../axiosConfig";

export default function TrainerDeatils() {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    // api connection for listing trainers

    const [userData, setUserData] = useState([]);
    const [isReload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [successful, setSuccessful] = useState(false);

    // search params

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    const fetchData = () => {
        setLoading(true);
        learnConfig
            .get(`events/hosts-list/?q=${searchInput}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    page: currentPage,
                },
            })
            .then((response) => {
                const { status_code, data, pagination_data } = response.data;

                if (status_code === 6000) {
                    setUserData(data);
                    setPagination(pagination_data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        setReload(true);
    }, [currentPage, searchInput, isReload]);

    return (
        <>
            <TrainerHeader
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                successful={successful}
                setSuccessful={setSuccessful}
                isReload={isReload}
                setReload={setReload}
            />
            <TrainersList
                loading={loading}
                searchInput={searchInput}
                handlePageClick={handlePageClick}
                setSearchInput={setSearchInput}
                userData={userData}
                isReload={isReload}
                pagination={pagination}
                currentParams={currentParams}
                successful={successful}
                setSuccessful={setSuccessful}
            />
        </>
    );
}
