import React from 'react'
// import { data } from "./Data/data";
import styled from "styled-components";


function Overview({data}) {
  return (
    <div>
        <Cards>
            {data?.map((item, id) => (
                <Card>
                    <TopContainer>
                        <Div>
                            <Span>{item.name}</Span>
                            {/* <ImageContainer>
                                <Img src={item.src} />
                            </ImageContainer> */}
                        </Div>
                    </TopContainer>
                    <Count>{item.subname}</Count>
                        <LastChild>
                            <Image><Img src={item.icons} /></Image>
                            <Span>{item.title}</Span>
                        </LastChild>
                        <LastChild>
                            <Image><Img src={item.icon} /></Image>
                            <Span>{item.subtitle}</Span>
                        </LastChild>
                </Card>
            ))}
        </Cards>
    </div>
  )
}

export default Overview

const Cards = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;
const Card = styled.li`
  width: 18%;
  border: 1px solid #3B3B3B;
  padding: 18px;
  border-radius: 8px;
//   cursor: pointer;
  background-color: #1B1C1F;
  &:hover{
    border: 1px solid #FE673A;
  }
  &:hover span,
  &:hover H3{
    color: #FE673A;
  }
  &:last-child{
    width: 23%;
  }
  @media all and (max-width: 1280px){
      padding: 12px;
  }
  @media all and (max-width: 980px){
      width: 24%;
      margin-bottom: 10px;
      &:last-child{
         width: 30%;
      }
  }
  @media all and (max-width: 768px){
    &:last-child{
        width: 34%;
      }
  }
  @media all and (max-width: 640px){
    width: 49%;
    &:last-child{
        width: 49%;
      }
  }
  @media all and (max-width: 480px){
    &:last-child{
        width: 100%;
    }
  }
  @media all and (max-width: 360px){
    width: 100%;
  }
  
`;
const TopContainer = styled.div`
  margin-bottom: 5px;
`;
const Div = styled.div`
   display: flex;
   justify-content: space-between;
`;
const Span = styled.span`
  font-size: 16px;
  @media all and (max-width: 1080px){
       font-size: 14px;
   }
`;
const ImageContainer = styled.div`
   justify-content: end;
`;
const Img = styled.img`
   display: block;
   width: 100%;
`;
const Count = styled.h3`
   font-size: 30px;
   font-family: gordita_medium;

   @media all and (max-width: 1380px){
      font-size: 25px;
    }
    @media all and (max-width: 768px){
      font-size: 20px;
    }

`;

const Image = styled.div`
  width: 9%;
  margin-right: 10px;
 
`;
const LastChild = styled.div`
   display: flex;
   align-items: center;
   &:last-child{
      margin-top: 10px;
   }
  
`;
