import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { useState } from "react";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { communityConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";

function SchoolScientistDeleteModal({
	isDeleteModal,
	setDeleteModal,
	questionObject,
	setRender,
	render,
}) {
	const {
		state: {
			user_data: { access_token },
			student_data: {},
		},
		dispatch,
	} = useContext(Context);

	// =============loader=============
	const [loading, setLoading] = useState(false);

	// ==========qustion delete function=========
	const deleteQustion = () => {
		setLoading(true);
		communityConfig
			.get(`school-scientists/question/delete/${questionObject.id}/`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setLoading(false);
					setDeleteModal(false);
					setTimeout(() =>{
						setRender(!render)
					},1000)
				} else {
					setLoading(false);
					setDeleteModal(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				setDeleteModal(false);
			});
	};

	return (
		<BackContainer>
			<Cover className={isDeleteModal ? "active" : ""}>
				<Overlay
					onClick={() => {
						setDeleteModal(false);
					}}
				></Overlay>
				<Modal>
					<Container>
						<HeadingContainer>
							<Close
								onClick={() => {
									setDeleteModal(false);
								}}
							>
								<CloseIcon
									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
									alt="Close Icon"
								/>
							</Close>
							<Text>
								Are you sure you want to delete this question ?
								{/* {modalType === "Enquiries"
                                    ? " Are you sure do you want to delete this  Enquiries?"
                                    : modalType === "Applicant"
                                    ? "Are you sure do you want to delete this  Applicant?"
                                    : modalType === "EventExaminers"
                                    ? " Are you sure do you want to remove this  Examiner?"
                                    : modalType === "Question"
                                    ? " Are you sure do you want to delete this  question?"
                                    : "Are you sure do you want to delete this daily syllabus?"} */}
							</Text>
						</HeadingContainer>
						<ButtonContainer>
							<Button
								className="reject"
								onClick={() => {
									setDeleteModal(false);
								}}
							>
								No
							</Button>
							<Button onClick={() => deleteQustion()}>
								{loading ? <ButtonLoader /> : "  Yes"}
							</Button>
						</ButtonContainer>
					</Container>
				</Modal>
			</Cover>
		</BackContainer>
	);
}

export default SchoolScientistDeleteModal;
const BackContainer = styled.div``;
const Cover = styled.div`
	position: fixed;
	transition: 0.3s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	&.active {
		transform: scale(1, 1);
		backdrop-filter: blur(4px);
	}
`;
// const Overlay = styled.div`
//     position: absolute;
//     left: 0;
//     top: 0px;
//     width: 100%;
//     min-height: 100vh;
//     max-height: 100vh;
// `;
const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0px;
	width: 100%;
	/* z-index: 1000; */
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
`;
const Modal = styled.div`
	width: 90%;
	max-width: 460px;
	max-height: 100vh;
	position: absolute;
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s;
	z-index: 1000;
	border-radius: 5px;
	overflow-y: hidden;
	box-shadow: 0px 3px 56px #00000048;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}

	@media all and (max-width: 1280px) {
	}
	@media all and (max-width: 400px) {
		max-height: 70vh;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 35px;
	width: 100%;
	height: 100%;
	background-color: #1b1c1f;
	border: 1px solid #26272a;
	@media all and (max-width: 480px) {
		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	@media all and (max-width: 360px) {
		padding: 10px;
	}
`;
const HeadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
`;
const Text = styled.p`
	font-size: 14px;
	color: #fff;
	margin-bottom: 25px;
	font-family: "gordita_medium";
	width: 100%;
	text-align: left;
`;
const Close = styled.div`
	cursor: pointer;
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;
const CloseIcon = styled.img`
	width: 18px;
	display: block;
	margin-bottom: 15px;
`;
const Button = styled.div`
	font-size: 14px;
	font-family: "gordita_medium";
	background-color: #5dbc88;
	height: 35px;
	width: 100px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	font-family: "gordita_medium";
	&:nth-child(1) {
		margin-right: 20px;
	}

	&.reject {
		background: #161619;
		border: 1px solid #fe673a;
		color: #fe673a;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	text-align: right;
	justify-content: flex-end;
`;
