import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import AepPoster from "./AepPoster";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function AepBussinessCardForm() {
    const [aepName, setAepName] = useState("");
    const [aepPhoneNumber, setPhoneNumber] = useState("");
    const [aepAddress, setAepAddress] = useState("");
    const [showPoster, setShowPoster] = useState(false);
    const [error, setError] = useState(false);
    const [buttonLoader, setLoader] = useState(false);
    const [localbody, setLocalbody] = useState("");
    const [validate, setValidate] = useState("");

    const getPanchayath = () => {
        // accountsConfig
        //     .get("/api/v1/users/business-card/", {
        //         params: {
        //             phone: aepPhoneNumber,
        //         },
        //     })
        //     .then((response) => {
        //         // console.log(response);
        //         const { data, StatusCode } = response.data;
        //         if (StatusCode === 6000) {
        //             setLoader(false);
        //         } else if (StatusCode === 6001) {
        //             setLoader(false);
        //             setShowPoster(true);
        //             if (data.message === "aep not exist in this number") {
        //                 setValidate(data.message);
        //             }
        //         }
        //     })
        //     .catch((err) => {
        //         console.log("err", err);
        //     });
    };

    return (
        <>
            {showPoster && (
                <AepPoster
                    aepName={aepName}
                    aepPhone={aepPhoneNumber}
                    setShowPoster={setShowPoster}
                    aepAddress={aepAddress}
                    localbody={localbody}
                    type="business_card"
                />
            )}
            <MainSection id="main">
                <Container>
                    <Title>Business Card</Title>
                    <InputContainer>
                        <Label>
                            AEP Name <span className="name">:</span>
                        </Label>
                        <InputField
                            // placeholder="Enter your name "
                            value={aepName}
                            onChange={(e) => {
                                setAepName(e.target.value);
                            }}
                            type="text"
                        />
                        {error && aepName === "" && (
                            <p>This field is required*</p>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <Label>
                            Phone Number <span>:</span>
                        </Label>
                        <InputField
                            id="phone"
                            // placeholder="Phone number"
                            value={aepPhoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value);
                            }}
                            type="number"
                            onKeyDown={(e) => {
                                ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                            }}
                        />
                        {error && aepPhoneNumber === "" && (
                            <p>This field is required*</p>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <Label>
                            Localbody <span className="name">:</span>
                        </Label>
                        <InputField
                            value={localbody}
                            onChange={(e) => {
                                setLocalbody(e.target.value);
                            }}
                            type="text"
                        />
                        {error && localbody === "" && (
                            <p>This field is required*</p>
                        )}
                    </InputContainer>
                    <AddressContainer>
                        <Label>
                            Address <span className="add">:</span>
                        </Label>
                        <TextArea
                            rows={6}
                            onChange={(e) => {
                                setAepAddress(e.target.value);
                            }}
                            value={aepAddress}
                        ></TextArea>
                        {error && aepAddress === "" && (
                            <p>This field is required*</p>
                        )}
                    </AddressContainer>
                    <GenerateBox>
                        <Generate
                            onClick={() => {
                                if (
                                    aepName !== "" &&
                                    aepPhoneNumber !== "" &&
                                    aepAddress !== "" &&
                                    localbody !== ""
                                ) {
                                    setError(false);
                                    // setLoader(true);
                                    setShowPoster(true);
                                } else {
                                    setError(true);
                                }
                            }}
                        >
                            {buttonLoader ? <ButtonLoader /> : "Preview"}
                        </Generate>
                    </GenerateBox>
                </Container>
            </MainSection>
        </>
    );
}

export default AepBussinessCardForm;

const AddressContainer = styled.div`
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    position: relative;
    p {
        position: absolute;
        right: 10px;
        bottom: -25px;
        font-size: 14px;
        color: red;
    }
    @media all and (max-width: 640px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const TextArea = styled.textarea`
    flex: 1;
    display: inline-block;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #fe673a;
    border-radius: 5px;
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 640px) {
        width: 100% !important;
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const MainSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 550px;
    height: 100vh;
`;

const Container = styled.div`
    width: 50%;
    @media all and (max-width: 1280px) {
        width: 60%;
    }
    @media all and (max-width: 1080px) {
        width: 67%;
    }
    @media all and (max-width: 980px) {
        width: 81%;
    }
    @media all and (max-width: 768px) {
        width: 81%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 410px) {
        width: 95%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const Title = styled.h3`
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
`;
const InputContainer = styled.div`
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    p {
        position: absolute;
        right: 10px;
        bottom: -25px;
        font-size: 14px;
        color: red;
    }
    @media all and (max-width: 640px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 13px;
    }
    @media all and (max-width: 360px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const Label = styled.label`
    width: 34%;
    display: inline-block;
    font-size: 16px;
    span {
        margin: 0 20px;
        display: inline-block;
        &.name {
            margin-left: 58px;
        }
        &.add {
            margin-left: 77px;
        }
    }
    @media all and (max-width: 768px) {
        width: 40%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        margin-bottom: 10px;
        font-size: 15px;
        span {
            margin: 0 10px;
            &.name {
                margin-left: 10px;
            }
            &.add {
                margin-left: 10px;
            }
        }
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 5px;
        span {
            margin: 0;
            &.name {
                margin: 0;
            }
            &.add {
                margin: 0;
            }
        }
    }
`;
const InputField = styled.input`
    flex: 1;
    display: inline-block;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #fe673a;
    border-radius: 5px;
    :valid {
        text-transform: capitalize;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const GenerateBox = styled.div`
    text-align: center;
    display: block;
`;
const Generate = styled.button`
    margin-top: 20px;
    cursor: pointer;
    background-color: #fe673a;
    color: #fff;
    display: inline-block;
    /* padding: 10px 30px; */
    width: 130px;
    height: 50px;
    border-radius: 6px;
    font-family: "gordita_medium";
    font-size: 17px;
`;
