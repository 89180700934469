import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function EnablePayment({
    isEnabled,
    setEnabled,
    setCategory,
    setStudentSatId,
    student,
    setEligibilityId,
    setStudentClass,
    setStudentStream,
    type,
    isEnableAccounctLoading,
}) {
    useEffect(() => {
        if (isEnabled) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isEnabled]);

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setEnabled(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <BackContainer>
            {/* <Overlay onClick={handleModal} ></Overlay> */}
            {isEnabled ? (
                <Overlay
                    onClick={() => setEnabled(false)}
                    style={{ display: isEnabled ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isEnabled && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Header>
                            <Head>
                                <Title>
                                    <InfoImage>
                                        <img
                                            src={
                                                require("../../../../assets/images/aep/info.svg")
                                                    .default
                                            }
                                            alt=""
                                        />
                                    </InfoImage>
                                    <TitleMain>Are you sure?</TitleMain>
                                </Title>
                                <Closeicon
                                    onClick={() => {
                                        setEnabled(false);
                                    }}
                                >
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/close.svg"
                                        alt="Icon"
                                    />
                                </Closeicon>
                            </Head>
                        </Header>
                        <h3>Are you sure you want to enable the payment?</h3>
                        <Continue
                            onClick={() => {
                                setEligibilityId(
                                    student.account_details.student_id
                                );
                                setCategory("college");
                                if (type === "school") {
                                    setStudentClass(student.student_class);
                                }
                                if (type === "college") {
                                    setStudentClass(student.year_of_study);
                                    setStudentSatId(student.graduation_name);
                                }
                                if (type === "graduates") {
                                    setStudentClass(student.year_of_graduation);
                                    setStudentSatId(
                                        student.educational_qualification
                                    );
                                }
                            }}
                        >
                            {isEnableAccounctLoading ? (
                                <ButtonLoader />
                            ) : (
                                "Continue"
                            )}
                        </Continue>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default EnablePayment;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 80%;

    max-width: 530px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    height: 100%;
    h3 {
        font-size: 14px;
        color: #fff;
        margin: 40px 0;
    }
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Header = styled.div`
    border-bottom: 2px solid #26272a;
`;
const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const Headtitle = styled.p`
    font-size: 18px;
    margin-left: 10px;
    color: #fff;
    font-family: gordita_medium;
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const InfoImage = styled.div`
    width: 40px;
    margin-right: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
const Title = styled.div`
    display: flex;
    align-items: center;
`;
const TitleMain = styled.span`
    font-size: 20px;
    font-family: "gordita_medium";
`;

const Continue = styled.div`
    width: 40%;
    margin: 0 0 0px auto;
    font-family: "gordita_medium";
    background-color: #fe673a;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    min-height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
`;
