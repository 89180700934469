import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Uploaded from "../../../assets/images/sat-agent/uploaded.svg";
import { Context } from "../../contexts/Store";
import { accountsConfig, communityConfig } from "../../../axiosConfig";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import arrow from "../../../assets/images/sat-agent/dropdown.svg";
import close from "../../../assets/images/sat-agent/Close.svg";

function AEPformpage() {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    //forms
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [adress, setAdress] = useState("");
    const [alternate, setAlternate] = useState("");
    const [mapAdress, setMapAdress] = useState("");
    const [localBody, setlocalBody] = useState("Select your local body");
    const [place, setPlace] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [centerPhoto, setCenterPhoto] = useState([]);
    const [idfrontfile, setIdfrontfile] = useState("");
    const [idbackfile, setIdbackfile] = useState("");
    const [registrationfile, setRegistrationfile] = useState("");
    const [frondPancardfile, setFrondPancardfile] = useState("");
    const [service, setService] = useState("");
    const [bankName, setBankName] = useState("");
    const [branchName, setBranchName] = useState("");
    const [accountHolder, setAccountHolder] = useState("");
    const [IfscCode, setIfscCode] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [dropdown, setDropdown] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [centerValue, setCenterValue] = useState("");
    const [frondfile, setFrondfile] = useState("");
    const [backfile, setBackfile] = useState("");
    const [registration, setRegistration] = useState("");
    const [frondPancard, setFrondPancard] = useState("");
    const [placeData, setPlaceData] = useState("");
    const [placeStatus, setPlaceStatus] = useState([]);
    const [placeDropdown, setPlaceDropdown] = useState(false);
    const [isError, setError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [servicesList, setServicesList] = useState([]);
    const [serviceDropdown, setServiceDropdown] = useState(false);
    const [selectService, setSelecteService] = useState([]);
    const [servicePk, setServicePk] = useState([]);
    const [centerPhotoList, setCenterPhotoList] = useState("");
    const [panNumber, setPanNumber] = useState("");

    const handleModal = () => {
        setName("");
        setPlaceData("");
        setNumber("");
        setAdress("");
        setAlternate("");
        setMapAdress("");
        setlocalBody("Select your local body");
        setPlace("");
        setUsername("");
        setPassword("");
        setRepassword("");
        setCenterPhoto("");
        setIdfrontfile("");
        setIdbackfile("");
        setRegistrationfile("");
        setFrondPancardfile("");
        setService("");
        setBankName("");
        setBranchName("");
        setAccountHolder("");
        setIfscCode("");
        setAccountNumber("");
        setCenterValue("");
        setFrondfile("");
        setBackfile("");
        setRegistration("");
        setFrondPancard("");
        setPlaceData("");
    };
    useEffect(() => {
        let newArr = selectService.reduce(
            (prev, next) => prev.concat(next.id),
            []
        );
        setServicePk(newArr);
        // let centerlist = centerPhoto.reduce(
        //   (prev, next) => prev.concat(next),
        //   []
        // );
        // setCenterPhotoList(centerlist);
    }, [selectService, centerPhoto]);

    const onPassword = () => {
        if (password === repassword) {
            setPasswordErrorMessage("");
        } else {
            setPasswordErrorMessage("Reentered password is incorrect");
        }
    };

    // local body list api
    ////////////////////////////////////////////////////

    useEffect(() => {
        onPassword();
    }, [repassword]);

    useEffect(() => {
        const fetchLocalbodyList = () => {
            communityConfig
                .get(`campuses/local-body-list/`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: { type: localBody, q: place },
                })
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setPlaceStatus(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchLocalbodyList();
    }, [place, localBody]);

    //services list api
    ///////////////////////////////////////////////////////

    useEffect(() => {
        const fetchServices = () => {
            accountsConfig
                .get(`/api/v1/users/authorized-education-partner-services/`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setServicesList(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchServices();
    }, []);

    //Agent Create  api
    ///////////////////////////////////////////////////////
    const [single, setSingle] = useState([]);
    const updateEvent = () => {
        setButtonLoading(true);
        const formData = new FormData();
        formData.append("username", username);
        formData.append("name", name);
        formData.append("phone", number);
        formData.append("alternate_phone", alternate);
        formData.append("address", adress);
        formData.append("password", password);
        formData.append("location", mapAdress);
        formData.append("local_body_pk", placeData);
        Array.from(centerPhoto).forEach((file) => {
            formData.append("aep_images", file);
        });
        formData.append("registration_detail", registrationfile);
        formData.append("id_card_front_side", idfrontfile);
        formData.append("id_card_back_side", idbackfile);
        // formData.append("services", JSON.stringify(servicePk));

        formData.append(`services`, JSON.stringify(servicePk));

        // servicePk.map((item)=>{
        //   formData.append("services",[]
        // })
        formData.append("pan_card", frondPancardfile);
        formData.append("bank_name", bankName);
        formData.append("branch_name", branchName);
        formData.append("account_number", accountNumber);
        formData.append("account_holder_name", accountHolder);
        formData.append("ifsc_code", IfscCode);
        formData.append("pan_card_number", panNumber);
        accountsConfig
            .post(
                `/api/v1/users/create-authorized-education-partner/`,
                formData,

                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setButtonLoading(false);
                    handleModal();
                    setError(false);
                } else {
                    setButtonLoading(false);
                    setError(true);
                }
            })
            .catch((error) => {
                setButtonLoading(false);
            });
    };
    const deleteFile = (filename) => {
        setCenterPhoto(centerPhoto.filter((file) => file.name !== filename));
    };
    useEffect(() => {}, [centerPhoto]);

    const handleMultipleFile = (e) => {
        const data = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            setCenterPhoto([...centerPhoto, e.target.files[i]]);
        }
    };

    return (
        <MainContainer>
            <Heading>AEP Form</Heading>
            <Container>
                <LeftContainer>
                    <BasicDetailsDiv>
                        <TitleDiv>
                            <Title>Basic Details</Title>
                        </TitleDiv>
                        <div>
                            <DetailsField>
                                <Campusfield>
                                    <SelectCampustittle>
                                        Name
                                    </SelectCampustittle>
                                    <SelectCampusInput
                                        type="text"
                                        placeholder="Enter your Name"
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        value={name.toLocaleUpperCase()}
                                    />
                                    {isError && !name && (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    )}
                                </Campusfield>

                                <Campusfield className="size">
                                    <SelectCampustittle>
                                        Address
                                    </SelectCampustittle>
                                    <SelectCampusTextarea
                                        type="text"
                                        placeholder="Enter your Address"
                                        onChange={(e) =>
                                            setAdress(e.target.value)
                                        }
                                        value={adress}
                                    />
                                    {isError && !adress && (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    )}
                                </Campusfield>
                            </DetailsField>
                            <ContactDiv>
                                <Campusfield>
                                    <SelectCampustittle>
                                        Contact number
                                    </SelectCampustittle>
                                    <SelectCampusInput
                                        type="number"
                                        placeholder="Enter your Contact number "
                                        onChange={(e) =>
                                            setNumber(e.target.value)
                                        }
                                        value={number}
                                    />
                                    {isError && !number && (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    )}
                                </Campusfield>

                                <Campusfield>
                                    <SelectCampustittle>
                                        Alternate Number
                                    </SelectCampustittle>
                                    <SelectCampusInput
                                        type="number"
                                        placeholder="Enter your Alternate Number"
                                        onChange={(e) =>
                                            setAlternate(e.target.value)
                                        }
                                        value={alternate}
                                    />
                                    {isError && !alternate && (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    )}
                                </Campusfield>
                            </ContactDiv>
                            <PlaceDiv>
                                <Campusfield>
                                    <SelectCampustittle>
                                        Select your local body
                                    </SelectCampustittle>
                                    <Dropdownlist
                                        onClick={() => {
                                            setDropdown(!dropdown);
                                        }}
                                    >
                                        <p>{localBody}</p>
                                        <Arrow src={arrow} alt="arrow" />
                                    </Dropdownlist>
                                    {isError && !localBody && (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    )}

                                    {dropdown ? (
                                        <Localbodydiv>
                                            <div>
                                                <li
                                                    onClick={() => {
                                                        setDropdown(false);
                                                        setlocalBody(
                                                            "panchayath"
                                                        );
                                                    }}
                                                >
                                                    Panchayath
                                                </li>
                                                <li
                                                    onClick={() => {
                                                        setDropdown(false);
                                                        setlocalBody(
                                                            "municipality"
                                                        );
                                                    }}
                                                >
                                                    Municipality
                                                </li>
                                                <li
                                                    onClick={() => {
                                                        setDropdown(false);
                                                        setlocalBody(
                                                            "corporation"
                                                        );
                                                    }}
                                                >
                                                    Corporation
                                                </li>
                                            </div>
                                        </Localbodydiv>
                                    ) : null}
                                </Campusfield>
                                <Campusfield>
                                    <SelectCampustittle>
                                        Place
                                    </SelectCampustittle>
                                    <SelectCampusInput
                                        type="text"
                                        placeholder="Enter your place"
                                        onChange={(e) => {
                                            setPlace(e.target.value);
                                            setPlaceDropdown(true);
                                        }}
                                        value={place}
                                    />
                                    {placeStatus.length > 0 &&
                                    place &&
                                    placeDropdown ? (
                                        <Localbodydiv>
                                            <div>
                                                {placeStatus.map((val) => (
                                                    <li
                                                        onClick={() => {
                                                            setPlaceData(
                                                                val.id
                                                            );
                                                            setPlace(val.name);
                                                            setPlaceDropdown(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        {val.name}
                                                    </li>
                                                ))}
                                            </div>
                                        </Localbodydiv>
                                    ) : null}
                                    {isError && !place && (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    )}
                                </Campusfield>
                            </PlaceDiv>
                            <Campusfield className="size">
                                <SelectCampustittle>
                                    Google map URL
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="text"
                                    placeholder="Enter google map URL "
                                    onChange={(e) =>
                                        setMapAdress(e.target.value)
                                    }
                                    value={mapAdress}
                                />
                                {isError && !adress && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Campusfield>
                        </div>
                    </BasicDetailsDiv>
                    <LoginDetailsDiv>
                        <TitleDiv>
                            <Title>Login Details</Title>
                        </TitleDiv>
                        <div>
                            <Userfield>
                                <SelectCampustittle>
                                    UserName
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="text"
                                    placeholder="Enter your username"
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    value={username}
                                />
                                {isError && !username && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Userfield>
                            <PasswordDiv>
                                <Userfield>
                                    <SelectCampustittle>
                                        Password
                                    </SelectCampustittle>
                                    <SelectCampusInput
                                        type="password"
                                        placeholder="Enter your Password"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        value={password}
                                    />
                                    {isError && !password && (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    )}
                                </Userfield>

                                <Userfield>
                                    <SelectCampustittle>
                                        Re-Enter Password
                                    </SelectCampustittle>
                                    <SelectCampusInput
                                        type="password"
                                        placeholder="Re-Enter Password"
                                        onChange={(e) => {
                                            setRepassword(e.target.value);
                                        }}
                                        value={repassword}
                                    />
                                    {isError && !password ? (
                                        <ErrorMessage>
                                            This Field is required
                                        </ErrorMessage>
                                    ) : passwordErrorMessage ? (
                                        <ErrorMessage>
                                            {passwordErrorMessage}
                                        </ErrorMessage>
                                    ) : (
                                        ""
                                    )}
                                </Userfield>
                            </PasswordDiv>
                        </div>
                    </LoginDetailsDiv>
                </LeftContainer>
                <RightContainer>
                    <AdditionalInfoDiv>
                        <TitleDiv>
                            <Title>Additional Info</Title>
                        </TitleDiv>
                        <IdDiv>
                            <Campusfield>
                                <SelectCampustittle>
                                    ID proof (front side)
                                </SelectCampustittle>
                                <SelectCampusInputDiv>
                                    <input
                                        id="frontside"
                                        className="bVGmbA"
                                        type="file"
                                        multiple
                                        onChange={(e) => {
                                            setIdfrontfile(e.target.files[0]);
                                            setFrondfile(e.target.value);
                                        }}
                                        value={frondfile}
                                    />
                                    <ImageDiv
                                        onClick={() => {
                                            document
                                                .getElementById("frontside")
                                                .click();
                                        }}
                                    >
                                        <img
                                            src={Uploaded}
                                            alt="UploadedIcon"
                                        />
                                    </ImageDiv>
                                </SelectCampusInputDiv>
                                {isError && !frondfile && (
                                    <ErrorMessage>Upload File</ErrorMessage>
                                )}
                            </Campusfield>
                            <Campusfield>
                                <SelectCampustittle>
                                    ID proof (Back side)
                                </SelectCampustittle>
                                <SelectCampusInputDiv>
                                    <input
                                        id="Backside"
                                        className="bVGmbA"
                                        type="file"
                                        multiple
                                        onChange={(e) => {
                                            setIdbackfile(e.target.files[0]);
                                            setBackfile(e.target.value);
                                        }}
                                        value={backfile}
                                    />
                                    <ImageDiv
                                        onClick={() => {
                                            document
                                                .getElementById("Backside")
                                                .click();
                                        }}
                                    >
                                        <img
                                            src={Uploaded}
                                            alt="UploadedIcon"
                                        />
                                    </ImageDiv>
                                </SelectCampusInputDiv>
                            </Campusfield>
                        </IdDiv>
                        <RegistrationDiv>
                            <Campusfield className="contact">
                                <SelectCampustittle>
                                    Registration details
                                </SelectCampustittle>
                                <SelectCampusInputDiv>
                                    <input
                                        id="registration"
                                        className="bVGmbA"
                                        type="file"
                                        multiple
                                        onChange={(e) => {
                                            setRegistrationfile(
                                                e.target.files[0]
                                            );
                                            setRegistration(e.target.value);
                                        }}
                                        value={registration}
                                    />
                                    <ImageDiv
                                        onClick={() => {
                                            document
                                                .getElementById("registration")
                                                .click();
                                        }}
                                    >
                                        <img
                                            src={Uploaded}
                                            alt="UploadedIcon"
                                        />
                                    </ImageDiv>
                                </SelectCampusInputDiv>
                                {isError && !registration && (
                                    <ErrorMessage>Upload File</ErrorMessage>
                                )}
                            </Campusfield>
                            <Campusfield className="contact">
                                <SelectCampustittle>
                                    PAN card
                                </SelectCampustittle>
                                <SelectCampusInputDiv>
                                    <input
                                        id="pancardfrond"
                                        className="bVGmbA"
                                        type="file"
                                        multiple
                                        onChange={(e) => {
                                            setFrondPancardfile(
                                                e.target.files[0]
                                            );
                                            setFrondPancard(e.target.value);
                                        }}
                                        value={frondPancard}
                                    />
                                    <ImageDiv
                                        onClick={() => {
                                            document
                                                .getElementById("pancardfrond")
                                                .click();
                                        }}
                                    >
                                        <img
                                            src={Uploaded}
                                            alt="UploadedIcon"
                                        />
                                    </ImageDiv>
                                </SelectCampusInputDiv>
                                {isError && !frondPancard && (
                                    <ErrorMessage>Upload File</ErrorMessage>
                                )}
                            </Campusfield>
                        </RegistrationDiv>
                        <Imagefield>
                            <SelectCampustittle>
                                Images of center
                            </SelectCampustittle>
                            <SelectCampusInputDiv>
                                <SelectCampusphoto>
                                    {centerPhoto.length > 0 ? (
                                        centerPhoto.map((e) => (
                                            <Photoname>
                                                {e.name}
                                                <img
                                                    onClick={() => {
                                                        deleteFile(e.name);
                                                    }}
                                                    src={close}
                                                    alt="close"
                                                />
                                            </Photoname>
                                        ))
                                    ) : (
                                        <span>No file chosen</span>
                                    )}
                                    <SelectInput
                                        id="center"
                                        type="file"
                                        multiple="multiple"
                                        onChange={(e) => {
                                            handleMultipleFile(e);
                                            // setCenterPhoto([...centerPhoto, e.target.files[0]]);
                                            // setCenterValue(e.target.value);
                                        }}
                                        value={centerValue}
                                    />
                                </SelectCampusphoto>

                                <ImageDiv
                                    onClick={() => {
                                        document
                                            .getElementById("center")
                                            .click();
                                    }}
                                    className="image"
                                >
                                    <img src={Uploaded} alt="Uploaded" />
                                </ImageDiv>
                            </SelectCampusInputDiv>
                            {isError && !centerPhoto && (
                                <ErrorMessage>Upload File</ErrorMessage>
                            )}
                        </Imagefield>
                        <Servicesfields>
                            <SelectCampustittle>
                                Services/Facilities available
                            </SelectCampustittle>
                            <SelectInputDiv
                                onClick={() =>
                                    setServiceDropdown(!serviceDropdown)
                                }
                            >
                                <ExaminerDiv>
                                    {selectService.length > 0 ? (
                                        selectService?.map((item) => (
                                            <List>
                                                {item.name}
                                                {","}
                                            </List>
                                        ))
                                    ) : (
                                        <Placeholder>
                                            Enter Your Services/Facilities{" "}
                                        </Placeholder>
                                    )}
                                </ExaminerDiv>

                                <Arrow src={arrow} alt="arrow" />
                            </SelectInputDiv>

                            {isError && !service && (
                                <ErrorMessage>
                                    This Field is required
                                </ErrorMessage>
                            )}
                            {serviceDropdown && (
                                <DropDown>
                                    {servicesList?.map((item, index) => (
                                        <span
                                            key={item.id}
                                            className={
                                                item.is_selected
                                                    ? "selected"
                                                    : ""
                                            }
                                            onClick={() => {
                                                item.is_selected =
                                                    !item.is_selected;
                                                setSelecteService(
                                                    servicesList.filter(
                                                        (item) =>
                                                            item.is_selected
                                                    )
                                                );
                                            }}
                                        >
                                            <p>{item.name}</p>
                                            <TickImage
                                                className={
                                                    item.is_selected
                                                        ? "selected"
                                                        : ""
                                                }
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/Tick.svg"
                                            />
                                        </span>
                                    ))}
                                </DropDown>
                            )}
                        </Servicesfields>
                    </AdditionalInfoDiv>
                    <BankDetailsDiv>
                        <TitleDiv>
                            <Title>Bank Details</Title>
                        </TitleDiv>
                        <AccountDiv>
                            <Campusfields>
                                <SelectCampustittle>
                                    Account number
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="number"
                                    placeholder="Enter your Account number"
                                    onChange={(e) =>
                                        setAccountNumber(e.target.value)
                                    }
                                />
                                {isError && !accountNumber && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Campusfields>
                            <Campusfields>
                                <SelectCampustittle>
                                    PAN number
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="text"
                                    placeholder="Enter your PAN number"
                                    onChange={(e) =>
                                        setPanNumber(e.target.value)
                                    }
                                />
                                {isError && !accountNumber && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Campusfields>
                        </AccountDiv>
                        <AccountDetails>
                            <Campusfield>
                                <SelectCampustittle>
                                    Account holder
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="text"
                                    placeholder="Enter your Account holder"
                                    onChange={(e) =>
                                        setAccountHolder(e.target.value)
                                    }
                                />
                                {isError && !accountHolder && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Campusfield>

                            <Campusfield>
                                <SelectCampustittle>
                                    IFSC code
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="text"
                                    placeholder="Enter your IFSC code"
                                    onChange={(e) =>
                                        setIfscCode(e.target.value)
                                    }
                                    value={IfscCode.toLocaleUpperCase()}
                                />
                                {isError && !IfscCode && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Campusfield>
                        </AccountDetails>
                        <BankDiv>
                            <Campusfield>
                                <SelectCampustittle>
                                    Bank Name
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="text"
                                    placeholder="Enter your Bank Name"
                                    onChange={(e) =>
                                        setBankName(e.target.value)
                                    }
                                    value={bankName.toLocaleUpperCase()}
                                />
                                {isError && !bankName && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Campusfield>
                            <Campusfield>
                                <SelectCampustittle>
                                    Branch name
                                </SelectCampustittle>
                                <SelectCampusInput
                                    type="text"
                                    placeholder="Enter your Branch name"
                                    onChange={(e) =>
                                        setBranchName(e.target.value)
                                    }
                                    value={branchName}
                                />
                                {isError && !branchName && (
                                    <ErrorMessage>
                                        This Field is required
                                    </ErrorMessage>
                                )}
                            </Campusfield>
                        </BankDiv>
                    </BankDetailsDiv>
                </RightContainer>
            </Container>
            <CreateButtonBox>
                <CancelButton
                    onClick={() => {
                        handleModal();
                    }}
                >
                    Cancel
                </CancelButton>
                <CreateButton
                    onClick={() => {
                        updateEvent();
                    }}
                >
                    {isButtonLoading ? <ButtonLoader /> : "Create"}
                </CreateButton>
            </CreateButtonBox>
        </MainContainer>
    );
}

export default AEPformpage;
const MainContainer = styled.div`
    position: relative;
    @media all and (max-width: 1080px) {
        overflow-y: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    border: 1px solid #26272a;
    background: #1b1c1f;
    padding: 30px;
    display: flex;
    @media all and (max-width: 1080px) {
        min-width: 1200px;
    }
`;
const Heading = styled.h4`
    color: #fff;
    font-family: "gordita_medium";
    margin-bottom: 20px;
    font-size: 18px;
`;
const TitleDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;
const Title = styled.h6`
    color: #fff;
    color: #f7684c;
    font-size: 16px;
    margin-right: 5px;
`;

const LeftContainer = styled.div`
    width: 50%;
    padding-right: 25px;
    border-right: 2px dashed #26272a;
`;
const BasicDetailsDiv = styled.div``;
const LoginDetailsDiv = styled.div`
    margin-top: 20px;
`;
const RightContainer = styled.div`
    width: 50%;
    padding-left: 25px;
`;
const AdditionalInfoDiv = styled.div``;
const BankDetailsDiv = styled.div`
    margin-top: 20px;
`;
const Campusfield = styled.div`
    width: 100%;
    position: relative;
    &.contact {
        width: 49%;
    }
    &.size {
        margin-top: 15px;
    }
`;
const SelectCampustittle = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #ababab;

    margin-bottom: 5px;
`;
const SelectCampusInput = styled.input`
    border-radius: 5px !important;
    width: 100%;
    padding: 5px 5px 5px 10px;
    height: 40px;
    color: #fff;
    background-color: #161619 !important;
    border: 1px solid #202124;
    &::-webkit-input-placeholder {
        color: #9b9a9a;
    }
    &:-moz-placeholder {
        color: #9b9a9a;
    }
`;
const SelectCampusTextarea = styled.textarea`
    border-radius: 5px !important;
    border: 1px solid #202124;
    width: 100% !important;
    padding: 10px;
    height: 60px !important;
    resize: none;

    background-color: #161619 !important;
    color: #fff;
`;
const ErrorMessage = styled.p`
    left: 0px;
    position: absolute;
    bottom: -23px;
    color: red;
    font-size: 12px;
`;
const ContactDiv = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
`;

const PlaceDiv = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
`;
const Localbodydiv = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    position: absolute;
    top: 9vh;
    div {
        background-color: #1b1c1f;
        border: 1px solid #26272a;
        color: #fff;
        font-size: 14px;
        z-index: 30;
        width: 100%;
        overflow-y: scroll;
        height: 150px;
        padding: 10px;
        & :first-child {
            margin-top: 0;
        }
    }
    li {
        font-size: 14px;
        color: #7f7f7f;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        background-color: #161619;
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 8px;
    }
`;
const Dropdownlist = styled.div`
    background-color: #161619 !important;
    border-radius: 5px !important;
    width: 100%;
    color: #fff;
    padding: 5px 5px 5px 10px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
        font-size: 14px;
    }
`;
const Userfield = styled.div`
    width: 100%;
    position: relative;
    &.field {
        width: 49%;
    }
`;
const PasswordDiv = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
`;
const SelectCampusInputDiv = styled.div`
    width: 100%;
    border-radius: 5px !important;
    padding: 5px 5px 5px 10px;
    height: 40px;
    background-color: #161619;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #202124;

    input {
        width: 100%;
        ::-webkit-file-upload-button {
            display: none;
            user-select: none;
        }
        ::-webkit-input-placehold {
            color: #9b9a9a;
        }
        :-moz-placeholder {
            color: #9b9a9a;
        }
    }
`;
const SelectCampusphoto = styled.div`
    overflow-y: scroll;
    display: flex;
    align-items: center;
    width: 90%;
    height: 100%;
    overflow-x: scroll;
    & :first-child {
        margin-left: 0;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    span {
        color: #9b9a9a;
        font-size: 13px;
    }
`;
const Photoname = styled.div`
    position: relative;
    color: #9b9a9a;
    border: 2px solid #202124;
    font-size: 13px;
    display: flex;
    padding: 2px 3px;
    margin-left: 7px;

    img {
        position: absolute;
        right: 0;
        top: 0;
        width: 6px;
        z-index: 4;
        cursor: pointer;
    }
`;
const ImageDiv = styled.div`
    align-items: center;
    width: 16px;
    position: absolute;
    right: 10px;
    z-index: 30;

    img {
        width: 100%;
        display: block;
    }
`;
const IdDiv = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
`;
const RegistrationDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`;
const Imagefield = styled.div`
    width: 100%;
    margin-top: 15px;
`;
const Servicesfields = styled.div`
    width: 100%;
    margin-top: 20px;
    position: relative;
`;
const AccountDiv = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
`;
const Campusfields = styled.div`
    width: 100%;
    position: relative;
`;
const AccountDetails = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
`;

const BankDiv = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
`;
const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    @media all and (max-width: 1080px) {
        min-width: 1200px;
    }
`;
const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    // padding: 12px 24px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;
const DetailsField = styled.div`
    & :first-child {
        margin-top: 0;
    }
`;
const Arrow = styled.img`
    width: 12px;
    margin-right: 10px;
    position: absolute;
    right: 0;
`;
const SelectInputDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    background-color: #161619;
    border-radius: 5px;
    border: 1px solid #202124;
`;
const DropDown = styled.div`
    padding: 10px;
    max-height: 150px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #26272a;
    border-radius: 5px;
    top: 9vh;
    transition: 3s ease;
    z-index: 10;
    overflow: scroll;
    background: #1b1c1f;
    & :first-child {
        margin-top: 0;
    }

    ::-webkit-scrollbar {
        display: none;
    }
    span {
        cursor: pointer;
        text-align: left;
        width: 100%;
        padding: 7px;
        font-size: 14px;
        border-radius: 5px;
        color: #fff;
        background: #161619;
        margin-top: 7px;
        text-transform: capitalize;

        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            font-size: 14px;
            color: #9b9a9a;
        }
    }
`;
const TickImage = styled.img`
    display: none;
    &.selected {
        display: block;
        width: 18px;
    }
`;
const ExaminerDiv = styled.div`
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #161619;
    display: flex;
    position: relative;
    padding: 5px 5px 5px 10px;
    height: 40px;
    align-items: center;
    overflow-x: scroll;
    width: 88%;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const List = styled.p`
    font-size: 14px;
    color: #fff;
`;

const Placeholder = styled.span`
    font-size: 14px;
    color: #9b9a9a;
`;

const SelectInput = styled.input`
    display: none;
    position: absolute;
    right: 0;
`;
