import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TechUpdates from "../../includes/content-manager/TechUpdates";
import TechUpdateTop from "../../includes/content-manager/TechUpdateTop";
import TechUpdateNotification from "../../includes/content-manager/modal/TechUpdateNotification";
import { Context } from "../../contexts/Store";
import { learnConfig, notificationsConfig } from "../../../axiosConfig";

function ContentManagerDashboard() {
    const {
        state: {
            notification_modal_data: { isModal, new_notification },
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);
    const [notificationData, setNotificationData] = useState([]);
    const [fetchNotification, setFetchNotification] = useState(false);

    const [articlePK, setArticlePK] = useState("");
    const [loader, setLoader] = useState(false);

    const updateNotificationCount = (data) => {
        let count = 0;
        data.map((item) => {
            if (!item.is_read) {
                count += 1;
            }
        });
        dispatch({
            type: "UPDATE_TECH_NOTIFICATION",
            notification_modal_data: {
                count: count,
            },
        });
    };

    const fetchNotifications = () => {
        learnConfig
            .get("/tech-updates/get-notifications/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setNotificationData(data);
                    updateNotificationCount(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const markAllAsRead = () => {
        setLoader(true);
        notificationsConfig
            .get("/main/mark-all-read/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setFetchNotification((prev) => !prev);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    const markAsRead = (dataPk) => {
        notificationsConfig
            .get(`/main/mark-as-read/${dataPk}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setFetchNotification((prev) => !prev);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotification, new_notification]);

    return (
        <Container id="main">
            <Title>Tech Updates</Title>
            <TechUpdateTop />
            <TechUpdateNotification
                isFilter={isModal}
                notificationData={notificationData}
                readAll={markAllAsRead}
                markAsRead={markAsRead}
                setArticlePK={setArticlePK}
                articlePK={articlePK}
                loader={loader}
            />
            <Middle>
                <TechUpdates />
            </Middle>
        </Container>
    );
}

export default ContentManagerDashboard;

const Container = styled.section`
    /* position: relative; */
`;

const Title = styled.h3`
    color: #fff;
    font-family: gordita_medium;
    font-size: 20px;
    margin-bottom: 20px;
`;

const Middle = styled.div``;
