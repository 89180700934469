import React, { useState } from "react";
import styled from "styled-components";
import SectionLoader from "../../helpers/SectionLoader";
import CallStatusCard from "./CallStatusCard";

function DetailView({ rmStatuses, selectedRM, isStatusLoading, startDate }) {
    return (
        <>
            {isStatusLoading ? (
                <SectionLoader />
            ) : (
                <Container className={selectedRM ? "" : "active"}>
                    {selectedRM && rmStatuses.length > 0 ? (
                        <StudentCardContainer>
                            {rmStatuses.map((item) => (
                                <CallStatusCard
                                    key={item.id}
                                    studentDetails={item}
                                    selectedRM={selectedRM}
                                    startDate={startDate}
                                />
                            ))}
                        </StudentCardContainer>
                    ) : (
                        <ImageContainer>
                            <h4>No preview available.</h4>
                        </ImageContainer>
                    )}
                </Container>
            )}
        </>
    );
}

export default DetailView;

const Container = styled.div`
    min-height: 83vh;
    max-height: 83vh;
    overflow-y: scroll;
    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    scrollbar-width: thin;
    scrollbar-color: #535353 #161619;

    /* Works on Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
        width: 7px;
    }

    ::-webkit-scrollbar-track {
        background: #161619;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #535353;
        border-radius: 20px;
        border: 3px solid #535353;
    }
`;
const ImageContainer = styled.div`
    color: #474747;
`;
const StudentCardContainer = styled.div`
    border-radius: 5px;
    border: 1px solid #2c2c2c;
`;
