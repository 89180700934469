import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Delete } from "../../includes/chat/Icons";
import EmojiPicker from "emoji-picker-react";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

export default function TypingBox({
    setImg,
    sendMessage,
    isExist,
    listenTyping,
    previewImage,
    handleTypingEnd,
    voiceRecordStart,
    voiceRecordEnd,
    setImgPreview,
    recording,
    setFile,
    setFilePreview,
    setRecording,
    voiceRecordCancel,
    insideText,
    setInsideText,
    setIsCode,
    isCode,
    joinedLoader,
    joinConversation,
}) {
    const [isPicker, setPicker] = useState(false);
    const [voiceDurationCount, setVoiceDurationCount] = useState(0);
    const [shiftHeld, setShiftHeld] = useState(false);
    const textareaRef = React.useRef(null);
    const MIN_TEXTAREA_HEIGHT = 21;

    React.useLayoutEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "inherit";
            textareaRef.current.style.height = `${Math.max(
                textareaRef.current.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        }
    }, [insideText]);

    const handlePicker = () => {
        setPicker((prevState) => !prevState);
    };

    const input = document.getElementById("reso");

    useEffect(() => {
        if (recording) {
            const id = setInterval(() => {
                setVoiceDurationCount((prevCount) => prevCount + 1);
            }, 1000);

            return () => clearInterval(id);
        }
    }, [recording]);

    function convertTime(sec) {
        var hours = Math.floor(sec / 3600);
        hours >= 1 ? (sec = sec - hours * 3600) : (hours = "00");
        var min = Math.floor(sec / 60);
        min >= 1 ? (sec = sec - min * 60) : (min = "00");
        sec < 1 ? (sec = "00") : void 0;

        min.toString().length == 1 ? (min = "0" + min) : void 0;
        sec.toString().length == 1 ? (sec = "0" + sec) : void 0;

        if (hours >= 1) {
            return hours + ":" + min + ":" + sec;
        } else {
            return min + ":" + sec;
        }
    }

    const handleKeyPress = (e) => {
        if (shiftHeld && e.key === "Enter") {
            e.preventDefault();
            setInsideText(insideText + "\n");
        } else if (!shiftHeld && e.key === "Enter") {
            if (!!/\S/.test(insideText)) {
                if (isCode) {
                    if (input.value) {
                        sendMessage(e, input.value, true);
                        input.innerHTML = "";
                    }
                } else {
                    if (input.value) {
                        sendMessage(e, input.value, false);
                        input.innerHTML = "";
                    }
                }
            } else {
                setInsideText("");
            }
        }
    };

    const downHandler = (e) => {
        if (e.key === "Shift") {
            setShiftHeld(true);
        }
    };

    const upHandler = (e) => {
        if (e.key === "Shift") {
            setShiftHeld(false);
        }
    };

    return (
        <Container>
            {isPicker && (
                <PickerContainer className={isPicker ? "active" : ""}>
                    <EmojiPicker
                        onEmojiClick={(emojiObject) => {
                            setInsideText(
                                (prevState) => prevState + emojiObject.emoji
                            );
                            input.focus();
                        }}
                    />
                </PickerContainer>
            )}

            {!isExist ? (
                <Back joinedLoader={joinedLoader}>
                    {window.innerWidth < 1281 ? (
                        joinedLoader ? (
                            <ButtonLoader joinedLoader={true} />
                        ) : (
                            <p onClick={joinConversation} className="join">
                                Join Conversation
                            </p>
                        )
                    ) : joinedLoader ? (
                        <ButtonLoader joinedLoader={true} />
                    ) : (
                        <p>Please join the conversation</p>
                    )}
                </Back>
            ) : (
                <Back
                    style={{
                        minHeight: MIN_TEXTAREA_HEIGHT,
                        resize: "none",
                        maxHeight: "150px",
                    }}
                >
                    <RecordingContainer
                        className={recording ? "active" : "disabled"}
                    >
                        <Icon className="record">
                            <img
                                className="file"
                                src={require("../../../assets/images/recording.gif")}
                                alt="Icon"
                            />
                            <span>Recording</span>
                        </Icon>
                        <RecordingRight>
                            <Duration>
                                {voiceDurationCount > 0
                                    ? convertTime(voiceDurationCount)
                                    : "00:00"}
                            </Duration>
                            <DeleteIcon
                                onClick={() => {
                                    setRecording(false);
                                    voiceRecordCancel();
                                    setVoiceDurationCount(0);
                                }}
                            >
                                <Delete />
                            </DeleteIcon>
                            <SendIcon
                                title="Send"
                                className="show"
                                onClick={() => {
                                    setVoiceDurationCount(0);
                                    voiceRecordEnd();
                                }}
                            >
                                <img
                                    src={
                                        require("../../../assets/images/chat/send.svg")
                                            .default
                                    }
                                    alt="Send"
                                />
                            </SendIcon>
                        </RecordingRight>
                    </RecordingContainer>
                    <InputParent className={recording ? "disabled" : "active"}>
                        <Icons>
                            <FileContainer className="file">
                                <span></span>
                                <File
                                    onChange={(e) => {
                                        setImg(e.target.files[0]);
                                        setImgPreview(true);
                                        previewImage(e);
                                    }}
                                    type="file"
                                    id="img"
                                    accept="image/*"
                                />
                                <img
                                    className="file"
                                    src={
                                        require("../../../assets/images/chat/gallery.svg")
                                            .default
                                    }
                                    alt="Icon"
                                />
                            </FileContainer>

                            <FileContainer className="file">
                                <span></span>
                                <File
                                    type="file"
                                    onChange={(e) => {
                                        setFile(e.target.files[0]);
                                        setFilePreview(true);
                                    }}
                                />
                                <img
                                    className="file"
                                    src={
                                        require("../../../assets/images/chat/attach.svg")
                                            .default
                                    }
                                    alt="Icon"
                                />
                            </FileContainer>
                            <Icon
                                onClick={() => {
                                    handlePicker();
                                    setIsCode(false);
                                }}
                            >
                                {isPicker ? (
                                    <img
                                        src={
                                            require("../../../assets/images/chat/emoji-active.svg")
                                                .default
                                        }
                                        alt="Icon"
                                    />
                                ) : (
                                    <img
                                        src={
                                            require("../../../assets/images/chat/emoji.svg")
                                                .default
                                        }
                                        alt="Icon"
                                    />
                                )}
                            </Icon>
                            <Icon
                                onClick={() => {
                                    setIsCode(!isCode);
                                    setPicker(false);
                                }}
                            >
                                {isCode ? (
                                    <img
                                        src={
                                            require("../../../assets/images/chat/code-active.svg")
                                                .default
                                        }
                                        alt="Icon"
                                    />
                                ) : (
                                    <img
                                        src={
                                            require("../../../assets/images/chat/code.svg")
                                                .default
                                        }
                                        alt="Icon"
                                    />
                                )}
                            </Icon>
                        </Icons>
                        <InputWrapper
                            style={{
                                minHeight: MIN_TEXTAREA_HEIGHT,
                                resize: "none",
                                maxHeight: "120px",
                            }}
                        >
                            <PlaceHolder
                                style={{
                                    visibility: insideText?.length
                                        ? "hidden"
                                        : "visible",
                                }}
                            >
                                {isCode
                                    ? "Type your code..."
                                    : "Type a message..."}
                            </PlaceHolder>
                            <Input
                                onChange={(e) => {
                                    setInsideText(e.target.value);
                                }}
                                onFocus={listenTyping}
                                onBlur={handleTypingEnd}
                                id="reso"
                                title="Type a message"
                                role="textbox"
                                data-tab="10"
                                dir="ltr"
                                spellcheck
                                rows="1"
                                value={insideText}
                                style={{
                                    minHeight: MIN_TEXTAREA_HEIGHT,
                                    resize: "none",
                                    maxHeight: "110px",
                                }}
                                ref={textareaRef}
                                onKeyPress={handleKeyPress}
                                onKeyUp={upHandler}
                                onKeyDown={downHandler}
                            ></Input>
                        </InputWrapper>
                        {!!/\S/.test(insideText) && insideText ? (
                            <SendIcon
                                title="Send"
                                className={insideText ? "show" : ""}
                                onClick={sendMessage}
                            >
                                <img
                                    src={
                                        require("../../../assets/images/chat/send.svg")
                                            .default
                                    }
                                    alt="Send"
                                />
                            </SendIcon>
                        ) : (
                            <Icons>
                                <Icon onClick={voiceRecordStart}>
                                    <img
                                        src={
                                            require("../../../assets/images/chat/mic.svg")
                                                .default
                                        }
                                        alt="Icon"
                                    />
                                </Icon>
                            </Icons>
                        )}
                    </InputParent>
                </Back>
            )}
        </Container>
    );
}
const Container = styled.div`
    /* height: 75px; */
    overflow: hidden;
    margin-top: 9px;
    background: #161619;
    border-radius: 6px;

    p {
        color: #fff;
        opacity: 0.6;
        font-size: 0.8rem;
    }
    aside.EmojiPickerReact.epr-main {
        width: 100% !important;
        height: 365px !important;
        border-color: transparent;
    }
    .EmojiPickerReact {
        background-color: #1b1c1f !important;
    }
    .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
        font-size: 15px;
        font-family: "gordita_regular";
        background: #1b1c1f;
    }
    .EmojiPickerReact .epr-search-container input.epr-search {
        background-color: #161619;
        height: 46px;
    }
    .EmojiPickerReact .epr-preview {
        display: none;
    }
    .EmojiPickerReact button.epr-btn:hover {
        /* background: #161619; */
    }
    .EmojiPickerReact .epr-search-container input.epr-search {
        font-family: "gordita_regular";
        border: none;
    }
    .EmojiPickerReact .epr-header .epr-header-overlay {
        padding-bottom: 0;
    }
`;
const PickerContainer = styled.div`
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: 0.3s;
    &.active {
        visibility: unset;
        max-height: 365px;
        opacity: 1;
    }
`;
const RecordingContainer = styled.div`
    opacity: 0;
    visibility: hidden;
    display: flex;
    grid-gap: 10px;
    align-items: center;
    font-size: 16px;
    justify-content: space-between;
    width: 100%;
    min-height: 81px;
    margin: -15px 0;
    transition: all 0.3s ease;
    position: absolute;
    left: 600px;
    right: 0;
    padding: 0 21px;

    img.delete {
        width: 28px;
        display: block;
        cursor: pointer;
    }
    &.active {
        left: 0;
        opacity: 1;
        visibility: visible;
    }
`;
const RecordingRight = styled.div`
    display: flex;
    align-items: center;
    min-width: 59px;
    justify-content: flex-end;
`;
const PlaceHolder = styled.div`
    flex: 1;
    /* max-width: 703px; */
    color: #d7d7d7;
    font-size: 0.75rem;
    pointer-events: none;
    position: absolute;
    user-select: none;
    transition: opacity 0.08s linear;
`;
const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    /* max-width: 703px; */
    user-select: text;
    background: #161619;
    padding: 13px 17px;
    border-radius: 3px;
    margin: 0px 21px;

    @media all and (max-width: 1280px) {
        padding: 7px 14px;
    }

    @media all and (max-width: 640px) {
        margin: 0 10px;
        padding: 10px 14px;
    }
`;
const InputParent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: text;
    &.disabled {
        opacity: 0;
        visibility: hidden;
    }
`;
const SendIcon = styled.div`
    min-width: 22px;
    cursor: pointer;
    transition: 0.2s ease;
    opacity: 0;
    visibility: hidden;
    &.show {
        opacity: 1;
        visibility: visible;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const DeleteIcon = styled.div`
    cursor: pointer;
    min-width: 17px;
    display: flex;
    align-items: center;
    margin-right: 14px;
`;
const Back = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* padding: 13px 19px; */
    padding: ${(props) => (props.joinedLoader ? "0" : "13px 19px")};

    background: #1b1c1f;
    grid-gap: 14px;
    overflow: hidden;

    & p.join {
        color: #fe673a;
    }
`;
const Input = styled.textarea`
    background: #161619;
    font-size: 0.74rem;
    word-wrap: break-word;
    white-space: pre-wrap;
    outline: none;
    min-height: 20px;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    color: #d7d7d7;
    width: 100%;
    resize: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
const Icons = styled.div`
    display: flex;
    /* width: 100px; */
    align-items: center;
    justify-content: space-between;
    grid-gap: 15px;
    max-height: 51px;

    &.input {
        width: auto;
        margin-right: 10px;

        & div {
            width: 22px;
            height: 22px;
        }
    }

    @media all and (max-width: 640px) {
        grid-gap: 7px;
    }
`;

const Icon = styled.div`
    width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
    }
    img {
        width: 100%;
        display: block;
    }

    &.record {
        width: 30px;
    }

    @media all and (max-width: 640px) {
        width: 15px;
    }
`;
const FileContainer = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        display: flex;
        color: #828282;
        padding-left: 25px;
        font-size: 14px;
        padding-top: 10px;
        align-items: center;
        position: absolute;
        /* background: #1b1c1f; */
        background-color: transparent;
        width: 20px;
        height: 20px;
        right: 1px;
        z-index: 3;
        bottom: 0;
    }
    img {
        width: 100%;
        display: block;
        position: absolute;
        top: 0px;
        z-index: 3;
    }

    @media all and (max-width: 640px) {
        width: 15px;
        height: 15px;
    }
`;
const File = styled.input`
    border: none;
    background-color: transparent;
    position: relative;
    font-size: 14px;
    width: 20px;
    opacity: 0;
    z-index: 4;

    &::file-selector-button {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 99;
    }
    /* width: 25px;
	cursor: pointer; */
    img {
        width: 100%;
        display: block;
        position: absolute;
        top: 0px;
        z-index: 3;
    }
`;
const Duration = styled.span`
    margin-right: 15px;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
`;
const Button = styled.div`
    background: ${(props) => (props.join ? "#161619" : "#fe673a")};
    border: 1px solid #fe673a;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 0.7rem;
    cursor: pointer;
    font-weight: 600;
    min-height: 39px;
    max-height: 39px;
`;
