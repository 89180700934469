import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const SrhStatus = lazy(() =>
    import("../../../screens/operations-head/SrhStatus")
);

function ManagerRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard/" />} />
            <Route
                path="/dashboard/"
                element={<Navigate to="/manager/status/" />}
            />
            <Route path="/status/" element={<SrhStatus />} />
        </Routes>
    );
}

export default ManagerRouter;
