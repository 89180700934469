import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import $ from "jquery";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useNavigate } from "react-router-dom";

function DeleteModal({ isModal, setModal, setIsbtnLoading, isbtnLoading }) {
    const navigate = useNavigate();

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const { meet_id } = useParams();

    //to avoid outside scroll
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    //for outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const handleBack = () => {
        navigate("/meet/list/");
    };

    const handleDeleteSubmit = () => {
        setIsbtnLoading(true);
        learnConfig
            .post(
                `events/delete/event/${meet_id}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { status_code, data, message } = response.data;
                if (status_code === 6000) {
                    handleBack();
                    setIsbtnLoading(false);
                } else {
                    setIsbtnLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsbtnLoading(false);
            });
    };

    return (
        <BackContainer>
            {isModal ? (
                <Overlay
                    onClick={() => setModal(false)}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Close
                            onClick={() => {
                                setModal(false);
                            }}
                        >
                            <CloseIcon
                                src={
                                    require("../../../../src/assets/images/close-icon.svg")
                                        .default
                                }
                                alt="Close Icon"
                            />
                        </Close>
                        <HeadingContainer>
                            <Text>Do you want to delete this session ?</Text>
                        </HeadingContainer>
                        <ButtonContainer>
                            <CancelButton
                                onClick={() => {
                                    setModal(false);
                                }}
                            >
                                No
                            </CancelButton>
                            <CreateButton onClick={handleDeleteSubmit}>
                                {isbtnLoading ? <ButtonLoader /> : "  Yes"}
                            </CreateButton>
                        </ButtonContainer>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default DeleteModal;
const BackContainer = styled.div``;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
// const Overlay = styled.div`
//     position: absolute;
//     left: 0;
//     top: 0px;
//     width: 100%;
//     min-height: 100vh;
//     max-height: 100vh;
// `;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 460px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 1280px) {
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 35px;
    width: 100%;
    height: 100%;
    background-color: #1b1c1f;
    border: 1px solid #26272a;
    position: relative;
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Text = styled.p`
    font-size: 16px;
    color: #fff;
    margin-bottom: 25px;
    font-family: "gordita_medium";
`;
const Close = styled.div`
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
`;
const CloseIcon = styled.img`
    display: block;
    margin-bottom: 15px;
    width: 40px;
`;
const Button = styled.div`
    font-size: 14px;
    font-family: "gordita_medium";
    background-color: #fe673a;
    height: 35px;
    width: 100px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-family: "gordita_medium";
    &:nth-child(1) {
        margin-right: 20px;
    }

    &.reject {
        background: #161619;
        border: 1px solid #fe673a;
        color: #fe673a;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
`;
const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;
