import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const TechGradStudentCard = ({ student, setExamModal }) => {
    return (
        <StudentCards>
            <Item type="slno">01</Item>
            <Item>
                <Name>
                    <p>{student.student_name.toLowerCase()}</p>
                    <span>
                        {student.student_phone_code} {student.student_phone}
                    </span>
                </Name>
            </Item>
            <Item type="email">{student.email}</Item>
            <Item type="dist">{student.gender}</Item>
            <Item type="dist">
                <span>{student.category}</span>
                <br />
                <span>
                    {student.category === "dropout"
                        ? student.student_stream
                        : student.category === "employee"
                        ? student.student_stream
                        : student.category === "graduate"
                        ? student.student_semester
                        : "--"}
                </span>
            </Item>
            <Item type="dist">{student.college}</Item>
            <Item type="dist">{student.interview_date}</Item>
            <Item type="slno">
                {student.resume ? (
                    <img
                        src={
                            require("../../../assets/images/download.svg")
                                .default
                        }
                        alt="Download"
                    />
                ) : (
                    "--"
                )}
            </Item>

            <Item type="dist">
                <div className="flex">
                    {student.interview_progress.is_started &&
                    student.interview_progress.is_compleated ? (
                        (student.interview_status === "eligible" && (
                            <span className="status">
                                Eligible
                                <br />
                                {student.interview_score}
                            </span>
                        )) ||
                        (student.interview_status === "not eligible" && (
                            <span className="color">Not eligible</span>
                        ))
                    ) : (
                        <StatusDiv onClick={(e) => setExamModal(true)}>
                            {student.interview_progress.is_started &&
                            !student.interview_progress.is_compleated
                                ? "Continue interview"
                                : "start interview"}
                        </StatusDiv>
                    )}

                    {student.interview_progress.is_started &&
                        student.interview_progress.is_compleated &&
                        (student.interview_status === "eligible" ||
                        student.interview_status === "not eligible" ? (
                            <img
                                src={
                                    require("../../../assets/images/tooltip.svg")
                                        .default
                                }
                            />
                        ) : null)}
                </div>
            </Item>
            <Item type="slno">
                <img
                    src={
                        require("../../../assets/images/examiner/calender.svg")
                            .default
                    }
                    alt="Icon"
                />
            </Item>
        </StudentCards>
    );
};

export default TechGradStudentCard;
const StudentCards = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 20px;
    color: #fff;
    // cursor: pointer;
    &.active {
        background: #121214;
    }
    :last-child {
        border-bottom: 0px solid #000;
    }
`;
const ProfileWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const ProfileImage = styled.div`
    margin-right: 13px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    font-family: "gordita_bold";
    border-radius: 50%;
    font-size: 18px;
    min-width: 40px;
`;
const NameSpan = styled.span`
    width: 40px;
    height: 40px;

    img {
        width: 100%;
        display: block;
    }
`;
const Name = styled.span`
    text-transform: capitalize;
    width: 70%;
    p {
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    p::first-letter {
        text-transform: capitalize;
    }
    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    p {
        text-decoration: underline;
    }
`;

const ImageContainer = styled.div`
    width: 25px;
    margin-left: 10px;

    cursor: pointer;
    &img {
        width: 100%;
        display: block;
    }
`;
const Bottom = styled.div`
    font-size: 14px;
`;
const ItemDiv = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: capitalize;
    width: 10%;
    color: ${({ type }) =>
        type === "not_eligible"
            ? "#ef3d3d"
            : type === "eligible"
            ? "#44c082"
            : "#fff"};
    &.eligible {
        color: #44c082;
    }
    &.not_eligible {
        color: #ef3d3d;
    }
`;
const Category = styled.span`
    margin-bottom: 2px;
`;
const Item = styled.li`
    list-style: none;
    font-size: 14px;
    text-align: ${({ type }) => (type === "count" ? "center" : "left")};
    &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 2px;
        :last-child {
            margin-bottom: 0;
        }
        &.flex {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "dist"
            ? "10%"
            : type === "email"
            ? "10%"
            : "15%"};

    color: ${({ type }) =>
        type === "not_eligible"
            ? "#ef3d3d"
            : type === "eligible"
            ? "#44c082"
            : "#fff"};
    text-transform: ${({ type }) => (type == "email" ? "none" : "capitalize")};

    span {
        text-transform: Capitalize;
        &.color {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #fe673a;
        }
        &.status {
            text-transform: capitalize;
            color: #44c082;
        }
        &.marks {
            color: #44c082;
        }
    }
    p {
        color: #fff;
        &.review {
            font-size: 14px;
        }
    }
`;
const StatusDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    background: #fe673a;
    border: 1px solid #fe673a;
    height: 40px;
    width: 150px;
    font-family: gordita_medium;
    &.pending {
        border: 1px solid #fe673a;
        height: 40px;
        width: 150px;
        background: #1b1c1f;
    }
    &.completed {
        border: 1px solid transparent;
        background: #1b1c20;
        color: #44c082;
    }
`;
