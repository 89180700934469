import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import SteypAdminHelmet from "../../helpers/SteypAdminHelmet";
import AepStudentsCard from "./AepStudentsCard";

function AepStudents() {
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(false);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const [studentDetails, setStudentDetails] = useState([]);
    const [pagination, setPagination] = useState({});
    useEffect(() => {
        accountsConfig
            .get("api/v1/users/aep-bonus/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                console.log(response);
                const { data, StatusCode, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setPagination(pagination_data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <MainSection id="main">
            <SteypAdminHelmet title="Aep Students" />
            <FilterContainer>
                <TextArea>Students</TextArea>
            </FilterContainer>
            <Divider>
                <MainContainer>
                    <Cover>
                        <Category>
                            <Item type="slno">SLNo</Item>
                            <Item type="name">Name</Item>
                            <Item type="phone">Phone</Item>
                            <Item type="category">Category</Item>
                            <Item type="payment">Payment On</Item>
                            <Item type="amount">Amount</Item>
                        </Category>
                        {isLoading ? (
                            <SectionLoader />
                        ) : (
                            <>
                                {isPageLoading ? (
                                    <SectionLoader />
                                ) : (
                                    <>
                                        {!isPageLoading &&
                                        studentDetails.length > 0 ? (
                                            studentDetails.map(
                                                (item, index) => (
                                                    <AepStudentsCard
                                                        key={item.id}
                                                        student={item}
                                                        index={index}
                                                        pagination={pagination}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <ImageBox>
                                                <CardImage
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                                                    alt="Image"
                                                />
                                                <p>No Data Found</p>
                                            </ImageBox>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Cover>
                </MainContainer>
            </Divider>
        </MainSection>
    );
}

export default AepStudents;
const MainSection = styled.div``;
const TextArea = styled.div`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 460px) {
        margin-bottom: 15px;
    }
`;
const Item = styled.li`
    font-size: 14px;
    /* text-align: ${({ type }) => (type === "count" ? "center" : "left")}; */
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "name"
            ? "20%"
            : type === "phone"
            ? "19%"
            : type === "category"
            ? "20%"
            : type === "payment"
            ? "17%"
            : type === "amount"
            ? "15%"
            : "15%"};
    list-style: none;
    &:last-child {
        text-align: center;
    }

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 460px) {
        align-items: flex-end;
    }
`;

const Divider = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 140px);
`;

const MainContainer = styled.div`
    border: 1px solid #26272a;
    border-radius: 5px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border-radius: 5px;
    border: 1px solid #26272a;
`;
const Cover = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1b1c20;
    color: #fff;
    min-width: 1300px;

    .card-container {
        :last-child {
        }
    }
`;
const Category = styled.div`
    border-bottom: 1px solid #26272a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
`;

const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    margin-top: 170px;
    height: calc(100% - (-50px));
    margin-bottom: 170px;
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;
const CardImage = styled.img`
    width: 100%;
    display: block;
`;
