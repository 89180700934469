import React from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/animations/loader.json";

export default function ButtonLoader({ joinedLoader, width, height }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <Lottie
            options={defaultOptions}
            height={joinedLoader ? "100%" : height ? height : 35}
            width={width ? width : 35}
        />
    );
}
