import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import dp from "../../../assets/images/chat/user.svg";
import Moment from "react-moment";
import Avatar from "react-avatar";
import {
    collection,
    query,
    onSnapshot,
    orderBy,
    doc,
    getDoc,
} from "firebase/firestore";
import { db } from "../../../fiebase";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import moment from "moment/moment";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

export default function StudentDetails({
    joinConversation,
    leaveConversation,
    myDetails,
    isExist,
    joinedLoader,
    setExist,
    myId,
}) {
    const [images, setImages] = useState([]);
    const [names, setNames] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [userProfileData, setUserProfileData] = useState({});
    const [joinedMembers, setJoinedMembers] = useState([]);

    const snapshot = useRef(null);

    const {
        state: {
            user_data: { access_token },
            chat_user,
        },
        dispatch,
    } = useContext(Context);

    useEffect(() => {
        let images = joinedMembers.reduce(
            (prev, next) => prev.concat(next.image),
            []
        );

        setImages(images);

        let names = joinedMembers.reduce(
            (prev, next) => prev.concat(next.name),
            []
        );
        setNames(names);
    }, [joinedMembers]);

    useEffect(() => {
        setUserProfileData({});
        const unsub = onSnapshot(doc(db, "users", chat_user.uid), (doc) => {
            setUserDetails(doc.data());
        });

        if (chat_user.pk)
            accountsConfig
                .get(
                    `/general/get/short-profile-details/?student_pk=${chat_user.pk}
            `,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    setUserProfileData(response.data.user_profile_data);
                    if (StatusCode === 6000) {
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        return () => unsub();
    }, [chat_user.uid]);

    // =====================================================
    let membersUnsubscribe;
    const fetchRoomMembers = async () => {
        const roomId =
            chat_user.uid + chat_user.uid.split("").reverse().join("");
        const getMemberStatus = async () => {
            const docRef = doc(db, "chatRooms", roomId, "members", myId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setExist(true);
            } else {
                setExist(false);
            }
        };

        getMemberStatus();

        const membersRef = query(
            collection(db, "chatRooms", roomId, "members")
        );

        const q = query(membersRef, orderBy("createdAt", "asc"));

        membersUnsubscribe = onSnapshot(q, (querySnapshot) => {
            let arr = [];
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });
            setJoinedMembers(arr);
        });
    };

    useEffect(async () => {
        if (chat_user.uid) {
            fetchRoomMembers();
        }

        return () => {
            membersUnsubscribe();
        };
    }, [chat_user.uid, joinedLoader]);

    return (
        <MainContainer>
            <Topper className="topper">
                <Top>
                    <DpContainer>
                        <small
                            className={
                                userDetails?.isOnline ? "online" : "offline"
                            }
                        ></small>

                        <Avatar
                            textSizeRatio={3}
                            name={userDetails?.name}
                            size="65"
                            round
                        />
                    </DpContainer>

                    <Name>{userDetails?.name}</Name>
                    {userDetails?.isOnline ? (
                        <ActiveStatus>Online</ActiveStatus>
                    ) : (
                        userDetails?.lastActiveTime && (
                            <ActiveStatus>
                                last seen{" "}
                                <Moment fromNow>
                                    {userDetails?.lastActiveTime?.toDate()}
                                </Moment>{" "}
                            </ActiveStatus>
                        )
                    )}
                    <Id>#{userProfileData?.user_pk}</Id>
                </Top>
                <Middle>
                    <DetailContainer>
                        <Icons>
                            <Icon
                                src={
                                    require("../../../assets/images/chat/phone.svg")
                                        .default
                                }
                            />
                        </Icons>
                        <Data>
                            <p>Phone Number</p>
                            <Detail>
                                {userProfileData?.phone_code}{" "}
                                {userProfileData?.phone
                                    ? userProfileData.phone
                                    : "----------"}
                            </Detail>
                        </Data>
                    </DetailContainer>
                    <DetailContainer>
                        <Icons>
                            <Icon
                                src={
                                    require("../../../assets/images/chat/mail.svg")
                                        .default
                                }
                            />
                        </Icons>
                        <Data>
                            <p>Email ID</p>
                            <Detail>
                                {userProfileData?.email
                                    ? userProfileData.email
                                    : "----------"}
                            </Detail>
                        </Data>
                    </DetailContainer>
                    <DetailContainer>
                        <Icons>
                            <Icon
                                src={
                                    require("../../../assets/images/chat/date.svg")
                                        .default
                                }
                            />
                        </Icons>
                        <Data>
                            <p>Joined Date</p>
                            <Detail>
                                {userProfileData?.joined_date
                                    ? moment(
                                          userProfileData.joined_date
                                      ).format("DD-MM-YYYY")
                                    : "----------"}
                            </Detail>
                        </Data>
                    </DetailContainer>
                    <DetailContainer>
                        <Icons>
                            <Icon
                                src={
                                    require("../../../assets/images/chat/campus.svg")
                                        .default
                                }
                            />
                        </Icons>
                        <Data>
                            <p>Program</p>
                            <Detail>
                                {userProfileData?.program
                                    ? userProfileData.program
                                    : "----------"}
                            </Detail>
                        </Data>
                    </DetailContainer>
                </Middle>
                <Bottom>
                    <h4>Joined Support Members</h4>
                    <Members>
                        {images.map((item, index) => (
                            <Member key={index}>
                                <MemberDpContainer>
                                    <MemberDp src={item || dp} alt="dp" />
                                </MemberDpContainer>
                            </Member>
                        ))}
                    </Members>
                    <Members className="names">
                        {names.map((member, index) => (
                            <SupportName key={index}>
                                {member === myDetails.name ? "You" : member}
                                {index !== names.length - 1 && ","}{" "}
                            </SupportName>
                        ))}
                    </Members>
                </Bottom>
            </Topper>

            {!isExist ? (
                joinedLoader ? (
                    <Button join={chat_user}>
                        <ButtonLoader joinedLoader={true} />
                    </Button>
                ) : (
                    <Button join={chat_user} onClick={joinConversation}>
                        Join Conversation
                    </Button>
                )
            ) : joinedLoader ? (
                <Button join={chat_user}>
                    <ButtonLoader joinedLoader={true} />
                </Button>
            ) : (
                <Button join={chat_user} onClick={leaveConversation}>
                    Leave Conversation
                </Button>
            )}
        </MainContainer>
    );
}
const MainContainer = styled.div`
    background-color: #1b1c1f;
    width: 100%;
    /* min-width: 350px; */
    padding: 32px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
        display: none;
        @media all and (max-width: 1450px) {
            display: block !important;
            position: relative;
            width: 20px;
            top: -23px;
            left: -22px;
            cursor: pointer;
        }
        img {
            width: 100%;
            display: block;
        }
    }
`;
const Topper = styled.div`
    /* max-height: 100vh;
	overflow-y: scroll; */
`;

const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 22px;
    border-bottom: 3px solid #161619;
`;
const DpContainer = styled.div`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
        display: block;
        font-weight: bold;
    }
    small {
        width: 16px;
        height: 16px;
        /* background: green; */
        position: absolute;
        bottom: 0px;
        right: 4px;
        border-radius: 50%;
        &.online {
            background: #00c569;
        }
        &.offline {
            background: #ef3e3e;
        }
    }
`;
const ActiveStatus = styled.p`
    font-size: 0.7rem;
    color: #868686;
`;
const Dp = styled.img`
    display: block;
    width: 100%;
    border-radius: 50%;
`;
const Name = styled.div`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 0.9rem;
    text-align: center;
    margin-top: 13px;
`;
const Id = styled.div`
    font-size: 0.7rem;
    color: #1eac60;
    margin-top: 8px;
    padding: 5px 24px;
    background: #161619;
    border: 1px solid #4c4c4e;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Middle = styled.div`
    padding: 22px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 7px;
    border-bottom: 3px solid #161619;
`;
const DetailContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
`;
const Icons = styled.div`
    width: 8%;
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;
const Data = styled.div`
    p {
        font-size: 0.7rem;
    }
`;
const Detail = styled.div`
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
`;
const Bottom = styled.div`
    padding-top: 18px;
    margin-bottom: 20px;
    h4 {
        color: #ffffff;
        opacity: 0.6;
        font-size: 0.7rem;
        margin-bottom: 12px;
    }
`;
const Members = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;

    &.names {
        padding-left: 10px;
    }
`;
const Member = styled.div`
    width: 8%;
`;
const MemberDpContainer = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    /* padding: 2px; */
    background: #fff;
    margin-bottom: 6px;
`;
const MemberDp = styled.img`
    width: 100%;
    display: block;
`;

const SupportName = styled.p`
    font-size: 0.68rem;
    line-height: 0.7rem;
`;
const Button = styled.div`
    background: ${(props) => (props.join ? "#161619" : "#fe673a")};
    border: 1px solid #fe673a;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 0.7rem;
    cursor: pointer;
    font-weight: 600;
    min-height: 39px;
    max-height: 39px;
`;
