import React from 'react'
import styled from 'styled-components'
import {Link, NavLink} from 'react-router-dom'

function QuestionCategoryUP({subject,category}) {

  return (
    <>
        <Container>
            <TotalContainer  className="active">
                <Cover>
                    <Item to={`/school-scientist/questions/?subject=mathematics&category=${category}`} className={subject === "mathematics" && "active"} >Mathematics</Item>
                    <Item to={`/school-scientist/questions/?subject=technology&category=${category}`} className={subject === "technology" && "active"}>Technology & Engineering</Item>
                    {
                        category === "hss" ? (
                            <>
                                <Item to={`/school-scientist/questions/?subject=physics&category=${category}`} className={subject === "physics" && "active"} >Physics</Item>
                                <Item to={`/school-scientist/questions/?subject=chemistry&category=${category}`} className={subject === "chemistry" && "active"}>Chemistry</Item>
                            </>) :(
                            <Item to={`/school-scientist/questions/?subject=science&category=${category}`} className={subject === "science" && "active"} >Science</Item>
                        )
                    }
                </Cover>
            </TotalContainer>
        </Container>
    </>
  )
}
const Container = styled.div`
    
`;
const TotalContainer = styled.div`    
    .active{
        border-bottom: 3px solid #f7684c;
        border-radius: 2px;
    }
    @media all and (max-width: 480px) {
		.active{
            border: none;
            color: #f7684c;
        }
	}
`;
const Cover = styled.ul`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;
const Item = styled(Link)`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 15px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
    &:last-child{
        margin-right: 0;
    }
    @media all and (max-width: 1280px) {
		font-size: 14px;
	}
    @media all and (max-width: 640px) {
		font-size: 13px;
	}
    @media all and (max-width: 480px) {
		width: 100%;
	}
`;

export default QuestionCategoryUP