/** @format */

import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { activityConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function TagModal({ isModal, setModal }) {
    const [selectedId, setSelectedId] = useState("");
    const [description, setDescription] = useState("");
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [editedContent, setEditedContent] = useState(
        "Lorem ipsum fghujiawdfsuyobinmbbn sadibapwdihn  "
    );

    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleModal();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const handleModal = () => {
        setSelectedId("");
        setModal(false); // Corrected here
        setDescription("");
    };

    return (
        <MainContainer>
            {isModal ? (
                <Overlay
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Head>
                                <MainTitle>Mention a Reason</MainTitle>
                                <Reason></Reason>
                                <Close>
                                    <CloseIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Close Icon"
                                        onClick={() => {
                                            handleModal();
                                        }}
                                    />
                                </Close>
                            </Head>
                        </Top>
                        <Discription>
                            <p>write why you decide to remove the question</p>
                        </Discription>
                        <Content>
                            <p>Reasons*</p>

                            <Message>
                                <CommentSection>
                                    <div
                                        className="message-text"
                                        contentEditable="true"
                                        // onClick={handleDivClick}
                                    >
                                        {editedContent}
                                    </div>
                                </CommentSection>
                            </Message>
                        </Content>
                       
                        <Button>
                            <Cancel onClick={() => handleModal()}>
                                Cancel
                            </Cancel>
                            {isSubmitLoading ? (
                                <Next>
                                    <ButtonLoader />
                                </Next>
                            ) : (
                                <Next
                                    style={{
                                        cursor:
                                            selectedId && description.length
                                                ? "pointer"
                                                : "not-allowed",
                                    }}
                                    // onClick={() => updateCallStatus()}
                                >
                                    Submit
                                </Next>
                            )}
                        </Button>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default TagModal;

const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    /* box-shadow: 0px 3px 56px #00000048; */
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    height: 100%;
`;
const Top = styled.div`
    margin-bottom: 20px;
`;
const Discription = styled.div`
    border-bottom: 1px solid #26272a;

    p {
        font-family: "gordita_regular";
        margin-bottom: 10px;
    }
`;
const CommentSection = styled.div`
    display: flex;
    position: relative;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #828282;
    justify-content: space-between;
    text-align: center;
`;
const Content = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 13px;
        margin-top: 20px;
    }
`;

const Message = styled.div`
    width: 100%;
    display: flex;
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 24px;
    margin-top: 4px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid #3d3d3d;
    p {
        font-family: "gordita_regular";
    }
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
`;
const Reason = styled.div``;
const Close = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const Button = styled.div`
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
`;
const Cancel = styled.div`
    border-radius: 5px;
    width: 210px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-right: 25px;
    background: #fff;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    font-family: "gordita_regular";
`;
const Next = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px;
    width: 210px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    font-family: gordita_regular;
`;
