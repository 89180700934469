import React from "react";
import styled from "styled-components";

function RmCard({ student, selectedRM, setSelectedRM, fetchRMsStatus }) {
	return (
		<Container
		// className={selectedRM === student.executive_pk ? "active" : ""}
		>
			<TopSection>
				<Profile>{/* <span>{student.name.charAt(0)}</span> */}</Profile>
				{/* <Name>{student.name.toLowerCase()}</Name> */}
			</TopSection>
			<MiddleSection>
				<TotalCount>
					{/* <span>{student.followup_data.total_followups}</span> */}
					<Text>Total</Text>
				</TotalCount>
				<Completed>
					{/* <span>{student.followup_data.completed_followups}</span> */}
					<Text>Completed</Text>
				</Completed>
				<Pending>
					{/* <span>{student.followup_data.pending_followups}</span> */}
					<Text>Pending</Text>
				</Pending>
			</MiddleSection>
			<BottomSection>
				<Details
				// onClick={() =>
				// 	setSelectedRM(
				// 		selectedRM === student.executive_pk
				// 			? ""
				// 			: student.executive_pk
				// 	)
				// }
				// className={
				// 	selectedRM === student.executive_pk ? "active" : ""
				// }
				>
					<span>Details</span>
				</Details>
			</BottomSection>
		</Container>
	);
}

export default RmCard;

const Container = styled.div`
	background: #1b1c1f;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #2c2c2c;
	&.active {
		border: 1px solid #fe673a;
	}
`;
const TopSection = styled.div`
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 14px;
	margin-bottom: 15px;
`;
const Profile = styled.div`
	background: #161619;
	color: #fe673a;
	font-size: 18px;
	font-family: "gordita_medium";
	margin-right: 15px;
	width: 40px;
	height: 40px;
	border: 1px solid #fe673a;
	border-radius: 50%;
	position: relative;
	span {
		top: 52%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
	}
`;
const Name = styled.div`
	font-size: 18px;
	text-transform: capitalize;
`;
const MiddleSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border-bottom:1px solid #161618;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
`;
const TotalCount = styled.div`
	text-align: center;
	& span {
		display: inline-block;
		margin-bottom: 10px;
		font-size: 18px;
	}
`;
const Completed = styled.div`
	text-align: center;
	& span {
		display: inline-block;
		color: #44c082;
		margin-bottom: 10px;
	}
`;
const Pending = styled.div`
	text-align: center;
	& span {
		display: inline-block;
		color: #ef3d3d;
		margin-bottom: 10px;
	}
`;
const BottomSection = styled.div``;
const Popup = styled.div``;
const Details = styled.div`
	border: 1px solid #fe673a;
	width: 120px;
	height: 40px;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	cursor: pointer;
	position: relative;
	span {
		font-size: 16px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
	}
	&.active {
		background: #fe673a;
	}
`;
const Text = styled.div`
	font-size: 14px;
`;
