import React, { useEffect, useState, useRef } from "react";

//Packages
import styled from "styled-components";

function AgentRegistrationModal({ formsModal, setFormsModal }) {
    const [regDetailName, setRegDetailName] = useState("");
    const [idName, setIdName] = useState("");
    const [panName, setPanName] = useState("");
    const [idBackName, setidBackName] = useState("");
    const handleclick = () => {
        setFormsModal(false);
    };
    //modal Outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFormsModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <BackContainer>
            {/* <Overlay onClick={handleModal}></Overlay> */}
            {formsModal ? (
                <Overlay
                    onClick={() => setFormsModal(false)}
                    style={{ display: formsModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: formsModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Heading>Agent Registration Form</Heading>
                            <Closeicon onClick={handleclick}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Icon"
                                />
                            </Closeicon>
                        </Top>
                        <Bottom>
                            <BasicDetailsDiv>
                                <LeftDiv>
                                    <InputDiv>
                                        <InputTitles>Name</InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter your name"
                                        />
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>Address</InputTitles>
                                        <AddressInput
                                            type="text"
                                            placeholder="Enter your address"
                                        />
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>
                                            Registration details{" "}
                                            <span>(optional)</span>
                                        </InputTitles>
                                        <Attachmentfields>
                                            {regDetailName !== "" && (
                                                <FileName>
                                                    {regDetailName}
                                                </FileName>
                                            )}

                                            <FileInput
                                                type="file"
                                                title="Choose a file to upload"
                                                className="file-input"
                                                onChange={(e) =>
                                                    // fileSelectFunction(e)
                                                    setRegDetailName(
                                                        e.target.files[0].name
                                                    )
                                                }
                                            />
                                            <ImageDiv>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-08-2022/uploaded.svg"
                                                    alt="ClipIcon"
                                                />
                                            </ImageDiv>
                                        </Attachmentfields>
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>
                                            ID proof <span>(front side)</span>
                                        </InputTitles>
                                        <Attachmentfields>
                                            {idName !== "" && (
                                                <FileName>{idName}</FileName>
                                            )}
                                            <FileInput
                                                type="file"
                                                className="file-input"
                                                onChange={(e) =>
                                                    setIdName(
                                                        e.target.files[0].name
                                                    )
                                                }
                                            />
                                            <ImageDiv>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-08-2022/uploaded.svg"
                                                    alt="ClipIcon"
                                                />
                                            </ImageDiv>
                                        </Attachmentfields>
                                    </InputDiv>
                                </LeftDiv>
                                <RightDiv>
                                    <InputDiv>
                                        <InputTitles>Company name</InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter company name"
                                        />
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>
                                            Contact number
                                        </InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter your phone number"
                                        />
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>
                                            Alternate number
                                        </InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter alternate number"
                                        />
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>PAN card</InputTitles>
                                        <Attachmentfields>
                                            {panName !== "" && (
                                                <FileName>{panName}</FileName>
                                            )}
                                            <FileInput
                                                type="file"
                                                className="file-input"
                                                accept="image/png, image/jpeg"
                                                onChange={(e) =>
                                                    setPanName(
                                                        e.target.files[0].name
                                                    )
                                                }
                                            />
                                            <ImageDiv>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-08-2022/uploaded.svg"
                                                    alt="ClipIcon"
                                                />
                                            </ImageDiv>
                                        </Attachmentfields>
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>
                                            ID proof<span>(back side)</span>
                                        </InputTitles>
                                        <Attachmentfields>
                                            {idBackName !== "" && (
                                                <FileName>
                                                    {idBackName}
                                                </FileName>
                                            )}
                                            <FileInput
                                                type="file"
                                                className="file-input"
                                                onChange={(e) =>
                                                    setidBackName(
                                                        e.target.files[0].name
                                                    )
                                                }
                                            />
                                            <ImageDiv>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-08-2022/uploaded.svg"
                                                    alt="ClipIcon"
                                                />
                                            </ImageDiv>
                                        </Attachmentfields>
                                    </InputDiv>
                                </RightDiv>
                            </BasicDetailsDiv>
                            <Title>Bank Details</Title>
                            <BankDetails>
                                <LeftDiv>
                                    <InputDiv>
                                        <InputTitles>Bank name</InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter bank name"
                                        />
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>
                                            Account holder
                                        </InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter account holder name"
                                        />
                                    </InputDiv>
                                </LeftDiv>
                                <RightDiv>
                                    <InputDiv>
                                        <InputTitles>Branch name</InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter branch name"
                                        />
                                    </InputDiv>
                                    <InputDiv>
                                        <InputTitles>IFSC code</InputTitles>
                                        <DetailsInput
                                            type="text"
                                            placeholder="Enter IFSC code"
                                        />
                                    </InputDiv>
                                </RightDiv>
                            </BankDetails>
                            <AccountInputDiv>
                                <AccountNumber>Account number</AccountNumber>
                                <AccountNumberInput
                                    type="text"
                                    placeholder="Enter account number"
                                />
                            </AccountInputDiv>
                        </Bottom>
                        <Buttons>
                            <CancelButton onClick={handleclick}>
                                Cancel
                            </CancelButton>
                            <SubmitButton>Submit</SubmitButton>
                        </Buttons>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default AgentRegistrationModal;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 800px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: scroll;
    box-shadow: 0px 3px 56px #00000048;
    &::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    border-bottom: 2px solid #26272a;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
`;
const Heading = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
`;
const Closeicon = styled.div`
    width: 18px;
    margin-bottom: 10px;
    margin-left: auto;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const Bottom = styled.div`
    /* background-color: #fff; */
`;
const BasicDetailsDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    @media all and (max-width: 768px) {
        flex-direction: column-reverse;
        & :first-child {
            margin-top: 0;
        }
    }
`;
const LeftDiv = styled.div`
    width: 45%;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const InputDiv = styled.div`
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const AccountInputDiv = styled.div``;
const InputTitles = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #fff;
    margin-bottom: 5px;
    span {
        margin-left: 5px;
    }
`;
const DetailsInput = styled.input`
    background-color: #161619 !important;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    padding: 10px;
    color: #fff;
    @media all and (max-width: 480px) {
        padding: 10px;
    }
    ::placeholder {
        font-size: 12px;
    }
`;
const FileInput = styled.input`
    cursor: pointer;
    &.file-input {
        opacity: 0;
        width: 100%;
        z-index: 2;
        position: relative;
    }
`;
const AddressInput = styled.textarea`
    background-color: #161619;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    height: 117px;
    color: #fff;
    padding: 10px;
    resize: none;
    @media all and (max-width: 480px) {
        padding: 15px 0px 15px 10px;
    }
    ::placeholder {
        font-size: 12px;
    }
`;
const RightDiv = styled.div`
    width: 50%;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const RegistrationDetails = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media all and (max-width: 768px) {
        flex-direction: column-reverse;
        & :first-child {
            margin-top: 0;
        }
    }
    span {
        font-size: 12px;
    }
`;
const Attachmentfields = styled.div`
    position: relative;
    background-color: #161619;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    padding: 5px;
    color: #fff;
    @media all and (max-width: 480px) {
        padding: 5px;
    }
`;
const ImageDiv = styled.div`
    width: 20px;
    position: absolute;
    top: 20%;
    right: 3%;
    z-index: 1;
    img {
        width: 100%;
        display: block;
    }
`;
const Title = styled.p`
    margin-bottom: 10px;
    border-bottom: 1px solid #26272a;
    padding-bottom: 10px;
`;
const BankDetails = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @media all and (max-width: 768px) {
        flex-direction: column-reverse;
        & :first-child {
            margin-top: 0;
        }
    }
`;
const AccountNumber = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #fff;
    margin-bottom: 5px;
`;
const AccountNumberInput = styled.input`
    background-color: #161619 !important;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    padding: 10px;
    color: #fff;
    ::placeholder {
        font-size: 12px;
    }
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const Buttons = styled.div`
    display: flex;
    margin-left: auto;
    margin-top: 20px;
`;
const CancelButton = styled.button`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-left: auto;
    background-color: #161619;
    border: 1px solid #fe673a;
    margin-right: 20px;
`;
const SubmitButton = styled.button`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: not-allowed;
    font-family: "gordita_medium";
    margin-left: auto;
    background-color: #fe673a;
`;
const FileName = styled.p`
    position: absolute;
    font-size: 14px;
`;
