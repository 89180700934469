import React, { useState } from "react";
import styled from "styled-components";
import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// Poster Images
import TechSchoolingBg from "../../../assets/images/aep/posters/steyp-tech-schooling-poster.jpg";
import TechDegreeBg from "../../../assets/images/aep/posters/steyp-tech-degree-poster.jpg";
import TechGradBg from "../../../assets/images/aep/posters/steyp-tech-grad-poster.jpg";
import BusinessCardBg from "../../../assets/images/aep/posters/business-card.png";

function AepPoster({
    aepName,
    aepPhone,
    aepAddress,
    type,
    setShowPoster,
    localbody,
}) {
    const [posterGenerated, setPosterGenerated] = useState(false);
    const download = () => {
        const download = document.getElementById("download");
        let image = document
            .querySelector("canvas")
            .toDataURL("image/png")
            .replace("image/pdf", "image/octet-stream");
        download.setAttribute("href", image);
    };
    const generatePoster = () => {
        // console.log("genter Poster download");
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#poster"), {
            scale: 2,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
        });
        window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
        );
    };
    const generateCard = () => {
        // console.log("Business Card generated");
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#card"), {
            scale: 2,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
            // var imgWidth = 336;
            // var imgHeight = 192;

            // var img = canvas.toDataURL("image/jpeg", 1);
            // const doc = new jsPDF({
            //     orientation: "landscape",
            //     unit: "in",
            //     format: [3.5, 2],
            // });
            // doc.addImage(img, "JPEG", 0, 0, imgWidth, imgHeight);
            // doc.save("business-card.pdf");
        });
        window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
        );
    };
    // CSC Digital Seve janasahaya Kendram,
    // Near Village Office, Melparamba,
    // kalanad P O,  Kasaragod - 671 317

    return (
        <BackContainer>
            <Cover className={type == "business_card" && "business_card"}>
                <CoverBox>
                    {type == "business_card" ? (
                        <BusinessCardContainer>
                            <BusinessCard id="card">
                                <Close
                                    onClick={() => {
                                        setShowPoster(false);
                                    }}
                                    src={require("../../../assets/images/aep/close.png")}
                                    alt="Close"
                                />
                                <AepBusinessName>
                                    {aepName ? aepName : ""}
                                </AepBusinessName>
                                <AEP>AUTHORISED EDUCATION PARTNER</AEP>
                                <AepPanchayathName>
                                    {localbody ? localbody : ""}
                                </AepPanchayathName>
                                <AepBusinessPhone>
                                    {aepPhone
                                        ? `+91 ${
                                              aepPhone.slice(0, 4) +
                                              " " +
                                              aepPhone.slice(4, 7) +
                                              " " +
                                              aepPhone.slice(7, 10)
                                          }`
                                        : ""}
                                </AepBusinessPhone>
                                <AepLocation>
                                    {aepAddress ? aepAddress : ""}
                                </AepLocation>
                                <HelpLine>
                                    Steyp Helpline:{" "}
                                    <span>+91 8590 137 361</span>
                                </HelpLine>
                                <Background src={BusinessCardBg} alt="Card" />
                            </BusinessCard>
                        </BusinessCardContainer>
                    ) : (
                        <Poster id="poster">
                            <Close
                                onClick={() => {
                                    setShowPoster(false);
                                }}
                                src={require("../../../assets/images/aep/close.png")}
                                alt="Close"
                            />
                            <Background
                                src={
                                    type == "tech_schooling"
                                        ? TechSchoolingBg
                                        : type == "tech_degree"
                                        ? TechDegreeBg
                                        : type == "tech_grad"
                                        ? TechGradBg
                                        : ""
                                }
                            />
                            <Details>
                                <More>For more details: </More>
                                <AepName>{aepName ? aepName : ""}</AepName>
                                <AepPhoneNumber>
                                    {aepPhone ? `+91 ${aepPhone}` : ""}
                                </AepPhoneNumber>
                            </Details>
                        </Poster>
                    )}
                    <RenderPoster id="render"></RenderPoster>

                    {posterGenerated ? (
                        <DownloadDiv>
                            <a id="download" download="poster.png">
                                <Download onClick={download}>Download</Download>
                            </a>
                        </DownloadDiv>
                    ) : (
                        <Generate
                            onClick={() => {
                                type == "business_card"
                                    ? generateCard()
                                    : generatePoster();
                            }}
                        >
                            Generate
                        </Generate>
                    )}
                </CoverBox>
            </Cover>
        </BackContainer>
    );
}

export default AepPoster;
const RenderPoster = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    display: none;
    canvas {
        border: 1px solid #e8e8e8;
    }
`;
const DownloadDiv = styled.div`
    display: flex;
    justify-content: center;
    margin: 25px auto;
    @media all and (max-width: 640px) {
        margin: 20px auto;
    }
    @media all and (max-width: 480px) {
        margin: 15px auto;
    }
    @media all and (max-width: 360px) {
        margin: 10px auto;
    }
`;
const Download = styled.span`
    color: #ffffff;
    margin: 0px auto !important;
    background: #ff8f20 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    display: block !important;
    padding: 15px 60px !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    text-align: center;
    width: 200px;
    &:hover {
        background: #c97624 !important;
        color: #ffffffc4;
    }
    @media all and (max-width: 768px) {
        padding: 10px 30px !important;
    }
    @media all and (max-width: 480px) {
        width: 140px;
        font-size: 15px !important;
    }
    @media all and (max-width: 360px) {
        font-size: 14px !important;
        width: 130px;
    }
`;
const Generate = styled.div`
    color: #ffffff;
    margin: 25px auto !important;
    background: #ff8f20 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    display: block !important;
    padding: 15px 60px !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    text-align: center;
    width: 200px;
    &:hover {
        background: #c97624 !important;
        color: #ffffffc4;
    }
    @media all and (max-width: 768px) {
        padding: 10px 30px !important;
        width: 165px;
    }
    @media all and (max-width: 480px) {
        width: 140px;
        font-size: 15px !important;
    }
    @media all and (max-width: 360px) {
        font-size: 14px !important;
        width: 130px;
        margin-top: 10px !important;
    }
`;
const BackContainer = styled.div``;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    &.business_card {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media all and (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const CoverBox = styled.div``;
const Poster = styled.div`
    transition: 0.5s all ease;
    width: 946px;
    min-height: 1050px;
    position: relative;
    margin: 50px auto 100px;
    background-color: #fff;
    @media all and (max-width: 1080px) {
        width: 880px;
        min-height: 1050px;
        max-height: 1050px;
    }
    @media all and (max-width: 980px) {
        width: 660px;
        min-height: 830px;
        max-height: 830px;
        margin-bottom: 60px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
        min-height: 690px;
        max-height: 690px;
    }
    @media all and (max-width: 640px) {
        width: 430px;
        min-height: 540px;
        max-height: 540px;
        margin-top: 50px;
    }
    @media all and (max-width: 480px) {
        width: 330px;
        min-height: 415px;
        max-height: 415px;
        margin-bottom: 30px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
        min-height: 395px;
        max-height: 395px;
    }
`;
const BusinessCardContainer = styled.div`
    margin-top: 50px;
    /* overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    } */
`;
const BusinessCard = styled.div`
    transition: all 0.5s ease;
    position: relative;
    width: 1000px;
    margin: 30px auto 0;
    @media all and (max-width: 1080px) {
        width: 950px;
    }
    @media all and (max-width: 980px) {
        width: 740px;
    }
    @media all and (max-width: 768px) {
        width: 620px;
    }
    @media all and (max-width: 640px) {
        width: 525px;
    }
    @media all and (max-width: 540px) {
        width: 460px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
`;
const AepBusinessName = styled.h1`
    position: absolute;
    color: #97cc59;
    font-size: 50px;
    font-weight: 800;
    font-family: gordita_bold;
    text-transform: capitalize;
    top: 160px;
    left: 60px;
    @media all and (max-width: 1080px) {
        top: 150px;
        font-size: 47px;
    }
    @media all and (max-width: 980px) {
        top: 120px;
        left: 40px;
        font-size: 40px;
    }
    @media all and (max-width: 768px) {
        top: 98px;
        left: 40px;
        font-size: 35px;
    }
    @media all and (max-width: 640px) {
        top: 80px;
        left: 30px;
        font-size: 33px;
    }
    @media all and (max-width: 540px) {
        top: 65px;
        left: 30px;
        font-size: 31px;
    }
    @media all and (max-width: 480px) {
        top: 56px;
        left: 20px;
        font-size: 21px;
    }
    @media all and (max-width: 360px) {
        top: 50px;
        left: 20px;
        font-size: 19px;
    }
`;
const AEP = styled.h2`
    position: absolute;
    color: #2d9b7f;
    top: 225px;
    left: 60px;
    font-size: 21px;
    font-weight: 800;
    @media all and (max-width: 1080px) {
        top: 215px;
    }
    @media all and (max-width: 980px) {
        top: 173px;
        left: 40px;
        font-size: 18px;
    }
    @media all and (max-width: 768px) {
        top: 145px;
        left: 40px;
        font-size: 15px;
    }
    @media all and (max-width: 640px) {
        top: 127px;
        left: 30px;
        font-size: 12px;
    }
    @media all and (max-width: 540px) {
        top: 104px;
        font-size: 11px;
    }
    @media all and (max-width: 480px) {
        top: 83px;
        font-size: 8px;
        left: 20px;
    }
    @media all and (max-width: 360px) {
        top: 74px;
        font-size: 6px;
        left: 20px;
    }
`;
const AepPanchayathName = styled.h4`
    position: absolute;
    color: #2e2d2e;
    top: 260px;
    left: 60px;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    @media all and (max-width: 1080px) {
        top: 250px;
    }
    @media all and (max-width: 980px) {
        top: 200px;
        left: 40px;
        font-size: 20px;
    }
    @media all and (max-width: 768px) {
        top: 167px;
        font-size: 16px;
    }
    @media all and (max-width: 640px) {
        top: 143px;
        left: 30px;
        font-size: 14px;
    }
    @media all and (max-width: 540px) {
        top: 120px;
        left: 30px;
        font-size: 13px;
    }
    @media all and (max-width: 480px) {
        top: 95px;
        left: 20px;
        font-size: 8px;
    }
    @media all and (max-width: 360px) {
        top: 84px;
        left: 20px;
        font-size: 6px;
    }
`;
const AepBusinessPhone = styled.p`
    position: absolute;
    color: #2e2d2e;
    top: 330px;
    left: 90px;
    font-weight: 600;
    font-size: 19px;
    @media all and (max-width: 1080px) {
        top: 315px;
    }
    @media all and (max-width: 980px) {
        top: 245px;
        left: 65px;
        font-size: 15px;
    }
    @media all and (max-width: 768px) {
        top: 201px;
        left: 55px;
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        top: 173px;
        font-size: 11px;
        left: 48px;
    }
    @media all and (max-width: 540px) {
        top: 151px;
        left: 43px;
    }
    @media all and (max-width: 480px) {
        top: 113px;
        left: 30px;
        font-size: 6px;
    }
    @media all and (max-width: 360px) {
        top: 103px;
        left: 28px;
    }
`;
const AepLocation = styled.pre`
    position: absolute;
    color: #2e2d2e;
    top: 376px;
    left: 90px;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    width: 360px;
    @media all and (max-width: 1080px) {
        top: 355px;
    }
    @media all and (max-width: 980px) {
        top: 279px;
        left: 65px;
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        top: 231px;
        left: 55px;
        font-size: 15px;
    }
    @media all and (max-width: 640px) {
        top: 196px;
        left: 48px;
        font-size: 13px;
    }
    @media all and (max-width: 540px) {
        top: 173px;
        left: 43px;
        font-size: 11px;
    }
    @media all and (max-width: 480px) {
        top: 129px;
        left: 30px;
        font-size: 7px;
    }
    @media all and (max-width: 360px) {
        top: 117px;
        left: 28px;
        font-size: 6px;
    }
`;
const HelpLine = styled.p`
    position: absolute;
    color: #2e2d2e;
    bottom: 26px;
    left: 90px;
    font-size: 18px;
    font-weight: 600;
    span {
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
    }
    @media all and (max-width: 980px) {
        bottom: 19px;
        left: 65px;
        font-size: 17px;
        span {
            font-size: 15px;
        }
    }
    @media all and (max-width: 768px) {
        bottom: 16px;
        left: 55px;
        font-size: 15px;
        span {
            font-size: 13px;
        }
    }
    @media all and (max-width: 640px) {
        bottom: 16px;
        font-size: 13px;
        left: 48px;
        span {
            font-size: 12px;
        }
    }
    @media all and (max-width: 540px) {
        bottom: 14px;
        font-size: 12px;
        left: 43px;
        span {
            font-size: 11px;
        }
    }
    @media all and (max-width: 480px) {
        bottom: 13px;
        font-size: 7px;
        left: 30px;
        span {
            font-size: 6px;
        }
    }
`;
const Close = styled.img`
    display: block;
    width: 30px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: -40px;
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    @media all and (max-width: 640px) {
        width: 25px;
        height: 25px;
        top: 5px;
        right: -35px;
    }
    @media all and (max-width: 480px) {
        width: 20px;
        height: 20px;
        top: 3px;
        right: -25px;
    }
`;
const Background = styled.img`
    width: 100%;
`;
const Details = styled.div`
    position: absolute;
    bottom: 100px;
    left: 0px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 1080px) {
        bottom: 40px;
    }
    @media all and (max-width: 980px) {
        bottom: 73px;
    }
    @media all and (max-width: 768px) {
        bottom: 57px;
    }
    @media all and (max-width: 640px) {
        bottom: 43px;
    }
    @media all and (max-width: 480px) {
        bottom: 34px;
    }
    @media all and (max-width: 360px) {
        bottom: 38px;
    }
`;
const More = styled.p`
    font-size: 24px;
    margin-right: 15px;
    @media all and (max-width: 1080px) {
        font-size: 21px;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
        margin-right: 10px;
    }
    @media all and (max-width: 640px) {
        font-size: 11px;
    }
    @media all and (max-width: 480px) {
        font-size: 8px;
        margin-right: 5px;
    }
    @media all and (max-width: 360px) {
        font-size: 7px;
    }
`;
const AepName = styled.p`
    color: #39937a;
    font-size: 24px;
    font-family: "gordita_medium";
    position: relative;
    text-transform: capitalize;
    margin-right: 10px;
    ::before {
        content: ",";
        position: absolute;
        bottom: 0;
        right: -7px;
        color: #333;
    }
    @media all and (max-width: 1080px) {
        font-size: 21px;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        font-size: 11px;
    }
    @media all and (max-width: 480px) {
        font-size: 8px;
    }
    @media all and (max-width: 360px) {
        font-size: 7px;
    }
`;
const AepPhoneNumber = styled.p`
    font-family: "gordita_medium";
    color: #363636;
    font-size: 24px;
    @media all and (max-width: 1080px) {
        font-size: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        font-size: 11px;
    }
    @media all and (max-width: 480px) {
        font-size: 8px;
    }
    @media all and (max-width: 360px) {
        font-size: 7px;
    }
`;
