import React, { useState } from "react";
import styled from "styled-components";

function ProjectViewNav({ navList, setSelectedNav }) {
    const [activeItem, setActiveItem] = useState(0);

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    return (
        <Container>
            <List>
                {navList.map((item, index) => (
                    <Item
                        key={index}
                        onClick={() => {
                            handleItemClick(index);
                            setSelectedNav(item.name);
                        }}
                        active={activeItem === index}
                    >
                        {item.name}
                    </Item>
                ))}
            </List>
        </Container>
    );
}

export default ProjectViewNav;

const Container = styled.div`
    margin-bottom: 25px;
`;
const List = styled.ul`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2e2e2e;
`;
const Item = styled.li`
    color: ${(props) => (props.active ? "#FE673A" : "#667085")};
    font-size: 16px;
    font-family: "gordita_regular";
    cursor: pointer;
    margin-right: 14px;
    &:last-child {
        margin-right: 0;
    }
    border-bottom: ${(props) =>
        props.active ? "2px solid #FE673A" : "2px solid transparent"};
    &:hover {
        color: #fe673a;
    }
`;
