import React from "react";
import styled from "styled-components";
import animationData from "../../../../../src/assets/lotties/success-lottie.json";
import Lottie from "react-lottie";

export default function SuccessModal({
    successful,
    setSuccessful,
    setReload,
    content,
    modalUse,
}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {},
    };
    return (
        <>
            <Overlay className={successful ? "active" : ""}></Overlay>
            <Container className={successful ? "active" : ""}>
                <SubContainer>
                    <Title>
                        {modalUse ? modalUse : "Successfully Created"}{" "}
                        {/* Successfully */}
                    </Title>
                    <Lottie options={defaultOptions} height={300} width={300} />
                    <Continue
                        onClick={() => {
                            setSuccessful(false);
                            setReload((prev) => !prev);
                        }}
                    >
                        Continue
                    </Continue>
                </SubContainer>
            </Container>
        </>
    );
}
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;

    &.active {
        display: block;
    }
`;
const Container = styled.div`
    transform: scale(0, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;

    &.active {
        transform: scale(1, 1);
    }
`;
const SubContainer = styled.div`
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 10px;
    box-shadow: 0px 3px 56px #00000048;
    padding: 20px 35px 35px 35px;
    width: 500px;
`;
const Title = styled.h6`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
    text-align: center;
`;
const Continue = styled.div`
    background-color: #f7684c;
    color: #fff;
    width: 150px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
`;
