import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MyStatusRouter from "../registration-mystatus/MyStatusRouter";

const TechSchoolManager = lazy(() =>
    import("../../../screens/registration-manager/TechSchoolManager")
);
const TechDegreeManager = lazy(() =>
    import("../../../screens/registration-manager/TechDegreeManager")
);

const TechGradManager = lazy(() =>
    import("../../../screens/registration-manager/TechGradManager")
);
const TechSchoolStatusmanager = lazy(() =>
    import("../../../screens/registration-manager/TechSchoolStatusmanager")
);
const TechDegreeStatusManager = lazy(() =>
    import("../../../screens/registration-manager/TechDegreeStatusManager")
);
const TechGradStatusManager = lazy(() =>
    import("../../../screens/registration-manager/TechGradStatusManager")
);
function RegistrationManagerRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="techschooling/" />} />
            <Route path="techschooling/" element={<TechSchoolManager />} />
            <Route path="techdegree/" element={<TechDegreeManager />} />
            <Route path="techgrad/" element={<TechGradManager />} />

            <Route path="my-status/*" element={<MyStatusRouter />} />
        </Routes>
    );
}

export default RegistrationManagerRouter;
