import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Questions from "../../../assets/images/Sat-head/questions.svg";
import QuestionsActive from "../../../assets/images/Sat-head/questions-active.svg";
import schoolIcon1 from "../../../assets/images/school-scientist/Property1=school.svg";
import schoolIcon2 from "../../../assets/images/school-scientist/Property1=school2.svg";
import clipboard from "../../../assets/images/school-scientist/clipboard-tick.svg";
import complete from "../../../assets/images/school-scientist/complete.svg";
import winner from "../../../assets/images/school-scientist/winner.svg";
import winner_active from "../../../assets/images/school-scientist/winner_avtive.svg";
import round from "../../../assets/images/school-scientist/2nd-round.svg";
import active_round from "../../../assets/images/school-scientist/2nd-round-active.svg";

function SchoolScientistSidebar({ isExpand, setExpand, wrapperRef }) {
    return (
        <Container
            id="menu"
            ref={wrapperRef}
            className={isExpand && "expand"}
            onMouseEnter={() => setExpand(true)}
            onMouseLeave={() => setExpand(false)}
        >
            <Button to="school-scientist/dashboard/">
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/dashboard.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/dashboard-orange.svg
            "
                        alt="Icon"
                    />
                    <Title>Dashboard</Title>
                </Menu>
            </Button>
            <Button to="school-scientist/students/">
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-08-2022/Events.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-08-2022/EventsActive.svg"
                        alt="Icon"
                    />
                    <Title>Students</Title>
                </Menu>
            </Button>
            <Button to="school-scientist/school/">
                <Menu className="menu-item">
                    <MenuItemIcon src={schoolIcon1} alt="Icon" />
                    <MenuItemIcon1 src={schoolIcon2} alt="Icon" />
                    <Title>Schools</Title>
                </Menu>
            </Button>
            <Button to="/school-scientist/questions/?subject=mathematics&category=up">
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/renewal-notactive.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/renewal-active.svg"
                        alt="Icon"
                    />
                    <Title>Questions</Title>
                </Menu>
            </Button>
            <Button to="/school-scientist/attendees/">
                <Menu className="menu-item">
                    <MenuItemIcon src={complete} alt="Icon" />
                    <MenuItemIcon1 src={clipboard} alt="Icon" />
                    <Title>Attendees</Title>
                </Menu>
            </Button>
            {/* <Button to="/school-scientist/winners/">
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={winner }
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={winner_active}
                        alt="Icon"
                    />
                    <Title>Winners</Title>
                </Menu>
            </Button>
            <Button to="/school-scientist/participants/?category=up">
                <Menu className="menu-item">
                    <MenuItemIcon
                        src={round}
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src={active_round}
                        alt="Icon"
                    />
                    <Title>Round 2 </Title>
                </Menu>
            </Button> */}
        </Container>
    );
}

export default SchoolScientistSidebar;

const Title = styled.span`
    font-family: "gordita_regular";
    color: inherit;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #f7684c;
    }
`;
const SubTitle = styled.p`
    font-family: "gordita_medium";
    font-size: 14px;
    white-space: nowrap;
    color: #fff;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #1ec569;
    }
`;
const Menu = styled.div`
    padding: 10px 21px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
`;
const MenuItemIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const MenuItemIcon1 = styled.img`
    display: none;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const ArrowImageContainer = styled.img`
    display: block;
    width: 16px;
    transform: rotate(-90deg);
    transition: all 0.4s ease;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const ArrowImageContainer1 = styled.img`
    display: none;
    width: 16px;
    /* margin-right: 1.125rem; */
    transform: rotate(0deg);
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const DownIcon = styled.img``;
const DownIcon1 = styled.img``;

const Button = styled(NavLink)`
    width: -webkit-fill-available;
    height: 46px;
    display: flex;
    color: #fff;
    transition: all 0.4s ease;
    &.active {
        border-left: 5px solid #fe673a;
        color: #1ec569 !important;
        background: #161616;
        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }

        ${Title} {
            color: #f7684c;
        }
        ${ArrowImageContainer} {
            display: none;
        }
        ${ArrowImageContainer1} {
            display: block;
        }
    }
`;

const Container = styled.aside`
    &.expand {
        width: 218px;
        transition: all 0.4s ease;
        ${Title} {
            display: block;
        }
        ${SubTitle} {
            display: block;
        }
        ${Menu} {
        }
        ${MenuItemIcon} {
            margin-right: 1.125rem;
        }
        ${MenuItemIcon1} {
            margin-right: 1.125rem;
        }
        ${Button} {
        }
    }
`;
