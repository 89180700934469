import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import $ from "jquery";
import moment from "moment/moment";
import styled from "styled-components";
import {
    accountsConfig,
    coinsConfig,
    learnConfig,
} from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import NoData from "../modals/NoData";

function PaymentModal({ isModal, setModal, campusPk, setCampusPk }) {
    const [activeTab, setActiveTab] = useState(0);
    const [fromDate, setFromDate] = useState(null);
    console.log(fromDate, "--fromDate");
    const [payment, setPayment] = useState([]);
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;
    const [transactionId, setTransactionId] = useState("");
    const [amount, setAmount] = useState("");
    const [address, setAddress] = useState("");
    const [program, setProgram] = useState("");
    const [balanceAmount, setBalanceAmount] = useState("");
    const [stateId, setStateId] = useState("");
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    //Clear All
    const handleModal = () => {
        setTransactionId("");
        setModal(false);
        setAmount("");
        setActiveTab(0);
        setCampusPk("");
        setFromDate("");
    };
    const isSubmitDisabled = !transactionId || !fromDate;

    const today = new window.Date();
    const formatDate = (date) => {
        if (!date) {
            return "";
        }
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const formattedDay = day < 10 ? `0${day}` : `${day}`;
        return `${year}-${formattedMonth}-${formattedDay}`;
    };

    const formattedDate = formatDate(fromDate);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    //no scroll
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    // list payment history
    useEffect(() => {
        if (campusPk) {
            const paymentHistory = () => {
                coinsConfig
                    .get(`purchases/list-campus-payments/${campusPk}`, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })
                    .then((response) => {
                        const { status_code, data } = response.data;
                        if (status_code === 6000) {
                            setPayment(data?.payments);
                            setBalanceAmount(
                                data?.plan_details?.balance_amount
                            );
                            setAddress(
                                data?.plan_details?.payer_details?.address
                            );

                            setStateId(
                                data?.plan_details?.payer_details?.state_code
                            );
                        } else {
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            };

            paymentHistory();
        }
    }, [access_token, campusPk, reload]);

    // view plan details
    useEffect(() => {
        if (campusPk) {
            const planDetails = () => {
                learnConfig
                    .get(
                        `/subscriptions/get-campus-subscription-payment-details/${campusPk}/`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                        }
                    )
                    .then((response) => {
                        const { status_code, data } = response.data;
                        if (status_code === 6000) {
                            setProgram(data?.plan_name);
                        } else {
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            };

            planDetails();
        }
    }, [access_token, campusPk, reload]);

    const handileSubscription = (e) => {
        e.preventDefault();
        const combinedAddresses = `${address}\n`;
        const formData = new FormData();

        formData.append("transaction_id", transactionId);
        formData.append("address", combinedAddresses);
        formData.append("amount", amount);
        formData.append("transaction_date", formattedDate);
        formData.append("state_code", stateId);
        setButtonLoading(true);
        learnConfig
            .post(
                `subscriptions/create-campus-subscription-payment/${campusPk}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { status_code, data, message } = response.data;
                if (status_code === 6000) {
                    setButtonLoading(false);
                    setModal(false);
                    handleModal();
                    setReload(!reload);
                } else {
                    setErrorMessage(message.message);
                    setButtonLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    return (
        <BackContainer>
            {isModal ? (
                <Overlay
                    onClick={() => {
                        setModal(false);
                    }}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <Top>
                            <Head>
                                <TabLabel
                                    active={activeTab === 0}
                                    onClick={() => handleTabClick(0)}
                                >
                                    Payment
                                </TabLabel>
                                <TabLabel
                                    active={activeTab === 1}
                                    onClick={() => handleTabClick(1)}
                                >
                                    Payment List
                                </TabLabel>

                                <Closeicon onClick={handleModal}>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Icon"
                                    />
                                </Closeicon>
                            </Head>
                        </Top>
                        <>
                            {activeTab === 0 ? (
                                <>
                                    {" "}
                                    <DetailContainer>
                                        <SubHead>Program :</SubHead>
                                        <ValueInpt
                                            placeholder="Enter the program"
                                            value={program}
                                        />
                                    </DetailContainer>
                                    <DetailContainer>
                                        <SubHead>Balance Amount :</SubHead>
                                        <ValueInpt
                                            placeholder="Enter the Amount"
                                            value={balanceAmount}
                                        />
                                    </DetailContainer>
                                    <DetailContainer>
                                        <SubHead>Transaction date * :</SubHead>
                                        <DateContainer>
                                            <DatePicker
                                                format={"dd-MM-yy"}
                                                dayPlaceholder="DD"
                                                monthPlaceholder="MM"
                                                yearPlaceholder="YY"
                                                onChange={(value) =>
                                                    setFromDate(value)
                                                }
                                                value={fromDate}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                maxDate={today}
                                            />
                                        </DateContainer>
                                    </DetailContainer>
                                    <DetailContainer>
                                        <SubHead> Amount :</SubHead>
                                        <ValueInpt
                                            // placeholder="Enter the Amount"
                                            // value={amount}
                                            // onChange={(e) =>
                                            //     setAmount(e.target.value)
                                            // }
                                            // max={balanceAmount}

                                            placeholder="Enter the Amount"
                                            value={amount}
                                            onChange={(e) => {
                                                const numericInput =
                                                    e.target.value.replace(
                                                        /\D/g,
                                                        ""
                                                    );

                                                if (numericInput === "") {
                                                    setAmount("");
                                                    return;
                                                }

                                                const maxAmount =
                                                    parseFloat(balanceAmount);

                                                let inputValue =
                                                    parseFloat(numericInput);

                                                if (inputValue > maxAmount) {
                                                    inputValue = maxAmount;
                                                }

                                                setAmount(
                                                    inputValue.toString()
                                                );
                                            }}
                                            type="text"
                                            maxLength={
                                                String(balanceAmount).length
                                            }
                                        />
                                    </DetailContainer>
                                    <DetailContainer>
                                        <SubHead>Transaction ID * :</SubHead>
                                        <ValueInpt
                                            placeholder="Enter Transaction Id"
                                            value={transactionId}
                                            onChange={(e) =>
                                                setTransactionId(e.target.value)
                                            }
                                        />
                                    </DetailContainer>
                                    <CreateButtonBox>
                                        <CancelButton onClick={handleModal}>
                                            Cancel
                                        </CancelButton>
                                        <CreateButton
                                            onClick={handileSubscription}
                                            disabled={isSubmitDisabled}
                                        >
                                            {isButtonLoading ? (
                                                <ButtonLoader />
                                            ) : (
                                                "Create"
                                            )}
                                        </CreateButton>
                                    </CreateButtonBox>
                                </>
                            ) : (
                                <ListDiv>
                                    <Head>
                                        <HeadList>
                                            <HeadItem type="no">
                                                <Text>Sl No </Text>
                                            </HeadItem>
                                            <HeadItem type="id">
                                                <Text>Transaction Id</Text>
                                            </HeadItem>
                                            <HeadItem type="pay">
                                                <Text>Payment</Text>
                                            </HeadItem>
                                            <HeadItem type="date">
                                                <Text>Date</Text>
                                            </HeadItem>
                                        </HeadList>
                                    </Head>
                                    <Body>
                                        <BodyList>
                                            {payment.length > 0 ? (
                                                payment?.map((data, index) => (
                                                    <BodyItem key={index}>
                                                        <BodyText type="no">
                                                            {index + 1}{" "}
                                                        </BodyText>

                                                        <BodyText type="id">
                                                            {
                                                                data?.transaction_id
                                                            }{" "}
                                                        </BodyText>
                                                        <BodyText type="pay">
                                                            {data?.paid_amount}{" "}
                                                        </BodyText>
                                                        <BodyText type="date">
                                                            {data?.date
                                                                ? moment(
                                                                      data?.date
                                                                  ).format(
                                                                      "MMM DD YYYY"
                                                                  )
                                                                : "--"}
                                                        </BodyText>
                                                    </BodyItem>
                                                ))
                                            ) : (
                                                <BodyItem>
                                                    <NoData />
                                                </BodyItem>
                                            )}
                                        </BodyList>
                                    </Body>
                                </ListDiv>
                            )}
                        </>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default PaymentModal;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 640px;
    max-height: 65vh;
    min-height: 65vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 1280px) {
        max-height: 75vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 65vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    /* border-bottom: 2px solid #26272a; */
`;
const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const TabLabel = styled.div`
    flex: 1;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    color: ${(props) => (props.active ? "#E76037" : "#fff")};
    border-bottom: 2px solid
        ${(props) => (props.active ? "#E76037" : "#26272a")};
    transition: border-color 0.3s ease;
    &:hover {
        opacity: 90%;
    }
`;
const Headtitle = styled.p`
    font-size: 18px;
    color: ${({ active }) =>
        active === "payment"
            ? "#FE673A"
            : active === "list"
            ? "#FE673A"
            : "#fff"};

    /* border-bottom: 2px solid ${(props) =>
        props.active ? "#E76037" : ""}; */
    font-family: "gordita_medium";
    cursor: pointer !important;
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
    }
`;
const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;

const DetailContainer = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    &.drop {
        position: relative;
    }
    &.country {
        display: flex;
        position: relative;
    }
    &.district {
        display: flex;
        position: relative;
    }
`;

const SubHead = styled.div`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_regular";
`;

const ValueInpt = styled.input`
    width: 50%;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 1px solid rgb(38, 39, 42);
    /* cursor: pointer; */
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    &.country {
        width: 100%;
    }
    &.state {
        width: 100%;
    }
    &.district {
        width: 100%;
    }
`;

const DateContainer = styled.div`
    position: relative;
    width: 50%;
    & div.react-date-picker {
        /* padding: 10px 20px; */
    }
    & div.react-date-picker__calendar {
        left: -121px !important;
        top: -224px !important;
    }
    .react-date-picker__wrapper {
        padding: 8px 10px;
        text-align: left !important;
        font-size: 14px;
    }
`;

const ListDiv = styled.div``;
const Body = styled.div``;
const HeadList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #26272a;
    padding: 6px 12px;
`;
const HeadItem = styled.li`
    width: ${({ type }) =>
        type === "no"
            ? "10%"
            : type === "id"
            ? "30%"
            : type === "date"
            ? "20%"
            : type === "pay"
            ? "20%"
            : ""};
`;
const Text = styled.h4`
    font-size: 16px;
    font-family: "gordita_medium";
    color: #fff;
`;

const BodyList = styled.ul`
    width: 100%;
    height: 295px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const BodyItem = styled.li`
    display: flex;
    justify-content: space-between;
    border: 1px solid #26272a;
    padding: 6px 12px;
    margin-bottom: 4px;
`;
const BodyText = styled.h4`
    font-size: 14px;
    font-family: "gordita_medium";
    color: #fff;
    width: ${({ type }) =>
        type === "no"
            ? "10%"
            : type === "id"
            ? "30%"
            : type === "date"
            ? "20%"
            : type === "pay"
            ? "20%"
            : ""};
`;
const CountryDiv = styled.div`
    width: 100%;
    position: absolute;
    z-index: 888;
`;

const SelectCountry = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    max-height: 180px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const StateDiv = styled.div`
    width: 100%;
    position: absolute;
    z-index: 999;
`;

const SelectState = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    max-height: 180px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const DistrictDiv = styled.div`
    width: 100%;
    position: absolute;
    z-index: 888;
`;

const SelectDistrict = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    max-height: 180px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const Option = styled.h4`
    font-size: 14px;
    margin-bottom: 4px;
    border-bottom: 1px solid rgb(38, 39, 42);
    padding: 6px;
    &:last-child {
        border-bottom: 0;
    }
    &:hover {
        background-color: rgb(27, 28, 31);
    }
`;
