import React, { useState } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import DeleteModal from "../syllabus/modal/DeleteModal";
import MembersDelete from "./Modal/MemberDelete";
import SuccessModal from "../../screens/meet/trainer/modals/TrainerSuccess";

function MembersCard({ item, setReload }) {
    const [isModal, setModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [successful, setSuccessful] = useState(false);

    const handleRemoveClick = () => {
        setSelectedUserId(item.id);
        setModal(true);
    };

    return (
        <>
            <MembersDelete
                isModal={isModal}
                setModal={setModal}
                userId={selectedUserId}
                successful={successful}
                setSuccessful={setSuccessful}
            />
            <SuccessModal
                successful={successful}
                setSuccessful={setSuccessful}
                setReload={setReload}
            />
            <ItemList>
                <Item type="id">
                    <Item type="id">#{item?.user_id ?? "--"}</Item>
                </Item>
                <Item type="name">
                    <Desig>
                        <ImgDiv>
                            {item?.photo ? (
                                <img src={item?.photo} alt="icon" />
                            ) : (
                                <Avatar name={item?.name} size="40" round />
                            )}
                        </ImgDiv>
                        <Name>{item?.name ?? "--"}</Name>
                    </Desig>
                </Item>
                <Item type="contact">
                    {item?.country_code} {item?.phone ?? "--"}
                </Item>
                <Item type="date">{item?.joined_date ?? "--"}</Item>
                <Item className="flex">
                    <EditButton onClick={handleRemoveClick}>Remove</EditButton>
                </Item>
            </ItemList>
        </>
    );
}

export default MembersCard;

const ItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 18px 15px;
    color: #fff;

    &.active {
        background: #121214;
    }
`;
const Item = styled.li`
    font-size: 14px;
    /* text-align: ${({ type }) =>
        type === "seats" || type === "reg" ? "center" : "left"}; */

    h3 {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
    }
    h6 {
        font-size: 14px;
    }
    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    p {
        width: 60%;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    width: ${({ type }) =>
        type === "id"
            ? "10%"
            : type === "name"
            ? "20%"
            : type === "contact"
            ? "20%"
            : type === "date"
            ? "15%"
            : "10%"};
    list-style: none;

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    &.flex {
        display: flex;
        align-items: center;
    }
`;
const EditButton = styled.button`
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid rgb(59, 59, 59);
    padding: 10px 24px;
    display: inline-block;
    color: rgb(255, 48, 48);
    font-family: "Gordita_medium";
    :hover {
        background: rgba(22, 22, 25, 1);
    }
`;
const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
const Name = styled.h6`
    font-size: 18px;
    text-transform: capitalize;
`;
const Desig = styled.div`
    display: flex;
    align-items: center;
`;
