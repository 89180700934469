import React, { useState, useContext, useCallback } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import { Link, useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import moment from "moment";
import { error } from "jquery";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "../../includes/jobdesk/NoData";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import RestoreModal from "../../includes/career-advisor/modal/RestoreModal";
import RemovedLogsNavbar from "./RemovedLogsNavbar";
import ViewReasonModal from "../../includes/career-advisor/modal/ViewReasonModal";
import SortModal from "../../includes/career-advisor/modal/SortModal";
import DatePicker from "react-date-picker";
import TiptapViewerTrunc from "../../includes/doubt-hub/Editor/TiptapViewerTrunc";

function RemovedLogsComments({
    isReload,
    editCategoryPK,
    editLoder,
    wrapperRef,
    setFromDate,
    setToDate,
    fromDate,
    toDate,
}) {
    //category

    const [isView, setView] = useState(false);
    const [isModal, setModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [isPageLoading, setPageLoading] = useState(false);
    const [toLink, setToLink] = useState("?");
    const [isCategory, setCategory] = useState(false);
    const [isAuthor, setAuthor] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [isFilter, setFilter] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [selectedData, setSelectedData] = useState("");

    //pagination
    const [pagination, setPagination] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [activeClick, setActiveClick] = useState("Filter");
    const [dateAlert, setDateAlert] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [isDropdown, setDropdown] = useState(false);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [toDay, setToday] = useState("");
    const today = new window.Date();
    const currentParams = Object.fromEntries([...searchParams]);
    const [isReason, setIsReason] = useState(false);
    const [categorys, setCategorys] = useState([]);
    const [isSort, setSort] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        return filterParams;
    };
    const clearAll = () => {
        setSearchWord("");
        setToday("");
        setFromDate("");
        setToDate("");
    };
    const sortingArticles = (value) => {
        setSearchParams({
            ...currentParams,
            sort: value,
        });
    };
    const handleDateRange = (date, range) => {
        let date_range = `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}`;
        if (range === "from") {
            setSearchParams({
                ...currentParams,
                from_date: date_range,
            });
        } else {
            setSearchParams({
                ...currentParams,
                to_date: date_range,
            });
        }
    };
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const handleRemoveCategory = () => {
        setSelectedCategory(null);
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        updateSearchParams(query);
    };

    const updateSearchParams = useCallback(
        debounce((query) => {
            setSearchParams((prevParams) => ({
                ...prevParams,
                q: query,
            }));
        }, 300),
        []
    );

    const categoryFetch = () => {
        setPageLoading(true);
        const params = getSearchParams();
        learnConfig
            .get("/doubt-hub/listing-removed-comments-chief/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                let { status_code, data, pagination_data } = response.data;
                if (status_code === 6000) {
                    setPagination(data.pagination_data);
                    setCategories(data.data);
                    setPageLoading(false);
                } else {
                    console.log(error);
                    setPageLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setPageLoading(false);
            });
    };

    // api for restore question

    const RestoreCall = () => {
        setBtnLoading(true);
        const formData = new FormData();
        formData.append("restore_instance", "comment");
        learnConfig
            .post(`/doubt-hub/restore-chief/${selectedItem}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setBtnLoading(false);
                    categoryFetch();
                    setModal(false);
                    setSelectedItem("");
                } else {
                    setBtnLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setBtnLoading(false);
            });
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    const handleCloseClick = () => {
        setSelectedAuthor(null);
        const updatedParams = { ...currentParams };
        delete updatedParams.author;
        setSearchParams(updatedParams);
    };
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setCategory(false);
    };
    const handleDateFilter = (date) => {
        if (date !== "") {
            setSearchParams({
                ...currentParams,
                published_date: date,
            });
        } else {
            const updatedParams = { ...currentParams };
            delete updatedParams.published_date;
            setSearchParams(updatedParams);
        }
    };

    useEffect(() => {
        categoryFetch();
    }, [isReload, searchParams]);

    return (
        <>
            {isView && (
                <ViewReasonModal
                    modal={isView}
                    setModal={setView}
                    isReason={isReason}
                    setIsReason={setIsReason}
                    wrapperRef={wrapperRef}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                />
            )}

            <RestoreModal
                isModal={isModal}
                setModal={setModal}
                RestoreCall={RestoreCall}
                isBtnLoading={isBtnLoading}
            />
            <Container>
                <ContentContainer>
                    <TopContainer>
                        <Title>
                            <Heading>Removed Logs</Heading>
                            <Content>
                                <span>Evaluate and manage here</span>
                            </Content>
                        </Title>

                        <NavContainer>
                            <SearchContainer>
                                <SearchBar>
                                    <InputField
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </SearchBar>
                                <SearchImageContainer>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
                                        alt="icon"
                                    />
                                </SearchImageContainer>
                            </SearchContainer>
                            <Sort>
                                <SortDiv
                                    onClick={() => {
                                        setFilter(false);
                                        setSort(!isSort);
                                    }}
                                >
                                    <Smaall className="white">Sort by</Smaall>
                                    <Spaan>
                                        <img
                                            src={
                                                require("../../../assets/images/sort.svg")
                                                    .default
                                            }
                                            alt="Sort"
                                        />
                                    </Spaan>
                                </SortDiv>
                            </Sort>
                            <SortModal
                                sortingArticles={sortingArticles}
                                isSort={isSort}
                                setSort={setSort}
                                wrapperRef={wrapperRef}
                            />
                            <TopFilter>
                                <CoverSet ref={wrapperRef}>
                                    <Filters
                                        onClick={() => setDropdown(!isDropdown)}
                                    >
                                        <FilterText>{activeClick}</FilterText>
                                        <FIlterImage>
                                            <img
                                                src={
                                                    require("../../../assets/images/bda/Filter.svg")
                                                        .default
                                                }
                                                alt="Image"
                                            />
                                        </FIlterImage>
                                    </Filters>
                                    <Popup
                                        className={isDropdown ? "dropdown" : ""}
                                    >
                                        <FilterDiv>
                                            Filter
                                            <ArrowDiv
                                                onClick={() =>
                                                    setDropdown(!isDropdown)
                                                }
                                            >
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                    alt="close"
                                                />
                                            </ArrowDiv>
                                        </FilterDiv>
                                        <HrLine className="Exam Status"></HrLine>
                                        {selectedAuthor && (
                                            <SelectDiv>
                                                <Select>
                                                    <MainImg
                                                        style={{
                                                            backgroundImage: `url(${selectedAuthor.image})`,
                                                        }}
                                                    ></MainImg>
                                                    <MianText>
                                                        {selectedAuthor.name}
                                                    </MianText>
                                                    <CloseImg
                                                        onClick={
                                                            handleCloseClick
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                require("../../../assets/images/closered.svg")
                                                                    .default
                                                            }
                                                            alt="close"
                                                        />
                                                    </CloseImg>
                                                </Select>
                                            </SelectDiv>
                                        )}
                                        {/* <SearchDiv
                                            onClick={() => {
                                                setCategory(!isCategory);
                                                setAuthor(false);
                                            }}
                                        >
                                            <SearchTxt
                                                type="text"
                                                value={searchWord}
                                                onChange={(e) =>
                                                    setSearchWord(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Search category"
                                            />
                                            <ArticleIcon>
                                                <img
                                                    src={
                                                        require("../../../assets/images/chat/search.svg")
                                                            .default
                                                    }
                                                    alt="searchimage"
                                                />
                                            </ArticleIcon>
                                        </SearchDiv>
                                        {isCategory && (
                                            <SearchDivModal>
                                                {categorys.map((data) => (
                                                    <SearchContent
                                                        key={data.id}
                                                        onClick={() => {
                                                            handleCategoryClick(
                                                                data
                                                            );
                                                            setSearchWord("");
                                                            setSearchParams({
                                                                ...currentParams,
                                                                category:
                                                                    data.slug,
                                                            });
                                                        }}
                                                    >
                                                        <p>{data.title}</p>
                                                    </SearchContent>
                                                ))}
                                            </SearchDivModal>
                                        )}

                                        {selectedCategory && (
                                            <SelectDiv>
                                                <Select>
                                                    <MianText>
                                                        {selectedCategory.title}
                                                    </MianText>
                                                    <CloseImg
                                                        onClick={
                                                            handleRemoveCategory
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                require("../../../assets/images/closered.svg")
                                                                    .default
                                                            }
                                                            alt="close"
                                                        />
                                                    </CloseImg>
                                                </Select>
                                            </SelectDiv>
                                        )}
                                        <TagContainer>
                                            <Tag>Artificial Intellegence</Tag>
                                            <TagIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                    alt="close"
                                                />
                                            </TagIcon>
                                        </TagContainer> */}
                                        <ContainerDiv>
                                            <Small>Date Range</Small>
                                            <SelectOption
                                                onClick={() => {
                                                    setToday(
                                                        toDay == "today"
                                                            ? ""
                                                            : "today"
                                                    );
                                                    handleDateFilter(
                                                        toDay == "today"
                                                            ? ""
                                                            : "today"
                                                    );
                                                }}
                                            >
                                                <Span>
                                                    {toDay === "today" ? (
                                                        <SelectedImg>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                                alt="image"
                                                            />
                                                        </SelectedImg>
                                                    ) : (
                                                        <UnSelectedImg>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                                alt="image"
                                                            />
                                                        </UnSelectedImg>
                                                    )}
                                                </Span>
                                                <Text>Today</Text>
                                            </SelectOption>

                                            <SelectOption
                                                onClick={() => {
                                                    setToday(
                                                        toDay == "this_week"
                                                            ? ""
                                                            : "this_week"
                                                    );
                                                    handleDateFilter(
                                                        toDay == "this_week"
                                                            ? ""
                                                            : "this_week"
                                                    );
                                                }}
                                            >
                                                <Span>
                                                    {toDay === "this_week" ? (
                                                        <SelectedImg>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                                alt="image"
                                                            />
                                                        </SelectedImg>
                                                    ) : (
                                                        <UnSelectedImg>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                                alt="image"
                                                            />
                                                        </UnSelectedImg>
                                                    )}
                                                </Span>
                                                <Text>This week</Text>
                                            </SelectOption>

                                            <SelectOption
                                                onClick={() => {
                                                    setToday(
                                                        toDay == "this_month"
                                                            ? ""
                                                            : "this_month"
                                                    );
                                                    handleDateFilter(
                                                        toDay == "this_month"
                                                            ? ""
                                                            : "this_month"
                                                    );
                                                }}
                                            >
                                                <Span>
                                                    {toDay === "this_month" ? (
                                                        <SelectedImg>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                                alt="image"
                                                            />
                                                        </SelectedImg>
                                                    ) : (
                                                        <UnSelectedImg>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                                alt="image"
                                                            />
                                                        </UnSelectedImg>
                                                    )}
                                                </Span>
                                                <Text>This month</Text>
                                            </SelectOption>
                                        </ContainerDiv>
                                        <HrLine className="Exam Status"></HrLine>
                                        <ContainerDiv>
                                            <Small>Pick Date</Small>
                                            <AccountFilter>
                                                <DatePickDiv>
                                                    <DatePicker
                                                        format={"dd-MM-yy"}
                                                        dayPlaceholder="DD"
                                                        monthPlaceholder="MM"
                                                        yearPlaceholder="YY"
                                                        onChange={(value) => {
                                                            setFromDate(value);
                                                            handleDateRange(
                                                                value,
                                                                "from"
                                                            );
                                                        }}
                                                        value={fromDate}
                                                        clearIcon={null}
                                                        calendarIcon={null}
                                                        maxDate={today}
                                                    />
                                                </DatePickDiv>
                                                to
                                                <DatePickDiv>
                                                    <DatePicker
                                                        format={"dd-MM-yy"}
                                                        dayPlaceholder="DD"
                                                        monthPlaceholder="MM"
                                                        yearPlaceholder="YY"
                                                        onChange={(value) => {
                                                            setToDate(value);
                                                            handleDateRange(
                                                                value,
                                                                "to"
                                                            );
                                                        }}
                                                        value={toDate}
                                                        clearIcon={null}
                                                        calendarIcon={null}
                                                        maxDate={today}
                                                    />
                                                </DatePickDiv>
                                            </AccountFilter>
                                        </ContainerDiv>
                                        <ButtonDiv>
                                            <ClearDiv onClick={clearAll} to="">
                                                Clear
                                            </ClearDiv>
                                            <ApplyDiv
                                                className={
                                                    dateAlert && "disabled"
                                                }
                                                onClick={(e) => {
                                                    setDropdown(false);
                                                }}
                                            >
                                                Apply
                                            </ApplyDiv>
                                        </ButtonDiv>
                                    </Popup>
                                </CoverSet>
                            </TopFilter>
                        </NavContainer>
                    </TopContainer>

                    <RemovedLogsNavbar />

                    <MainContainer>
                        <Top>
                            <Text type="sl_no">Sl No</Text>
                            <Text type="qa">Answers</Text>
                            <Text type="post">Posted by </Text>
                            <Text type="remove">Removed by</Text>
                            <Text type="date">Date and Time </Text>
                            <Text type="actions">Action</Text>
                        </Top>
                        <DataContainer>
                            {isPageLoading ? (
                                <SectionLoader />
                            ) : categories.length > 0 ? (
                                <>
                                    {categories.map((data, index) => (
                                        <Bottom key={index}>
                                            <Section type="sl_no">
                                                <small>
                                                    {index < 9 &&
                                                    pagination.current_page ===
                                                        1
                                                        ? `0${
                                                              pagination.first_item +
                                                              index
                                                          }`
                                                        : pagination.first_item +
                                                          index}
                                                </small>
                                            </Section>
                                            <Section type="qa">
                                                <p title={data?.description}>
                                                    {data?.description
                                                        ? data?.description
                                                        : "--"}
                                                </p>
                                            </Section>
                                            <Section type="post">
                                                {data?.posted_by
                                                    ?.student_photo ? (
                                                    <ImageContainer>
                                                        <img
                                                            src={
                                                                data?.posted_by
                                                                    ?.student_photo
                                                            }
                                                            alt="student"
                                                        />
                                                    </ImageContainer>
                                                ) : (
                                                    <DpDiv>
                                                        <Avatar
                                                            name={
                                                                data?.posted_by
                                                                    ?.student_name
                                                            }
                                                            size="38"
                                                            round
                                                        />
                                                    </DpDiv>
                                                )}
                                                <Student>
                                                    <h3
                                                        style={{
                                                            fontFamily:
                                                                "gordita_medium",
                                                            fontSize: "14px",
                                                            color: " #fff",
                                                        }}
                                                    >
                                                        {data?.posted_by
                                                            ?.student_name ??
                                                            "--"}
                                                    </h3>
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                "gordita_regular",
                                                            fontSize: "14px",
                                                            color: "#fe673a",
                                                        }}
                                                    >
                                                        {data?.posted_by
                                                            ?.student_program ??
                                                            "--"}
                                                    </p>
                                                </Student>
                                            </Section>
                                            <Section type="remove">
                                                {data?.removed_by
                                                    ?.profile_photo ? (
                                                    <ImageContainer>
                                                        <img
                                                            src={
                                                                data?.removed_by
                                                                    ?.profile_photo
                                                            }
                                                            alt="student"
                                                        />
                                                    </ImageContainer>
                                                ) : (
                                                    <DpDiv>
                                                        <Avatar
                                                            name={
                                                                data?.removed_by
                                                                    ?.user_name
                                                            }
                                                            size="38"
                                                            round
                                                        />
                                                    </DpDiv>
                                                )}

                                                <Student>
                                                    <h3
                                                        style={{
                                                            fontFamily:
                                                                "gordita_medium",
                                                            fontSize: "14px",
                                                            color: " #fff",
                                                        }}
                                                    >
                                                        {data?.removed_by
                                                            ?.user_name ?? "--"}
                                                    </h3>
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                "gordita_regular",
                                                            fontSize: "14px",
                                                            color: "#fe673a",
                                                        }}
                                                    >
                                                        {data?.removed_by
                                                            ?.profile_type ===
                                                        "students_relations_officer"
                                                            ? "Moderator"
                                                            : data?.removed_by
                                                                  ?.profile_type}
                                                    </p>
                                                </Student>
                                            </Section>
                                            <Section type="date">
                                                <div>
                                                    <Date>
                                                        {moment(
                                                            data?.removed_date ??
                                                                "--"
                                                        ).format("MMMM DD")}
                                                    </Date>
                                                    <Time>
                                                        {moment(
                                                            data?.removed_date ??
                                                                "--"
                                                        ).format("LT")}
                                                    </Time>
                                                </div>
                                            </Section>
                                            <Section type="actions">
                                                <BottomContainer>
                                                    <Edit
                                                        onClick={(e) => {
                                                            setModal(true);
                                                            setSelectedItem(
                                                                data?.pk
                                                            );
                                                        }}
                                                    >
                                                        <RestoreButton>
                                                            Restore
                                                        </RestoreButton>
                                                        {editLoder &&
                                                        data.id ==
                                                            editCategoryPK ? (
                                                            <ButtonLoader
                                                                width={25}
                                                                height={25}
                                                            />
                                                        ) : (
                                                            <ImageBox>
                                                                {/* <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/Edit.svg"
                                                                alt="EditIcon"
                                                            /> */}
                                                            </ImageBox>
                                                        )}
                                                    </Edit>
                                                    <Delete
                                                        onClick={() => {
                                                            setView(!isView);
                                                            setSelectedData(
                                                                data
                                                            );
                                                        }}
                                                    >
                                                        View Reason
                                                    </Delete>
                                                </BottomContainer>
                                            </Section>
                                        </Bottom>
                                    ))}
                                </>
                            ) : (
                                <NoData />
                            )}
                        </DataContainer>
                    </MainContainer>
                </ContentContainer>
                {pagination?.total_pages > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
            </Container>
        </>
    );
}

export default RemovedLogsComments;

const Date = styled.h5`
    font-size: 14px;
    font-family: "gordita_medium";
`;
const Time = styled.h5`
    font-size: 14px;
    font-family: "gordita_regular";
`;

const Containers = styled.section`
    padding-top: 22px;
`;
const ContentContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
`;
const ClearDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 154px;
    height: 40px;
    background: #1b1c20;
    border-radius: 6px;
    border: 1px solid #fe673a;
    margin-right: 15px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
`;

const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Title = styled.div``;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;
const Content = styled.div`
    margin-bottom: 12px;
    font-family: "gordita_reglar";
    span {
        color: #fff;
        font-size: 16px;
    }
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Nav = styled.div`
    display: flex;
    justify-content: end;
`;
const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px 16px 8px 16px;
    border: 2px solid #2c2c2c;
    border-radius: 8px;
    margin-right: 12px;
    background-color: #1b1c1f;
`;
const Sort = styled.button`
    position: relative;
    margin-right: 8px;
    img {
        display: block;
        width: 100%;
    }
`;
const SortDiv = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const Spaan = styled.span`
    width: 24px;
`;
const Smaall = styled.small`
    color: #7b7b7b;
    font-size: 0.875rem;
    font-family: gordita_medium;

    cursor: pointer;
    &.white {
        color: #fff;

        margin-right: 8px;
    }
    &.whites {
        color: #f2f2f2;

        line-height: normal;
        margin-right: 0;
        margin-left: 4px;
    }
`;
const Container = styled.div``;
const List = styled.ul`
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    display: flex;
    width: 302px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 299px;
    top: 180px;
    transition: 120ms ease all;
    opacity: 0;
    visibility: hidden;
    &.active {
        top: 190px;
        opacity: 1;
        right: 299px;
        visibility: visible;
        z-index: 99;
    }
`;
const Item = styled.li`
    display: flex;
    height: 44px;
    width: 268px;
    padding: 6px 10px 6px 12px;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    cursor: pointer;
    &.active {
        border-radius: 6px;
        background: #161619;
        &::before {
            content: "";
            position: absolute;
            border-radius: 0px 6px 6px 0px;
            background: #fe673a;
            width: 3px;
            height: 30px;
            left: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;

const Contents = styled.div`
    display: flex;
    font-family: "gordita_medium";
    font-size: 16px;
    width: ${({ type }) =>
        type === "question"
            ? "30%"
            : type === "date"
            ? "10%"
            : type === "tags"
            ? "15%"
            : type === "ask"
            ? "8%"
            : type === "action"
            ? "15%"
            : ""};
    justify-content: ${({ type }) =>
        type === "viewbutton" ? "center" : "flex-start"};
`;

const Li = styled.li`
    display: flex;
    flex-wrap: wrap;
    width: ${({ type }) =>
        type === "question"
            ? "36%"
            : type === "date"
            ? "8%"
            : type === "tags"
            ? "20%"
            : type === "ask"
            ? "9%"
            : type === "action"
            ? "9%"
            : ""};
    justify-content: ${({ type }) =>
        type === "subscription" ? "center" : "flex-start"};
`;
const UlList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    align-items: center;
    border-bottom: 1px solid #252627;
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const Round = styled.span`
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    position: relative;
    img {
        width: 100%;
        display: block;
    }
`;
const Texts = styled.h4`
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    height: 16px;
`;

const SortConrtainer = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 2px solid #2c2c2c;
    padding: 8px 10px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 12px;
    span {
        font-family: "gordita_regular";
        color: #fff;
    }
`;
const SortIcon = styled.div`
    width: 25%;
    img {
        width: 100%;
        display: block;
        margin-left: 4px;
    }
`;
const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;
const Filters = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #26272a;
    background: #1b1c1f;
    cursor: pointer;
`;
const FilterText = styled.div`
    margin-right: 10px;
    font-family: gordita_medium;
    color: #fff;
    font-size: 14px;
`;
const FIlterImage = styled.div`
    & img {
        width: 18px;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    padding: 20px;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 384px;
    top: 0;
    right: -500px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: auto;
        transition: 0.5s ease;
        min-height: 100%;
        right: 0;
        overflow-y: scroll;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const ApplyDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 11px;
    width: 154px;
    height: 40px;
    background: #fe673a;
    border-radius: 6px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
    &.disabled {
        cursor: not-allowed;
    }
`;
const FilterDiv = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
    justify-content: space-between;
    align-items: center;
`;
const Wrap = styled.div`
    display: flex;
    flex-direction: column;
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    margin: 10px 0;
`;
const ArrowDiv = styled.div`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 20px;

    transform: rotate(90deg);

    img {
        width: 100%;
        display: block;
    }
`;

const AccountFilter = styled.div`
    display: flex;
    align-items: center;
`;
const DatePickDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    background: #161618;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #272729;
    font-size: 14px;
    width: 146px;
    height: 50px;
    cursor: pointer;
    z-index: unset !important;
    @media all and (max-width: 480px) {
        padding: 13px 5px;
    }
    /* position: relative; */

    & .react-date-picker__calendar {
        inset: inherit !important;
        top: -175px !important;
        left: -38px !important;
        z-index: 201;
        width: 375px !important;
    }

    &:last-child .react-date-picker__calendar {
        left: -218px !important;
    }

    &:last-child {
        margin: 0 0 0 10px;
    }

    &.active {
        border: 1px solid #fe673a;
    }
`;
const ArticleDivModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    width: 100%;
    max-height: 219px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-bottom: 10px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SearchDivModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    width: 100%;
    max-height: 219px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    top: 290px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SelectDiv = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
`;
const TagContainer = styled.div`
    padding: 8px 10px 8px 10px;
    background: #1d1c1c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 56%;
    border-radius: 6px;
`;
const Tag = styled.div`
    font-family: "gordita_regular";
`;
const TagDate = styled.div`
    display: flex;
    flex-direction: column;
    span {
        font-family: "gordita_regular";
        font-size: 13px;
    }
    small {
        font-family: "gordita_regular";
        font-size: 13px;
    }
`;
const TagIcon = styled.div`
    width: 12px;
    img {
        width: 100%;
        display: block;
    }
`;
const ContainerDiv = styled.div``;
const Small = styled.span`
    color: #838488;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 15px;
`;
const SelectOption = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Select = styled.div`
    display: inline-flex;
    padding: 6px 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: #3b3b3b;
`;
const MainImg = styled.small`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    margin-right: 8px;
`;
const MianText = styled.h5`
    color: #fff;
    font-size: 14px;
    height: 16px;
`;
const CloseImg = styled.span`
    display: inline-block;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
    }
`;

const SearchContent = styled.div`
    font-size: 16px;
    border-bottom: 1px solid #777;
    font-family: gordita_medium;
    padding: 14px 20px;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    }
`;
const SearchBar = styled.div`
    // padding: 10px 24px;
    background-color: #1b1c1f;
    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background-color: #1b1c1f;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 10px 10px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const SearchImg = styled.img`
    width: 100%;
    display: block;
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;
const MainContainer = styled.div`
    min-width: 1280px;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    border-radius: 8px 8px 0 0;
    background-color: #1b1c1f;
`;

const Text = styled.h3`
    color: #ffff;
    font-size: 14px;
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "qa"
            ? "30%"
            : type === "post"
            ? "18%"
            : type === "remove"
            ? "17%"
            : type === "date"
            ? "10%"
            : type === "actions"
            ? "20%"
            : "10%"};
    display: flex;
    align-items: center;
`;

const DataContainer = styled.div`
    min-height: 61vh;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    background-color: #1b1c1f;
    &:hover {
        background-color: #161619;
    }
`;
const Section = styled.div`
    color: #d2caca;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "qa"
            ? "30%"
            : type === "post"
            ? "18%"
            : type === "remove"
            ? "17%"
            : type === "date"
            ? "10%"
            : type === "actions"
            ? "20%"
            : "10%"};

    display: flex;
    align-items: center;

    p {
        color: #d2caca;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 320px;
        white-space: nowrap;
    }
    span {
        height: 30px;
        /* width: 30px; */
        img {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
`;

const BottomContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Edit = styled.button`
    border-radius: 6px;
    border: 2px solid #2c2c2c;
    background: #fff;
    padding: 10px 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    p {
        font-family: "gordita_medium";

        font-size: 12px;
        color: #000;
    }
`;
const RestoreButton = styled.div`
    font-family: "gordita_medium";
`;
const ImageBox = styled.span`
    width: 20px;
    display: inline-block;

    img {
        display: block;
        width: 100%;
    }
`;
const Delete = styled.button`
    margin-left: 10px;
    font-family: "gordita_medium";
    color: #fe673a;
    font-size: 14px;
    padding: 10px 28px;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
    width: 150px;
`;
const SearchDiv = styled.div`
    display: flex;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    padding: 12px 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
`;
const SearchTxt = styled.input`
    color: #fff;
    font-size: 18px;
    width: 100%;

    &::placeholder {
        color: #fff;
        font-size: 18px;
    }
`;
const ArticleIcon = styled.small`
    display: inline-block;
    width: 18px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const ImageContainer = styled.div`
    margin-right: 5px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;

    img {
        width: 100%;
        height: 100%;
        display: block;
    }

    img {
        width: 100%;
        display: block;
    }
`;
const Student = styled.div`
    h2 {
        font-family: "gordita_medium";
        font-size: 16px;
    }
    p {
        font-family: "gordita_medium";
        font-size: 16px;
    }
`;
const DpDiv = styled.div`
    margin-right: 5px;
`;
