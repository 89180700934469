import React, { lazy, useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CommunityPosts from "../../../screens/community-post/CommunityPosts";
import CommunityComments from "../../../screens/community-post/CommunityComments";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
const Community = lazy(() =>
    import("../../../screens/community-post/Community")
);
const CommunityPostDetails = lazy(() =>
    import("../../../screens/community-post/CommunityPostDetails")
);

function CommunityPostRouter() {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const location = useLocation();
    const [reports, setReports] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [loader, setLoader] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("blog_status") &&
            (filterParams.blog_status = searchParams.get("blog_status"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        searchParams.get("author") &&
            (filterParams.author = searchParams.get("author"));
        searchParams.get("published_date") &&
            (filterParams.published_date = searchParams.get("published_date"));
        searchParams.get("from_date") &&
            (filterParams.from_date = searchParams.get("from_date"));
        searchParams.get("to_date") &&
            (filterParams.to_date = searchParams.get("to_date"));
        searchParams.get("category") &&
            (filterParams.category = searchParams.get("category"));
        return filterParams;
    };

    useEffect(() => {
        async function fetchReports() {
            setLoader(true);
            const params = getSearchParams();

            try {
                const reportType =
                    location.pathname === "/community/reports/"
                        ? "posts"
                        : "comments";
                const response = await learnConfig.get(
                    `/posts/list-reports/?report_type=${reportType}`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: params,
                    }
                );
                const { status_code, data, pagination_data } = response.data;

                if (status_code === 6000) {
                    setReports(data);
                    setLoader(false);
                    setPagination(pagination_data);
                } else {
                }
            } catch (error) {
                console.error(error);
                setLoader(false);
            }
        }

        fetchReports();
    }, [currentPage, access_token, location.pathname, searchParams]);
    return (
        <>
            {location.pathname === "/community/reports/" ? (
                <Community
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                    handlePageClick={handlePageClick}
                    currentParams={currentParams}
                />
            ) : location.pathname === "/community/comments/" ? (
                <Community />
            ) : null}
            <Routes>
                <Route
                    path="/reports/"
                    element={
                        <CommunityPosts
                            reports={reports}
                            pagination={pagination}
                            loader={loader}
                            handlePageClick={handlePageClick}
                        />
                    }
                />
                <Route
                    path="/comments/"
                    element={
                        <CommunityComments
                            reports={reports}
                            pagination={pagination}
                            loader={loader}
                            handlePageClick={handlePageClick}
                        />
                    }
                />
                <Route
                    path="/reports/:report_type/:id/"
                    element={<CommunityPostDetails />}
                />
            </Routes>
        </>
    );
}

export default CommunityPostRouter;
