import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import AepPoster from "./AepPoster";

function AepTechDegreePosterForm() {
    const [aepName, setAepName] = useState("");
    const [aepPhoneNumber, setPhoneNumber] = useState("");
    const [showPoster, setShowPoster] = useState(false);
    const [error, setError] = useState(false);

    return (
        <>
            {showPoster && (
                <AepPoster
                    aepName={aepName}
                    aepPhone={aepPhoneNumber}
                    setShowPoster={setShowPoster}
                    type="tech_degree"
                />
            )}
            <MainSection id="main">
                <Container>
                    <Title>Posters</Title>
                    <InputContainer>
                        <Label>
                            AEP Name <span className="name">:</span>
                        </Label>
                        <InputField
                            value={aepName}
                            onChange={(e) => {
                                setAepName(e.target.value);
                            }}
                            type="text"
                        />
                        {error && aepName == "" && (
                            <p>This field is required*</p>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <Label>
                            Phone Number <span>:</span>
                        </Label>
                        <InputField
                            id="phone"
                            value={aepPhoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value);
                            }}
                            type="number"
                            onKeyDown={(e) => {
                                ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                            }}
                        />
                        {error && aepPhoneNumber == "" && (
                            <p>This field is required*</p>
                        )}
                    </InputContainer>
                    <GenerateBox>
                        <Generate
                            onClick={() => {
                                if (aepName !== "" && aepPhoneNumber !== "") {
                                    setError(false);
                                    setShowPoster(true);
                                } else {
                                    setError(true);
                                }
                            }}
                        >
                            Generate
                        </Generate>
                    </GenerateBox>
                </Container>
            </MainSection>
        </>
    );
}

export default AepTechDegreePosterForm;
const MainSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 550px;
    height: 100vh;
`;

const Container = styled.div`
    width: 50%;
    @media all and (max-width: 1280px) {
        width: 60%;
    }
    @media all and (max-width: 1080px) {
        width: 67%;
    }
    @media all and (max-width: 980px) {
        width: 81%;
    }
    @media all and (max-width: 768px) {
        width: 81%;
    }

    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const Title = styled.h3`
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
`;
const InputContainer = styled.div`
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    p {
        position: absolute;
        right: 10px;
        bottom: -25px;
        font-size: 14px;
        color: red;
    }
    @media all and (max-width: 640px) {
        flex-direction: column;
        align-items: flex-start;
    }
    @media all and (max-width: 360px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const Label = styled.label`
    width: 34%;
    display: inline-block;
    span {
        margin: 0 20px;
        display: inline-block;
        &.name {
            margin-left: 58px;
        }
    }
    @media all and (max-width: 768px) {
        width: 40%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        margin-bottom: 10px;
        span {
            margin: 0 10px;
            &.name {
                margin-left: 10px;
            }
        }
    }
    @media all and (max-width: 480px) {
        span {
            margin: 0;
            &.name {
                margin: 0;
            }
        }
    }
`;
const InputField = styled.input`
    flex: 1;
    display: inline-block;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #fe673a;
    border-radius: 5px;
    :valid {
        text-transform: capitalize;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const GenerateBox = styled.div`
    text-align: center;
    display: block;
`;
const Generate = styled.button`
    margin-top: 20px;
    cursor: pointer;
    background-color: #fe673a;
    color: #fff;
    padding: 10px 30px;
    border-radius: 6px;
    font-family: "gordita_medium";
    font-size: 17px;
`;
