import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import successData from "../../../../assets/lotties/modal/successtick.json";
import Lottie from "react-lottie";

const ExamCompletedModal = ({
    isSuccess,
    setSuccess,
    setContinue,
    isContinue,
    setNumber,
}) => {
    //-----------for blocking background scroll when modal is open-------------
    useEffect(() => {
        if (isSuccess) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isSuccess]);

    //--------------------------------------------------------------------------

    //---------------lottie options---------------------------------------------

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successData,
        rendererSettings: {},
    };

    return (
        <BackContainer style={{ transform: isSuccess && "scale(1,1)" }}>
            <Overlay> </Overlay>
            <Container>
                <Circle>
                    <LottieContainer>
                        <Lottie
                            options={defaultOptions}
                            height={120}
                            width="90%"
                        />
                    </LottieContainer>
                </Circle>
                <HeadingContainer>
                    <>
                        <Title>Success</Title>
                        <Text>Exam Completed Successfully</Text>
                    </>
                </HeadingContainer>
                <Button
                    onClick={() => {
                        setSuccess(false);
                        setNumber(1);
                        setContinue(!isContinue);
                    }}
                    type="success"
                >
                    Continue
                </Button>
            </Container>
        </BackContainer>
    );
};

export default ExamCompletedModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
`;
const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .update {
        align-items: flex-start;
        width: 100%;
    }
`;
const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #1b1c1f;
    padding: 55px 40px 40px;
    border: 1px solid #3c3c3c;
    border-radius: 5px;
    z-index: 101;
    transition: 0.3s;

    @media (max-width: 560px) {
        width: 400px;
    }
    @media (max-width: 440px) {
        width: 370px;
        padding: 55px 25px 30px;
    }
    @media (max-width: 385px) {
        width: 340px;
    }
    @media (max-width: 385px) {
        width: 300px;
    }
`;
const LottieContainer = styled.div`
    width: 130px;
    height: 130px;
    position: absolute;
    top: 1px;
    left: 39px;
    transform: translate(-45px, -3px);
    margin: 0px auto;
    @media (max-width: 560px) {
        transform: translate(-45px, -8px);
        width: 120px;
        height: 120px;
    }
    @media (max-width: 440px) {
        transform: translate(-44px, -18px);
        width: 100px;
        height: 100px;
    }
    @media (max-width: 385px) {
        transform: translate(-44px, -23px);
        width: 90px;
    }
`;
const Title = styled.h4`
    font-size: 26px;
    margin-bottom: 10px;
    font-family: "gordita_medium";
    @media (max-width: 768px) {
        font-size: 22px;
    }
`;
const Text = styled.p`
    font-size: 18px;
    color: #fff;
    margin-bottom: ${({ type }) => (type === "Update" ? "10px" : "35px")};
    font-family: "gordita_medium";
`;
const InputField = styled.div`
    position: relative;
    margin-bottom: 20px !important;
    background: #161619;
    border-radius: 5px;
    margin-left: 0;
    width: 100%;
    border: 1px solid #3c3c3c;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
        height: 40px;
        width: 80%;
        color: #fff;
    }
    img {
        transition: 0.5s ease;
        cursor: pointer;
        &.active {
            transform: rotate(-180deg);
            transition: 0.5s ease;
        }
    }
    transition: 3s ease;
`;
const ImageContainer = styled.div`
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const DropDown = styled.div`
    max-height: 250px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    top: 44px;
    left: 0;
    background: #161619;
    transition: 3s ease;
    overflow: scroll;
    :nth-child(2n) {
        background: #fff;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    span {
        cursor: pointer;
        text-align: left;
        width: 100%;
        padding: 10px;
        font-size: 14px;
        :nth-child(2n) {
            background: #1b1c1f;
        }
    }
`;
const Close = styled.span`
    cursor: pointer;
    width: 18px;
    position: absolute;
    top: 20px;
    right: 20px;
    @media all and (max-width: 640px) {
        width: 15px;
    }
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const Button = styled.div`
    font-size: 14px;
    font-family: "gordita_medium";
    background-color: ${(props) =>
        props.type === "failed" ? "#161619" : "#5dbc88"};
    height: 40px;
    width: ${(props) => (props.type === "success" ? "100%" : "120px")};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-right: ${({ type }) => (type === "update" ? "20px" : "0px")};
    &.not-allowed {
        cursor: not-allowed;
    }
    font-family: "gordita_medium";
    border: 1px solid
        ${(props) => (props.type === "failed" ? "#f9003a" : "#5dbc88")};
    color: ${(props) => (props.type === "failed" ? "#f9003a" : "white")};
    @media (max-width: 440px) {
        width: ${(props) => (props.type === "success" ? "100%" : "100px")};
    }
    &.reject {
        background: #fe673a;
        border: 1px solid #fe673a;
    }
`;
const Circle = styled.div`
    background-color: #1b1c1f;
    padding: 10px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    top: -70px;
    left: 150px;
    transform: translate(40px, 0);
    border-top: 1px solid #3c3c3c;
    @media (max-width: 560px) {
        transform: translate(-10px, 0);
        width: 110px;
        height: 110px;
    }
    @media (max-width: 440px) {
        width: 90px;
        height: 90px;
        top: -40px;
    }
    @media (max-width: 385px) {
        width: 80px;
        height: 80px;
        top: -40px;
        transform: translate(-40px, -6px);
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ type }) =>
        type === "update" ? "flex-end" : "space-between"};
    padding: ${({ type }) => (type === "update" ? "0px" : "0 60px")};
    @media (max-width: 560px) {
        padding: 0 20px;
    }
    @media (max-width: 440px) {
        padding: 0 40px;
    }
    @media (max-width: 385px) {
        padding: 0 10px;
    }
`;
