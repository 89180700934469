import React, { useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Context } from "../../../contexts/Store";

function ProjectManagerRoute({ children }) {
    const { state } = useContext(Context);
    const role = state.user_data.role;

    const [searchParams] = useSearchParams();
    const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";

    return role.includes("project_manager") ||
        role.includes("students_relations_officer") ? (
        children
    ) : (
        <Navigate to="/auth/login/" />
    );
}

export default ProjectManagerRoute;
