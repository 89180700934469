import styled from "styled-components";
import React, { useEffect, useContext, useState, useRef } from "react";
import {
    managersConfig,
    accountsConfig,
    learnConfig,
} from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
//Components
import SteypAdminHelmet from "../../helpers/SteypAdminHelmet";
import SectionLoader from "../../helpers/SectionLoader";
import ReactPaginate from "react-paginate";
import { useNavigate, useSearchParams } from "react-router-dom";
import AepNewFilter from "../../includes/aep/AepNewFilter";
import BdmCollageStudentCard from "./BdmCollegeStudentCard";
import BdmStudentMenuTab from "./BdmStudentMenuTab";

export default function BdmCollegeStudents() {
    // schoolStudents list api
    const [schoolStudents, setSchoolStudents] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isContinue, setContinue] = useState(false);
    const [renderReload, setRenderReload] = useState(false);
    const [reload, setReload] = useState(false);

    // access token
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    //pagination
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isPageLoading, setPageLoading] = useState(false);

    // eligibility
    const [eligibiltyId, setEligibilityId] = useState();
    const [category, setCategory] = useState("");
    const [studentSatId, setStudentSatId] = useState();
    const [studentClass, setStudentClass] = useState();
    const [isEnableAccounctLoading, setEnableAccounctLoading] = useState(false);

    // account activation
    const [isAccountActivatedLoading, setAccountActivatedLoading] =
        useState(false);

    // create account
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [clickTrue, setClickTrue] = useState(false);

    const [clickedId, setClickedId] = useState("");
    const [isCreateLoding, setCreateLoding] = useState(false);

    //search params
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    //filter
    const [activeClick, setActiveClick] = useState("Filter");
    const [isDropdown, setDropdown] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [accountStatus, setAccountStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [subscriptionDay, setSubscriptionDay] = useState("");
    const [eligibility, setEligibility] = useState("");
    const [dateType, setDateType] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));

        searchParams.get("account_status") &&
            (filterParams.account_status = searchParams.get("account_status"));
        searchParams.get("payment_status") &&
            (filterParams.payment_status = searchParams.get("payment_status"));
        searchParams.get("activated_date") &&
            (filterParams.activated_date = searchParams.get("activated_date"));
        searchParams.get("eligible_status") &&
            (filterParams.eligible_status =
                searchParams.get("eligible_status"));
        searchParams.get("start_date") &&
            (filterParams.start_date = searchParams.get("start_date"));
        searchParams.get("end_date") &&
            (filterParams.end_date = searchParams.get("end_date"));
        searchParams.get("date_type") &&
            (filterParams.date_type = searchParams.get("date_type"));

        return filterParams;
    };

    //student list api
    useEffect(() => {
        const fetchStudents = () => {
            setPageLoading(true);
            const params = getFilterParams();
            managersConfig
                .get(`/leads/bdm/leads/?category=tech_degree`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: params,
                })
                .then((response) => {
                    let { StatusCode, data, pagination_data } = response.data;
                    if (StatusCode === 6000) {
                        setSchoolStudents(data);
                        setPagination(pagination_data);
                        setPageLoading(false);
                        setLoading(false);
                        setReload(false);
                    } else {
                        setPageLoading(false);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setPageLoading(false);
                });
        };
        fetchStudents();
    }, [isContinue, searchParams, reload, clickTrue]);

    const [isButton, setButton] = useState(false);
    const wrapperRef = useRef(null);

    //student list eligibility update api
    useEffect(() => {
        const updateEligibility = () => {
            setEnableAccounctLoading(true);
            accountsConfig
                .get(
                    `api/v1/users/aep/update-student-eligibilty/${eligibiltyId}/?student_sat_id=${studentSatId}&category=college&student_class=${studentClass}
                `,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    let { StatusCode, data, pagination_data } = response.data;
                    if (StatusCode === 6000) {
                        setPageLoading(false);
                        setLoading(false);
                        setEnableAccounctLoading(false);
                        setClickTrue(!clickTrue);
                    } else {
                        setPageLoading(false);
                        setEnableAccounctLoading(false);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setEnableAccounctLoading(false);
                });
        };
        eligibiltyId && updateEligibility();
    }, [eligibiltyId, studentSatId, category, studentClass]);

    const navigate = useNavigate();
    // create account api
    const createAccount = () => {
        setPageLoading(true);
        accountsConfig
            .post(
                `api/v1/users/aep/create-student/`,
                {
                    name: userName,
                    password: password,
                    phone: phoneNumber,
                    country: "IN",
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setPagination(pagination_data);
                    setPageLoading(false);
                    setLoading(false);
                    setUserName("");
                    setPassword("");
                    setPhoneNumber("");
                    setClickTrue(!clickTrue);
                    setEnableAccounctLoading(false);
                } else {
                    setPageLoading(false);
                    setLoading(false);
                    setUserName("");
                    setPassword("");
                    setPhoneNumber("");
                    setEnableAccounctLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setPageLoading(false);
                setEnableAccounctLoading(false);
            });
    };

    // account activation
    const activateAccount = (id) => {
        setAccountActivatedLoading(true);
        const formData = new FormData();
        formData.append("profile_pk", id);
        formData.append("payment_type", "bdm_payment");

        learnConfig
            .post(`/subscriptions/aep/start-subscription/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { data, StatusCode } = response.data;
                if (StatusCode === 6001) {
                    if (data.data.payment_link) {
                        window.open(data.data.payment_link);
                    }
                    setLoading(false);
                    setAccountActivatedLoading(false);
                    setClickedId("");
                } else {
                    setLoading(false);
                    setAccountActivatedLoading(false);
                    setClickedId("");
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setAccountActivatedLoading(false);
                setClickedId("");
                setPageLoading(false);
            });
    };

    // outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDropdown(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    return (
        <MainSection id="main">
            <SteypAdminHelmet title="Tech Degree" />
            <MainCover>
                <InnerCover>
                    <FilterContainer>
                        <TextArea>Leads</TextArea>
                        <RightContainer>
                            <AepNewFilter
                                activeClick={activeClick}
                                isDropdown={isDropdown}
                                setDropdown={setDropdown}
                                setActiveClick={setActiveClick}
                                setButton={setButton}
                                isButton={isButton}
                                searchWord={searchWord}
                                setSearchWord={setSearchWord}
                                wrapperRef={wrapperRef}
                                isContinue={isContinue}
                                accountStatus={accountStatus}
                                setAccountStatus={setAccountStatus}
                                paymentStatus={paymentStatus}
                                setPaymentStatus={setPaymentStatus}
                                subscriptionDay={subscriptionDay}
                                setSubscriptionDay={setSubscriptionDay}
                                eligibility={eligibility}
                                setEligibility={setEligibility}
                                dateType={dateType}
                                setDateType={setDateType}
                                fromDate={fromDate}
                                setFromDate={setFromDate}
                                toDate={toDate}
                                setToDate={setToDate}
                            />
                        </RightContainer>
                    </FilterContainer>
                    <BdmStudentMenuTab />
                    <Diversion>
                        <MainContainer>
                            <Cover>
                                <Category>
                                    <Item type="slno">Sl No.</Item>
                                    <Item>Name</Item>
                                    <Item type="dist">Email</Item>
                                    <Item type="dist">Gender</Item>
                                    <Item type="dist">Qualification</Item>
                                    <Item>Collage Name</Item>
                                    <Item type="dist">AEP Name</Item>
                                    <Item type="dist">Localbody</Item>
                                    <Item type="slno">Year</Item>
                                    <Item type="dist">Resume</Item>
                                    <Item type="dist">Action</Item>
                                </Category>
                                {isPageLoading ? (
                                    <SectionLoader />
                                ) : schoolStudents.length > 0 ? (
                                    <>
                                        {schoolStudents.map((item, index) => (
                                            <BdmCollageStudentCard
                                                key={item.id}
                                                student={item}
                                                pagination={pagination}
                                                isPageLoading={isPageLoading}
                                                index={index}
                                                studentId={item.student_sat_id}
                                                isContinue={isContinue}
                                                setContinue={setContinue}
                                                setCategory={setCategory}
                                                setStudentSatId={
                                                    setStudentSatId
                                                }
                                                setEligibilityId={
                                                    setEligibilityId
                                                }
                                                setPhoneNumber={setPhoneNumber}
                                                setUserName={setUserName}
                                                setPassword={setPassword}
                                                phoneNumber={phoneNumber}
                                                userName={userName}
                                                password={password}
                                                createAccount={createAccount}
                                                activateAccount={
                                                    activateAccount
                                                }
                                                clickTrue={clickTrue}
                                                setClsetCreateLeedickTrue={
                                                    setClickTrue
                                                }
                                                isAccountActivatedLoading={
                                                    isAccountActivatedLoading
                                                }
                                                setAccountActivatedLoading={
                                                    setAccountActivatedLoading
                                                }
                                                setStudentClass={
                                                    setStudentClass
                                                }
                                                clickedId={clickedId}
                                                setClickedId={setClickedId}
                                                isEnableAccounctLoading={
                                                    isEnableAccounctLoading
                                                }
                                                setEnableAccounctLoading={
                                                    setEnableAccounctLoading
                                                }
                                                isCreateLoding={isCreateLoding}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <ImageBox>
                                        <CardImage
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                                            alt="Image"
                                        />
                                        <p>No data found</p>
                                    </ImageBox>
                                )}
                            </Cover>
                        </MainContainer>
                        {pagination?.total_pages > 1 &&
                            schoolStudents?.length > 0 && (
                                <PaginationContainer>
                                    <PaginationText>
                                        Showing College Students{" "}
                                        {pagination?.first_item} -{" "}
                                        {pagination?.last_item} of{" "}
                                        {pagination?.total_items}
                                    </PaginationText>
                                    <ReactPaginate
                                        previousLabel={"< Prev"}
                                        nextLabel={"Next >"}
                                        pageCount={pagination?.total_pages}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={
                                            "pagination__link"
                                        }
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={
                                            "pagination__link--disabled"
                                        }
                                        activeClassName={
                                            "pagination__link--active"
                                        }
                                    />
                                </PaginationContainer>
                            )}
                    </Diversion>
                </InnerCover>
            </MainCover>
        </MainSection>
    );
}

const Diversion = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 187px);
`;
const MainSection = styled.div``;
const MainContainer = styled.div`
    border: 1px solid #26272a;
    border-radius: 5px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border-radius: 5px;
    border: 1px solid #26272a;
    min-height: 700px;
`;
const MainCover = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const InnerCover = styled.div`
    width: 100%;
`;
const Cover = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1b1c20;
    color: #fff;
    min-width: 1300px;
    .card-container {
        border-bottom: 1px solid #26272a;
        :last-child {
            border-bottom: 0px solid #000;
        }
    }
`;
const Category = styled.div`
    border-bottom: 1px solid #26272a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px;
`;
const Item = styled.li`
    font-size: 14px;
    /* text-align: ${({ type }) => (type === "count" ? "center" : "left")}; */
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "slno" ? "5%" : type === "dist" ? "10%" : "15%"};
    list-style: none;
    &:last-child {
        text-align: center;
    }
    &:nth-child(10),
    :nth-child(9) {
        text-align: center;
    }
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 460px) {
        align-items: center;
    }
`;

const TextArea = styled.div`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 460px) {
        /* margin-bottom: 15px; */
    }
`;

const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Filter = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #3b3b3b;
    padding: 6px 12px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
`;

const FilterText = styled.div`
    margin-right: 8px;
`;
const FIlterImage = styled.div`
    width: 15px;
    & img {
        width: 100%;
        display: block;
    }
`;
const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    margin-top: 170px;
    height: calc(100% - (-50px));
    margin-bottom: 170px;
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;
const CardImage = styled.img`
    width: 100%;
    display: block;
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const RightContainer = styled.div`
    display: flex;
    align-items: center;
`;
const CreateLead = styled.div`
    display: flex;
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #fe673a;
    padding: 6px 12px;
    font-size: 13px;
    color: #fff;
    justify-content: space-between;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    font-family: "gordita_medium";
`;
const ImageContainer = styled.div`
    width: 15%;
    img {
        width: 100%;
        display: block;
    }
`;
const Add = styled.p`
    color: #fff;
    font-size: 13px;
    line-height: 1.2em;
`;
