import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AepPosterStyle from "../../../includes/aep/AepPosterStyle";
import AepScoreboard from "../../../includes/aep/AepScoreboard";
import AepStudents from "../../../includes/aep/AepStudents";
import AepResources from "../../../screens/aep/AepResources";
import AepLeadInnerRouter from "../aep/AepLeadInnerRouter";
import PosterInnerRouter from "../aep/PosterInnerRouter";
// import AepInvoice from "../../../screens/aep/AepInvoice";
//componets
const AepDashboard = lazy(() => import("../../../screens/aep/AepDashboard"));
const AepFinaceReport = lazy(() =>
    import("../../../screens/aep/AepFinaceReport")
);
const AepSettlement = lazy(() => import("../../../screens/aep/AepSettlement"));
const AepSchoolStudent = lazy(() =>
    import("../../../screens/aep/AepSchoolStudent")
);
const AepCollageStudents = lazy(() =>
    import("../../../screens/aep/AepCollageStudents")
);
const AepGraduateStudents = lazy(() =>
    import("../../../screens/aep/AepGraduateStudents")
);
const AepAdmission = lazy(() => import("../../../screens/aep/AepAdmission"));
const Training = lazy(() => import("../../../screens/aep/Training"));
const AepMeterialDashboard = lazy(() =>
    import("../../../screens/aep/AepMeterialDashboard")
);
const AepTraining = lazy(() => import("../../../screens/aep/AepTraining"));
const AepInvoice = lazy(() => import("../../../screens/aep/AepInvoice"));

function SatCenterRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<AepDashboard />} />
            <Route path="/school-students/" element={<AepSchoolStudent />} />
            <Route path="/collage-students/" element={<AepCollageStudents />} />
            <Route
                path="/graduate-students/"
                element={<AepGraduateStudents />}
            />

            <Route
                path="student/:student_sat_id/scoreboard"
                element={<AepScoreboard />}
            />
            <Route path="leads/applicants/*" element={<AepLeadInnerRouter />} />
            <Route path="resources" element={<AepResources />} />
            <Route path="financereport" element={<AepFinaceReport />} />
            <Route path="settlement" element={<AepSettlement />} />
            <Route path="students" element={<AepStudents />} />
            <Route path="admission" element={<AepAdmission />} />
            <Route path="training" element={<Training />} />
            <Route path="Materials" element={<AepMeterialDashboard />} />
            {/* <Route path="training" element={<AepTraining />} /> */}
            <Route
                path="school-students/invoice/:student_id"
                element={<AepInvoice />}
            />
            <Route path="posters/*" element={<PosterInnerRouter />} />
        </Routes>
    );
}

export default SatCenterRouter;
