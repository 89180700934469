import React from "react";
import styled from "styled-components";

export default function ChatBox() {
    return (
        <>
            {/* <SteypHelmet title="Dashboard" /> */}
            <MainContainer>
                <ImageContainer>
                    <Image
                        src={
                            require("../../../assets/images/chat/chat.svg")
                                .default
                        }
                        alt="Icon"
                    />
                </ImageContainer>
                <Start>Start Chat Now</Start>
                <p>
                    You can chat with our students and solve all students facing
                    issue
                </p>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1b1c1f;
    width: 100%;
    height: calc(100vh - 87px);
    p {
        width: 38%;
        text-align: center;
        line-height: 1.4em;
        font-size: 0.9rem;
        margin-top: 10px;
        opacity: 0.7;
        color: #fff;
    }
`;
const Start = styled.div`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 1.2rem;
`;

const ImageContainer = styled.div`
    width: 10%;
    margin-bottom: 8px;
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
