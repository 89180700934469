import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { Link, useSearchParams } from "react-router-dom";
import { learnConfig } from "../../../axiosConfig";
import { error } from "jquery";
import { Context } from "../../contexts/Store";
import Moment from "react-moment";
import NoData from "../jobdesk/NoData";
import SectionLoader from "../../helpers/SectionLoader";
import SteypHelmet from "../../helpers/SteypHelmet";
import TechUpdatePopupModal from "./modal/TechUpdatePopupModal";

function TechUpdatesEditor() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);
    const currentParams = Object.fromEntries([...searchParams]);
    const [articles, setArticles] = useState([]);
    const [isPageLoading, setPageLoading] = useState(false);
    const [pagination, setPagination] = useState({});

    const {
        state: {
            isView,
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("blog_status") &&
            (filterParams.blog_status = searchParams.get("blog_status"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        searchParams.get("author") &&
            (filterParams.author = searchParams.get("author"));
        searchParams.get("published_date") &&
            (filterParams.published_date = searchParams.get("published_date"));
        searchParams.get("from_date") &&
            (filterParams.from_date = searchParams.get("from_date"));
        searchParams.get("to_date") &&
            (filterParams.to_date = searchParams.get("to_date"));
        searchParams.get("category") &&
            (filterParams.category = searchParams.get("category"));
        return filterParams;
    };

    const articleFetch = () => {
        setPageLoading(true);
        const params = getSearchParams();
        learnConfig
            .get("/tech-updates/all-articles/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                const { data, StatusCode, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setArticles(data);
                    setPageLoading(false);
                    setPagination(pagination_data);
                } else {
                    setArticles([]);
                    setPageLoading(false);
                }
            })
            .catch((err) => {
                console.log("err", err);
                setPageLoading(false);
            });
    };
    // articleFetch();
    useEffect(() => {
        articleFetch();
    }, [searchParams]);

    const changeBlogStatus = (articleid, newStatus) => {
        const formData = new FormData();
        formData.append("status", newStatus);
        learnConfig
            .post(
                `/tech-updates/change-article-status/${articleid}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response, data) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    articleFetch();
                } else {
                    console.error(error);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    function truncateString(str = "", maxLength) {
        if (str?.length <= maxLength) {
            return str;
        }

        return str?.slice(0, maxLength) + "...";
    }

    return (
        <Container>
            <SteypHelmet title="Content-Editor" />
            <ContentContaioner>
                <MainContainer>
                    <Top>
                        <Text type="article">Article</Text>
                        <Text type="category">Category</Text>
                        <Text type="status">Status</Text>
                        <Text type="actions">Actions</Text>
                    </Top>
                    <DataContainer>
                        {isPageLoading ? (
                            <SectionLoader />
                        ) : articles?.length > 0 ? (
                            <>
                                {articles.map((data) => {
                                    return (
                                        <Bottom key={data.id}>
                                            <ArticleSection
                                                onClick={() => {
                                                    if (
                                                        data.blog_status ===
                                                            "in_review" ||
                                                        data.blog_status ===
                                                            "published"
                                                    ) {
                                                        dispatch({
                                                            type: "UPDATE_EDITOR_VIEW",
                                                            isView: true,
                                                        });
                                                    }
                                                }}
                                                to={`/content-editor/update/${data.id}`}
                                            >
                                                <ArticleImg>
                                                    <ImgContainer
                                                        src={
                                                            data.featured_image
                                                        }
                                                    />
                                                </ArticleImg>
                                                <ArtText>
                                                    {truncateString(
                                                        data.title
                                                            ? data.title
                                                            : "",
                                                        78
                                                    )}
                                                </ArtText>
                                            </ArticleSection>
                                            {data.category ? (
                                                <Section type="category">
                                                    <CategoryName>
                                                        {data.category}
                                                    </CategoryName>
                                                </Section>
                                            ) : (
                                                <Section type="category">
                                                    <p>--</p>
                                                </Section>
                                            )}
                                            {/* <Section type="category">
                                    <CategoryName>{data.category}</CategoryName>
                                </Section> */}
                                            {data.blog_status ? (
                                                <Section type="status">
                                                    <StatusUpdate
                                                        status={
                                                            data.blog_status
                                                        }
                                                    >
                                                        {data.blog_status.replace(
                                                            "_",
                                                            ""
                                                        )}
                                                    </StatusUpdate>
                                                    <StatusDate>
                                                        <Moment
                                                            format="D MMM YYYY"
                                                            withTitle
                                                        >
                                                            {data.date_added}
                                                        </Moment>
                                                    </StatusDate>
                                                </Section>
                                            ) : (
                                                <Section type="status">
                                                    <Draft>Draft</Draft>
                                                </Section>
                                            )}
                                            <Section type="actions">
                                                {data?.blog_status ===
                                                    "draft" ||
                                                data?.blog_status ===
                                                    "re_work" ? (
                                                    <ActionsButton
                                                        onClick={() => {
                                                            changeBlogStatus(
                                                                data.id,
                                                                "in_review"
                                                            );
                                                        }}
                                                        type="draft"
                                                    >
                                                        Publish In-review
                                                    </ActionsButton>
                                                ) : null}
                                                {(data.blog_status ===
                                                    "in_review" ||
                                                    data.blog_status ===
                                                        "published") && (
                                                    <ViewButton
                                                        onClick={() => {
                                                            dispatch({
                                                                type: "UPDATE_EDITOR_VIEW",
                                                                isView: true,
                                                            });
                                                        }}
                                                        to={`/content-editor/view/${data.id}`}
                                                    >
                                                        View
                                                    </ViewButton>
                                                )}
                                                {data.blog_status !==
                                                    "published" &&
                                                    data.blog_status !==
                                                        "in_review" && (
                                                        <ActionsEdit
                                                            to={`/content-editor/update/${data.id}`}
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: "UPDATE_EDITOR_VIEW",
                                                                    isView: false,
                                                                });
                                                            }}
                                                        >
                                                            <ImgContainer
                                                                src={
                                                                    require("../../../assets/images/enquires-active.svg")
                                                                        .default
                                                                }
                                                            />
                                                        </ActionsEdit>
                                                    )}
                                            </Section>
                                        </Bottom>
                                    );
                                })}
                            </>
                        ) : (
                            <NoData />
                        )}
                    </DataContainer>
                </MainContainer>
            </ContentContaioner>
            {pagination?.total_pages > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </Container>
    );
}

export default TechUpdatesEditor;
const DataContainer = styled.div`
    min-height: 61vh;
`;
const Container = styled.section`
    padding-top: 22px;
`;
const MainContainer = styled.div`
    min-width: 1280px;
`;

const ContentContaioner = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    border-radius: 8px 8px 0 0;
    background-color: #1b1c1f;
`;

const Text = styled.h3`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_regular";

    width: ${({ type }) =>
        type === "article"
            ? "30%"
            : type === "author"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "status"
            ? "8%"
            : type === "reads"
            ? "5%"
            : type === "actions" && "14%"};
    text-align: ${({ type }) => (type === "actions" ? "right" : "")};
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    background-color: #1b1c1f;
    &:hover {
        background-color: #161619;
    }
`;

const ArticleSection = styled(Link)`
    width: 30%;
    display: flex;
`;

const Section = styled.div`
    width: ${({ type }) =>
        type === "article"
            ? "30%"
            : type === "author"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "status"
            ? "8%"
            : type === "reads"
            ? "5%"
            : type === "actions" && "14%"};
    display: ${({ type }) =>
        type === "article" ||
        type === "actions" ||
        type === "author" ||
        type === "reads"
            ? "flex"
            : ""};
    justify-content: ${({ type }) => (type === "actions" ? "flex-end" : "")};
    align-items: center;
`;

const ArticleImg = styled.div`
    width: 30%;
`;

const ImgContainer = styled.img`
    display: block;
    width: 100%;
`;

const ArtText = styled.p`
    color: #d2caca;
    line-height: 1.6em;
    font-size: 1rem;
    font-family: "gordita_regular" !important;
    margin-left: 10px;
    width: 82%;
    @media all and (max-width: 1380px) {
        font-size: 0.8rem;
    }
`;

const ActionsEdit = styled(Link)`
    border-radius: 6px;
    border: 2px solid #2c2c2c;
    background: #2c2c2c;
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4%;
`;

const CategoryName = styled.div`
    border-radius: 8px;
    background: #2c2c2c;
    padding: 12px 12px;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    @media all and (max-width: 1380px) {
        font-size: 13px;
        padding: 16px 9px;
    }
`;

const StatusUpdate = styled.h4`
    text-transform: capitalize;
    color: ${({ status }) =>
        status === "draft"
            ? "#0c85f6"
            : status === "published"
            ? "#0FA76F"
            : status === "rejected"
            ? "#FF3636"
            : status === "re_work"
            ? "#FF3636"
            : "#fff"};
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 4px;
`;

const StatusDate = styled.small`
    color: #525252;
    font-size: 14px;
    font-family: "gordita_medium";
    @media all and (max-width: 1380px) {
        font-size: 12px;
    }
`;

const ActionsButton = styled.button`
    border-radius: 6px;
    border: 2px solid #fe673a;
    color: #fff;
    font-size: 12px;
    font-family: "gordita_medium";
    display: flex;
    width: 65%;
    height: 44px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
    background: ${({ type }) => (type === "in_review" ? "#161619" : "#FE673A")};
`;
const ViewButton = styled(Link)`
    border-radius: 6px;
    border: 2px solid #fe673a;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    display: flex;
    width: 65%;
    height: 44px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
    background: #161619;
`;

const Draft = styled.h1`
    color: #0c85f6;
    font-size: 14px;
    font-family: "gordita_medium";
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
