import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function CreateAccount({
    isCreated,
    setCreated,
    setPassword,
    password,
    createAccount,
    isCreateLoding,
    student,
    checkMessage,
}) {
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [error, setError] = useState(false);

    //modal outside click
    useEffect(() => {
        if (isCreated) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isCreated]);

    //function for ouside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setCreated(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    //function to convert sentence into sentence case
    const getSentenceCase = (str) => {
        const sentenceCase = str.charAt(0).toUpperCase() + str.slice(1);
        return sentenceCase;
    };

    // function to create account

    return (
        <BackContainer>
            {isCreated ? (
                <Overlay
                    onClick={() => setCreated(false)}
                    style={{ display: isCreated ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isCreated && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <ContainerTool>
                        <Container>
                            <div>
                                <User>
                                    <img
                                        src={
                                            require("../../../../assets/images/aep/user-white.svg")
                                                .default
                                        }
                                        alt=""
                                    />
                                </User>
                                <Title>Create Student</Title>
                            </div>
                            <Close onClick={() => setCreated(false)}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/close.svg"
                                    alt="Close"
                                />
                            </Close>
                        </Container>
                        <hr />
                        <UserDetails>
                            <span>Phone Number</span>
                            <Phone>
                                <PhoneNumber>
                                    <PhoneLogo>
                                        <img
                                            src={
                                                require("../../../../assets/images/aep/phone.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </PhoneLogo>
                                    <TextBox>
                                        <small> +91 {""}</small>
                                        <input
                                            type="number"
                                            defaultValue={student.phone}
                                            required
                                            autoComplete="off"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    </TextBox>
                                </PhoneNumber>
                            </Phone>
                            <span>Full Name</span>
                            <Name>
                                <input
                                    type="text"
                                    defaultValue={student.name}
                                    autoComplete="new-password"
                                    required
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </Name>
                            <span>Password</span>
                            <Password>
                                <input
                                    type={isRevealPwd ? "text" : "password"}
                                    placeholder="Enter Password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    required
                                    maxLength={25}
                                />
                                <div
                                    onClick={() =>
                                        setIsRevealPwd(
                                            (prevState) => !prevState
                                        )
                                    }
                                >
                                    {isRevealPwd ? (
                                        <img
                                            src={
                                                require("../../../../assets/images/aep/eye-close.svg")
                                                    .default
                                            }
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={
                                                require("../../../../assets/images/aep/eye-open.svg")
                                                    .default
                                            }
                                            alt=""
                                        />
                                    )}
                                </div>
                                {password.length < 0 && error ? (
                                    <Error>This is a required field</Error>
                                ) : (
                                    ""
                                )}
                            </Password>
                            {password && (
                                <CheckPassword>
                                    <Item
                                        className={
                                            password.length > 7 ? "length" : ""
                                        }
                                    >
                                        <ImageOne
                                            className={
                                                password.length > 7
                                                    ? "length"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/remove.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageOne>
                                        <ImageTwo
                                            className={
                                                password.length > 7
                                                    ? "length"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/active.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageTwo>
                                        <span>
                                            Should contain at least 8 characters
                                        </span>
                                    </Item>
                                    <Item
                                        className={
                                            password.match(/[a-z]/)
                                                ? "small"
                                                : ""
                                        }
                                    >
                                        <ImageOne
                                            className={
                                                password.match(/[a-z]/)
                                                    ? "small"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/remove.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageOne>
                                        <ImageTwo
                                            className={
                                                password.match(/[a-z]/)
                                                    ? "small"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/active.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageTwo>
                                        <span>
                                            Should contain a lowercase (small)
                                            letter (a -z)
                                        </span>
                                    </Item>
                                    <Item
                                        className={
                                            password.match(/[A-Z]/)
                                                ? "capital"
                                                : ""
                                        }
                                    >
                                        <ImageOne
                                            className={
                                                password.match(/[A-Z]/)
                                                    ? "capital"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/remove.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageOne>
                                        <ImageTwo
                                            className={
                                                password.match(/[A-Z]/)
                                                    ? "capital"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/active.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageTwo>
                                        <span>
                                            Should contain a uppercase (capital)
                                            letter (A - Z)
                                        </span>
                                    </Item>
                                    <Item
                                        className={
                                            password.match(/[0-9]/)
                                                ? "number"
                                                : ""
                                        }
                                    >
                                        <ImageOne
                                            className={
                                                password.match(/[0-9]/)
                                                    ? "number"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/remove.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageOne>
                                        <ImageTwo
                                            className={
                                                password.match(/[0-9]/)
                                                    ? "number"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/active.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageTwo>
                                        <span>
                                            Should contain at least one number
                                            (0-9)
                                        </span>
                                    </Item>
                                    <Item
                                        className={
                                            password.match(/[$,@,#,!,*,?,&]/)
                                                ? "symbol"
                                                : ""
                                        }
                                    >
                                        <ImageOne
                                            className={
                                                password.match(
                                                    /[$,@,#,!,*,?,&]/
                                                )
                                                    ? "symbol"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/remove.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageOne>
                                        <ImageTwo
                                            className={
                                                password.match(
                                                    /[$,@,#,!,*,?,&]/
                                                )
                                                    ? "symbol"
                                                    : ""
                                            }
                                        >
                                            <img
                                                src={
                                                    require("../../../../assets/images/aep/active.svg")
                                                        .default
                                                }
                                                alt="Tick"
                                            />
                                        </ImageTwo>
                                        <span>
                                            Should contain at least one symbol
                                            ($,@,#,%,!,*,?,&)
                                        </span>
                                    </Item>
                                </CheckPassword>
                            )}
                        </UserDetails>
                        {password.length > 7 &&
                        password.match(/[a-z]/) &&
                        password.match(/[A-Z]/) &&
                        password.match(/[0-9]/) &&
                        password.match(/[$,@,#,!,*,?,&]/) ? (
                            <CreateButtonBox onClick={() => createAccount()}>
                                <CreateButton>
                                    {isCreateLoding ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Create"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        ) : (
                            <CreateButtonBox className="disabled">
                                <CreateButton>Create</CreateButton>
                            </CreateButtonBox>
                        )}
                        <p>{checkMessage}</p>
                    </ContainerTool>
                </Modal>
            </Cover>
        </BackContainer>
    );
}
export default CreateAccount;

const BackContainer = styled.div``;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    max-height: 100vh;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    box-shadow: 0px 3px 56px #00000048;
    ::-webkit-scrollbar {
        display: none;
    }
    overflow-y: scroll;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;

const ContainerTool = styled.div`
    background: #1b1c20;
    color: white;
    border: 1px solid #26272a;
    border-radius: 5px;
    box-shadow: 14px 25px 75px -18px rgba(0, 0, 0, 0.75);
    padding: 50px;
    hr {
        height: 2px;
        background-color: #26272a;
        margin: 20px 0;
    }
    @media all and (max-width: 480px) {
        padding: 50px 30px;
    }
    p {
        color: #b00020;
        font-family: "gordita_regular";
        font-size: 14px;
    }
`;

const Title = styled.span`
    color: #fff;
    display: inline-block;
    padding-top: 5px;
    font-family: "gordita_medium";
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        display: flex;
        align-items: center;
    }
`;
const User = styled.div`
    margin-right: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
const Close = styled.div`
    cursor: pointer;
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 14px;
    }
`;
const TextBox = styled.div`
    width: 100%;
    small {
        display: inline-block;
        font-family: "gordita_medium";
        position: absolute;
        font-size: 16px;
        left: 48px;
        bottom: 11px;
    }
    input {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        background: #161619;
        color: #fff;
        font-family: "gordita_medium";
        border: 1px solid #333333;
        border-radius: 4px;
        padding: 10px 10px 10px 84px;
        &:focus {
            border: 1px solid #fe673a;
        }
        &::placeholder {
            font-family: "gordita_regular";
        }
    }
    @media all and (max-width: 360px) {
        input,
        small {
            font-size: 14px;
        }
    }
`;
const UserDetails = styled.div`
    span {
        font-family: "gordita_medium";
        display: inline-block;
        font-size: 14px;
        margin-bottom: 15px;
    }
`;
const Phone = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
const Country = styled.div`
    display: flex;
    align-items: center;
    div {
        border-radius: 50%;
        overflow: hidden;
        width: 57%;
        margin-right: 5px;

        img {
            width: 100%;
            display: block;
        }
    }
    span {
        display: inline-block;
        margin-bottom: 0;
        img {
            width: 100%;
            display: block;
        }
    }
`;
const PhoneNumber = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
`;
const PhoneLogo = styled.div`
    position: absolute;
    margin-right: 20px;
    left: 24px;
    bottom: 15px;
    width: 15px;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 360px) {
        bottom: 10px;
    }
`;
const Name = styled.div`
    margin-bottom: 15px;
    input {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        background: #161619;
        color: #fff;
        font-family: "gordita_medium";
        border: 1px solid #333333;
        border-radius: 4px;
        padding: 10px 10px 10px 25px;
        &::placeholder {
            font-family: "gordita_regular";
        }
        &:focus {
            border: 1px solid #fe673a;
        }
    }
    @media all and (max-width: 360px) {
        input {
            font-size: 14px;
        }
    }
`;
const Password = styled.div`
    position: relative;
    margin-bottom: 20px;
    div {
        cursor: pointer;
    }
    input {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        background: #161619;
        color: #fff;
        font-family: "gordita_medium";
        border: 1px solid #333333;
        border-radius: 4px;
        padding: 10px 45px 10px 25px;
        &::placeholder {
            font-family: "gordita_regular";
        }
        &:focus {
            border: 1px solid #fe673a;
        }
    }
    div {
        position: absolute;
        top: 10px;
        right: 14px;
        img {
            width: 100%;
            display: block;
            filter: invert(48%) brightness(118%);
        }
    }
    @media all and (max-width: 360px) {
        input {
            font-size: 14px;
        }
    }
`;
const CreateButton = styled.span`
    width: 100%;
    display: flex;
    height: 100%;
    line-height: 1.2em;
    align-items: center;
    justify-content: center;
`;
const CheckPassword = styled.div`
    margin-bottom: 20px;
`;
const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media all and (max-width: 360px) {
        align-items: flex-start;
    }
    &.length,
    &.symbol,
    &.number,
    &.small,
    &.capital {
        span {
            color: #e9e9e9;
        }
    }
    div {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    span {
        color: #bcbcbc;
        display: inline-block;
        margin-bottom: 0;
        width: 80%;

        font-size: 12px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
const CreateButtonBox = styled.div`
    display: block;
    width: 40%;
    margin: 0 0 0px auto;
    height: 44px;
    font-family: "gordita_medium";
    background-color: #fe673a;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
    }
    @media all and (max-width: 360px) {
        width: 45%;
        height: 37px;
        font-size: 15px;
    }
`;
const ImageOne = styled.div`
    display: block;
    &.length,
    &.symbol,
    &.number,
    &.small,
    &.capital {
        display: none;
    }
`;
const ImageTwo = styled.div`
    display: none;
    &.length,
    &.symbol,
    &.number,
    &.small,
    &.capital {
        display: block;
    }
`;
const Error = styled.p`
    margin-top: 10px;
    color: red;
    font-size: 13px;
`;
