import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import styled from "styled-components";
import moment from "moment/moment";
import Moment from "moment";
import $ from "jquery";
import ReactPaginate from "react-paginate";

const formatTimeAgo = (dateAdded) => {
    const now = moment();
    const added = moment(dateAdded);
    const diffMinutes = now.diff(added, "minutes");

    if (diffMinutes < 1) {
        return "Just now";
    } else if (diffMinutes < 20) {
        return `${diffMinutes} mins ago`;
    } else {
        return added.format("MMM DD");
    }
};

function ProjectCommitModal({
    modal,
    setModal,
    activitiData,
    pagination,
    setPageNumber,
}) {
    const [currentPage, setCurrentPage] = useState(0);
    const [copied, setCopied] = useState(false);
    const [selectedCommit, setSelectedCommit] = useState("");
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setPageNumber(selectedPage + 1);
    };

    const groupedActivities = Object.entries(
        activitiData?.reduce((grouped, activity, index) => {
            let date = activity?.commit?.committer?.date;
            date = Moment.utc(date).format("MMM DD, YYYY");

            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(activity);
            return grouped;
        }, {})
    );

    //-----------for blocking background scroll when modal is open-------------
    useEffect(() => {
        if (modal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [modal]);

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }, [copied]);

    function truncate(string, n) {
        return string?.length > n ? string.substr(0, n - 1) + "..." : string;
    }

    return (
        <MainContainer>
            {modal && <Overlay onClick={() => setModal(false)}></Overlay>}
            <ContainerDiv className={modal ? "active" : ""}>
                <ContainerHead>
                    <HeadText>Commit history</HeadText>
                    <HeadIcon onClick={() => setModal(false)}>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-01-2024/x-close.svg "
                            alt="close-icon"
                        />
                    </HeadIcon>
                </ContainerHead>
                <ActivityCards>
                    <Card>
                        <List>
                            {groupedActivities.map(([date, activities]) => (
                                <Item key={date}>
                                    <Date>{date}</Date>
                                    {activities.map((commit, index) => (
                                        <CommitContainer
                                            key={index}
                                            href={commit?.html_url}
                                            target="_blanks"
                                        >
                                            <Icon>
                                                {commit?.committer
                                                    ?.avatar_url ? (
                                                    <Dot
                                                        src={
                                                            commit?.committer
                                                                ?.avatar_url
                                                        }
                                                        alt="avathar"
                                                    />
                                                ) : (
                                                    <Avatar
                                                        name={
                                                            commit?.commit
                                                                ?.committer
                                                                ?.name
                                                                ? commit?.commit
                                                                      ?.committer
                                                                      ?.name
                                                                : "--"
                                                        }
                                                        size="40"
                                                        round
                                                    />
                                                )}
                                            </Icon>
                                            <CommitDiv>
                                                <CommitMessage>
                                                    <span>
                                                        {truncate(
                                                            commit?.commit
                                                                ?.message,
                                                            46
                                                        )}
                                                    </span>
                                                    <span
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            navigator.clipboard.writeText(
                                                                commit?.sha
                                                            );
                                                            setCopied(true);
                                                            setSelectedCommit(
                                                                commit?.sha
                                                            );
                                                        }}
                                                    >
                                                        {commit?.sha?.substr(
                                                            0,
                                                            8
                                                        )}
                                                        <small>
                                                            <img
                                                                src={
                                                                    copied &&
                                                                    commit?.sha ===
                                                                        selectedCommit
                                                                        ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-02-2024/check-tick.svg"
                                                                        : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-02-2024/code.svg"
                                                                }
                                                                alt="copy-icon"
                                                            />
                                                        </small>
                                                    </span>
                                                </CommitMessage>
                                                <CommitDetails>
                                                    <Name>
                                                        {
                                                            commit?.commit
                                                                ?.committer
                                                                ?.name
                                                        }
                                                    </Name>
                                                    <Time>
                                                        {formatTimeAgo(
                                                            commit?.commit
                                                                ?.committer
                                                                ?.date
                                                        )}
                                                    </Time>
                                                </CommitDetails>
                                            </CommitDiv>
                                        </CommitContainer>
                                    ))}
                                </Item>
                            ))}
                        </List>
                    </Card>
                </ActivityCards>
                {pagination?.total_pages > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
            </ContainerDiv>
        </MainContainer>
    );
}

export default ProjectCommitModal;
const MainContainer = styled.div`
    /* padding: 24px; */
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const ContainerDiv = styled.div`
    position: fixed;
    z-index: 1001;
    right: -666px;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 666px;
    max-width: 666px;
    transition: all ease 200ms;
    padding: 24px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        right: 0;
    }
    border-left: 1px solid #2c2c2c;
    background: #1b1c1f;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
`;
const ContainerHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid #2c2c2c;
`;

const HeadText = styled.h4`
    color: #fff;
    font-size: 20px;
    font-family: "gordita_medium";
`;
const HeadIcon = styled.span`
    display: inline-block;
    cursor: pointer;
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;

const ActivityCards = styled.ul`
    margin-top: 20px;
`;
const Card = styled.div``;
const List = styled.ul``;

const Item = styled.li`
    margin-bottom: 10px;
    cursor: pointer;
`;

const Date = styled.p`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_medium";
    padding: 10px 0px;
    border-bottom: 1px solid #475569;
`;
const CommitContainer = styled.a`
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #475569;
    &:last-child {
        border-bottom: none;
    }
`;
const Icon = styled.div`
    margin-right: 10px;
`;
const Dot = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    border-radius: 50%;
`;
const CommitDiv = styled.div`
    width: 100%;
`;
const CommitMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        color: #e2e8f0;
        font-size: 14px;
        font-family: "gordita_medium";
        display: flex;
        align-items: center;

        small {
            display: flex;
            align-items: center;
            width: 20px;
            margin-left: 7px;
            img {
                display: block;
                width: 100%;
            }
        }
    }
`;
const CommitDetails = styled.div`
    display: flex;
    align-items: center;
    margin-top: 6px;
`;
const Name = styled.div`
    color: #cbd5e1;
    font-size: 12px;
    font-family: "gordita_medium";
    margin-right: 6px;
`;
const Time = styled.div`
    color: #cbd5e1;
    font-size: 12px;
    font-family: "gordita_medium";
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    & ul.pagination a {
        width: 34px;
        height: 34px;
    }
    & ul.pagination li.previous a.pagination__link {
        width: 34px;
    }

    @media all and (max-width: 890px) {
        flex-direction: column;
        padding: 0 10px;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
