import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { activityConfig, communityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import ExamCompletedModal from "./modal/ExamCompletedModal";
import ReviewDescriptionDetails from "./modal/ReviewDescriptionDetails";
import QuestionsModal from "./modal/QuestionsModal";
import ExamStartModal from "./modal/ExamStartModal";

function TechSchoolingStudentCard({ student, setExamModal }) {
    // const [isExamModal, setExamModal] = useState(false);
    // const [questions, setQuestions] = useState({});
    // const [questionsLoading, setQuestionsLoading] = useState(false);
    // const [mark, setMark] = useState("");
    // const [totalQuestions, setTotalQuestions] = useState();
    // const [isFeedback, setFeedback] = useState(false);
    // const [number, setNumber] = useState(0);
    // const [description, setDescription] = useState("");
    // const [examPk, setExamPk] = useState("");
    // const [isExamLoading, setExamLoading] = useState(false);
    // const [isReviewed, setReviewed] = useState(false);
    // const [showSummary, setShowSummary] = useState("");
    // const [isStartModal, setStartModal] = useState(false);
    // const {
    //     state: {
    //         user_data: { access_token },
    //         student_data: {},
    //     },
    //     dispatch,
    // } = useContext(Context);
    // const handleLogModal = () => {
    //     setExamModal(!isExamModal);
    // };

    // const studentUpdate = (item) => {
    //     dispatch({
    //         type: "UPDATE_STUDENT_DETAILS",
    //         student_data: {
    //             student_name: item.student_name,
    //             phone: item.student_phone,
    //             student_photo: item.student_photo,
    //         },
    //     });
    // };
    // //----------------------for starting-----------------------------------

    // const startStudentExam = () => {
    //     setExamLoading(true);
    //     activityConfig
    //         .post(
    //             `followups/applicant-student-start-exam/${student.id}/`,
    //             { student_sat_id: student.student_sat_id },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${access_token}`,
    //                 },
    //             }
    //         )
    //         .then((response) => {
    //             let { StatusCode, data, pagination_data } = response.data;
    //             if (StatusCode === 6000) {
    //                 setExamPk(data.exam_pk);
    //                 setExamLoading(false);
    //                 setExamModal(true);
    //                 setStartModal(false);
    //             } else {
    //                 setExamLoading(false);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setExamLoading(false);
    //         });
    // };

    //------------------------------------------------------------------
    //----------------send current questions-------------

    // const fetchExamQuestions = () => {
    //     setQuestionsLoading(true);
    //     communityConfig
    //         .get(`enquiry-exams/send-current-question/`, {
    //             headers: {
    //                 Authorization: `Bearer ${access_token}`,
    //             },
    //             params: { student_sat_id: student.student_sat_id },
    //         })
    //         .then((response) => {
    //             let { StatusCode, data, total_questions, is_summary_pending } =
    //                 response.data;
    //             if (StatusCode === 6000) {
    //                 console.log(data);
    //                 setQuestions(data);
    //                 setQuestionsLoading(false);
    //                 setTotalQuestions(total_questions);
    //                 setMark("");
    //                 setSelectedId("");
    //                 setDescription("");
    //                 setExamModal(true);
    //             } else {
    //                 is_summary_pending &&
    //                     setFeedback(true) &&
    //                     setExamModal(false);
    //                 setQuestionsLoading(false);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setQuestionsLoading(false);
    //         });
    // };
    // ----------------------------------------------------

    // -------while starting or continuing the exam-------------

    const handleExam = (e) => {
        setExamModal(true);
    };
    return (
        <>
            {/* <ReviewDescriptionDetails
                setReviewed={setReviewed}
                isReviewed={isReviewed}
                showSummary={showSummary}
            />
            <ExamStartModal
                isStartModal={isStartModal}
                setStartModal={setStartModal}
                student={student}
                startStudentExam={startStudentExam}
                isExamLoading={isExamLoading}
                studentUpdate={studentUpdate}
            />
            <QuestionsModal
                isExamModal={isExamModal}
                handleLogModal={handleLogModal}
                setExamModal={setExamModal}
                setSuccess={setSuccess}
                number={number}
                setNumber={setNumber}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                description={description}
                setDescription={setDescription}
                student={student}
                examPk={examPk}
                fetchExamQuestions={fetchExamQuestions}
                questions={questions}
                mark={mark}
                totalQuestions={totalQuestions}
                setMark={setMark}
                questionsLoading={questionsLoading}
                setQuestionsLoading={setQuestionsLoading}
                setFeedback={setFeedback}
                isFeedback={isFeedback}
                setContinue={setContinue}
                isContinue={isContinue}
            />
            <ExamCompletedModal
                isSuccess={isSuccess}
                setSuccess={setSuccess}
                number={number}
                setNumber={setNumber}
                student={student}
                setContinue={setContinue}
                isContinue={isContinue}
            /> */}

            <StudentCards>
                <Item type="slno">01</Item>
                <Item>
                    <Name>
                        <p>{student.student_name.toLowerCase()}</p>
                        <span>#{student.student_sat_id}</span>
                    </Name>
                </Item>
                <Item type="dist">
                    {student.student_phone_code} {student.student_phone}
                </Item>
                <Item type="dist">
                    <span>
                        {student.category === "school"
                            ? student.student_class
                            : student.category === "graduates"
                            ? student.student_stream
                            : student.category === "college"
                            ? student.student_semester && student.student_stream
                            : "--"}
                    </span>
                </Item>
                <Item type="dist">
                    {student.district ? student.district : "--"}
                </Item>
                <Item type="dist">{student.sheduled_on}</Item>
                <Item type="dist">{student.assignedby}</Item>

                <Item type="dist">
                    <div className="flex">
                        {student.exam_progress.is_started &&
                        student.exam_progress.is_compleated ? (
                            (student.exam_status === "eligible" && (
                                <span className="status">
                                    Eligible
                                    <br />
                                    {student.exam_score}
                                </span>
                            )) ||
                            (student.exam_status === "not eligible" && (
                                <span className="color">Not eligible</span>
                            ))
                        ) : (
                            <StatusDiv onClick={handleExam}>
                                {student.exam_progress.is_started &&
                                !student.exam_progress.is_compleated
                                    ? "Continue Exam"
                                    : "start Exam"}
                            </StatusDiv>
                        )}
                        {student.exam_progress.is_started &&
                            student.exam_progress.is_compleated &&
                            (student.exam_status === "eligible" ||
                            student.exam_status === "not eligible" ? (
                                <img
                                    src={
                                        require("../../../assets/images/tooltip.svg")
                                            .default
                                    }
                                />
                            ) : null)}
                    </div>
                </Item>
                <Item type="slno">
                    <img
                        src={
                            require("../../../assets/images/examiner/calender.svg")
                                .default
                        }
                        alt=""
                    />
                </Item>
            </StudentCards>
        </>
    );
}

export default TechSchoolingStudentCard;

const StudentCards = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #26272a;
    padding: 20px;
    color: #fff;
    // cursor: pointer;
    &.active {
        background: #121214;
    }
    :last-child {
        border-bottom: 0px solid #000;
    }
`;
const ProfileWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const ProfileImage = styled.div`
    margin-right: 13px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    font-family: "gordita_bold";
    border-radius: 50%;
    font-size: 18px;
    min-width: 40px;
`;
const NameSpan = styled.span`
    width: 40px;
    height: 40px;

    img {
        width: 100%;
        display: block;
    }
`;
const Name = styled.span`
    text-transform: capitalize;
    width: 70%;
    p {
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    p::first-letter {
        text-transform: capitalize;
    }
    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    p {
        text-decoration: underline;
    }
`;

const ImageContainer = styled.div`
    width: 25px;
    margin-left: 10px;

    cursor: pointer;
    &img {
        width: 100%;
        display: block;
    }
`;
const Bottom = styled.div`
    font-size: 14px;
`;
const ItemDiv = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: capitalize;
    width: 10%;
    color: ${({ type }) =>
        type === "not_eligible"
            ? "#ef3d3d"
            : type === "eligible"
            ? "#44c082"
            : "#fff"};
    &.eligible {
        color: #44c082;
    }
    &.not_eligible {
        color: #ef3d3d;
    }
`;
const Category = styled.span`
    margin-bottom: 2px;
`;
const Item = styled.li`
    list-style: none;
    font-size: 14px;
    text-align: ${({ type }) => (type === "count" ? "center" : "left")};
    &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 2px;
        :last-child {
            margin-bottom: 0;
        }
        &.flex {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }

    width: ${({ type }) =>
        type === "slno" ? "5%" : type === "dist" ? "10%" : "15%"};

    color: ${({ type }) =>
        type === "not_eligible"
            ? "#ef3d3d"
            : type === "eligible"
            ? "#44c082"
            : "#fff"};
    text-transform: capitalize;

    span {
        text-transform: Capitalize;
        &.color {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #fe673a;
        }
        &.status {
            text-transform: capitalize;
            color: #44c082;
        }
        &.marks {
            color: #44c082;
        }
    }
    p {
        color: #fff;
        &.review {
            font-size: 14px;
        }
    }
`;
const StatusDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    background: #fe673a;
    border: 1px solid #fe673a;
    height: 40px;
    width: 150px;
    font-family: gordita_medium;
    &.pending {
        border: 1px solid #fe673a;
        height: 40px;
        width: 150px;
        background: #1b1c1f;
    }
    &.completed {
        border: 1px solid transparent;
        background: #1b1c20;
        color: #44c082;
    }
`;
