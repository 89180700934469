import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const SalesExecutiveTechSchoolingStudents = lazy(() =>
    import(
        "../../../screens/sales-executive/SalesExecutiveTechSchoolingStudents"
    )
);
const TechSchoolingStudentsSingle = lazy(() =>
    import("../../../screens/sales-executive/TechSchoolingStudentsSingle")
);
const SalesExecutiveTechSchoolingLeeds = lazy(() =>
    import("../../../screens/sales-executive/SalesExecutiveTechSchoolingLeeds")
);
const SalesExecutiveTechGradStudents = lazy(() =>
    import("../../../screens/sales-executive/SalesExecutiveTechGradStudents")
);
const SalesExecutiveTechGradMyLeeds = lazy(() =>
    import("../../../screens/sales-executive/SalesExecutiveTechGradMyLeeds")
);
const SalesExecutiveTechDegreeStudents = lazy(() =>
    import("../../../screens/sales-executive/SalesExecutiveTechDegreeStudents")
);
const SalesExecutiveTechDegreeMyLeeds = lazy(() =>
    import("../../../screens/sales-executive/SalesExecutiveTechDegreeMyLeeds")
);

function SalesExecutiveRouter() {
    return (
        <Routes>
            <Route
                path="/"
                element={<Navigate to="/tech-schooling/students/" />}
            />
            {/* tech schooling */}
            <Route
                path="/tech-schooling/students/"
                element={<SalesExecutiveTechSchoolingStudents />}
            />
            <Route
                path="/tech-schooling/students/:id"
                element={<TechSchoolingStudentsSingle />}
            />
            {/* tech schooling */}
            {/* <Route
                path="/tech-schooling/students/:id/*"
                element={<SalesExecutiveInnerRouter />}
            /> */}
            <Route
                path="/tech-schooling/my-leads/"
                element={<SalesExecutiveTechSchoolingLeeds />}
            />
            {/* tech grad */}
            <Route
                path="/tech-grad/students/"
                element={<SalesExecutiveTechGradStudents />}
            />
            <Route
                path="/tech-grad/my-leads"
                element={<SalesExecutiveTechGradMyLeeds />}
            />
            {/* tech degree */}
            <Route
                path="/tech-degree/students/"
                element={<SalesExecutiveTechDegreeStudents />}
            />
            <Route
                path="/tech-degree/my-leads/"
                element={<SalesExecutiveTechDegreeMyLeeds />}
            />
            <Route
                path="/tech-degree/my-leads/"
                element={<SalesExecutiveTechDegreeMyLeeds />}
            />
        </Routes>
    );
}

export default SalesExecutiveRouter;
