import React, { useState } from "react";
import styled from "styled-components";

function ProjectStudentList({ studentData }) {
    const [selectItem, setSelectItem] = useState();

    return (
        <MainContainer>
            <Container>
                <AddDiv>
                    <AddLeft>
                        <Image></Image>
                        <Name>{selectItem?.name}</Name>
                        <CloseIcon>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                alt="Icon"
                            />
                        </CloseIcon>
                    </AddLeft>
                    <ArrowIcon>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                            alt="down-arrow"
                        />
                    </ArrowIcon>
                </AddDiv>
                <ListDiv>
                    <SerachBox />
                    <ListContainer>
                        {studentData.map((item, index) => (
                            <Item
                                key={index}
                                onClick={() => {
                                    setSelectItem(item);
                                }}
                            >
                                <Top></Top>
                                <Bottom>
                                    <Left>
                                        <SubName> {item.name}</SubName>

                                        <Sub>{item.program}</Sub>
                                    </Left>
                                    <Right>
                                        <SubName>#{item.user_id}</SubName>

                                        <Sub>{item.phone}</Sub>
                                    </Right>
                                </Bottom>
                            </Item>
                        ))}
                    </ListContainer>
                </ListDiv>
            </Container>
        </MainContainer>
    );
}

export default ProjectStudentList;

const MainContainer = styled.div``;
const Container = styled.div``;
const AddDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #161619;
    border: 1px solid #3d3d3d;
    border-radius: 8px;
    padding: 8px 14px;
    min-height: 45px;
    max-height: 100px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ListDiv = styled.div`
    margin-top: 4px;
    min-height: 240px;
    max-height: 240px;
    background-color: #232427;
    border: 1px solid #3d3d3d;
    border-radius: 8px;
    padding: 4px;
    overflow-y: scroll;
    position: relative;
`;
const AddLeft = styled.div``;
const Image = styled.span``;
const Name = styled.small`
    display: inline-block;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #fff;
`;
const CloseIcon = styled.span`
    display: inline-block;
    width: 10px;
    img {
        display: block;
        width: 100%;
    }
`;

const ArrowIcon = styled.span`
    width: 14px;
    display: inline-block;
    img {
        display: block;
        width: 100%;
    }
`;

const SerachBox = styled.input`
    border: 1px solid #fff;
    width: 90%;
    position: fixed;
`;
const ListContainer = styled.ul``;
const Item = styled.li`
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #fff;
    display: flex;
    align-items: center;
    padding-top: 20px;
`;
const Top = styled.div`
    width: 25px;
    height: 25px;
    border: 1px solid #fff;
    border-radius: 50px;
    margin-right: 6px;
`;
const Bottom = styled.div``;
const Left = styled.div`
    display: flex;
    align-items: center;
`;
const Right = styled.div`
    display: flex;
    align-items: center;
`;
const SubName = styled.h4`
    font-size: 14px;
    font-family: "gordita_medium";
    color: #fff;
        margin-right: 4px;
`;
const Sub = styled.h5`
    font-size: 13px;
    font-family: "gordita_medium";
    color: #fff;
`;
