import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { preventDefault } from "@fullcalendar/react";

function EvaluvationHeader({
    setComplete,
    studentData,
    projectComplete,
    btnStatus,
}) {
    const handleButtonClick = (event, issue_exists, is_completed) => {
        if (!issue_exists) {
            if (!issue_exists && is_completed) {
                projectComplete();
            } else {
                setComplete(true);
            }
        } else {
            event.preventDefault();
        }
        // studentData?.student_project?.is_completed === true
        //     ? projectComplete()
        //     : setComplete(true);
    };
    return (
        <>
            <Container>
                <BackContainer to="/projects/evaluation">
                    <CloseIcon>
                        <Icon
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/arrow-left.svg"
                            alt="Arrow-Icon"
                        />
                    </CloseIcon>
                    <CloseText>Back to all projects</CloseText>
                </BackContainer>

                {btnStatus === "completed" ? (
                    <Button
                        ButtonColor={studentData?.student_project?.is_completed}
                        ButtonActive={
                            studentData?.student_project?.issue_exists
                        }
                        onClick={(event) => {
                            handleButtonClick(
                                event,
                                studentData?.student_project?.issue_exists,
                                studentData?.student_project?.is_completed
                            );
                        }}
                    >
                        {studentData?.student_project?.is_completed === true
                            ? "Remove Evaluation"
                            : "Complete Project"}
                    </Button>
                ) : (
                    <DisButton>
                        {studentData?.student_project?.is_completed === true
                            ? "Project Completed"
                            : "Complete Project"}
                    </DisButton>
                )}
            </Container>
        </>
    );
}

export default EvaluvationHeader;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;
const BackContainer = styled(Link)`
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    cursor: pointer;
`;
const CloseIcon = styled.span`
    display: inline-block;
    width: 20px;
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;
const CloseText = styled.h4`
    font-size: 14px;
    font-family: "gordita_medium";
    color: #fe673a;
`;

const Button = styled.div`
    font-size: 13px;
    font-family: "gordita_medium";
    /* border: 1px solid
        ${({ ButtonColor }) =>
        ButtonColor === false ? "#eaecf0" : "#fe673a"}; */
    border-radius: 5px;
    width: 160px;
    cursor: ${({ ButtonActive }) =>
        ButtonActive === true ? "not-allowed" : "pointer"};
    padding: 8px 14px;
    background: ${({ ButtonColor }) =>
        ButtonColor === false ? "#fe673a" : "#fe673a"};
    color: ${({ ButtonColor }) => (ButtonColor === false ? "#fff" : "#fff")};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DisButton = styled.div`
    font-size: 13px;
    font-family: "gordita_medium";
    border-radius: 5px;
    width: 160px;
    padding: 8px 14px;
    background: #fe673a;
    color: ${({ ButtonColor }) => (ButtonColor === false ? "#fff" : "#fff")};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
    opacity: 0.4;
`;
