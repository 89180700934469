import React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import ProfilePictures from "../../../screens/verifications/ProfilePictures";
import Campus from "../../../screens/verifications/Campus";
import CampusList from "../../../screens/verifications/CampusList";

function VerificationRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route exact path="/" element={<ProfilePictures />} />
                <Route
                    exact
                    path="/profile-pictures/"
                    element={<ProfilePictures />}
                />
                <Route exact path="/campus/" element={<Campus />} />
                <Route exact path="campus-list/" element={<CampusList />} />
            </Routes>
        </Container>
    );
}

export default VerificationRouter;

const Container = styled.div``;
