import React from "react";
import Avatar from "react-avatar";
import styled from "styled-components";

function UserProfile({ image, name }) {
    return (
        <ImgDiv>
            {image ? (
                <img src={image} alt="icon" />
            ) : (
                <Avatar name={name} size="40" round />
            )}
        </ImgDiv>
    );
}

export default UserProfile;

const ImgDiv = styled.div`
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
