import React, { useState } from "react";
import styled from "styled-components";

function SortModal({ isSort, sortingArticles }) {
    const [isSelected, setSelected] = useState("");

    return (
        <Container>
            <List className={isSort && "active"}>
                <Item
                    className={isSelected === "A-Z" && "active"}
                    onClick={() => {
                        setSelected("A-Z");
                        sortingArticles("a-z");
                    }}
                >
                    {isSelected === "A-Z" ? (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting-active.svg")
                                        .default
                                }
                                alt="Sorting Active"
                            />
                        </Round>
                    ) : (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting.svg")
                                        .default
                                }
                                alt="Sorting"
                            />
                        </Round>
                    )}
                    <Text>A-Z</Text>
                </Item>
                <Item
                    className={isSelected === "Z-A" && "active"}
                    onClick={() => {
                        setSelected("Z-A");
                        sortingArticles("z-a");
                    }}
                >
                    {isSelected === "Z-A" ? (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting-active.svg")
                                        .default
                                }
                                alt="Sorting Active"
                            />
                        </Round>
                    ) : (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting.svg")
                                        .default
                                }
                                alt="Sorting"
                            />
                        </Round>
                    )}
                    <Text>Z-A</Text>
                </Item>
                <Item
                    className={isSelected === "Latest" && "active"}
                    onClick={() => {
                        setSelected("Latest");
                        sortingArticles("latest");
                    }}
                >
                    {isSelected === "Latest" ? (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting-active.svg")
                                        .default
                                }
                                alt="Sorting Active"
                            />
                        </Round>
                    ) : (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting.svg")
                                        .default
                                }
                                alt="Sorting"
                            />
                        </Round>
                    )}
                    <Text>Latest</Text>
                </Item>
                <Item
                    className={isSelected === "Earliest" && "active"}
                    onClick={() => {
                        setSelected("Earliest");
                        sortingArticles("oldest");
                    }}
                >
                    {isSelected === "Earliest" ? (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting-active.svg")
                                        .default
                                }
                                alt="Sorting Active"
                            />
                        </Round>
                    ) : (
                        <Round>
                            <img
                                src={
                                    require("../../../../assets/images/sorting.svg")
                                        .default
                                }
                                alt="Sorting"
                            />
                        </Round>
                    )}
                    <Text>Oldest </Text>
                </Item>
            </List>
        </Container>
    );
}

export default SortModal;

const Container = styled.div``;

const List = styled.ul`
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    display: flex;
    width: 302px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 16px;
    top: 133px;
    transition: 120ms ease all;
    opacity: 0;
    visibility: hidden;
    &.active {
        top: 133px;
        opacity: 1;
        right: 16px;
        visibility: visible;
        z-index: 99;
    }
`;

const Item = styled.li`
    display: flex;
    height: 44px;
    width: 268px;
    padding: 6px 10px 6px 12px;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    cursor: pointer;
    &.active {
        border-radius: 6px;
        background: #161619;
        &::before {
            content: "";
            position: absolute;
            border-radius: 0px 6px 6px 0px;
            background: #fe673a;
            width: 3px;
            height: 30px;
            left: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const Round = styled.span`
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    position: relative;
    img {
        width: 100%;
        display: block;
    }
`;

const Text = styled.h4`
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    height: 16px;
`;
