import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
// import StatusModal from "./modal/StatusModal";
import {
    ConvertDate,
    calcTime,
    getDDMMYY,
    getDateStr,
    getTimeStr,
    secondsTohm,
} from "../../helpers/functions";
import ResonModal from "./modal/ResonModal";
import CallStatusModal from "./modal/CallStatusModal";
// import { accountsConfig, activityConfig } from "../../../axiosConfig";
// import { Context } from "../../contexts/Store";
// import StatusLogModal from "./modal/StatusLogModal";
// import SectionLoader from "../../helpers/SectionLoader";
// import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function StatusCard({ student, pagination, index, isAssigned, setAssigned }) {
    const [isPhoneModal, setPhoneModal] = useState(false);

    const [prerantReson, setPerentReson] = useState("");
    const [isModal, setModal] = useState(false);
    const [studentPk, setStudentPk] = useState();
    const [heading, setHeading] = useState("");
    const [showFeedback, setShowFeedback] = useState("");
    function truncate(string, n) {
        return string?.length > n ? string.substr(0, n - 1) + "..." : string;
    }

    const handlePhoneModal = () => setPhoneModal((prev) => !prev);

    const getDateAndTime = (timestamp) => {
        const date = new Date(timestamp);

        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        const istTime = date.toLocaleString("en-US", options);
        return istTime;
    };

    return (
        <>
            <ResonModal
                isModal={isPhoneModal}
                handlePhoneModal={handlePhoneModal}
                prerantReson={prerantReson}
                currentHeading={heading}
            />

            <CallStatusModal
                isModal={isModal}
                setModal={setModal}
                studentPk={studentPk}
                name={student?.student_details?.name}
                phone={student?.student_details?.contact_number}
                feedback={showFeedback}
                status={student?.status}
                setAssigned={setAssigned}
                isAssigned={isAssigned}
            />

            <MainContainer>
                {pagination.first_item <= pagination.last_item && (
                    <Item type="odd">{pagination.first_item + index}</Item>
                )}
                <Item>
                    <Left>
                        <div>
                            <span>
                                {student?.student_details?.name
                                    ? student?.student_details?.name?.charAt(0)
                                    : "--"}
                            </span>
                        </div>
                    </Left>
                    <Right>
                        <span className="name">
                            {student?.student_details?.name
                                ? student?.student_details?.name?.toLowerCase()
                                : "--"}
                        </span>
                        <p>
                            +91{" "}
                            {student?.student_details?.contact_number
                                ? student?.student_details?.contact_number
                                : ""}
                        </p>
                    </Right>
                </Item>

                <Item type="even">
                    {student?.student_details?.category
                        ? student?.student_details?.category
                        : "--"}
                </Item>
                <Item type="even">
                    <InfoImage
                        onClick={() => {
                            handlePhoneModal();
                            setHeading("Reason");
                            setPerentReson(
                                student?.reason ? student?.reason : ""
                            );
                        }}
                    >
                        <img
                            src={
                                require("../../../assets/images/aep/info.svg")
                                    .default
                            }
                            alt="Info"
                        />
                    </InfoImage>

                    {student?.reason ? truncate(student?.reason, 12) : ""}
                </Item>
                <Item type="">
                    {student?.date_added
                        ? getDateAndTime(student?.date_added)
                        : "--"}
                    <br />
                </Item>
                <Item type="even">
                    {student?.feedback ? (
                        <>
                            <InfoImage
                                onClick={() => {
                                    handlePhoneModal();
                                    setHeading("feedback");

                                    setPerentReson(
                                        student?.feedback
                                            ? student?.feedback
                                            : ""
                                    );
                                }}
                            >
                                <img
                                    src={
                                        require("../../../assets/images/aep/info.svg")
                                            .default
                                    }
                                    alt="Info"
                                />
                            </InfoImage>
                            <p>{truncate(student?.feedback, 12)}</p>
                        </>
                    ) : (
                        "--"
                    )}
                </Item>

                {/* <Item type="even"></Item> */}
                <Item type="even">
                    {student?.status === "attended"
                        ? "Attended"
                        : student?.status === "not_attended"
                        ? "Not Attended"
                        : student?.status === "call_later/busy"
                        ? "Call later / Busy"
                        : student?.status === "switched_off"
                        ? "Switched Off"
                        : "No status"}
                </Item>
                <Item type="even">
                    <ActionDiv
                        className={student?.status ? "" : "active"}
                        onClick={(e) => {
                            setShowFeedback(student?.feedback);
                            setStudentPk(student?.pk);
                            setModal(true);
                        }}
                    >
                        {student?.status ? "Update" : "Add status"}
                    </ActionDiv>
                </Item>
            </MainContainer>
        </>
    );
}
export default StatusCard;

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    font-size: 13px;
    border-bottom: 2px solid #161618;
    :last-child {
        border-bottom: 0px solid #000;
    }
    min-width: 1100px;
`;

const Item = styled.div`
    text-transform: capitalize;
    width: ${({ type }) =>
        type === "odd" ? "5%" : type === "even" ? "10%" : "15%"};
    :last-child {
        text-align: center;
    }

    display: ${({ type }) =>
        type === "name" || type === "action" ? "flex" : "flex"};
    align-items: center;
    justify-content: ${({ type }) => (type === "action" ? "center" : "")};
    font-size: 13px;
    color: ${({ status }) =>
        status === "attended"
            ? "#0FA76F"
            : status === "not-attended"
            ? "#C73838"
            : status === "switched-off"
            ? "#C73838"
            : status === "busy"
            ? "#FE673A"
            : ""};
    &.status {
        width: 15%;
    }
    &.phone {
        flex-direction: column;
        align-items: flex-start;
        & div {
            margin-bottom: 4px;
        }
        & small {
            font-size: 12px;
            color: #da5b36;
            font-family: "gordita_medium";
            cursor: pointer;
        }
    }
    p {
        font-size: 13px;
        color: #fff;
    }
`;
const Right = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 13px;
    p {
        font-size: 12px;
        color: #da5b36;
    }

    .id {
        font-size: 12px;
        color: #da5b36;
    }

    span {
        text-transform: capitalize;
    }
`;
const Left = styled.div`
    margin-right: 10px;
    div {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid #da5b36;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        color: #da5b36;
        font-size: 13px;
        font-family: "gordita_medium";
    }
`;

const InfoImage = styled.div`
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const ActionDiv = styled.div`
    width: 120px;
    height: 40px;
    font-size: 13px;
    border: 1px solid #da5b36;
    text-transform: capitalize;
    border-radius: 5px;
    background: ${({ type }) => (type === "attended" ? "#da5b36" : "")};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ type }) => (type === "attended" ? "not-allowed" : "pointer")};
    &.active {
        background-color: #da5b36;
    }
`;
