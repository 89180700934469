// src/Tiptap.jsx

import React, { useCallback, useEffect, useState, useContext } from "react";
import "../../../../assets/css/tiptap.css";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorProvider, useCurrentEditor, Extension } from "@tiptap/react";
import Dropcursor from "@tiptap/extension-dropcursor";
import Image from "@tiptap/extension-image";
import StarterKit from "@tiptap/starter-kit";

import Link from "@tiptap/extension-link";
import Blockquote from "@tiptap/extension-blockquote";
import Underline from "@tiptap/extension-underline";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import CodeBlock from "@tiptap/extension-code-block";
// import { CodeBlockLowlightOptions } from '@tiptap/extension-code-block-lowlight';
// import js from "highlight.js/lib/languages/javascript";

// Use the function to create the `lowlight` instance
// import { lowlight } from "lowlight";
import DropDown from "./_components/DropDown";
import WebLink from "./_components/WebLink";

// const lowlight = createLowlight(common);
// lowlight.register({ html });

import { lowlight } from "lowlight/lib/core.js";
import javascript from "highlight.js/lib/languages/javascript";
// import css from "highlight.js/lib/languages/css";
import html from "highlight.js/lib/languages/xml";
import ts from "highlight.js/lib/languages/typescript";
import python from "highlight.js/lib/languages/python";
import django from "highlight.js/lib/languages/django";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";

lowlight.registerLanguage("javascript", javascript);
// lowlight.registerLanguage('css', css);
lowlight.registerLanguage("typescript", ts);
lowlight.registerLanguage("html", html);
lowlight.registerLanguage("python", python);
lowlight.registerLanguage("django", django);

const Tiptap = ({ placeholder, setGetJson, setDiscriptionData }) => {
    const [webLink, setWebLink] = useState("");
    const [imageBinary, setImageBinary] = useState();

    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    const Menu = () => {
        const { editor } = useCurrentEditor();
        const datas = JSON.stringify(editor.getJSON(), null, 2);
        useEffect(() => {
            setDiscriptionData(datas);
        }, [datas]);

        const uploadImage = (event) => {
            const imageFile = event.target.files[0];
            if (imageFile) {
                setImageBinary(imageFile);
                convertImage(imageFile);
            } else {
                console.error("No file selected.");
            }
        };
        
        // API connection for image conversion
        
        const convertImage = (imageBinary) => {
            const formData = new FormData();
            formData.append("image", imageBinary);
            
            learnConfig
                .post(`/doubt-hub/upload-image/`, formData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { data } = response;
                    const url = data.file.url;
                    editor.chain().focus().setImage({ src: url }).run();
                })
                .catch((error) => {
                    console.error("Error uploading image:", error);
                });
        };
        

        const setLink = useCallback(() => {
            const previousUrl = editor.getAttributes("link").href;
            const url = webLink ? webLink : previousUrl;

            // cancelled
            if (url === null) {
                return;
            }

            // empty
            if (url === "") {
                editor
                    .chain()
                    .focus()
                    .extendMarkRange("link")
                    .unsetLink()
                    .run();

                return;
            }

            // update link
            editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .toggleLink({ href: url })
                .run();
        }, [editor]);
        if (!editor) {
            return null;
        }
        // setGetJson(editor.getJSON())
        return (
            <div className="menu">
                <div className="text-style">
                    <button
                        onClick={() => {
                            editor.chain().focus().toggleBold().run();
                        }}
                        disabled={
                            !editor.can().chain().focus().toggleBold().run()
                        }
                        className={
                            editor.isActive("bold") ? "is-active" : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/b.svg"
                            }
                            alt="Bold"
                        />
                    </button>
                    <button
                        onClick={() =>
                            editor.chain().focus().toggleItalic().run()
                        }
                        disabled={
                            !editor.can().chain().focus().toggleItalic().run()
                        }
                        className={
                            editor.isActive("italic") ? "is-active" : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/i.svg"
                            }
                            alt="Italic"
                        />
                    </button>

                    <button
                        onClick={() =>
                            editor.chain().focus().toggleUnderline().run()
                        }
                        className={
                            editor.isActive("underline") ? "is-active" : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/u.svg"
                            }
                            alt="Underline"
                        />
                    </button>
                    <DropDown editor={editor} />
                    <button
                        onClick={() =>
                            editor.chain().focus().setParagraph().run()
                        }
                        className={
                            editor.isActive("paragraph") ? "is-active" : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/paragraph.svg"
                            }
                            alt="Paragraph"
                        />
                    </button>
                    <button
                        onClick={() =>
                            editor.chain().focus().toggleCodeBlock().run()
                        }
                        className={
                            editor.isActive("codeBlock") ? "is-active" : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/code.svg"
                            }
                            alt="code-block"
                        />
                    </button>
                </div>
                <div className="list-style">
                    <button
                        onClick={() =>
                            editor.chain().focus().setTextAlign("left").run()
                        }
                        className={
                            editor.isActive({ textAlign: "left" })
                                ? "is-active"
                                : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/left.svg"
                            }
                            alt="left-align"
                        />
                    </button>
                    <button
                        onClick={() =>
                            editor.chain().focus().setTextAlign("center").run()
                        }
                        className={
                            editor.isActive({ textAlign: "center" })
                                ? "is-active"
                                : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/center.svg"
                            }
                            alt="center-align"
                        />
                    </button>
                    <button
                        onClick={() =>
                            editor.chain().focus().setTextAlign("right").run()
                        }
                        className={
                            editor.isActive({ textAlign: "right" })
                                ? "is-active"
                                : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/right.svg"
                            }
                            alt="right-align"
                        />
                    </button>
                    <button
                        onClick={() =>
                            editor.chain().focus().toggleBulletList().run()
                        }
                        className={
                            editor.isActive("bulletList") ? "is-active" : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/bullet.svg"
                            }
                            alt="bullet-list"
                        />
                    </button>
                    <button
                        onClick={() =>
                            editor.chain().focus().toggleOrderedList().run()
                        }
                        className={
                            editor.isActive("orderedList")
                                ? "is-active"
                                : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/number.svg"
                            }
                            alt="order-list"
                        />
                    </button>
                    <WebLink
                        editor={editor}
                        setLink={setLink}
                        setWebLink={setWebLink}
                        webLink={webLink}
                    />
                    <button
                        className="icon"
                        onClick={() => {
                            editor.chain().focus().uploadFile().run();
                        }}
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/document.svg"
                            }
                            alt="document"
                        />
                    </button>
                    <div className="image-selector">
                        <label for="files" className="btn-upload">
                            <img
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/image.svg"
                                }
                                alt="image"
                            />
                        </label>
                        <input
                            id="files"
                            style={{ visibility: "hidden", width: 0 }}
                            type="file"
                            onChange={uploadImage}
                        />
                    </div>
                    <button
                        onClick={() =>
                            editor.chain().focus().toggleBlockquote().run()
                        }
                        className={
                            editor.isActive("blockquote") ? "is-active" : "icon"
                        }
                    >
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/quote.svg"
                            }
                            alt="Quote"
                        />
                    </button>
                </div>
                <div className="btn-undo">
                    <button
                        onClick={() => editor.chain().focus().undo().run()}
                        disabled={!editor.can().chain().focus().undo().run()}
                    >
                        <img
                            src={
                                !editor.can().chain().focus().undo().run()
                                    ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/undo.svg"
                                    : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/undo-active.svg"
                            }
                            alt="undo"
                        />
                    </button>
                    <button
                        onClick={() => editor.chain().focus().redo().run()}
                        disabled={!editor.can().chain().focus().redo().run()}
                    >
                        <img
                            src={
                                !editor.can().chain().focus().redo().run()
                                    ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/redo.svg"
                                    : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-05-2024/redo-active.svg"
                            }
                            alt="redo"
                        />
                    </button>
                </div>
            </div>
        );
    };
    const FileUploadExtension = Extension.create({
        name: "fileUpload",
        addCommands() {
            return {
                uploadFile:
                    () =>
                    ({ editor }) => {
                        const input = document.createElement("input");
                        input.type = "file";
                        input.accept = ".zip,.pdf";
                        input.onchange = async (e) => {
                            const file = e.target.files[0];
                            if (file) {
                                try {
                                    const blobUrl = URL.createObjectURL(file);
                                    if (blobUrl) {
                                        editor.commands.insertContent(
                                            `<a href="${blobUrl}" class="file-link" download="${file.name}">${file.name}</a>`
                                        );
                                    }
                                } catch (error) {
                                    console.error(
                                        "Error uploading file:",
                                        error
                                    );
                                }
                            }
                        };
                        input.click();
                    },
            };
        },
    });

    // define your extension array
    const extensions = [
        CodeBlockLowlight.configure({
            lowlight,
        }),
        // CodeBlock,
        // CodeBlockLowlight,
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        TextAlign.configure({
            types: ["heading", "paragraph"],
        }),
        Placeholder.configure({
            placeholder: placeholder,
        }),
        StarterKit.configure({
            bulletList: {
                keepMarks: true,
                keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
            },
            orderedList: {
                keepMarks: true,
                keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
            },
            codeBlock: false,
        }),
        Image,
        Dropcursor,
        Link.configure({
            class: "url-link",
            rel: "noopener noreferrer",
            target: "_blank",
        }),
        Blockquote,
        FileUploadExtension,
        Underline,
    ];

    const content = ``;
    return (
        <>
            <EditorProvider
                className="editor"
                slotBefore={<Menu />}
                extensions={extensions}
                content={content}
            >
                {}
            </EditorProvider>
            <div>{content}</div>
        </>
    );
};

export default Tiptap;
