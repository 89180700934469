/** @format */

import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { activityConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { id } from "date-fns/locale";

function CallStatusModal({
    isModal,
    setModal,
    student,
    setAssigned,
    isAssigned,
    name,
    phone,
    studentPk,
    status,
    feedback,
}) {
    const [selectedId, setSelectedId] = useState("");
    const [description, setDescription] = useState("");
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setDescription(feedback);
        setResponseStatus(status);
    }, [feedback, status]);

    const callStatus = [
        {
            id: 1,
            slug: "attended",
            status: "Attended",
        },
        {
            id: 2,
            slug: "not_attended",
            status: "Not Attended",
        },
        {
            id: 3,
            slug: "call_later/busy",
            status: "Call later / Busy",
        },
        {
            id: 4,
            slug: "switched_off",
            status: "Switched Off",
        },
    ];

    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const updateCallStatus = () => {
        const formData = new FormData();

        if (responseStatus && description) {
            setSubmitLoading(true);

            formData.append("feedback", description);
            formData.append("status", responseStatus);
            activityConfig
                .post(`followups/parent-call-summary/${studentPk}/`, formData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    let { StatusCode, data, message } = response.data;
                    if (StatusCode === 6000) {
                        setSubmitLoading(false);
                        setAssigned(!isAssigned);
                        setModal(false);
                    } else {
                        setSubmitLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setSubmitLoading(false);
                });
        } else {
            if (!responseStatus) {
                setErrorMessage("status is not found");
            } else if (!description) {
                setErrorMessage("description is not found");
            }
        }
    };

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleModal();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const handleModal = () => {
        setSelectedId("");
        setModal(false);
    };

    return (
        <MainContainer>
            {isModal ? (
                <Overlay
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Head>
                                <MainTitle>Call Status</MainTitle>
                                <Close>
                                    <CloseIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Close Icon"
                                        onClick={() => {
                                            handleModal();
                                        }}
                                    />
                                </Close>
                            </Head>
                        </Top>
                        <Middle>
                            <Profile>
                                <ProfileIconContainer>
                                    <span>{name?.charAt(0).toUpperCase()}</span>
                                </ProfileIconContainer>
                                <ProfileDetails>
                                    <UserName>{name?.toLowerCase()}</UserName>
                                    <PhoneNumber> +91 {phone}</PhoneNumber>
                                </ProfileDetails>
                            </Profile>
                            <SubTitle>Select call status</SubTitle>
                            <StatusList>
                                {callStatus.map((data) => (
                                    <StatusDetail
                                        key={data.pk}
                                        className={
                                            data.slug === responseStatus
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            setResponseStatus(data.slug);
                                        }}
                                    >
                                        <Select
                                            className={
                                                data.slug === responseStatus
                                                    ? ""
                                                    : "show"
                                            }
                                        >
                                            {data.slug === responseStatus && (
                                                <Orangetickcontainer>
                                                    <Orangetick
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/tick.svg"
                                                        alt="Icon"
                                                    />
                                                </Orangetickcontainer>
                                            )}
                                        </Select>
                                        <Status>{data.status}</Status>
                                    </StatusDetail>
                                ))}
                            </StatusList>
                            <Details className="details">
                                <DetailsHead>Details about student</DetailsHead>
                                <DetailsContent
                                    placeholder="Type here"
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            </Details>
                        </Middle>
                        <Button>
                            <Cancel onClick={() => handleModal()}>
                                Cancel
                            </Cancel>
                            {isSubmitLoading ? (
                                <Next>
                                    <ButtonLoader />
                                </Next>
                            ) : (
                                <Next
                                    style={{
                                        cursor: responseStatus
                                            ? "pointer"
                                            : "not-allowed",
                                    }}
                                    onClick={() => updateCallStatus()}
                                >
                                    Submit
                                </Next>
                            )}
                        </Button>
                        {errorMessage && (
                            <Error>{errorMessage && errorMessage}</Error>
                        )}
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default CallStatusModal;
const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    /* box-shadow: 0px 3px 56px #00000048; */
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    height: 100%;
`;
const Top = styled.div`
    margin-bottom: 20px;
    border-bottom: 1px solid #26272a;
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
`;
const Close = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const Middle = styled.div``;
const Profile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;
const ProfileIconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fe673a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fe673a;
    position: relative;
    span {
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
const ProfileDetails = styled.div`
    margin-left: 10px;
    font-family: gordita_regular;
`;
const UserName = styled.h3`
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
`;
const PhoneNumber = styled.h3`
    font-size: 14px;
    color: #fe673a;
`;
const SubTitle = styled.h3`
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    font-family: gordita_medium;
`;
const StatusList = styled.ul``;
const StatusDetail = styled.li`
    display: flex;
    padding: 10px;
    align-items: center;
    background: #161619;
    cursor: pointer;
    border: 1px solid #26272a;
    border-radius: 4px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    &.active {
        border: 1px solid #fe673a;
    }
`;
const Select = styled.div`
    &.show {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #1b1c1f;
        border: 1px solid #26272a;
    }
`;
const Orangetickcontainer = styled.div`
    width: 70%;
`;
const Orangetick = styled.img`
    width: 100%;
    display: block;
`;

const Status = styled.p`
    margin-left: 10px;
    font-size: 14px;
    font-family: gordita_regular;
    color: #fff;
`;
const Details = styled.div`
    margin-top: 16px;
    transition: 0.3s;
`;
const DetailsHead = styled.h3`
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
    font-family: gordita_medium;
`;
const DetailsContent = styled.textarea`
    width: 100%;
    height: 80px;
    border: 1px solid #26272a;
    background: #161619;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    /* opacity:0.5; */
    font-size: 14px;
    outline: none;
    resize: none;
    font-family: gordita_regular;
`;

const Button = styled.div`
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: flex-end;
    margin-top: 35px;
`;
const Cancel = styled.div`
    border: 2px solid #fe673a;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-right: 25px;
    background: #1f2935;
    cursor: pointer;
    background-color: #1b1c1f;
    font-family: gordita_regular;
`;
const Next = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    font-family: gordita_regular;
`;
const Error = styled.p`
    color: red;
    font-size: 14px;
    text-align: right;
    margin-top: 5px;
`;
