import React from "react";
import styled from "styled-components";
import SchoolCategory from "./SchoolCategory";
import SchoolScientistStudents from "./SchoolScientistStudents";
import UpStudentsDetails from "./UpStudentsDetails";
import ViewStudentList from "./ViewStudentList";

function ViewStudents() {
    return (
        <Container id="main">
            <SchoolScientistStudents />
            <SchoolCategory />
            <ViewStudentList />
        </Container>
    );
}
const Container = styled.div``;

export default ViewStudents;
