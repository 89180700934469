import React, { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { activityConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import {
    getDDMMYY,
    getShortMonthName,
    getYYMMDD,
} from "../../helpers/functions";
import ChartGraph from "../../includes/ChartGraph";
import SrmDateModal from "../../includes/operations-head/modal/SrmDateModal";
import BottomCards from "../../includes/primeprogrammes/BottomCards";
import ChartsContainer from "./ChartsContainer";

function SrmStatus() {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const [studentCount, setStudentCount] = useState({});
    const [isStudentLoading, setStudentLoading] = useState(false);
    // const [userFromDate, setUserFromDate] = useState();

    const [isGraphDataLoading, setGraphDataLoading] = useState(true);
    const [graphData, setGraphData] = useState([]);
    const [attendArray, setAttendArray] = useState([]);
    const [pendingArray, setPendingArray] = useState([]);
    const [notAttendedArray, setNotAttendedArray] = useState([]);
    const [switchedOffArray, setSwitchedOffArray] = useState([]);
    const [isShow, setShow] = useState(false);
    const [active, setActive] = useState("Today");
    const [startDate, setStartDate] = useState("");
    const [busyArray, setBusyArray] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [modalType, setModalType] = useState("");
    const [isModal, setModal] = useState(false);
    const currentParams = Object.fromEntries([...searchParams]);
    const [userFromDate, setUserFromDate] = useState();
    const [userToDate, setUserToDate] = useState();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isSubmit, setSubmit] = useState(false);
    const [heading, setHeading] = useState("");
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");

    const handleModal = () => {
        setModal(!isModal);
    };
    const handleClick = () => {
        setShow(!isShow);
    };

    const dateConversion = (value) => {
        if (value) {
            let values = value.split("-");
            const dd = values[0];
            const mm = values[1];
            const yy = values[2];
            let date = yy + "-" + mm + "-" + dd;
            return date;
        }
        return "";
    };

    //---------------------------params---------------------------

    const getBottomFilterParams = () => {
        let filterParams = {};
        if (active === "Today") {
            filterParams.days = "today";
        }
        searchParams.get("days") &&
            (filterParams.days = searchParams.get("days"));
        searchParams.get("activated_days") &&
            (filterParams.activated_days = searchParams.get("activated_days"));
        searchParams.get("date") &&
            (filterParams.date = searchParams.get("date"));
        searchParams.get("from_date") &&
            searchParams.get("to_date") &&
            (filterParams.from_date = searchParams.get("from_date")) &&
            (filterParams.to_date = searchParams.get("to_date"));
        return filterParams;
    };

    //----------------for Doughnut chart---------------------------
    const fetchStudentsCount = () => {
        const filterParams = getBottomFilterParams();
        setStudentLoading(true);
        activityConfig
            .get("followups/srm/followup-progress/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: filterParams,
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudentCount(data);
                    setStudentLoading(false);
                } else {
                    setStudentLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setStudentLoading(false);
            });
    };

    //--------------------------------------------------------------
    //--------------------for bar graph-----------------------------
    const fetchGraphData = () => {
        const filterParams = getBottomFilterParams();
        activityConfig
            .get("followups/srm/followup-progress-chart/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: filterParams,
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setGraphDataLoading(false);
                    setSelectedDate("");
                    let newArr = data.user_data.reduce(
                        (prev, next) => prev.concat(next.date),
                        []
                    );

                    newArr = newArr.map((item, index) => {
                        const month = getShortMonthName(new window.Date(item));
                        const day = item.slice(item.length - 2);
                        return `${day} ${month}`;
                    });

                    setGraphData(newArr);

                    let attendArr = data.user_data.reduce(
                        (prev, next) => prev.concat(next.attended_followups),
                        []
                    );
                    setAttendArray(attendArr);

                    let pendingArr = data.user_data.reduce(
                        (prev, next) => prev.concat(next.pending_followups),
                        []
                    );
                    setPendingArray(pendingArr);

                    let notAttendedArr = data.user_data.reduce(
                        (prev, next) =>
                            prev.concat(next.not_attended_followups),
                        []
                    );
                    setNotAttendedArray(notAttendedArr);

                    let switchedOffArr = data.user_data.reduce(
                        (prev, next) =>
                            prev.concat(next.switched_off_followups),
                        []
                    );

                    setSwitchedOffArray(switchedOffArr);
                    let busyArr = data.user_data.reduce(
                        (prev, next) => prev.concat(next.busy_followups),
                        []
                    );

                    setBusyArray(busyArr);
                } else {
                    setGraphDataLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setGraphDataLoading(false);
            });
    };

    // const [today, setToday] = useState("");
    // useEffect(() => {
    //     setToday(new Date());
    // }, []);
    useEffect(() => {
        fetchGraphData();
        fetchStudentsCount();
    }, [active, searchParams]);
    const handleDateRange = (f, t) => {
        const convertFromDate = getDDMMYY(f);

        setUserFromDate(convertFromDate);

        const convertToDate = getDDMMYY(t);

        setUserToDate(convertToDate);

        const str = convertFromDate + " to " + convertToDate;
        setActive(str);
    };
    useEffect(() => {
        searchParams.get("days") === "today"
            ? setActive("Today")
            : searchParams.get("days") === "yesterday"
            ? setActive("Yesterday")
            : searchParams.get("activated_days") === "7"
            ? setActive("Last 7 days")
            : searchParams.get("activated_days") === "14"
            ? setActive("Last 14 days")
            : searchParams.get("date")
            ? setActive(searchParams.get("date"))
            : searchParams.get("from_date") && searchParams.get("to_date")
            ? setActive("Date Range") ||
              handleDateRange(
                  searchParams.get("from_date"),
                  searchParams.get("to_date")
              )
            : setActive("Today");
    }, [searchParams]);
    //--------------------------------------------------------------------------------------------

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);
    const handleFilterDate = (d) => {
        const convertDates = getYYMMDD(d);
        setActive(convertDates);
        setStartDate(convertDates);
    };
    //----------------------------------------------------------------
    const today = new window.Date();
    return (
        <MainContainer id="main">
            <StatusTop>
                <StatusHeading>
                    <Cover>
                        <Heading>Call Status</Heading>
                        <Filter onClick={handleClick}>
                            <FilterText>
                                {active === "Date Range" ||
                                active === "Month Range"
                                    ? heading
                                    : active}
                            </FilterText>
                            <FIlterImage>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                    alt="Image"
                                />
                            </FIlterImage>
                        </Filter>
                    </Cover>
                    {}
                    <Popup
                        ref={wrapperRef}
                        className={isShow ? "dropdown" : ""}
                    >
                        <SelectOption
                            to={`?days=today`}
                            className={active === "Today" ? "active" : ""}
                            onClick={() => {
                                // setActive("Today");
                                setStartDate("");
                                setShow(false);
                            }}
                        >
                            <Span>
                                {active === "Today" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Today</Text>
                        </SelectOption>
                        <SelectOption
                            to={`?days=yesterday`}
                            onClick={() => (
                                // setActive("Yesterday"),
                                setStartDate(""), setShow(false)
                            )}
                            className={active === "Yesterday" ? "active" : ""}
                        >
                            <Span>
                                {active === "Yesterday" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Yesterday</Text>
                        </SelectOption>

                        <SelectOption
                            to={`?activated_days=7`}
                            className={active === "Last 7 days" ? "active" : ""}
                            onClick={() =>
                                // setActive("Last 7 days"),
                                setShow(false)
                            }
                        >
                            <Span>
                                {active === "Last 7 days" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Last 7 days</Text>
                        </SelectOption>

                        <SelectOption
                            to={`?activated_days=14`}
                            className={
                                active === "Last 14 days" ? "active" : ""
                            }
                            onClick={() =>
                                // setActive("Last 14 days"),
                                setShow(false)
                            }
                        >
                            <Span>
                                {active === "Last 14 days" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Last 14 days</Text>
                        </SelectOption>
                        <SelectOption
                            to=""
                            className={active === "Date Range" ? "active" : ""}
                            onClick={() => {
                                // setActive("Date Range");
                                setModal(true);
                                setModalType("date");
                            }}
                        >
                            <Span>
                                {active === "Date Range" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Date Range</Text>
                        </SelectOption>

                        <HrLine></HrLine>
                        <DateHead>Select date</DateHead>
                        <Date
                            to=""
                            // to=""
                            onClick={() => {
                                setButtonClicked(true);
                            }}
                        >
                            <DatePicker
                                format="dd-MM-yy"
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                yearPlaceholder="YY"
                                onChange={
                                    (value) => setSelectedDate(value)
                                    // setFromDate(value)
                                }
                                value={selectedDate}
                                clearIcon={null}
                                calendarIcon={null}
                                maxDate={today}
                            />
                        </Date>
                        {isButtonClicked && (
                            <Button
                                to={
                                    selectedDate
                                        ? `?date=${getYYMMDD(selectedDate)}`
                                        : ""
                                }
                                onClick={() => {
                                    setShow(false);
                                    handleFilterDate(selectedDate);
                                    setActive(getDDMMYY(selectedDate));
                                }}
                            >
                                Filter
                            </Button>
                        )}
                    </Popup>
                    <SrmDateModal
                        isModal={isModal}
                        setModal={setModal}
                        handleModal={handleModal}
                        modalType={modalType}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        setUserFromDate={setUserFromDate}
                        setUserToDate={setUserToDate}
                        setSubmit={setSubmit}
                        isSubmit={isSubmit}
                        setActive={setActive}
                        today={today}
                        // setHeading={setHeading}
                    />
                </StatusHeading>
                <StatusGraphContainer>
                    <ChartsContainer
                        studentCount={studentCount}
                        isStudentLoading={isStudentLoading}
                        isGraphDataLoading={isGraphDataLoading}
                        graphData={graphData}
                        attendArray={attendArray}
                        pendingArray={pendingArray}
                        notAttendedArray={notAttendedArray}
                        switchedOffArray={switchedOffArray}
                        busyArray={busyArray}
                    />
                </StatusGraphContainer>
            </StatusTop>
            <StatusBottom>
                <BottomCards
                    studentCount={studentCount}
                    isStudentLoading={isStudentLoading}
                />
            </StatusBottom>
        </MainContainer>
    );
}

export default SrmStatus;

const MainContainer = styled.div``;
const StatusTop = styled.div``;
const StatusHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
`;
const Heading = styled.p`
    width: 50%;
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
`;
const Filter = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-radius: 5px;
    background: #1b1c1f;
    padding: 0 15px;
    cursor: pointer;
    position: relative;
    p {
        font-size: 14px;
        color: #fff;
    }
`;
const ImageContainer = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
const StatusGraphContainer = styled.div`
    border-radius: 5px;
    @media (max-width: 1350px) {
    }
    margin-bottom: 20px;
`;
const StatusBottom = styled.div``;
const FilterText = styled.div`
    margin-right: 5px;
    color: #fff;
    font-size: 14px;
`;
const FIlterImage = styled.div`
    & img {
        width: 12px;
    }
`;

const Popup = styled.div`
    font-size: 14px;
    width: 240px;
    padding: 10px;
    position: absolute;
    right: -2px;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    opacity: 0;
    top: 46px;
    &.dropdown {
        opacity: 1;
        overflow: visible;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        background: #1b1c20;
        max-height: 430px;
        transition: 0.5s ease;
        border-radius: 5px;
        z-index: 1;
    }
`;
const Span = styled.div``;
const Date = styled(Link)`
    text-align: center;
    background: #161618;
    border: 1px solid #28292d;
    border-radius: 4px;
    color: #fff;
    padding: 10px 0;
    cursor: pointer;
    .react-datepicker__day:hover {
        border: 1px solid #fff !important;
        color: #fff !important;
        background-color: red !important;
    }
`;
const Button = styled(Link)`
    width: 120px;
    height: 40px;
    background: #ff5722;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 15px;
    color: #fff;
`;
const DateHead = styled.div`
    margin: 15px 0 10px 14px;
    color: #c8c8c8;
`;
const SelectOption = styled(Link)`
    display: flex;
    cursor: pointer;
    padding: 10px;
    position: relative;
    align-items: center;
    color: #fff;
    margin-bottom: 10px;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
`;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 17px;
    height: 17px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 17px;
    height: 17px;
`;
const SubmitButton = styled(Link)`
    width: 120px;
    height: 40px;
    background: red;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 15px;
`;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
`;
const Text = styled.div`
    font-size: 16px;
`;
const Cover = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
