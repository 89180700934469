import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { auth } from "../../../fiebase";
import moment from "moment";
import Avatar from "react-avatar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Context } from "../../contexts/Store";

export default function UserCard({ selectStudent, student }) {
    const navigate = useNavigate();

    const {
        state: { chat_user, isStudentList },
        dispatch,
    } = useContext(Context);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        dispatch({
            type: "UPDATE_CHAT_USER",
            payload: {
                uid: searchParams.get("id"),
                name: searchParams.get("name"),
                pk: searchParams.get("pk"),
            },
        });
    }, [searchParams]);

    useEffect(() => {
        if (student.uid === chat_user.uid) {
            dispatch({
                type: "UPDATE_CHAT_USER",
                payload: {
                    ...chat_user,
                    activeDeviceToken: student.activeDeviceToken,
                },
            });
        }
    }, [chat_user.uid]);

    const ConvertDate = (value, el) => {
        const time = el.toDate().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });

        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];

        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0, 0);

        let date_new = new Date(value);
        date_new.setHours(0);
        date_new.setMinutes(0);
        date_new.setSeconds(0, 0);

        if (today.getTime() === date_new.getTime()) {
            return time;
        } else if (today.getTime() - 86400000 === date_new.getTime()) {
            return "Yesterday";
        } else if (today.getTime() - 86400000 * 7 < date_new.getTime()) {
            return days[date_new.getDay()];
        } else {
            return getDateStr(value);
        }
    };

    const getDateStr = (value) => {
        let date = new Date(value);
        let month = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
        ][date.getMonth()];
        let date_str = date.getDate() + "/" + month + "/" + date.getFullYear();
        return date_str;
    };

    const getDate = (el) => {
        const new_date = moment(el.toDate()).format("YYYY-MM-DD");
        return ConvertDate(new_date, el);
    };

    const [slicedLastMessageText, setSlicedLastMessageText] = useState("");

    useEffect(() => {
        if (student?.lastMessage?.text.startsWith("```")) {
            setSlicedLastMessageText(student?.lastMessage?.text.substring(3));
        } else {
            setSlicedLastMessageText(student?.lastMessage?.text);
        }
    }, [student?.lastMessage?.text]);

    return (
        <Container
            className={student.uid === chat_user.uid ? "selected" : ""}
            to={`/support/room?id=${student.uid}&name=${student.name}&pk=${student.pk}`}
            onClick={(e) => {
                e.preventDefault();
                navigate(
                    `/support/room?id=${student.uid}&name=${student.name}&pk=${student.pk}`
                );
                selectStudent();
                dispatch({
                    type: "UPDATE_SUPPORT_STUDENT_LIST_MENU",
                    isStudentList: !isStudentList,
                });
            }}
        >
            <DpContainer>
                {student.isOnline ? (
                    <small></small>
                ) : (
                    <small className="offline"></small>
                )}
                <Avatar name={student.name} size="38" round />
            </DpContainer>
            <BottomMiddle>
                <h4>{student.name}</h4>
                {student?.lastMessage?.type === "image" ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                        {student?.lastMessage?.fromId === auth.currentUser.uid
                            ? "You"
                            : student?.lastMessage?.fromId === student.uid
                            ? "Student"
                            : student?.lastMessage?.fromName}
                        :{" "}
                        <Image>
                            <img
                                className="file"
                                src={
                                    require("../../../assets/images/chat/gallery.svg")
                                        .default
                                }
                                alt="Icon"
                            />
                            <span>Photo</span>
                        </Image>
                    </span>
                ) : student?.lastMessage?.type === "file" ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                        {student?.lastMessage?.fromId === auth.currentUser.uid
                            ? "You"
                            : student?.lastMessage?.fromId === student.uid
                            ? "Student"
                            : student?.lastMessage?.fromName}
                        :{" "}
                        <Image>
                            <img
                                className="file"
                                src={
                                    require("../../../assets/images/chat/attach.svg")
                                        .default
                                }
                                alt="Icon"
                            />
                            <span>File</span>
                        </Image>
                    </span>
                ) : student?.lastMessage?.type === "voice" ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                        {student?.lastMessage?.fromId === auth.currentUser.uid
                            ? "You"
                            : student?.lastMessage?.fromId === student.uid
                            ? "Student"
                            : student?.lastMessage?.fromName}
                        :{" "}
                        <Image>
                            <img
                                className="file"
                                src={
                                    require("../../../assets/images/chat/mic.svg")
                                        .default
                                }
                                alt="Icon"
                            />
                            <span>Voice</span>
                        </Image>
                    </span>
                ) : student?.lastMessage?.text ? (
                    <span>
                        {student?.lastMessage?.fromId === auth.currentUser.uid
                            ? "You"
                            : student?.lastMessage?.fromId === student.uid
                            ? "Student"
                            : student?.lastMessage?.fromName}
                        :{" "}
                        {slicedLastMessageText
                            ? slicedLastMessageText
                            : student?.lastMessage?.text}
                    </span>
                ) : (
                    <span>--</span>
                )}
            </BottomMiddle>
            <BottomRight>
                <Time>
                    {student?.lastMessage?.createdAt
                        ? getDate(student?.lastMessage?.createdAt)
                        : "--"}
                </Time>
                {student?.lastMessage?.fromId === student.uid &&
                    student?.lastMessage.unread && <NewLabel>New</NewLabel>}
                {student.count && <ChatCount>2</ChatCount>}
            </BottomRight>
        </Container>
    );
}

const Container = styled(Link)`
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    grid-gap: 12px;
    cursor: pointer;
    position: relative;
    transition: 0.3s all ease-in;

    &.selected {
        background: #161619;
    }
    &:hover {
        background: #161619;
    }
    &:last-child::after {
        display: none;
    }
    &:after {
        content: "";
        width: 80%;
        height: 0.5px;
        background: #161619;
        position: absolute;
        width: 91%;
        bottom: 0;
        left: 15px;
        right: 8px;
    }
`;
const DpContainer = styled.div`
    min-width: 38px;
    max-width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    small {
        width: 11px;
        height: 11px;
        background: green;
        position: absolute;
        bottom: 0px;
        right: -2px;
        border-radius: 50%;
        background: #00c569;
    }
    small.offline {
        background: red;
    }
`;
const Dp = styled.img`
    display: block;
    width: 100%;
    border-radius: 50%;
`;
const BottomMiddle = styled.div`
    flex: 1;
    min-width: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h4 {
        color: #ffffff;
        font-family: "gordita_medium";
        font-size: 0.76rem;
        word-break: break-all;
        display: flex;
        align-items: center;
    }
    span {
        display: inline-block;
        margin-top: 4px;
        font-size: 0.65rem;
        color: #fff;
        opacity: 0.7;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 236px;
    }
`;
const Image = styled.div`
    display: flex;
    align-items: center;
    padding-left: 4px;
    img {
        width: 16px;
        margin-right: 3px;
    }
`;
const BottomRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
const Time = styled.div`
    text-align: right;
    font-size: 0.6rem;
    color: #b0b7be;
    opacity: 0.6;
    width: max-content;
`;
const NewLabel = styled.span`
    background: #f53636;
    text-align: center;
    font-size: 0.6rem;
    padding: 3px 8px;
    color: #fff;
    border-radius: 13px;
    margin-top: 3px;
`;
const ChatCount = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #ef674a;
    text-align: center;
    font-family: "gordita_medium";
    font-size: 13px;
    padding: 2px;
    color: #fff;
    margin-top: 6px;
`;
