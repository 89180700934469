import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import TechUpdateSortModal from "./modal/TechUpdateSortModal";
import { useSearchParams } from "react-router-dom";

function TechUpdateCatogeryTop({ setCategoryModal, setModalType }) {
    const [activeTab, setActiveTab] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [isFilter, setFilter] = useState(false);
    const [isSort, setSort] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);

    //create category
    //    const [isCategoryModal, setCategoryModal] = useState(false);

    const wrapperRef = useRef(null);
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setSort(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    const sortingArticles = (value) => {
        setSearchParams({
            ...currentParams,
            sort: value,
        });
    };
    useEffect(() => {
        if (searchWord) {
            setSearchParams({
                ...currentParams,
                q: searchWord,
            });
        } else {
            let newObj = currentParams;
            delete newObj.q;
            setSearchParams(newObj);
        }
    }, [searchWord]);

    return (
        <Top>
            <SubContainer>
                <MainContainer>
                    <MenuText
                        onClick={() => setActiveTab("all")}
                        className={activeTab === "all" && "active"}
                    >
                        All
                    </MenuText>
                </MainContainer>
                <InputContainer>
                    <SearchContainer>
                        <SearchIcon>
                            <img
                                src={
                                    require("../../../assets/images/search.svg")
                                        .default
                                }
                                alt="Search"
                            />
                        </SearchIcon>
                        <InputFiled
                            type="search"
                            value={searchWord}
                            onChange={(e) => setSearchWord(e.target.value)}
                            placeholder="Search…"
                        />
                    </SearchContainer>

                    <Sort>
                        <SortDiv
                            onClick={() => {
                                setFilter(false);
                                setSort(!isSort);
                            }}
                        >
                            <Small className="white">Sort by</Small>
                            <Span>
                                <img
                                    src={
                                        require("../../../assets/images/sort.svg")
                                            .default
                                    }
                                    alt="Sort"
                                />
                            </Span>
                        </SortDiv>
                    </Sort>
                    <TechUpdateSortModal
                        sortingArticles={sortingArticles}
                        isSort={isSort}
                        setSort={setSort}
                        wrapperRef={wrapperRef}
                    />
                    <CreateContainer
                        onClick={() => {
                            setCategoryModal((prev) => !prev);
                            setModalType("create");
                        }}
                    >
                        <Span>
                            <img
                                src={
                                    require("../../../assets/images/plus.svg")
                                        .default
                                }
                                alt="plus"
                            />
                        </Span>
                        <Small className="whites">Create category</Small>
                    </CreateContainer>
                </InputContainer>
            </SubContainer>
        </Top>
    );
}

export default TechUpdateCatogeryTop;
const Top = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const SubContainer = styled.div`
    border-bottom: 1px solid #2e2e2e;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-width: 1280px;
    padding-bottom: 15px;
`;
const MainContainer = styled.div``;

const MenuText = styled.h5`
    color: #7b7b7b;
    font-size: 16px;
    font-family: gordita_regular;
    margin-right: 14px;
    height: 16px;
    cursor: pointer;
    position: relative;
    &.active {
        color: #fe673a;
        &::before {
            content: "";
            width: 20px;
            height: 4px;
            background-color: #fe673a;
            border: 4px;
            position: absolute;
            bottom: -18px;
            border-radius: 5px;
        }
    }
`;

const InputContainer = styled.div`
    width: 44%;
    display: flex;
    justify-content: space-between;
    /* padding-bottom: 10px; */
    @media all and (max-width: 1380px) {
        width: 64%;
    }
`;
const SearchContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    display: flex;
    width: 45%;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
`;

const SearchIcon = styled.div`
    img {
        display: block;
        width: 100%;
    }
`;

const InputFiled = styled.input`
    width: 100%;
    color: #fff;
    font-size: 18px;
    line-height: normal;
`;

const Sort = styled.button`
    position: relative;
    img {
        display: block;
        width: 100%;
    }
`;
const SortDiv = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const Span = styled.span`
    width: 24px;
`;
const Small = styled.small`
    color: #7b7b7b;
    font-size: 0.875rem;
    font-family: gordita_medium;

    cursor: pointer;
    &.white {
        color: #fff;

        margin-right: 8px;
    }
    &.whites {
        color: #f2f2f2;

        line-height: normal;
        margin-right: 0;
        margin-left: 4px;
    }
`;

const Filter = styled.button`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;

    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const CreateContainer = styled.div`
    display: flex;
    height: 44px;
    padding: 8px 16px;
    align-items: center;
    border-radius: 8px;
    background: #fe673a;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 460px) {
        align-items: flex-end;
    }
`;

const TextArea = styled.div`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 460px) {
        margin-bottom: 15px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
`;
