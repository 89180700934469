import React, { Component } from "react";
import WaveSurfer from "wavesurfer.js";
import styled from "styled-components";
import { Pause, Play } from "./Icons";

class Waveform extends Component {
    state = {
        playing: false,
        rate: 1,
        duration: "",
        currentTime: "",
        remainingTime: "",
        loading: true,
    };

    componentDidMount() {
        this.waveform = WaveSurfer.create({
            barGap: 3,
            barWidth: 2,
            barRadius: 2,
            cursorWidth: 1,
            container: `#waveform-${this.props.id}`,
            backend: "WebAudio",
            height: 39,
            progressColor: "#f7684c",
            responsive: true,
            waveColor: "#EFEFEF",
            cursorColor: "transparent",
            maxCanvasWidth: 30000,
            audioRate: 1,
            barHeight: 3,
        });

        if (!this.props.loading && this.props.voiceState) {
            this.waveform.load(`${this.props.voiceState}`);
        }

        this.waveform.on("ready", () => {
            this.setState({
                duration: new Date(this.waveform.getDuration() * 1000)
                    .toISOString()
                    .slice(14, 19),
            });
            this.setState({ loading: false });
        });

        this.waveform.on("play", () => {
            this.setState({ playing: true });
        });
        this.waveform.on("pause", () => {
            this.setState({ playing: false });
        });

        this.waveform.on("audioprocess", () => {
            if (this.waveform.isPlaying()) {
                let totalTime = this.waveform.getDuration(),
                    currentTime = this.waveform.getCurrentTime(),
                    remainingTime = totalTime - currentTime;

                this.setState({
                    remainingTime: new Date(remainingTime * 1000)
                        .toISOString()
                        .slice(14, 19),
                });
            }
        });

        setTimeout(() => {
            this.setState({
                duration: new Date(this.waveform.getDuration() * 1000)
                    .toISOString()
                    .slice(14, 19),
            });
        }, 600);
    }

    handlePlay = () => {
        this.waveform.playPause();
    };

    changeRate = (rate) => {
        if (rate === 1) {
            this.waveform.setPlaybackRate(1.5);
            this.setState({ rate: 1.5 });
        } else if (rate === 1.5) {
            this.waveform.setPlaybackRate(2);
            this.setState({ rate: 2 });
        } else if (rate === 2) {
            this.waveform.setPlaybackRate(1);
            this.setState({ rate: 1 });
        }
    };

    render() {
        return (
            <WaveformContianer className={this.props.className}>
                {this.props.loading || this.state.loading ? (
                    <Left
                        onClick={() => this.changeRate(this.state.rate)}
                        role="button"
                        aria-hidden="true"
                        tabindex="0"
                    >
                        <Spinner className={this.props.className}></Spinner>
                    </Left>
                ) : (
                    <Icon
                        onClick={this.handlePlay}
                        className={this.props.className}
                    >
                        {!this.state.playing ? <Play /> : <Pause />}
                    </Icon>
                )}

                <Wave
                    id={`waveform-${this.props.id}`}
                    className={this.props.className}
                ></Wave>
                {!this.props.loading && (
                    <Duration className={this.props.className}>
                        {this.state.playing
                            ? this.state.remainingTime
                            : this.state.duration}
                    </Duration>
                )}
                <audio id="track" />
            </WaveformContianer>
        );
    }
}

export default Waveform;

const Left = styled.div`
    display: flex;
    min-width: 41px;
    align-items: center;
`;
const Spinner = styled.span`
    margin: 0 auto;
    height: 27px;
    width: 27px;
    animation: rotate 0.8s infinite linear;
    border: 2px solid #b1b1b2;
    border-right-color: transparent;
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    &.student {
        border: 2px solid #482b24;
        border-right-color: transparent;
    }
    &.chief-side,
    &.chief-side-engineer {
        border: 2px solid #fff;
        border-right-color: transparent;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(1turn);
        }
    }
`;
const WaveformContianer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    grid-gap: 10px;

    background: #482b24;
    border-radius: 13px 13px 0 13px;
    min-width: 260px;
    min-height: 55px;
    padding: 0 19px 0 10px;

    &.student {
        margin-right: 0;
        border-radius: 13px 13px 13px 0;
        background-color: #d7d7d7;
    }
    &.chief-side {
        border-radius: 13px 13px 0 13px;
    }
    &.chief-side-engineer {
        border-radius: 13px 13px 13px 0;
    }
`;
const Icon = styled.button`
    cursor: pointer;
    padding-left: 1px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.student {
        background-color: #482b24;

        & svg path {
            fill: #fff;
        }
    }
    &.chief-side {
        background-color: #fff;

        & svg path {
            fill: #482b24;
        }
    }
    &.chief-side-engineer {
        background-color: #fff;

        & svg path {
            fill: #482b24;
        }
    }
    & svg {
        width: 20px;
        height: 20px;
    }
`;
const Wave = styled.div`
    width: calc(100% - 70px);
    position: relative;

    &.student {
        filter: invert(1);
    }
`;
const Duration = styled.span`
    font-size: 0.6rem;
    color: #482b24;
    font-family: "gordita_medium";

    &.student {
        color: #482b24;
    }
    &.chief-side {
        color: #fff;
    }
    &.chief-side-engineer {
        color: #fff;
    }
`;
