import React, { useEffect } from "react";
import styled from "styled-components";
import $ from "jquery";

function PhoneNumberModal({ isModal, handlePhoneModal, prerantReson }) {
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);
    return (
        <BackContainer style={{ display: isModal ? "block" : "none" }}>
            <Overlay
                onClick={() => {
                    handlePhoneModal();
                }}
            />
            <Modal>
                <ContainerTool>
                    <Bottom>
                        <Heading>Reason</Heading>
                        {prerantReson !== "" ? (
                            <Content>{prerantReson}</Content>
                        ) : (
                            <Content>No data available.</Content>
                        )}
                    </Bottom>
                </ContainerTool>
            </Modal>
        </BackContainer>
    );
}
export default PhoneNumberModal;

const BackContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;
    display: none;
`;

const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
`;

const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 70vh;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background: #1b1c1f;
    border-radius: 5px;
    z-index: 101;
    overflow-y: auto;
`;

const ContainerTool = styled.div`
    background: #1b1c20;
    color: white;
    border: 2px solid #3b3b3b;
    border-radius: 8px;
    box-shadow: 14px 25px 75px -18px rgba(0, 0, 0, 0.75);
`;

const Heading = styled.div`
    font-size: 16px;
    font-family: "gordita_medium";
    margin-bottom: 10px;
`;

const Bottom = styled.div`
    width: 100%;
    padding: 18px 34px 18px;
    &:hover {
        background: #161619;
    }
    border-bottom: 1px solid #2c2c2c;
    &:last-child {
        border-bottom: none;
    }
    /* :nth-child(2n) {
        background: #161619;
    } */
`;
const Log = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Number = styled.div`
    font-size: 14px;
`;
const Content = styled.p`
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;
    color: #fff;
`;
const Relation = styled.div`
    color: #da5b36;
    margin-right: 10px;
    font-size: 14px;
    font-family: "gordita_medium";
    text-transform: capitalize;
`;
