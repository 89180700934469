import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import Moment from "react-moment";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Context } from "../../contexts/Store";
import ShareDeleteModal from "../../includes/career-advisor/modal/ShareDeleteModal";
import RemovedTags from "./RemovedTags";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "../../includes/jobdesk/NoData";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import DatePicker from "react-date-picker";
import { useSearchParams } from "react-router-dom";
import TiptapViewer from "../../includes/doubt-hub/Editor/TiptapViewer";
import { learnConfig } from "../../../axiosConfig";
import SortBtnModal from "../../includes/career-advisor/modal/SortBtnModal";
import RemoveQuestions from "../../includes/career-advisor/modal/RemoveQuestions";

function ReportViewPage(
    editLoder,
    setModalType,
    editCategoryPK,
    setEditCategoryPK,
    setDeleteModal,
    wrapperRef
) {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const [pagination, setPagination] = useState(null);
    const [aboutMeText, setAboutMeText] = useState("");

    const [isModal, setModal] = useState(false);
    const [isShare, setShare] = useState(false);

    const [isPageLoading, setPageLoading] = useState(false);
    const [sigleData, setSingleData] = useState();
    const [desc, setDesc] = useState(null);
    const dataObject = JSON.parse(desc);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [activeClick, setActiveClick] = useState("Filter");
    const [isDropdown, setDropdown] = useState(false);
    const [isCategory, setCategory] = useState(false);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [dateAlert, setDateAlert] = useState(false);
    const [toDay, setToday] = useState("");
    const today = new window.Date();
    const [toLink, setToLink] = useState("?");
    const [searchWord, setSearchWord] = useState("");
    const [isAuthor, setAuthor] = useState(false);
    const [isSelected, setSelected] = useState("");
    const [categorys, setCategorys] = useState([]);
    const [respoterData, setRespoterData] = useState([]);
    const [isFilter, setFilter] = useState(false);
    const [isSort, setSort] = useState(false);
    const [isSelectedItem, setSelectedItem] = useState("");
    const [isBtnLoading, setBtnLoading] = useState(false);

    //search params
    const [searchQuery, setSearchQuery] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("blog_status") &&
            (filterParams.blog_status = searchParams.get("blog_status"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        searchParams.get("author") &&
            (filterParams.author = searchParams.get("author"));
        searchParams.get("published_date") &&
            (filterParams.published_date = searchParams.get("published_date"));
        searchParams.get("from_date") &&
            (filterParams.from_date = searchParams.get("from_date"));
        searchParams.get("to_date") &&
            (filterParams.to_date = searchParams.get("to_date"));
        searchParams.get("category") &&
            (filterParams.category = searchParams.get("category"));
        return filterParams;
    };

    const { pk } = useParams();

    const history = useNavigate();

    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    const clearAll = () => {
        setSearchWord("");
        setToday("");
        setFromDate("");
        setToDate("");
        setDropdown(false);
    };
    const handleCloseClick = () => {
        setSelectedAuthor(null);
        const updatedParams = { ...currentParams };
        delete updatedParams.author;
        setSearchParams(updatedParams);
    };
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    const handleAuthorClick = (author) => {
        setSelectedAuthor(author);
        setAuthor(false);
        setSearchParams({
            ...currentParams,
            author: author.id,
        });
    };
    const handleBlogStatus = (status) => {
        if (status !== "") {
            setSearchParams({
                ...currentParams,
                blog_status: status,
            });
        } else {
            const updatedParams = { ...currentParams };
            delete updatedParams.blog_status;
            setSearchParams(updatedParams);
        }
    };

    const sortingArticles = (value) => {
        setSearchParams({
            ...currentParams,
            sort: value,
        });
    };

    // api connection for fetch data

    useEffect(() => {
        setPageLoading(true);
        const params = getSearchParams();
        async function singleView() {
            try {
                const response = await learnConfig.get(
                    `/doubt-hub/question-view-report/${pk}/`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: params,
                    }
                );
                const { status_code, data } = response.data;

                if (status_code === 6000) {
                    setSingleData(data);
                    setDesc(data?.description);
                    setRespoterData(data?.reported_persons);
                    setPageLoading(false);
                    setPagination(data?.reported_persons?.pagination_data);
                    // setPageLoading(false);
                } else {
                    // setPageLoading(false);

                    setPageLoading(false);
                }
            } catch (error) {
                // setPageLoading(false);
            }
        }

        singleView();
    }, [currentPage, searchParams, searchQuery]);

    // delete question api

    const DeleteQuestion = () => {
        setBtnLoading(true);
        const formData = new FormData();
        formData.append("reason", aboutMeText);
        formData.append("page", "report");
        learnConfig
            .post(
                `/doubt-hub/delete-question-chief/${isSelectedItem}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setAboutMeText("");
                    setSelectedItem("");
                    history("/doubt-hub/reports/questions/");
                    setModal(!isModal);
                    setBtnLoading(false);
                } else {
                    setBtnLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setBtnLoading(false);
            });
    };

    // mark as evaluate question api

    const EvaluateCall = (selectedItem) => {
        setBtnLoading(true);
        const formData = new FormData();
        formData.append("evaluate_instance", "question");
        learnConfig
            .post(`/doubt-hub/evaluate-report/${selectedItem}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code } = response.data;
                if (status_code === 6000) {
                    setAboutMeText("");
                    setSelectedItem("");
                    history("/doubt-hub/reports/questions/");
                    setBtnLoading(false);
                } else {
                    setBtnLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setBtnLoading(false);
            });
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const [counter, setCounter] = useState(0);

    const handleModal = () => {
        // setSelectedId("");
        setShare(!isShare);
        // setDescription("");
    };

    const [editedContent, setEditedContent] = useState("Type your comment");

    const handleDivClick = () => {
        setEditedContent("");
    };
    const handleDateRange = (date, range) => {
        let date_range = `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}`;
        if (range === "from") {
            setSearchParams({
                ...currentParams,
                from_date: date_range,
            });
        } else {
            setSearchParams({
                ...currentParams,
                to_date: date_range,
            });
        }
    };
    const handleDateFilter = (date) => {
        if (date !== "") {
            setSearchParams({
                ...currentParams,
                published_date: date,
            });
        } else {
            const updatedParams = { ...currentParams };
            delete updatedParams.published_date;
            setSearchParams(updatedParams);
        }
    };
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setCategory(false);
    };
    const handleRemoveCategory = () => {
        setSelectedCategory(null);
    };
    const updateFilters = () => {
        // console.log("currentParams", currentParams);
        if (currentParams.blog_status) {
            setSelected(currentParams.blog_status);
        }
        if (currentParams.published_date) {
            setToday(currentParams.published_date);
        }
    };
    useEffect(() => {
        updateFilters();
    }, [searchParams]);

    return (
        <>
            {isModal && (
                <RemoveQuestions
                    isModal={isModal}
                    setModal={setModal}
                    setAboutMeText={setAboutMeText}
                    DeleteQuestion={DeleteQuestion}
                    isBtnLoading={isBtnLoading}
                />
            )}
            {isShare && (
                <ShareDeleteModal isShare={isShare} setShare={setShare} />
            )}

            <DoubtNavigator>
                <DoubtButton to="/doubt-hub/reports/questions/">
                    <ArrowIcon>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2024/arrow-left.svg"
                            alt=""
                        />
                    </ArrowIcon>
                    Back to Reports
                </DoubtButton>
            </DoubtNavigator>

            <MainContainer>
                <Breadcrum>{/* <CardPathChip /> */}</Breadcrum>
                <StudentContainer>
                    <TopContainer>
                        <Heads>
                            <Heading>Question Reports</Heading>
                            <p>Evaluate and manage the report here</p>
                        </Heads>
                    </TopContainer>
                    <Crumb>
                        <MiddleContainer>
                            <Views>{sigleData?.title ?? "--"}</Views>
                            <RightContainer>
                                <AskButton
                                    to={`/doubt-hub/doubts/${sigleData?.pk}/`}
                                >
                                    View Doubts
                                </AskButton>
                            </RightContainer>
                        </MiddleContainer>
                        <BottomContainers>
                            <MainHead>
                                <MainSub>
                                    <Contents type="question">
                                        Posted by
                                    </Contents>
                                    <Contents type="date">Vote Count</Contents>
                                    <Contents type="campus">Answers</Contents>
                                    <Contents type="comments">
                                        Comments
                                    </Contents>
                                    <Contents type="subscription">
                                        Tags
                                    </Contents>
                                    <Contents type="ask">Create Date</Contents>
                                </MainSub>
                            </MainHead>
                            <UlList>
                                <Li type="question">
                                    <StudentProfile>
                                        {" "}
                                        <StudentPhoto>
                                            {sigleData?.student_details
                                                ?.student_photo ? (
                                                <img
                                                    src={
                                                        sigleData
                                                            ?.student_details
                                                            ?.student_photo
                                                    }
                                                    alt={
                                                        sigleData
                                                            ?.student_details
                                                            ?.student_name
                                                    }
                                                />
                                            ) : (
                                                <Avatar
                                                    name={
                                                        sigleData
                                                            ?.student_details
                                                            ?.student_name
                                                    }
                                                    size="38"
                                                    round
                                                />
                                            )}
                                        </StudentPhoto>
                                        <StudentDetails>
                                            <StudentName>
                                                {sigleData?.student_details
                                                    ?.student_name ?? "--"}
                                            </StudentName>
                                            <StudentPosition>
                                                {sigleData?.student_details
                                                    ?.student_program ?? "--"}
                                            </StudentPosition>
                                        </StudentDetails>
                                    </StudentProfile>
                                </Li>
                                <Li type="date">
                                    {" "}
                                    <span>{sigleData?.vote_count ?? "--"}</span>
                                </Li>

                                <Li type="campus">
                                    <span>
                                        {sigleData?.answers_count ?? "--"}
                                    </span>
                                </Li>
                                <Li type="comments">
                                    <span>
                                        {sigleData?.comment_count ?? "--"}
                                    </span>
                                </Li>

                                <Li type="subscription">
                                    {sigleData?.tags?.length > 1 ? (
                                        <>
                                            <small
                                                style={{
                                                    border: "1px solid #22c55e",
                                                    color: "#22c55e",
                                                    padding: "6px 12px",
                                                    borderRadius: "8px",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {sigleData.tags[0]}
                                            </small>
                                            <small
                                                style={{
                                                    border: "1px solid #22c55e",
                                                    color: "#22c55e",
                                                    padding: "6px 12px",
                                                    borderRadius: "8px",
                                                    fontSize: "12px",
                                                    marginLeft: "8px",
                                                }}
                                            >
                                                +{sigleData.tags.length - 1}
                                            </small>
                                        </>
                                    ) : (
                                        sigleData?.tags?.map((tag) => (
                                            <small
                                                style={{
                                                    border: "1px solid #22c55e",
                                                    color: "#22c55e",
                                                    padding: "6px 12px",
                                                    borderRadius: "8px",
                                                    fontSize: "13px",
                                                    fontFamily:
                                                        "gordita_medium",
                                                }}
                                                key={tag}
                                            >
                                                {tag}
                                            </small>
                                        ))
                                    )}
                                </Li>

                                <Li type="ask">
                                    <p>
                                        <Moment format="D MMM YYYY LT">
                                            {sigleData?.published_time}
                                        </Moment>
                                    </p>
                                </Li>
                            </UlList>
                        </BottomContainers>
                    </Crumb>
                </StudentContainer>
                <BorderContainer>
                    <BorderTop>
                        <Heading>Question:</Heading>
                        <Buttons>
                            <Remove
                                onClick={(e) => {
                                    setModal(true);
                                    setSelectedItem(sigleData.pk);
                                }}
                            >
                                Remove Question
                            </Remove>
                            <Evaluate
                                onClick={(e) => {
                                    EvaluateCall(sigleData.pk);
                                }}
                            >
                                {isBtnLoading ? (
                                    <ButtonLoader />
                                ) : (
                                    "Mark as Evaluated"
                                )}
                            </Evaluate>
                        </Buttons>
                    </BorderTop>
                    <QuestionContainer>
                        <TiptapViewer content={dataObject} />
                    </QuestionContainer>
                    <Container>
                        <ContentContainer>
                            <TopContainers>
                                <Search>
                                    <p>Search </p>
                                    <SearchContainer>
                                        <SearchBar maxlength="20">
                                            <InputField placeholder="Search for keywork student etc..." />
                                        </SearchBar>
                                        <SearchImageContainer>
                                            <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg" />
                                        </SearchImageContainer>
                                    </SearchContainer>
                                </Search>

                                <TopDiv>
                                    <NavContainer>
                                        <Sort>
                                            <SortDiv
                                                onClick={() => {
                                                    setFilter(false);
                                                    setSort(!isSort);
                                                }}
                                            >
                                                <Smaall className="white">
                                                    Sort by
                                                </Smaall>
                                                <Spaan>
                                                    <img
                                                        src={
                                                            require("../../../assets/images/sort.svg")
                                                                .default
                                                        }
                                                        alt="Sort"
                                                    />
                                                </Spaan>
                                            </SortDiv>
                                            <SortBtnModal
                                                sortingArticles={
                                                    sortingArticles
                                                }
                                                isSort={isSort}
                                                setSort={setSort}
                                                wrapperRef={wrapperRef}
                                            />
                                        </Sort>

                                        <TopFilter>
                                            <CoverSet ref={wrapperRef}>
                                                <Filters
                                                    onClick={() =>
                                                        setDropdown(!isDropdown)
                                                    }
                                                >
                                                    <FilterText>
                                                        {activeClick}
                                                    </FilterText>
                                                    <FIlterImage>
                                                        <img
                                                            src={
                                                                require("../../../assets/images/bda/Filter.svg")
                                                                    .default
                                                            }
                                                            alt="Image"
                                                        />
                                                    </FIlterImage>
                                                </Filters>
                                                <Popup
                                                    className={
                                                        isDropdown
                                                            ? "dropdown"
                                                            : ""
                                                    }
                                                >
                                                    <FilterDiv>
                                                        Filter
                                                        <ArrowDiv
                                                            onClick={() =>
                                                                setDropdown(
                                                                    !isDropdown
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                                alt="close"
                                                            />
                                                        </ArrowDiv>
                                                    </FilterDiv>
                                                    <HrLine className="Exam Status"></HrLine>
                                                    {/* {selectedAuthor && (
                                        <SelectDiv>
                                            <Select>
                                                <MainImg
                                                    style={{
                                                        backgroundImage: `url(${selectedAuthor.image})`,
                                                    }}
                                                ></MainImg>
                                                <MianText>
                                                    {selectedAuthor.name}
                                                </MianText>
                                                <CloseImg
                                                    onClick={handleCloseClick}
                                                >
                                                    <img
                                                        src={
                                                            require("../../../assets/images/closered.svg")
                                                                .default
                                                        }
                                                        alt="close"
                                                    />
                                                </CloseImg>
                                            </Select>
                                        </SelectDiv>
                                    )} */}
                                                    {/* <SearchDiv
                                            onClick={() => {
                                                setCategory(!isCategory);
                                                setAuthor(false);
                                            }}
                                        >
                                            <SearchTxt
                                                type="text"
                                                value={searchWord}
                                                onChange={(e) =>
                                                    setSearchWord(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Search category"
                                            />
                                            <ArticleIcon>
                                                <img
                                                    src={
                                                        require("../../../assets/images/chat/search.svg")
                                                            .default
                                                    }
                                                    alt="searchimage"
                                                />
                                            </ArticleIcon>
                                        </SearchDiv> */}
                                                    {/* {isCategory && (
                                            <SearchDivModal>
                                                {categorys.map((data) => (
                                                    <SearchContent
                                                        key={data.id}
                                                        onClick={() => {
                                                            handleCategoryClick(
                                                                data
                                                            );
                                                            setSearchWord("");
                                                            setSearchParams({
                                                                ...currentParams,
                                                                category:
                                                                    data.slug,
                                                            });
                                                        }}
                                                    >
                                                        <p>{data.title}</p>
                                                    </SearchContent>
                                                ))}
                                            </SearchDivModal>
                                        )} */}

                                                    {/* {selectedCategory && (
                                            <SelectDiv>
                                                <Select>
                                                    <MianText>
                                                        {selectedCategory.title}
                                                    </MianText>
                                                    <CloseImg
                                                        onClick={
                                                            handleRemoveCategory
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                require("../../../assets/images/closered.svg")
                                                                    .default
                                                            }
                                                            alt="close"
                                                        />
                                                    </CloseImg>
                                                </Select>
                                            </SelectDiv>
                                        )}
                                        <TagContainer>
                                            <Tag>Artificial Intellegence</Tag>
                                            <TagIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                    alt="close"
                                                />
                                            </TagIcon>
                                        </TagContainer> */}
                                                    <ContainerDiv>
                                                        <Small>
                                                            Date Range
                                                        </Small>
                                                        <SelectOption
                                                            onClick={() => {
                                                                setToday(
                                                                    toDay ==
                                                                        "today"
                                                                        ? ""
                                                                        : "today"
                                                                );
                                                                handleDateFilter(
                                                                    toDay ==
                                                                        "today"
                                                                        ? ""
                                                                        : "today"
                                                                );
                                                            }}
                                                        >
                                                            <Span>
                                                                {toDay ===
                                                                "today" ? (
                                                                    <SelectedImg>
                                                                        <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                                            alt="image"
                                                                        />
                                                                    </SelectedImg>
                                                                ) : (
                                                                    <UnSelectedImg>
                                                                        <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                                            alt="image"
                                                                        />
                                                                    </UnSelectedImg>
                                                                )}
                                                            </Span>
                                                            <Text>Today</Text>
                                                        </SelectOption>

                                                        <SelectOption
                                                            onClick={() => {
                                                                setToday(
                                                                    toDay ==
                                                                        "this_week"
                                                                        ? ""
                                                                        : "this_week"
                                                                );
                                                                handleDateFilter(
                                                                    toDay ==
                                                                        "this_week"
                                                                        ? ""
                                                                        : "this_week"
                                                                );
                                                            }}
                                                        >
                                                            <Span>
                                                                {toDay ===
                                                                "this_week" ? (
                                                                    <SelectedImg>
                                                                        <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                                            alt="image"
                                                                        />
                                                                    </SelectedImg>
                                                                ) : (
                                                                    <UnSelectedImg>
                                                                        <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                                            alt="image"
                                                                        />
                                                                    </UnSelectedImg>
                                                                )}
                                                            </Span>
                                                            <Text>
                                                                This week
                                                            </Text>
                                                        </SelectOption>

                                                        <SelectOption
                                                            onClick={() => {
                                                                setToday(
                                                                    toDay ==
                                                                        "this_month"
                                                                        ? ""
                                                                        : "this_month"
                                                                );
                                                                handleDateFilter(
                                                                    toDay ==
                                                                        "this_month"
                                                                        ? ""
                                                                        : "this_month"
                                                                );
                                                            }}
                                                        >
                                                            <Span>
                                                                {toDay ===
                                                                "this_month" ? (
                                                                    <SelectedImg>
                                                                        <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                                            alt="image"
                                                                        />
                                                                    </SelectedImg>
                                                                ) : (
                                                                    <UnSelectedImg>
                                                                        <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                                            alt="image"
                                                                        />
                                                                    </UnSelectedImg>
                                                                )}
                                                            </Span>
                                                            <Text>
                                                                This month
                                                            </Text>
                                                        </SelectOption>
                                                    </ContainerDiv>
                                                    <HrLine className="Exam Status"></HrLine>
                                                    <ContainerDiv>
                                                        <Small>Pick Date</Small>
                                                        <AccountFilter>
                                                            <DatePickDiv>
                                                                <DatePicker
                                                                    format={
                                                                        "dd-MM-yy"
                                                                    }
                                                                    dayPlaceholder="DD"
                                                                    monthPlaceholder="MM"
                                                                    yearPlaceholder="YY"
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        setFromDate(
                                                                            value
                                                                        );
                                                                        handleDateRange(
                                                                            value,
                                                                            "from"
                                                                        );
                                                                    }}
                                                                    value={
                                                                        fromDate
                                                                    }
                                                                    clearIcon={
                                                                        null
                                                                    }
                                                                    calendarIcon={
                                                                        null
                                                                    }
                                                                    maxDate={
                                                                        today
                                                                    }
                                                                />
                                                            </DatePickDiv>
                                                            to
                                                            <DatePickDiv>
                                                                <DatePicker
                                                                    format={
                                                                        "dd-MM-yy"
                                                                    }
                                                                    dayPlaceholder="DD"
                                                                    monthPlaceholder="MM"
                                                                    yearPlaceholder="YY"
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        setToDate(
                                                                            value
                                                                        );
                                                                        handleDateRange(
                                                                            value,
                                                                            "to"
                                                                        );
                                                                    }}
                                                                    value={
                                                                        toDate
                                                                    }
                                                                    clearIcon={
                                                                        null
                                                                    }
                                                                    calendarIcon={
                                                                        null
                                                                    }
                                                                    maxDate={
                                                                        today
                                                                    }
                                                                />
                                                            </DatePickDiv>
                                                        </AccountFilter>
                                                    </ContainerDiv>
                                                    <ButtonDiv>
                                                        <ClearDiv
                                                            onClick={clearAll}
                                                            to=""
                                                        >
                                                            Clear
                                                        </ClearDiv>
                                                        <ApplyDiv
                                                            className={
                                                                dateAlert &&
                                                                "disabled"
                                                            }
                                                            onClick={(e) => {
                                                                setDropdown(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Apply
                                                        </ApplyDiv>
                                                    </ButtonDiv>
                                                </Popup>
                                            </CoverSet>
                                        </TopFilter>
                                    </NavContainer>
                                </TopDiv>
                            </TopContainers>

                            <MainContainers>
                                <Top>
                                    <Text type="sl_no">Sl No</Text>
                                    <Text type="title">Reported By</Text>
                                    <Text type="category">Reason</Text>
                                    <Text type="category_image">
                                        Date and Time
                                    </Text>
                                </Top>
                                {/*  */}
                                <DataContainer>
                                    {isPageLoading ? (
                                        <SectionLoader />
                                    ) : respoterData.data?.length > 0 ? (
                                        <>
                                            {respoterData.data.map(
                                                (data, index) => (
                                                    <Bottom key={index}>
                                                        <Section type="sl_no">
                                                            <small>
                                                                {index + 1}
                                                            </small>
                                                        </Section>
                                                        <Section type="title">
                                                            {data?.reporter_photo ? (
                                                                <ImageContainer>
                                                                    <img
                                                                        src={
                                                                            data?.reporter_photo
                                                                        }
                                                                        alt="student"
                                                                    />
                                                                </ImageContainer>
                                                            ) : (
                                                                <DpDiv>
                                                                    <Avatar
                                                                        name={
                                                                            data?.reporter_name
                                                                        }
                                                                        size="38"
                                                                        round
                                                                    />
                                                                </DpDiv>
                                                            )}

                                                            <NameDiv>
                                                                <h2>
                                                                    {data.reporter_name
                                                                        ? data.reporter_name
                                                                        : "--"}
                                                                </h2>
                                                                <p
                                                                    style={{
                                                                        fontFamily:
                                                                            "gordita_regular",
                                                                        fontSize:
                                                                            "14px",
                                                                        color: "#fe673a",
                                                                    }}
                                                                >
                                                                    {
                                                                        data?.reporter_program
                                                                    }
                                                                </p>
                                                            </NameDiv>
                                                        </Section>

                                                        <Section type="category">
                                                            <h3>
                                                                {data?.reason
                                                                    ? data?.reason
                                                                    : data?.reported_category
                                                                    ? data?.reported_category
                                                                    : "--"}
                                                            </h3>
                                                        </Section>

                                                        <Section type="category_image">
                                                            <p>
                                                                <Moment format="D MMM YYYY LT">
                                                                    {
                                                                        data?.reported_date_time
                                                                    }
                                                                </Moment>
                                                            </p>
                                                        </Section>
                                                    </Bottom>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <NoData />
                                    )}
                                </DataContainer>
                            </MainContainers>
                        </ContentContainer>
                        {pagination?.total_pages > 0 && (
                            <PaginationContainer>
                                <PaginationText>
                                    {pagination?.last_item} of{" "}
                                    {pagination?.total_items}
                                </PaginationText>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pagination?.total_pages}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={
                                        "pagination__link--disabled"
                                    }
                                    activeClassName={"pagination__link--active"}
                                />
                            </PaginationContainer>
                        )}
                    </Container>
                </BorderContainer>
            </MainContainer>
        </>
    );
}
const DoubtButton = styled(Link)`
    color: #fe673a;
    font-size: 13px;
    display: flex;
    align-items: center;
`;
const ArrowIcon = styled.div`
    width: 20px;
    margin-right: 5px;
    img {
        width: 100%;
        display: block;
        /* filter: hue-rotate(90deg); */
        /* transform: rotate(180deg); */
    }
`;
const DoubtNavigator = styled.div``;
const MainContainer = styled.div`
    overflow-y: scroll;
    max-height: calc(93vh - 80px);
    min-height: calc(93vh - 80px);
`;
const Breadcrum = styled.div`
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const NameDiv = styled.div``;

const DpDiv = styled.div`
    margin-right: 5px;
`;

const ImageContainer = styled.div`
    margin-right: 5px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;

    img {
        width: 100%;
        height: 100%;
        display: block;
    }

    img {
        width: 100%;
        display: block;
    }
`;
const StudentContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid #2c2c2c;
    overflow-x: scroll;
    /* margin-top: 12px; */

    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background: #e5e5e5;
        border-radius: 0.625rem;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #b7b7b7;
    }
    @media all and (max-width: 768px) {
        padding-right: 0;
    }
`;

const TopContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2c2c2c;
`;
const Heads = styled.div`
    p {
        font-family: "gordita_medium";
        font-size: 13px;
        color: #fff;
        margin-bottom: 12px;
    }
`;
const Heading = styled.h4`
    font-size: 22px;
    color: #fff;
    font-family: "gordita_medium";
    margin-top: 15px;
    @media all and (max-width: 1400px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
        margin-top: 20px;
    }
`;
const Crumb = styled.div`
    background: #1b1c1f;
    border-radius: 6px;
    padding: 14px 34px;
`;

const Section = styled.div`
    color: #d2caca;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "18%"
            : type === "category"
            ? "50%"
            : type === "category_image"
            ? "15%"
            : type === "actions" && "15%"};

    display: flex;
    align-items: center;

    h3 {
        font-size: 14px;
        color: #ffffff;
        font-family: "gordita_regular";
    }

    h2 {
        font-family: "gordita_medium";
        font-size: 16px;
        color: #ffffff;
    }

    p {
        color: #d2caca;
        font-size: 13px;
    }
    span {
        height: 30px;
        img {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
`;
const MiddleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #161619;
`;
const BottomContainer = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
`;
const MainHead = styled.div`
    display: flex;
    /* flex-direction: column; */
    font-size: 18px;
    color: rgb(255, 255, 255);
`;
const UlList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    align-items: center;
`;
const Li = styled.li`
    display: flex;
    flex-wrap: wrap;
    width: ${({ type }) =>
        type === "question"
            ? "25%"
            : type === "date"
            ? "10%"
            : type === "campus"
            ? "10%"
            : type === "comments"
            ? "10%"
            : type === "subscription"
            ? "15%"
            : type === "ask"
            ? "15%"
            : ""};

    p {
        font-family: "gordita_regular";
        font-size: 13px;
        color: #d2caca;
    }
    span {
        font-family: "gordita_medium";
        font-size: 14px;
        color: #d2caca;
    }
`;

const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    display: flex;
    font-family: "gordita_regular";
    font-size: 13px;
    color: #b7b7b7;
    width: ${({ type }) =>
        type === "question"
            ? "25%"
            : type === "date"
            ? "10%"
            : type === "campus"
            ? "10%"
            : type === "comments"
            ? "10%"
            : type === "subscription"
            ? "15%"
            : type === "ask"
            ? "15%"
            : ""};
    justify-content: ${({ type }) =>
        type === "viewbutton" ? "center" : "flex-start"};
`;

const Views = styled.div`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
    margin-top: 15px;
    @media all and (max-width: 1400px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
        margin-top: 20px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const AskButton = styled(Link)`
    background: #fe673a;
    color: #fff;
    padding: 10px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 18px;
    width: 130px;
    display: flex;
    justify-content: center;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const StudentProfile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
const StudentDetails = styled.div``;
const StudentName = styled.h4`
    font-family: "gordita_regular";
    font-size: 16px;
    color: #fff;
`;
const StudentPosition = styled.p`
    font-size: 12px;
    color: #fe673a;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const CopyButton = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
const StudentPhoto = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
    img {
        width: 100%;
        display: block;
    }
`;

const QuestionContainer = styled.div`
    margin-top: 30px;
    background: #1b1c1f;
    padding: 20px;
    border-radius: 12px;
`;
const Question = styled.div`
    display: flex;
`;
const CommentContainer = styled.div`
    width: 100%;
    margin-bottom: 30px;
    p {
        font-size: 16px;
        margin-bottom: 15px;
        color: #fff;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    small {
        font-size: 14px;
        font-family: "gordita_regular";
        color: #fff;
        line-height: 18px;
        margin-bottom: 15px;
    }
`;

const Message = styled.div`
    width: 100%;
    display: flex;
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 24px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const Error = styled.div``;
const MessageTwo = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 14px;
        color: #fff;
    }
`;
const SecondMessage = styled.div`
    width: 100%;
    display: flex;
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 24px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const MessageOne = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 14px;
        color: #fff;
    }
`;
const CommentSection = styled.div`
    display: flex;
    position: relative;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #828282;
    justify-content: space-between;
    text-align: center;
`;
const Input = styled.textarea`
    font-size: 15px;
    word-wrap: break-word;
    white-space: pre-wrap;
    outline: none;
    /* min-height: 20px; */
    /* max-height: 100px; */
    overflow-x: hidden;
    overflow-y: auto;
    color: #414141;
    width: 100%;
    resize: none;

    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        padding: 8px 8px;
        font-size: 14px;
    }
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;

const BorderContainer = styled.div`
    border: 1px solid #334155;
    border-radius: 6px;
    margin-top: 24px;
    padding: 0px 24px;
`;
const BorderTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
`;
const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Remove = styled(Link)`
    background: #fff;
    font-family: "gordita_medium";
    font-size: 13px;
    padding: 10px 16px 10px 16px;
    border-radius: 6px;
    color: #b42318;
    border: 1px solid #fda29b;
    margin-right: 18px;
`;
const Evaluate = styled(Link)`
    background: #fe673a;
    font-family: "gordita_medium";
    color: #fff;
    font-size: 13px;
    padding: 11px 17px 11px 17px;
    min-width: 153px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 41px;
`;
// removedtags>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = styled.section`
    padding-top: 22px;
`;
const ContentContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
`;
const ClearDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 154px;
    height: 40px;
    background: #1b1c20;
    border-radius: 6px;
    border: 1px solid #fe673a;
    margin-right: 15px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
`;

const TopContainers = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Search = styled.div`
    p {
        font-family: "gordita_regular";
        color: #fff;
        font-size: 14px;
    }
`;
const Headings = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px 16px 8px 16px;
    border: 2px solid #1b1c20;
    border-radius: 3px;
    margin-right: 12px;
`;
const SortConrtainer = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 8px 10px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 12px;
    span {
        font-family: "gordita_regular";
        color: #fff;
    }
`;
const SortIcon = styled.div`
    width: 25%;
    img {
        width: 100%;
        display: block;
        margin-left: 4px;
    }
`;
const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;
const Filters = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const FilterText = styled.div`
    margin-right: 5px;
    font-family: gordita_medium;
    color: #fff;
    font-size: 14px;
`;
const FIlterImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
        width: 15px;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    padding: 20px;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 384px;
    top: 0;
    right: -500px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: auto;
        transition: 0.5s ease;
        min-height: 100%;
        right: 0;
        overflow-y: scroll;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const ApplyDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 11px;
    width: 154px;
    height: 40px;
    background: #fe673a;
    border-radius: 6px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
    &.disabled {
        cursor: not-allowed;
    }
`;
const FilterDiv = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
    justify-content: space-between;
    align-items: center;
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    margin: 10px 0;
`;
const ArrowDiv = styled.div`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 20px;

    transform: rotate(90deg);

    img {
        width: 100%;
        display: block;
    }
`;

const AccountFilter = styled.div`
    display: flex;
    align-items: center;
`;
const DatePickDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    background: #161618;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #272729;
    font-size: 14px;
    width: 146px;
    height: 50px;
    cursor: pointer;
    z-index: unset !important;
    @media all and (max-width: 480px) {
        padding: 13px 5px;
    }
    /* position: relative; */

    & .react-date-picker__calendar {
        inset: inherit !important;
        top: -175px !important;
        left: -38px !important;
        z-index: 201;
        width: 375px !important;
    }

    &:last-child .react-date-picker__calendar {
        left: -218px !important;
    }

    &:last-child {
        margin: 0 0 0 10px;
    }

    &.active {
        border: 1px solid #fe673a;
    }
`;
const ArticleDivModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    width: 100%;
    max-height: 219px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-bottom: 10px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SearchDivModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    width: 100%;
    max-height: 219px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    top: 290px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SelectDiv = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
`;
const TagContainer = styled.div`
    padding: 8px 10px 8px 10px;
    background: #1d1c1c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 56%;
    border-radius: 6px;
`;
const Tag = styled.div`
    font-family: "gordita_regular";
`;
const TagIcon = styled.div`
    width: 12px;
    img {
        width: 100%;
        display: block;
    }
`;
const ContainerDiv = styled.div``;
const Small = styled.span`
    color: #838488;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 15px;
`;
const SelectOption = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Select = styled.div`
    display: inline-flex;
    padding: 6px 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background: #3b3b3b;
`;
const MainImg = styled.small`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    margin-right: 8px;
`;
const MianText = styled.h5`
    color: #fff;
    font-size: 14px;
    height: 16px;
`;
const CloseImg = styled.span`
    display: inline-block;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
    }
`;

const SearchContent = styled.div`
    font-size: 16px;
    border-bottom: 1px solid #777;
    font-family: gordita_medium;
    padding: 14px 20px;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    }
`;
const SearchBar = styled.div`
    // padding: 10px 24px;
    background: #161619;
    border-radius: 5px;
    width: 310px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background: #161619;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 10px 10px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const SearchImg = styled.img`
    width: 100%;
    display: block;
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    max-length: 20;
`;

const MainContainers = styled.div`
    min-width: 1280px;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    border-radius: 8px 8px 0 0;
    background-color: #1b1c1f;
`;

const Text = styled.h3`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "18%"
            : type === "category"
            ? "50%"
            : type === "category_image"
            ? "15%"
            : type === "actions" && "15%"};
    display: flex;
    align-items: center;
`;

const DataContainer = styled.div``;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    background-color: #1b1c1f;
    cursor: pointer;
    &:hover {
        background-color: #161619;
    }
`;
const Sections = styled.div`
    color: #d2caca;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "category_image"
            ? "10%"
            : type === "parent_category"
            ? "25%"
            : type === "actions" && "15%"};

    display: flex;
    align-items: center;

    p {
        color: #d2caca;
    }
    span {
        height: 30px;
        /* width: 30px; */
        img {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
`;

const BottomContainers = styled.div`
    margin-top: 20px;
`;

const Edit = styled.button`
    border-radius: 6px;
    border: 2px solid #2c2c2c;
    background: #fff;
    padding: 10px 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    p {
        font-family: "gordita_regular";
        font-size: 12px;
        color: #000;
    }
`;
const RestoreButton = styled.div``;
const ImageBox = styled.span`
    width: 20px;
    display: inline-block;
    img {
        display: block;
        width: 100%;
    }
`;
const Delete = styled.button`
    margin-left: 10px;
    font-family: "gordita_regular";
    color: #ff3030;
    font-size: 14px;
    padding: 10px 28px;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const SearchDiv = styled.div`
    display: flex;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    padding: 12px 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
`;
const SearchTxt = styled.input`
    color: #fff;
    font-size: 18px;
    width: 100%;

    &::placeholder {
        color: #fff;
        font-size: 18px;
    }
`;
const ArticleIcon = styled.small`
    display: inline-block;
    width: 18px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const Sort = styled.button`
    position: relative;
    margin-right: 8px;
    img {
        display: block;
        width: 100%;
    }
`;
const SortDiv = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const Spaan = styled.span`
    width: 24px;
`;
const Smaall = styled.small`
    color: #7b7b7b;
    font-size: 0.875rem;
    font-family: gordita_medium;

    cursor: pointer;
    &.white {
        color: #fff;

        margin-right: 8px;
    }
    &.whites {
        color: #f2f2f2;

        line-height: normal;
        margin-right: 0;
        margin-left: 4px;
    }
`;

// const PaginationContainer = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 40px 0 15px;
//     @media all and (max-width: 890px) {
//         flex-direction: column;
//     }
// `;
// const PaginationText = styled.p`
//     color: #898989;
//     font-size: 14px;
//     @media all and (max-width: 890px) {
//         margin-bottom: 10px;
//     }
// `;

export default ReportViewPage;
