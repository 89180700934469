import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { managersConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
//components
import SectionLoader from "../../helpers/SectionLoader";
import JobApplicantsList from "../../includes/jobdesk/JobApplicantsList";
import JobDeskApplicantsFilter from "../../includes/jobdesk/JobDeskApplicantsFilter";
import NoData from "../../includes/jobdesk/NoData";

function JobApplicants() {
    const [applicantsData, setApplicantsData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isUpdated, setUpdated] = useState(false);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    //filter
    const [activeClick, setActiveClick] = useState("Filter");
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [searchWord, setSearchWord] = useState("");
    const today = new window.Date();

    useEffect(() => {
        if (searchParams.get("q")) {
            setActiveClick("Search");
        } else if (searchParams.get("timestamp") === "today") {
            setActiveClick("Today");
        } else if (searchParams.get("timestamp") === "this_week") {
            setActiveClick("This Week");
        } else if (searchParams.get("timestamp") === "this_month") {
            setActiveClick("This Month");
        } else if (searchParams.get("category") === "Student") {
            setActiveClick("Student");
        } else if (searchParams.get("category") === "Graduate") {
            setActiveClick("Graduate");
        } else if (searchParams.get("category") === "Drop_out") {
            setActiveClick("Dropout");
        } else if (searchParams.get("category") === "employee") {
            setActiveClick("Employee");
        } else if (searchParams.get("student_status") === "approved") {
            setActiveClick("Accepted");
        } else if (searchParams.get("student_status") === "rejected") {
            setActiveClick("Rejected");
        } else if (searchParams.get("student_status") === "pending") {
            setActiveClick("Pending");
        } else {
            setActiveClick("Filter");
        }
    });

    // const
    // getFilterParams = () => {
    //     let filterParams = {};
    //     searchParams.get("q") && (filterParams.q = searchParams.get("q"));
    //     searchParams.get("timestamp") === "today" &&
    //         (filterParams.timestamp = "today");
    //     searchParams.get("timestamp") === "this_week" &&
    //         (filterParams.timestamp = "this_week");
    //     searchParams.get("timestamp") === "this_month" &&
    //         (filterParams.timestamp = "this_month");
    //     searchParams.get("category") === "student" &&
    //         (filterParams.category = "student");
    //     searchParams.get("category") === "graduate" &&
    //         (filterParams.category = "graduate");
    //     searchParams.get("category") === "drop_out" &&
    //         (filterParams.category = "drop_out");
    //     searchParams.get("category") === "employee" &&
    //         (filterParams.category = "employee");
    //     searchParams.get("student_status") === "approved" &&
    //         (filterParams.student_status = "approved");
    //     searchParams.get("student_status") === "rejected" &&
    //         (filterParams.student_status = "rejected");
    //     searchParams.get("student_status") === "pending" &&
    //         (filterParams.student_status = "pending");
    //     searchParams.get("applied_date") &&
    //         (filterParams.applied_date = dateConversion(
    //             searchParams.get("applied_date")
    //         ));
    //     searchParams.get("from_date") &&
    //         (filterParams.from_date = dateConversion(
    //             searchParams.get("from_date")
    //         ));
    //     searchParams.get("to_date") &&
    //         (filterParams.to_date = dateConversion(
    //             searchParams.get("to_date")
    //         ));
    //     return filterParams;
    // };

    const dateConversion = (value) => {
        if (value) {
            let values = value.split("-");
            const dd = values[0];
            const mm = values[1];
            const yy = values[2];
            let date = yy + "-" + mm + "-" + dd;
            return date;
        }
        return "";
    };

    // useEffect(() => {
    //     const fetchjobapplicants = () => {
    //         const params = getFilterParams();
    //         managersConfig
    //             .get("web/job-desk/students/", {
    //                 headers: {
    //                     Authorization: `Bearer ${access_token}`,
    //                 },
    //                 params: params,
    //             })
    //             .then((response) => {
    //                 let { StatusCode, data } = response.data;
    //                 if (StatusCode === 6000) {
    //                     setApplicantsData(data);
    //                     setLoading(false);
    //                     setSearchWord("");
    //                     setSelectedDate("");
    //                 } else {
    //                     setLoading(false);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setLoading(false);
    //             });
    //     };
    //     fetchjobapplicants();
    // }, [searchParams, isUpdated]);

    const getCollegeFilterParams = () => {
        let filterParams = {};
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("timestamp") === "today" &&
            (filterParams.timestamp = "today");
        searchParams.get("timestamp") === "this_week" &&
            (filterParams.timestamp = "this_week");
        searchParams.get("timestamp") === "this_month" &&
            (filterParams.timestamp = "this_month");
        searchParams.get("from_date") &&
            (filterParams.from_date = dateConversion(
                searchParams.get("from_date")
            ));
        searchParams.get("applied_date") &&
            (filterParams.applied_date = dateConversion(
                searchParams.get("applied_date")
            ));
        searchParams.get("to_date") &&
            (filterParams.to_date = dateConversion(
                searchParams.get("to_date")
            ));
        searchParams.get("student_status") === "approved" &&
            (filterParams.student_status = "approved");
        searchParams.get("student_status") === "rejected" &&
            (filterParams.student_status = "rejected");
        searchParams.get("student_status") === "pending" &&
            (filterParams.student_status = "pending");
        return filterParams;
    };

    useEffect(() => {
        const fetchjobapplicants = () => {
            const params = getCollegeFilterParams();
            managersConfig
                .get("web/job-desk/students/?category=college", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: params,
                })
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setApplicantsData(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        };
        fetchjobapplicants();
    }, [searchParams, isUpdated]);

    return (
        <>
            <MainContainer>
                <TopContainer>
                    <Heading>College Students</Heading>
                    <FilterDiv>
                        <JobDeskApplicantsFilter
                            activeClick={activeClick}
                            setActiveClick={setActiveClick}
                            today={today}
                            setLoading={setLoading}
                            isLoading={isLoading}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            searchWord={searchWord}
                            setSearchWord={setSearchWord}
                        />
                    </FilterDiv>
                </TopContainer>

                {isLoading ? (
                    <SectionLoader />
                ) : applicantsData?.length > 0 ? (
                    // <BottomContainer>
                    <JobApplicantsList
                        data={applicantsData}
                        key={applicantsData.id}
                        isUpdated={isUpdated}
                        setUpdated={setUpdated}
                    />
                ) : (
                    // </BottomContainer>
                    <NoData />
                )}
            </MainContainer>
        </>
    );
}
const MainContainer = styled.div``;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;
const FilterDiv = styled.div``;
const BottomContainer = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    @media all and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    margin-top: 170px;
    height: calc(100% - (-50px));
    margin-bottom: 170px;
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;

const CardImage = styled.img`
    width: 100%;
    display: block;
`;
export default JobApplicants;
