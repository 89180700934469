import React from "react";
//packages
import styled from "styled-components";
import { Link } from "react-router-dom";

function SingleAgentCard({ item, index }) {
    return (
        <MainContent key={item.id} to={`${item.id}`}>
            <Content type="slno">{index + 1}</Content>
            <Content type="name">{item?.name}</Content>
            <Content type="district">{item.location_details?.district}</Content>
            <Content type="type">{item.location_details?.type}</Content>
            <Content type="totalExamCompleted">
                {item.total_exams?.total_exam_taken}
            </Content>
            <Content type="admission">{item?.total_admissions}</Content>
            <Content
                className="ExpiryStatus"
                type={item?.total_exams.is_center_expired}
            >
                {item.total_exams?.is_center_expired}
            </Content>
        </MainContent>
    );
}
const Container = styled.div`
    margin: 20px 0px;
`;
const ScrollDiv = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const HeadDiv = styled.div`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    text-transform: capitalize;
    font-family: "gordita_medium";
    font-size: 14px;
    text-align: left;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "name"
            ? "20%"
            : type === "district"
            ? "20%"
            : type === "type"
            ? "15%"
            : type === "totalExamCompleted"
            ? "15%"
            : type === "admission"
            ? "10%"
            : type === "ExpiryStatus"
            ? "10%"
            : ""};
    :nth-child(7) {
        text-align: right;
    }
`;
const Content = styled.div`
    text-transform: capitalize;
    font-family: "gordita_regular";
    font-size: 14px;
    text-align: left;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "name"
            ? "20%"
            : type === "district"
            ? "20%"
            : type === "type"
            ? "15%"
            : type === "totalExamCompleted"
            ? "15%"
            : type === "admission"
            ? "10%"
            : type === "ExpiryStatus"
            ? "10%"
            : ""};
    :nth-child(7) {
        text-align: right;
    }
    &.ExpiryStatus {
        color: ${({ type }) => (type === "active" ? "#14C25A" : "#E94C68")};
        width: 10%;
    }
`;
const MainContentDiv = styled.div`
    color: rgb(255, 255, 255);
    background-color: #1b1c1f;
    width: 100%;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
    width: 100%;
`;
const SubContent = styled.div`
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
`;
const MainContent = styled(Link)`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    font-size: 14px;
    text-align: left;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
    font-family: "gordita_regular";
    :last-child {
        border-bottom: 0px solid #000;
    }
`;
export default SingleAgentCard;
