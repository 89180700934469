import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { primeConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import SectionLoader from "../../helpers/SectionLoader";
import { getDateStr } from "../../helpers/functions";
import useDetectClickOut from "../../helpers/useDetectClickOut";
import Filter from "../../includes/primeprogrammes/PrimeProgrammeFilter";

export default function PrimeProgrammesSingle() {
    const [isLoading, setLoading] = useState(true);
    const [isPageLoading, setPageLoading] = useState(false);
    const { state } = useContext(Context);
    const [primeData, setPrimeData] = useState([]);
    const [courseName, setCourseName] = useState("");
    const [coursesData, setCoursesData] = useState([]);
    const [pagination, setPagination] = useState({});
    const location = useLocation();
    const { primeprogramme_id } = useParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [isClearFilter, setClearFilter] = useState(false);
    const { show, nodeRef, triggerRef, setShow } = useDetectClickOut(false);
    const [appliedDate, setAppliedDate] = useState("");
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedCoupon, setSelectedCoupon] = useState("Select");
    const [searchParams, setSearchParams] = useSearchParams();
    let { search } = location;
    const values = queryString.parse(search);
    let page = values.page;
    let enrolled_time = values.enrolled_time;
    let coupon_code = values.selectedCoupon;
    let status = values.selectedStatus;

    useEffect(() => {
        const setInitialSearch = () => {
            const page = searchParams.get("page");
            setCurrentPage(page ? page : 1);
        };
        setInitialSearch();
    }, [searchParams]);

    function handlePageClick({ selected: selectedPage }) {
        setSearchParams({ page: selectedPage + 1 });
    }

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    useEffect(() => {
        currentPage && fetchPrimeData();
    }, [currentPage, isClearFilter]);

    const fetchPrimeData = () => {
        setPageLoading(true);
        setLoading(true);
        primeConfig
            .get(`purchases/sro/students/${primeprogramme_id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    page: currentPage,
                    // enrolled_time: enrolled_time,
                    // coupon_code: selectedCoupon,
                    // status: selectedStatus,
                },
            })
            .then((response) => {
                const { StatusCode, data, pagination_data, course } =
                    response.data;
                if (StatusCode === 6000) {
                    setCourseName(course);
                    setPrimeData(data);
                    setCoursesData(data.student_profile);
                    setPagination(pagination_data);
                    setLoading(false);
                    setPageLoading(false);
                } else {
                    setLoading(false);
                    setPageLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setPageLoading(false);
            });
    };

    const clearFilter = () => {
        // history.push({
        //     pathname: `/prime-programs/${primeprogramme_id}/`,
        //     search: `?page=${currentPage}`,
        // });
        setAppliedDate("");
        setSelectedStatus("");
        setSelectedCoupon("Select");
        setClearFilter(false);
        fetchPrimeData();
    };
    return (
        <>
            <Container>
                <NavContainer>
                    <Heading>
                        <Title>Prime Programs</Title>

                        {/* <FilterContainer>
                            {isClearFilter && (
                                <div
                                    onClick={clearFilter}
                                    className="clear-filter"
                                >
                                    <CloseIcon
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/close.svg"
                                        }
                                        alt=""
                                    />
                                </div>
                            )}

                            <FilterButton ref={triggerRef}>
                                Filter
                                <Icon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/filter.svg"
                                    alt=""
                                    width={15}
                                />
                            </FilterButton>
                            <div ref={nodeRef}>
                                <Filter
                                    setShow={setShow}
                                    show={show}
                                    setLoading={setLoading}
                                    appliedDate={appliedDate}
                                    setAppliedDate={setAppliedDate}
                                    setClearFilter={setClearFilter}
                                    selectedStatus={selectedStatus}
                                    setSelectedStatus={setSelectedStatus}
                                    selectedCoupon={selectedCoupon}
                                    setSelectedCoupon={setSelectedCoupon}
                                    fetchPrimeData={fetchPrimeData}
                                />
                            </div>
                        </FilterContainer> */}
                    </Heading>
                    <PathSection>
                        <ImageBox>
                            <Image src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/syllubus.svg" />
                        </ImageBox>
                        <Path>
                            Prime Programs {courseName && <> / {courseName} </>}
                        </Path>
                    </PathSection>
                </NavContainer>
            </Container>
            {isLoading ? (
                <SectionLoader />
            ) : (
                <>
                    {primeData.length > 0 ? (
                        <>
                            <Container>
                                <VerificationCard>
                                    <VerificationHeader>
                                        {/* <IdLabel type="heading">No</IdLabel> */}
                                        <UserId type="heading">User ID</UserId>
                                        <Name type="heading">Name</Name>
                                        <Phone type="heading">Phone</Phone>
                                        <Purchased type="heading">
                                            Purchased On
                                        </Purchased>
                                        <Payment type="heading">
                                            Payment
                                        </Payment>
                                        <Discount type="heading">
                                            Discount
                                        </Discount>
                                        <Coupon type="heading">Coupon</Coupon>
                                        <Status type="heading">Status</Status>
                                    </VerificationHeader>
                                    {primeData.map((data) => {
                                        return (
                                            <>
                                                {isPageLoading ? (
                                                    <SectionLoader />
                                                ) : (
                                                    <VerificationListCard>
                                                        {/* <IdLabel>
                                                    {data.auto_id}
                                                </IdLabel> */}
                                                        <UserId>
                                                            {
                                                                data
                                                                    .student_profile
                                                                    .user_pk
                                                            }
                                                        </UserId>
                                                        <Name>
                                                            {data
                                                                .student_profile
                                                                .name
                                                                ? data.student_profile.name.toLowerCase()
                                                                : "--"}
                                                        </Name>
                                                        <Phone>
                                                            {
                                                                data
                                                                    .student_profile
                                                                    .phone
                                                            }
                                                        </Phone>

                                                        <Purchased>
                                                            {data?.date_added
                                                                ? getDateStr(
                                                                      data.date_added
                                                                  )
                                                                : "--"}
                                                        </Purchased>

                                                        <Payment>
                                                            {data.amount >= 0
                                                                ? data.amount
                                                                : "--"}
                                                        </Payment>
                                                        <Discount>
                                                            {data.discount ? (
                                                                <>
                                                                    {
                                                                        data.discount
                                                                    }
                                                                    %
                                                                </>
                                                            ) : (
                                                                "--"
                                                            )}
                                                        </Discount>
                                                        <Coupon>
                                                            {data.coupon
                                                                ? data.coupon
                                                                : "--"}
                                                            {/* <CouponApplied>
                                                                {data.price_data
                                                                    .coupon_amount ? (
                                                                    <>
                                                                        ₹{" "}
                                                                        {
                                                                            data
                                                                                .price_data
                                                                                .coupon_amount
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </CouponApplied> */}
                                                        </Coupon>
                                                        <Status
                                                            type={
                                                                data.is_completed
                                                            }
                                                        >
                                                            {data?.is_completed
                                                                ? "Completed"
                                                                : "Ongoing"}
                                                        </Status>
                                                    </VerificationListCard>
                                                )}
                                            </>
                                        );
                                    })}
                                </VerificationCard>
                            </Container>
                        </>
                    ) : (
                        <ImageBoxContainer>
                            <CardImage
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/Group 676.svg"
                                alt="Image"
                            />
                        </ImageBoxContainer>
                    )}
                </>
            )}
            {pagination?.total_pages > 1 && (
                <PaginationContainer>
                    <PaginationText>
                        Showing students {pagination?.first_item} -{" "}
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </>
    );
}

const Container = styled.div`
    padding: 20px;
    background-color: #1b1c1f;
    padding-bottom: 0;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const VerificationCard = styled.div`
    padding: 15px;
    background-color: #161619;
    border-radius: 5px;
    min-width: 1200px;
`;
const VerificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    min-width: 850px;
`;
const IdLabel = styled.p`
    width: 10%;
    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    text-align: left;
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
`;
const Name = styled.p`
    width: 20%;
    text-align: left;
    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
    text-transform: capitalize;
`;
const Phone = styled.p`
    width: 20%;

    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
`;

const UserId = styled.p`
    width: 20%;

    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
`;
const Purchased = styled.p`
    width: 20%;

    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
`;
const Coupon = styled.p`
    width: 20%;

    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
`;
const Payment = styled.p`
    width: 20%;

    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
`;
const Discount = styled.p`
    width: 20%;

    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
    color: ${(props) => (props.type === "heading" ? "#ffff" : "#d4d4d4")};
`;
const Status = styled.p`
    width: 20%;
    text-align: right;
    color: ${(props) =>
        props.type === "heading"
            ? "#ffff"
            : props.type === true
            ? "#1ec569"
            : "#eb5733"};
    font-size: ${(props) => (props.type === "heading" ? "16px" : "14px")};
    font-family: ${(props) =>
        props.type === "heading" ? "Gordita_medium" : "gordita_regular"};
`;
const VerificationListCard = styled.div`
    background-color: #1b1c1f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 10px 21px;
    margin-bottom: 15px;
    min-width: 850px;
`;
const Statuss = styled.p`
    width: 20%;
    text-align: center;
    font-size: 14px;
    font-family: "Gordita_medium";
`;
const NavContainer = styled.div`
    background: #1b1c1f;
    border-radius: 5px;
`;
const Title = styled.h6`
    font-size: 22px;
    font-family: "Gordita_medium";
    color: #fff;
`;
const PathSection = styled.div`
    background: #161619;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
`;
const Path = styled.h6`
    color: #9b9b9c;
    margin-left: 10px;
    font-size: 13px;
`;
const ImageBox = styled.div`
    width: 13px;
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
const FilterButton = styled.span`
    width: 90px;
    padding: 3px 10px;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Gordita_medium";
    cursor: pointer;
    border-radius: 5px;
    background-color: #161619;
`;
const FilterIcon = styled.img`
    margin-left: 7px;
`;
const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px; */
`;
const CouponApplied = styled.span`
    display: block;
    color: #1ec569;
`;
const FilterContainer = styled.div`
    position: relative;
    div.clear-filter {
        position: absolute;
        left: -37px;
        top: 3px;

        ::before {
            position: absolute;
            content: " ";
            left: -30px;
            width: 30px;
            background-color: red;
            transition: ease 0.5s;
        }
    }
`;
const CloseIcon = styled.img`
    display: block;
    width: 70%;
    cursor: pointer;
`;
const Icon = styled.img`
    margin-left: 10px;
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;

const ImageBoxContainer = styled.div`
    margin: 0 auto;
    width: 27%;
    margin-top: 100px;
`;
const CardImage = styled.img`
    width: 100%;
    display: block;
`;
