import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function ExamFilter({
    activeClick,
    isDropdown,
    setDropdown,
    setActiveClick,
    setButton,
    isButton,
    searchWord,
    setSearchWord,
    wrapperRef,
    setDownloadDropdown,
    isDownloadDropdown,
}) {
    return (
        <TopFilter>
            <CoverSet ref={wrapperRef}>
                <Filter
                    onClick={() => (
                        setDropdown(!isDropdown), setDownloadDropdown(false)
                    )}
                >
                    <FilterText>{activeClick}</FilterText>
                    <FIlterImage>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                            alt="Image"
                        />
                    </FIlterImage>
                </Filter>

                <Popup className={isDropdown ? "dropdown" : ""}>
                    <Date
                        onClick={() => {
                            setActiveClick("Search");
                        }}
                        className="search"
                    >
                        <input
                            type="text"
                            placeholder="Search Here"
                            onChange={(e) => setSearchWord(e.target.value)}
                        ></input>
                        <ImageContainer
                            to={`?q=${searchWord}`}
                            onClick={() => {
                                setDropdown(false);
                            }}
                        >
                            <img
                                src={
                                    require("../../../assets/images/sat-examiner/search.svg")
                                        .default
                                }
                                alt="Search"
                            />
                        </ImageContainer>
                    </Date>

                    <CategoryButton onClick={() => setButton(!isButton)}>
                        {!(
                            activeClick === "Filter" ||
                            activeClick === "Search" ||
                            activeClick === "Eligible" ||
                            activeClick === "Not Eligible" ||
                            activeClick === "Not Attended"
                        )
                            ? activeClick
                            : "Select Category"}
                        <CategoryImage isButton={isButton}>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                alt="Image"
                            />
                        </CategoryImage>
                    </CategoryButton>
                    <PopupDropDown
                        className={isButton ? "categorydropdown" : ""}
                    >
                        <SelectOption
                            to={`?category=school`}
                            className={
                                activeClick === "Tech Schooling" ? "active" : ""
                            }
                            onClick={() => (
                                setActiveClick("Tech Schooling"),
                                setDropdown(false),
                                setButton(false)
                            )}
                        >
                            <Span>
                                {activeClick === "Tech Schooling" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Tech Schooling</Text>
                        </SelectOption>
                        <SelectOption
                            to={`?category=college`}
                            className={
                                activeClick === "Tech Degree" ? "active" : ""
                            }
                            onClick={() => (
                                setActiveClick("Tech Degree"),
                                setDropdown(false),
                                setButton(false)
                            )}
                        >
                            <Span>
                                {activeClick === "Tech Degree" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Tech Degree</Text>
                        </SelectOption>
                        <SelectOption
                            to={`?category=graduates`}
                            className={
                                activeClick === "Tech Grad" ? "active" : ""
                            }
                            onClick={() => (
                                setActiveClick("Tech Grad"),
                                setDropdown(false),
                                setButton(false)
                            )}
                        >
                            <Span>
                                {activeClick === "Tech Grad" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Tech Grad</Text>
                        </SelectOption>
                    </PopupDropDown>

                    <Small>Exam Status</Small>
                    <HrLine className="Exam Status"></HrLine>
                    <SelectOption
                        to={`?exam_status=eligible`}
                        className={activeClick === "Eligible" ? "active" : ""}
                        onClick={() => (
                            setActiveClick("Eligible"), setDropdown(false)
                        )}
                    >
                        <Span>
                            {activeClick === "Eligible" ? (
                                <SelectedImg>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                        alt="image"
                                    />
                                </SelectedImg>
                            ) : (
                                <UnSelectedImg>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                        alt="image"
                                    />
                                </UnSelectedImg>
                            )}
                        </Span>
                        <Text>Eligible</Text>
                    </SelectOption>
                    <SelectOption
                        to={`?exam_status=not_eligible`}
                        className={
                            activeClick === "Not Eligible" ? "active" : ""
                        }
                        onClick={() => (
                            setActiveClick("Not Eligible"), setDropdown(false)
                        )}
                    >
                        <Span>
                            {activeClick === "Not Eligible" ? (
                                <SelectedImg>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                        alt="image"
                                    />
                                </SelectedImg>
                            ) : (
                                <UnSelectedImg>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                        alt="image"
                                    />
                                </UnSelectedImg>
                            )}
                        </Span>
                        <Text>Not Eligible</Text>
                    </SelectOption>

                    <SelectOption
                        to={`?exam_status=not-attended`}
                        className={
                            activeClick === "Not Attended" ? "active" : ""
                        }
                        onClick={() => (
                            setActiveClick("Not Attended"), setDropdown(false)
                        )}
                    >
                        <Span>
                            {activeClick === "Not Attended" ? (
                                <SelectedImg>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                        alt="image"
                                    />
                                </SelectedImg>
                            ) : (
                                <UnSelectedImg>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                        alt="image"
                                    />
                                </UnSelectedImg>
                            )}
                        </Span>
                        <Text>Not Attended</Text>
                    </SelectOption>
                </Popup>
            </CoverSet>
        </TopFilter>
    );
}

const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    // margin-top: -35px;
`;
const CoverSet = styled.div``;
const Filter = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 6px 12px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
`;
const FilterText = styled.div`
    margin-right: 5px;
`;
const FIlterImage = styled.div`
    & img {
        width: 12px;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    width: 250px;
    padding: 10px;
    position: absolute;
    right: 17px;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 1;
    border: 1px solid #28292d;
    &.dropdown {
        opacity: 1;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        margin-top: 10px;
        overflow: visible;
        background: #1b1c20;
        max-height: 500px;
        transition: 0.5s ease;
        border-radius: 5px;
    }
`;
const Date = styled.div`
    text-align: center;
    background: #161618;
    display: flex;
    border: 1px solid #26272a;
    border-radius: 4px;
    align-items: center;
    color: #fff;
    cursor: pointer;
    padding: 0 10px;
    .react-datepicker__day:hover {
        border: 1px solid #fff !important;
        color: #fff !important;
        background-color: red !important;
    }
    input {
        color: #fff;
        width: 90%;
        height: 40px;
        padding: 10px;
    }
`;
const ImageContainer = styled(Link)`
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const CategoryButton = styled.div`
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #161618;
    border: 1px solid #26272a;
    padding: 6px 12px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    justify-content: space-between;
    position: relative;
    padding: 10px;
    margin-top: 10px;
`;
const CategoryImage = styled.div`
    & img {
        width: 12px;
        transform: ${({ isButton }) =>
            isButton ? "rotate(180deg)" : "rotate(0deg)"};
        transition: 0.5s ease;
    }
`;
const PopupDropDown = styled.div`
    font-size: 14px;
    color: #fff;
    width: 225px;
    padding: 10px;
    position: absolute;
    right: 12px;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 1;
    border: 1px solid #28292d;
    &.categorydropdown {
        opacity: 1;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        margin-top: 10px;
        overflow: visible;
        background: #1b1c20;
        max-height: 500px;
        transition: 0.5s ease;
        border-radius: 5px;
    }
`;
const SelectOption = styled(Link)`
    display: flex;
    cursor: pointer;
    padding: 10px;
    position: relative;
    align-items: center;
    // margin-bottom: 10px;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Text = styled.div`
    font-size: 12px;
`;
const Small = styled.span`
    color: #fff;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 15px;
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    &.students {
        margin-bottom: 10px;
    }
`;
