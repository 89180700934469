import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SchoolScientistDashboard from "../../../screens/schoolScientist/SchoolScientistDashboard";
import MathematicalSkill from "../../../screens/schoolScientist/MathematicalSkill";
import StudentsUP from "../../../screens/schoolScientist/StudentsUP";
import StudentsHS from "../../../screens/schoolScientist/StudentsHS";
import StudentsHSS from "../../../screens/schoolScientist/StudentsHSS";
import SchoolScientistScoreBoard from "../../../screens/schoolScientist/SchoolScientistScoreBoard";
import Schools from "../../../screens/schoolScientist/Schools";
import SchoolsSinglePage from "../../../screens/schoolScientist/SchoolsSinglePage";
import ViewStudents from "../../../screens/schoolScientist/ViewStudents";
import Attendees from "../../../screens/schoolScientist/Attendees";
import Winners from "../../../screens/schoolScientist/Winners";
import AttendeesCategoryList from "../../../screens/schoolScientist/AttendeesCategoryList";
import CategoryPage from "../../../screens/schoolScientist/CategoryPage";
import TotalRoundCategory from "../../../screens/schoolScientist/TotalRoundCategory";

function SchoolScientistRouter() {
	return (
		<Routes>
			<Route path="/" element={<Navigate to="/dashboard/" />} />
			<Route path="/dashboard/" element={<SchoolScientistDashboard />} />
			<Route path="/questions/" element={<MathematicalSkill />} />
			<Route path="/students/up/" element={<StudentsUP />} />
			<Route path="/students/hs/" element={<StudentsHS />} />
			<Route path="/students/hss/" element={<StudentsHSS />} />
			<Route
				path="/students/scoreboard/"
				element={<SchoolScientistScoreBoard />}
			/>
			<Route path="/school/" element={<Schools />} />
			<Route path="/school/:id" element={<SchoolsSinglePage />} />
			<Route path="/students/" element={<ViewStudents />} />
			<Route path="/attendees/" element={<Attendees />} />
			<Route path="/winners/" element={<Winners />} />
            <Route path="/attendees/:id" element={<AttendeesCategoryList />} />
			<Route path="/winners/:id" element={<CategoryPage />} />
			<Route path="//participants/" element={<TotalRoundCategory />} />
		</Routes>
	);
}

export default SchoolScientistRouter;
