import React from "react";
import styled from "styled-components";

export default function ImageUploading({
    setSelectedImage,
    selectedImage,
    Title,
    errorMassage,
    setErrorMessage,
}) {
    /** Handles the drop event. */
    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];

            // Check if the file is an image
            if (file.type.startsWith("image/")) {
                setSelectedImage({
                    file: file,
                    // name: name,
                    // url: url,
                });
            }
        }
    };

    /* Handles the drag over event. Prevents the default browser behavior.*/
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    const file = (event) => {
        const selectedFile = event.target.files[0];
        setSelectedImage(selectedFile);
    };

    return (
        <IntroImage>
            <Intro>{Title ? Title : "Thumbnail"}</Intro>
            <Drop onDrop={handleDrop} onDragOver={handleDragOver}>
                <LabelDiv htmlFor="image-upload">
                    <Img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-04-2024/featuredicon.svg"
                        alt="Upload icon"
                        style={{ width: "50px" }}
                    />
                </LabelDiv>
                <Upload>Click to upload</Upload>
                <File>File supported: JPEG, PNG</File>
                {selectedImage && (
                    <SelectedName>
                        Selected image:{" "}
                        {selectedImage.name
                            ? selectedImage.name
                            : selectedImage}
                    </SelectedName>
                )}
                <InputText
                    type="file"
                    id="image-upload"
                    onChange={file}
                    accept="image/jpeg,image/png"
                    style={{ display: "none" }}
                />
            </Drop>
            {errorMassage && errorMassage?.image ? (
                <Error>{errorMassage?.image[0]}</Error>
            ) : null}
        </IntroImage>
    );
}
const Error = styled.p`
    font-size: 12px;
    color: red;
    margin-top: 6px;
`;
const IntroImage = styled.div`
    width: 40%;
    margin-bottom: 15px;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Intro = styled.h3`
    font-size: 14px;
    color: #cdd5df;
    margin-bottom: 10px;
`;
const Drop = styled.div`
    cursor: pointer;
    border: 1px solid #3b3b3b;
    padding: 40px 20px;
    width: 94%;
    border-radius: 9px;
    text-align: center;
    @media all and (max-width: 1280px) {
        width: 96%;
        padding: 69px 14px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        margin-bottom: 15px;
    }
    @media all and (max-width: 980px) {
        padding: 40px 7px;
    }
`;
const LabelDiv = styled.label`
    margin-bottom: 7px;
    display: block;
    cursor: pointer;
`;
const Img = styled.img`
    display: block;
    width: 100%;
    margin: 0 auto;
`;
const SelectedName = styled.span`
    font-size: 12px;
    color: #ffffff;
`;
const Upload = styled.h3`
    font-size: 15px;
    text-align: center;
    color: #fe673a;
    font-family: gordita_medium;
`;
const File = styled.h3`
    font-size: 14px;
    color: #475467;
    text-align: center;
    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
`;

const InputText = styled.input``;
