import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import SectionLoader from "../../../helpers/SectionLoader";
const StaffEdit = lazy(() =>
    import("../../../screens/staff-registaration/StaffEdit")
);
const Staffs = lazy(() =>
    import("../../../screens/staff-registaration/Staffs")
);

const EmployRouter = () => {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Routes>
                <Route path="staffs/" element={<Staffs />} />
                <Route path="staffs/edit/:id/" element={<StaffEdit />} />
                <Route path="staffs/view/:id/" element={<StaffEdit />} />
                <Route path="staffs/create/" element={<StaffEdit />} />
                <Route path="engineers/" element={<Staffs />} />
                <Route path="deleted/" element={<Staffs />} />
            </Routes>
        </Suspense>
    );
};

export default EmployRouter;
