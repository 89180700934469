import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";

// React Component
const MemberManagement = ({
    members,
    setSearchInput,
    searchInput,
    selectedUserIds,
    setSelectedUserIds,
    setError,
    setCount,
}) => {
    const handleItemClick = (userId) => {
        setSelectedUserIds((prevSelectedIds) => {
            if (prevSelectedIds?.includes(userId)) {
                return prevSelectedIds.filter((id) => id !== userId);
            } else {
                return [...prevSelectedIds, userId];
            }
        });
    };

    useEffect(() => {
        setCount(selectedUserIds?.length);
    }, [selectedUserIds]);

    return (
        <Container>
            {/* <Heading>Add Student</Heading> */}
            <BottomSection>
                <Label>Search student</Label>
                <SearchBar
                    type="text"
                    placeholder="Enter ID, phone number or name"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <ScrollView>
                    <MemberList>
                        {members.map((item) => (
                            <MemberItem
                                key={item.id}
                                onClick={() => {
                                    handleItemClick(item.id);
                                    setError("");
                                }}
                                selected={selectedUserIds?.includes(item.id)}
                            >
                                <UserInfo>
                                    <Imgiv>
                                        {item?.icon ? (
                                            <img src={item?.icon} alt="icon" />
                                        ) : (
                                            <Avatar
                                                name={item?.name}
                                                size="40"
                                                round
                                            />
                                        )}
                                    </Imgiv>
                                    <div>
                                        <UserName>
                                            {item?.name ?? "--"}
                                        </UserName>
                                        <NumDiv>
                                            <AdmissionId>
                                                #{item?.user_id ?? "--"}
                                            </AdmissionId>
                                            <small>/</small>
                                            <UserPhone>
                                                {item?.phone ?? "--"}
                                            </UserPhone>
                                        </NumDiv>
                                    </div>
                                </UserInfo>
                                <ButtonDiv>
                                    {selectedUserIds?.includes(item.id) ? (
                                        <AddIcon>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-06-2022/greentick.svg"
                                                alt=""
                                            />
                                        </AddIcon>
                                    ) : (
                                        <RemoveIcon>-</RemoveIcon>
                                    )}
                                </ButtonDiv>
                            </MemberItem>
                        ))}
                    </MemberList>
                </ScrollView>
            </BottomSection>
        </Container>
    );
};

export default MemberManagement;

// Styled Components
const Container = styled.div`
    padding: 20px 0;
`;

const Heading = styled.h1`
    border-bottom: 1px solid rgb(59, 59, 59);
    color: #e76037;
    font-size: 16px;
    padding-bottom: 8px;
`;

const BottomSection = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
`;

const SearchBar = styled.input`
    padding: 12px 16px;
    font-size: 14px;
    border: 2px solid #333333;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #ccc;
    background: #161619 !important;
`;

const MemberList = styled.ul`
    list-style: none;
    padding: 0;
`;

const MemberItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: ${({ selected }) =>
        selected
            ? "#161619"
            : "transparent"}; /* Apply background color when selected */
    border-radius: 5px; /* Add border radius for better visual */
    cursor: pointer; /* Add cursor pointer on hover */
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

const UserImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
`;

const UserName = styled.h6`
    margin-right: 10px;
    font-size: 16px;
    color: #fff !important;
    text-transform: capitalize;
`;

const UserPhone = styled.h6`
    margin-left: 6px;
    font-size: 13px;
    color: #fff !important;
`;

const AdmissionId = styled.h6`
    margin-right: 6px;
    font-size: 13px;
    color: #fe673a !important;
`;

const AddIcon = styled.span`
    cursor: pointer;
    width: 15px;
    height: 15px;
    display: inline-block;
    img {
        width: 100%;
        display: inline-block;
    }
`;
const RemoveIcon = styled.span`
    cursor: pointer;
`;
const Label = styled.h6`
    font-size: 14px;
    color: #7b7b7b;
    margin-bottom: 6px;
`;
const NumDiv = styled.div`
    display: flex;
    small {
        color: #fff !important;
    }
`;
const ButtonDiv = styled.div``;
const ScrollView = styled.div`
    overflow: scroll;
    max-height: 260px;
    &::-webkit-scrollbar {
        display: none;
    }
    scroll-behavior: smooth;
`;
const Imgiv = styled.div`
    margin: 0 auto;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 12px;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
