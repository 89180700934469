import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SteypHelmet from "../../helpers/SteypHelmet";
import ProjectViewPageHead from "../../includes/projects-section/ProjectViewPageHead";
import ProjectViewNav from "../../includes/projects-section/ProjectViewNav";
import ProjectOverView from "../../includes/projects-section/ProjectOverView";
import ProjectAssignedPage from "../../includes/projects-section/ProjectAssignedPage";
import ProjectEnrolledPage from "../../includes/projects-section/ProjectEnrolledPage";
import ProjectSubmissionsPage from "../../includes/projects-section/ProjectSubmissionsPage";
import { useParams, useSearchParams } from "react-router-dom";
import ProjectAssignedModal from "../../includes/projects-section/modal/ProjectAssignedModal";
import { accountsConfig, learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function ProjectOverViewScreen() {
    const [selectedNav, setSelectedNav] = useState("Overview");
    const [details, setDetails] = useState([]);
    const [enrolled, setEnrolled] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [isReload, setIsReload] = useState(false);
    const [isbuttonLoad, setIsButtonLoad] = useState(false);
    const [isSearchWord, setSearchWord] = useState("");
    const [isSearchenroll, setSearchenroll] = useState("");
    const [isSuccess, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);

    const [studentData, setStudentData] = useState([]);

    const [editorContent, setEditorContent] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState(false);

    const { slug } = useParams();

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const navList = [
        {
            name: "Overview",
        },
        {
            name: "Assigned",
        },
        {
            name: "Enrolled",
        },
        {
            name: "Submissions",
        },
    ];

    useEffect(() => {
        const fetchSingelData = async () => {
            try {
                await learnConfig
                    .get(`projects/get-project/${slug}/`, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })
                    .then((res) => {
                        let { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setDetails(data);
                            setIsButtonLoad(false);
                            setEditorContent(JSON.parse(data?.description));
                        } else {
                            console.log(data);
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };
        fetchSingelData();
    }, [isbuttonLoad]);

    //Assigned students api

    useEffect(() => {
        const assignedData = async () => {
            try {
                await accountsConfig
                    .get("api/v1/users/projects/list-profiles-assign-modal/", {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        // params: {
                        //     project: slug,
                        // },
                    })
                    .then((res) => {
                        let { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setStudentData(data);
                        } else {
                            console.log(data);
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };
        assignedData();
    }, []);

    //List Student(modal) api
    useEffect(() => {
        const allStudents = async () => {
            try {
                setLoader(true);
                await accountsConfig
                    .get(
                        `api/v1/users/projects/list-profiles-assigned/?q=${isSearchWord}`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                            params: {
                                project: slug,
                            },
                        }
                    )
                    .then((res) => {
                        let { status_code, data } = res.data;
                        if (status_code === 6000) {
                            setAssigned(data);
                            setLoader(false);
                        } else {
                            console.log(data);
                            setLoader(false);
                        }
                    });
            } catch (error) {
                console.log(error);
                setLoader(false);
            }
        };
        allStudents();
    }, [isSearchWord, isSuccess]);

    // Enrolled Students
    useEffect(() => {
        const allStudents = async () => {
            try {
                await accountsConfig
                    .get(
                        `api/v1/users/projects/list-profiles-enrolled/?q=${isSearchenroll}`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                            params: {
                                project: slug,
                            },
                        }
                    )
                    .then((res) => {
                        let { status_code, data } = res.data;
                        if (status_code === 6000) {
                            setEnrolled(data);
                        } else {
                            console.log(data);
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };
        allStudents();
    }, [isSearchenroll]);

    return (
        <Container id="main">
            <MainContainer>
                <ProjectAssignedModal
                    slug={slug}
                    studenModal={modal}
                    setShowstudentModal={setModal}
                    studentData={studentData}
                    setIsReload={setIsReload}
                    setSuccess={setSuccess}
                />
                <ContainerDiv>
                    <SteypHelmet title="Project Overview" />
                    <ProjectViewPageHead details={details} slug={slug} />
                    <ProjectViewNav
                        navList={navList}
                        setSelectedNav={setSelectedNav}
                    />
                    {selectedNav === "Overview" ? (
                        <ProjectOverView
                            slug={slug}
                            setModal={setModal}
                            details={details}
                            editorContent={editorContent}
                            isbuttonLoad={isbuttonLoad}
                            setIsButtonLoad={setIsButtonLoad}
                        />
                    ) : selectedNav === "Assigned" ? (
                        <ProjectAssignedPage
                            setModal={setModal}
                            assigned={assigned}
                            setSearchWord={setSearchWord}
                            setSearchParams={setSearchParams}
                            setIsReload={setIsReload}
                            isReload={isReload}
                            isSuccess={isSuccess}
                            setSuccess={setSuccess}
                            loader={loader}
                        />
                    ) : selectedNav === "Enrolled" ? (
                        <ProjectEnrolledPage
                            enrolled={enrolled}
                            setSearchWord={setSearchenroll}
                            setSearchParams={setSearchParams}
                            setIsReload={setIsReload}
                            isReload={isReload}
                        />
                    ) : selectedNav === "Submissions" ? (
                        <ProjectSubmissionsPage
                            slug={slug}
                            setSearchWord={setSearchWord}
                            setSearchParams={setSearchParams}
                        />
                    ) : null}
                </ContainerDiv>
            </MainContainer>
        </Container>
    );
}

export default ProjectOverViewScreen;
const MainContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ContainerDiv = styled.div`
    /* min-width: 1620px; */
    min-width: 1100px;
`;
const Container = styled.div``;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
    margin: 24px 0;
`;
