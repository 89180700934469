import React, { useState } from "react";
import styled from "styled-components";
import InterviewStatusModal from "../../includes/jobdesk/modals/InterviewStatusModal";
import Moment from "react-moment";

function Graduatecard({ item, index, isUpdated, setUpdated }) {
    const [isModal, setModal] = useState(false);
    return (
        <>
            <InterviewStatusModal
                isUpdated={isUpdated}
                setUpdated={setUpdated}
                isFormModal={isModal}
                setFormModal={setModal}
                data={item}
            />
            <TotalContainer>
                <Cover>
                    <List type="slno">{index + 1}</List>
                    <List type="dist">
                        {item.name}
                        <Span>{item.phone}</Span>
                    </List>
                    <List type="dist">
                        <p>{item.email}</p>
                    </List>
                    <List type="slno">{item.gender}</List>
                    <List>
                        {item.course}
                        <Item>{item.name_of_college}</Item>
                    </List>
                    <List type="dist">{item.local_body}</List>

                    <List type="dist">{item.category}</List>
                    <List type="dist">
                        <Moment format="DD/MM/YYYY">{item.applied_date}</Moment>
                    </List>
                    <List type="dist">
                        <Moment format="DD/MM/YYYY">
                            {item.interviewed_date}
                        </Moment>
                    </List>
                    <List type="slno">
                        {/* {item.resume} */}
                        --
                    </List>
                    <List type="dist">
                        {item.account_details.approval_status === "pending" ? (
                            <Button
                                onClick={() => {
                                    setModal(true);
                                }}
                            >
                                Update
                            </Button>
                        ) : item.account_details.approval_status ===
                          "rejected" ? (
                            <SelectButton type="rejected">
                                Rejected
                            </SelectButton>
                        ) : item.account_details.approval_status ===
                          "approved" ? (
                            <SelectButton type="selected">
                                Selected
                            </SelectButton>
                        ) : (
                            ""
                        )}
                    </List>
                </Cover>
                <hr />
            </TotalContainer>
        </>
    );
}
const TotalContainer = styled.div`
    hr {
        border-bottom: 1px solid #000;
        width: 99%;
        margin: 0 auto;
    }
`;
const Cover = styled.ul`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
`;
const List = styled.li`
    color: rgb(255, 255, 255);
    font-size: 14px;
    width: ${({ type }) =>
        type === "slno" ? "4%" : type === "dist" ? "9.5%" : "11.5%"};
    &:last-child {
        text-align: right;
    }
    p {
        width: 90%;
        margin-right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(255, 255, 255);
        text-align: left;
    }
`;
const Span = styled.span`
    display: block;
    color: #f7684c;
`;
const Item = styled.span``;
const Button = styled.button`
    color: rgb(255, 255, 255);
    background: #f7684c;
    padding: 8px 20px;
    border-radius: 8px;
    cursor: pointer;
`;
const SelectButton = styled.button`
    color: green;
    font-size: 16px;
`;

export default Graduatecard;
