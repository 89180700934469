import React from "react";
import { Route, Routes } from "react-router-dom";
import AepPosterStyle from "../../../includes/aep/AepPosterStyle";
import AepPosterTypes from "../../../includes/aep/AepPosterTypes";

function PosterCardRouter() {
    return (
        <Routes>
            <Route path="" element={<AepPosterStyle />} />
            <Route path="/create-poster" element={<AepPosterTypes />} />
        </Routes>
    );
}

export default PosterCardRouter;
