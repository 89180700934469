import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { data, error } from "jquery";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import TagModal from "../../includes/career-advisor/modal/TagModal";
import DeleteTagModal from "../../includes/career-advisor/modal/DeleteTagModal";
import TagsNavBar from "./TagsNavBar";
import TagSortModal from "../../includes/career-advisor/modal/TagSortModal";
import CreateTagModal from "../../includes/career-advisor/modal/CreateTagModal";
import EditTagModal from "../../includes/career-advisor/modal/EditTagModal";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function Tags({ editCategoryPK, setFromDate, setToDate }) {
    //category
    const [categories, setCategories] = useState([]);
    const [isPageLoading, setPageLoading] = useState(false);
    const [isModal, setModal] = useState(false);
    const [isReload, setReload] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [isCreateTag, setCreateTag] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [modalType, setModalType] = useState("");
    const [loader, setLoader] = useState(null);

    //pagination
    const [pagination, setPagination] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [toDay, setToday] = useState("");
    const today = new window.Date();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [searchWord, setSearchWord] = useState("");

    const currentParams = Object.fromEntries([...searchParams]);
    const [isCategory, setCategory] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);
    const [description, setDescription] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [isFeatured, setFeatured] = useState(false);
    const [selectedParent, setSelectedParent] = useState("");
    const fileInputRef = useRef(null);
    const [selectedInput, setSelectedInput] = useState("");
    const [parentValue, setParentValue] = useState("");
    const [isError, setError] = useState(false);
    const navigate = useNavigate();
    const [isFilter, setFilter] = useState(false);
    const [isSort, setSort] = useState(false);

    const createCategory = () => {
        setSaveLoader(true);
        const formData = new FormData();

        if (!categoryName) {
            setError("* title is reqiured");
        }

        formData.append("title", categoryName);
        formData.append("description", description);
        formData.append("parent", parentValue);
        learnConfig
            .post("/doubt-hub/doubt-hub-tags/", formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setCreateTag(false);
                    setReload((prev) => !prev);
                    resetValues();
                    setSaveLoader(false);
                } else {
                    console.error(error);
                    setSaveLoader(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setSaveLoader(false);
            });
    };

    const resetValues = () => {
        setDescription("");
        setCategoryName("");
        setFeatured(false);
        setSelectedParent("");
        fileInputRef.current.value = null;
        setSelectedInput("");
        setParentValue("");
        // setEditCategoryPK("");
    };

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        return filterParams;
    };
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setCategory(false);
    };
    const clearAll = () => {
        setSearchWord("");
        setToday("");
        setFromDate("");
        setToDate("");
    };
    const handleDateRange = (date, range) => {
        let date_range = `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}`;
        if (range === "from") {
            setSearchParams({
                ...currentParams,
                from_date: date_range,
            });
        } else {
            setSearchParams({
                ...currentParams,
                to_date: date_range,
            });
        }
    };
    const handleRemoveCategory = () => {
        setSelectedCategory(null);
    };

    const {
        state: {
            user_data: { role, access_token },
        },
        dispatch,
    } = useContext(Context);

    //To. get all tags
    const categoryFetch = () => {
        setPageLoading(true);
        const params = getSearchParams();
        learnConfig
            .get(`/doubt-hub/listing-doubt-hub-tags/?q=${searchWord}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setPagination(pagination_data);
                    setCategories(data);
                    setPageLoading(false);
                } else {
                    console.log(error);
                    setPageLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setPageLoading(false);
            });
    };

    // delete tag

    const deleteTag = (tag_pk, index) => {
        setLoader(index);
        learnConfig
            .post(
                `/doubt-hub/delete-tags-chief/${tag_pk}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { status_code } = response.data;
                if (status_code === 6000) {
                    setReload((prev) => !prev);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoader(null);
            });
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    const handleDateFilter = (date) => {
        if (date !== "") {
            setSearchParams({
                ...currentParams,
                published_date: date,
            });
        } else {
            const updatedParams = { ...currentParams };
            delete updatedParams.published_date;
            setSearchParams(updatedParams);
        }
    };
    const handleCloseClick = () => {
        setSelectedAuthor(null);
        const updatedParams = { ...currentParams };
        delete updatedParams.author;
        setSearchParams(updatedParams);
    };

    useEffect(() => {
        categoryFetch();
    }, [isReload, searchParams, searchWord]);

    return (
        <>
            {isEdit && <EditTagModal isEdit={isEdit} setEdit={setEdit} />}
            {isModal && <TagModal isModal={isModal} setModal={setModal} />}

            {isCreateTag && (
                <CreateTagModal
                    showModal={isCreateTag}
                    setShowModal={setCreateTag}
                    createCategory={createCategory}
                    setCategoryName={setCategoryName}
                    categoryName={categoryName}
                    setDescription={setDescription}
                    setSelectedParent={setSelectedParent}
                    description={description}
                    saveLoader={saveLoader}
                    parentValue={parentValue}
                    setParentValue={setParentValue}
                    modalType={modalType}
                    isError={isError}
                />
            )}

            <Container>
                <ContentContainer>
                    <Heading>Tags</Heading>
                    <TopContainer>
                        <TopDiv>
                            <NavContainer>
                                <SearchContainer>
                                    <SearchBar>
                                        <InputField
                                            placeholder="Search Here"
                                            valude={searchWord}
                                            onChange={(e) => {
                                                setSearchWord(e.target.value);
                                            }}
                                        />
                                    </SearchBar>
                                    <SearchImageContainer>
                                        <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg" />
                                    </SearchImageContainer>
                                </SearchContainer>

                                {/* <Sort>
                                    <SortDiv
                                        onClick={() => {
                                            setFilter(false);
                                            setSort(!isSort);
                                        }}
                                    >
                                        <Smaall className="white">
                                            Sort by
                                        </Smaall>
                                        <Spaan>
                                            <img
                                                src={
                                                    require("../../../assets/images/sort.svg")
                                                        .default
                                                }
                                                alt="Sort"
                                            />
                                        </Spaan>
                                    </SortDiv>
                                </Sort> */}
                                <CreateContainer
                                    onClick={(e) => {
                                        // e.stopPropagation();
                                        // handleCreateArticle();
                                        // dispatch({
                                        //     type: "UPDATE_EDITOR_VIEW",
                                        //     isView: false,
                                        // });
                                        setCreateTag(true);
                                    }}
                                >
                                    <Spans>
                                        <img
                                            src={
                                                require("../../../assets/images/plus.svg")
                                                    .default
                                            }
                                            alt="plus"
                                        />
                                    </Spans>
                                    <Smalls className="whites">
                                        Create Tags
                                    </Smalls>
                                </CreateContainer>
                            </NavContainer>
                        </TopDiv>
                    </TopContainer>
                    {/* <TagsNavBar /> */}

                    <BottomContainers>
                        <MainHead>
                            <MainSub>
                                <Contents type="sl_no">Sl No</Contents>
                                <Contents type="title">Title</Contents>
                                <Contents type="date">Tag type</Contents>
                                <Contents type="campus">
                                    No: of questions
                                </Contents>
                                <Contents type="tag">Parent Tag</Contents>
                                <Contents type="subscription">Actions</Contents>
                            </MainSub>
                        </MainHead>
                        {categories.map((data, id) => (
                            <UlList key={id}>
                                <Li type="sl_no">
                                    {id < 9 && pagination.current_page === 1
                                        ? `0${pagination.first_item + id}`
                                        : pagination.first_item + id}
                                </Li>

                                <Li type="title">{data.title}</Li>
                                <Li type="date">{data.tag_type ?? "--"}</Li>

                                <Li type="campus">
                                    {data.no_of_questions ?? "--"}
                                </Li>
                                <Li type="tag">
                                    <Small>
                                        {data.parent_tag
                                            ? data.parent_tag
                                            : "--"}
                                    </Small>
                                </Li>
                                <Li type="subscription">
                                    {/* <EditButton
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <img
                                            src={
                                                require("../../../assets/images/staff/edit.svg")
                                                    .default
                                            }
                                            alt="Edit"
                                        />
                                    </EditButton> */}
                                    <DeleteButton
                                        onClick={() => {
                                            deleteTag(data.slug, id);
                                        }}
                                    >
                                        {loader === id ? (
                                            <ButtonLoader
                                                width={25}
                                                height={25}
                                            />
                                        ) : (
                                            "Delete"
                                        )}
                                    </DeleteButton>
                                </Li>
                            </UlList>
                        ))}
                    </BottomContainers>
                </ContentContainer>
                {pagination?.total_pages > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
            </Container>
        </>
    );
}

export default Tags;

const Container = styled.section``;
const ContentContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;
const TopDiv = styled.div``;
const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px 16px 8px 16px;
    border: 2px solid #1b1c20;
    border-radius: 3px;
    margin-right: 12px;
`;
const Sort = styled.button`
    position: relative;
    margin-right: 8px;
    img {
        display: block;
        width: 100%;
    }
`;
const SortDiv = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const Spaan = styled.span`
    width: 24px;
`;
const Smaall = styled.small`
    color: #7b7b7b;
    font-size: 0.875rem;
    font-family: gordita_medium;

    cursor: pointer;
    &.white {
        color: #fff;

        margin-right: 8px;
    }
    &.whites {
        color: #f2f2f2;

        line-height: normal;
        margin-right: 0;
        margin-left: 4px;
    }
`;
const CreateContainer = styled.div`
    display: flex;
    height: 44px;
    padding: 8px 16px;
    align-items: center;
    border-radius: 8px;
    background: #fe673a;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;
const Spans = styled.span`
    width: 24px;
`;

const Smalls = styled.small`
    color: #7b7b7b;
    font-size: 0.875rem;
    font-family: gordita_medium;

    cursor: pointer;
    &.white {
        color: #fff;

        margin-right: 8px;
    }
    &.whites {
        color: #f2f2f2;

        line-height: normal;
        margin-right: 0;
        margin-left: 4px;
    }
`;
const SearchBar = styled.div`
    background: #161619;
    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background: #161619;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 10px 10px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const BottomContainers = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const MainSub = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    display: flex;
    font-family: "gordita_medium";
    font-size: 15px;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "18%"
            : type === "tags"
            ? "10%"
            : type === "date"
            ? "10%"
            : type === "campus"
            ? "10%"
            : type === "tag"
            ? "18%"
            : type === "subscription"
            ? "15%"
            : ""};
    justify-content: ${({ type }) =>
        type === "subscription" ? "flex-end" : "center"};
`;
const UlList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    color: #d2caca;
    border-radius: 5px;
    align-items: center;
    border-bottom: 1px solid #2c2c2c;
    &:hover {
        background-color: #161619;
        color: #fff;
    }
`;
const Li = styled.li`
    display: flex;
    flex-wrap: wrap;
    font-family: "gordita_medium";
    font-size: 15px;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "18%"
            : type === "tags"
            ? "10%"
            : type === "date"
            ? "10%"
            : type === "campus"
            ? "10%"
            : type === "tag"
            ? "18%"
            : type === "subscription"
            ? "15%"
            : ""};
    justify-content: ${({ type }) =>
        type === "subscription" ? "flex-end" : "center"};
`;

const DeleteButton = styled.div`
    border: 2px solid #3b3b3b;
    padding: 5px 30px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    font-family: "gordita_medium";
    height: 40px;
    justify-content: center;
    align-items: center;
    width: 100px;
    color: #ff3030;
`;
const EditButton = styled.div`
    border-radius: 8px;
    width: 40px;
    background-color: #2c2c2c;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;

const Small = styled.small`
    border-radius: 8px;
    background-color: #2c2c2c;
    padding: 10px;
    font-family: "gordita_medium";
    font-size: 15px;
`;
