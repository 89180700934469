import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../../contexts/Store";
import { learnConfig } from "../../../../axiosConfig";
import { error } from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function BlogPublishModal({
    isModal,
    setModal,
    selectedArticle,
    articleFetch,
}) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const [blogStatus, setBlogStatus] = useState("");
    const [saveLoader, setSaveLoader] = useState(false);

    const changeBlogStatus = (newStatus) => {
        const formData = new FormData();
        formData.append("status", newStatus);
        setSaveLoader(true);
        learnConfig
            .post(
                `/tech-updates/change-article-status/${selectedArticle.id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response, data) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    articleFetch();
                    setModal(false);
                    setSaveLoader(false);
                } else {
                    console.error(error);
                    setModal(false);
                    setSaveLoader(false);
                }
            })
            .catch((error) => {
                setModal(false);
                setSaveLoader(false);
                console.error(error);
            });
    };
    useEffect(() => {
        if (selectedArticle.blog_status === "draft") {
            setBlogStatus("in_review");
        } else if (selectedArticle.blog_status === "in_review") {
            setBlogStatus("published");
        } else if (selectedArticle.blog_status === "published") {
            setBlogStatus("draft");
        } else if (selectedArticle.blog_status === "re_work") {
            setBlogStatus("in_review");
        }
    }, [selectedArticle]);

    return (
        <MainContainer>
            {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}
            <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <p>
                            {selectedArticle.blog_status === "draft"
                                ? "Do You Want Send This To Review ?"
                                : selectedArticle.blog_status === "in_review"
                                ? "Do You Want To Publish This Article?"
                                : selectedArticle.blog_status === "published"
                                ? "Do You Want To UnPublish This Article?"
                                : selectedArticle.blog_status === "re_work"
                                ? "Do You Want To Publish This Article?"
                                : ""}
                        </p>
                        <ButtonConatiner>
                            <Cancel
                                onClick={() => {
                                    setModal(false);
                                }}
                            >
                                Cancel
                            </Cancel>
                            <Submit
                                onClick={() => {
                                    changeBlogStatus(blogStatus);
                                }}
                            >
                                {saveLoader ? <ButtonLoader /> : "Submit"}
                            </Submit>
                        </ButtonConatiner>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default BlogPublishModal;

const MainContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Modal = styled.div`
    width: 90%;
    max-width: 494px;
    /* height: 50vh; */
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #3b3b3b;
    background: #1b1c20;
    border-radius: 5px;
    /* overflow-y: hidden; */
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding-bottom: 50px;

    @media all and (max-width: 640px) {
        padding: 28px;
    }
    p {
        color: #fff;
        font-size: 18px;
        font-family: "gordita_medium";
    }
`;

const ButtonConatiner = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;
`;

const Cancel = styled.button`
    padding: 0px 14px;
    background-color: #1b1c1f;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    border: 1px solid #fe673a;
    width: 100px;
    height: 40px;
    flex-shrink: 0;
`;

const Submit = styled(Cancel)`
    background-color: #fe673a;
    margin-left: 18px;
`;
