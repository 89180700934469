import React, { useState,useContext,useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useSearchParams } from 'react-router-dom';
import { accountsConfig} from '../../../axiosConfig';
import { Context } from '../../contexts/Store';
import SectionLoader from '../../helpers/SectionLoader';
import tick from "../../../assets/images/school-scientist/tick.svg"
import NoData from './NoData';
import ReactPaginate from 'react-paginate';
import UpStudentsDetails from './UpStudentsDetails';


function ViewStudentList() {
    const [isLoading, setLoading] = useState(false);
    const {
        state: {
            user_data: { access_token },
            student_data: {},
        },
        dispatch,
    } = useContext(Context);
    const [students,setStudents] = useState([])
    const [isPageLoading, setPageLoading] = useState(false);
    const [pagination, setPagination] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);
    
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    const truncate = (str) => {
        if (str) {
            return str.length > 18 ? str.substring(18, 0) + "..." : str;
        }
        return " - ";
    };

    const page = searchParams.get("page") ? searchParams.get("page") : ""
    const q = searchParams.get("q") ? searchParams.get("q") : ""

    const studentClass = searchParams.get("student_class") ? searchParams.get("student_class") : ""
    const [hov,setHov]=useState("")
    const id = searchParams.get("campus") ? searchParams.get("campus") : ""

    // const exam_status = searchParams.get("exam_status") ? searchParams.get("exam_status") : ""

    useEffect(() => {
        setLoading(true)
        accountsConfig
            .get(`/api/v1/users/scs/students/?campus=${id}`, {
                params :{
                    q:q,
                    exam_status :"completed",
                    page : page ,
                    student_class : studentClass
                },
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudents(data);
                    setLoading(false);
                    setPagination(pagination_data);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);  
            });
    }, [searchParams])

    
  return (
    <>
        <Container>
            <UpStudentsDetails/>
            {isLoading ? (
                <SectionLoader />
            ) :  
            students?.length > 0 ? (
                students.map((student,index) => {
                    return ( 
                        <Cover to={`/school-scientist/students/scoreboard/?phone=${student.phone}&id=${student.id}`}>
                            <Item type="slno">{pagination?.first_item <= pagination?.last_item && (
                            <Item type="slno">{pagination?.first_item + index}</Item>)}</Item>
                            <Item>{student.name}<span>{student.phone}</span></Item>
                            <Item type="slno">{student.student_class}</Item>
                            <Item className='school' onMouseOver={()=>setHov((student.id))} onMouseOut={()=>setHov("")}>{hov == student.id ? student?.details?.campus_name : truncate (student?.details?.campus_name, 30)}</Item>
                            <Item type="slno" >
                                <div>{student.has_computer ? <img src={tick} /> : ""}</div>
                            </Item>
                            <Item type="dist">{student.admission_number}</Item>
                            <Item type="dist" >1</Item>
                            <Item type="slno">{student?.details?.time_taken !==null&&student?.details?.time_taken?(Math.round(student.details.time_taken/60)) :"--"}</Item>
                            <Item type="slno">{student?.details?.score?student?.details?.score:"0"}</Item>
                            <Item type="dist">{student.eligiblity_status}</Item>
                        </Cover>
                    )
                })
            ) : (
                <NoData />
            )}
              {!isLoading &&
                    pagination?.total_pages > 1 &&
                    students?.length > 0 && (
                        <PaginationContainer>
                            <PaginationText>
                                Showing Admissions {pagination?.first_item}{" "}
                                - {pagination?.last_item} of{" "}
                                {pagination?.total_items}
                            </PaginationText>
                            <ReactPaginate
                                previousLabel={"< Prev"}
                                nextLabel={"Next >"}
                                pageCount={pagination?.total_pages}
                                onPageChange={handlePageClick}
                                forcePage={currentPage - 1 }
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={
                                    "pagination__link--disabled"
                                }
                                activeClassName={"pagination__link--active"}
                            />
                        </PaginationContainer>
                    )}
        </Container>
    </>
  )
}
const Container = styled.div`
    color: rgb(255,255,255);
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    background-color: #1b1c1f;
    overflow: scroll;
`;
const Cover = styled(NavLink)`
    display: flex;
    border-bottom: 1px solid #26272a;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    font-size: 16px;
    color: rgb(255,255,255);
    min-width: 1180px;
`;
const Item = styled.li`
    list-style: none;
    font-size: 14px;
    span{
        display: block;
        color: rgb(254, 103, 58);
        font-size: 14px;
        @media all and (max-width: 980px) {
		    font-size: 13px;
	    }
        @media all and (max-width: 768px) {
		    font-size: 12px;
	    }
    }
    width: ${({ type }) =>
          type === "slno" ? "8%" : type === "dist" ? "11%" : "15%"
    };
    div{
        height: 20px;
        width: 20px;
        border: 1px solid #fff;
        border-radius: 4px;
        @media all and (max-width: 980px) {
            height: 18px;
            width: 18px;
	    } 
    }
    img{
        width: 18px;
        @media all and (max-width: 980px) {
            width: 16px;
	    }  
    }
    @media all and (max-width: 980px) {
		font-size: 13px;
	}

`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    margin-left: 20px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;

export default ViewStudentList