import styled from "styled-components";
import React from "react";
import ButtonLoader from "./Loader/ButtonLoader";

export default function VideoUpload({
    setSelectedVideo,
    isUploadLoading,
    selectedVideo,
    selectedVideoName,
    Title,
    errorMassage,
}) {
    /** Handles the drop event. */
    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            // Check if the file is a video
            if (file.type.startsWith("video/")) {
                setSelectedVideo(file);
            }
        }
    };

    /* Handles the drag over event. Prevents the default browser behavior.*/
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    /* Handles the file selection event.*/
    const file = (event) => {
        const selectedFile = event.target.files[0];
        setSelectedVideo(selectedFile);
    };

    return (
        <IntroVideo>
            <Intro>{Title}</Intro>
            <LabelDiv htmlFor="video-upload">
                <Drop onDrop={handleDrop} onDragOver={handleDragOver}>
                    {isUploadLoading ? (
                        <ButtonLoader />
                    ) : (
                        <Img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-04-2024/featuredicon.svg"
                            alt="Upload icon"
                            style={{ width: "50px" }}
                        />
                    )}
                    {isUploadLoading ? (
                        <Upload>Uploading...</Upload>
                    ) : (
                        <Upload>Click to upload</Upload>
                    )}{" "}
                    <File>File supported: MP4, AVI, MOV</File>
                    {selectedVideo || selectedVideoName ? (
                        <SelectedName>
                            Selected video:{" "}
                            {isUploadLoading
                                ? "..."
                                : selectedVideo
                                ? selectedVideo.name
                                : selectedVideoName}
                        </SelectedName>
                    ) : null}
                    <InputText
                        type="file"
                        id="video-upload"
                        onChange={file}
                        accept="video/mp4,video/x-m4v,video/*"
                        style={{ display: "none" }}
                    />
                </Drop>
            </LabelDiv>

            {errorMassage && errorMassage?.video_id ? (
                <Error>{errorMassage?.video_id[0]}</Error>
            ) : null}
        </IntroVideo>
    );
}

const Error = styled.div`
    font-size: 12px;
    color: red;
    margin-top: 6px;
`;
const IntroVideo = styled.div`
    width: 60%;
    margin-bottom: 15px;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Intro = styled.h3`
    font-size: 14px;
    color: #cdd5df;
    margin-bottom: 10px;
`;
const Drop = styled.div`
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #3b3b3b;
    padding: 40px 20px;
    width: 94%;
    border-radius: 9px;
    text-align: center;
    @media all and (max-width: 1280px) {
        width: 96%;
        padding: 69px 14px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        margin-bottom: 15px;
    }
    @media all and (max-width: 980px) {
        padding: 40px 7px;
    }
`;
const LabelDiv = styled.label`
    margin-bottom: 7px;
    display: block;
    cursor: pointer;
`;
const Img = styled.img`
    display: block;
    width: 100%;
    margin: 0 auto;
`;
const SelectedName = styled.span`
    font-size: 12px;
    color: #ffffff;
`;
const File = styled.h3`
    font-size: 14px;
    color: #475467;
    text-align: center;
    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
`;
const Upload = styled.h3`
    font-size: 15px;
    text-align: center;
    color: #fe673a;
    font-family: gordita_medium;
`;
const InputText = styled.input``;
