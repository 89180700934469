import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";

function ReviewDescriptionDetails({ setReviewed, isReviewed, showSummary }) {
    //modal outside click
    useEffect(() => {
        if (isReviewed) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isReviewed]);

    //function for ouside cllick
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setReviewed(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    //function to convert sentence into sentence case
    const getSentenceCase = (str) => {
        const sentenceCase = str.charAt(0).toUpperCase() + str.slice(1);
        return sentenceCase;
    };

    return (
        <BackContainer>
            {isReviewed ? (
                <Overlay
                    onClick={() => setReviewed(false)}
                    style={{ display: isReviewed ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isReviewed && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <ContainerTool>
                        <Container>
                            <Title>Exam Review</Title>
                            {showSummary && (
                                <Content>
                                    {getSentenceCase(showSummary)}
                                </Content>
                            )}
                        </Container>
                    </ContainerTool>
                </Modal>
            </Cover>
        </BackContainer>
    );
}
export default ReviewDescriptionDetails;

const BackContainer = styled.div``;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 50vh;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    box-shadow: 0px 3px 56px #00000048;
    ::-webkit-scrollbar {
        display: none;
    }
    overflow-y: scroll;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;

const ContainerTool = styled.div`
    background: #1b1c20;
    color: white;
    border: 1px solid #26272a;
    border-radius: 5px;
    box-shadow: 14px 25px 75px -18px rgba(0, 0, 0, 0.75);
`;

const Content = styled.p`
    margin-top: 10px;
    font-size: 14px;
    text-align: left;
`;
const Title = styled.span`
    color: #fff;
    font-family: "gordita_medium";
`;
const Container = styled.div`
    padding: 20px 15px;
`;
const Close = styled.div`
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer;
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
