import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TechEditor from "../../includes/content-manager/TechEditor";
import TechUpdatePopupModal from "../../includes/content-manager/modal/TechUpdatePopupModal";
import TechUpdateNotificationModal from "../../includes/content-manager/modal/TechUpdateNotificationModal";
import { Context } from "../../contexts/Store";
import { learnConfig } from "../../../axiosConfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import Blocks from "editorjs-blocks-react-renderer";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function TechUpdateReworkScreen() {
    const {
        isView,
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);
    const { article_pk } = useParams();

    const navigate = useNavigate();

    const [editorContent, setEditorContent] = useState("");
    const [newEditorContent, setNewEditorContent] = useState({});

    const [reason, setReason] = useState("");

    const [saveLoader, setSaveLoader] = useState(false);

    useEffect(() => {
        async function fetchSingleArticle() {
            try {
                const response = await learnConfig.get(
                    `tech-updates/get-updated-article/${article_pk}/`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000 && data) {
                    setEditorContent(
                        data.content === null ? {} : JSON.parse(data.content)
                    );
                } else {
                }
            } catch (error) {
                console.error("Error fetching article:", error);
            }
        }

        fetchSingleArticle();
    }, []);

    const createRework = () => {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("correction", JSON.stringify(newEditorContent));
        formData.append("reason", reason);
        formData.append("status", "re_work");
        learnConfig
            .post(
                `/tech-updates/change-article-status/${article_pk}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setSaveLoader(false);
                    navigate("/content-manager/dashboard/");
                } else {
                    setSaveLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setSaveLoader(false);
            });
    };

    return (
        <>
            <SectionMain id="main">
                <Container>
                    <Left>
                        <MainText>Article</MainText>
                        <ArticleBox>
                            {editorContent && <Blocks data={editorContent} />}
                        </ArticleBox>
                    </Left>
                    <Right>
                        <SideFirstHeading>Rework</SideFirstHeading>
                        <RightBox>
                            <SideSubHeading>Reason</SideSubHeading>
                            <TypeDiv>
                                <TypeInput
                                    onChange={(e) => setReason(e.target.value)}
                                    value={reason}
                                    placeholder="Type here.."
                                />
                            </TypeDiv>
                            <SideHeading>Corrections</SideHeading>

                            <ContentBox>
                                <RichTextContainer>
                                    <TechEditor
                                        editorContent={newEditorContent}
                                        setEditorContent={setNewEditorContent}
                                        article_pk={article_pk}
                                    />
                                </RichTextContainer>
                            </ContentBox>
                            <RightButtonBox>
                                <FirstButton to="/content-manager/dashboard/">
                                    Cancel
                                </FirstButton>
                                <SecondButton onClick={createRework}>
                                    {saveLoader ? <ButtonLoader /> : "Send"}
                                </SecondButton>
                            </RightButtonBox>
                        </RightBox>
                    </Right>
                </Container>
            </SectionMain>
        </>
    );
}

export default TechUpdateReworkScreen;

const SectionMain = styled.section``;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Left = styled.div`
    width: 49%;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    padding: 13px;
`;
const MainText = styled.h1`
    color: #c9c3c2;
    font-family: "gordita_medium";
    font-size: 16px;
`;
const ArticleBox = styled.div`
    border-radius: 8px;
    border: 1px solid #333;
    background: #161619;
    padding: 40px;
    margin-top: 10px;
    & h1 {
        font-size: 32px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 24px;
        line-height: 32px;
    }
    & h2 {
        font-size: 24px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 24px;
        line-height: 32px;

        @media all and (max-width: 480px) {
            /* width: 90%; */
            font-size: 22px;
        }
    }
    & h3 {
        font-size: 20px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 24px;
        line-height: 32px;
    }
    & p {
        color: #fff;
        font-size: 16px;
        font-family: "gordita_regular";
        margin-top: 10px;
        /* width: 90%; */
        line-height: 30px;
        margin-bottom: 30px;

        @media all and (max-width: 480px) {
            /* width: 90%; */
            font-size: 15px;
            line-height: 27px;
        }
    }
    & a {
        color: #fe673a;
        text-decoration-line: underline;
        font-style: italic;
        cursor: pointer;
    }
    & table {
        /* width: 90%; */
        border-collapse: collapse;
        border-radius: 8px !important;
        border: 1px solid #e8e8e8;
        background-color: #f2f2f2;
        margin-bottom: 48px;

        @media all and (max-width: 480px) {
            /* width: 70%; */
            min-width: 200px;
        }

        /* overflow: hidden; */
    }
    & thead {
        background-color: #f2f2f2;
        color: #aaaaaa;
        text-align: left;
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
        @media all and (max-width: 480px) {
            font-size: 12px;
            padding: 6px;
        }
    }
    & th {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }
    & td {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        color: #000;
        @media all and (max-width: 980px) {
            font-size: 13px;
        }
        @media all and (max-width: 480px) {
            font-size: 11px;
            padding: 6px;
        }
    }
    & tbody tr:hover {
        background-color: #f5f5f5;
    }

    & ol {
        list-style-type: upper-roman;
        margin-left: 30px;
        margin-top: 10px;
    }
    & ul {
        list-style-type: square;
        margin-left: 30px;
        margin-top: 10px;
    }
    li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    & ol ol,
    ul ul {
        list-style-type: lower-alpha;
        margin-left: 30px;
    }
    & figure {
        margin-bottom: 20px;
        img {
            display: block;
            width: 100%;
        }
        img:hover {
            cursor: zoom-in;
        }
        .ytp-cued-thumbnail-overlay-image {
            height: 0;
            padding-top: 56.25%;
        }
        .embed-block-service-twitter {
            max-width: 560px;
            min-width: 560px;
            min-height: 87vh;
        }
        @media all and (max-width: 980px) {
            margin-bottom: 25px;
        }
    }

    & figure:has(> iframe.embed-block-service-youtube) {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    & pre {
        width: 100% !important;
        padding: 10px !important;
        background: #d2caca;
    }

    & code {
        width: 100% !important;
        display: block !important;
        overflow: scroll !important;
        ::-webkit-scrollbar {
            display: none !important;
        }
    }

    & li {
        color: #fff;
    }
`;
const Right = styled.div`
    width: 49%;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
`;
const RightBox = styled.div`
    padding: 13px;
`;
const SideFirstHeading = styled.h1`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 18px;
    font-weight: 500;
    padding: 13px;
    border-bottom: 1px solid #2c2c2c;
`;
const SideSubHeading = styled.h2`
    color: #c9c3c2;
    font-family: "gordita_medium";
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
`;
const TypeDiv = styled.div`
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background: #161619;
    padding: 10px 20px;
    width: 100%;
`;
const SideHeading = styled.h1`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0px;
`;
const TypeInput = styled.textarea`
    display: flex;
    flex-direction: column;
    color: #fff;
    font-family: "gordita_medium";
    font-size: 18px;
    width: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ContentBox = styled.div``;
const RichTextContainer = styled.div`
    width: 100%;
    border-radius: 8px !important;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 40vh;
    & .codex-editor__redactor {
        padding: 10px !important;
        min-height: 500px !important;
    }
`;
const RightButtonBox = styled.div`
    display: flex;
    justify-content: right;
    margin-top: 30px;
`;
const FirstButton = styled(Link)`
    display: flex;
    width: 20%;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fe673a;
    color: #f2f2f2;
    font-family: "gordita_medium";
    font-size: 14px;
    margin-right: 8px;
    cursor: pointer;
`;
const SecondButton = styled.button`
    display: flex;
    width: 20%;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #f2f2f2;
    font-family: "gordita_medium";
    font-size: 14px;
    background: #fe673a;
    cursor: pointer;
`;
