import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { auth, db, storage } from "../../../fiebase";
import {
	ref,
	getDownloadURL,
	uploadBytes,
	uploadBytesResumable,
} from "firebase/storage";
import ChatList from "./ChatList";
import StudentDetails from "./StudentDetails";
import TypingBox from "./TypingBox";
import {
	collection,
	query,
	onSnapshot,
	addDoc,
	orderBy,
	setDoc,
	doc,
	getDoc,
	updateDoc,
	deleteDoc,
	getDocs,
	limit,
	startAfter,
	serverTimestamp,
} from "firebase/firestore";
import { Context } from "../../contexts/Store";
import Close from "../../includes/svg/Close";
import { formatBytes } from "../../helpers/functions";
import { Helmet } from "react-helmet";
import SupportEngineerDetails from "./SupportEngineerDetails";

export default function ChatRoom({ isStudentSelected }) {
	const [isShowLoadButton, setShowLoadButton] = useState(false);
	const [msgFetching, setMsgFetching] = useState(true);
	const [isExist, setExist] = useState(false);
	const [recording, setRecording] = useState(false);
	const [text, setText] = useState("");
	const [img, setImg] = useState("");
	const [imgUrl, setImgUrl] = useState("");
	const [myId, setMyId] = useState("");
	const [imgPreview, setImgPreview] = useState(false);
	const [filePreview, setFilePreview] = useState(false);
	const [myDetails, setMyDetails] = useState({});
	const [file, setFile] = useState("");
	const [percentage, setPercentage] = useState(0);
	const [uploadedSize, setUploadedSize] = useState(0);
	const [uploadTask, setUploadTask] = useState(null);
	const [joinedLoader, setJoinedLoader] = useState(false);
	const [documents, setDocuments] = useState([]);
	const [lastDocument, setLastDocument] = useState(null);
	const [insideText, setInsideText] = useState("");
	const [isCode, setIsCode] = useState(false);
	const [isStudentDetailsMenu, setStudentDetailsMenu] = useState(false);

	const {
		state: { user_data, chat_user, isEditProfile },
	} = useContext(Context);

	useEffect(() => {
		textChanger();
	}, [insideText, isCode]);

	const textChanger = () => {
		if (isCode) {
			setText("``` \n" + insideText);
		} else {
			setText(insideText);
		}
	};

	useEffect(() => {
		if (auth.currentUser) setMyId(auth.currentUser.uid);
		else setMyId(user_data.uid);
	}, [user_data, auth.currentUser]);

	let messagesUnsubscribe;
	async function fetchInitialMessages() {
		setMsgFetching(true);
		const roomId =
			chat_user.uid + chat_user.uid?.split("").reverse().join("");

		const q = query(
			collection(db, "chatRooms", roomId, `${chat_user.uid}-chat`),
			orderBy("createdAt", "desc"),
			limit(15)
		);

		const snapshotData = await getDocs(q);
		messagesUnsubscribe = onSnapshot(q, (querySnapshot) => {
			let array = [];
			querySnapshot.forEach((doc) => {
				array.push(doc.data());
			});

			setMsgFetching(false);
			setDocuments(array);
		});

		setLastDocument(snapshotData.docs[snapshotData.docs.length - 1]);
	}
	useEffect(() => {
		if (chat_user.uid) {
			fetchInitialMessages();
		}
		return () => {
			messagesUnsubscribe && messagesUnsubscribe();
		};
	}, [chat_user.uid]);

	const scrollableDiv = useRef(null);
	const scrollHeight = 0;

	const handleScroll = () => {
		const currentScrollPosition = scrollableDiv.current.scrollTop;
		if (currentScrollPosition === scrollHeight) {
			setShowLoadButton(true);
		} else {
			setShowLoadButton(false);
		}
	};

	const updateUserLastMessage = async (type, myDetails, messageText) => {
		const docRef = doc(db, "users", chat_user?.uid);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			updateDoc(doc(db, "users", chat_user?.uid), {
				lastMessageTime: serverTimestamp(),
				lastMessage: {
					text: messageText ? messageText : text,
					// text,
					fromId: myId,
					fromName: myDetails?.name || "",
					fromImage: myDetails?.image || "",
					createdAt: serverTimestamp(),
					media: imgUrl || "",
					type:
						type === "file"
							? "file"
							: type === "image"
							? "image"
							: type === "voice"
							? "voice"
							: "text",
					unread: true,
				},
			});
		}
	};

	const handleKeyDown = (e) => {
		if (e.code === "Enter") {
			sendMessage(e, e.target.value);
			setImgPreview(false);
			setText("");
		}
	};

	useEffect(() => {
		if (auth.currentUser) setMyId(auth.currentUser.uid);
		else setMyId(user_data.uid);
	}, [user_data, auth.currentUser]);

	const previewImage = (event) => {
		var reader = new FileReader();
		reader.onload = function () {
			var output = document.getElementById("output_image");
			output.src = reader.result;
			setImgUrl(reader.result);
		};
		reader.readAsDataURL(event.target.files[0]);
	};

	useEffect(async () => {
		if (scrollableDiv && !msgFetching)
			scrollableDiv.current.addEventListener("scroll", handleScroll);
	}, [documents]);

	const updateLastMessage = async (roomId) => {
		const docSnap = await getDoc(doc(db, "lastMessage", roomId));
		// if last message exists and message is from selected user
		if (docSnap.data() && docSnap.data().from !== myId) {
			// update last message doc, set unread to false
			await updateDoc(doc(db, "lastMessage", roomId), {
				unread: false,
			});
		}
	};

	const addaKey = async (id, isDeleted) => {
		const roomId =
			chat_user?.uid + chat_user?.uid?.split("").reverse().join("");
		const docRef = doc(
			db,
			"chatRooms",
			roomId,
			`${chat_user?.uid}-chat`,
			id
		);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			if (isDeleted === undefined) {
				updateDoc(docRef, {
					isDeleted: false,
				});
			} else {
			}
		} else {
		}
	};

	const fetchMoreData = async () => {
		const roomId =
			chat_user?.uid + chat_user?.uid?.split("").reverse().join("");

		const q = query(
			collection(db, "chatRooms", roomId, `${chat_user?.uid}-chat`),
			orderBy("createdAt", "desc"),
			startAfter(lastDocument),
			limit(29)
		);

		const snapshotData = await getDocs(q);

		onSnapshot(q, (querySnapshot) => {
			let array = [];
			querySnapshot.forEach((doc) => {
				array.push(doc.data());
			});

			setDocuments([...documents, ...array]);
		});

		setLastDocument(snapshotData.docs[snapshotData.docs.length - 1]);
	};

	useEffect(() => {
		async function updateToken() {
			const currentToken = localStorage.getItem("currentToken");
			const docRef = doc(db, "users", user_data.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				updateDoc(doc(db, "users", user_data.uid), {
					activeDeviceToken: currentToken,
				});

				setMyDetails(docSnap.data());
			} else {
			}
		}
		updateToken();
	}, [user_data.uid]);

	const openRoom = async (user) => {
		const roomId = user.uid + user.uid.split("").reverse().join("");

		updateLastMessage(roomId);
	};

	const sendPushNotification = (text) => {
		let notificationData = {
			to: chat_user?.activeDeviceToken,
			notification: {
				title: myDetails?.name,
				body: text,
			},
		};

		$.ajax({
			url: "https://fcm.googleapis.com/fcm/send",
			type: "post",
			data: JSON.stringify(notificationData),
			headers: {
				"Content-Type": "application/json",
				Authorization:
					"key=AAAAAFCgeLY:APA91bHCZ1YxWGR7WXEdxTHt4iWD46ZTvxVbRdSXXiO3c6XDOsuyXZpY_RxgZluTbTJaq0klQ05OeyMXAyQWNr9O7w5yn40O_JMv3ov6CSprxDAjn07F4Sqj-eZCPUt4AXarEpVZHq3C",
			},
			dataType: "json",
			success: function (data) {
				// console.info(data);
			},
			error: function (e, _, thrownError) {
				// console.log(e.status, e);
			},
		});
	};
	// }, []);

	const sendMessage = async (e, messageText, isCode) => {
		setText("");
		setInsideText("");
		e.preventDefault();
		const input = document.getElementById("reso");
		const typer = document.getElementById("res");
		if (typer) document.getElementById("res").innerHTML = "";

		const studentRecievingId = "NoT" + chat_user.uid + "iOn";
		const typingId = "TyInP" + myId + "nGTyp";
		const imgMsgId = "mkM" + new Date().getTime() + "njPh";
		const fileMsgId = "flE" + new Date().getTime() + "fPU";
		const roomId =
			chat_user.uid + chat_user.uid.split("").reverse().join("");

		if (img) {
			const imgRef = ref(
				storage,
				`images/${new Date().getTime()} - ${img.name}`
			);

			const uploadTask = uploadBytesResumable(imgRef, img);

			// Register three observers:
			// 1. 'state_changed' observer, called any time the state changes
			// 2. Error observer, called on failure
			// 3. Completion observer, called on successful completion
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// Observe state change events such as progress, pause, and resume
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

					switch (snapshot.state) {
						case "paused":
							break;
						case "running":
							break;
					}
				},
				(error) => {
					// Handle unsuccessful uploads
				},
				() => {
					// Handle successful uploads on complete
					// For instance, get the download URL: https://firebasestorage.googleapis.com/...
					getDownloadURL(uploadTask.snapshot.ref).then(
						(downloadURL) => {
							updateDoc(
								doc(
									db,
									"chatRooms",
									roomId,
									`${chat_user?.uid}-chat`,
									imgMsgId
								),
								{
									isUploading: false,
									media: downloadURL,
								}
							);

							updateUserLastMessage("image", myDetails);

							updateDoc(
								doc(
									db,
									"chatRooms",
									roomId,
									"notification",
									studentRecievingId
								),
								{
									isSound: true,
								}
							);
							updateDoc(doc(db, "lastMessage", roomId), {
								media: downloadURL,
							});
						}
					);
				}
			);

			await setDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					imgMsgId
				),
				{
					id: "mSg" + new Date().getTime() + "uFv",
					text: messageText ? messageText : text,
					isDeleted: false,
					fromId: myId,
					fromName: myDetails.name,
					fromImage: myDetails.image || "",
					createdAt: serverTimestamp(),
					media: imgUrl,
					isUploading: true,
					viewers: [],
				}
			);
			sendPushNotification("📷 Photo");
		} else if (file) {
			const fileRef = ref(
				storage,
				`files/${new Date().getTime()} - ${file.name}`
			);

			const uploadTask = uploadBytesResumable(fileRef, file);
			setUploadTask(uploadTask);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

					setPercentage(Math.round(progress));
					setUploadedSize(snapshot.bytesTransferred);

					switch (snapshot.state) {
						case "paused":
							break;
						case "running":
							break;
					}
				},
				(error) => {
					// Handle unsuccessful uploads
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then(
						(downloadURL) => {
							updateDoc(
								doc(
									db,
									"chatRooms",
									roomId,
									`${chat_user?.uid}-chat`,
									fileMsgId
								),
								{
									isUploading: false,
									fileUrl: downloadURL,
								}
							);
							updateUserLastMessage("file", myDetails);

							updateDoc(
								doc(
									db,
									"chatRooms",
									roomId,
									"notification",
									studentRecievingId
								),
								{
									isSound: true,
								}
							);

							updateDoc(doc(db, "lastMessage", roomId), {
								fileUrl: downloadURL,
							});
						}
					);
				}
			);

			await setDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					fileMsgId
				),
				{
					id: "mSg" + new Date().getTime() + "uFv",
					text: messageText ? messageText : text,
					isDeleted: false,
					fromId: myId,
					fromName: myDetails.name,
					fromImage: myDetails.image || "",
					createdAt: serverTimestamp(),
					fileUrl: "",
					fileName: file.name,
					fileSize: file.size,
					type: "file",
					isUploading: true,
					viewers: [],
				}
			);

			sendPushNotification("📁 File");
		} else {
			updateDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					"notification",
					studentRecievingId
				),
				{
					isSound: true,
				}
			);
			updateUserLastMessage("text", myDetails, messageText);

			await addDoc(
				collection(db, "chatRooms", roomId, `${chat_user?.uid}-chat`),
				{
					id: "mSg" + new Date().getTime() + "uFv",
					text: messageText
						? isCode
							? "``` \n" + messageText
							: messageText
						: text,
					// text: messageText ? "``` \n" + messageText : text,
					isDeleted: false,
					fromId: myId,
					fromName: myDetails.name || "",
					fromImage: myDetails.image || "",
					createdAt: serverTimestamp(),
					media: "",
					viewers: [],
				}
			);

			sendPushNotification(messageText ? messageText : text);
		}

		await deleteDoc(
			doc(db, "chatRooms", roomId, `${chat_user?.uid}-chat`, typingId)
		);

		await setDoc(doc(db, "lastMessage", roomId), {
			id: "mSg" + new Date().getTime() + "uFv",
			text: messageText ? messageText : text,
			isDeleted: false,
			fromId: myId,
			fromName: myDetails?.name || "",
			fromImage: myDetails?.image || "",
			to: chat_user.uid,
			createdAt: serverTimestamp(),
			media: imgUrl || "",
			type: file ? "file" : img ? "image" : "",
			unread: true,
		});

		setText("");
		setImg("");
	};

	const joinConversation = async () => {
		setJoinedLoader(true);
		const roomId =
			chat_user.uid + chat_user.uid.split("").reverse().join("");
		const joinId = "joI" + new Date().getTime() + "9jN";

		await setDoc(doc(db, "chatRooms", roomId, "members", myId), {
			uid: myId,
			name: myDetails.name,
			image: myDetails.image || "",
			createdAt: serverTimestamp(),
		});

		await setDoc(
			doc(db, "chatRooms", roomId, `${chat_user?.uid}-chat`, joinId),
			{
				id: "mSg" + new Date().getTime() + "uFv",
				type: "join",
				fromId: myId,
				fromName: myDetails.name,
				fromImage: myDetails.image || "",
				createdAt: serverTimestamp(),
			}
		);
		setJoinedLoader(false);
	};

	const leaveConversation = async () => {
		setJoinedLoader(true);
		const roomId =
			chat_user.uid + chat_user.uid.split("").reverse().join("");
		const leaveId = "joI" + new Date().getTime() + "9jN";

		await deleteDoc(doc(db, "chatRooms", roomId, "members", myId));
		await setDoc(
			doc(db, "chatRooms", roomId, `${chat_user?.uid}-chat`, leaveId),
			{
				id: "mSg" + new Date().getTime() + "uFv",
				type: "leave",
				fromId: myId,
				fromName: myDetails.name,
				fromImage: myDetails.image || "",
				createdAt: serverTimestamp(),
			}
		);
		setJoinedLoader(false);
	};

	const listenTyping = async () => {
		const roomId =
			chat_user.uid + chat_user.uid.split("").reverse().join("");
		const typingId = "TyInP" + myId + "nGTyp";
		const docRef = doc(
			db,
			"chatRooms",
			roomId,
			`${chat_user?.uid}-chat`,
			typingId
		);
		const docSnap = await getDoc(docRef);

		if (!docSnap.data()) {
			setDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					typingId
				),
				{
					id: "mSg" + new Date().getTime() + "uFv",
					type: "typing",
					fromId: myId,
					fromName: myDetails.name,
					fromImage: myDetails.image || "",
					isTyping: true,
					createdAt: serverTimestamp(),
				}
			);
		} else {
			updateDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					typingId
				),
				{
					createdAt: serverTimestamp(),
					isTyping: true,
				}
			);
		}
	};

	const listenRecording = async () => {
		const roomId =
			chat_user.uid + chat_user.uid.split("").reverse().join("");
		const recordingId = "VcIne" + myId + "nGiNg";
		const docRef = doc(
			db,
			"chatRooms",
			roomId,
			`${chat_user?.uid}-chat`,
			recordingId
		);
		const docSnap = await getDoc(docRef);

		if (!docSnap.data()) {
			setDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					recordingId
				),
				{
					id: "mSg" + new Date().getTime() + "uFv",
					type: "recording",
					isDeleted: false,
					fromId: myId,
					fromName: myDetails.name,
					fromImage: myDetails.image || "",
					isRecording: true,
					createdAt: serverTimestamp(),
				}
			);
		} else {
			updateDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					recordingId
				),
				{
					createdAt: serverTimestamp(),
					isRecording: true,
				}
			);
		}
	};

	const recordingStatusDelete = async () => {
		const roomId =
			chat_user?.uid + chat_user?.uid.split("").reverse().join("");
		const recordingId = "VcIne" + myId + "nGiNg";
		const docRef = doc(
			db,
			"chatRooms",
			roomId,
			`${chat_user?.uid}-chat`,
			recordingId
		);
		const docSnap = await getDoc(docRef);

		if (docSnap.data()) {
			await deleteDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					recordingId
				)
			);
			// updateDoc(doc(db, "chatRooms", roomId, `${chat_user?.uid}-chat`, recordingId), {
			//     isRecording: false,
			// });
		}
	};

	const handleTypingEnd = async () => {
		const roomId =
			chat_user?.uid + chat_user?.uid?.split("").reverse().join("");
		const typingId = "TyInP" + myId + "nGTyp";
		const docRef = doc(
			db,
			"chatRooms",
			roomId,
			`${chat_user?.uid}-chat`,
			typingId
		);
		const docSnap = await getDoc(docRef);

		if (docSnap.data()) {
			updateDoc(
				doc(
					db,
					"chatRooms",
					roomId,
					`${chat_user?.uid}-chat`,
					typingId
				),
				{
					isTyping: false,
				}
			);
		}
	};

	const getFileBlob = function (url, cb) {
		const xhr = new XMLHttpRequest();

		xhr.open("GET", url);
		xhr.responseType = "blob";
		xhr.addEventListener("load", function () {
			cb(xhr.response);
		});
		xhr.send();
	};

	const sendVoiceMessage = async (blobURL) => {
		const storageRef = ref(storage, `voices/${new Date().getTime()}.mp3`);
		const studentRecievingId = "NoT" + chat_user.uid + "iOn";
		const voiceMsgId = "vcE" + new Date().getTime() + "njPh";
		const roomId =
			chat_user.uid + chat_user.uid.split("").reverse().join("");

		const metadata = {
			contentType: "audio/mp3",
		};

		await setDoc(doc(db, "lastMessage", roomId), {
			id: "mSg" + new Date().getTime() + "uFv",
			text: "",
			isDeleted: false,
			fromId: myId,
			fromName: myDetails.name,
			fromImage: myDetails.image || "",
			to: chat_user.uid,
			createdAt: serverTimestamp(),
			media: "",
			type: "voice",
			unread: true,
			isUploading: true,
		});

		await setDoc(
			doc(db, "chatRooms", roomId, `${chat_user?.uid}-chat`, voiceMsgId),
			{
				id: "mSg" + new Date().getTime() + "uFv",
				text: "",
				isDeleted: false,
				fromId: myId,
				fromName: myDetails.name,
				fromImage: myDetails.image || "",
				createdAt: serverTimestamp(),
				media: "",
				type: "voice",
				isUploading: true,
				viewers: [],
			}
		);

		await setDoc(
			doc(db, "chatRooms", roomId, "notification", studentRecievingId),
			{
				isSound: true,
			}
		);

		getFileBlob(blobURL, (blob) => {
			uploadBytes(storageRef, blob, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref).then((downloadURL) => {
						updateUserLastMessage("voice", myDetails);

						updateDoc(
							doc(
								db,
								"chatRooms",
								roomId,
								`${chat_user?.uid}-chat`,
								voiceMsgId
							),
							{
								isUploading: false,
								media: downloadURL,
							}
						);

						sendPushNotification("🎤 Voice");

						updateDoc(doc(db, "lastMessage", roomId), {
							media: downloadURL,
						});
					});
				})
				.catch((error) => {
					console.log(error);
				});
		});
	};

	//=======================================
	const [mediaRecorder, setMediaRecorder] = useState(null);
	const isCanceledRef = useRef(false);

	const startRecording = async () => {
		listenRecording();
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			const mediaRecorder = new MediaRecorder(stream);
			setMediaRecorder(mediaRecorder);
			setRecording(true);
			isCanceledRef.current = false;

			const chunks = [];

			mediaRecorder.start();
			mediaRecorder.addEventListener("dataavailable", (event) => {
				chunks.push(event.data);
			});

			mediaRecorder.onstop = () => {
				if (!isCanceledRef.current) {
					const blob = new Blob(chunks);
					const url = URL.createObjectURL(blob);
					sendVoiceMessage(url);
				}
				chunks.length = 0;
			};
		} catch (error) {
			console.error("Error", error);
		}
	};

	const stopRecording = () => {
		setRecording(false);
		isCanceledRef.current = false;
		mediaRecorder.stop();
		mediaRecorder.stream.getTracks().forEach((track) => track.stop());
		recordingStatusDelete();
	};

	const cancelRecording = () => {
		isCanceledRef.current = true;
		setRecording(false);
		mediaRecorder.stop();
		mediaRecorder.stream.getTracks().forEach((track) => track.stop());
		recordingStatusDelete();
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Steyp | {user_data.name ? user_data.name : "Admin"}
				</title>
			</Helmet>
			<Overlay
				className={isStudentDetailsMenu && "overlay-active"}
				onClick={() => setStudentDetailsMenu(false)}
			></Overlay>
			<>
				<Middle className={isStudentSelected && "student-active"}>
					<ChatList
						isShowLoadButton={isShowLoadButton}
						msgFetching={msgFetching}
						myId={myId}
						msgs={documents}
						percentage={percentage}
						setPercentage={setPercentage}
						uploadedSize={uploadedSize}
						setUploadedSize={setUploadedSize}
						isStudentDetailsMenu={isStudentDetailsMenu}
						setStudentDetailsMenu={setStudentDetailsMenu}
						chat_user={chat_user}
						scrollableDiv={scrollableDiv}
						fetchMoreData={fetchMoreData}
					/>

					{filePreview && file?.name ? (
						<ImagePreview>
							<FileTop>
								<CloseWrapper
									title="Remove"
									onClick={() => {
										setFilePreview(false);
										setImg("");
									}}
								>
									<Close />
								</CloseWrapper>
								<FileName>{file.name}</FileName>
								<div></div>
							</FileTop>

							<FileImage>
								<img
									src={
										require("../../../assets/images/chat/file.svg")
											.default
									}
									alt="Image"
								/>
								<FileBottom>
									<PreviewTitle>
										No preview available
									</PreviewTitle>
									<PreviewBottom>
										{formatBytes(file.size)} - {file.type}
									</PreviewBottom>
								</FileBottom>
							</FileImage>
							<div></div>

							<FileSendButton
								title="Send"
								onClick={(e) => {
									sendMessage(e);
									setFilePreview(false);
									setFile("");
								}}
							>
								<img
									className="file"
									src={
										require("../../../assets/images/chat/send.svg")
											.default
									}
									alt="Icon"
								/>
							</FileSendButton>
						</ImagePreview>
					) : imgPreview && img ? (
						<ImagePreview>
							<CloseWrapper
								title="Remove"
								onClick={() => {
									setImgPreview(false);
									setImg("");
								}}
							>
								<Close />
							</CloseWrapper>
							<PreviewLeft>
								<img id="output_image" alt="Image" />
							</PreviewLeft>
							<PreviewRight>
								<PreviewInput
									id="res"
									value={text}
									onChange={(e) => {
										setText(e.target.value);
									}}
									onFocus={listenTyping}
									onBlur={handleTypingEnd}
									placeholder="Type message..."
									onKeyPress={handleKeyDown}
								></PreviewInput>
								<SendButton
									title="Send"
									onClick={(e) => {
										sendMessage(e);
										setImgPreview(false);
										setImg("");
									}}
								>
									<img
										className="file"
										src={
											require("../../../assets/images/chat/send.svg")
												.default
										}
										alt="Icon"
									/>
								</SendButton>
							</PreviewRight>
						</ImagePreview>
					) : (
						<TypingBox
							recording={recording}
							insideText={insideText}
							isCode={isCode}
							isExist={isExist}
							joinedLoader={joinedLoader}
							setInsideText={setInsideText}
							setIsCode={setIsCode}
							setImg={setImg}
							previewImage={previewImage}
							setImgPreview={setImgPreview}
							setFilePreview={setFilePreview}
							setRecording={setRecording}
							setFile={setFile}
							joinConversation={joinConversation}
							handleTypingEnd={handleTypingEnd}
							listenTyping={listenTyping}
							// +++++++++++++++++++++++++++++++++++++
							voiceRecordStart={startRecording}
							voiceRecordEnd={stopRecording}
							voiceRecordCancel={cancelRecording}
							sendMessage={sendMessage}
						/>
					)}
				</Middle>

				<Right className={isStudentDetailsMenu && "menu-actve"}>
					{chat_user.uid && !isEditProfile && (
						<StudentDetails
							joinConversation={joinConversation}
							leaveConversation={leaveConversation}
							isExist={isExist}
							setExist={setExist}
							myDetails={myDetails}
							joinedLoader={joinedLoader}
							myId={myId}
						/>
					)}
					{isEditProfile && <SupportEngineerDetails />}

					<CloseButton onClick={() => setStudentDetailsMenu(false)}>
						<img
							src={
								require("../../../assets/images/subscription_manager/Close.svg")
									.default
							}
							alt="Close"
						/>
					</CloseButton>
				</Right>
			</>
		</>
	);
}

const ImagePreview = styled.div`
	img {
		width: 100%;
		display: block;
	}
	display: flex;
	padding: 18px;
	animation: move;
	position: absolute;
	z-index: 2;
	flex-direction: column;
	justify-content: space-between;
	background: #1b1c1f;
	height: 100%;
	width: 100%;
	animation: 0.3s ease fade;
	@keyframes fade {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;
const CloseWrapper = styled.div`
	cursor: pointer;
`;
const PreviewLeft = styled.div`
	width: 81%;
	max-width: 219px;
	margin: auto;
	border-radius: 3px;
	overflow: hidden;
	margin: auto;
	border-radius: 3px;
	overflow: hidden;
`;
const PreviewRight = styled.div`
	position: relative;
	background: #161619;
	flex: 1;
	border: 1px solid #2c2c2c;
	border-radius: 6px;
	padding: 8px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-height: 50px;
`;
const FileSendButton = styled.div`
	position: absolute;
	right: 15px;
	bottom: 15px;
	cursor: pointer;
	background: #161619;
	height: 58px;
	width: 58px;
	border-radius: 50%;
	padding: 14px;
`;
const SendButton = styled.div`
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
`;
const PreviewInput = styled.input`
	max-height: 40px;
	width: 100%;
	color: #8d8d8d;
	font-size: 0.7rem;
	resize: none;
`;
const Right = styled.div`
	/* max-width: 350px; */
	/* min-width: 350px; */
	background: #1b1c1f;
	display: flex;
	height: 100%;
	overflow-y: scroll;
	width: 24%;

	&::-webkit-scrollbar {
		display: none;
	}

	&.active {
		/* min-width: 350px; */
	}

	/* @media all and (max-width: 1450px) {
        transition: all 0.6s ease 0s;
        right: ${(props) => (props.selected ? "0" : "-367px")};
        position: fixed;
        top: 87px;
        bottom: 0;
        box-shadow: rgb(22 22 25) -2px 11px 15px;
        z-index: 999;
    } */

	@media all and (max-width: 1280px) {
		position: absolute;
		top: 0;
		right: -500px;
		transition: 0.5s all ease;
		z-index: 1001;
		width: 250px;

		&.menu-actve {
			/* display: block; */
			right: 0;
		}
	}
`;
const Div = styled.div`
	height: calc(100vh - 153px);
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const Middle = styled.div`
	position: relative;
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0px 11px;

	@media all and (max-width: 1280px) {
		width: 74%;
		margin: 0px 0 0 11px;
	}

	@media all and (max-width: 768px) {
		width: 100%;
		&.student-active {
			width: 100%;
			margin: 0 !important;
		}
	}
`;

const MainContainer = styled.div`
	width: 100%;
	background-color: #161619;
	height: 100vh;
	display: flex;
	justify-content: space-between;
	/* grid-gap: 15px; */
	/* min-width: 1300px; */
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const Left = styled.div`
	width: 21.5%;
	background: #1b1c1f;

	/* min-width: 346px; */
	/* min-width: 24%;
    max-width: 24%; */
	/* min-width: 400px;
    max-width: 400px; */
	/* width: 355px; */
	/* width: 27%; */
	/* height: 100vh; */
	@media all and (max-width: 360px) {
		max-width: 355px;
		min-width: 355px;
	}
`;
const Top = styled.div`
	background: #1b1c1f;
	padding: 9px 7px;
	border-radius: 6px 6px 0px 0px;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		height: 8px;
		background: #161619;
		left: 0;
		right: 0;
		bottom: -8px;
		z-index: 2;
	}
`;
const InputContainer = styled.div`
	padding: 12px 9px;
	display: flex;
	align-items: center;
	grid-gap: 8px;
	justify-content: flex-start;
	background: #161619;
	border-radius: 6px;
`;
const ImageContainer = styled.div`
	width: 7%;
`;
const Image = styled.img`
	width: 100%;
	display: block;
`;

const Input = styled.input`
	width: 100%;
	color: #fff;
	font-size: 0.7rem;
`;
const Bottom = styled.div`
	margin-top: 15px;
	display: flex;
	align-items: end;
	justify-content: space-between;
	p {
		color: #fff;
	}
`;
const All = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 7px;
	cursor: pointer;
`;
const Icons = styled.span`
	width: 21px;
	&.icon {
		width: 16px;
	}
	&.dp {
		width: 26px;
	}
	img {
		display: block;
		width: 100%;
	}
`;
const UnassignedConatainer = styled.div`
	background: #1b1c1f;
	padding: 15px 0px 0px;
	margin-top: 8px;
	/* position: relative; */
	@media all and (max-width: 360px) {
		max-width: 350px;
	}
`;
const NoFound = styled.p`
	padding: 30px 14px;
`;
const Para = styled.p`
	color: #fff;
	font-size: 0.8rem;
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 15px;
`;
const AssignedPara = styled.p`
	color: #fff;
	font-size: 15px;
	margin-top: 0px;
	margin-bottom: 0px;
`;
const UnassignedBottom = styled.div`
	padding: 18px 15px;
	display: flex;
	justify-content: space-between;
	grid-gap: 10px;
	cursor: pointer;
	position: relative;
	transition: 0.3s all ease-in;
	/* transition-duration: 0.3s; */
	&:hover {
		background: #161619;
	}
	&:after {
		content: "";
		width: 80%;
		height: 0.5px;
		background: #161619;
		position: absolute;
		width: 91%;
		bottom: 0;
		left: 15px;
		right: 8px;
	}
`;
const DpContainer = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	/* overflow: hidden; */
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	small {
		width: 15px;
		height: 15px;
		background: green;
		position: absolute;
		bottom: 0px;
		right: -3px;
		border-radius: 50%;
		background: #00c569;
	}
`;
const Dp = styled.img`
	display: block;
	width: 100%;
	border-radius: 50%;
`;
const BottomMiddle = styled.div`
	flex: 1;
	h4 {
		color: #ffffff;
		font-family: "gordita_medium";
		font-size: 16px;
		word-break: break-all;
	}
	p {
		margin-top: 4px;
		font-size: 15px;
		color: #fff;
		opacity: 0.7;
	}
`;
const BottomRight = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;
const Time = styled.div`
	text-align: right;
	font-size: 12px;
	color: #b0b7be;
	opacity: 0.6;
`;
const ChatCount = styled.div`
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background: #ef674a;
	text-align: center;
	font-family: "gordita_medium";
	font-size: 13px;
	padding: 2px;
	color: #fff;
	margin-top: 6px;
`;
const AssignedConatainer = styled.div`
	background: #1b1c1f;
	padding: 15px 0px 40px;
	margin-top: 14px;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		height: 14px;
		background: #161619;
		left: 0;
		right: 0;
		top: -18px;
		z-index: 2;
	}
`;
const AssignedTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 15px;
	margin-bottom: 11px;
`;
const FileImage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	img {
		width: 12%;
		margin: 0 auto;
		display: block;
	}
`;
const FileTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const FileName = styled.h5`
	color: #e1e5e7;
`;
const FileBottom = styled.div``;
const PreviewTitle = styled.h4`
	color: #afb7bc;
	font-size: 1.3rem;
	margin: 18px 0 6px;
`;
const PreviewBottom = styled.p`
	font-size: 0.83rem;
`;
const SearchDiv = styled.div`
	width: 100%;
	height: 50px;
	background-color: transparent;
	padding: 10px 20px 0 10px;

	& input {
		background-color: #161619;
		width: 100%;
		height: 100%;
		padding: 10px 15px;
		color: #fff;
	}
`;
const Overlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: none;

	&.overlay-active {
		display: block;
	}
`;
const CloseButton = styled.div`
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: #00000080;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	padding: 5px;

	img {
		width: 100%;
		display: block;
	}
`;
