import React from 'react'
import SchoolCategory from './SchoolCategory'
import styled from 'styled-components'
import SchoolScientistStudents from './SchoolScientistStudents';
import HSSstudentsList from './HSSstudentList'

function StudentsHSS() {
  return (
    <Container id="main">
        <SchoolScientistStudents />
        <SchoolCategory /> 
        <HSSstudentsList />
    </Container>
  )
}
const Container = styled.div`
  
`;

export default StudentsHSS