import React, { useContext, useEffect, useState } from "react";
import Blocks from "editorjs-blocks-react-renderer";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import { learnConfig } from "../../../axiosConfig";

function TechUpdateViewRework() {
    const { history_pk } = useParams();
    const [editorContent, setEditorContent] = useState("");
    const [articlePK, setArticlePK] = useState("");
    const [reason, setReason] = useState("");
    const [correction, setCorrection] = useState("");

    const {
        isView,
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);
    const article_pk = "";

    useEffect(() => {
        async function fetchSingleArticle() {
            try {
                const response = await learnConfig.get(
                    `tech-updates/get-rework-detail/${history_pk}/`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000 && data) {
                    setArticlePK(data.article_id);
                    setReason(data.reason);
                    if (data.correction !== null) {
                        setCorrection(JSON.parse(data.correction));
                    }
                    if (data.snapshot !== null) {
                        setEditorContent(JSON.parse(data.snapshot));
                    }
                } else {
                }
            } catch (error) {
                console.error("Error fetching article:", error);
            }
        }

        fetchSingleArticle();
    }, []);

    return (
        <>
            <SectionMain id="main">
                <Container>
                    <Left>
                        <MainText>Article</MainText>
                        <ArticleBox>
                            {editorContent && <Blocks data={editorContent} />}
                        </ArticleBox>
                    </Left>
                    <Right>
                        <SideFirstHeading>Rework</SideFirstHeading>
                        <RightBox>
                            <SideSubHeading>Reason</SideSubHeading>
                            <TypeDiv>{reason}</TypeDiv>
                            <SideHeading>Corrections</SideHeading>

                            <ContentBox>
                                {correction && <Blocks data={correction} />}
                            </ContentBox>
                            <ButtonBox>
                                <RightButton
                                    to={`/content-manager/update/${articlePK}/`}
                                >
                                    Go to article
                                </RightButton>
                            </ButtonBox>
                        </RightBox>
                    </Right>
                </Container>
            </SectionMain>
        </>
    );
}

export default TechUpdateViewRework;

const SectionMain = styled.section``;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Left = styled.div`
    width: 49%;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    padding: 13px;
`;
const MainText = styled.h1`
    color: #c9c3c2;
    font-family: "gordita_medium";
    font-size: 16px;
`;
const ArticleBox = styled.div`
    border-radius: 8px;
    border: 1px solid #333;
    background: #161619;
    padding: 40px;
    margin-top: 10px;
    & figure {
        margin-bottom: 14px;
        img {
            display: block;
            width: 100%;
        }
        .ytp-cued-thumbnail-overlay-image {
            height: 0;
            padding-top: 56.25%;
        }
        .embed-block-service-twitter {
            max-width: 560px;
            min-width: 560px;
            min-height: 87vh;
        }
        @media all and (max-width: 980px) {
            margin-bottom: 25px;
        }
    }
`;
const Right = styled.div`
    width: 49%;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
`;
const RightBox = styled.div`
    padding: 13px;
`;
const SideFirstHeading = styled.h1`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 18px;
    font-weight: 500;
    padding: 13px;
    border-bottom: 1px solid #2c2c2c;
`;
const SideSubHeading = styled.h2`
    color: #c9c3c2;
    font-family: "gordita_medium";
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
`;
const TypeDiv = styled.div`
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background: #161619;
    padding: 10px 20px;
    width: 100%;
    color: #fff;
    font-family: "gordita_medium";
    font-size: 14px;
`;
const SideHeading = styled.h1`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0px;
`;
const ContentBox = styled.div`
    border-radius: 6px;
    font-family: "gordita_medium";
    font-size: 14px;
    border: 1px solid #2c2c2c;
    background: #161619;
    padding: 10px 20px;
    width: 100%;
    & figure {
        margin-bottom: 20px;
        img {
            display: block;
            width: 100%;
        }
        .ytp-cued-thumbnail-overlay-image {
            height: 0;
            padding-top: 56.25%;
        }
        .embed-block-service-twitter {
            max-width: 560px;
            min-width: 560px;
            min-height: 87vh;
        }
        @media all and (max-width: 980px) {
            margin-bottom: 25px;
        }
    }
    & h1 {
        font-size: 32px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 14px;
        line-height: 32px;
    }
    & h2 {
        font-size: 24px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 14px;
        line-height: 32px;

        @media all and (max-width: 480px) {
            /* width: 90%; */
            font-size: 22px;
        }
    }
    & h3 {
        font-size: 20px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 14px;
        line-height: 32px;
    }
    & p {
        color: #fff;
        font-size: 16px;
        font-family: "gordita_regular";
        margin-top: 10px;
        /* width: 90%; */
        line-height: 30px;
        margin-bottom: 14px;

        @media all and (max-width: 480px) {
            /* width: 90%; */
            font-size: 15px;
            line-height: 27px;
        }
    }
    & a {
        color: #15bf81;
        text-decoration-line: underline;
        font-style: italic;
        cursor: pointer;
    }
`;
const ButtonBox = styled.div`
    display: flex;
    justify-content: right;
`;
const RightButton = styled(Link)`
    display: inline-block;
    width: 111px;
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #fe673a;
    color: #f2f2f2;
    font-family: "gordita_medium";
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
`;

const MainHeading = styled.h1`
    color: #fff;
    font-family: "gordita-medium";
    font-size: 32px;
`;
const SubHeading = styled.h2`
    color: #fff;
    font-family: "gordita-medium";
    font-size: 20px;
`;
const Paragraph = styled.p`
    color: #fff;
    font-family: "gordita-medium";
    font-size: 16px;
`;
