import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import { communityConfig } from "../../../axiosConfig";

import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import { Context } from "../../contexts/Store";
//modal
import FeedbackModal from "../examiner/modal/FeedbackModal";

function AepQuestionsModal({
    isExamModal,
    setExamModal,
    setSuccess,
    selectedId,
    setSelectedId,
    description,
    setDescription,
    examPk,
    student,
    fetchExamQuestions,
    questions,
    mark,
    totalQuestions,
    setMark,
    questionsLoading,
    setQuestionsLoading,
    setFeedback,
    isFeedback,
    setContinue,
    isContinue,
    students,
}) {
    const [overallReview, setOverallReview] = useState("");
    const [overallReviewDescription, setOverallReviewDescription] =
        useState("");
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [isSubmitFeedbackLoading, setSubmitFeedbackLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("eng");
    const [isHint, setHint] = useState(false);

    const {
        state: {
            user_data: { access_token },
            student_data: { student_name, phone },
        },
    } = useContext(Context);

    useEffect(() => {
        examPk && fetchExamQuestions();
    }, [examPk]);
    //---------------------------------------------------------
    //-------------answer submit-------------------------------

    const submitAnswer = () => {
        setSubmitLoading(true);
        setQuestionsLoading(true);
        communityConfig
            .post(
                `enquiry-exams/submit-answer/${questions.id}/`,
                {
                    student_sat_id: student.student_sat_id,
                    rating: mark,
                    description: description,
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSubmitLoading(false);
                    setFeedback(false);
                    if (totalQuestions == +questions.question_number) {
                        setExamModal(false);
                        setFeedback(true);
                        setQuestionsLoading(false);
                    } else {
                        fetchExamQuestions();
                    }
                } else {
                    setSubmitLoading(false);
                    setQuestionsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setSubmitLoading(false);
                setQuestionsLoading(false);
            });
    };

    //---------------------------------------------------------
    //-----------------submit feedback-------------------------

    const submitFeedback = () => {
        setSubmitFeedbackLoading(true);
        communityConfig
            .post(
                `/enquiry-exams/aep/submit-student-summary/
                `,
                {
                    student_sat_id: student.student_sat_id,
                    student_summary: overallReviewDescription,
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setSuccess(true);
                    setFeedback(false);
                    setSubmitFeedbackLoading(false);
                } else {
                    setSubmitFeedbackLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setSubmitLoading(false);
            });
    };
    //---------------------------------------------------------

    const marks = [
        { id: 1, mark: "1" },
        { id: 2, mark: "2" },
        { id: 3, mark: "3" },
        { id: 4, mark: "4" },
        { id: 5, mark: "5" },
        { id: 6, mark: "6" },
        { id: 7, mark: "7" },
        { id: 8, mark: "8" },
        { id: 9, mark: "9" },
        { id: 10, mark: "10" },
    ];

    useEffect(() => {
        if (isExamModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isExamModal]);

    return (
        <MainContainer>
            <FeedbackModal
                setSuccess={setSuccess}
                isFeedback={isFeedback}
                setFeedback={setFeedback}
                overallReview={overallReview}
                overallReviewDescription={overallReviewDescription}
                setOverallReviewDescription={setOverallReviewDescription}
                setOverallReview={setOverallReview}
                student={student}
                submitAnswer={submitAnswer}
                setMark={setMark}
                questionsLoading={questionsLoading}
                setDescription={setDescription}
                submitFeedback={submitFeedback}
                isSubmitFeedbackLoading={isSubmitFeedbackLoading}
                setContinue={setContinue}
                isContinue={isContinue}
            />
            {isExamModal ? (
                <Overlay
                    style={{ display: isExamModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isExamModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <Top>
                            <Head>
                                <MainTitle>Start Exam</MainTitle>
                            </Head>
                            <Close>
                                <CloseIcon
                                    onClick={() => {
                                        setExamModal(false);
                                        setContinue(!isContinue);
                                    }}
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Close Icon"
                                />
                            </Close>
                        </Top>
                        <NameSection>
                            {student.student_photo && (
                                <ProPic>
                                    <Span>
                                        <img src={student.student_photo} />
                                    </Span>
                                </ProPic>
                            )}
                            <NameSec>
                                <Name>{student_name?.toLowerCase()}</Name>
                                <Phone>{phone}</Phone>
                            </NameSec>
                        </NameSection>
                        <Middle>
                            <>
                                <QuestionHead>
                                    <QuestionTitle>
                                        {questions.question_title}
                                    </QuestionTitle>
                                    <LanguageDiv>
                                        <Options>Eng</Options>
                                        <LanguageSelector
                                            onClick={() => {
                                                if (
                                                    selectedLanguage === "mal"
                                                ) {
                                                    setSelectedLanguage("eng");
                                                } else {
                                                    setSelectedLanguage("mal");
                                                }
                                            }}
                                            selectedLanguage={selectedLanguage}
                                        >
                                            <LangButton></LangButton>
                                        </LanguageSelector>
                                        <Options>Mal</Options>
                                    </LanguageDiv>
                                </QuestionHead>
                                <SubDiv onClick={() => setHint(!isHint)}>
                                    <SubTitle>
                                        <QuestionDiv>
                                            {questions.question_number}
                                            {" . "}
                                            {selectedLanguage === "mal"
                                                ? questions.malayalam_question
                                                : questions.question}
                                        </QuestionDiv>
                                        <ArrowDiv isHint={isHint}>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                                alt="Arrow"
                                            />
                                        </ArrowDiv>
                                    </SubTitle>
                                    <Hint isHint={isHint}>
                                        Hint : {questions.answer_hint}
                                    </Hint>
                                </SubDiv>
                                <StatusList>
                                    {marks.map((item) => (
                                        <StatusDetail
                                            key={item.id}
                                            className={
                                                item.id === selectedId
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() => {
                                                setSelectedId(item.id);
                                                setMark(item.mark);
                                            }}
                                        >
                                            {item.mark}
                                        </StatusDetail>
                                    ))}
                                </StatusList>
                                <Details>
                                    <DetailsHead>
                                        Description if any..?
                                    </DetailsHead>
                                    <DetailsContent
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        value={description}
                                    ></DetailsContent>
                                </Details>
                            </>
                        </Middle>
                        <Button>
                            {questions.question_number === totalQuestions ? (
                                selectedId != "" ? (
                                    <Next
                                        onClick={() => {
                                            submitAnswer();
                                        }}
                                    >
                                        {isSubmitLoading ? (
                                            <ButtonLoader />
                                        ) : (
                                            "Submit"
                                        )}
                                    </Next>
                                ) : (
                                    <Next style={{ cursor: "not-allowed" }}>
                                        Submit
                                    </Next>
                                )
                            ) : selectedId != "" ? (
                                <Next
                                    onClick={() => {
                                        submitAnswer();
                                    }}
                                >
                                    {isSubmitLoading && questionsLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Next"
                                    )}
                                </Next>
                            ) : (
                                <Next style={{ cursor: "not-allowed" }}>
                                    Next
                                </Next>
                            )}
                        </Button>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default AepQuestionsModal;

const MainContainer = styled.div`
    z-index: 500;
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 650px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 35px 30px;
    width: 100%;
    height: 100%;
`;
const Top = styled.div`
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
`;
const NameSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #26272a;
`;
const ProPic = styled.div`
    margin-right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    font-family: "gordita_bold";
    border-radius: 50%;
    min-width: 50px;
`;
const Span = styled.span`
    width: 40px;
    height: 40px;
    img {
        width: 100%;
        display: block;
    }
`;
const NameSec = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;
const Name = styled.span`
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-family: "gordita_medium";
`;
const Phone = styled.span`
    font-size: 16px;
    color: #fe673a;
`;
const MainTitle = styled.h3`
    font-size: 20px;
    color: #fff;
    font-family: gordita_medium;
`;
const Close = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const Middle = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const QuestionTitle = styled.h3`
    color: #44c082;
    font-size: 16px;
    font-family: gordita_medium;
`;
const SubTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StatusList = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    margin-bottom: 15px;
`;
const StatusDetail = styled.li`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    background: #161619;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #26272a;
    border-radius: 5px;
    &.active {
        border: 1px solid #fe673a;
    }
`;

const Details = styled.div``;
const DetailsHead = styled.h3`
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
    font-family: gordita_medium;
`;
const DetailsContent = styled.textarea`
    width: 100%;
    height: 80px;
    border: 1px solid #26272a;
    background: #161619;
    border-radius: 5px;
    padding: 6px;
    color: #fff;
    font-size: 13px;
    outline: none;
    resize: none;
    font-family: gordita_regular;
`;
const Button = styled.div`
    display: flex;
    position: relative;
    color: #fff;
    justify-content: flex-end;
    margin-top: 30px;
`;

const Next = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: gordita_regular;
`;
const QuestionHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;
const LanguageSelector = styled.div`
    position: relative;
    width: 50px;
    height: 25px;
    border: 2px solid #26272a;
    background-color: #161619;
    border-radius: 10px;
    padding: 0 2px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: ${({ selectedLanguage }) =>
        selectedLanguage === "eng" ? "left" : "right"};
`;
const LangButton = styled.div`
    background-color: #fe6a37;
    height: 20px;
    width: 20px;
    border-radius: 50%;
`;
const LanguageDiv = styled.div`
    display: flex;
    align-items: center;
`;
const Options = styled.p`
    font-family: "gordita_medium";
    font-size: 15px;
    color: #fff;

    &:first-child {
        margin-right: 10px;
    }
    &:last-child {
        margin-left: 10px;
    }
`;
const QuestionDiv = styled.h3`
    color: #fff;
    font-size: 15px;
    font-family: gordita_medium;
`;
const ArrowDiv = styled.div`
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease 0s;
    transform: ${({ isHint }) => (isHint ? "rotate(-180deg)" : "rotate(0)")};

    & img {
        width: 100%;
        display: block;
    }
`;
const SubDiv = styled.div`
    border: 1px solid rgb(38, 39, 42);
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
`;
const Hint = styled.p`
    color: #fff;
    font-size: 15px;
    font-family: gordita_medium;
    white-space: pre-line;
    width: 100%;
    background: #161619;
    border-radius: 5px;
    overflow-y: scroll;
    transition: all 0.1s ease 0s;

    height: ${({ isHint }) => (isHint ? "auto" : "0")};
    padding: ${({ isHint }) => (isHint ? "10px 15px" : "0")};
    margin-top: ${({ isHint }) => (isHint ? "10px" : "0")};

    &::-webkit-scrollbar {
        display: none;
    }
`;
