import {useContext,useEffect,useState} from "react";
import styled from 'styled-components'
import UpSchoolStudents from './UpSchoolStudents';
import HsStudents from './HsStudents';
import HSSStudents from './HSSStudents';
import Levels from './Levels';
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import AttendedCount from "./AttendedCount";


function Total({count, isLoading}) {

  return (
    <>
        <Container>
            <TotalContainer>
                <Item>
                    <Levels count={count}/>
                </Item> 
                <Item>
                    <UpSchoolStudents count={count}/>
                </Item>
                <Item>
                    <HsStudents count={count}/>
                </Item>
                <Item>
                    <HSSStudents count={count}/> 
                </Item>      
            </TotalContainer>
        </Container>
    </>
  )
}
const Container = styled.div`
   width: 100%; 
`;
const TotalContainer = styled.ul`
     display: grid;
     width: 100%;
  gap: 1rem;
  grid-template-columns:1fr 1fr 1fr 1fr;
  @media (max-width: 1180px) {
        grid-template-columns:  1fr 1fr 1fr;
        width: 100%;
    }
 
  @media (max-width: 980px) {
        grid-template-columns:  1fr 1fr;
        width: 100%;
    }
    @media (max-width: 640px) {
        grid-template-columns:  1fr;
        width: 100%;
    }


`;
const Item = styled.li`
    width: 100%;
    
`;



export default Total