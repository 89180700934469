/** @format */

import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { activityConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function DeleteTagModal({ isModal, setModal }) {
    const [selectedId, setSelectedId] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleModal();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const handleModal = () => {
        setSelectedId("");
        setModal(false);
        setDescription("");
    };
    const handleCloseButtonClick = () => {
        handleModal();
    };

    return (
        <MainContainer>
            {isModal ? (
                <Overlay
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Head>
                                <Remove>
                                    <RemoveIcon>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2024/remove-icon.svg"
                                            alt="Remove"
                                        />
                                    </RemoveIcon>
                                    <h3>Delete Tag</h3>
                                </Remove>

                                <Close onClick={handleCloseButtonClick}>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Close Icon"
                                    />
                                </Close>
                            </Head>

                            <MainTitle>
                                Are you sure you want to delete "Artificial
                                Intelligence"?
                            </MainTitle>
                        </Top>
                        <Discription>
                            <p>
                                <span>
                                    <img src="" alt="" />
                                </span>
                                Transfer all child tag to another parent tag.
                            </p>
                            <p>
                                <span>
                                    <img src="" alt="" />
                                </span>
                                Transfer all child tag to another parent tag.
                            </p>
                        </Discription>

                        <Button>
                            <Next
                                style={{
                                    cursor:
                                        selectedId && description.length
                                            ? "pointer"
                                            : "not-allowed",
                                }}
                            >
                                Delete
                            </Next>
                        </Button>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default DeleteTagModal;
const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    /* box-shadow: 0px 3px 56px #00000048; */
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    height: 100%;
`;
const Top = styled.div`
    margin-bottom: 20px;
`;
const Discription = styled.div`
    border-bottom: 1px solid #26272a;

    p {
        font-family: "gordita_regular";
        margin-bottom: 10px;
    }
    span {
    }
    img {
        width: 100%;
        display: block;
    }
`;
const CommentSection = styled.div`
    display: flex;
    position: relative;
    font-size: 14px;
    font-family: "gordita_medium";
    color: #828282;
    justify-content: space-between;
    text-align: center;
`;
const Content = styled.div`
    p {
        font-family: "gordita_regular";
        font-size: 13px;
        margin-top: 20px;
    }
`;

const Message = styled.div`
    width: 100%;
    display: flex;
    /* border: 1px solid #e4e7ec; */
    background: #0d121c;
    border-radius: 4px;
    padding: 10px 24px;
    margin-top: 4px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid #3d3d3d;
    p {
        font-family: "gordita_regular";
    }
    @media all and (max-width: 480px) {
        padding: 0px 10px;
    }
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    border-bottom: 1px solid #26272a;
`;
const MainTitle = styled.h3`
    font-size: 16px;
    color: #fff;
    font-family: "gordita_regular";
`;
const Remove = styled.div`
    display: flex;
    margin-bottom: 12px;

    img {
        width: 100%;
        display: block;
    }
    h3 {
        font-family: "gordita_medium";
        color: #fff;
    }
`;
const RemoveIcon = styled.div`
    width: 30px;
    height: 30px;
`;
const Close = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const Middle = styled.div``;
const Profile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;
const ProfileIconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fe673a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fe673a;
    position: relative;
    span {
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
const ProfileDetails = styled.div`
    margin-left: 10px;
    font-family: gordita_regular;
`;
const UserName = styled.h3`
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
`;
const PhoneNumber = styled.h3`
    font-size: 14px;
    color: #fe673a;
`;
const SubTitle = styled.h3`
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    font-family: gordita_medium;
`;
const StatusList = styled.ul``;
const StatusDetail = styled.li`
    display: flex;
    padding: 10px;
    align-items: center;
    background: #161619;
    cursor: pointer;
    border: 1px solid #26272a;
    border-radius: 4px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    &.active {
        border: 1px solid #fe673a;
    }
`;
const Select = styled.div`
    &.show {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #1b1c1f;
        border: 1px solid #26272a;
    }
`;
const Orangetickcontainer = styled.div`
    width: 70%;
`;
const Orangetick = styled.img`
    width: 100%;
    display: block;
`;

const Status = styled.p`
    margin-left: 10px;
    font-size: 14px;
    font-family: gordita_regular;
    color: #fff;
`;
const Details = styled.div`
    margin-top: 16px;
    transition: 0.3s;
`;
const DetailsHead = styled.h3`
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
    font-family: gordita_medium;
`;
const DetailsContent = styled.textarea`
    width: 100%;
    height: 80px;
    border: 1px solid #26272a;
    background: #161619;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    /* opacity:0.5; */
    font-size: 14px;
    outline: none;
    resize: none;
    font-family: gordita_regular;
`;
const Button = styled.div`
    display: flex;
    color: #fff;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 35px;
`;
const Cancel = styled.div`
    border-radius: 5px;
    width: 210px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-right: 25px;
    background: #fff;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    font-family: "gordita_regular";
`;
const Next = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px;
    width: 134px;
    height: 45px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    font-family: gordita_regular;
`;
