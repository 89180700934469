import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const BdaLeadTechschool = lazy(() =>
    import("../../../screens/bda/BdaLeadTechschool")
);
const BdaLeadTechdegree = lazy(() =>
    import("../../../screens/bda/BdaLeadTechdegree")
);
const BdaLeadTechgrad = lazy(() =>
    import("../../../screens/bda/BdaLeadTechgrad")
);

// const TechGradManager = lazy(() =>
//     import("../../../screens/registration-manager/TechGradManager")
// );
// const TechSchoolStatusmanager = lazy(() =>
//     import("../../../screens/registration-manager/TechSchoolStatusmanager")
// );
// const TechDegreeStatusManager = lazy(() =>
//     import("../../../screens/registration-manager/TechDegreeStatusManager")
// );
// const TechGradStatusManager = lazy(() =>
//     import("../../../screens/registration-manager/TechGradStatusManager")
// );
function StudentStatusRouter() {
    return (
        <Routes>
            <Route path="studentstatus/" element={<BdaLeadTechschool />} />
            <Route
                path="college-studentstatus/"
                element={<BdaLeadTechdegree />}
            />
            <Route
                path="graduates-studentstatus/"
                element={<BdaLeadTechgrad />}
            />
        </Routes>
    );
}

export default StudentStatusRouter;
