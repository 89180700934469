import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
//components
import EventDetailsRouter from "./EventDetailsRouter";
import SteypHelmet from "../../../helpers/SteypHelmet";
const UpcomingEvents = lazy(() =>
    import("../../../screens/examiner-head/UpcomingEvents")
);
const CompletedEvents = lazy(() =>
    import("../../../screens/examiner-head/CompletedEvents")
);
const OngoingEvents = lazy(() =>
    import("../../../screens/examiner-head/OngoingEvents")
);
const ExaminerStudents = lazy(() =>
    import("../../../includes/examiner-head/ExaminerStudents")
);
const ApplicantsList = lazy(() =>
    import("../../../screens/examiner-head/ApplicantsList")
);
const CareerAdvisorList = lazy(() =>
    import("../../../screens/examiner-head/CareerAdvisorList")
);

const TotalAdmissions = lazy(() =>
    import("../../../screens/examiner-head/TotalAdmissions")
);

function EventsRouter() {
    return (
        <div id="main">
            <SteypHelmet title="Events" />
            <Routes>
                <Route path="upcoming" element={<UpcomingEvents />} />
                <Route path="completed" element={<CompletedEvents />} />
                <Route path="ongoing" element={<OngoingEvents />} />
                <Route
                    path=":status/:exam_center_pk/*"
                    element={<EventDetailsRouter />}
                />
                <Route
                    path=":status/:exam_center_pk/examiners/students-list/"
                    element={<ExaminerStudents />}
                />
                <Route
                    path=":status/:exam_center_pk/details/students/:subject_slug/"
                    element={<ApplicantsList />}
                />
                <Route
                    path=":status/:exam_center_pk/details/:subject_slug"
                    element={<CareerAdvisorList />}
                />
                <Route
                    path=":status/:exam_center_pk/details/total-admissions"
                    element={<TotalAdmissions />}
                />
            </Routes>
        </div>
    );
}

export default EventsRouter;
