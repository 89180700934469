import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../axiosConfig";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import { error } from "jquery";

function CategoryDeleteModal({
    modalType,
    setModalType,
    deleteModal,
    setDeleteModal,
    selectedCategory,
    access_token,
    editCategoryPK,
    setReload,
    resetValues,
    parentValue,
    setParentValue,
}) {
    const [parentCategory, setParentCategory] = useState([]);
    const [isSelected, setSelected] = useState(true);
    const [deleteLoder, setDeleteLoader] = useState(false);
    const [selectedParent, setSelectedParent] = useState("");
    const [isErrors, setErrors] = useState(false);

    useEffect(() => {
        setErrors(false);
        const fetchParentCategory = () => {
            learnConfig
                .get("/tech-updates/list-parent-categories/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        q: parentValue,
                    },
                })

                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setParentCategory(data);
                        setErrors(false);
                    } else {
                        setErrors(true);
                    }
                })
                .catch((error) => {});
        };
        fetchParentCategory();
    }, [parentValue]);

    const deleteCategory = () => {
        setDeleteLoader(true);
        setErrors(false);
        learnConfig
            .get(`/tech-updates/delete-category/${editCategoryPK}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDeleteLoader(false);
                    setParentValue("");
                    setErrors(false);
                    if (
                        data.is_parent_category &&
                        data.is_child_category_available
                    ) {
                        setModalType("transfer_category");
                    } else if (data.is_articles_available) {
                        setModalType("transfer_articles");
                    } else if (data.is_deleted) {
                        setReload((prev) => !prev);
                        setDeleteModal(false);
                        resetValues();
                    }
                } else {
                    setDeleteLoader(false);
                    setErrors(true);
                }
            })
            .catch((error) => {
                setDeleteLoader(false);
            });
    };

    const transferCategory = () => {
        setDeleteLoader(true);
        setErrors(false);
        const formData = new FormData();
        formData.append("category", selectedParent);
        learnConfig
            .post(
                `/tech-updates/change-parent-category/${editCategoryPK}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDeleteLoader(false);
                    setParentValue("");
                    setErrors(false);
                    if (data.is_articles_available) {
                        setModalType("transfer_articles");
                    } else if (data.is_deleted) {
                        setReload((prev) => !prev);
                        setDeleteModal(false);
                        resetValues();
                    }
                } else {
                    setDeleteLoader(false);
                    setErrors(true);
                }
            })
            .catch((error) => {
                console.error(error);
                setDeleteLoader(false);
            });
    };

    const transferArticles = () => {
        setDeleteLoader(true);
        setErrors(false);
        const formData = new FormData();
        formData.append("category", selectedParent);
        learnConfig
            .post(`/tech-updates/swap-articles/${editCategoryPK}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDeleteLoader(false);
                    setErrors(false);
                    setParentValue("");
                    setReload((prev) => !prev);
                    setDeleteModal(false);
                    resetValues();
                } else {
                    setDeleteLoader(false);
                    setErrors(true);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <MainContainer>
            {deleteModal ? (
                <Overlay
                    onClick={() => {
                        setDeleteModal(false);
                        resetValues();
                    }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: deleteModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <Header>
                            <HeadText>
                                {modalType === "delete_category" ? (
                                    <HeadImage>
                                        <ImgSpan>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/delete-icon.svg"
                                                alt="DeleteButton"
                                            />
                                        </ImgSpan>
                                        <p>Delete Category</p>
                                    </HeadImage>
                                ) : modalType === "transfer_category" ? (
                                    <HeadImage>
                                        <ImgSpan>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/transfer.svg "
                                                alt="transfer"
                                            />
                                        </ImgSpan>
                                        <p>Transfer Child categories</p>
                                    </HeadImage>
                                ) : modalType === "transfer_articles" ? (
                                    <HeadImage>
                                        <ImgSpan>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/transfer.svg "
                                                alt="transfer"
                                            />
                                        </ImgSpan>
                                        <p>Transfer Articles</p>
                                    </HeadImage>
                                ) : (
                                    ""
                                )}
                            </HeadText>
                            <HeaderClose
                                onClick={() => {
                                    setDeleteModal(false);
                                    resetValues();
                                }}
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Close Icon"
                                />
                            </HeaderClose>
                        </Header>
                        <CategoriesContainer>
                            {modalType === "delete_category" ? (
                                <>
                                    <ParentCategory>
                                        Are you sure you want to delete{""}
                                        <small> "{selectedCategory}"</small> ?
                                    </ParentCategory>
                                    <ParentCategoryInput>
                                        <IconTick>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/orange-round-tick.svg "
                                                alt="Tick Icon"
                                            />
                                        </IconTick>
                                        <p>
                                            Transfer all child categories to
                                            another parent category.
                                        </p>
                                    </ParentCategoryInput>
                                    <ParentCategoryInput>
                                        <IconTick>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/orange-round-tick.svg "
                                                alt="Tick Icon"
                                            />
                                        </IconTick>
                                        <p>
                                            Transfer all articles to another
                                            parent category.
                                        </p>
                                    </ParentCategoryInput>
                                </>
                            ) : modalType === "transfer_category" ? (
                                <>
                                    <ParentCategory>
                                        Transfer child categories in this parent
                                        category before deleting.
                                    </ParentCategory>
                                    <ParentModal>
                                        <InputContainer>
                                            <Label>
                                                Choose parent category
                                            </Label>
                                            <InputSub>
                                                <Input
                                                    type="text"
                                                    placeholder="Select category"
                                                    value={parentValue}
                                                    onChange={(e) => {
                                                        setParentValue(
                                                            e.target.value
                                                        );
                                                        setSelected(true);
                                                    }}
                                                />
                                                <Icon
                                                    className={
                                                        parentValue
                                                            ? "rotated"
                                                            : ""
                                                    }
                                                >
                                                    <img
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                                        alt="Image"
                                                    />
                                                </Icon>
                                            </InputSub>
                                        </InputContainer>
                                        {isSelected && parentValue && (
                                            <InputModalContainer>
                                                {parentCategory.length > 0 ? (
                                                    <ul>
                                                        {parentCategory.map(
                                                            (data, index) => (
                                                                <li
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setSelected(
                                                                            false
                                                                        );
                                                                        setParentValue(
                                                                            data.title
                                                                        );
                                                                        setSelectedParent(
                                                                            data.id
                                                                        );
                                                                    }}
                                                                >
                                                                    {data.title}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                ) : (
                                                    <ul>
                                                        <li>
                                                            No Category Found!
                                                        </li>
                                                    </ul>
                                                )}
                                            </InputModalContainer>
                                        )}
                                        {isErrors && !parentValue && (
                                            <ErrorMessage>
                                                Category should be selected*
                                            </ErrorMessage>
                                        )}
                                    </ParentModal>
                                </>
                            ) : modalType === "transfer_articles" ? (
                                <>
                                    <ParentCategory>
                                        Transfer articles in this parent before
                                        deleting.
                                    </ParentCategory>
                                    <ParentModal>
                                        <InputContainer>
                                            <Label>
                                                Choose parent category
                                            </Label>
                                            <InputSub>
                                                <Input
                                                    type="text"
                                                    placeholder="Select category"
                                                    value={parentValue}
                                                    onChange={(e) => {
                                                        setParentValue(
                                                            e.target.value
                                                        );
                                                        setSelected(true);
                                                    }}
                                                />
                                                <Icon
                                                    className={
                                                        parentValue
                                                            ? "rotated"
                                                            : ""
                                                    }
                                                >
                                                    <img
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                                        alt="Image"
                                                    />
                                                </Icon>
                                            </InputSub>
                                        </InputContainer>
                                        {isSelected && parentValue && (
                                            <InputModalContainer>
                                                {parentCategory.length > 0 ? (
                                                    <ul>
                                                        {parentCategory.map(
                                                            (data, index) => (
                                                                <li
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setSelected(
                                                                            false
                                                                        );
                                                                        setParentValue(
                                                                            data.title
                                                                        );
                                                                        setSelectedParent(
                                                                            data.id
                                                                        );
                                                                    }}
                                                                >
                                                                    {data.title}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                ) : (
                                                    <ul>
                                                        <li>
                                                            No Category Found!
                                                        </li>
                                                    </ul>
                                                )}
                                            </InputModalContainer>
                                        )}
                                        {isErrors && !parentValue && (
                                            <ErrorMessage>
                                                Category should be selected*
                                            </ErrorMessage>
                                        )}
                                    </ParentModal>
                                </>
                            ) : (
                                ""
                            )}
                        </CategoriesContainer>
                        <ButtonConatiner>
                            <Submit
                                onClick={() => {
                                    if (modalType === "delete_category") {
                                        deleteCategory();
                                    } else if (
                                        modalType === "transfer_category"
                                    ) {
                                        transferCategory();
                                    } else if (
                                        modalType === "transfer_articles"
                                    ) {
                                        transferArticles();
                                    }
                                }}
                            >
                                {deleteLoder ? (
                                    <ButtonLoader />
                                ) : modalType === "delete_category" ? (
                                    "Delete"
                                ) : (
                                    "Transfer"
                                )}
                            </Submit>
                        </ButtonConatiner>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default CategoryDeleteModal;

const MainContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Modal = styled.div`
    width: 90%;
    max-width: 494px;
    /* height: 50vh; */
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #3b3b3b;
    background: #1b1c20;
    border-radius: 5px;
    /* overflow-y: hidden; */
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const HeadImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #fff;
        font-size: 18px;
        font-family: "gordita_medium";
    }
`;
const ImgSpan = styled.span`
    width: 30px;
    img {
        display: block;
        width: 100%;
    }
    margin-right: 4px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-height: 200px;
`;

const ButtonConatiner = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;
    padding: 20px 20px 20px 20px;
    border-top: 1px solid #3b3b3b;
`;

const Cancel = styled.button`
    padding: 0px 14px;
    background-color: #1b1c1f;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-family: "gordita_medium";
    border: 1px solid #fe673a;
    width: 100px;
    height: 40px;
    flex-shrink: 0;
`;

const Submit = styled(Cancel)`
    background-color: #fe673a;
    margin-left: 18px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-bottom: 1px solid #3b3b3b;
`;
const HeadText = styled.h4`
    color: #fff;
    font-size: 22 px;
    font-family: "gordita_medium";
`;
const HeaderClose = styled.span`
    width: 20px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const CategoriesContainer = styled.div`
    padding: 20px 20px;
`;

const ParentCategory = styled.p`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_regular";
    margin-bottom: 28px;
    small {
        font-size: 20px;
        font-family: "gordita_medium";
    }
`;

const ParentCategoryInput = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    p {
        color: #fff;
        font-size: 15px;
        font-family: "gordita_regular";
    }
`;
const IconTick = styled.span`
    width: 22px;
    img {
        display: block;
        width: 100%;
    }
    margin-right: 4px;
`;
const Input = styled.input`
    width: 100%;
    font-size: 16px;
    color: #ffff;
    &::placeholder {
        color: #4f5360;
    }
`;

const Icon = styled.span`
    width: 18px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
        transition: transform 0.3s ease;
        transform: rotate(0deg);
    }

    &.rotated img {
        transform: rotate(-180deg);
    }
`;
const InputContainer = styled.div``;

const Label = styled.label`
    color: #7b7b7b;
    font-family: "gordita_regular";
    font-size: 12px;
`;

const InputSub = styled.div`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    height: 60px;
    margin-top: 10px;
    padding: 12px 10px;
    align-items: center;
    justify-content: space-between;
`;

const InputModalContainer = styled.div`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    align-items: center;
    z-index: 999;
    position: absolute;
    top: 96px;
    left: 0;
    right: 0;
    width: 100%;

    margin-bottom: 14px;
    cursor: pointer;
    ul {
        width: 100%;
        min-height: 53px;
        max-height: 209px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        li {
            padding: 16px 20px;
            font-size: 14px;
            color: #fff;
            :hover {
                background: #1b1c1f;
            }
        }
    }
`;
const ParentModal = styled.div`
    position: relative;
`;
const ErrorMessage = styled.p`
    position: absolute;
    color: red;
    font-size: 12px;
    top: 94px;
    right: 0;
`;
