import React from "react";
import styled from "styled-components";

function AttendeesEligible() {
	return (
		<>
			<Container>
				<Cover>
					<Item type="slno">Sl No</Item>
					<Item>School</Item>
					<Item type="slno">UP</Item>
					<Item type="slno">HS</Item>
					<Item type="slno">HSS</Item>
					<Item type="dist">Total Count</Item>
					<Item type="dist">Eligibility Count</Item>
				</Cover>
			</Container>
		</>
	);
}
const Container = styled.div`
	color: rgb(255, 255, 255);
	border-radius: 5px;
	width: 100%;
	background-color: #1b1c1f;
	min-width: 1180px;
`;
const Cover = styled.ul`
	display: flex;
	border-bottom: 1px solid #26272a;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
	color: rgb(255, 255, 255);
`;
const Item = styled.li`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 14px;
	width: ${({ type }) =>
		type === "slno" ? "8%" : type === "dist" ? "11%" : "15%"};
		/* :last-child{
			text-align: end;
		} */
     @media all and (max-width: 640px) {
        font-size: 13px;
	}
`;


export default AttendeesEligible;
