import React, { useContext, useEffect } from "react";
import { useState } from "react";

//packeges
import styled from "styled-components";
import $ from "jquery";

//Components
import SingleAgentCard from "../../includes/community/SingleAgentCard";
import AgentRegistrationModal from "../../includes/community/AgentRegistrationModal";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../contexts/Store";
import { accountsConfig } from "../../../axiosConfig";

function ChiefAgentList() {
    const [Ismodal, setModal] = useState(false);
    const [aepData, setAepData] = useState([]);
    const [agentData, setAgentData] = useState([
        {
            name: "Thushara Ramadas",
            id: "1000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "20",
            total_applicant: "26",
            total_admission: "20",
        },
        {
            name: "Revathy PR",
            id: "2000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "20",
            total_applicant: "6",
            total_admission: "27",
        },
        {
            name: "Varsha VT",
            id: "3000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "20",
            total_applicant: "26",
            total_admission: "20",
        },
        {
            name: "Varsha c",
            id: "4000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "200",
            total_applicant: "500",
            total_admission: "200",
        },
        {
            name: "Athira Babu",
            id: "5000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "209",
            total_applicant: "260",
            total_admission: "200",
        },
        {
            name: "name6",
            id: "6000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "100",
            total_applicant: "270",
            total_admission: "290",
        },
        {
            name: "name7",
            id: "7000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "20",
            total_applicant: "290",
            total_admission: "20",
        },
        {
            name: "name8",
            id: "8000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "20",
            total_applicant: "26",
            total_admission: "80",
        },
        {
            name: "name9",
            id: "9000",
            phone: "796857557",
            alternate_phone: "78908766",
            company_name: "Talrop",
            total_aep: "20",
            total_applicant: "26",
            total_admission: "290",
        },
    ]);
    useEffect(() => {
        if (Ismodal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [Ismodal]);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    useEffect(() => {
        accountsConfig
            .get("api/v1/users/sro/total-authorized-education-partners/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (res) {
                if (res.data.StatusCode == 6000) {
                    setAepData(res.data.data);
                }
            });
    }, []);

    return (
        <>
            <AgentRegistrationModal
                formsModal={Ismodal}
                setFormsModal={setModal}
            />

            <Container>
                <TopContainer>
                    <Heading>AEP</Heading>
                    <CreateNewButton
                        // onClick={() => {
                        //     // setModal(true);
                        //     handleNavigation();
                        // }}
                        to="agent-register"
                    >
                        <PlusImage>
                            <Plus
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-08-2022/plusbutton.svg"
                                alt="Image"
                            />
                        </PlusImage>
                        Create New AEP
                    </CreateNewButton>
                </TopContainer>

                <BottomContainer>
                    <MainHead>
                        <MainSub>
                            <Contents
                                type="slno"
                                onClick={() => setModal(true)}
                            >
                                Sl.No
                            </Contents>
                            <Contents type="name">Name</Contents>
                            <Contents type="district">District</Contents>
                            <Contents type="type">Type</Contents>
                            <Contents type="totalExamCompleted">
                                Total Exam Completed
                            </Contents>
                            <Contents type="admission">Admission</Contents>
                            <Contents type="ExpiryStatus">
                                Expiry Status
                            </Contents>
                        </MainSub>
                    </MainHead>
                    {aepData.map((item, index) => (
                        <SingleAgentCard
                            key={item.id}
                            item={item}
                            index={index}
                        />
                    ))}
                </BottomContainer>
            </Container>
        </>
    );
}
const Container = styled.div``;
const TopContainer = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const Heading = styled.p`
    font-size: 18px;
    font-family: gordita_medium;
    color: rgb(255, 255, 255);
`;
const CreateNewButton = styled(Link)`
    cursor: pointer;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    background-color: rgb(254, 103, 58);
    border: 1px solid #26272a;
    font-family: gordita_medium;
    font-size: 18px;
    width: 215px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const PlusImage = styled.div`
    width: 18px;
`;
const Plus = styled.img`
    display: block;
    width: 100%;
`;
const BottomContainer = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 10px;
    // @media all and (max-width: 1080px) {
    //     grid-template-columns: 1fr 1fr;
    // }
    // @media all and (max-width: 640px) {
    //     grid-template-columns: 1fr;
    // }
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    text-transform: capitalize;
    font-family: "gordita_medium";
    font-size: 14px;
    text-align: left;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "name"
            ? "20%"
            : type === "district"
            ? "20%"
            : type === "type"
            ? "15%"
            : type === "totalExamCompleted"
            ? "15%"
            : type === "admission"
            ? "10%"
            : type === "ExpiryStatus"
            ? "10%"
            : ""};
    :nth-child(7) {
        text-align: right;
    }
`;

export default ChiefAgentList;
