import React from "react";
import styled from "styled-components";
import EditGroup from "../../screens/groups/modals/EditGroup";
import Avatar from "react-avatar";
import SectionLoader from "../../helpers/SectionLoader";
import GroupStatus from "../../screens/groups/modals/GroupStatus";
import AddStudent from "../../screens/groups/modals/AddStudent";

function GroupInfo({
    showModal,
    setShowModal,
    groupData,
    loading,
    setReload,
    isReload,
    successful,
    setSuccessful,
    showStatusModal,
    setShowStatusModal,
    studenModal,
    setShowstudentModal,
}) {
    // Date formatter
    const dateString = groupData?.date_added;
    const date = new Date(dateString);
    const options = {
        year: "2-digit",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    const formattedDate = date.toLocaleString("en-US", options);

    return (
        <>
            <AddStudent
                studenModal={studenModal}
                setShowstudentModal={setShowstudentModal}
                successful={successful}
                setSuccessful={setSuccessful}
                setReload={setReload}
            />
            <EditGroup
                showModal={showModal}
                setShowModal={setShowModal}
                groupData={groupData}
                setReload={setReload}
                isReload={isReload}
                successful={successful}
                setSuccessful={setSuccessful}
            />
            <GroupStatus
                showStatusModal={showStatusModal}
                setShowStatusModal={setShowStatusModal}
                groupData={groupData}
                successful={successful}
                setSuccessful={setSuccessful}
                setReload={setReload}
                isReload={isReload}
            />
            {loading ? (
                <SectionLoader />
            ) : (
                <MainContainer>
                    <TopSection>
                        <LeftDiv>
                            <GroupName>{groupData?.name ?? "--"}</GroupName>
                        </LeftDiv>
                        <RightDiv>
                            <EditButton
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                <span>
                                    <img
                                        src={
                                            require("../../../assets/images/Edit.svg")
                                                .default
                                        }
                                        alt="Edit"
                                    />
                                </span>
                                <small>Edit group info</small>
                            </EditButton>
                            <EditButton
                                onClick={(e) => {
                                    setShowstudentModal(true);
                                }}
                            >
                                <small>Add Student</small>
                            </EditButton>
                            <EditButton
                                onClick={(e) => {
                                    setShowStatusModal(true);
                                }}
                            >
                                <small>Change status</small>
                            </EditButton>
                        </RightDiv>
                    </TopSection>
                    <BottomSection>
                        <Item>
                            <Label>Group icon</Label>
                            <Imgiv>
                                {groupData?.icon ? (
                                    <img src={groupData?.icon} alt="icon" />
                                ) : (
                                    <Avatar
                                        name={groupData?.name}
                                        size="40"
                                        round
                                    />
                                )}
                            </Imgiv>
                        </Item>
                        <Item>
                            <Label>Participants</Label>
                            <Count>
                                {groupData?.no_of_participants ?? "--"}
                            </Count>
                        </Item>
                        <Item>
                            <Label>Create Date</Label>
                            <DateAdded>{formattedDate ?? "--"}</DateAdded>
                        </Item>
                        <Item>
                            <Label>Status</Label>
                            <Status status={groupData?.status}>
                                {groupData?.status}
                            </Status>
                        </Item>
                    </BottomSection>
                </MainContainer>
            )}
        </>
    );
}

export default GroupInfo;

const MainContainer = styled.div`
    width: 100%;
    color: white;
    background: #1b1c1f;
    border-radius: 6px;
    padding: 24px 18px;
    margin: 24px 0;
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(0 0 0);
    padding-bottom: 24px;
`;
const LeftDiv = styled.div``;
const RightDiv = styled.div`
    display: flex;
    align-items: center;
`;
const GroupName = styled.h2`
    font-size: 16px;
    color: #ffff;
    font-family: "gordita_medium";
`;
const EditButton = styled.div`
    margin-right: 12px;
    border: 1px solid #fe673a;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    span {
        margin-right: 8px;
    }
    img {
        width: 100%;
        display: block;
    }
    small {
        font-size: 16px;
        color: #fe673a;
    }
    &:hover {
        opacity: 0.8;
    }
`;
const BottomSection = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
`;
const Item = styled.li`
    text-align: center;
`;
const Label = styled.h6`
    color: #b7b7b7;
    font-size: 14px;
    margin-bottom: 8px;
`;
const Imgiv = styled.div`
    margin: 0 auto;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
    }
`;
const Count = styled.h6`
    color: #ba5032;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const DateAdded = styled.h6`
    color: #ba5032;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const Status = styled.small`
    color: ${(props) => {
        switch (props.status) {
            case "Archived":
                return "#FF1D5C";
            case "Active":
                return "#0FA76F";
        }
    }};
    background-color: ${(props) => {
        switch (props.status) {
            case "Archived":
                return "rgba(54, 25, 32, 0.4)"; // Adjust the alpha value here
            case "Active":
                return "rgba(15, 167, 111, 0.2)"; // Adjust the alpha value here
        }
    }};
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 25px;
    text-transform: capitalize;
    display: inline-block;
`;
