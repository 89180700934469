import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";

function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}

const WebLink = ({ editor, setLink }) => {
    const [webLink, setWebLink] = useState(
        editor.isActive("link") ? editor.getAttributes("link").href : null
    );

    const [isInput, setIsInput] = useState(false);
    useEffect(() => {
        if (editor.isActive("link")) {
            setWebLink(editor.getAttributes("link").href);
        } else {
            setWebLink("");
        }
    }, [editor.isActive("link"), isInput]);

    const dropDownRef = useRef(null);

    useOutsideAlerter(dropDownRef, () => setIsInput(false));

    const onSubmit = (e) => {
        e.preventDefault();

        const linkValue = e.target.elements.link.value.trim(); // Trim the input value

        console.log("TRIM", linkValue);
        // Check if the input is empty after trimming whitespace
        if (linkValue === "") {
            editor.chain().focus().extendMarkRange("link").unsetLink().run();
        } else {
            // Set the link with the trimmed value
            editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .toggleLink({ href: linkValue })
                .run();
        }

        // Toggle the input state and clear the form input after setting or unsetting the link
        setIsInput((prev) => !prev);
        e.target.elements.link.value = ""; // Clear the input field
    };
    return (
        <Container>
            <LinkButton
                type="button" // Make sure this is specified as "button" to prevent accidental form submission
                onClick={() => {
                    // if(editor.getAttributes('link').href){
                    //     editor.commands.unsetLink()
                    // }else{
                    setIsInput((prev) => !prev);
                    // }
                }}
                className={editor.isActive("link") ? "is-active" : "icon"}
            >
                <Icon
                    src={
                        "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-02-2024/icons/link.svg"
                    }
                    alt="link"
                />
            </LinkButton>
            <SelectContainer
                style={{
                    opacity: isInput ? 1 : 0,
                    transform: isInput ? "scaleY(1)" : "scaleY(0)",
                    visibility: isInput ? "visible" : "hidden",
                }}
                ref={dropDownRef}
            >
                <Form action="#" onSubmit={onSubmit}>
                    {" "}
                    {/* The action attribute is not needed when using onSubmit */}
                    <Input
                        type="text"
                        value={webLink}
                        onChange={(e) => setWebLink(e.target.value)}
                        placeholder="Enter Link"
                        name="link"
                    />
                    <LinkButton
                        type="submit"
                        className={
                            editor.isActive("link") ? "is-active" : "icon"
                        }
                    >
                        <Icon
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-02-2024/icons/link.svg"
                            }
                            alt="link"
                        />
                    </LinkButton>
                </Form>
            </SelectContainer>
        </Container>
    );
};
const Container = styled.div`
    position: relative;
`;
const Form = styled.form`
    display: flex;
    align-items: center;
`;
const LinkButton = styled.button`
    border: none;
    color: #000;
    background: #90909070;
    border-radius: 4px;
    padding: 3px;
    &.icon {
        background: transparent;
        border: none;
        padding: 3px;
    }
`;
const Input = styled.input`
    border: 0;
    border-bottom: 1px solid #7d7d7d;
    padding: 5px;
    margin-right: 5px;
    &:focus {
        outline: none;
    }
`;
const Icon = styled.img`
    display: block;
    width: 100%;
`;

const SelectContainer = styled.div`
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 13px 0px #10182833;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 35px;
    overflow: hidden;
    z-index: 999;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
    transform-origin: top;
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
    display: flex;
`;
export default WebLink;
