import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

function ReadMoreModal({ isModal, setIsmodal, student_summary }) {
    //function for outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsmodal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <MainContainer>
            {isModal ? (
                <Overlay
                    onClick={() => setIsmodal(false)}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Head>
                                <MainTitle>Student Summary</MainTitle>
                                <Close>
                                    <CloseIcon
                                        onClick={() => {
                                            setIsmodal(false);
                                        }}
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Close Icon"
                                    />
                                </Close>
                            </Head>
                        </Top>
                        <Middle>
                            <SubTitle>
                                {student_summary?.charAt(0).toUpperCase() +
                                    student_summary?.slice(1)}
                            </SubTitle>
                        </Middle>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default ReadMoreModal;
const MainContainer = styled.div``;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    box-shadow: 0px 3px 56px #00000048;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 25px;
    width: 100%;
    max-height: 50vh;
`;
const Top = styled.div`
    margin-bottom: 20px;
    border-bottom: 1px solid #26272a;
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const MainTitle = styled.h5`
    font-size: 18px;
    color: #737375;
    font-family: gordita_medium;
`;
const Close = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const SubTitle = styled.p`
    font-size: 14px;
    color: #fff;
`;
const Middle = styled.div``;
