import React from "react";
import styled from "styled-components";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function BottomCards({ studentCount, isStudentLoading }) {
    return (
        <MainContainer>
            <Cover>
                <BottomContainer>
                    <Card>
                        <Datas>
                            <TextData>Total</TextData>

                            {isStudentLoading ? (
                                <ButtonLoader />
                            ) : (
                                <AmountData>
                                    {studentCount.total_followups?studentCount.total_followups:"0"}
                                </AmountData>
                            )}
                        </Datas>
                    </Card>
                </BottomContainer>
                <BottomContainer>
                    <Card>
                        <Datas>
                            <TextData>Attended</TextData>
                            {isStudentLoading ? (
                                <ButtonLoader />
                            ) : (
                                <AmountData>
                                    {studentCount.attended_followups?studentCount.attended_followups:"0"}
                                </AmountData>
                            )}
                        </Datas>
                    </Card>
                </BottomContainer>
                <BottomContainer>
                    <Card>
                        <Datas>
                            <TextData>Pending</TextData>

                            {isStudentLoading ? (
                                <ButtonLoader />
                            ) : (
                                <AmountData>
                                    {studentCount.pending_followups?studentCount.pending_followups:"0"}
                                </AmountData>
                            )}
                        </Datas>
                    </Card>
                </BottomContainer>
                <BottomContainer>
                    <Card>
                        <Datas>
                            <TextData>Other</TextData>
                            {isStudentLoading ? (
                                <ButtonLoader />
                            ) : (
                                <AmountData>
                                    {studentCount.other_followups?studentCount.other_followups:"0"}
                                </AmountData>
                            )}
                        </Datas>
                    </Card>
                </BottomContainer>
            </Cover>
        </MainContainer>
    );
}
export default BottomCards;

const Cover = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    min-width: 1350px;
`;
const MainContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const BottomContainer = styled.li`
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallcard.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 10px;
    border: 1px solid #2c2c2c;
    border-radius: 6px;
    padding: 53px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Card = styled.div``;
const Datas = styled.div`
    text-align: center;
`;
const TextData = styled.div`
    font-family: "gordita_regular";
    font-size: 14px;
    margin-bottom: 10px;
`;
const AmountData = styled.div`
    font-family: "gordita_medium";
    font-size: 22px;
`;
