import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../../axiosConfig";
import { Context } from "../../../../contexts/Store";
import ButtonLoader from "../../../../genaral/Loader/ButtonLoader";

export default function MeetStatus({
    cards,
    showStatusModal,
    setShowStatusModal,
    setSuccessful,
    setReload,
}) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    // options

    const options = [
        { value: "upcoming", label: "Upcoming" },
        { value: "started", label: "Started" },
        { value: "postponed", label: "Postponed" },
        { value: "completed", label: "Completed" },
        { value: "cancelled", label: "Cancelled" },
    ];

    const [selectedOption, setSelectedOption] = useState("");
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    // const previousSelectedValue = cards?.status;
    // console.log("cards", cards);
    useEffect(() => {
        let selectedStatus = options.filter(
            (item) => item.value === cards?.status
        );
        if (selectedStatus) {
            selectedStatus = selectedStatus[0];
        } else {
            selectedStatus = {};
        }

        setSelectedOption(selectedStatus);
    }, [showStatusModal]);

    // handle

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // api connection for change status

    const handleFormSubmit = () => {
        const formData = new FormData();
        formData.append("status", selectedOption);
        setButtonLoading(true);
        learnConfig
            .post(`events/change-status/${cards.id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { status_code, data, message } = response.data;
                if (status_code === 6000) {
                    setSuccessful(true);
                    setShowStatusModal(false);
                    setButtonLoading(false);
                } else {
                    setButtonLoading(false);
                    setErrorMessage(message);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    return (
        <>
            {showStatusModal && (
                <Backdrop>
                    <Modal>
                        <Container>
                            <TopContainer>
                                <span>Change status</span>
                                <img
                                    onClick={() => setShowStatusModal(false)}
                                    src={
                                        require("../../../../../assets/images/close-icon.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            </TopContainer>
                            <FormContainer>
                                <Label>Status</Label>
                                <StyledSelect
                                    value={selectedOption.value}
                                    onChange={handleChange}
                                >
                                    {options.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </StyledSelect>
                            </FormContainer>
                            <CreateButtonBox>
                                <CancelButton
                                    onClick={() => setShowStatusModal(false)}
                                >
                                    Cancel
                                </CancelButton>
                                <CreateButton onClick={handleFormSubmit}>
                                    {isButtonLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Update"
                                    )}
                                </CreateButton>
                            </CreateButtonBox>
                        </Container>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
}

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    width: 100%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 1000;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(59, 59, 59, 1);
    span {
        color: white;
    }
    img {
        cursor: pointer;
        width: 40px;
    }
`;

const FormContainer = styled.div`
    margin-top: 18px;
`;

const Label = styled.label`
    color: rgba(123, 123, 123, 1);
`;

const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
`;

const CreateButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
`;

const CancelButton = styled.div`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-right: 20px;
    border: 1px solid #fe673a;
`;
const StyledSelect = styled.select`
    cursor: pointer;
    padding: 12px 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #3b3b3b;
    color: #fff;
    width: 100%;
`;
