import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import $ from "jquery";
import { activityConfig } from "../../../../axiosConfig";
import { Link } from "react-router-dom";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import Calendar from "react-calendar";
import calender from "../../../../assets/images/calendar.svg";

function StatusModal({
    isModal,
    handleModal,
    statusSelect,
    setStatusSelect,
    textarea,
    setTextarea,
    textareaValue,
    setTextareaValue,
    isSelected,
    setIsSelected,
    student,
    activityList,
    selectedStatusID,
    setSelectedStatusID,
    setStatusUpdated,
    setStudentID,
    setReload,
}) {
    const [isSubmit, setSubmit] = useState(false);
    const [isFollowup, setFollowup] = useState(false);
    const [showCalendar, setShowCalender] = useState(false);
    const [startExclude, setStartExclude] = useState(new Date());
    const [endExclude, setEndExclude] = useState(new Date());
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    //--------------------------------------------------------------

    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowCalender(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    //--------------------------------------------------------------

    useEffect(() => {
        if (statusSelect === "") setFollowup(false);
    }, [statusSelect]);

    //--------------------------------------------------------------
    const updateStatus = () => {
        setSubmit(true);
        if (isSelected && textareaValue.length > 0) {
            activityConfig
                .post(
                    `followups/srm/create-followup-status/${student.id}/`,
                    {
                        status_pk: selectedStatusID,
                        description: textareaValue,
                        is_exclude: isFollowup,
                        start_exclude:
                            startExclude.getFullYear() +
                            "-" +
                            (startExclude.getMonth() + 1) +
                            "-" +
                            startExclude.getDate(),
                        end_exclude:
                            endExclude.getFullYear() +
                            "-" +
                            (endExclude.getMonth() + 1) +
                            "-" +
                            endExclude.getDate(),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        handleModal();
                        setSubmit(false);
                        setStatusUpdated(true);
                        setReload((prev) => !prev);
                    } else {
                        setSubmit(false);
                        setReload((prev) => !prev);
                        setStatusUpdated(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setSubmit(false);
                    setStatusUpdated(true);
                });
        } else {
            setStatusUpdated(true);
            setSubmit(false);
        }
    };

    //------------------------------------------------------------------------------

    return (
        <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
            <Overlay
                onClick={() => (
                    handleModal(),
                    setStatusSelect(""),
                    setTextarea(false),
                    setStudentID(""),
                    setFollowup(false)
                )}
            ></Overlay>
            <Modal>
                <Conatiner>
                    <Top>
                        <Head>
                            <MainTitle className="gm">Student Status</MainTitle>
                            <Close>
                                <CloseIcon
                                    onClick={() => (
                                        handleModal(),
                                        setStatusSelect(""),
                                        setSelectedStatusID(""),
                                        setTextarea(false),
                                        setStudentID(""),
                                        setFollowup(false)
                                    )}
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Close Icon"
                                />
                            </Close>
                        </Head>
                    </Top>

                    <Middile>
                        <Profile>
                            <Profilename>
                                <span>{student.student_name.charAt(0)}</span>
                            </Profilename>
                            <ProfileDetails className="gr">
                                <UserName>
                                    {student.student_name.toLowerCase()}
                                </UserName>
                                <PhoneNumber>
                                    {student.student_phone}
                                </PhoneNumber>
                            </ProfileDetails>
                        </Profile>
                        <SubTitle className="gb">Select call status</SubTitle>

                        <StatusList>
                            {activityList.map((data, index) => {
                                return (
                                    <Status
                                        key={index}
                                        onClick={() => (
                                            setStatusSelect(data.title),
                                            setIsSelected(true),
                                            setSelectedStatusID(data.pk)
                                        )}
                                        className={
                                            statusSelect === data.title &&
                                            "active"
                                        }
                                    >
                                        <Check>
                                            <CheckImage
                                                src={
                                                    statusSelect === data.title
                                                        ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/orange-tick.png"
                                                        : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/untick.svg"
                                                }
                                                alt="Check Icon"
                                            />
                                        </Check>
                                        <StatusTitle className="gr">
                                            {data.title === "Busy"
                                                ? "Call later / Busy"
                                                : data.title}
                                        </StatusTitle>
                                    </Status>
                                );
                            })}
                        </StatusList>
                        {statusSelect === "Attended" && (
                            <>
                                <NotFollowup>
                                    <ToggleCover
                                        className={isFollowup ? "active" : ""}
                                    >
                                        <ToggleContainer
                                            onClick={() => {
                                                setFollowup(!isFollowup);
                                                setShowCalender(false);
                                            }}
                                        >
                                            <ToggleBackground
                                                className={
                                                    isFollowup ? "activate" : ""
                                                }
                                            >
                                                <Circle
                                                    className={
                                                        isFollowup
                                                            ? "activate"
                                                            : ""
                                                    }
                                                ></Circle>
                                            </ToggleBackground>
                                        </ToggleContainer>
                                        <Statusdetail className="followup">
                                            Do Not Followup
                                        </Statusdetail>
                                    </ToggleCover>
                                    {isFollowup && showCalendar ? (
                                        <CalendarCover ref={wrapperRef}>
                                            <Calendar
                                                onChange={(data) => {
                                                    setEndExclude(data);
                                                    setShowCalender(false);
                                                }}
                                                value={endExclude}
                                                minDate={startExclude}
                                            />
                                        </CalendarCover>
                                    ) : (
                                        ""
                                    )}
                                    {isFollowup ? (
                                        <DateCover>
                                            <DateStatus
                                                onClick={() =>
                                                    setShowCalender(
                                                        !showCalendar
                                                    )
                                                }
                                            >
                                                <input
                                                    placeholder="Select Date"
                                                    value={
                                                        endExclude.getDate() +
                                                        "-" +
                                                        (endExclude.getMonth() +
                                                            1) +
                                                        "-" +
                                                        endExclude.getFullYear()
                                                    }
                                                    type="text"
                                                />
                                                <CalendarImage>
                                                    <img
                                                        src={calender}
                                                        alt="Calender"
                                                    />
                                                </CalendarImage>
                                            </DateStatus>
                                        </DateCover>
                                    ) : (
                                        ""
                                    )}
                                </NotFollowup>
                            </>
                        )}

                        <Detail textarea={textarea}>
                            <DetailHead>Details about student</DetailHead>

                            <DetailContent
                                placeholder="Type here"
                                value={textareaValue}
                                onChange={(e) =>
                                    setTextareaValue(e.target.value)
                                }
                            ></DetailContent>
                        </Detail>
                    </Middile>

                    <Buttons className="gr">
                        <Cancel
                            onClick={() => (
                                handleModal(),
                                setTextarea(false),
                                setStudentID(""),
                                setFollowup(false)
                            )}
                        >
                            Cancel
                        </Cancel>

                        {isSelected && textareaValue.length > 0 ? (
                            <Submit
                                isSelected={isSelected}
                                onClick={() => (
                                    updateStatus(),
                                    setStatusSelect(""),
                                    setTextarea(false),
                                    setStudentID("")
                                )}
                            >
                                {isSubmit ? <ButtonLoader /> : "Update"}
                            </Submit>
                        ) : (
                            <Submit type="not-allowed">Submit</Submit>
                        )}
                    </Buttons>
                </Conatiner>
            </Modal>
        </BackContainer>
    );
}
export default StatusModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    // transform: scale(0, 0);
    max-width: 500px;
    max-height: 90vh;
    min-height: fit-content;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Conatiner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;

    @media all and (max-width: 640px) {
        padding: 28px;
    }
`;
const Top = styled.div`
    margin-bottom: 20px;
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #111b26;
    align-items: center;
    @media all and (max-width: 480px) {
        padding-bottom: 10px;
    }
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Close = styled.span`
    cursor: pointer;
    width: 18px;
    @media all and (max-width: 640px) {
        width: 15px;
    }
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const Profile = styled.div`
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const Profilename = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #da5b36;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        color: #da5b36;
        font-size: 14px;
        font-family: "gordita_medium";
    }
`;
const Image = styled.span`
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    min-width: 30px;
    @media all and (max-width: 640px) {
        width: 38px;
        height: 38px;
        min-width: 38px;
    }
`;
const ProfileDetails = styled.div`
    margin-left: 10px;
    transform: translateY(5px);
    font-family: "gordita_regular";
    @media all and (max-width: 640px) {
        transform: translateY(2px);
    }
`;
const UserName = styled.h3`
    font-size: 13px;
    color: #fff;
    text-transform: capitalize;
    @media all and (max-width: 640px) {
        font-size: 12px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const PhoneNumber = styled.p`
    font-size: 13px;
    color: #fff;
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const Middile = styled.div`
    overflow-y: scroll;
    max-height: 525px;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 480px) {
        margin-top: 10px;
    }
    @media all and (max-height: 700px) {
        max-height: 330px;
    }
`;
const SubTitle = styled.h3`
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const StatusList = styled.ul``;
const SectionLoaderContainer = styled.div``;
const Status = styled.li`
    padding: 10px;
    display: flex;
    align-items: center;
    background: #161619;
    cursor: pointer;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    margin-bottom: 10px;
    &.active {
        border: 2px solid #da5b36;
    }

    &:last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;

const Check = styled.span`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    overflow: hidden;
    @media all and (max-width: 480px) {
        width: 15px;
        height: 15px;
        overflow: visible;
        display: flex;
        transform: translateY(-1px);
    }
    &.tick {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        @media all and (max-width: 480px) {
            width: 15px;
            height: 15px;
            overflow: visible;
            display: flex;
            transform: translateY(-1px);
        }
    }
`;

const CheckImage = styled.img`
    width: 100%;
    display: inline-block;
`;
const StatusTitle = styled.h3`
    margin-left: 15px;
    font-size: 13px;
    font-family: "gordita_regular";
    color: #fff;
    @media all and (max-width: 480px) {
        margin-left: 10px;
        font-size: 13px;
    }
`;

const Detail = styled.div`
    margin-top: 16px;
    transition: 0.3s;
    // height: ${({ textarea }) => (textarea ? "180px" : "0")};
    overflow: hidden;
    @media all and (max-height: 500px) {
        // height: ${({ textarea }) => (textarea ? "300px" : "180px")};
    }
`;
const DetailHead = styled.h3`
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const DetailContent = styled.textarea`
    width: 100%;
    height: 72px;
    border: 1px solid #6d767c;
    background: #161619;
    border-radius: 5px;
    padding: 15px;
    color: #ffffff;
    opacity: 0.5;
    font-size: 14px;
    outline: none;
    resize: none;
    font-family: "gordita_regular";
`;
const Buttons = styled.div`
    display: flex;
    position: relative;
    color: #fff;
    justify-content: flex-end;
    font-family: "gordita_regular";
    margin-top: 30px;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
`;
const Cancel = styled.span`
    border: 2px solid #da5b36;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-right: 25px;
    background: #161619;
    cursor: pointer;
    @media all and (max-width: 640px) {
        width: 110px;
        height: 35px;
    }
    @media all and (max-width: 360px) {
        width: 90px;
        height: 35px;
    }
`;
const Submit = styled.div`
    background: #da5b36;
    color: #fff;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: ${({ type }) =>
        type === "not-allowed" ? "not-allowed" : "pointer"};
    @media all and (max-width: 640px) {
        width: 110px;
        height: 35px;
    }
    @media all and (max-width: 480px) {
        margin-right: 0;
    }
    @media all and (max-width: 360px) {
        width: 90px;
        height: 35px;
    }
`;
const NotFollowup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;
const Statusdetail = styled.p`
    margin-left: 10px;
    font-size: 13px;
    font-family: "gordita_regular";
    color: #fff;
`;
const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
`;
const ToggleBackground = styled.div`
    position: relative;
    height: 20px;
    width: 40px;
    background-color: #401a17;
    border-radius: 15px;
    &.activate {
        background-color: #fff;
    }
`;
const Circle = styled.span`
    position: absolute;
    left: 4px;
    top: 1.5px;
    height: 16px;
    width: 16px;
    background-color: #fe673a;
    transition: 0.4s;
    border-radius: 50%;
    &.activate {
        left: 20px;
        background-color: #fe673a;
    }
`;
const DateCover = styled.div`
    position: relative;
`;
const CalendarCover = styled.div`
    margin-left: 45px;
    margin-top: 15px;
    margin-bottom: 10px;
    position: absolute;
    bottom: 60%;
`;
const DateStatus = styled.div`
    padding: 10px;
    background: #161619;
    cursor: pointer;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
    margin-top: 15px;
    input {
        color: #ffffff;
        display: inline-block;
    }
`;
const ToggleCover = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 12px;
    &.activate {
        margin-top: 20px;
    }
`;
const CalendarImage = styled.div`
    width: 10%;
    img {
        width: 100%;
        display: block;
    }
`;
