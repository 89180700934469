import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import ParticipantsCard from "./ParticipantsCard";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import SectionLoader from "../../../helpers/SectionLoader";
import NoData from "../../../includes/jobdesk/NoData";
import { useParams, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";

const MeetParticipants = () => {
    const [filter, setFilter] = useState("all");
    const [studentData, setStudentsData] = useState([]);
    const [pagination, setPagination] = useState({});
    const { meet_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const getFilterParams = useCallback(() => {
        const params = {};
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        return params;
    }, [searchParams]);

    useEffect(() => {
        const filterParam = searchParams.get("status");
        if (filterParam) {
            setFilter(filterParam);
        }
    }, [searchParams]);

    const fetchParticipants = useCallback(async () => {
        setLoading(true);
        const params = getFilterParams();
        try {
            const response = await learnConfig.get(
                `events/participants/${meet_id}/`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: { ...params },
                }
            );

            const { status_code, data, pagination_data } = response.data;

            if (status_code === 6000) {
                setStudentsData(data);
                setPagination(pagination_data);
            } else {
                setStudentsData([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [meet_id, access_token, getFilterParams, filter]);

    useEffect(() => {
        fetchParticipants();
    }, [fetchParticipants, filter]);

    const handlePageClick = useCallback(
        ({ selected }) => {
            setSearchParams((prev) => {
                prev.set("page", selected + 1);
                return prev;
            });
        },
        [setSearchParams]
    );

    const handleFilterChange = useCallback(
        (newFilter) => {
            setFilter(newFilter);
            setSearchParams((prev) => {
                prev.set("status", newFilter);
                prev.delete("page");
                return prev;
            });
        },
        [setSearchParams]
    );

    return (
        <>
            <MainContainer>
                <SubContainer>
                    <Container>
                        <FilterOptions>
                            <FilterButton
                                active={filter === "all"}
                                onClick={() => handleFilterChange("all")}
                            ></FilterButton>
                            <FilterText>All</FilterText>
                            <FilterButton
                                active={filter === "registrations"}
                                onClick={() => {
                                    handleFilterChange("registrations");
                                    console.log("click");
                                }}
                            ></FilterButton>
                            <FilterText>Registrations</FilterText>
                            <FilterButton
                                active={filter === "participated"}
                                onClick={() =>
                                    handleFilterChange("participated")
                                }
                            ></FilterButton>
                            <FilterText>Attendees</FilterText>
                        </FilterOptions>
                    </Container>
                    <Category>
                        <Item type="slno">Sl No.</Item>
                        <Item type="students">Student</Item>
                        <Item type="time">Registration Time</Item>
                        <Item type="waiting">Waiting list</Item>
                        <Item type="part">Status</Item>
                        <Item type="joined">Joined Time</Item>
                    </Category>
                    {loading ? (
                        <SectionLoader />
                    ) : studentData?.length > 0 ? (
                        <StudentList>
                            <ParticipantsCard
                                loading={loading}
                                pagination={pagination}
                                studentData={studentData}
                            />
                        </StudentList>
                    ) : (
                        <NoData />
                    )}
                </SubContainer>
            </MainContainer>
            {!loading &&
                pagination?.total_pages > 1 &&
                studentData?.length > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            Showing students {pagination?.first_item} -{" "}
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"< Prev"}
                            nextLabel={"Next >"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            forcePage={pagination?.current_page - 1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
        </>
    );
};

export default MeetParticipants;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;

const MainContainer = styled.section`
    border: 1px solid #26272a;
    border-radius: 6px;
    background: #1b1c1f;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SubContainer = styled.div`
    min-width: 1300px;
`;
const Category = styled.div`
    border-bottom: 1px solid #26272a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
`;

const Item = styled.li`
    font-size: 14px;
    text-align: ${({ type }) => (type === "count" ? "center" : "left")};

    span {
        color: #fe673a;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
    }
    p {
        width: 60%;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "students"
            ? "19%"
            : type === "time"
            ? "15%"
            : type === "waiting"
            ? "10%"
            : type === "part"
            ? "10%"
            : type === "joined"
            ? "10%"
            : "10%"};

    list-style: none;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px 20px 0 20px;
`;

const FilterOptions = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;
    border-bottom: 1px solid #26272a;
    padding-bottom: 24px;
`;

const StudentList = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const StudentItem = styled.li`
    margin-bottom: 10px;
`;

const FilterButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#fe673a" : "transparent")};
    border: 1px solid #fe673a;
    border-radius: 50%;
    color: ${(props) => (props.active ? "white" : "#fe673a")};
    margin: 0 5px;
    margin-right: 12px;
`;
const FilterText = styled.h5`
    margin-right: 32px;
    &:last-child {
        margin-right: 0;
    }
`;
