import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
    activityConfig,
    communityConfig,
    managersConfig,
} from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import SteypHelmet from "../../helpers/SteypHelmet";
import LeftScoreboard from "../examiner/LeftScoreboard";
import RightScoreboard from "../examiner/RightScoreboard";

function AepScoreboard() {
    const [isLoading, setLoading] = useState(true);
    const [isStudentLoading, setStudentLoading] = useState(true);
    const [studentDetail, setStudentDetail] = useState([]);
    const [pagination, setPagination] = useState({});
    const [studentProfile, setStudentProfile] = useState({});
    const { student_sat_id } = useParams();
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const fetchStudentExamDetails = () => {
        communityConfig
            .get(`enquiry-exams/sat-exam-questions/attended/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: { student_sat_id: student_sat_id },
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetail(data);
                    setLoading(false);
                    setPagination(pagination_data);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const fetchStudentProfile = () => {
        managersConfig
            .get("web/aep/applicant-details/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: { student_sat_id: student_sat_id },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentProfile(data);
                    setStudentLoading(false);
                } else {
                    setStudentLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setStudentLoading(false);
            });
    };

    useEffect(() => {
        (student_sat_id && fetchStudentProfile()) || fetchStudentExamDetails();
    }, [student_sat_id]);

    return (
        <>
            <TotalContainer id="main">
                <SteypHelmet title="Scoreboard" />
                {isLoading && isStudentLoading ? (
                    <SectionLoader />
                ) : (
                    <Container>
                        <LeftScoreboard
                            studentDetail={studentDetail}
                            pagination={pagination}
                        />
                        <RightScoreboard
                            studentProfile={studentProfile}
                            studentDetail={studentDetail}
                        />
                    </Container>
                )}
            </TotalContainer>
        </>
    );
}

export default AepScoreboard;

const TotalContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Container = styled.div`
    min-width: 1350px;
    max-height: calc(100vh - 95px);
    min-height: calc(100vh - 95px);
    display: flex;
    justify-content: space-between;
`;
