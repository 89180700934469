import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

function TagsNavBar() {
    const navBars = [
        {
            id: 1,
            title: "All",
            url: "/tags-all/",
        },
    ];

    return (
        <Container>
            {navBars.map((item) => (
                <NavItem key={item.id} to={item.url} activeClassName="active">
                    {item.title}{" "}
                    {item.button && <CountButton>({item.button})</CountButton>}
                </NavItem>
            ))}
        </Container>
    );
}

const Container = styled.nav`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    padding-right: 25px;
    overflow-x: scroll;
    margin-top: 11px;

    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background: #e5e5e5;
        border-radius: 0.625rem;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #b7b7b7;
    }
`;
const CountButton = styled.span`
    position: absolute;
    top: -8px;
    right: -10px;
    background-color: #fe673a;
    color: #fff;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px;
`;

const NavItem = styled(NavLink)`
    cursor: pointer;
    font-family: "gordita_regular";
    border-bottom: 3px solid transparent;
    min-width: fit-content;
    display: inline-block;
    color: #717171;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
    margin-right: 40px;
    &:last-child {
        margin-right: 0;
    }
    &:focus {
        outline: none;
    }
    &.active {
        color: #fe673a;
        border-bottom: 3px solid #fe673a;
    }
    &:not(.active):hover {
        color: #fe673a;
    }

    @media all and (max-width: 1380px) {
        margin-right: 34px;
    }
    @media all and (max-width: 1280px) {
        font-size: 14px;
        margin-right: 25px;
    }
    @media all and (max-width: 1080px) {
        font-size: 13px;
        margin-right: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
        margin-right: 20px;
    }
    @media (max-width: 480px) {
        font-size: 14px;
        margin-right: 30px;
    }
    @media (max-width: 360px) {
        font-size: 13px;
        margin-right: 15px;
    }
`;
export default TagsNavBar;
