import React, { lazy, Suspense } from "react";
import { Route, Redirect, Routes } from "react-router-dom";
import SectionLoader from "../../../helpers/SectionLoader";
// import Login from "../../../authentication/screens/Login";
const Login = lazy(() => import("../../../authentication/screens/Login"));

const AuthRouter = () => {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Routes>
                <Route path="/login" element={<Login />} />
            </Routes>
        </Suspense>
    );
};

export default AuthRouter;
