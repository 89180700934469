import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { auth, db } from "../../../fiebase";
import {
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
    doc,
    getDoc,
    updateDoc,
    getDocs,
    startAfter,
    limit,
} from "firebase/firestore";
import { Context } from "../../contexts/Store";
import UserCard from "../../includes/chat/UserCard";
import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

export default function UsersList({ isStudentSelected, setStudentSelected }) {
    const {
        state: { user_data, isStudentList },
        dispatch,
    } = useContext(Context);

    const [students, setStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [myId, setMyId] = useState("");
    const [isStudentsLoading, setStudentsLoading] = useState(true);
    const [isMoreStudentsLoading, setMoreStudentsLoading] = useState(false);
    const [lastStudent, setLastStudent] = useState(null);
    const [searchedLastStudent, setSearchedLastStudent] = useState(null);
    const location = useLocation();
    const [searchedStudents, setSearchedStudents] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const searchTermRef = useRef(null);

    function debounce(func, delay) {
        let timeoutId;

        return function () {
            const context = this;
            const args = arguments;

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                if (args[0]) {
                    func.apply(context, args);
                }
            }, delay);
        };
    }

    function handleChange(event) {
        setSearchTerm(event.target.value);
        if (event.target.value) {
            fetchStudents(event.target.value);
        } else {
            setSearchedStudents([]);
            setHasMore(true);
        }
    }

    const delayedFunction = debounce(handleChange, 3000);

    useEffect(() => {
        if (auth.currentUser) setMyId(auth.currentUser.uid);
        else setMyId(user_data.uid);
    }, [user_data.uid, auth.currentUser]);

    let studentsUnsbscribe;

    async function fetchStudents(searchTerm) {
        setStudentsLoading(true);
        try {
            const usersRef = collection(db, "users");
            let q;

            if (searchTerm) {
                q = query(
                    usersRef,
                    where("name", ">=", searchTerm),
                    where("name", "<=", searchTerm + "\uf7ff"),
                    orderBy("name"),
                    orderBy("lastMessageTime", "desc"),
                    limit(13)
                );
            } else {
                q = query(
                    usersRef,
                    orderBy("lastMessageTime", "desc"),
                    limit(13)
                );
            }

            const snapshot = await getDocs(q);
            const docSnap = await getDoc(doc(db, "users", myId));

            studentsUnsbscribe = onSnapshot(
                q,
                { includeMetadataChanges: true },
                (querySnapshot) => {
                    let newStudents = [];
                    querySnapshot.forEach((doc) => {
                        const student = doc.data();
                        student.name_lowercase = student?.name?.toLowerCase();
                        newStudents.push(doc.data());

                        if (searchTerm) {
                            setSearchedLastStudent(doc);
                        } else {
                            setLastStudent(doc);
                        }
                    });

                    if (newStudents?.length >= 13) {
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }

                    if (searchTerm) {
                        setSearchedStudents(newStudents);
                    } else {
                        setStudents(newStudents);
                    }
                    setStudentsLoading(false);
                }
            );
        } catch (error) {
            setStudentsLoading(false);
            console.error(error);
        }
    }

    const fetchMoreStudents = async () => {
        setMoreStudentsLoading(true);
        try {
            const usersRef = collection(db, "users");
            let q;

            if (searchTerm) {
                q = query(
                    usersRef,
                    where("name", ">=", searchTerm),
                    where("name", "<=", searchTerm + "\uf7ff"),
                    orderBy("name"),
                    orderBy("lastMessageTime", "desc"),
                    startAfter(searchedLastStudent),
                    limit(10)
                );
            } else {
                q = query(
                    usersRef,
                    orderBy("lastMessageTime", "desc"),
                    startAfter(lastStudent),
                    limit(10)
                );
            }

            const snapshotData = await getDocs(q);

            let newStudents = [];
            snapshotData.forEach((doc) => {
                newStudents.push(doc.data());
            });

            if (searchTerm) {
                setSearchedLastStudent(
                    snapshotData.docs[snapshotData.docs.length - 1]
                );

                setSearchedStudents((prevStudents) => [
                    ...prevStudents,
                    ...newStudents,
                ]);
            } else {
                setLastStudent(snapshotData.docs[snapshotData.docs.length - 1]);
                setStudents((prevStudents) => [
                    ...prevStudents,
                    ...newStudents,
                ]);
            }

            if (newStudents?.length >= 10) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            setMoreStudentsLoading(false);
        } catch (error) {
            console.log(error);
            setMoreStudentsLoading(false);
        }
    };

    useEffect(() => {
        // Don't delete or uncomment this function

        // const addLastMessageTime = async (data) => {
        //     const docRef = doc(db, "users", data.uid);
        //     const docSnap = await getDoc(docRef);

        //     if (docSnap.exists()) {
        //         if (docSnap.data().lastMessageTime) {
        //             console.log(data.uid, "AVAIALABLEEEEEE", docSnap.data());
        //         } else {
        //             console.log(
        //                 data.createdAt,
        //                 "NOT AVAIALABLEEEEEE",
        //                 docSnap.data()
        //             );
        //             updateDoc(doc(db, "users", data.uid), {
        //                 lastMessageTime: data.createdAt,
        //             });
        //         }
        //     }
        // };

        if (myId) {
            fetchStudents();

            return () => {
                studentsUnsbscribe();
            };
        }
    }, [myId]);

    function handleFetchMore(e) {
        fetchMoreStudents();
    }

    const updateLastMessage = async (roomId) => {
        const docSnap = await getDoc(doc(db, "lastMessage", roomId));
        // if last message exists and message is from selected user
        if (docSnap.data() && docSnap.data().from !== myId) {
            // update last message doc, set unread to false
            await updateDoc(doc(db, "lastMessage", roomId), {
                unread: false,
            });
        }
    };

    const snapshot = useRef(null);
    const selectStudent = async () => {
        snapshot.current && snapshot.current();
    };

    useEffect(() => {
        if (location.pathname === "/support/room") {
            setStudentSelected(true);
        }
    }, [location.pathname]);

    const renderStudents = (students) => {
        return (
            <>
                {students.length > 0 ? (
                    students.map((student, index) => (
                        <div key={student.uid}>
                            <UserCard
                                selectStudent={selectStudent}
                                student={student}
                            />
                        </div>
                    ))
                ) : (
                    <NoFound>No students found</NoFound>
                )}
                {hasMore && !isMoreStudentsLoading && (
                    <Load onClick={handleFetchMore}>
                        view more{" "}
                        <img
                            src={
                                require("../../../assets/images/green-arrow.svg")
                                    .default
                            }
                            alt="Arrow"
                        />
                    </Load>
                )}
                {isMoreStudentsLoading && <ButtonLoader />}
            </>
        );
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Steyp | {user_data.name ? user_data.name : "Admin"}
                </title>
            </Helmet>
            <Overlay
                className={
                    isStudentList && window.innerWidth < 769 && "overlay-active"
                }
                onClick={() => {
                    dispatch({
                        type: "UPDATE_SUPPORT_STUDENT_LIST_MENU",
                        isStudentList: !isStudentList,
                    });
                }}
            ></Overlay>
            <MainContainer
                // id="main"
                className={
                    isStudentSelected && !isStudentList
                        ? "student-active"
                        : isStudentList
                        ? "student-menu-active"
                        : ""
                }
            >
                <Left>
                    <Top>
                        <InputContainer>
                            <ImageContainer>
                                <Image
                                    src={
                                        require("../../../assets/images/chat/search.svg")
                                            .default
                                    }
                                    alt="Icon"
                                />
                            </ImageContainer>
                            <Input
                                placeholder="Search.."
                                ref={searchTermRef}
                                onChange={delayedFunction}
                            />
                            {searchTermRef?.current?.value !== "" && (
                                <CloseButton
                                    onClick={() => {
                                        searchTermRef.current.value = "";
                                        setSearchTerm("");
                                        setSearchedStudents([]);
                                        setHasMore(true);
                                    }}
                                >
                                    <img
                                        src={
                                            require("../../../assets/images/subscription_manager/Close.svg")
                                                .default
                                        }
                                        alt="Close"
                                    />
                                </CloseButton>
                            )}
                        </InputContainer>
                    </Top>

                    <Div id="users">
                        <UnassignedConatainer>
                            {isStudentsLoading ? (
                                <ButtonLoader />
                            ) : (
                                <>
                                    {searchTerm !== ""
                                        ? renderStudents(searchedStudents)
                                        : renderStudents(students)}
                                </>
                            )}
                        </UnassignedConatainer>
                    </Div>
                </Left>
            </MainContainer>
            <Outlet />
        </>
    );
}
const Load = styled.div`
    color: #1eac60;
    font-family: "gordita_medium";
    font-size: 12px;
    width: 100%;
    padding: 5px 15px 0;
    text-align: right;
    cursor: pointer;

    & img {
        width: 11px;
        margin: 0 0 -1px 5px;
    }
`;
const Div = styled.div`
    height: calc(100vh - 153px);
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 768px) {
        height: 100vh;
    }
`;

const MainContainer = styled.div`
    background-color: #161619;
    display: flex;
    justify-content: space-between;
    width: 25%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 1280px) {
        min-width: 270px;
    }

    @media all and (max-width: 768px) {
        &.student-active {
            display: none;
        }
    }

    @media all and (max-width: 768px) {
        position: absolute;
        top: 0;
        left: -500px;
        transition: 0.5s all ease;
        z-index: 1001;
        width: 300px;

        &.student-menu-active {
            display: block;
            left: 0;
        }
    }
`;
const Left = styled.div`
    background: #1b1c1f;
    min-width: 100%;
    @media all and (max-width: 768px) {
        max-width: 300px;
        min-width: 300px;
    }
`;
const Top = styled.div`
    background: #1b1c1f;
    padding: 9px 7px;
    border-radius: 6px 6px 0px 0px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        height: 8px;
        background: #161619;
        left: 0;
        right: 0;
        bottom: -8px;
        z-index: 2;
    }

    @media all and (max-width: 768px) {
        padding: 15px 9px 9px 15px;
    }
`;
const InputContainer = styled.div`
    padding: 12px 9px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    justify-content: flex-start;
    background: #161619;
    border-radius: 6px;
`;
const ImageContainer = styled.div`
    width: 7%;
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;

const Input = styled.input`
    width: 100%;
    color: #fff;
    font-size: 0.7rem;
    margin-top: 5px;
`;

const UnassignedConatainer = styled.div`
    background: #1b1c1f;
    padding: 15px 0px 0px;
    margin-top: 8px;
    /* position: relative; */
    @media all and (max-width: 360px) {
        max-width: 350px;
    }
`;
const NoFound = styled.p`
    padding: 30px 14px;
`;
const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;

    &.overlay-active {
        display: block;
    }
`;
const CloseButton = styled.div`
    background-color: transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & img {
        width: 100%;
        display: block;
    }
`;
