import React, { useContext, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Context } from "../../../contexts/Store";
import StudentRouter from "../../routers/students/StudentRouter";

const AdmissionManagerRoute = ({ children }) => {
	const { state } = useContext(Context);
	const role = state.user_data.role[0];

	const [searchParams] = useSearchParams();
	const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";

	console.log(children);

	return role === "admission_manager" ? children : <h1>404</h1>;
};

export default AdmissionManagerRoute;
