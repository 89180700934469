import React from 'react'
import styled from "styled-components";
import SecondRoundParticipantsCategory from './SecondRoundParticipantsCategory';
import SecondRoundStudentList from './SecondRoundStudentList';

function TotalRoundCategory() {
  return (
    <Container id="main">
      <SecondRoundParticipantsCategory />
      <SecondRoundStudentList />
    </Container>
  )
}
const Container = styled.div`
    
`;

export default TotalRoundCategory