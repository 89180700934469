import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";


const ChiefReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
           
      const user_data = { ...state.user_data, ...action.user_data };
      const encryptedUserData = CryptoJS.AES.encrypt(
        JSON.stringify(user_data),
        "#q8a2!u*&4"
      ).toString();

      // Set the encrypted data in the cookie
      Cookies.set("user_data", encryptedUserData, {
        expires:30,
      });

      return {
        ...state,
        user_data: user_data,
      };
        case "UPDATE_STUDENT_DETAILS":
            const student_data = {
                ...state.student_data,
                ...action.student_data,
            };
            localStorage.setItem("student_data", JSON.stringify(student_data));
            return {
                ...state,
                student_data: student_data,
            };
        case "UPDATE_USER_ID":
            return {
                ...state,
                user_id: action.user_id,
            };
        case "UPDATE_SUPPORT_SIDEBAR":
            return {
                ...state,
                support_sidebar: action.support_sidebar,
            };
        case "UPDATE_STUDENTS":
            return {
                ...state,
                students: action.students,
            };
        case "UPDATE_SEARCH_STUDENTS":
            return {
                ...state,
                search_results: action.search_results,
            };
        case "UPDATE_FILTER":
            return {
                ...state,
                isFilter: !state.isFilter,
            };
        case "UPDATE_SIDEBAR":
            return {
                ...state,
                isSideBar: action.isSideBar,
            };
        case "UPDATE_SEARCH_PARAMS":
            return {
                ...state,
                currentParams: action.payload,
            };
        case "UPDATE_CHAT_USER":
            return {
                ...state,
                chat_user: action.payload,
            };
        case "UPDATE_SUPPORT_STUDENT_LIST_MENU":
            return {
                ...state,
                isStudentList: action.isStudentList,
            };
        case "UPDATE_SUPPORT_EDIT_PROFILE":
            return {
                ...state,
                isEditProfile: action.isEditProfile,
            };
        case "UPDATE_EDITOR_VIEW":
            localStorage.setItem("isView", JSON.stringify(action.isView));
            return {
                ...state,
                isView: action.isView,
            };
        case "UPDATE_TECH_NOTIFICATION":
            const notification_modal_data = {
                ...state.notification_modal_data,
                ...action.notification_modal_data,
            };
            return {
                ...state,
                notification_modal_data: notification_modal_data,
            };

        default:
            return state;
    }
};

export default ChiefReducer;
