import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import TrainerHeader from "../../../includes/meet/TrainerHeader";
// import TrainersList from "./TrainersList";
import Vector from "../../../../assets/images/Vector.png";
import MeetHeader from "../../../includes/meet/MeetHeader";
import MeetDetails from "./MeetDetails";
import { Helmet } from "react-helmet";
import SteypHelmet from "../../../helpers/SteypHelmet";
import { Context } from "../../../contexts/Store";
import { learnConfig } from "../../../../axiosConfig";

export default function MeetMain() {
    const [searchInput, setSearchInput] = useState("");
    // api connection for fetch meet list

    const [userData, setUserData] = useState([]);
    const [isReload, setReload] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);

    // search params

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    // access token

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await learnConfig.get(
                `events/events-list/?q=${searchInput}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        page: currentPage,
                    },
                }
            );
            const { status_code, data, pagination_data } = response.data;

            if (status_code === 6000) {
                setUserData(data);
                setLoading(false);
                setPagination(pagination_data);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
        setReload(false);
    }, [searchInput, currentPage, isReload]);

    const handleCardButtonClick = (card) => {
        setSelectedCard(card);
    };

    const closePopup = () => {
        setSelectedCard(null);
    };

    return (
        <>
            <SteypHelmet title="Meet" />
            <MeetHeader
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                isReload={isReload}
                setReload={setReload}
                setSuccessful={setSuccessful}
                successful={successful}
            />
            <MeetDetails
                loading={loading}
                searchInput={searchInput}
                userData={userData}
                pagination={pagination}
                isReload={isReload}
                setReload={setReload}
                handlePageClick={handlePageClick}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setSearchInput={setSearchInput}
                setSearchParams={setSearchParams}
                currentParams={currentParams}
                handleCardButtonClick={handleCardButtonClick}
                closePopup={closePopup}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
            />
        </>
    );
}
