import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import RemoveAnswerModal from "./RemoveAnswerModal";
import $ from "jquery";
import RemoveAnswer from "./RemoveAnswer";

function ShareDeleteModal({ isShare, setShare, isModal, setModal }) {
    const [selectedData, setSelectData] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    console.log(isModal, "ismodal ---------out--------");

    const wrapperRef = useRef(null);

    const handleModal = () => {
        setModal(!isModal);
        console.log(isModal, "ismodal -----------------");
    };

    // useEffect(() => {
    //     if (isShare) {
    //         document.body.style.overflow = "hidden";
    //     } else {
    //         document.body.style.overflow = "auto";
    //     }

    //     return () => {
    //         document.body.style.overflow = "auto";
    //     };
    // }, [isShare]);
    useEffect(() => {
        if (isModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isModal]);
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    return (
        <>
            {isModal && <RemoveAnswer />}

            <ReworkBox
                style={{
                    display: isShare ? "block" : "none",
                }}
            >
                {/* <ReworkBoxDiv>
                    <ReworkText>Share</ReworkText>
                    <ReworkIcon>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-02-2024/share.svg"
                            alt="share"
                        />
                    </ReworkIcon>
                </ReworkBoxDiv> */}
                <ReworkBoxDiv>
                    <ReworkText>Remove</ReworkText>
                    <ReworkIcon>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2024/remove-icon.svg"
                            alt="Remove"
                            onClick={() => {
                                handleModal();
                                setModal(true);
                            }}
                        />
                    </ReworkIcon>
                </ReworkBoxDiv>
            </ReworkBox>
        </>
    );
}

export default ShareDeleteModal;

const ReworkBox = styled.div`
    border-radius: 8px;
    border: 3px solid #3b3b3b;
    background: #1b1c1f;
    width: 246px;
    position: absolute;
    right: 11px;
    top: 35px;
    /* z-index: 100; */
`;
const ReworkBoxDiv = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50%;
    padding: 6px 8px 6px 32px;
    gap: 8px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 6px;
    background: #18191c;
    &:hover {
        background-color: #161619;
    }
`;
const ReworkText = styled.p`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;
const ReworkIcon = styled.div`
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
