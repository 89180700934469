import React, { useState, useContext } from "react";
import styled from "styled-components";
import CreateMeetModal from "../../screens/meet/trainer/modals/CreateMeetModal";
import { Context } from "../../contexts/Store";
import { learnConfig } from "../../../axiosConfig";
import MeetSuccessModal from "../../screens/meet/trainer/modals/MeetSuccess";

export default function MeetHeader({
    setSearchInput,
    searchInput,
    isReload,
    setReload,
    setSuccessful,
    successful,
}) {
    const [showModal, setShowModal] = useState(false);
    const [localInput, setLocalInput] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    // api for fetch select preferred trainer

    const fetchData = () => {
        learnConfig
            .get("events/hosts-list/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { status_code, data } = response.data;
                if (status_code === 6000) {
                    setLocalInput(data);
                } else {
                    setErrorMessage(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <MeetSuccessModal
                content={"Meet"}
                successful={successful}
                setSuccessful={setSuccessful}
                setReload={setReload}
            />
            <CreateMeetModal
                setShowModal={setShowModal}
                showModal={showModal}
                localInput={localInput}
                isReload={isReload}
                setReload={setReload}
                setSuccessful={setSuccessful}
            />
            <MainContainer>
                <LeftContainer>Meets</LeftContainer>
                <HeadRight>
                    <SearchContainer>
                        <SearchImageContainer>
                            <SearchImg
                                src={
                                    require("../../../assets/images/search.svg")
                                        .default
                                }
                                alt="Search-icon"
                            />
                        </SearchImageContainer>
                        <SearchBar>
                            <InputField
                                placeholder="Search Here"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </SearchBar>
                    </SearchContainer>

                    <CreateButton
                        onClick={() => {
                            setShowModal(true);
                            fetchData();
                        }}
                    >
                        <Plusicon>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/plus.svg"
                                alt="Icon"
                            />
                        </Plusicon>
                        <CreateTitle>Create Meet</CreateTitle>
                    </CreateButton>
                </HeadRight>
            </MainContainer>
        </>
    );
}

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
`;

const LeftContainer = styled.div`
    font-size: 18px;
    font-family: "gordita_medium";
`;

const HeadRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    width: 50%;
`;
const Plusicon = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const CreateTitle = styled.div`
    font-size: 14px;
    color: #fff;
    margin-left: 5px;
`;
const CreateButton = styled.div`
    display: flex;
    align-items: center;
    background-color: #fe673a;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
`;

const SearchContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid #2c2c2c;
    border-radius: 8px;
    background-color: #1b1c1f;
    width: 60%;
`;

const SearchBar = styled.div`
    padding-left: 40px;
    margin-left: 10px;
    border-radius: 5px;
    width: 100%;
    margin-right: 10px;
`;

const SearchImageContainer = styled.div`
    position: absolute;
    left: 4%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    cursor: pointer;
    width: 20px;
`;

const SearchImg = styled.img`
    width: 100%;
    display: block;
`;

const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    width: 100%;
    min-height: 40px;
`;
