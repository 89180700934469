import React, { useEffect, useRef } from "react";

//pacakges
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

function RegistrationModal({ isIdModal, setIdModal }) {
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIdModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const handleClick = () => {
        setIdModal(false);
    };
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const images = [
        {
            original: require("../../../../assets/images/community/driving.jpg"),
            thumbnail: require("../../../../assets/images/community/driving.jpg"),
        },
        {
            original: require("../../../../assets/images/community/driving.jpg"),
            thumbnail: require("../../../../assets/images/community/driving.jpg"),
        },
    ];
    return (
        <BackContainer>
            {isIdModal ? (
                <Overlay
                    onClick={() => isIdModal(false)}
                    style={{ display: isIdModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isIdModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Closeicon onClick={handleClick}>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                alt="Icon"
                            />
                        </Closeicon>
                        <ImageGallery items={images} />
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default RegistrationModal;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 640px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;

    .image-gallery-icon.image-gallery-left-nav {
        left: -15px !important;
    }
    .image-gallery-icon.image-gallery-right-nav {
        right: -15px !important;
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        width: 20px;
    }
    .image-gallery-play-button {
        display: none;
    }
    .image-gallery-fullscreen-button {
        display: none;
    }
    @media all and (max-width: 768px) {
        padding: 30px;
        .image-gallery-content .image-gallery-slide .image-gallery-image {
            max-height: calc(100vh - 0px);
        }
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
// const Top = styled.div`
//     border-bottom: 2px solid #26272a;
//     margin-bottom: 15px;
// `;
const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Headtitle = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 10px;
    img {
        width: 100%;
        display: block;
        filter: invert(1);
    }
`;
const Bottum = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`;
const Box = styled.div`
    border: 1px solid #26272a;
    border-radius: 5px;
    background: #161619;
    /* height: 30vh;
    padding: 5px; */
    padding: 5px;
    img {
        width: 100%;
        display: block;
    }
`;
