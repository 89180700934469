import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import activeTick from "../../../assets/images/tick.svg";
import DatePicker from "react-date-picker";
import moment from "moment/moment";

export default function AepNewFilter({
    activeClick,
    isDropdown,
    setDropdown,
    setActiveClick,
    searchWord,
    setSearchWord,
    wrapperRef,
    accountStatus,
    setAccountStatus,
    paymentStatus,
    setPaymentStatus,
    subscriptionDay,
    setSubscriptionDay,
    eligibility,
    setEligibility,
    dateType,
    setDateType,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
}) {
    const clearAll = () => {
        setSearchWord("");
        setAccountStatus("");
        setPaymentStatus("");
        setSubscriptionDay("");
        setEligibility("");
        setDateType("");
        setFromDate("");
        setToDate("");
        setToLink("?");
    };
    const today = new window.Date();
    const [toLink, setToLink] = useState("?");
    const [dateAlert, setDateAlert] = useState(false);

    const clearfilterparams = () => {
        if (accountStatus == "" && toLink.includes("account_status=created")) {
            let new_link = toLink.replace("account_status=created&", "");
            setToLink(new_link);
        }
        if (accountStatus == "" && toLink.includes("account_status=pending")) {
            let new_link = toLink.replace("account_status=pending&", "");
            setToLink(new_link);
        }
        //  -----------------------------------
        if (paymentStatus == "" && toLink.includes("payment_status=done")) {
            let new_link = toLink.replace("payment_status=done&", "");
            setToLink(new_link);
        }
        if (paymentStatus == "" && toLink.includes("payment_status=pending")) {
            let new_link = toLink.replace("payment_status=pending&", "");
            setToLink(new_link);
        }
        //  -----------------------------------
        if (eligibility == "" && toLink.includes("eligible_status=approved")) {
            let new_link = toLink.replace("eligible_status=approved&", "");
            setToLink(new_link);
        }
        if (eligibility == "" && toLink.includes("eligible_status=rejected")) {
            let new_link = toLink.replace("eligible_status=rejected&", "");
            setToLink(new_link);
        }
        // ----------------------------
        if (dateType == "" && toLink.includes("date_type=subscription")) {
            let new_link = toLink.replace("date_type=subscription&", "");
            setToLink(new_link);
        }
        if (dateType == "" && toLink.includes("date_type=applied")) {
            let new_link = toLink.replace("date_type=applied&", "");
            setToLink(new_link);
        }
    };
    const clearsubscription = () => {
        let subs = ["today", "yesterday", "this_week", "this_month"];
        for (let outer in subs) {
            for (let inner in subs) {
                if (subs[outer] !== subs[inner]) {
                    if (
                        subscriptionDay == subs[outer] &&
                        toLink.includes(`activated_date=${subs[inner]}`)
                    ) {
                        let new_link = toLink.replace(
                            `activated_date=${subs[inner]}`,
                            `activated_date=${subs[outer]}`
                        );

                        setToLink(new_link);
                    }
                }
            }
        }
        if (subscriptionDay == "") {
            for (let x in subs) {
                if (toLink.includes(`activated_date=${subs[x]}`)) {
                    let new_link = toLink.replace(
                        `activated_date=${subs[x]}&`,
                        ""
                    );
                    setToLink(new_link);
                }
            }
        }
    };
    const changingfilterparams = () => {
        //---------- ( account Status ) -------------
        if (
            accountStatus == "created" &&
            toLink.includes("account_status=pending")
        ) {
            let new_link = toLink.replace(
                "account_status=pending",
                "account_status=created"
            );
            setToLink(new_link);
        }
        if (
            accountStatus == "pending" &&
            toLink.includes("account_status=created")
        ) {
            let new_link = toLink.replace(
                "account_status=created",
                "account_status=pending"
            );
            setToLink(new_link);
        }

        // ---------- ( payment Status ) -----------
        if (
            paymentStatus == "done" &&
            toLink.includes("payment_status=pending")
        ) {
            let new_link = toLink.replace(
                "payment_status=pending",
                "payment_status=done"
            );
            setToLink(new_link);
        }
        if (
            paymentStatus == "pending" &&
            toLink.includes("payment_status=done")
        ) {
            let new_link = toLink.replace(
                "payment_status=done",
                "payment_status=pending"
            );
            setToLink(new_link);
        }
        //---------- ( eligibility status ) ----------
        if (
            eligibility == "approved" &&
            toLink.includes("eligible_status=rejected")
        ) {
            let new_link = toLink.replace(
                "eligible_status=rejected",
                "eligible_status=approved"
            );
            setToLink(new_link);
        }
        if (
            eligibility == "rejected" &&
            toLink.includes("eligible_status=approved")
        ) {
            let new_link = toLink.replace(
                "eligible_status=approved",
                "eligible_status=rejected"
            );
            setToLink(new_link);
        }
        //---------- ( Date Type ) ----------
        if (
            dateType == "subscription" &&
            toLink.includes("date_type=applied")
        ) {
            let new_link = toLink.replace(
                "date_type=applied",
                "date_type=subscription"
            );
            setToLink(new_link);
        }
        if (
            dateType == "applied" &&
            toLink.includes("date_type=subscription")
        ) {
            let new_link = toLink.replace(
                "date_type=subscription",
                "date_type=applied"
            );
            setToLink(new_link);
        }
    };

    const toSelector = () => {
        clearfilterparams();
        clearsubscription();
        changingfilterparams();

        if (accountStatus !== "" && !toLink.includes("account_status")) {
            setToLink(toLink + `account_status=${accountStatus}&`);
        }
        // ---------------------
        if (paymentStatus !== "" && !toLink.includes("payment_status")) {
            setToLink(toLink + `payment_status=${paymentStatus}&`);
        }

        if (subscriptionDay && !toLink.includes("activated_date")) {
            setToLink(toLink + `activated_date=${subscriptionDay}&`);
        }
        if (eligibility && !toLink.includes("eligible_status")) {
            setToLink(toLink + `eligible_status=${eligibility}&`);
        }

        if (fromDate && !toLink.includes("start_date")) {
            setToLink(
                toLink + `start_date=${moment(fromDate).format("YYYY-MM-DD")}&`
            );
        }
        if (toDate && !toLink.includes("end_date")) {
            setToLink(
                toLink + `end_date=${moment(toDate).format("YYYY-MM-DD")}&`
            );
        }
        if (dateType && !toLink.includes("date_type")) {
            setToLink(toLink + `date_type=${dateType}&`);
        }
    };

    useEffect(() => {
        toSelector();
    }, [
        accountStatus,
        paymentStatus,
        subscriptionDay,
        eligibility,
        fromDate,
        toDate,
        dateType,
    ]);
    useEffect(() => {
        if (
            (dateType !== "" && (fromDate == "" || toDate == "")) ||
            (dateType == "" && (fromDate !== "" || toDate !== ""))
        ) {
            setDateAlert(true);
        } else {
            setDateAlert(false);
        }
    }, [fromDate, toDate, dateType]);

    return (
        <TopFilter>
            <CoverSet ref={wrapperRef}>
                <Filter onClick={() => setDropdown(!isDropdown)}>
                    <FilterText>{activeClick}</FilterText>
                    <FIlterImage>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                            alt="Image"
                        />
                    </FIlterImage>
                </Filter>
                <Popup className={isDropdown ? "dropdown" : ""}>
                    <FilterDiv>
                        <ArrowDiv onClick={() => setDropdown(!isDropdown)}>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/smallarrow.svg"
                                alt="Arrow"
                            />
                        </ArrowDiv>
                        Filter
                    </FilterDiv>
                    <HrLine className="Exam Status"></HrLine>

                    <Date className="search">
                        <input
                            type="text"
                            placeholder="Search Here"
                            onChange={(e) => setSearchWord(e.target.value)}
                            value={searchWord}
                        ></input>
                        <ImageContainer
                            to={`?q=${searchWord}`}
                            onClick={() => {
                                setDropdown(false);
                            }}
                        >
                            <img
                                src={
                                    require("../../../assets/images/sat-examiner/search.svg")
                                        .default
                                }
                                alt="Search"
                            />
                        </ImageContainer>
                    </Date>

                    <ContainerDiv>
                        <Small>Account</Small>
                        <AccountFilter>
                            <Account
                                className={
                                    accountStatus === "created" && "active"
                                }
                                onClick={() => {
                                    accountStatus === "created"
                                        ? setAccountStatus("")
                                        : setAccountStatus("created");
                                }}
                            >
                                Created
                                <ActiveArrow>
                                    <img src={activeTick} alt="Arrow" />
                                </ActiveArrow>
                            </Account>
                            <Account
                                className={
                                    accountStatus === "pending" && "active"
                                }
                                onClick={() => {
                                    accountStatus === "pending"
                                        ? setAccountStatus("")
                                        : setAccountStatus("pending");
                                }}
                            >
                                Pending
                                <ActiveArrow>
                                    <img src={activeTick} alt="Arrow" />
                                </ActiveArrow>
                            </Account>
                        </AccountFilter>
                    </ContainerDiv>
                    <HrLine className="Exam Status"></HrLine>

                    <ContainerDiv>
                        <Small>Payment</Small>
                        <AccountFilter>
                            <Account
                                className={paymentStatus === "done" && "active"}
                                onClick={() => {
                                    paymentStatus === "done"
                                        ? setPaymentStatus("")
                                        : setPaymentStatus("done");
                                }}
                            >
                                Done
                                <ActiveArrow>
                                    <img src={activeTick} alt="Arrow" />
                                </ActiveArrow>
                            </Account>
                            <Account
                                className={
                                    paymentStatus === "pending" && "active"
                                }
                                onClick={() => {
                                    paymentStatus === "pending"
                                        ? setPaymentStatus("")
                                        : setPaymentStatus("pending");
                                }}
                            >
                                Pending
                                <ActiveArrow>
                                    <img src={activeTick} alt="Arrow" />
                                </ActiveArrow>
                            </Account>
                        </AccountFilter>
                    </ContainerDiv>
                    <HrLine className="Exam Status"></HrLine>

                    <ContainerDiv>
                        <Small>Subscription</Small>
                        <SelectOption
                            onClick={() =>
                                setSubscriptionDay(
                                    subscriptionDay == "today" ? "" : "today"
                                )
                            }
                        >
                            <Span>
                                {subscriptionDay === "today" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Today</Text>
                        </SelectOption>
                        <SelectOption
                            onClick={() =>
                                setSubscriptionDay(
                                    subscriptionDay == "yesterday"
                                        ? ""
                                        : "yesterday"
                                )
                            }
                        >
                            <Span>
                                {subscriptionDay === "yesterday" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Yesterday</Text>
                        </SelectOption>

                        <SelectOption
                            onClick={() =>
                                setSubscriptionDay(
                                    subscriptionDay == "this_week"
                                        ? ""
                                        : "this_week"
                                )
                            }
                        >
                            <Span>
                                {subscriptionDay === "this_week" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>This week</Text>
                        </SelectOption>

                        <SelectOption
                            onClick={() =>
                                setSubscriptionDay(
                                    subscriptionDay == "this_month"
                                        ? ""
                                        : "this_month"
                                )
                            }
                        >
                            <Span>
                                {subscriptionDay === "this_month" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>This month</Text>
                        </SelectOption>
                    </ContainerDiv>
                    <HrLine className="Exam Status"></HrLine>

                    {/* <ContainerDiv>
                        <Small>Eligibility</Small>
                        <SelectOption
                            onClick={() =>
                                setEligibility(
                                    eligibility == "approved" ? "" : "approved"
                                )
                            }
                        >
                            <Span>
                                {eligibility === "approved" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Eligible</Text>
                        </SelectOption>
                        <SelectOption
                            onClick={() =>
                                setEligibility(
                                    eligibility == "rejected" ? "" : "rejected"
                                )
                            }
                        >
                            <Span>
                                {eligibility === "rejected" ? (
                                    <SelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                            alt="image"
                                        />
                                    </SelectedImg>
                                ) : (
                                    <UnSelectedImg>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                            alt="image"
                                        />
                                    </UnSelectedImg>
                                )}
                            </Span>
                            <Text>Not eligible</Text>
                        </SelectOption>
                    </ContainerDiv>
                    <HrLine className="Exam Status"></HrLine> */}

                    <ContainerDiv>
                        <Small>Pick Date</Small>
                        <AccountFilter>
                            <DatePickDiv>
                                <DatePicker
                                    format={"dd-MM-yy"}
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YY"
                                    onChange={(value) => setFromDate(value)}
                                    value={fromDate}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    maxDate={today}
                                />
                            </DatePickDiv>
                            to
                            <DatePickDiv>
                                <DatePicker
                                    format={"dd-MM-yy"}
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YY"
                                    onChange={(value) => setToDate(value)}
                                    value={toDate}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    maxDate={today}
                                />
                            </DatePickDiv>
                        </AccountFilter>
                    </ContainerDiv>

                    <ContainerDiv>
                        <Small>Data Type</Small>
                        <TypeContainer>
                            <SelectOption
                                onClick={() =>
                                    setDateType(
                                        dateType == "subscription"
                                            ? ""
                                            : "subscription"
                                    )
                                }
                            >
                                <Span>
                                    {dateType === "subscription" ? (
                                        <SelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                alt="image"
                                            />
                                        </SelectedImg>
                                    ) : (
                                        <UnSelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                alt="image"
                                            />
                                        </UnSelectedImg>
                                    )}
                                </Span>
                                <Text>Payment on</Text>
                            </SelectOption>
                            <SelectOption
                                onClick={() =>
                                    setDateType(
                                        dateType == "applied" ? "" : "applied"
                                    )
                                }
                            >
                                <Span>
                                    {dateType === "applied" ? (
                                        <SelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                alt="image"
                                            />
                                        </SelectedImg>
                                    ) : (
                                        <UnSelectedImg>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                alt="image"
                                            />
                                        </UnSelectedImg>
                                    )}
                                </Span>
                                <Text>Applied on</Text>
                            </SelectOption>
                        </TypeContainer>
                    </ContainerDiv>
                    <ButtonDiv>
                        <ClearDiv
                            onClick={() => {
                                clearAll();
                            }}
                            to=""
                        >
                            Clear
                        </ClearDiv>
                        <ApplyDiv
                            className={dateAlert && "disabled"}
                            onClick={(e) => {
                                if (dateAlert) {
                                    e.preventDefault();
                                } else {
                                    setDropdown(false);
                                }
                            }}
                            to={toLink.slice(0, -1)}
                        >
                            Apply
                        </ApplyDiv>
                    </ButtonDiv>
                </Popup>
            </CoverSet>
        </TopFilter>
    );
}

const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;
const Filter = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 6px 12px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
`;
const FilterText = styled.div`
    margin-right: 10px;
    font-family: gordita_medium;
    font-size: 14px;
`;
const FIlterImage = styled.div`
    & img {
        width: 12px;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    padding: 20px;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 384px;
    top: 0;
    right: -500px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: auto;
        transition: 0.5s ease;
        min-height: 100%;
        right: 0;
        overflow-y: scroll;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const Date = styled.div`
    text-align: center;
    background: #161618;
    display: flex;
    border: 1px solid #26272a;
    border-radius: 4px;
    align-items: center;
    color: #fff;
    cursor: pointer;
    padding: 0 10px;
    margin-bottom: 10px;

    .react-datepicker__day:hover {
        border: 1px solid #fff !important;
        color: #fff !important;
        background-color: red !important;
    }
    input {
        color: #fff;
        width: 90%;
        height: 40px;
        padding: 10px;
    }
`;
const AccountFilter = styled.div`
    display: flex;
    align-items: center;
`;
const Account = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 105px;
    height: 51px;
    background: #161618;
    border-radius: 6px;
    margin-right: 15px;
    position: relative;
    border: 1px solid #272729;
    font-size: 14px;
    cursor: pointer;

    &:last-child {
        margin: 0;
    }

    @media all and (max-width: 360px) {
        height: 40px;
    }

    &.active {
        border: 1px solid #fe673a;
    }

    & div {
        display: none;
    }

    &.active div {
        display: block;
    }
`;
const TypeContainer = styled.div`
    display: flex;
    align-items: center;
`;
const DatePickDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    background: #161618;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #272729;
    font-size: 14px;
    width: 146px;
    height: 50px;
    cursor: pointer;
    z-index: unset !important;
    @media all and (max-width: 480px) {
        padding: 13px 5px;
    }
    /* position: relative; */

    & .react-date-picker__calendar {
        inset: inherit !important;
        top: -175px !important;
        left: -38px !important;
        z-index: 201;
        width: 375px !important;
    }

    &:last-child .react-date-picker__calendar {
        left: -218px !important;
    }

    /* & .react-date-picker {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
    } */

    /* & input {
        &:nth-child(2) {
            width: 100% !important;
            position: absolute !important;
            background-color: transparent !important;
            top: 0 !important;
            right: -26px;
        }
    } */

    &:last-child {
        margin: 0 0 0 10px;
    }

    &.active {
        border: 1px solid #fe673a;
    }
`;
const ActiveArrow = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    top: -7px;
    right: -8px;
    @media all and (max-width: 480px) {
        width: 15px;
        height: 15px;
    }

    img {
        width: 100%;
        display: block;
    }
`;
const FilterDiv = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const ArrowDiv = styled.div`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 20px;

    transform: rotate(90deg);

    img {
        width: 100%;
        display: block;
    }
`;
const ContainerDiv = styled.div``;
const ImageContainer = styled(Link)`
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

// const SelectOption = styled(Link)`
const SelectOption = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    /* position: relative; */
    align-items: center;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const Span = styled.div``;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Text = styled.div`
    font-size: 14px;
`;
const Small = styled.span`
    color: #838488;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 15px;
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    margin: 10px 0;
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
`;
const ClearDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 154px;
    height: 40px;
    background: #1b1c20;
    border-radius: 6px;
    border: 1px solid #fe673a;
    margin-right: 15px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
`;
const ApplyDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 11px;
    width: 154px;
    height: 40px;
    background: #fe673a;
    border-radius: 6px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
    &.disabled {
        cursor: not-allowed;
    }
`;
