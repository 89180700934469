import React, { useEffect, useState, useRef, useContext } from "react";
//Packages
import styled from "styled-components";
import Jdenticon from "react-jdenticon";
import { managersConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
//components
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function InterviewStatusModal({
    isFormModal,
    setFormModal,
    data,
    isUpdated,
    setUpdated,
}) {
    const [isSelected, setSelected] = useState("");
    const [status, setStatus] = useState("");
    const [isButtonLoading, setButtonLoading] = useState(false);

    const handleClick = () => {
        setFormModal(false);
    };
    //modal Outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFormModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const interviewStatusUpdate = () => {
        const formData = new FormData();
        formData.append("student_status", status);
        setButtonLoading(true);

        managersConfig
            .post(`web/job-desk/update-student-status/${data.id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;

                setFormModal(false);
                setUpdated(!isUpdated);
                if (StatusCode === 6000) {
                    setButtonLoading(false);
                    // console.log("data", data);
                } else {
                    setButtonLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
                setFormModal(false);
            });
    };
    return (
        <BackContainer>
            {isFormModal ? (
                <Overlay
                    onClick={() => setFormModal(false)}
                    style={{ display: isFormModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isFormModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Heading>Interview status</Heading>
                            <Closeicon onClick={handleClick}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Icon"
                                />
                            </Closeicon>
                        </Top>
                        <Bottom>
                            <Profile>
                                <ProfileIconContainer>
                                    <Jdenticon size="30" value="5" />
                                </ProfileIconContainer>
                                <ProfileDetails>
                                    <UserName>{data.name}</UserName>
                                    <PhoneNumber>
                                        {data.phone_number}
                                    </PhoneNumber>
                                </ProfileDetails>
                            </Profile>

                            <StatusDetails>
                                <StatusDetail
                                    onClick={() => {
                                        setSelected("approved");
                                        setStatus("approved");
                                    }}
                                >
                                    <Select>
                                        <Tickcontainer>
                                            {isSelected === "approved" ? (
                                                <Tick
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-06-2022/tick.svg"
                                                    alt="Check"
                                                />
                                            ) : (
                                                <Tick
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/untick.svg"
                                                    alt="Check"
                                                />
                                            )}
                                        </Tickcontainer>
                                    </Select>
                                    <Status>Accepted</Status>
                                </StatusDetail>
                                <StatusDetail
                                    onClick={() => {
                                        setSelected("rejected");
                                        setStatus("rejected");
                                    }}
                                >
                                    <Select>
                                        <Tickcontainer>
                                            {isSelected === "rejected" ? (
                                                <Tick
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-06-2022/tick.svg"
                                                    alt="Check"
                                                />
                                            ) : (
                                                <Tick
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/untick.svg"
                                                    alt="Check"
                                                />
                                            )}
                                        </Tickcontainer>
                                    </Select>
                                    <Status>Rejected</Status>
                                </StatusDetail>
                            </StatusDetails>
                        </Bottom>
                        <Buttons>
                            <CancelButton onClick={handleClick}>
                                Cancel
                            </CancelButton>
                            {isSelected ? (
                                <>
                                    <SubmitButton
                                        onClick={() => {
                                            interviewStatusUpdate();
                                        }}
                                    >
                                        {isButtonLoading ? (
                                            <ButtonLoader />
                                        ) : (
                                            " Submit"
                                        )}
                                    </SubmitButton>
                                </>
                            ) : (
                                <NotAllowed>Submit</NotAllowed>
                            )}
                        </Buttons>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default InterviewStatusModal;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: scroll;
    box-shadow: 0px 3px 56px #00000048;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
`;
const Heading = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
`;
const Closeicon = styled.div`
    width: 18px;
    margin-bottom: 10px;
    margin-left: auto;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const Bottom = styled.div``;
const Profile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #26272a;
    padding-bottom: 10px;
`;
const ProfileIconContainer = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
`;
const ProfileDetails = styled.div`
    margin-left: 10px;
    font-family: gordita_regular;
`;
const UserName = styled.h3`
    font-size: 14px;
    color: #fff;
`;
const PhoneNumber = styled.h3`
    font-size: 14px;
    color: #fff;
`;
const StatusDetails = styled.ul`
    margin-top: 10px;
`;
const StatusDetail = styled.li`
    display: flex;
    padding: 10px;
    align-items: center;
    background: #161619;
    cursor: pointer;
    border: 1px solid #3b3b3b;
    border-radius: 4px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    &.active {
        border: 1px solid #fe673a;
    }
`;
const Select = styled.div`
    &.show {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #1b1c1f;
        border: 1px solid #3b3b3b;
    }
`;
const Tickcontainer = styled.div`
    width: 70%;
`;
const Tick = styled.img`
    width: 100%;
    display: block;
`;
const Status = styled.p`
    margin-left: 10px;
    font-size: 14px;
    font-family: gordita_regular;
    color: #fff;
`;
const Buttons = styled.div`
    display: flex;
    margin-left: auto;
    margin-top: 25px;
`;
const CancelButton = styled.button`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-left: auto;
    background-color: #161619;
    border: 1px solid #fe673a;
    margin-right: 20px;
`;
const SubmitButton = styled.button`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    font-family: "gordita_medium";
    margin-left: auto;
    background-color: #fe673a;
`;
const NotAllowed = styled.button`
    color: #fff;
    border-radius: 5px !important;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: not-allowed;
    font-family: "gordita_medium";
    margin-left: auto;
    background-color: #fe673a;
`;
