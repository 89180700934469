import React from "react";
import styled from "styled-components";
import NoData from "../../includes/jobdesk/NoData";
import Graduatecard from "./Graduatecard";

function GraduatesList({ data, isUpdated, setUpdated }) {
    return (
        <>
            {/* <TotalContainer> */}
            <TopContainer>
                <Category>
                    <Item type="slno">Sl No.</Item>
                    <Item type="dist">Name</Item>
                    <Item type="dist">Email</Item>
                    <Item type="slno">Gender</Item>
                    <Item>College</Item>
                    <Item type="dist">Local body</Item>
                    <Item type="dist">Category</Item>
                    <Item type="dist">Applied on</Item>
                    <Item type="dist">Interview on</Item>
                    <Item type="slno">Resume</Item>
                    <Item type="dist">Action</Item>
                </Category>
                <hr />
                <>
                    {data?.length > 0 ? (
                        data.map((item, index) => (
                            <Graduatecard
                                item={item}
                                index={index}
                                key={item.id}
                                isUpdated={isUpdated}
                                setUpdated={setUpdated}
                            />
                        ))
                    ) : (
                        <NoData />
                    )}
                </>
            </TopContainer>
            <hr />
            {/* <BottomContainer> */}
            {/* <Cover>
                        <List>1</List>
                        <List>
                            Anjana
                            <Span>9974171816</Span>
                        </List>
                        <List>
                            anjana@gmail.com
                        </List>
                        <List>Female</List>
                        <List>
                            Bca 
                            <Span>IF college</Span>
                        </List>
                        <List>Graduate</List>
                        <List>10/02/2022</List>
                        <List>12/02/2022</List>
                        <List>--</List>
                        <List>
                            <Button 
                               
                            >
                                Update
                            </Button>
                        </List>
                    </Cover> */}
            {/* </BottomContainer> */}
            {/* </TotalContainer>   */}
        </>
    );
}

export default GraduatesList;

const TopContainer = styled.div`
    hr {
        border-bottom: 1px solid #000;
        width: 99%;
        margin: 0 auto;
    }
`;
const Category = styled.ul`
    display: flex;
    justify-content: space-between;
    padding: 20px;
`;
const Item = styled.li`
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "slno" ? "4%" : type === "dist" ? "9.5%" : "11.5%"};

    &:last-child {
        text-align: right;
    }
`;
const BottomContainer = styled.div``;
const Cover = styled.ul`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
`;
const List = styled.li`
    color: rgb(255, 255, 255);
    font-size: 14px;
    width: ${({ type }) =>
        type === "slno" ? "5%" : type === "dist" ? "10%" : "14%"};
`;
const Span = styled.span`
    display: block;
`;
const Button = styled.button`
    color: rgb(255, 255, 255);
    background: #f7684c;
    padding: 8px 20px;
    border-radius: 8px;
    cursor: pointer;
`;
