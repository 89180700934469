import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import VideoPlayer from "../../applications/video-player/src/VideoPlayer";
import Blocks from "editorjs-blocks-react-renderer";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoData from "../jobdesk/NoData";

function ProjectOverView({
    slug,
    setModal,
    details,
    editorContent,
    isbuttonLoad,
    setIsButtonLoad,
}) {
    const [isToggled, setIsToggled] = useState();

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const handlePublished = async (details_status) => {
        setIsButtonLoad(true);
        const Newstatus = details_status;
        setIsToggled(Newstatus === "published" ? true : false);
        const formData = new FormData();
        formData.append(
            "status",
            details_status === "published" ? "draft" : "published"
        );

        try {
            const response = await learnConfig.post(
                `projects/publish/${slug}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );

            const { StatusCode, data } = response.data;

            if (StatusCode === 6000) {
                // setReloadApi((prev) => !prev);
                setIsButtonLoad(false);
            } else {
                console.log(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const videoJsOptions = {
        autoplay: false,
        controls: false,
        sources: [
            {
                src: details?.cover_video_url,
                type: "video/m3u8",
            },
        ],
    };

    return (
        <MainContainer>
            <Container>
                <HeadContainer>
                    <HeadLeft>
                        {details?.status === "draft" ? (
                            <EditButton>
                                <EditIcon>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/edit.svg"
                                        alt="edit-icon"
                                    />
                                </EditIcon>
                                <EditText
                                    to={`/projects/add-project/?pk=${slug}`}
                                >
                                    Edit info
                                </EditText>
                            </EditButton>
                        ) : (
                            <EditButton className="disabled">
                                <EditIcon>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/edit.svg"
                                        alt="edit-icon"
                                    />
                                </EditIcon>
                                <EditText className="disabled">
                                    Edit info
                                </EditText>
                            </EditButton>
                        )}
                    </HeadLeft>
                    <HeadRight>
                        <TextContainer>
                            <LeftText>Unpublish</LeftText>
                            <ToggleContainer>
                                {/* {isbuttonLoad === true ? (
                                    <ButtonLoader />
                                ) : ( */}
                                <ToggleButton
                                    onClick={() => {
                                        handlePublished(details?.status);
                                    }}
                                    toggled={details?.status === "published"}
                                />
                                {/* )} */}
                            </ToggleContainer>
                            <RightText>Publish</RightText>
                        </TextContainer>
                        <AssignButton
                            onClick={() => {
                                setModal(true);
                            }}
                        >
                            <AssignIcon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/icon.svg"
                                    alt="plusicon"
                                />
                            </AssignIcon>
                            <AssignText>Assigned to</AssignText>
                        </AssignButton>
                    </HeadRight>
                </HeadContainer>
                <BottomContainer>
                    <BottonLeft>
                        <VideoSection>
                            {details?.cover_video_url?.length > 0 ? (
                                <VideoPlayer
                                    cover={details?.image}
                                    source={
                                        details?.cover_video_url
                                            ? details?.cover_video_url
                                            : null
                                    }
                                    {...videoJsOptions}
                                    autoPlay={false}
                                />
                            ) : (
                                <LazyLoadImage
                                    src={details?.image}
                                    effect="blur"
                                />
                            )}
                        </VideoSection>
                        <TextSection>
                            {editorContent && <Blocks data={editorContent} />}
                        </TextSection>
                    </BottonLeft>

                    <BottomRight>
                        <Label>Project Assets</Label>
                        {details?.assets?.figma_url?.length > 0 ||
                        details?.assets?.git_url?.length > 0 ||
                        details?.assets?.document_data?.asset_name?.length >
                            0 ? (
                            <AssetsCard>
                                {details?.assets?.figma_url?.length > 0 ? (
                                    <Card>
                                        <TextDiv>
                                            <Icon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/group.svg"
                                                    alt="icon"
                                                />
                                            </Icon>
                                            <IconText>Figma Design</IconText>
                                        </TextDiv>
                                        {/* <Size>--</Size> */}
                                        <Button
                                            to={details?.assets?.figma_url}
                                            target="_blank"
                                        >
                                            View
                                        </Button>
                                    </Card>
                                ) : (
                                    ""
                                )}
                                {details?.assets?.git_url?.length > 0 ? (
                                    <Card>
                                        <TextDiv>
                                            <Icon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-03-2024/mdi_github.svg"
                                                    alt="icon"
                                                />
                                            </Icon>
                                            <IconText>GitHub Repo</IconText>
                                        </TextDiv>

                                        <Button
                                            to={details?.assets?.git_url}
                                            target="_blank"
                                        >
                                            View
                                        </Button>
                                    </Card>
                                ) : null}
                                {details?.assets?.document_data?.asset_name
                                    ?.length > 0 ? (
                                    <Card>
                                        <TextDiv>
                                            <Icon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-03-2024/zip_file.svg"
                                                    alt="icon"
                                                />
                                            </Icon>
                                            <IconText>Media Assets</IconText>
                                        </TextDiv>

                                        <Size>
                                            {
                                                details?.assets?.document_data
                                                    ?.asset_size
                                            }
                                        </Size>
                                        <Button
                                            to={
                                                details?.assets?.document_data
                                                    ?.asset_name
                                            }
                                        >
                                            View File
                                        </Button>
                                    </Card>
                                ) : null}
                            </AssetsCard>
                        ) : (
                            <ImageBox>
                                <CardImage
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                                    alt="Image"
                                />
                                <p>No data found</p>
                            </ImageBox>
                        )}

                        <Label>Skill</Label>
                        <SkillCard>
                            <Skills>
                                <SkillList>
                                    {details?.skill?.name ?? "--"}
                                </SkillList>
                            </Skills>
                        </SkillCard>
                    </BottomRight>
                </BottomContainer>
            </Container>
        </MainContainer>
    );
}

export default ProjectOverView;

const MainContainer = styled.div``;
const Container = styled.div``;
const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border: 1px solid #2c2c2c;
    padding: 16px;
    border-radius: 4px;
    background-color: #1b1c1f;
    margin-bottom: 20px;
`;
const HeadLeft = styled.div``;
const EditButton = styled.div`
    cursor: pointer !important;
    background-color: rgb(27, 28, 31);
    border: 1px solid rgb(254, 103, 58);
    display: flex;
    align-items: center;
    padding: 6px 14px;
    border-radius: 6px;
    &.disabled {
        opacity: 0.2;
        cursor: not-allowed !important;
    }
`;
const EditIcon = styled.span`
    display: inline-block;
    width: 16px;
    margin-right: 6px;
    img {
        display: block;
        width: 100%;
    }
`;
const EditText = styled(Link)`
    font-family: "gordita_medium";
    font-size: 14px;
    color: #fff;
    &.disabled {
        cursor: not-allowed !important;
    }
`;
const HeadRight = styled.div`
    display: flex;
    align-items: center;
`;
const TextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const AssignButton = styled.div`
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 6px;
    border-radius: 8px;
    width: 160px;
    height: 45px;
    background-color: #fe673a;
    cursor: pointer;
`;
const AssignIcon = styled.span`
    display: inline-block;
    width: 15px;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;
const AssignText = styled.h5`
    font-family: "gordita_medium";
    font-size: 15px;
    color: #fff;
`;
const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const LeftText = styled.h4`
    color: #9c9c9c;
    height: 20px;
    font-family: "gordita_medium";
    font-size: 16px;
`;

const RightText = styled.h4`
    color: #9c9c9c;
    height: 20px;
    font-family: "gordita_medium";
    font-size: 16px;
`;

const ToggleContainer = styled.div`
    height: 33px;
`;

const ToggleButton = styled.button`
    width: 40px;
    height: 22px;
    border: none;
    border-radius: 12px;
    background-color: ${({ toggled }) => (toggled ? "#fe673a" : "#2c2c2c")};
    cursor: pointer;
    position: relative;

    &::before {
        content: "";
        width: ${({ toggled }) => (toggled ? "19px" : "16px")};
        height: ${({ toggled }) => (toggled ? "19px" : "16px")};
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: ${({ toggled }) => (toggled ? "1px" : "3px")};
        left: ${({ toggled }) => (toggled ? "calc(100% - 22px)" : "2px")};
        transition: left 0.3s ease-in-out;
    }
`;

const BottonLeft = styled.div`
    width: 65%;
`;
const BottomRight = styled.div`
    width: 30%;
`;

const VideoSection = styled.div`
    margin-bottom: 40px;
    img {
        display: block;
        width: 100%;
    }
`;
const TextSection = styled.div`
    margin-top: 10px;
    & h1 {
        font-size: 32px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 24px;
        line-height: 32px;
    }
    & h2 {
        font-size: 24px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 24px;
        line-height: 32px;

        @media all and (max-width: 480px) {
            /* width: 90%; */
            font-size: 22px;
        }
    }
    & h3 {
        font-size: 20px;
        /* width: 90%; */
        font-family: "gordita_medium";
        color: #fff;
        margin-bottom: 24px;
        line-height: 32px;
    }
    & p {
        color: #fff;
        font-size: 16px;
        font-family: "gordita_regular";
        margin-top: 10px;
        /* width: 90%; */
        line-height: 30px;
        margin-bottom: 30px;

        @media all and (max-width: 480px) {
            /* width: 90%; */
            font-size: 15px;
            line-height: 27px;
        }
    }
    & a {
        color: #fe673a;
        text-decoration-line: underline;
        font-style: italic;
        cursor: pointer;
    }
    & table {
        /* width: 90%; */
        border-collapse: collapse;
        border-radius: 8px !important;
        border: 1px solid #e8e8e8;
        background-color: #f2f2f2;
        margin-bottom: 48px;

        @media all and (max-width: 480px) {
            /* width: 70%; */
            min-width: 200px;
        }

        /* overflow: hidden; */
    }
    & thead {
        background-color: #f2f2f2;
        color: #aaaaaa;
        text-align: left;
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
        @media all and (max-width: 480px) {
            font-size: 12px;
            padding: 6px;
        }
    }
    & th {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }
    & td {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        color: #000;
        @media all and (max-width: 980px) {
            font-size: 13px;
        }
        @media all and (max-width: 480px) {
            font-size: 11px;
            padding: 6px;
        }
    }
    & tbody tr:hover {
        background-color: #f5f5f5;
    }

    & ol {
        list-style-type: upper-roman;
        margin-left: 30px;
        margin-top: 10px;
    }
    & ul {
        list-style-type: square;
        margin-left: 30px;
        margin-top: 10px;
    }
    li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    & ol ol,
    ul ul {
        list-style-type: lower-alpha;
        margin-left: 30px;
    }
    & figure {
        margin-bottom: 20px;
        img {
            display: block;
            width: 100%;
        }
        img:hover {
            cursor: zoom-in;
        }
        .ytp-cued-thumbnail-overlay-image {
            height: 0;
            padding-top: 56.25%;
        }
        .embed-block-service-twitter {
            max-width: 560px;
            min-width: 560px;
            min-height: 87vh;
        }
        @media all and (max-width: 980px) {
            margin-bottom: 25px;
        }
    }

    & figure:has(> iframe.embed-block-service-youtube) {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    & pre {
        width: 100% !important;
        padding: 10px !important;
        background: #d2caca;
    }

    & code {
        width: 100% !important;
        display: block !important;
        overflow: scroll !important;
        ::-webkit-scrollbar {
            display: none !important;
        }
    }

    & li {
        color: #fff;
    }
`;
const AssetsCard = styled.div`
    background-color: #1b1c1f;
    border: 1px solid #2c2c2c;
    border-radius: 6px;
    padding: 22px;
    margin-bottom: 10px;
    width: 100%;
`;
const Label = styled.p`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 16px;
    margin-bottom: 10px;
`;
const SkillCard = styled.div`
    background-color: #1b1c1f;
    border: 1px solid #2c2c2c;
    border-radius: 6px;
    padding: 22px;
    margin-bottom: 20px;
    width: 100%;
`;

const TextDiv = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
`;

const Icon = styled.span`
    display: inline-block;
    width: 16%;

    img {
        display: block;
        width: 100%;
    }
`;
const IconText = styled.p`
    color: #4d4d4d;
    font-size: 15px;
    font-family: "gordita_medium";
    margin-left: 10px;
`;
const Size = styled.small`
    display: inline-block;
    width: 20%;
    color: #a4a4a4;
    font-size: 14px;
    font-family: "gordita_medium";
    text-align: center;
`;
const Button = styled(Link)`
    display: inline-block;
    width: 25%;
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_medium";
    cursor: pointer;
`;

const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0px;
    }
`;

const Skills = styled.ul`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
`;
const SkillList = styled.li`
    color: #027a48;
    font-size: 14px;
    font-family: "gordita_medium";
    border: 1px solid #027a48;
    border-radius: 8px;
    padding: 8px 12px;
    width: fit-content;
    text-transform: uppercase;
`;
const Circle = styled(Link)`
    position: absolute;
    left: 4px;
    top: 1.5px;
    height: 16px;
    width: 16px;
    background-color: #fff;

    transition: ease-in 0.4s all;
    border-radius: 50%;
    cursor: pointer;
    &.activate {
        left: 20px;
        background-color: #fff;
    }
`;
const ImageBox = styled.div`
    margin: auto;
    width: 30%;

    p {
        color: #465663;
        font-size: 16px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;

const CardImage = styled.img`
    width: 100%;
    display: block;
`;
