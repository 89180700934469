import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { error } from "jquery";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "../jobdesk/NoData";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function TechUpdateCatogery({
    setModalType,
    setDeleteModal,
    setSelectedCategory,
    isReload,
    setEditCategoryPK,
    editCategoryPK,
    editLoder,
    getCategory,
}) {
    //category
    const [categories, setCategories] = useState([]);
    const [isPageLoading, setPageLoading] = useState(false);
    //pagination
    const [pagination, setPagination] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);
    const currentParams = Object.fromEntries([...searchParams]);

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        return filterParams;
    };

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    //To. get all categories
    const categoryFetch = () => {
        setPageLoading(true);
        const params = getSearchParams();
        learnConfig
            .get("/tech-updates/list-categories/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                let { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setPagination(pagination_data);
                    setCategories(data);
                    setPageLoading(false);
                } else {
                    console.log(error);
                    setPageLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setPageLoading(false);
            });
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    useEffect(() => {
        categoryFetch();
    }, [isReload, searchParams]);

    return (
        <Container>
            <ContentContainer>
                <MainContainer>
                    <Top>
                        <Text type="sl_no">Sl No</Text>
                        <Text type="title">Title</Text>
                        <Text type="category">Category type</Text>
                        <Text type="category_image">Category Image</Text>
                        <Text type="parent_category">Parent category</Text>
                        <Text type="actions">Actions</Text>
                    </Top>
                    <DataContainer>
                        {isPageLoading ? (
                            <SectionLoader />
                        ) : categories.length > 0 ? (
                            <>
                                {categories.map((data, index) => (
                                    <Bottom key={index}>
                                        <Section type="sl_no">
                                            <small>
                                                {index < 9 &&
                                                pagination.current_page === 1
                                                    ? `0${
                                                          pagination.first_item +
                                                          index
                                                      }`
                                                    : pagination.first_item +
                                                      index}
                                            </small>
                                        </Section>
                                        <Section type="title">
                                            <p>
                                                {data.title ? data.title : "--"}
                                            </p>
                                        </Section>
                                        <Section type="category">
                                            <p>
                                                {data.category_type
                                                    ? data.category_type
                                                    : "--"}
                                            </p>
                                        </Section>
                                        <Section type="category_image">
                                            <span>
                                                <img
                                                    src={data.featured_image}
                                                    alt=""
                                                />
                                            </span>
                                        </Section>
                                        <Section type="parent_category">
                                            <p>
                                                {data.parent_category
                                                    ? data.parent_category
                                                    : "--"}
                                            </p>
                                        </Section>
                                        <Section type="actions">
                                            <BottomContainer>
                                                <Edit
                                                    onClick={() => {
                                                        setModalType("edit");
                                                        getCategory(data.id);
                                                        setEditCategoryPK(
                                                            data.id
                                                        );
                                                    }}
                                                >
                                                    {editLoder &&
                                                    data.id ==
                                                        editCategoryPK ? (
                                                        <ButtonLoader
                                                            width={25}
                                                            height={25}
                                                        />
                                                    ) : (
                                                        <ImageBox>
                                                            <img
                                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/Edit.svg"
                                                                alt="EditIcon"
                                                            />
                                                        </ImageBox>
                                                    )}
                                                </Edit>
                                                <Delete
                                                    onClick={() => {
                                                        setModalType(
                                                            "delete_category"
                                                        );
                                                        setDeleteModal(
                                                            (prev) => !prev
                                                        );
                                                        setSelectedCategory(
                                                            data.title
                                                        );
                                                        setEditCategoryPK(
                                                            data.id
                                                        );
                                                    }}
                                                >
                                                    Delete
                                                </Delete>
                                            </BottomContainer>
                                        </Section>
                                    </Bottom>
                                ))}
                            </>
                        ) : (
                            <NoData />
                        )}
                    </DataContainer>
                </MainContainer>
            </ContentContainer>
            {pagination?.total_pages > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </Container>
    );
}

export default TechUpdateCatogery;

const Container = styled.section`
    padding-top: 22px;
`;
const ContentContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const MainContainer = styled.div`
    min-width: 1280px;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    border-radius: 8px 8px 0 0;
    background-color: #1b1c1f;
`;

const Text = styled.h3`
    color: #d2caca;
    font-size: 14px;
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "category_image"
            ? "10%"
            : type === "parent_category"
            ? "25%"
            : type === "actions" && "15%"};
    display: flex;
    align-items: center;
    justify-content: ${({ type }) =>
        type === "actions" ? "flex-end" : "center"};
`;

const DataContainer = styled.div`
    min-height: 61vh;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border: 1px solid #2e2e2e;
    background-color: #1b1c1f;
    &:hover {
        background-color: #161619;
    }
`;
const Section = styled.div`
    color: #d2caca;
    width: ${({ type }) =>
        type === "sl_no"
            ? "5%"
            : type === "title"
            ? "20%"
            : type === "category"
            ? "15%"
            : type === "category_image"
            ? "10%"
            : type === "parent_category"
            ? "25%"
            : type === "actions" && "15%"};
    justify-content: ${({ type }) =>
        type === "actions" ? "flex-end" : "center"};

    display: flex;
    align-items: center;

    p {
        color: #d2caca;
    }
    span {
        height: 30px;
        /* width: 30px; */
        img {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
`;

const BottomContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Edit = styled.button`
    border-radius: 6px;
    border: 2px solid #2c2c2c;
    background: #2c2c2c;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
`;
const ImageBox = styled.span`
    width: 20px;
    display: inline-block;
    img {
        display: block;
        width: 100%;
    }
`;
const Delete = styled.button`
    margin-left: 10px;
    font-family: "gordita_regular";
    color: #ff3030;
    font-size: 14px;
    padding: 10px 28px;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
