import React from "react";
import styled from "styled-components";

function Levels({ count }) {
    return (
        <>
            <Container>
                <Text>Total</Text>
                <Cover>
                    <Item>
                        <LeftContainer>
                            <GreenCircle></GreenCircle>
                            <TextOne>Level 1</TextOne>
                        </LeftContainer>
                        <RightContainer>
                            <Number>{count.total_attended}</Number>
                        </RightContainer>
                    </Item>
                    <Item>
                        <LeftContainer>
                            <RedCircle></RedCircle>
                            <TextTwo>Level 2</TextTwo>
                        </LeftContainer>
                        <RightContainer>
                            <Number>0</Number>
                        </RightContainer>
                    </Item>
                    <Item>
                        <LeftContainer>
                            <OrangeCircle></OrangeCircle>
                            <TextThree>Level 3</TextThree>
                        </LeftContainer>
                        <RightContainer>
                            <Number>0</Number>
                        </RightContainer>
                    </Item>
                </Cover>
            </Container>
        </>
    );
}
const Container = styled.div`
    background: #1b1c20;
    padding: 30px;
    border-radius: 16px;
    height: 329px;
    border: 1px solid #242528;
`;
const Cover = styled.ul``;
const Item = styled.li`
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #161616;
    padding: 20px;
    height: 54px;
    border-radius: 8px;
    border: 1px solid #242528;
    margin: 0 auto;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const Text = styled.h3`
    margin-bottom: 30px;
    color: #fff;
    font-size: 16px;
`;
const LeftContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const GreenCircle = styled.div`
    width: 12px;
    height: 12px;
    background: rgb(68, 192, 130);
    margin-right: 5px;
    border-radius: 25px;
`;
const RedCircle = styled.div`
    width: 12px;
    height: 12px;
    background: rgb(239, 6, 61);
    margin-right: 5px;
    border-radius: 25px;
`;
const OrangeCircle = styled.div`
    width: 12px;
    height: 12px;
    background: #ff8733;
    margin-right: 5px;
    border-radius: 25px;
`;
const TextOne = styled.h2`
    color: #fff;
    font-size: 18px;
    margin-right: 50px;
    color: rgb(68, 192, 130);
`;
const TextTwo = styled.h2`
    color: #fff;
    font-size: 18px;
    margin-right: 50px;
    color: rgb(239, 6, 61);
`;
const TextThree = styled.h2`
    color: #fff;
    font-size: 18px;
    color: #ff8733;
`;

const RightContainer = styled.div``;
const Number = styled.h3`
    font-size: 18px;
`;

export default Levels;
