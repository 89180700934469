import React, { lazy } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
//components
const EventDetails = lazy(() =>
    import("../../../includes/examiner-head/EventDetails")
);
const EventDetailsTabStatus = lazy(() =>
    import("../../../includes/examiner-head/EventDetailsTabStatus")
);
const EventExaminers = lazy(() =>
    import("../../../includes/examiner-head/EventExaminers")
);
const EventStudents = lazy(() =>
    import("../../../includes/examiner-head/EventStudents")
);

export default function EventDetailsRouter() {
    const { status, exam_center_pk } = useParams();
    return (
        <div>
            <div style={{ marginBottom: "30px" }}>
                <Title>Event Single page</Title>
                <EventDetailsTabStatus
                    status={status}
                    exam_center_pk={exam_center_pk}
                />
            </div>
            <Routes>
                <Route path="details/" element={<EventDetails />} />
                <Route path="examiners/" element={<EventExaminers />} />
                <Route path="students/" element={<EventStudents />} />
            </Routes>
        </div>
    );
}

const Title = styled.h5`
    font-size: 18px;
    color: #ffffff;
    font-family: "gordita_medium";
    margin-bottom: 20px;
`;
