import React from "react";
import styled from "styled-components";
function NoData() {
    return (
        <>
            {" "}
            <ImageBox>
                <CardImage
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                    alt="Image"
                />
                <p>No data found</p>
            </ImageBox>
        </>
    );
}
const ImageBox = styled.div`
    margin: 0 auto;
    width: 30%;
    margin-top: 40px;
    /* margin-bottom: 170px; */
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;

const CardImage = styled.img`
    width: 100%;
    display: block;
`;
export default NoData;
