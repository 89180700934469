import React, {useState, useContext} from 'react'
import styled from 'styled-components'
import NoData from '../../includes/jobdesk/NoData';
import Graduatecard from './Graduatecard';
import { useEffect } from 'react';
import { managersConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useSearchParams } from "react-router-dom";
import SectionLoader from '../../helpers/SectionLoader';
import JobApplicantsList from '../../includes/jobdesk/JobApplicantsList';
import GraduatesList from './GraduatesList';
// import JobDeskApplicantsFilter from '../../includes/jobdesk/JobDeskApplicantsFilter';
import GraduateApplicatsFilter from "../../includes/jobdesk/GraduateApplicatsFilter"



function Graduate() {   
    const [data,setData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [isUpdated, setUpdated] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [applicantsData, setApplicantsData] = useState([]);
    
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const getCollegeFilterParams = () => {
        let filterParams = {};
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("timestamp") === "today" &&
            (filterParams.timestamp = "today");
        searchParams.get("timestamp") === "this_week" &&
            (filterParams.timestamp = "this_week");
        searchParams.get("timestamp") === "this_month" &&
            (filterParams.timestamp = "this_month");
        searchParams.get("from_date") &&
            (filterParams.from_date = dateConversion(
                searchParams.get("from_date")
            ));
        searchParams.get("to_date") &&
            (filterParams.to_date = dateConversion(
                searchParams.get("to_date")
            ));
        searchParams.get("applied_date") &&
            (filterParams.applied_date = dateConversion(
                searchParams.get("applied_date")
            ));
        searchParams.get("category") === "graduate" &&
            (filterParams.category = "graduate");
        searchParams.get("category") === "drop_out" &&
            (filterParams.category = "drop_out");
        searchParams.get("category") === "employee" &&
            (filterParams.category = "employee");
        searchParams.get("student_status") === "approved" &&
            (filterParams.student_status = "approved");
        searchParams.get("student_status") === "rejected" &&
            (filterParams.student_status = "rejected");
        searchParams.get("student_status") === "pending" &&
            (filterParams.student_status = "pending");
        return filterParams;
    };

    useEffect(() => {
        const fetchjobapplicants = () => {
            const params = getCollegeFilterParams();
            managersConfig
                .get("web/job-desk/students/?category=graduate", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params : params
                })
                .then((response) => {
                    let { StatusCode, data} = response.data;
                    if (StatusCode === 6000) {
                        setData(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        };
        fetchjobapplicants();
    },[searchParams, isUpdated]);

    const [activeClick, setActiveClick] = useState("Filter");
    // const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [searchWord, setSearchWord] = useState("");
    const today = new window.Date();

    // const getFilterParams = () => {
    //     let filterParams = {};
    //     searchParams.get("q") && (filterParams.q = searchParams.get("q"));
    //     searchParams.get("timestamp") === "today" &&
    //         (filterParams.timestamp = "today");
    //     searchParams.get("timestamp") === "this_week" &&
    //         (filterParams.timestamp = "this_week");
    //     searchParams.get("timestamp") === "this_month" &&
    //         (filterParams.timestamp = "this_month");
    //     searchParams.get("category") === "student" &&
    //         (filterParams.category = "student");
    //     searchParams.get("category") === "graduate" &&
    //         (filterParams.category = "graduate");
    //     searchParams.get("category") === "drop_out" &&
    //         (filterParams.category = "drop_out");
    //     searchParams.get("category") === "employee" &&
    //         (filterParams.category = "employee");
    //     searchParams.get("student_status") === "approved" &&
    //         (filterParams.student_status = "approved");
    //     searchParams.get("student_status") === "rejected" &&
    //         (filterParams.student_status = "rejected");
    //     searchParams.get("student_status") === "pending" &&
    //         (filterParams.student_status = "pending");
    //     searchParams.get("applied_date") &&
    //         (filterParams.applied_date = dateConversion(
    //             searchParams.get("applied_date")
    //         ));
    //     searchParams.get("from_date") &&
    //         (filterParams.from_date = dateConversion(
    //             searchParams.get("from_date")
    //         ));
    //     searchParams.get("to_date") &&
    //         (filterParams.to_date = dateConversion(
    //             searchParams.get("to_date")
    //         ));
    //     return filterParams;
    // };

    useEffect(() => {
        if (searchParams.get("q")) {
            setActiveClick("Search");
        } else if (searchParams.get("timestamp") === "today") {
            setActiveClick("Today");
        } else if (searchParams.get("timestamp") === "this_week") {
            setActiveClick("This Week");
        } else if (searchParams.get("timestamp") === "this_month") {
            setActiveClick("This Month");
        } else if (searchParams.get("category") === "Student") {
            setActiveClick("Student");
        } else if (searchParams.get("category") === "Graduate") {
            setActiveClick("Graduate");
        } else if (searchParams.get("category") === "Drop_out") {
            setActiveClick("Dropout");
        } else if (searchParams.get("category") === "employee") {
            setActiveClick("Employee");
        } else if (searchParams.get("student_status") === "approved") {
            setActiveClick("Accepted");
        } else if (searchParams.get("student_status") === "rejected") {
            setActiveClick("Rejected");
        } else if (searchParams.get("student_status") === "pending") {
            setActiveClick("Pending");
        } else {
            setActiveClick("Filter");
        }
    });

    const dateConversion = (value) => {
        if (value) {
            let values = value.split("-");
            const dd = values[0];
            const mm = values[1];
            const yy = values[2];
            let date = yy + "-" + mm + "-" + dd;
            return date;
        }
        return "";
    };

  return (
    <>
        <Container>
            <TotalContainer>
                <Heading>Graduates</Heading>
                <FilterDiv>
                    <GraduateApplicatsFilter
                        activeClick={activeClick}
                        setActiveClick={setActiveClick}
                        today={today}
                        setLoading={setLoading}
                        isLoading={isLoading}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        searchWord={searchWord}
                        setSearchWord={setSearchWord}
                    />
                </FilterDiv>
            </TotalContainer>
            
                {isLoading ? (
                            <SectionLoader />
                        ) : data?.length > 0 ? (
                            <BottomContainer>
                                <GraduatesList
                                    data={data}
                                    key={data.id}
                                    isUpdated={isUpdated}
                                    setUpdated={setUpdated}
                                />
                            </BottomContainer>  
                        ) : (
                            <NoData />
                        )}
              
        </Container>
    </>
  )
}
const Container = styled.div`
   width: 100%;
`;
const TotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;
const FilterDiv = styled.div``;
const Heading = styled.h1`
    font-size: 18px;
    color: rgb(255,255,255);
    font-family: "gordita_medium";
    margin-bottom: 10px;
`;
const BottomContainer = styled.div`
    background: #1b1c1f;
    border-radius: 5px;
    border: 1px solid #26272a;
`;
export default Graduate